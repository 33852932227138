import { useState, useEffect } from "react";
import { useNavigate, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {
  FormControl,
  InputLabel,
  Stack,
  Input,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import * as Yup from "yup";
import { BASE_URL, API_ENDPOINTS } from "../../../src/ApiEndpoint/ApiEndpoint";
import { tokens } from "../../theme";
import "./LoginPage.css";
import ForgotPassword from "./ForgetPassword";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import jwtDecode from "jwt-decode";
import logoImage from "../../../src/Assets/Logo/NewLogo.png";
import aws from "../../Assets/aws.png";
import ApiErrorAlert from "../../ApiErrorAlert";
import SaveAlert from "../../SaveAlert";
import axios from "axios";
import { localStorageAvailable } from "@mui/x-data-grid/utils/utils";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

function LoginPage({ authenticated, setAuthenticated }) {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      axiosInstance
        .get("/auth/verify")
        .then((response) => {
          const data = response?.data;
          // console.log("Tokennnn data====", data);
          if (data?.loggedIn) {
            setAuthenticated(true);
          } else {
            localStorage.clear();
            console.log("token expire or not12 , ",localStorageAvailable())
            console.log("storage clearrrrrrr");
            console.log("hey123");
            navigate("");

            console.log("navigatingggg");
          }
          setLoading(false);
        })
        .catch((error) => {
          setApiErrorOpen(true);
          setError("Error authenticating user", error);
          console.log("verify API not working");
          setLoading(false);
          localStorage.clear();
          console.log("hey12");
          // console.log("token expire or not123 , ",localStorage?.getItem());
          console.log("storage clearrrrrrr");
        
          navigate("");
          console.log("navigatingggg");
        });
    } else {
      localStorage.clear();
      console.log("storage clearrrrrrr");
      console.log("token expire or not12 , ",localStorageAvailable())
      console.log("token expire or not123456 , ",localStorage?.getItem('token'));
      console.log("hey12789");
      navigate("");
      console.log("navigatingggg");
      setLoading(false);
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${API_ENDPOINTS.POST_AUTHENTICATION_LOGIN}`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response?.data;
      console.log("DATAAAAAAAAAAA", data);
      const token = data.token;
      const decodedUserName = jwtDecode(token).user;
      localStorage.setItem("userName", decodedUserName);
      if (data?.is_active) {
        if (data?.loggedIn) {
          const hiddenToken = data.token;
          console.log("Tokennn", hiddenToken);
          const decode = jwtDecode(hiddenToken);
          console.log("decodeeeeeeee", decode);
          const client_id = jwtDecode(hiddenToken).client_id;
          const userName = jwtDecode(hiddenToken).user;
          console.log("username", decode);
          console.log("ghjkkkkkkkkkkkkkkkk", client_id);
          localStorage.setItem("token", hiddenToken);

          const defaultLocation = data.locations_geographic;
          console.log("defaultLocation in Login Page----------", defaultLocation);
          // localStorage.setItem("locations_geographic", defaultLocation);
          localStorage.setItem("locations_geographic", JSON.stringify(defaultLocation));

          const fetchUserDetails = async () => {
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${hiddenToken}`;
            try {
              const response = await axiosInstance.get(
                `${
                  API_ENDPOINTS.GET_AUTHENTICATION_USERDETAILS
                }${encodeURIComponent(userName)}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              const data = await response?.data;
              console.log("data from accessibility API", data);
              if (!data?.rows || data.rows.length === 0) {
                localStorage.clear();
                console.log("storage clearrrrrrr");
                navigate("");
                console.log("navigatingggg");
                throw new Error("No user details found");
              }
              localStorage.setItem("details", JSON.stringify(data.rows[0]));
            } catch (error) {
              setApiErrorOpen(true);
              console.log("Error fetching user details:", error);
              localStorage.clear();
              console.log("storage clearrrrrrr");
              navigate("");
              console.log("navigatingggg");
            }
          };
          fetchUserDetails();
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${hiddenToken}`;
          setAuthenticated(true);
        } else {
          setError("Invalid username or password");
        }
      } else {
        setError("You do not have access. Please contact the administrator.");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Login error:", error);
      setError("Error authenticating user. Please try again later.");
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup?.object({
      username: Yup?.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      password: Yup.string()
        .min(6, "Must be at least 6 characters")
        .required("Required"),
    }),
    onSubmit,
  });

  const handleForgotPassword = () => {
    console.log("navigate forgot password page ");
    navigate("/forget-password");
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <>
      <div className="login-page" style={{ backdropFilter: "blur(5px)" }}>
        <Grid
          container
          sx={{ backgroundColor: "#ffffffcf", width: "70%", height: "90%" }}
        >
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-5%",
                marginLeft: "-12%",
              }}
            >
              <img
                src={logoImage}
                alt="Logo"
                style={{ maxWidth: "60%", maxHeight: "60%" }}
                onError={(e) => {
                  console.log("prachi", e.target.src); // Display a placeholder image or a fallback
                  // Alternatively, you can handle the error in a custom way (e.g., show an error message)
                }}
              />
              <Typography variant="h5" color={colors.green.DEFAULT}>
                Cultivating Transformation
              </Typography>
              <Typography
                variant="h5"
                color={colors.green.DEFAULT}
                sx={{ marginTop: "2%" }}
              >
                powered by
              </Typography>
              <img
                src={aws}
                alt="Powered by AWS"
                style={{
                  maxWidth: "30%",
                  maxHeight: "30%",
                  marginTop: "-10%",
                  boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)",
                }}
                onError={(e) => {
                  console.log("prachi", e.target.src);
                }}
              />
            </Stack>
          </Grid>
          {/* <Divider orientation="vertical" flexItem /> */}
          {/* <Divider variant="middle" /> */}
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "88%",
                height: "80%",
                padding: "50px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                marginRight: "22%",
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Stack
                  sx={{
                    marginTop: "-14%",
                    marginLeft: "-8%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h2" sx={{ marginTop: "1%" }}>
                    Welcome
                  </Typography>
                  <Grid
                    sx={{
                      marginLeft: "14%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "5%",
                    }}
                  >
                    <Typography variant="h4" color={colors.green.DEFAULT}>
                      Login to Farm Operating System
                    </Typography>
                  </Grid>
                </Stack>
                <Grid sx={{ marginTop: "20%" }}>
                  <FormControl sx={{ width: "40ch" }} variant="standard">
                    <InputLabel htmlFor="username">UserName</InputLabel>
                    <Input
                      type="text"
                      id="username"
                      {...formik.getFieldProps("username")}
                    />
                  </FormControl>
                  {formik.touched?.username && formik.errors?.username ? (
                    <div className="error-message">
                      {formik.errors?.username}
                    </div>
                  ) : null}
                  <Grid sx={{ marginTop: "3%" }}>
                    <FormControl
                      sx={{ width: "40ch" }}
                      variant="standard"
                      error={
                        formik.touched?.currentPassword &&
                        Boolean(formik.errors?.currentPassword)
                      }
                    >
                      <InputLabel htmlFor="password"> Password</InputLabel>
                      <Input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        {...formik.getFieldProps("password")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  {formik.touched?.password && formik.errors?.password ? (
                    <div className="error-message">
                      {formik.errors?.password}
                    </div>
                  ) : null}

                  {error && (
                    <Typography className="error-message">{error}</Typography>
                  )}

                  <div className="login-buttons">
                    <div>
                      <a
                        href="/forget-password"
                        onClick={handleForgotPassword}
                        style={{ display: "block", color: "#2c7B55" }}
                      >
                        Change password?
                      </a>
                    </div>
                  </div>
                  <button type="submit">Login</button>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
        {/* <Grid sx={{width:'50%'}}>
        <h2>Login</h2>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ width: "47ch" }} variant="standard">
            <InputLabel htmlFor="username">UserName</InputLabel>
            <Input
              type="text"
              id="username"
              {...formik.getFieldProps("username")}
            />
          </FormControl>
          {formik.touched?.username && formik.errors?.username ? (
            <div className="error-message">{formik.errors?.username}</div>
          ) : null}

          <FormControl
            sx={{ width: "47ch" }}
            variant="standard"
            error={
              formik.touched?.currentPassword &&
              Boolean(formik.errors?.currentPassword)
            }
          >
            <InputLabel htmlFor="password"> Password</InputLabel>
            <Input
              type={showPassword ? "text" : "password"}
              id="password"
              {...formik.getFieldProps("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {formik.touched?.password && formik.errors?.password ? (
            <div className="error-message">{formik.errors?.password}</div>
          ) : null}

          {error && <p className="error-message">{error}</p>}
          <div className="login-buttons">
            <div className="left-section">
              <label>
                <input type="checkbox" /> Keep me signed in
              </label>
            </div>
            <div className="right-section">
              <a href="/forget-password" onClick={handleForgotPassword}>
                Change password?
              </a>
            </div>
          </div>
          <button type="submit">Login</button>
        </form>
      </Grid> */}
      </div>

      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
}

export default LoginPage;
