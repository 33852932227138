import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import { useState, useEffect } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { ScaleLoader } from "react-spinners";

import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const CropDataGrid = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchCropData();
    const loaderTimer = setTimeout(() => {
      setIsLoading(false);
    }, 30000); // Display loader for 3 seconds

    fetchCropData();

    return () => clearTimeout(loaderTimer);
  }, []);

  const fetchCropData = () => {
    // fetch(`${BASE_URL}${API_ENDPOINTS.GET_TASK_MANAGEMENT_CROPDATA}`, {
    //   method: "GET",
    //   headers: {
    //     "content-Type": "application/json",
    //     client_id: client_id,
    //   },
    // })
    axiosInstance
      .get(API_ENDPOINTS.GET_TASK_MANAGEMENT_CROPDATA)
      .then((response) => {
        const data = response?.data;
        console.log("!!!!!!!!!", data?.rows);
        const formattedData = data?.rows?.map((item) => {
          const formatteddos = moment(item?.date_of_sow)?.format("DD-MM-YYYY");
          let formatteddot = moment(item?.date_of_transplant)?.format(
            "DD-MM-YYYY"
          );
          if (formatteddot === "Invalid date") {
            formatteddot = "NT";
          }
          const formattededoh = moment(item?.exp_date_of_harvest).format(
            "DD-MM-YYYY"
          );

          return {
            ...item,
            formatteddos,
            formatteddot,
            formattededoh,
          };
        });
        setRows(formattedData);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
    setIsLoading(false);
  };

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "#000000", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        button: {
          color: "#ffffff",

          backgroundColor: "#2c7B55",
        },
        typography: {
          button: {
            //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "success",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12, // S
          backgroundColor: "#2c7B55",
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
              root: {
                color: "#ffffff", // set the color of the text inside the button
                backgroundColor: "#2c7B55", // set the background color of the button
                "&:hover": {
                  backgroundColor: "#2c7B55", // set the background color when hovering over the button
                },
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#2c7B55", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const columns = useMemo(
    () => [
      {
        header: "Farm",
        accessorKey: "farm_name",
        size: 10,
      },
      {
        header: "Variety",
        accessorKey: "crop_variety_name",
        size: 10,
      },
      {
        header: "Crop",
        accessorKey: "crop_name",
        size: 10,
      },

      {
        header: "Zone",
        accessorKey: "zone_name",
        size: 10,
      },
      {
        header: "Batch",
        accessorKey: "batch",
        size: 10,
      },
      {
        header: "No. of Plants",
        accessorKey: "no_of_plant",
        size: 10,
      },
      {
        header: "Crop Type",
        accessorKey: "crop_type",
        size: 10,
      },
      {
        header: "Expected Phase",
        accessorKey: "current_phase",
        size: 10,
      },
      {
        header: "Actual Phase",
        accessorKey: "actual_phase",
        size: 10,
      },
      {
        header: "Next Phase",
        accessorKey: "next_phase",
        size: 10,
      },
      {
        header: "Day",
        accessorKey: "diff_days_phase",
        size: 10,
      },
      {
        header: "Exp. DOH",
        accessorKey: "formattededoh",
        size: 10,
      },
      {
        header: "Date Of Sow",
        accessorKey: "formatteddos",
        size: 10,
      },
    ],
    []
  );

  return (
    <div>
      <div>
        {isLoading ? ( // Check if data is still loading
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <ScaleLoader color="#2C7B55" />
          </div>
        ) : (
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={rows}
              enableGrouping
              enableStickyHeader
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 5 },
              }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: "650",
                  color: "#2c7B55",
                  fontSize: "15px",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  //stripe the rows, make odd rows a darker color
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f8fafc",
                  },
                },
              }}
              muiTableContainerProps={{ sx: { marginBottom: "15px" } }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
            />
          </ThemeProvider>
        )}
      </div>
    </div>
  );
};

export default CropDataGrid;
