import React, { useState, useEffect, useRef, useMemo } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { MdModeEdit } from "react-icons/md";
import NullScreen from "../PopUpMsg/NullScreen";
import { ScaleLoader } from "react-spinners";
import { CiCalendarDate } from "react-icons/ci";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import Pagination from "@mui/material/Pagination";
import { MaterialReactTable } from "material-react-table";
import { IoSaveOutline } from "react-icons/io5";
import { AlertDialogBox } from "../PopUpMsg/AlertDialog";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import {
  Grid,
  Button,
  Select,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  TextField,
  ThemeProvider,
  MenuItem,
  createTheme,
  IconButton,
  InputLabel,
  FormControl,
  Typography,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { TiTickOutline } from "react-icons/ti";
import { GiFruitBowl } from "react-icons/gi";
import Paper from "@mui/material/Paper";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import { tokens } from "../WMStheme";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const gridContainerStyle = {
   marginTop:"-1.5%",
  //   display: "flex",
  height: "74%",
  width: "100%",
   overflowY: "hidden",
  //  backgroundColor: "red",
};
const containerStyle8 = {
  // maxHeight: "70vh",
  // height: "80%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  //  backgroundColor: "pink",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};


const STOAdd = ({ setOpenPerformSTO , count , setCount }) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const oneWeekAgo = new Date();
  const [skuNames, setSkuNames] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [skuId, setSkuId] = useState(null);
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [sTOData, setSTOData] = useState([]);
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorPopupOpen3, setErrorPopupOpen3] = useState(false);
  // const [sTOData, setSTOData] = useState(dummyData);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [fromwarehouseId, setfromWarehouseId] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [secondwarehouseData, setSecondWarehouseData] = useState([]);
  const [towarehouseId, setToWarehouseId] = useState(null);
  const [selectedType, setSelectedType] = useState("sku");
  const [productCode, setProductCode] = useState("");
  const [productName, setProductName] = useState("");
  const [editedTransferQty, setEditedTransferQty] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
  const [addedSKUMasterData, setAddedSKUMasterData] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle changes in transfer_qty
  const [stoLoading ,setStoLoading] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      if (Array.isArray(parsedWarehouseData)) {
        setWarehouseData(parsedWarehouseData);
  
        // Filter out the selected first warehouse from the second warehouse options
        const filteredSecondWarehouseData = parsedWarehouseData.filter(
          (warehouse) => warehouse.id !== fromwarehouseId
        );
        setSecondWarehouseData(filteredSecondWarehouseData);
      }
    }
  }, [fromwarehouseId]); // The empty dependency array ensures that this effect runs only once on component mount

  useEffect(() => {
    // Check if both fromwarehouseId and towarehouseId are not null before calling fetchSTODatawrtWarehouse
    if (fromwarehouseId !== null && towarehouseId !== null) {
      const updatedFilterValues = {
        from_wh_id: fromwarehouseId,
        to_wh_id: towarehouseId,
        limit: 1000,
        offset: 1,
      };
      setTemporaryFilterValues(updatedFilterValues);
      fetchSTODatawrtWarehouse(updatedFilterValues); // Call the function only if both IDs are not null
    }
  }, [fromwarehouseId, towarehouseId]);

  const [filterValues, setFilterValues] = useState({
    from_wh_id: fromwarehouseId,
    to_wh_id: towarehouseId,
    limit: 1000,
    offset: 1,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    from_wh_id: fromwarehouseId,
    to_wh_id: towarehouseId,
    limit: 1000,
    offset: 1,
  });

  const handleApplyClick = () => {
    const newFilterValues = {
      from_wh_id: fromwarehouseId,
      to_wh_id: towarehouseId,
      limit: 1000,
      offset: 1,
    };
    setfromWarehouseId(temporaryFilterValues?.from_wh_id);

    setToWarehouseId(temporaryFilterValues?.to_wh_id);

    setFilterValues(newFilterValues);
    if (fromwarehouseId) {
      fetchSTODatawrtWarehouse(newFilterValues);
    }
  };
  useEffect(() => {
    const updatedFilterValues = {
      // start_date: startDate,
      // end_date: endDate,
      limit: 15,
      offset: offset,
      warehouse_id: towarehouseId,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [offset, towarehouseId]);

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleBackToSTOViewPage = () => {
    navigate("/STO");
  };
  const handleTransferQtyChange = (rowIndex, value) => {
    console.log(
      "Updating transfer_qty for rowIndex:",
      rowIndex,
      "with value:",
      value
    );
    setEditedTransferQty((prev) => ({ ...prev, [rowIndex]: value }));
  };

  const [selectedRowsState, setSelectedRowsState] = useState([]);

  const handleRefreshClick = () => {
    const initialValues = {
      limit: 1000,
      offset: 1,
      from_wh_id: fromwarehouseId,
      to_wh_id: towarehouseId,
    };

    setToWarehouseId(null);
    setfromWarehouseId(null);

    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    fetchSTODatawrtWarehouse(initialValues);
  };
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#0A0A0A",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#040404", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const handleRowClick = (row) => {
    if (row.original) {
      const skuId = row?.original?.inv_id;
      const selectedRow = {
        count: row?.original.count,
        inv_id: skuId,
        item_id: row.original.item_id,
        type: row?.original?.type,
        avl_qty: row.original?.avl_qty,
        lot_no: row.original?.lot_no,
        rate:row?.original?.rate,
        warehouse_id: row.original?.warehouse_id,
        flag: !selectedRows[skuId]?.flag,
        transfer_qty: inputValues[skuId] || 0,
      };

      setSelectedRows((prevSelectedRows) => {
        const updatedRows = { ...prevSelectedRows };
        updatedRows[skuId] = selectedRow;
        return updatedRows;
      });
    } else {
      console.error("Row data is undefined or missing 'original' property");
    }
  };

  const fetchSTODatawrtWarehouse = async (newParams) => {
    // Check if both from_wh_id and to_wh_id are not null
    if (!newParams?.from_wh_id || !newParams?.to_wh_id) {
      console.log("Both from_wh_id and to_wh_id must be provided.");
      return;
    }

    console.log("new params in fetch:", newParams);
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_STO_TRANSFERABLE_INV,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data?.inv_list;
        console.log("data", data);
        setSTOData(data);
        setIsLoading(false);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsLoading(false);
      // setErrorMessage("Please Try Again");
      // setErrorPopupOpen(true);
    }
  };

  const onRowSelectionChange = (selectedRowIds) => {
    const selectedIdsArray = Array.isArray(selectedRowIds)
      ? selectedRowIds
      : Object.keys(selectedRowIds);

    const updatedRows = sTOData
      ?.filter((row) => selectedIdsArray.includes(row.inv_id))
      .map((row) => ({
        count: row.count,
        inv_id: row.inv_id,
        item_id: row.item_id,
        type: row.type,
        rate:row?.rate,
        avl_qty: row.avl_qty,
        lot_no: row.lot_no,
        warehouse_id: row.warehouse_id,
        flag: !row.flag,
        transfer_qty: inputValues[row.inv_id] || 0,
      }));

    setSelectedRows(updatedRows);
    setSelectedRowIds(selectedIdsArray);
    setSelectedRowsState(updatedRows);
    console.log("selected rows ", updatedRows);
  };
  const handleQuantityChange = (e, original) => {
    let { value } = e.target;
    const parts = value.toString().split(".");
    const fromQty = original?.avl_qty;

    if (value <= fromQty) {
      // Update the row's quantity in the SKU master data
      // const updatedSkuMasterData = sTOData?.map((item) =>
      //   item.inv_id === original.inv_id
      //     ? { ...item, transfer_qty: value }
      //     : item
      // );
      // setAddedSKUMasterData(updatedSkuMasterData);
       if (parts?.length >= 0 && parts[1]?.length > 2) { 
        // Convert to maximum 2 decimal places
        setErrorMessage3(
          "Please enter Transfer quantity upto 2 decimal place only ."
        );
  
        setErrorPopupOpen3(true);
       
          value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
          console.log("value here after decimal round off " , value);
      
      } 
      // Limit the value to 20000
      // else if (parseFloat(value) > 20000) {
      //     value = 20000;
          
      // }
      else if (value < 0 ){
        console.log("order_qty", original?.order_qty);
        
        
        setErrorMessage3(
          "Transfer quantity should  be greater than 0."
        );
  
        setErrorPopupOpen3(true);
        value = 0;
      }
      // Update the input values state
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [original.inv_id]: value,
      }));

      // Update the selected rows with the new flag
      setSelectedRows((prevSelectedRows) => ({
        ...prevSelectedRows,
        [original.inv_id]: { flag: true },
      }));

      // Add or update the row in the array
      const updatedRow = { ...original, transfer_qty: value, flag: true };
      const index = updatedRowsArray.findIndex(
        (row) => row?.inv_id === original?.inv_id
      );

      if (index !== -1) {
        // If row exists in the array, update it
        setUpdatedRowsArray((prevArray) => [
          ...prevArray.slice(0, index),
          updatedRow,
          ...prevArray.slice(index + 1),
        ]);
      } else {
        // If row doesn't exist in the array, add it
        setUpdatedRowsArray((prevArray) => [...prevArray, updatedRow]);
      }
      setSaveButtonDisabled(false);
      // Console the updated array here
      console.log("Updated Rows Array:", updatedRowsArray);
    }
     else {
      setSaveButtonDisabled(true);
      console.log("Invalid quantity value");
    }
  };
  const createSTONew = async () => {
    console.log(" create sto function called ");
    try {
      // Check if there are any edited rows

      const hasEmptyFields = updatedRowsArray?.some(
        (row) => !row.transfer_qty || row.transfer_qty.trim() === ""
      );

      if (hasEmptyFields) {
        console.log(
          "Error: Please make sure all selected rows have a non-empty quantity."
        );
        // Display an error message and prevent saving
        setErrorMessage(
          "Please make sure all selected rows have a non-empty quantity."
        );
        setErrorPopupOpen(true);
        setSaveButtonDisabled(true);
        return;
      }
      // Map selected rows to create inv_list
      const inv_list = updatedRowsArray.map((row) => ({
        count: row.count,
        inv_id: row.inv_id,
        item_id: row.item_id,
        type: row.type,
        avl_qty: row.avl_qty,
        lot_no: row.lot_no,
        rate:row?.rate,
        warehouse_id: row.warehouse_id,
        flag: row.flag, // set flag to true
        transfer_qty: row.transfer_qty,
      }));
      console.log("inv list outside  POST function : ", inv_list);

      console.log("to warehouse id ", towarehouseId);
      console.log("from warehouse id ", fromwarehouseId);
      setStoLoading(true);
      // Determine the endpoint for creating a new transport
      const endpoint = API_ENDPOINTS.POST_WMS_STO_ADD_NEW(
        fromwarehouseId,
        towarehouseId,
        "pending",
        "stock"
      );
      console.log("API Endpoint:", endpoint);
      console.log("Creating Count Data:", updatedRowsArray);
      console.log("inv list in POST function : ", inv_list);

      // Send the POST request to create a new transport
      const response = await axiosInstance.post(
        endpoint,
        {
          inv_list: inv_list,
          
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Stock Transfer Order created successfully!");
        setSuccessPopupOpen(true);
        setOpenPerformSTO(false);
        setStoLoading(false);
        setCount((prevcount) => prevcount + 1);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the Stock Transfer Order ");
        setErrorMessage(
          "Failed to create the Stock Transfer Order. Please try again."
        );
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setStoLoading(false);
      console.error(
        "Error occurred while creating the Stock Transfer Order ",
        error
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Select",
        accessorKey: "select",
        size: 10,
        Cell: ({ row }) => (
          <Checkbox
            checked={Boolean(selectedRows[row.original.inv_id]?.flag)}
            //checked={Boolean(row.original.flag)}
            color="primary"
            onChange={() => handleRowClick(row)}
            sx={{ padding: "2px", "& .MuiSvgIcon-root": { fontSize: "15px" } }} // Adjust the padding and fontSize as needed
          />
        ),
      },
      {
        header: "Item",
        accessorKey: "sku_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Code",
        accessorKey: "sku_code",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Lot Number",
        accessorKey: "lot_no",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Type",
        accessorKey: "type",
        size: 10,
        enableEditing: false,
      },

      {
        header: "Available Qty",
        accessorKey: "avl_qty",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Transfer Qty",
        accessorKey: "transfer_qty",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const inputValue = inputValues[original?.inv_id] || "";

          return (
            <TextField
              
              value={ inputValues[original?.inv_id] !== null &&
                inputValues[original?.inv_id] !== undefined &&
                inputValues[original?.inv_id] !== ""
                  ? inputValues[original?.inv_id]
                  : ""}
              size="small"
              onChange={(e) => handleQuantityChange(e, original)}
              inputProps={{
                step: "any",
                max: original.avl_qty,
              }}
            />
          );
        },
      },
    ],
    [
      inputValues,
      selectedRows,
      sTOData,
      setAddedSKUMasterData,
      updatedRowsArray,
    ]
  );
  const handleClose = () => {
    setOpenPerformSTO(false);
  };
  return (
    <>
    <Dialog
        maxWidth="lg"
        open={true}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <DialogTitle sx={{ position: "sticky", height: "8vh" }}>
          
       
      
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "row", // Stack items vertically
              alignItems: "center",
              marginTop: "1%",
            }}
          >
            <Typography variant="h2" color={colors.green.DEFAULT}>
              Stock Transfer Order
            </Typography>
          </Grid>
        </Grid>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 1.9,
              top: 1,
              color: "grey",
            }}
          >
            <CloseSharpIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          { stoLoading ? (
            <>  <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={stoLoading} />
          </div>
          </>
          ) : (
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "100%",
            }}
          >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "10vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                  <InputLabel>From Warehouse</InputLabel>
                  <Select
                    label="From Warehouse"
                    value={fromwarehouseId}
                    onChange={(e) => {
                      setfromWarehouseId(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Warehouse" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <CiCalendarDate />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {Array.isArray(warehouseData) &&
                      warehouseData.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                  <InputLabel>To Warehouse</InputLabel>
                  <Select
                    label="To Warehouse"
                    value={towarehouseId}
                    disabled={!fromwarehouseId}
                    onChange={(e) => {
                      setToWarehouseId(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Warehouse" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <CiCalendarDate />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {Array.isArray(secondwarehouseData) &&
                      secondwarehouseData.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={0.8}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline />}
                  sx={{
                    backgroundColor: "#4a3676",
                    color: "#ffffff",
                  }}
                  size="small"
                   onClick={handleApplyClick}
                  disabled={!towarehouseId }
                >
                  Apply
                </Button>
              </Grid> */}
              <Grid item xs={12} sm={0.8}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={4.8} md={4.8}></Grid>
              <Grid item xs={12} sm={2.4} md={2.4} textAlign="right">
                {!saveButtonDisabled && (
                  <Button
                    variant="contained"
                    startIcon={
                      <IoSaveOutline
                        style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                      />
                    }
                    sx={{
                      borderColor: colors.green.DEFAULT,
                      backgroundColor: colors.green.DEFAULT,
                      color: "#ffff",
                      marginRight: "16px",
                    }}
                    onClick={() => {
                      console.log("Button clicked");
                      createSTONew();
                    }}
                  >
                    <Typography variant="h5" color={colors.white[100]}>
                      Save
                    </Typography>
                  </Button>
                )}
                {/* <Button
                  variant="contained"
                  startIcon={
                    <IoArrowBackCircleOutline
                      style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffff",
                  }}
                  onClick={handleBackToSTOViewPage}
                >
                  <Typography variant="h5" color={colors.white[100]}>
                    Back
                  </Typography>
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={containerStyle8}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) :  (
          <>
          {(fromwarehouseId === null || towarehouseId === null ) ? (
            <NullScreen
              containerheight="70vh"
              containerWidth="100%"
              screenType="Info"
              message="Select Warehouse"
            />
          ) : (
            <>
        {  sTOData?.length === 0 ? (
            <NullScreen containerheight="80vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
              <Grid item sm={12} xs={12} md={12}>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    columns={columns}
                    data={sTOData}
                    enableGrouping
                    getRowId={(row) => row?.inv_id}
                    onRowSelectionChange={onRowSelectionChange}
                    state={{ rowSelection }}
                    enableEditing={false}
                    enableStickyHeader
                    enableRowVirtualization
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    editingMode="table"
                    enablePagination={false}
                    initialState={{
                      density: "compact",
                      pagination: { pageIndex: 0, pageSize: 100 },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        fontWeight: "650",
                        color: "#fff",
                        backgroundColor: "#34519fe6",
                        fontSize: "12px",
                      },
                    }}
                    muiTableContainerProps={{ sx: { maxHeight: "65vh" } }}
                    muiToolbarAlertBannerChipProps={{ color: "primary" }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: "#ffff",
                        },
                      },
                    }}
                  />
                </ThemeProvider>
                </Grid>
              </div>
            </>
            )}
                    </>
                  )}
                </>
              )}
        </Grid>
        </Box>
        )}
        </DialogContent>
      </Dialog>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
      <AlertDialogBox
      open={errorPopupOpen3}
      okayText="Okay"
      handleOkayClick={() => setErrorPopupOpen3(false)}
      onClose={() => setErrorPopupOpen3(false)}
      content={errorMessage3}
    />
    </>
  );
};

export default STOAdd;
