import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "./WMStheme";
import InventoryTable from "../Modules/CropManagement/GridDisplay/Live/Live";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import "./GRNMainPage.css";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  createTheme,
  ThemeProvider,
  TextField,
  MenuItem,
  IconButton,
  TableHead,
  TableCell,
  TableRow,
  InputLabel,
  Select,
  Stack,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import SendIcon from "@mui/icons-material/Send";
import StagesImg from "../Assets/ConfigurationImg/grades.png";
import { MaterialReactTable } from "material-react-table";
import Divider from "@mui/material/Divider";
import ViewListIcon from "@mui/icons-material/ViewList";

const dummyGridData = [
  {
    invoice_number: "B-1950",
    vendor_id: "V16",
    vendor_name: "Fambo innovations",
    reference_number: "PO1003",
    date: "14-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "100.00",
        rate: "22.00",
        rcvdQty: "95.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1951",
    vendor_id: "V02",
    vendor_name: "Fruits Corporations",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1953",
    vendor_id: "V04",
    vendor_name: "BAGGA RAM RAJESH KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1954",
    vendor_id: "V03",
    vendor_name: "ARSHAD FRUIT COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "100.00",
        rate: "21.50",
        rcvdQty: "95.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "150.00",
        rate: "18.00",
        rcvdQty: "150.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1955",
    vendor_id: "V05",
    vendor_name: "BEDAPRKASH",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1956",
    vendor_id: "V06",
    vendor_name: "BILAL FRUIT CO",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1957",
    vendor_id: "V07",
    vendor_name: "CHANDAN KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1959",
    vendor_id: "V10",
    vendor_name: "PARASNATH AGRIFOODS PRIVATE LIMITED",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1960",
    vendor_id: "V12",
    vendor_name: "JAI MAA BHAGWATI & COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1966",
    vendor_id: "V15",
    vendor_name: "FRESH AGRI PRODUCTS",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1967",
    vendor_id: "V03",
    vendor_name: "ARSHAD FRUIT COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1968",
    vendor_id: "V05",
    vendor_name: "BEDAPRKASH",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1969",
    vendor_id: "V07",
    vendor_name: "CHANDAN KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1970",
    vendor_id: "V10",
    vendor_name: "PARASNATH AGRIFOODS PRIVATE LIMITED",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1971",
    vendor_id: "V03",
    vendor_name: "ARSHAD FRUIT COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1972",
    vendor_id: "V12",
    vendor_name: "JAI MAA BHAGWATI & COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1973",
    vendor_id: "V05",
    vendor_name: "BEDAPRKASH",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1974",
    vendor_id: "V04",
    vendor_name: "BAGGA RAM RAJESH KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1975",
    vendor_id: "V04",
    vendor_name: "BAGGA RAM RAJESH KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
];

const dummyGridDataUpdated = [
  {
    invoice_number: "B-1950",
    vendor_id: "V16",
    vendor_name: "Fambo innovations",
    reference_number: "PO1003",
    date: "14-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "100.00",
        rate: "22.00",
        rcvdQty: "95.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1951",
    vendor_id: "V02",
    vendor_name: "Fruits Corporations",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1953",
    vendor_id: "V04",
    vendor_name: "BAGGA RAM RAJESH KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1954",
    vendor_id: "V03",
    vendor_name: "ARSHAD FRUIT COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "100.00",
        rate: "21.50",
        rcvdQty: "95.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "150.00",
        rate: "18.00",
        rcvdQty: "150.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1955",
    vendor_id: "V05",
    vendor_name: "BEDAPRKASH",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1956",
    vendor_id: "V06",
    vendor_name: "BILAL FRUIT CO",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1957",
    vendor_id: "V07",
    vendor_name: "CHANDAN KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1959",
    vendor_id: "V10",
    vendor_name: "PARASNATH AGRIFOODS PRIVATE LIMITED",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1960",
    vendor_id: "V12",
    vendor_name: "JAI MAA BHAGWATI & COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1966",
    vendor_id: "V15",
    vendor_name: "FRESH AGRI PRODUCTS",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1967",
    vendor_id: "V03",
    vendor_name: "ARSHAD FRUIT COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1968",
    vendor_id: "V05",
    vendor_name: "BEDAPRKASH",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1969",
    vendor_id: "V07",
    vendor_name: "CHANDAN KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1970",
    vendor_id: "V10",
    vendor_name: "PARASNATH AGRIFOODS PRIVATE LIMITED",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1971",
    vendor_id: "V03",
    vendor_name: "ARSHAD FRUIT COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1972",
    vendor_id: "V12",
    vendor_name: "JAI MAA BHAGWATI & COMPANY",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1973",
    vendor_id: "V05",
    vendor_name: "BEDAPRKASH",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1974",
    vendor_id: "V04",
    vendor_name: "BAGGA RAM RAJESH KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
  {
    invoice_number: "B-1975",
    vendor_id: "V04",
    vendor_name: "BAGGA RAM RAJESH KUMAR",
    reference_number: "PO1003",
    date: "13-12-2023",
    orderItems: [
      {
        lineAmount: "2090.0000",
        order_qty: "1000.00",
        rate: "22.00",
        rcvdQty: "980.00",
        sku_code: "6220134",
        sku_name: "Cucumber Hybrid - ( Fresh)",
        uom: "KG",
      },
      {
        lineAmount: "2090.0000",
        order_qty: "1500.00",
        rate: "18.00",
        rcvdQty: "1495.00",
        sku_code: "630035",
        sku_name: "Onion",
        uom: "KG",
      },
    ],
  },
];

function GRNMainPage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const GRNGrid = () => {
    const [rows, setRows] = useState(dummyGridData);

    const handleSubmitClick = () => {
      setRows(dummyGridDataUpdated);
      handleClose();
    };

    const handleClose = () => {
      setIsDialogOpen(false);
    };

    const [OrderData, setOrderData] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const globalTheme = useTheme();

    const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
            primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
            info: {
              main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
            },
            background: {
              default:
                globalTheme.palette.mode === "light"
                  ? "#E4F1F640" //random light yellow color for the background in light mode
                  : "#000", //pure black table in dark mode for fun
            },
          },
          typography: {
            button: {
              variant: "contained", //customize typography styles for all buttons in table by default
              fontSize: "0.8rem",
              color: "#2c677b",
            },
            fontFamily: "Lexend Deca", // Add your desired font family here
            fontWeightRegular: 400, // Set the desired font weight for regular entries
            fontWeightBold: 700, // Set the desired font weight for bold entries
            fontSize: 12,
          },
          components: {
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  fontSize: "0.8rem", //override to make tooltip font size larger
                },
              },
            },
            MuiSwitch: {
              styleOverrides: {
                thumb: {
                  color: "#2c677b", //change the color of the switch thumb in the columns show/hide menu to pink
                },
              },
            },
          },
        }),
      [globalTheme]
    );

    const columns = useMemo(
      () => [
        {
          header: "Order Items",
          size: 4,
          enableEditing: false,

          Cell: ({ cell }) => {
            const handleClick = () => {
              setOrderData(cell.row.original);
              setIsDialogOpen(true);
            };
            return (
              <div className="tooltip-cell" title="Click to view Order Items ">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleClick}
                  endIcon={<ViewListIcon />}
                  sx={{ backgroundColor: colors.green.DEFAULT }}
                >
                  <Typography variant="h7" color={colors.white.DEFAULT}>
                    View
                  </Typography>
                </Button>
                <span className="farm-name">{cell.getValue()}</span>
              </div>
            );
          },
        },
        {
          header: "Vendor Name",
          accessorKey: "vendor_name",
          size: 40,
        },
        {
          header: "Vendor Id",
          accessorKey: "vendor_id",
          size: 20,
        },
        {
          header: "Date",
          accessorKey: "date",
          size: 20,
        },

        {
          header: "PO Number",
          accessorKey: "reference_number",
          size: 20,
        },
      ],
      []
    );

    const OrderDialog = ({ OrderData }) => {
      const newData = [OrderData];

      console.log("OrderData-------------", OrderData);
      console.log("newData-------------", newData);

      const handleQuantityChange = (orderIndex, itemIndex, newValue) => {
        const updatedData = [...newData]; // Copy the array
        updatedData[orderIndex].orderItems[itemIndex].rcvdQty = newValue; // Update the received quantity
      };

      return (
        <Dialog open={isDialogOpen} onClose={handleClose}>
          <DialogTitle>
            <Typography
              variant="h2"
              style={{ marginLeft: "0.1%" }}
              color={colors.green.DEFAULT}
            >
              Edit Received Quantity:
            </Typography>
          </DialogTitle>
          <DialogContent>
            {newData?.map((order, index) => (
              <div key={index}>
                <List>
                  <ListItem style={{ marginLeft: "-3%" }}>
                    <ListItemText primary={`Date of Order: ${order?.date}`} />
                  </ListItem>
                  {order?.orderItems?.map((orderItem, idx) => (
                    <div key={idx}>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        display="flex"
                        key={index}
                        style={{ marginTop: "1%" }}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={4}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="SKU Name"
                            fullWidth
                            variant="standard"
                            disabled
                            size="small"
                            margin="normal"
                            value={orderItem?.sku_name}
                            placeholder="SKU Name"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={2}
                          md={2}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="UOM"
                            fullWidth
                            variant="standard"
                            disabled
                            size="small"
                            margin="normal"
                            value={orderItem?.uom}
                            placeholder="UOM"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          sm={2}
                          md={2}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="SKU Code"
                            fullWidth
                            variant="standard"
                            disabled
                            size="small"
                            margin="normal"
                            value={orderItem?.sku_code}
                            placeholder="SKU Code"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={2}
                          md={2}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="Order Qty"
                            fullWidth
                            variant="standard"
                            disabled
                            size="small"
                            margin="normal"
                            value={orderItem?.order_qty}
                            placeholder="Order Qty"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={2}
                          md={2}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="Received Qty."
                            fullWidth
                            variant="standard"
                            size="small"
                            type="number"
                            margin="normal"
                            value={orderItem?.rcvdQty}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              handleQuantityChange(index, idx, newValue);
                            }}
                            placeholder="Received Qty."
                            inputProps={{
                              style: { width: "100%" },
                              min: 0,
                              max: 100000,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </List>
                <Divider
                  sx={{
                    borderBottom: "2px solid #000000", // Adjust the border color as needed
                    margin: "16px 0", // Adjust the margin as needed
                  }}
                  variant="middle"
                />
              </div>
            ))}
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  style={{
                    height: "32px",
                    marginRight: "0%",
                    marginLeft: "69%",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    style={{ float: "left", marginLeft: "15px" }}
                  >
                    <Typography variant="h6" color={colors.green.DEFAULT}>
                      Cancel
                    </Typography>
                  </Button>
                  <Button
                    onClick={handleSubmitClick}
                    variant="contained"
                    sx={{ backgroundColor: colors.green.DEFAULT }}
                    style={{ float: "left" }}
                  >
                    <Typography variant="h6" color={colors.white.DEFAULT}>
                      Submit
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      );
    };

    return (
      <>
        <Grid sx={{ marginTop: "2%" }}>
          <div>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={rows}
                enableGrouping
                enableStickyHeader
                enableRowVirtualization
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                // enablePagination={false}
                enableColumnFilters={false}
                initialState={{
                  density: "compact",
                  pagination: { pageIndex: 0, pageSize: 8 },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    fontWeight: "650",
                    color: "#2c677b",
                    fontSize: "15px",
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    overflow: "hidden",
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#f8fafc",
                    },
                  },
                }}
                muiToolbarAlertBannerChipProps={{ color: "primary" }}
              />
            </ThemeProvider>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Pagination count={27} size="small" />
            </div> */}
          </div>
          <OrderDialog OrderData={OrderData} />
        </Grid>
      </>
    );
  };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
        }}
      >
        <Typography
          variant="h1"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "2.6%" }}
        >
          Goods Receipt Note (GRN)
        </Typography>
      </Grid>

      <GRNGrid />
    </div>
  );
}

export default GRNMainPage;
