
// reducers.js
const initialState = {
    action: '',
    familyData: null,
  };
  export const familyReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FAMILY_ACTION':
        return {
          action: action.payload.action,
          familyData: action.payload.familyData,
        };
      default:
        return state;
    }
  };