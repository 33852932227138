import React, { useState, useEffect, useRef, useMemo } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { Label } from "semantic-ui-react";
import { GiFamilyTree } from "react-icons/gi";
import FamilyTransfer from "./FamilyTransfer";
import STOProgressPopUp from "../Reports/STOProgressPopUp";
import STOMiddleWareDialog from "./STOMiddleWareDialog";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ulid } from "ulid";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import NullScreen from "../PopUpMsg/NullScreen";
import { IoMdClose } from "react-icons/io";
import { ScaleLoader } from "react-spinners";
import { CiCalendarDate } from "react-icons/ci";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import Pagination from "@mui/material/Pagination";
import { MaterialReactTable } from "material-react-table";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IoSaveOutline } from "react-icons/io5";
import { CgShutterstock } from "react-icons/cg";
import SocketReportPopup from "../../Attendance/HomePage/SocketReportPopup";
import {
  Grid,
  Stack,
  Button,
  List,
  Checkbox,
  ListItem,
  ListItemText,
  Select,
  ThemeProvider,
  Box,
  createTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  InputLabel,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { TiTickOutline } from "react-icons/ti";
import { GiFruitBowl } from "react-icons/gi";
import Paper from "@mui/material/Paper";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GrChapterAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import { tokens } from "../WMStheme";
import STOAdd from "./STOAdd";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const StatusTag = ({ status }) => {
  let tagColor;
  let statusLabel;

  switch (status) {
    case "received":
      tagColor = "#0B823A";
      statusLabel = "Received";
      break;
    case "pending":
      tagColor = "#DE3721";
      statusLabel = "Pending";
      break;
    default:
      tagColor = "#B8BCED";
      statusLabel = "Received";
      break;
  }

  return (
    <Label
      style={{ backgroundColor: tagColor, color: "white" }}
      tag
      size="small"
    >
      {statusLabel}
    </Label>
  );
};
const columns = [
  { id: "status", label: "Status" },
  { id: "created_at", label: " Created At" },
  { id: "sto_no", label: "STO No." },
  { id: "from_wh_name", label: "From Warehouse" },
  { id: "to_wh_name", label: "To Warehouse" },
  { id: "channel", label: "Channel" },
  { id: "created_by_name", label: " Created By" },
];
const columns1 = [
  { id: "sku_name", label: "Name" },
  { id: "sku_code", label: "Code" },
  { id: "from_qty", label: "From Quantity" },
  { id: "transfer_qty", label: "Transfer Quantity" },

  { id: "lot_no", label: "Lot Number" },
];
const gridContainerStyle = {
  // marginTop:"1%",
  display: "flex",
  height: "70vh",
  width: "100%",
  overflowY: "hidden",

  //  backgroundColor: "red",
};
const containerStyle8 = {
  maxHeight: "74vh",
  height: "100%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  //  backgroundColor: "pink",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};

function TableHeadComponent() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((column) => (
          <StyledTableCell key={column?.id} align="center">
            {column?.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function TableRowsComponent({
  data1,
  datasending,
  HistoryDialog,
  setHistoryDialog,
  selectedRow,
  setStoId,
  setChannel,
  stoId,
  setSelectedRow,
}) {
  const theme = useTheme();

  const handleRowClick = (row) => {
    setSelectedRow(row?.sto_id);
    console.log("selected row data in tbalerow function ", row.sto_id);
    // navigate("/addpo");
    setChannel(row?.channel);
    setStoId(row?.sto_id);
    setHistoryDialog(true);
  };

  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const handleSuccess = (newState) => {
    setApiSuccessOpen(newState);
  };

  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const handleFailure = (newState) => {
    setApiErrorOpen(newState);
  };

  const dialogRef = useRef(null);

  return (
    <>
      <TableBody>
        {data1?.map((row, index) => (
          <StyledTableRow
            key={index}
            onClick={() => handleRowClick(row)}
            style={{ cursor: "pointer" }}
          >
            {columns?.map((column) => (
              <StyledTableCell key={column?.id} align="center">
                {column?.id === "status" ? (
                  <StatusTag status={row[column?.id]} />
                ) : column?.id === "created_at" ? (
                  formatDateIST(row[column?.id])
                ) : (
                  row[column.id]
                )}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </>
  );
}
function TableHeadComponentDialog() {
  return (
    <TableHead>
      <TableRow>
        {columns1?.map((column) => (
          <StyledTableCell key={column?.id} align="center">
            {column?.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const formatDateIST = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} || ${hours}:${minutes}`;
};

function TableRowsComponentDialog({ data1 }) {
  const navigate = useNavigate();

  const theme = useTheme();

  return (
    <>
      <TableBody>
        {/* replace data1 from dummyData  */}
        {data1?.map((row, index) => (
          <StyledTableRow key={index} style={{ cursor: "pointer" }}>
            {columns1?.map((column) => (
              <StyledTableCell key={column?.id} align="center">
                {["created_at", "updated_at"].includes(column.id)
                  ? formatDateIST(row[column.id])
                  : row[column.id]}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </>
  );
}
const STOList = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const oneWeekAgo = new Date();
  const [skuNames, setSkuNames] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [HistoryDialog, setHistoryDialog] = useState(false);
  const [skuId, setSkuId] = useState(null);
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const [inputValues, setInputValues] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  //const [stoData, setSTOData] = useState(dummyData);
  const [stoData, setSTOData] = useState([]);
  const [stoEdit, setStoEdit] = useState([]);
  // const [sTOData, setSTOData] = useState([]);
  const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
  const [addedSKUMasterDatas, setAddedSKUMasterData] = useState([]);
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorPopupOpen3, setErrorPopupOpen3] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isdailogLoading, setIsDialogLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [warehouseId, setWarehouseData] = useState(null);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [openPerformSTO, setOpenPerformSTO] = useState(false);
  const [openFamilyTransfer, setOpenFamilyTransfer] = useState(false);
  const [stoId, setStoId] = useState(null);
  const [generatedId, setGeneratedId] = useState(null);
  const [openDialogforDownload, setOpenDialogforDownload] = useState(false);
  const [stochannel, setStoChannel] = useState("stock");
  const [allowdownload, setAllowDownload] = useState(false);
  const [channel, setChannel] = useState(null);
  const [selectedRowsState, setSelectedRowsState] = useState([]);
  const [stoLoading, setStoLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [stoEditStoData, setStoEditStoData] = useState([]);
  const [count, setCount] = useState(1);
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [selectedSTOData, setSelectedSTOData] = useState([]);
  const [stoDownloadData, setStoDownloadData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [openSTODownloadPopUp, setOpenSTODownloadPopUp] = useState(false);
  const [filterValues, setFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    channel: stochannel,
    limit: 12,
    offset: offset,
    download: 0,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    channel: stochannel,
    limit: 12,
    offset: offset,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,
      channel: stochannel,
      limit: 12,
      offset: offset,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, offset, stochannel]);
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#0A0A0A",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#040404", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  useEffect(() => {
    fetchSTOData(filterValues);
  }, [filterValues, count]);

  const handleApplyClick = () => {
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      channel: temporaryFilterValues.channel,
      limit: 12,
      offset: offset,
    };
    setStartDate(temporaryFilterValues?.start_date);
    setEndDate(temporaryFilterValues?.end_date);
    setStoChannel(temporaryFilterValues?.channel);
    setFilterValues(newFilterValues);
    fetchSTOData(newFilterValues);
    setAllowDownload(true);
  };
  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchSTOData(updatedValues);
  };
  useEffect(() => {
    // Check if cycle_count_id is provided
    if (!stoId) {
      console.error("sto_id is required");
      setErrorMessage("Oops! Something went Wrong!");
      return;
    }

    // Execute the function when cycle_count_id changes
    fetchSTOHistoryData(stoId);
  }, [stoId, count]);

  const handlePerformSTOClick = () => {
    // navigate("/addsto");
    setOpenPerformSTO(true);
  };

  const fetchSTOHistoryData = async (stoId) => {
    setIsDialogLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_STO_DETAIL_OF_STO(stoId, channel),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsDialogLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data;
        console.log("data", data);
        setStoEditStoData(data[0]);
        setStoDownloadData(data[0]);
        setSelectedSTOData(data[0]?.sto_sku);
        // setTotalPages(data?.total_pages);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsDialogLoading(false);
      setErrorMessage("Please Try Again");
      setErrorPopupOpen(true);
    }
  };
  const columnsSTO = useMemo(
    () => [
      {
        header: "Select",
        accessorKey: "select",
        size: 10,
        Cell: ({ row }) => (
          <Checkbox
            // checked={Boolean(selectedRows[row.original.inv_id]?.flag)}
            checked={true}
            color="primary"
            onChange={() => handleRowClick(row)}
            sx={{
              padding: "2px",
              "& .MuiSvgIcon-root": { fontSize: "15px" },
            }} // Adjust the padding and fontSize as needed
          />
        ),
      },
      {
        header: "Item",
        accessorKey: "sku_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Code",
        accessorKey: "sku_code",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Lot Number",
        accessorKey: "lot_no",
        size: 10,
        enableEditing: false,
      },

      {
        header: "Transfer Qty",
        accessorKey: "transfer_qty",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Difference Qty",
        accessorKey: "difference_qty",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Received Qty",
        accessorKey: "received_qty",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const inputValue = inputValues[original?.inv_id] || "";
          console.log(
            "stoEditStoData?.status----->>>>>>>>>>>> ",
            stoEditStoData?.status
          );
          return (
            <TextField
              value={
                inputValues[original?.inv_id] !== null &&
                inputValues[original?.inv_id] !== undefined &&
                inputValues[original?.inv_id] !== ""
                  ? inputValues[original?.inv_id]
                  : row.original.received_qty
              }
              size="small"
              type="number"
              onChange={(e) => handleQuantityChange(e, original)}
              inputProps={{
                step: "any",
              }}
              key={count}
              disabled={stoEditStoData?.status === "received"}
            />
          );
        },
      },
    ],
    [
      inputValues,
      selectedRows,
      selectedSTOData,
      setAddedSKUMasterData,
      updatedRowsArray,
    ]
  );
  const columnsFamily = useMemo(
    () => [
      {
        header: "Family",
        accessorKey: "family_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Family Code",
        accessorKey: "family_code",
        size: 10,
        enableEditing: false,
      },
      {
        header: "From SKU",
        accessorKey: "sku_name_from",
        size: 10,
        enableEditing: false,
      },
      {
        header: "UOM",
        accessorKey: "sku_uom_to",
        size: 10,
        enableEditing: false,
      },
      {
        header: "To SKU",
        accessorKey: "sku_name_to",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Transfer Qty",
        accessorKey: "transfer_qty",
        size: 10,
        enableEditing: false,
      },
    ],
    []
  );
  const handleCloseHistoryDialog = () => {
    setHistoryDialog(false);
  };
  const handleRowClick = (row) => {
    if (row.original) {
      const skuId = row?.original?.inv_id;
      const selectedRow = {
        count: row?.original.count,
        inv_id: skuId,
        item_id: row.original.item_id,
        type: row?.original?.type,
        avl_qty: row.original?.avl_qty,
        lot_no: row.original?.lot_no,
        rate: row?.original?.rate,
        warehouse_id: row.original?.warehouse_id,
        flag: !selectedRows[skuId]?.flag,
        transfer_qty: row.original?.transfer_qty,
        received_qty: inputValues[skuId] || 0,
      };

      setSelectedRows((prevSelectedRows) => {
        const updatedRows = { ...prevSelectedRows };
        updatedRows[skuId] = selectedRow;
        return updatedRows;
      });
    } else {
      console.error("Row data is undefined or missing 'original' property");
    }
  };
  const onRowSelectionChange = (selectedRowIds) => {
    const selectedIdsArray = Array.isArray(selectedRowIds)
      ? selectedRowIds
      : Object.keys(selectedRowIds);

    const updatedRows = selectedSTOData
      ?.filter((row) => selectedIdsArray.includes(row.inv_id))
      .map((row) => ({
        count: row.count,
        inv_id: row.inv_id,
        item_id: row.item_id,
        type: row.type,
        rate: row?.rate,
        avl_qty: row.avl_qty,
        lot_no: row.lot_no,
        warehouse_id: row.warehouse_id,
        flag: !row.flag,
        transfer_qty: row.transfer_qty,
        received_qty: inputValues[row.inv_id] || 0,
      }));

    setSelectedRows(updatedRows);
    setSelectedRowIds(selectedIdsArray);
    setSelectedRowsState(updatedRows);
    console.log("selected rows ", updatedRows);
  };
  const handleQuantityChange = (e, original) => {
    let { value } = e.target;
    const parts = value.toString().split(".");
    const fromQty = original?.transfer_qty;

    if (value <= fromQty) {
      if (parts?.length >= 0 && parts[1]?.length > 2) {
        // Convert to maximum 2 decimal places
        setErrorMessage3(
          "Please enter Transfer quantity upto 2 decimal place only ."
        );

        setErrorPopupOpen3(true);

        value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
        console.log("value here after decimal round off ", value);
      } else if (value < 0) {
        console.log("order_qty", original?.received_qty);

        setErrorMessage3("Transfer quantity should  be greater than 0.");

        setErrorPopupOpen3(true);
        value = 0;
      }
      // Update the input values state
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [original.inv_id]: parseFloat(value),
      }));

      // Update the selected rows with the new flag
      setSelectedRows((prevSelectedRows) => ({
        ...prevSelectedRows,
        [original.inv_id]: { flag: true },
      }));

      // Add or update the row in the array
      const updatedRow = {
        ...original,
        received_qty: parseFloat(value),
        flag: true,
      };
      const index = updatedRowsArray.findIndex(
        (row) => row?.inv_id === original?.inv_id
      );

      if (index !== -1) {
        // If row exists in the array, update it
        setUpdatedRowsArray((prevArray) => [
          ...prevArray.slice(0, index),
          updatedRow,
          ...prevArray.slice(index + 1),
        ]);
      } else {
        // If row doesn't exist in the array, add it
        setUpdatedRowsArray((prevArray) => [...prevArray, updatedRow]);
      }
      setSaveButtonDisabled(false);
      // Console the updated array here
      console.log("Updated Rows Array:", updatedRowsArray);
    } else {
      setSaveButtonDisabled(true);
      console.log("Invalid quantity value");
    }
  };
  const createSTONew = async () => {
    console.log(" create sto function called in the function  ");
    try {
      // Check if there are any edited rows

      // const hasEmptyFields = updatedRowsArray?.some((row) => !row.received_qty);

      // if (hasEmptyFields) {
      //   console.log(
      //     "Error: Please make sure all selected rows have a non-empty quantity."
      //   );
      //   // Display an error message and prevent saving
      //   setErrorMessage(
      //     "Please make sure all selected rows have a non-empty quantity."
      //   );
      //   setErrorPopupOpen(true);
      //   setSaveButtonDisabled(true);
      //   return;
      // }
      // Map selected rows to create inv_list
      const inv_list = updatedRowsArray?.map((row) => ({
        count: row.count,
        inv_id: row.inv_id,
        item_id: row.item_id,
        type: row.type,
        avl_qty: row.avl_qty,
        lot_no: row.lot_no,
        rate: row?.rate,
        sto_id: stoEditStoData?.sto_id,
        sto_sku_id: row?.sto_sku_id,
        warehouse_id: stoEditStoData?.to_wh_id,
        flag: row.flag, // set flag to true
        transfer_qty: row.transfer_qty,
        received_qty: row.received_qty,
      }));
      console.log("inv list outside  POST function : ", inv_list);

      setStoLoading(true);
      // Determine the endpoint for creating a new transport
      const endpoint = API_ENDPOINTS.POST_WMS_STO_ADD_NEW(
        stoEditStoData?.from_wh_id,
        stoEditStoData?.to_wh_id,
        "received",
        "stock"
      );
      console.log("API Endpoint:", endpoint);
      console.log("Creating Count Data:", updatedRowsArray);
      console.log("inv list in POST function : ", inv_list);

      // Send the POST request to create a new transport
      const response = await axiosInstance.post(
        endpoint,
        {
          inv_list: inv_list,
          sto_id: stoEditStoData?.sto_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Stock Transfer Order created successfully!");
        setSuccessPopupOpen(true);
        setOpenPerformSTO(false);
        setStoLoading(false);
        setHistoryDialog(false);
        setCount((prevcount) => prevcount + 1);
      } else {
        console.error("Failed to create the Stock Transfer Order ");
        setErrorMessage(
          "Failed to create the Stock Transfer Order. Please try again."
        );
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setStoLoading(false);
      console.error(
        "Error occurred while creating the Stock Transfer Order ",
        error
      );
    }
  };
  const handleFamilyTransferClick = () => {
    // navigate("/addsto");
    setOpenFamilyTransfer(true);
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };
  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const fetchSTOData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_STO_LIST_OF_ALL_STO,
        {
          params: { 
          ...newParams, 
          download: 0,
           },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data;
        console.log("data", data);
        setSTOData(data?.inv_list);

        setTotalPages(data?.total_pages);
        setIsLoading(false);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsLoading(false);
      setErrorMessage("Please Try Again");
      setErrorPopupOpen(true);
    }
  };
  const handleGenerateReportClick = async () => {
    setIsLoading(true);
    const requestId = ulid();
    try {
      const response = await axiosInstance.get(
       API_ENDPOINTS.GET_WMS_STO_LIST_OF_ALL_STO,
        {
          params: {
            ...filterValues,
            download: 1,
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialogforDownload(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  const handleRefreshClick = () => {
    const initialValues = {
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
      channel: "stock",
      limit: 12,
      offset: 1,
    };

    setSkuId(null);
    setSkuCode(null);
    setStoChannel('stock');
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    fetchSTOData(initialValues);
    setAllowDownload(false);
  };
  const getColumnsForChannel = (channel) => {
    if (channel === "stock") {
      return columnsSTO;
    } else if (channel === "family") {
      return columnsFamily;
    }
    return []; // Return an empty array if the channel is neither stock nor family
  };
  const columns = getColumnsForChannel(channel);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "10vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={2} sm={2} md={2}>
                <RangePicker
                  onChange={handleDateChange}
                  // size="large"
                  key={refreshCount}
                  format={dateFormat}
                  defaultValue={[
                    dayjs(formatDate(startDate), dateFormat),
                    dayjs(formatDate(endDate), dateFormat),
                  ]}
                />
              </Grid>
              <Grid item xs={2} sm={1.2} md={1.2}>
                <FormControl fullWidth>
                  <InputLabel>Channel</InputLabel>
                  <Select
                    label="Channel"
                    value={stochannel}
                    onChange={(e) => setStoChannel(e.target.value)}
                    displayEmpty
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Channel" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <CgShutterstock />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Channel
                    </MenuItem>
                    <MenuItem value="stock">Stock</MenuItem>
                    <MenuItem value="family">Family</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!startDate || !endDate || !stochannel}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>

              <Grid item xs={6} sm={6} md={6} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={
                    <FileDownloadIcon
                      style={{ color: "#ffffff", fontSize: "26px" }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffff",
                    marginRight: "16px",
                  }}
                  onClick={handleGenerateReportClick}
                  disabled={!allowdownload}
                >
                  <Typography variant="h4" color={colors.white[100]}>
                    Download
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  startIcon={
                    <GiFamilyTree
                      style={{ color: colors.white.DEFAULT, fontSize: "24px" }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffff",
                    marginRight: "1%",
                  }}
                  onClick={handleFamilyTransferClick}
                >
                  <Typography variant="h5" color={colors.white[100]}>
                    Family Transfer
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  startIcon={
                    <GrChapterAdd
                      style={{ color: colors.white.DEFAULT, fontSize: "24px" }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffff",
                  }}
                  onClick={handlePerformSTOClick}
                  disabled={
                    !userDetail?.b_stock_transfer_perform_stock_transfer
                  }
                >
                  <Typography variant="h5" color={colors.white[100]}>
                    Perform STO
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={containerStyle8}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : stoData?.length === 0 ? (
            <NullScreen containerheight="60vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 900 }} aria-label="customized table">
                    <TableHeadComponent />
                    <TableRowsComponent
                      data1={stoData}
                      HistoryDialog={HistoryDialog}
                      setHistoryDialog={setHistoryDialog}
                      selectedRow={selectedRow}
                      stoId={stoId}
                      setStoId={setStoId}
                      setChannel={setChannel}
                      setSelectedRow={setSelectedRow}
                    />
                  </Table>
                </TableContainer>
              </div>

              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: "1px" }}
              >
                <Pagination
                  count={totalPages}
                  page={offset}
                  onChange={handleChangePage}
                  size="small"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
       {openDialogforDownload && (
            <SocketReportPopup
              setOpenDialog={setOpenDialogforDownload}
              requestId={generatedId}
            />
          )}
      {openPerformSTO && (
        <STOAdd
          setOpenPerformSTO={setOpenPerformSTO}
          count={count}
          setCount={setCount}
        />
      )}
      {openFamilyTransfer && (
        <FamilyTransfer
          setOpenFamilyTransfer={setOpenFamilyTransfer}
          count={count}
          setCount={setCount}
        />
      )}
      {HistoryDialog && (
        <Dialog open={true} onClose={handleCloseHistoryDialog} fullScreen>
          <DialogTitle>
            <Grid item xs={12} sm={2}>
              {channel === "stock" ? (
                <Typography
                  variant="h2"
                  style={{ marginLeft: "2.1%", marginRight: "10.1%" }}
                  color="#0C0C0C"
                >
                  View STO Details( Fill received qty, Click Save. Action can be
                  performed only once.)
                </Typography>
              ) : (
                <Typography
                  variant="h2"
                  style={{ marginLeft: "2.1%", marginRight: "10.1%" }}
                  color="#0C0C0C"
                >
                  View STO Details
                </Typography>
              )}
            </Grid>
 {channel === "stock" && (
            <Grid container spacing={1.2} sx={{ marginTop: "1%" }}>
              <Grid item xs={12} sm={9.2} md={9.2} textAlign="right"></Grid>
             
                <>
                  <Grid item xs={12} sm={1.2} md={1.2}>
                    <Button
                      variant="contained"
                      startIcon={
                        <FileDownloadIcon
                          style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                        />
                      }
                      size="small"
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        backgroundColor: colors.green.DEFAULT,
                        color: "#ffff",
                        marginRight: "16px",
                      }}
                      onClick={() => setOpenSTODownloadPopUp(true)}
                      disabled={stoEditStoData?.status === "Received"}
                    >
                      <Typography variant="h5" color={colors.white[100]}>
                        Download
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={0.8} md={0.8}>
                    <Button
                      variant="contained"
                      startIcon={
                        <IoSaveOutline
                          style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                        />
                      }
                      size="small"
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        backgroundColor: colors.green.DEFAULT,
                        color: "#ffff",
                        marginRight: "16px",
                      }}
                      onClick={() => {
                        createSTONew();
                        console.log("Button clicked");
                      }}
                      disabled={stoEditStoData?.status === "received"}
                    >
                      <Typography variant="h5" color={colors.white[100]}>
                        Save
                      </Typography>
                    </Button>
                  </Grid>
                  
                    <Grid item xs={12} sm={0.8} md={0.8} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={
                    <IoArrowBackCircleOutline style={{ fontSize: 26 }} />
                  }
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleCloseHistoryDialog}
                >
                  Back
                </Button>
              </Grid> 
                </>
             
                </Grid>
                 )}
              {channel === "family" && 
                    ( <>
                      <Grid container spacing={1.2} sx={{ marginTop: "1%" }}>
                     <Grid item xs={12} sm={11.2} md={11.2} textAlign="right"></Grid>
                     <Grid item xs={12} sm={0.8} md={0.8} >
                <Button
                  variant="contained"
                  startIcon={
                    <IoArrowBackCircleOutline style={{ fontSize: 26 }} />
                  }
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleCloseHistoryDialog}
                >
                  Back
                </Button>
              </Grid> 
              </Grid>
              </>)}
            
          </DialogTitle>
          <DialogContent
            style={{ marginTop: "2%", width: "100%", height: "100%" }}
          >
            <>
              <Grid container spacing={1} style={containerStyle8}>
                {isdailogLoading ? (
                  <div style={loaderContainerStyle}>
                    <ScaleLoader color="#495057" loading={isdailogLoading} />
                  </div>
                ) : selectedSTOData?.length === 0 ? (
                  <NullScreen containerheight="74vh" containerWidth="100%" />
                ) : (
                  <>
                    <div style={{ width: "100%" }}>
                      <Grid item sm={12} xs={12} md={12}>
                        <ThemeProvider theme={tableTheme}>
                          <MaterialReactTable
                            columns={columns}
                            data={selectedSTOData}
                            enableGrouping
                            getRowId={(row) => row?.inv_id}
                            onRowSelectionChange={onRowSelectionChange}
                            state={{ rowSelection }}
                            enableEditing={false}
                            enableStickyHeader
                            enableRowVirtualization
                            enableFullScreenToggle={false}
                            enableDensityToggle={false}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            editingMode="table"
                            enablePagination={false}
                            enableGlobalFilter={true}
                            initialState={{
                              density: "compact",
                              pagination: { pageIndex: 0, pageSize: 100 },
                            }}
                            muiTableHeadCellProps={{
                              sx: {
                                fontWeight: "650",
                                color: "#fff",
                                backgroundColor: "#34519fe6",
                                fontSize: "12px",
                              },
                            }}
                            muiTableContainerProps={{
                              sx: { maxHeight: "74vh" },
                            }}
                            muiToolbarAlertBannerChipProps={{
                              color: "primary",
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor: "#ffff",
                                },
                              },
                            }}
                          />
                        </ThemeProvider>
                      </Grid>
                    </div>
                  </>
                )}
              </Grid>
            </>
          </DialogContent>
        </Dialog>
      )}
      {openSTODownloadPopUp && (
        <STOProgressPopUp
          setOpenDialog={setOpenSTODownloadPopUp}
          stoData={stoDownloadData}
        />
      )}
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default STOList;
