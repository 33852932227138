import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Delete } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import SaveAlert from "../../../../SaveAlert";
import {
  MenuItem,
  Dialog,
  Grid,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ScaleLoader } from "react-spinners";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Box,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";

import { tokens } from "../../../../theme";

import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const FarmerView = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  // console.log("Detailssssssssssssssssssssssssssssssss---->>>>", userDetails);
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [saveError, setSaveError] = useState(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false); // State for the confirmation dialog
  const [employeeToDelete, setEmployeeToDelete] = useState(null); // State to hold employee data for deletion
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const [filteredRows, setFilteredRows] = useState([]);
  const handleButtonClick = () => {
    navigate("/mainpage");
  };

  const GenderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];
  const fetchFarmerData = useCallback(async () => {
    axiosInstance
      .get(API_ENDPOINTS.GET_CONFIGURATION_FARMER, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response?.data;
        console.log("!!!!!!!!!", data?.rows);

        const formattedData = data?.rows?.map((item) => {
          const formatteddob = moment(item?.employee_dob).format("DD/MM/YYYY");
          console.log("formateddob", formatteddob);

          return {
            ...item,
            formatteddob,
          };
        });
        setRows(formattedData);
        console.log("iewxfhgvuijubugwe", formattedData);
        // console.log("formatted date ::::::::",formatteddob);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setApiErrorOpen(true);
      });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchFarmerData();
    const loaderTimer = setTimeout(() => {
      setIsLoading(false);
    }, 30000); // Display loader for 3 seconds

    fetchFarmerData();

    return () => clearTimeout(loaderTimer);
  }, [fetchFarmerData]);
  // const getCommonEditTextFieldProps = useCallback(
  //   (cell) => {
  //     return {
  //       error: !!validationErrors[cell.id],
  //       helperText: validationErrors[cell.id],
  //     };
  //   },
  //   [validationErrors]
  // );
  // Handle filtering change
  const handleFilterChange = (filteredData) => {
    setFilteredRows(filteredData);
  };
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#2c7B55",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#166534", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          let isValid = true;
          if (cell.column.id === "email") {
            isValid = validateEmail(event.target.value);
          } else if (cell.column.id === "mobile_number") {
            isValid = validateMobileNumber(event.target.value);
          } else {
            isValid = validateRequired(event.target.value);
          }
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
  const validateRequired = (value) => !!value.length;
  const validateEmail = (employee_email) =>
    !!employee_email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/);
  const validateMobileNumber = (value) => {
    // Use a regular expression to check if the value contains exactly 10 digits
    return /^[0-9]{10}$/.test(value);
  };

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    const isValidEmail = validateEmail(values?.employee_email);
    const isValidMobile = validateMobileNumber(values?.mobile_number);
    if (
      !Object.keys(validationErrors)?.length &&
      isValidEmail &&
      isValidMobile
    ) {
      // fetchFarmerData();
      // exitEditingMode();

      // Create a new array to hold the updated rows
      try {
        const updatedRows = {
          ...row.original, // Include the existing data from the original row
          ...values,
          employee_dob: values.formatteddob, // Include the updated values from the form
        };
        //  delete updatedRows?.formatteddob;
        // Update the rows state with the updated data
        setRows(updatedRows);
        const idToUpdate = row.original?.employee_id;
        const endpoint = API_ENDPOINTS.PUT_CONFIGURATION_FARMER(idToUpdate);

        const response = await axiosInstance.put(`${endpoint}`, updatedRows, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("updated data:", updatedRows);
        if (response.status >= 200 && response.status < 305) {
          // The data was successfully updated on the backend
          // You can perform any additional actions or update the UI here
          fetchFarmerData();
          exitEditingMode(); // Required to exit editing mode and close modal
          setOpenDialog(true);
        } else {
          // Handle the error if the PUT request was not successful
          console.log("Failed to update the farmer data");
          setSaveError("Failed to update the farmer data. Please try again.");
        }
      } catch (error) {
        setApiErrorOpen(true);
        console.log("Error occurred while updating the farmer data:", error);
      }
    } else {
      const errors = {};

      if (!isValidEmail) {
        errors.email = "Please enter a valid email address";
      }

      setValidationErrors(errors);
      setSaveError("Please correct the errors and try again.");
      setErrorDialogOpen(true); // Show the error dialog
      setRows(rows); // Revert the changes if there are validation errors
    }
    // Format the Date of Birth before saving
    // const formatteddob = moment(values.formatteddob, "DD-MM-YYYY").format("YYYY-MM-DD");
    const updatedRows = {
      ...row.original,
      ...values,
      //  formatteddob, // Include the formatted date of birth
    };
  };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };
  const handleCloseDialog = () => {
    fetchFarmerData();
    setOpenDialog(false); // Close the dialog
  };
  const handleDeleteRow = (name, employeeId) => {
    setEmployeeToDelete({ name, employeeId });
    setOpenConfirmation(true);
  };
  const confirmDelete = async () => {
    setOpenConfirmation(false);

    if (!employeeToDelete) {
      return;
    }

    const { name, employeeId } = employeeToDelete;

    try {
      const response = await axiosInstance.delete(
        `${API_ENDPOINTS.DELETE_CONFIGURATION_FARMER}${encodeURIComponent(
          employeeId
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        // Delete the row from the local table data
        const updatedRows = rows?.filter(
          (item) => item?.employee_id !== employeeId
        );
        setRows(updatedRows);
        fetchFarmerData();
      } else {
        console.log("Failed to delete the employee details");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error occurred while deleting the employee:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: " Name",
        accessorKey: "employee_name",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },
      {
        header: "Farm",
        accessorKey: "farm_name",
        size: 10,
      },
      {
        header: "Email",
        accessorKey: "employee_email",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "email",
          inputProps: { maxLength: 50 }, // Limit the maximum characters to 50
          pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$", // Add a regex pattern for email validation
          title: "Please enter a valid email address", // Error message if the email format is invalid
        }),
      },

      {
        header: "Date of Birth",
        accessorKey: "formatteddob",
        size: 10,

        enableEditing: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "date",
        }),
      },

      {
        header: "Gender",
        accessorKey: "employee_gender",
        size: 10,
        enableEditing: "true",
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: GenderOptions?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )),
        },
      },
      {
        header: "Mobile No.",
        accessorKey: "mobile_number",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: {
            maxLength: 10,
            pattern: "[0-9]*", // Only allows numeric input
            inputMode: "numeric", // Shows the numeric keyboard on mobile devices},
          },
          onKeyPress: (event) => {
            const isValidInput = /^\d+$/.test(event.key);
            if (!isValidInput) {
              event.preventDefault(); // Prevent non-numeric input
            }
          },
        }),
      },
      {
        header: "Department",
        accessorKey: "employee_department",
        size: 10,
      },

      // {
      //   header: "  ",
      //   accessorKey: "actions",
      //   size: 10,
      //   Cell: ({ row, table }) => (
      //     <Box sx={{ display: "flex", gap: "1rem" }}>
      //       <Tooltip arrow placement="right" title="Delete">
      //         <IconButton
      //           color="error"
      //           onClick={() =>
      //             handleDeleteRow(
      //               row.original?.employee_name,
      //               row.original?.employee_id
      //             )
      //           }
      //         >
      //           <Delete />
      //         </IconButton>
      //       </Tooltip>
      //     </Box>
      //   ),
      // },
    ],
    [getCommonEditTextFieldProps]
  );

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Farmer Details
        </Typography>

        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon style={{ color: colors.green.DEFAULT }} />}
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Back
          </Typography>
        </Button>

        {/* <div>
        <Button variant="outlined" onClick={exportToExcel}>
          Export to Excel
        </Button>
        <Button variant="outlined" onClick={exportToPDF}>
          Export to PDF
        </Button>
      </div> */}
      </Grid>

      <Grid sx={{ marginTop: "2%" }}>
        {isLoading ? ( // Check if data is still loading
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <ScaleLoader color="#2C7B55" />
          </div>
        ) : (
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              // data={rows}
              data={rows && rows.length !== 0 ? rows : []}
              enableGrouping
              enableStickyHeader
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 5 },
              }}
              enableMode="modal"
              enableEditing={userDetails?.config_b_editFarmerDetails}
              onEditingRowSave={handleSaveRow}
              onEditingRowCancel={handleCancelRowEdits}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: "650",
                  color: "#2c7B55",
                  fontSize: "15px",
                },
              }}
              muiTableContainerProps={{ sx: { marginBottom: "15px" } }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              muiTableBodyProps={{
                sx: {
                  //stripe the rows, make odd rows a darker color
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f8fafc",
                  },
                },
              }}
            />
          </ThemeProvider>
        )}
      </Grid>
      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h4">
              Are you sure you want to delete {employeeToDelete?.name} Details?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmation(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#198754" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p>Your data has been updated successfully.</p>
        </DialogContent>
        <Button
          onClick={handleCloseDialog}
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "2%",
            color: "#198754",
          }}
        >
          OK
        </Button>
      </Dialog>
      <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
        <DialogTitle style={{ textAlign: "center" }}>Oops Error!!!</DialogTitle>
        <DialogContent>
          <Typography color="error">
            Please correct the errors and try again.
          </Typography>
        </DialogContent>
        <Button onClick={() => setErrorDialogOpen(false)}>OK</Button>
      </Dialog>

      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};
export default FarmerView;
