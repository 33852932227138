import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import NullScreen from "../../PopUpMsg/NullScreen";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { Grid } from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ScaleLoader } from "react-spinners";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Box,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { setWarehouseAction } from "../../actions/warehouseAction";
import { tokens } from "../../WMStheme";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const gridContainerStyle = {
  // marginTop:"1%",
  // display: "flex",
  height: "75vh",
  width: "100%",
  overflowY: "hidden",
  backgroundColor: "#fff",
  padding: "1.2%",
  //  backgroundColor: "red",
};
const containerStyle8 = {
  maxHeight: "80vh",
  height: "90%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  marginTop: "1%",
  //  backgroundColor: "pink",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};

const WarehouseView = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const dispatch = useDispatch();

  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/configuration");
  };

  useEffect(() => {
    fetchWarehouseData(offset);
  }, [offset]);

  const fetchWarehouseData = async (offset) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_WAREHOUSE_DATA}`,
        {
          params: {
            limit: 9,
            offset: offset,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data;
        console.log("data", data);
        setRows(data?.warehouse_list);
        setTotalPages(data?.total_pages);
        console.log("warehouse Data", data?.warehouse_list);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      // Set loading to false after the request is completed
      setLoading(false);
    }
  };
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#fffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    fetchWarehouseData(newPage);
  };

  const columns = useMemo(() => [
    {
      header: "Edit",
      size: 2,
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => ({
        onClick: () => {
          console.log("cell original", cell.row.original);
          console.log(cell.getValue(), cell.id);
          const rowData = cell.row.original;
          console.log("Dispatching action with rowData:", rowData);
          // dispatch(setSelectedCustomer(rowData));
          dispatch(setWarehouseAction("edit", rowData));
          // Navigate to the "mainpage"
          navigate("/addWarehouse");
        },
        title: "Click to Edit ",
      }),
      Cell: ({ cell }) => {
        // const navigate = useNavigate();

        const handleClick = () => {
          console.log("clicked here or not ");
          //  console.log("Rowdata Here ", rowData);

          // Navigate to another page (replace '/destination' with your desired destination)

          // If you still want to render CustomerMasterConfig, you can do it conditionally
        };
        return (
          <div className="tooltip-cell" title="Click to Edit the Details ">
            <IconButton
              className="icon-wrapper"
              onMouseEnter={() => console.log("Hovering...")}
            >
              <MdModeEdit style={{ width: "10px", height: "10px" }} />
            </IconButton>

            <span className="farm-name">{cell.getValue()}</span>
          </div>
        );
      },
    },

    {
      header: "Name",
      accessorKey: "wh_name",
      size: 10,
    },
    {
      header: "Code",
      accessorKey: "wh_code",
      size: 10,
    },
    {
      header: "Address",
      accessor: "wh_address",
      size: 10,
      Cell: ({ row }) => {
        const address = row?.original?.wh_address?.test;

        return <span>{address ? address : "-"}</span>;
      },
    },
    //   {
    //     header: "Address",
    //     accessorKey: "test",
    //     size: 10,
    //   },
    {
      header: "Manager",
      accessorKey: "wh_manager",
      size: 10,
    },

    {
      header: "Created At",
      accessor: "created_at",
      size: 100,
      Cell: ({ row }) => {
        const created_at = row?.original?.created_at;

        return <span>{created_at ? formatDate(created_at) : "-"}</span>;
      },
    },
  ]);

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Warehouse Details
        </Typography>

        <Button
          variant="outlined"
          startIcon={
            <ArrowBackIcon
              style={{ color: colors.green.DEFAULT, fontSize: 26 }}
            />
          }
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Back
          </Typography>
        </Button>
      </Grid>
      <Grid container spacing={1} style={containerStyle8}>
        {loading ? (
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={loading} />
          </div>
        ) : rows?.length === 0 ? (
          <NullScreen containerheight="80vh" containerWidth="100%" />
        ) : (
          <div style={gridContainerStyle}>
            <Grid sx={{ marginTop: "0%", marginBottom: "1.1%" }}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  // data={rows}
                  data={rows}
                  enableGrouping
                  enableStickyHeader
                  enablePagination={false}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  initialState={{
                    density: "compact",
                    pagination: { pageIndex: 0, pageSize: 10 },
                  }}
                  muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      fontWeight: "650",
                      color: "#000",
                      fontSize: "12px",
                    },
                  }}
                  muiTableContainerProps={{ sx: { marginBottom: "10px" } }}
                  muiToolbarAlertBannerChipProps={{ color: "primary" }}
                  muiTableBodyProps={{
                    sx: {
                      //stripe the rows, make odd rows a darker color
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#ffff",
                      },
                    },
                  }}
                />
              </ThemeProvider>
            </Grid>
          </div>
        )}
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ marginTop: "0.1%" }}>
        {!loading && rows?.length > 0 && (
          <Pagination
            count={totalPages}
            page={offset}
            onChange={handleChangePage}
            // style={{ color: "#5e89c7" }}
          />
        )}
      </Grid>
    </div>
  );
};
export default WarehouseView;
