import React, { useState, createContext, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomToolbar from "./CustomToolbar";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Menu,
  Button,
  Slide,
  Alert,
  AlertTitle,
} from "@mui/material";

import MasterDialog from "../../../Modules/ExpenseTracker/Planning/FormDialog/MasterDialog";

const localizer = momentLocalizer(moment);
const SelectedDatesContext = createContext();

const Scheduler = ({ fetchTaskData, taskData }) => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  // console.log("Detailssssssssssssssssssssssssssssssss---->>>>", userDetails);
  console.log("taskDataaaaaaaaa", taskData);

  const events = taskData?.map((task) => ({
    title: task.mandatory_field.ledger_name,
    start: moment(task.mandatory_field.start_date).toDate(),
    end: moment(task.mandatory_field.end_date).toDate(),
    color: task.optional_field.priority_color,
  }));

 

  const [selectedStart, setSelectedStart] = useState(null);
  const [selectedEnd, setSelectedEnd] = useState(null);
  const [colorMenuAnchor, setColorMenuAnchor] = useState(null);
  const [isTaskSelected, setIsTaskSelected] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // const uniqueColors = Array.from(new Set(events?.map((event) => event.color)));
  const [showPopUp, setShowPopUp] = useState(false);

  const today = new Date();
  today.setDate(today.getDate() - 1);

  const [errorOpen, setErrorOpen] = useState(false);

  const handleClose = () => {
    setErrorOpen(false);
  };

  const handleSelect = ({ start, end }) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const selectedStartDate = new Date(start);
    const selectedEndDate = new Date(end);

    if (selectedStartDate < currentDate) {
      setErrorOpen(true);
    } else {
      setSelectedStart(start);
      setSelectedEnd(end);
      setSelectedStartDate(moment(start).format("YY/MM/DD"));
      setSelectedEndDate(moment(end).format("YY/MM/DD"));
      setIsTaskSelected(false); // Reset the task selection flag
      // setIsDialogOpen(true); // Open the master dialog box
      selectedEndDate.setDate(selectedEndDate.getDate() - 1);
      setShowPopUp(true);
    }
  };

  const handleMasterDialogClose = () => {
    setIsDialogOpen(false);
  };

  const [selectedColor, setSelectedColor] = useState(null);

  const handleColorMenuItemClick = (color) => {
    setSelectedColor(color);
  };

  const filteredEvents = selectedColor
    ? events.filter((event) => event.color === selectedColor)
    : events;

  // const colorOptions = taskData.map((task) => (
  //   <MenuItem
  //     key={task.priority_colour}
  //     value={task.priority_colour}
  //     style={{ backgroundColor: task.priority_colour }}
  //     onClick={() => handleColorMenuItemClick(task.priority_colour)}
  //   >
  //     <div
  //       style={{
  //         backgroundColor: task.priority_colour,
  //         width: "100%",
  //         height: "24px",
  //         borderRadius: "4px",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       {task?.priority_color_description && (
  //         <Typography variant="caption" style={{ color: "#ffffff" }}>
  //           {task?.priority_color_description}
  //         </Typography>
  //       )}
  //     </div>
  //   </MenuItem>
  // ));

  const handleColorMenuClose = () => {
    setColorMenuAnchor(null);
  };

  const eventStyleGetter = (event, isSelected) => {
    return {
      className: isSelected ? "selected-event" : "normal-event",
      style: {
        backgroundColor: event.color,
        borderRadius: "4px",
        border: "none",
        color: "#fff",
        cursor: "pointer",
      },
    };
  };

  const handlePopupClose = () => {
    setShowPopUp(false);
  };

  useEffect(() => {
    console.log("Formatted Start Date:", selectedStartDate);
    console.log("Formatted End Date:", selectedEndDate);
  }, [selectedStartDate, selectedEndDate]);

  return (
    <SelectedDatesContext.Provider
      value={{
        selectedStartDate,
        selectedEndDate,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "hidden",
          height: "100%",
        }}
      >
        {/* <div style={{ marginTop: "1%", height: "5%" }}>
          <FormControl
            variant="outlined"
            style={{ width: "120px", marginLeft: "2%" }}
          >
            <InputLabel id="Priority" style={{ marginTop: "-2%" }}>
              Priority
            </InputLabel>

            <Select
              labelId="Priority"
              label="Priority"
              id="Priority"
              variant="outlined"
              size="small"
              style={{
                height: "100%",
                zIndex: 1,
              }}
              value={selectedColor}
              onChange={(event) => handleColorMenuItemClick(event.target.value)}
              MenuProps={{
                style: {
                  maxHeight: "245px", // Set the desired max height here
                },
              }}
            >
              <MenuItem value={null}>
                <em>All Tasks</em>
              </MenuItem>
              {colorOptions}
            </Select>
          </FormControl>
        </div> */}

        <div
          style={{
            flex: 1,
            marginLeft: "2%",
            marginRight: "2%",
            marginBottom: "1%",
            marginTop: "30px",
          }}
        >
          <Calendar
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            // selectable
            // showAllEvents
            onSelectSlot={handleSelect}
            eventPropGetter={eventStyleGetter}
            // toolbar={false} // Set the toolbar prop to false
            style={{
              height: "100%",
              width: "100%",
            }}
            components={{
              toolbar: CustomToolbar, // Use your custom toolbar component
            }}
          />
        </div>
        {/* <Menu
          anchorEl={colorMenuAnchor}
          open={Boolean(colorMenuAnchor)}
          onClose={handleColorMenuClose}
        >
          {colorOptions}
        </Menu> */}
        <Slide direction="left" in={errorOpen}>
          <Alert
            severity={errorOpen ? "error" : "success"}
            onClose={handleClose}
            style={{
              position: "fixed",
              top: "8px",
              right: errorOpen ? "2px" : "-100%",
              zIndex: 1000,
              transition: "right 0.3s ease-in-out",
            }}
          >
            <AlertTitle>{errorOpen ? "Error" : ""}</AlertTitle>
            {errorOpen &&
              "Cannot assign tasks for past dates, please select another date"}
          </Alert>
        </Slide>

        {userDetails?.task_action_addTask ? (
          <MasterDialog
            isOpen={isDialogOpen}
            onClose={handleMasterDialogClose}
            selectedStartDate={selectedStartDate} // Pass the formatted start date
            selectedEndDate={selectedEndDate} // Pass the formatted end date
            fetchTaskData={fetchTaskData}
          />
        ) : (
          showPopUp && (
            <div className="popup">
              <div className="popup-content">
                <p>You do not have access to this action!</p>
                <Button onClick={handlePopupClose}>Close</Button>
              </div>
            </div>
          )
        )}
      </div>
    </SelectedDatesContext.Provider>
  );
};
export { Scheduler, SelectedDatesContext }; // Export the Scheduler component and SelectedDatesContext

export default Scheduler;
