import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  Stack,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  ListItem,
  ListItemText,
  useTheme,
  Chip,
  Box,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import Divider from "@mui/material/Divider";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AWS from "aws-sdk";
import { tokens } from "../../../../theme";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
AWS.config.update({
  accessKeyId: "AKIAYGYIFM3LE7VZTYU2",
  secretAccessKey: "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",
  region: "ap-south-1",
});
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const s3 = new AWS.S3();

const ActualCard = ({
  selectedRow,
  filterdata,
  onChange,
  onSuccess,
  onFailure,
}) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  console.log("filterdata in ActualCard Page------------------", filterdata);

  const [InvData, setInvData] = useState([filterdata?.expected?.inv_data]);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [subordinateData, setSubordinateData] = useState(null);
  const [fileName, setFileName] = useState(
    filterdata?.actual?.optional_fields?.proof_url
  );
  // The fileName field from the backend
  console.log("filter data in actual card", filterdata);
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split("T")[0];

  console.log("data subordinatedata", subordinateData);
  // Convert 16 digit Epochs format  to DD-MM-YYYY
  function formatDate(epochTimestamp) {
    const date = new Date(epochTimestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Note: Month is 0-based, so we add 1
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // Convert DD-MM-YYYY to 16 digit Epochs format
  const toEpochDate = (dateStr) => {
    // Check if the input date is in "DD-MM-YYYY" format
    const ddMmYyyyRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    const ddMmYyyyMatch = dateStr.match(ddMmYyyyRegex);

    // Check if the input date is in "YYYY-MM-DD" format
    const yyyyMmDdRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const yyyyMmDdMatch = dateStr.match(yyyyMmDdRegex);

    if (ddMmYyyyMatch) {
      const dd = parseInt(ddMmYyyyMatch[1]);
      const mm = parseInt(ddMmYyyyMatch[2]) - 1; // Months are 0-indexed
      const yyyy = parseInt(ddMmYyyyMatch[3]);
      const epochTimestamp = new Date(yyyy, mm, dd).getTime();
      return isNaN(epochTimestamp) ? null : epochTimestamp;
    } else if (yyyyMmDdMatch) {
      const yyyy = parseInt(yyyyMmDdMatch[1]);
      const mm = parseInt(yyyyMmDdMatch[2]) - 1; // Months are 0-indexed
      const dd = parseInt(yyyyMmDdMatch[3]);
      const epochTimestamp = new Date(yyyy, mm, dd).getTime();
      return isNaN(epochTimestamp) ? null : epochTimestamp;
    }

    return null; // Invalid format
  };

  const initialStartDateEpoch =
    filterdata?.expected?.mandatory_fields?.start_date;

  const formattedStartDate = formatDate(initialStartDateEpoch);
  console.log("formattedStartDateeeeeeee", formattedStartDate);
  const initialEndDateEpoch = filterdata?.expected?.mandatory_fields?.end_date;

  const formattedEndDate = formatDate(initialEndDateEpoch);
  console.log("formattedStartDateeeeeeeee", formattedEndDate);

  const initialDateOfPurchase =
    filterdata?.expected?.mandatory_fields?.date_of_purchase;
  const fromatteddateofpurchase = formatDate(initialDateOfPurchase);
  console.log("dateeeeeeee", fromatteddateofpurchase);
  const [cropData, setCropData] = useState([]);

  const commonFields = {
    ledger_id: filterdata?.expected?.mandatory_fields?.ledger_id,
    ledger_actual_id: filterdata?.actual?.mandatory_fields?.ledger_actual_id,
    activity_name: filterdata?.expected?.mandatory_fields?.activity_name,
    employee_name: filterdata?.expected?.mandatory_fields?.employee_name,
    employee_id: filterdata?.expected?.mandatory_fields?.employee_id,
    status: filterdata?.expected?.mandatory_fields?.status,
    total_labour: filterdata?.expected?.mandatory_fields?.total_labour,
    start_date: formattedStartDate,
    end_date: formattedEndDate,
    // start_date: filterdata?.actual?.optional_fields?.start_date,
    // end_date: filterdata?.actual?.optional_fields?.end_date,
    start_location: filterdata?.actual?.optional_fields?.start_location,
    end_location: filterdata?.actual?.optional_fields?.end_location,
    vendor_name: filterdata?.expected?.optional_fields.vendor_name,
    date_of_purchase: fromatteddateofpurchase,
    actual_amount: null,
    proof_url: filterdata?.actual?.optional_fields?.proof_url,
  };
  const [formData, setFormData] = useState({
    ...commonFields,
  });
  useEffect(() => {
    fetchSubordinateData();
    fetchTaskStatus();
    fetchCropData();
  }, []);
  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,

  //   }));
  // }, []);

  const fetchSubordinateData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARMER,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const subordinateData = data?.rows;
      console.log("SUBORDINATEEEEE DATAAA", subordinateData);
      // const filteredRows = subordinateData?.filter(
      //   (rows) => rows?.farm_name === dataToSend?.farm
      // );
      // const subordinates = filteredRows[0]?.farm_subordinate;
      setSubordinateData(subordinateData);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching subordinate data:", error);
    }
  };
  // Function to handle changes in form fields

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("EVENTTTTTTTTTT", name);
    console.log("VALUEEEEEEEEE", value);

    if (name === "harvest_quantity") {
      const numericValue = parseInt(value, 10);
      setFormData({ ...formData, [name]: numericValue });

      return;
    } else if (name === "actual_amount") {
      const numericValue = parseInt(value, 10);
      setFormData({ ...formData, [name]: numericValue });

      return;
    } else if (name === "total_labour") {
      const numericValue = parseInt(value, 10);

      setFormData({ ...formData, [name]: numericValue });

      return;
    } else if (name === "volume") {
      const numericValue = parseInt(value, 10);

      setFormData({ ...formData, [name]: numericValue });

      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  console.log("After handle chnageeeee", formData);

  useEffect(() => {
    console.log("fileeeee", fileName);
  }, [fileName]);

  const handleDownloadClick = () => {
    const presignedUrl = s3.getSignedUrl("getObject", {
      Bucket: "fambo-assets",
      Key: `farmer/${fileName}`,
      Expires: 3600,
    });
    console.log("Presigned URL for viewing:", presignedUrl);
    window.open(presignedUrl, "_blank");
  };
  const [isAttachmentDialog, setIsAttachmentDialog] = useState(false);
  const [taskStatus, setTaskStatus] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleAttachmentOpen = () => {
    setIsAttachmentDialog(true);
  };
  const handleAttachmentClose = () => {
    setIsAttachmentDialog(false);
    setSelectedFile(null);
  };

  const fetchTaskStatus = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_TASK_MANAGEMENT_TASKSTATUS,
        { headers: { "Content-Type": "application/json" } }
      );
      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setTaskStatus(options);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching Task Status", error);
    }
  };
  const fetchCropData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_CROP
      );
      const data = response?.data;

      // Check if data and rows exist
      if (data && data?.rows) {
        // Map the data correctly
        console.log("crop data coming in grid", data);
        const cropData1 = data?.rows?.map((row) => row?.crop_details);
        const cropNames = cropData1?.map(
          (item) => item?.crop_details?.CropName
        );
        console.log(cropNames); // This will give you an array of crop names

        console.log("Crop Data", cropData1);
        setCropData(cropData1);
        console.log("poiugydgvbhj", cropData);
      } else {
        console.log("Data or rows not found in response:", data);
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching crop data:", error);
    }
  };

  // Using aws-sdk
  const handleSaveAttachmentClick = async () => {
    if (selectedFile) {
      const key = `${Date.now()}_${selectedFile.name}`;

      const params = {
        Bucket: "fambo-assets",
        Key: `farmer/${key}`,
        Body: selectedFile,
      };

      s3.upload(params, async (err, data) => {
        if (err) {
          console.log("Error uploading file:", err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          setFileName(key);
        }
      });

      handleAttachmentClose();
    }
  };
  const handleSubmit = async () => {
    console.log("handleSubmit called");
    try {
      const fields = {
        // ledger_id: filterdata?.expected?.mandatory_fields?.ledger_id,
        // ledger_actual_id:
        //   filterdata?.actual?.mandatory_fields?.ledger_actual_id,
        // actual_amount: formData?.actual_amount,
        // start_date: formData.start_date_epoch,
        // end_date: formData.end_date_epoch,
        // status: formData.status,
        // activity_name:formData.activity_name,
        // total_labour: filterdata?.expected?.mandatory_fields?.total_labour,
        ledger_id: formData?.ledger_id,
        ledger_actual_id: formData?.ledger_actual_id,
        employee_id: formData?.employee_id,
        activity_name: formData?.activity_name,
        employee_name: formData?.employee_name,
        status: formData?.status,
        total_labour: formData?.total_labour,
        start_date: toEpochDate(formData?.start_date),
        end_date: toEpochDate(formData?.end_date),
        actual_amount: formData?.actual_amount,
      };
      let optionalFields = {};

      console.log("hereeeeee", filterdata?.expected);

      if (
        filterdata?.expected?.mandatory_fields?.ledger_name === "Harvesting"
      ) {
        optionalFields = {
          crop_name: filterdata?.expected?.optional_fields?.crop,
          crop_id: formData?.crop_id,
          Zone_id: formData?.Zone_id,
          zone_name: filterdata?.expected?.optional_fields?.zone,
          harvest_quantity: formData?.harvest_quantity,
          harvest_unit: formData?.harvest_unit,
        };
      } else if (
        filterdata?.expected?.mandatory_fields?.ledger_name === "Irrigation"
      ) {
        optionalFields = {
          irrigation_type: formData?.irrigation_type,
          volume: formData?.volume,
        };
      } else if (
        filterdata?.expected?.mandatory_fields?.ledger_name === "Cleaning"
      ) {
        optionalFields = {
          start_location: formData?.start_location,
          end_location: formData?.end_location,
        };
      } else if (
        filterdata?.expected?.mandatory_fields?.ledger_name === "Purchase"
      ) {
        optionalFields = {
          vendor_name: formData?.vendor_name,
          date_of_purchase: formData?.date_of_purchase,
        };
      } else if (
        filterdata?.expected?.mandatory_fields?.ledger_name === "Miscellaneous"
      ) {
        optionalFields = {
          vendor_name: formData?.vendor_name,
          date_of_purchase: formData?.date_of_purchase,
        };
      }
      const formDataToSend = {
        actual: {
          mandatory_fields: {
            ...fields,
          },
          optional_fields: {
            ...optionalFields,
            proof_url: fileName,
          },
          inv_data: JSON.stringify(InvData),
        },
      };
      console.log("test+++++++++++++++", formDataToSend);
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_COST_MANAGEMENT_ACTUAL_DATA,
        formDataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("responseeeeeeeeee", response);
      if (response.status >= 200 && response.status < 305) {
        onSuccess(true);
        console.log("Response from backend:", response.data);
      } else {
        onFailure(true);
      }
    } catch (error) {
      onFailure(true);
      console.log("Error submitting data:", error);
    }
    onChange();
  };

  const handleGradeChange = (
    detailIndex,
    harvestIndex,
    gradeIndex,
    field,
    value
  ) => {
    // console.log("harvestIndex", harvestIndex);
    // console.log("gradeIndex", gradeIndex);
    // console.log("field", field);
    // console.log("value", value);
    const updatedData = [...InvData];
    updatedData[detailIndex].harvestdetails[harvestIndex].grades[gradeIndex][field] = value;
    setInvData(updatedData);
    return updatedData;
  };

  const renderHarvestDetails = () => {
    if (InvData) {
      // const invDataArray = [InvData];

      return InvData?.map((detail, index) => (
        <Stack direction="column" key={index}>
          <Typography
            variant="h4"
            color={colors.black.DEFAULT}
            fontFamily="Lexend Deca"
            style={{ marginTop: "4%" }}
          >
            Grades:
          </Typography>
          {detail?.harvestdetails &&
            detail?.harvestdetails?.map((harvest, idx) => (
              <div key={idx}>
                <ListItem style={{ marginLeft: "-4%" }}>
                  <ListItemText
                    primary={`Date of Harvest : ${formatDate(
                      harvest?.date_of_harvest
                    )}`}
                  />
                </ListItem>
                {harvest?.grades &&
                  harvest?.grades?.map((grade, gIndex) => (
                    <div key={gIndex}>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        display="flex"
                        key={index}
                        style={{ marginTop: "1%" }}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="Name"
                            fullWidth
                            variant="filled"
                            disabled
                            size="small"
                            margin="normal"
                            value={grade?.name}
                            placeholder="Name"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="No. of Plants"
                            fullWidth
                            variant="filled"
                            disabled
                            size="small"
                            margin="normal"
                            value={grade?.numberOfPlants}
                            placeholder="No. of Plants"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="Weight(Kg)"
                            fullWidth
                            variant="filled"
                            disabled
                            size="small"
                            margin="normal"
                            value={grade?.weight}
                            placeholder="Weight(Kg)"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="Amount"
                            fullWidth
                            variant="standard"
                            size="small"
                            type="number"
                            margin="normal"
                            value={grade?.price}
                            onChange={(e) =>
                              handleGradeChange(
                                index,
                                idx,
                                gIndex,
                                "price",
                                e.target.value
                              )
                            }
                            placeholder="Amount"
                            inputProps={{
                              style: { width: "100%" },
                              min: 0,
                              max: 100000,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  ))}

                {idx !== detail?.harvestdetails.length - 1 && (
                  <Divider
                    sx={{
                      borderBottom: "2px solid #000000",
                      margin: "16px 0",
                    }}
                    variant="middle"
                  />
                )}
              </div>
            ))}
        </Stack>
      ));
    }
    return null;
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Activity Name"
            name="activity_name"
            value={formData.activity_name}
            onChange={handleChange}
            // onChange={handleTitleChange}
            fullWidth
            size="small"
            autoFocus
            // required
            // style={{ marginBottom: "16px" }}
          />
        </Grid>

        <Grid item xs={12} sm={5.4} style={{ marginTop: "10px" }}>
          <FormControl fullWidth>
            <InputLabel>Assign to</InputLabel>
            <Select
              //value={myAs}
              label="Assign to"
              labelId="Assign to"
              name="assigned_to"
              fullWidth
              displayEmpty
              // required
              size="small"
              value={formData?.employee_name}
              onChange={handleChange}
              inputProps={{ "aria-label": "Select Subordinate" }}
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
            >
              {subordinateData &&
                subordinateData?.map((farmer) => (
                  <MenuItem
                    key={farmer.employee_id}
                    value={farmer.employee_name}
                  >
                    {farmer.employee_name}
                  </MenuItem>
                ))}
              <MenuItem disabled value="">
                Select Subordinate
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
          <TextField
            label="Start Date"
            labelId="StartDate"
            name="start_date"
            value={formData?.start_date}
            onChange={handleChange}
            type="date"
            InputLabelProps={{ shrink: true }}
            // value={title}
            // onChange={handleTitleChange}
            fullWidth
            size="small"
            date
            autoFocus
            // required
            //onBlur={handleStartDateBlur}
            InputProps={{
              inputProps: {
                min: formattedSixMonthsAgo,
                max: new Date().toISOString().split("T")[0],
              },
            }}
            inputProps={{ "aria-label": "Start Date" }}
            startAdornment={<InputAdornment position="start"></InputAdornment>}
            // style={{ marginBottom: "16px" }}
          />
        </Grid>
        <Grid item xs={12} sm={5.4} style={{ marginTop: "20px" }}>
          <TextField
            label="End Date"
            name="end_date"
            value={formData.end_date}
            onChange={handleChange}
            fullWidth
            type="date"
            size="small"
            date
            time
            // onBlur={handleEndDateBlur}
            InputProps={{
              inputProps: {
                max: new Date(new Date().setMonth(new Date().getMonth() + 6))
                  .toISOString()
                  .split("T")[0],
                min: formData.start_date,
              },
            }}
            autoFocus
            // required
            // style={{ marginBottom: "16px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
          <TextField
            label="Total Labour"
            name="total_labour"
            // onChange={handleTitleChange}
            value={formData.total_labour}
            onChange={handleChange}
            fullWidth
            type="number"
            size="small"
            autoFocus
            // required
            // style={{ marginBottom: "16px" }}
          />
        </Grid>
        <Grid item xs={12} sm={5.4} style={{ marginTop: "10px" }}>
          <TextField
            label="Actual Total Amount"
            fullWidth
            type="number"
            name="actual_amount"
            value={formData.actual_amount}
            onChange={handleChange}
            // onChange={(event) => {
            //   const value = parseInt(event.target.value);
            //   if (!isNaN(value) && value > 1000000) {
            //     updateState("actual_amount", 1000000);
            //   } else {
            //     updateState("actual_amount", value);
            //   }
            // }}
            size="small"
            inputProps={{
              min: 0,
              inputMode: "numeric",
            }}
            // inputProps={{
            //   min: 0,
            //   max: 1000000,
            //   inputMode: "numeric",
            // }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={11.4} style={{ marginTop: "20px" }}>
          <FormControl fullWidth>
            <InputLabel>Select Status</InputLabel>
            <Select
              labelId="Select Status"
              label="Select Status"
              value={formData.status}
              onChange={handleChange}
              name="status"
              // value={myStatus}
              //onChange={handleStatusChange}
              // required
              fullWidth
              size="small"
              displayEmpty
              inputProps={{ "aria-label": "Select Status" }}
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
            >
              {taskStatus?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              <MenuItem disabled value="">
                Select Status
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {filterdata?.expected?.mandatory_fields?.ledger_name === "Harvesting" && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
              <TextField
                label="Crop"
                name="crop"
                // value={title}
                // onChange={handleTitleChange}
                value={filterdata?.expected?.optional_fields?.crop}
                onChange={handleChange}
                fullWidth
                readonly
                size="small"
                autoFocus
                // required
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5.4} style={{ marginTop: "20px" }}>
              <TextField
                label="Zone"
                name="zone"
                // value={title}
                // onChange={handleTitleChange}
                value={filterdata?.expected?.optional_fields?.zone}
                onChange={handleChange}
                fullWidth
                readonly
                size="small"
                autoFocus
                // required
                InputProps={{
                  readOnly: true,
                }}
                // style={{ marginBottom: "16px" }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
              <TextField
                label="Quantity"
                fullWidth
                type="number"
                name="harvest_quantity"
                value={formData.harvest_quantity}
                onChange={handleChange}
                size="small"
                inputProps={{
                  min: 0,
                  inputMode: "numeric",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5.4} style={{ marginTop: "20px" }}>
              <FormControl fullWidth>
                <InputLabel>Unit</InputLabel>
                <Select
                  labelId="Unit"
                  id="Unit"
                  name="harvest_unit"
                  label="Unit"
                  //multiple
                  // required
                  size="small"
                  value={formData.harvest_unit}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "arial-label": "Unit" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  fullWidth
                >
                  <MenuItem value="No. of Plants">Number of Plants</MenuItem>
                  <MenuItem value="Kg">Kg</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {filterdata?.transaction_type === "income" && (
              <Grid item xs={12} sm={5.3} style={{ marginTop: "20px" }}>
                <TextField
                  label="Rate"
                  name="rate"
                  // value={title}
                  // onChange={handleTitleChange}
                  value={formData?.rate}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  size="small"
                  autoFocus

                  // required
                />
              </Grid>
            )}
            {/* <Stack direction="row" spacing={2}>
            </Stack> */}
            <Grid
              item
              xs={11.4}
              sm={11.4}
              md={11.4}
              style={{ marginTop: "22px" }}
            >
              {renderHarvestDetails()}
            </Grid>
          </Grid>
        </>
      )}
      {filterdata?.expected?.mandatory_fields?.ledger_name === "Irrigation" && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "3%" }}>
              <FormControl fullWidth>
                <InputLabel>Irrigation Type</InputLabel>
                <Select
                  labelId="Irrigation Type"
                  name="irrigation_type"
                  id="Irrigation Type"
                  label="Irrigation Type"
                  value={formData.irrigation_type}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  // required
                  displayEmpty
                  inputProps={{ "arial-label": "Irrigation Type" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  <MenuItem value="Single Use">Single Use</MenuItem>
                  <MenuItem value="Recirculating">Recirculating</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container xs={12} sm={5.4} style={{ marginTop: "28px" }}>
              <TextField
                label="Volume"
                name="volume"
                value={formData?.volume}
                size="small"
                onChange={handleChange}
                inputProps={{
                  min: 0,
                }}
                // required
                fullWidth
                autoFocus
                style={{ marginBottom: "16px" }}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.9} style={{ marginTop: "2px" }}></Grid>
        </>
      )}
      {filterdata?.expected?.mandatory_fields?.ledger_name === "Cleaning" && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} marginTop={"12px"}>
              <TextField
                label="Start Location"
                value={formData?.start_location}
                onChange={handleChange}
                fullWidth
                autoFocus
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} marginTop={"12px"}>
              <TextField
                label="End Location"
                value={formData?.end_location}
                onChange={handleChange}
                fullWidth
                autoFocus
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.9} style={{ marginTop: "2px" }}></Grid>
        </>
      )}
      {filterdata?.expected?.mandatory_fields?.ledger_name === "Purchase" && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} marginTop={"12px"}>
              <TextField
                name="vendor_name"
                label="Vendor Name"
                value={formData?.vendor_name}
                onChange={handleChange}
                fullWidth
                autoFocus
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={5.4} marginTop={"12px"}>
              <TextField
                name="date_of_purchase"
                label="Date of Purchase"
                value={formData?.date_of_purchase}
                onChange={handleChange}
                type="date"
                fullWidth
                autoFocus
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.9} style={{ marginTop: "2px" }}></Grid>
        </>
      )}
      {filterdata?.expected?.mandatory_fields?.ledger_name ===
        "Miscellaneous" && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5.9} style={{ marginTop: "2px" }}></Grid>
          </Grid>
        </>
      )}
      {filterdata?.expected?.mandatory_fields?.ledger_name ===
        "Electricity" && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5.9} style={{ marginTop: "2px" }}></Grid>
          </Grid>
        </>
      )}
      {filterdata?.expected?.mandatory_fields?.ledger_name === "Transport" && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5.9} style={{ marginTop: "2px" }}></Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3.4} style={{ marginTop: "20px" }}>
          <Typography
            variant="h4"
            color={colors.black[200]}
            fontFamily="Lexend Deca"
          >
            Add Invoice:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} style={{ marginTop: "20px" }}>
          <>
            <div>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleAttachmentOpen}
                  startIcon={<AttachmentIcon />}
                  size="small"
                  disabled={fileName}
                  sx={{
                    color: "#2C7B55",
                    borderColor: "#2C7B55",
                    marginRight: "5px",
                  }}
                  variant="outlined"
                >
                  <Typography variant="h6" fontFamily="Lexend Deca">
                    Add
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon />}
                  sx={{
                    backgroundColor: "#2C7B55", // Default background color
                  }}
                  size="small"
                  disabled={!fileName}
                  onClick={handleDownloadClick}
                >
                  <Typography variant="h6" fontFamily="Lexend Deca">
                    Download
                  </Typography>
                </Button>
              </Stack>
              <Dialog open={isAttachmentDialog} onClose={handleAttachmentClose}>
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  Upload Document
                  <IconButton
                    aria-label="close"
                    onClick={handleAttachmentClose}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,
                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {selectedFile && (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "300px",
                        marginBottom: "20px",
                      }}
                    />
                  )}
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                  />
                </DialogContent>

                <DialogActions
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "32px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#2C7B55",
                    }}
                    onClick={handleSaveAttachmentClick}
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Save
                    </Typography>
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          marginTop: "10%",
          marginLeft: "79%",
          marginBottom: "5%",
        }}
      >
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          Save
        </Button>
      </Grid>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};

export default ActualCard;
