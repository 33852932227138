import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IconButton, Box, Tooltip, Grid } from "@mui/material";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import { MaterialReactTable } from "material-react-table";
import { ScaleLoader } from "react-spinners";
import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useMemo, useState, useEffect } from "react";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

function GridDamage({ farmname, farmId }) {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  console.log("farm_id in Damage page", farmId);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(1);
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  //  const farmname2 = farmname.data;
  // console.log("farmname",farmname.farmname);
  const handleOpen = (row) => {
    setOpen(true);
    setSelectedRow(row);
  };
  const handleRollBack = (row) => {
    handleOpen(row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async (rowData) => {
    setOpen(false);
    if (selectedRow) {
      try {
        const response = await axiosInstance.put(
          `${API_ENDPOINTS.PUT_CROPMANAGEMENT_DAMAGE_ROLLBACK}`,
          rowData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 305) {
          console.log("API call successful", response);
          // updateRows(rowData);
          fetchData();
        } else {
          console.log("API CALL failed");
        }
      } catch (error) {
        setApiErrorOpen(true);
        console.log("API call failed", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    const loaderTimer = setTimeout(() => {
      setIsLoading(false);
    }, 30000); // Display loader for 3 seconds

    fetchData();

    return () => clearTimeout(loaderTimer);
  }, [selectedRow,limit,offset]);
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "success",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12, // S
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#166534", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const fetchData = async (newPage) => {
    console.log("newpage",newPage);
    try {
      const response = await axiosInstance
      .get(`${API_ENDPOINTS.GET_CROPMANAGEMENT_DAMAGE(farmId,limit,offset)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const formattedData = data?.rows?.map((item) => {
          const formatteddos = moment(item?.date_of_sow).format("DD-MM-YYYY");
          let formatteddod = moment(item?.date_of_dump).format("DD-MM-YYYY");
          const formattededoh = moment(item?.exp_date_of_harvest).format(
            "DD-MM-YYYY"
          );
          if (formatteddod === "Invalid date") {
            formatteddod = "NT";
          }
          console.log("expected doh========", formattededoh);
          return {
            ...item,
            formatteddos,
            formatteddod,
            formattededoh,
          };
        });

        setRows(formattedData);
        setIsLoading(false);
      }else {
        console.log("Error fetching details");
      }
     }catch (error) {
        setApiErrorOpen(true);
        console.log("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    const handleChangePage = (event, newPage) => {
      setOffset(newPage);
      fetchData();
     
    };
  console.log("row data in damage grid ", rows);
  const handleDelete = (row) => {
    setDeleteConfirmationOpen((prevState) => ({
      ...prevState,
      [row.id]: true,
    }));
    setRowToDelete(row);
    setDeleteConfirmationOpen(true);
  };
  // const handlerollback = (row) => {};

  const handleDeleteConfirmationClose = (confirmed, rowToDelete) => {
    if (confirmed) {
      // Send delete request to backend
      fetch(
        `${process.env.REACT_APP_BASE_URL}/general/dump/${rowToDelete?.dump_id}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => {
          if (response.ok) {
            const updatedRows = rows?.filter(
              (r) => r?.dump_id !== rowToDelete?.dump_id
            );
            setRows(updatedRows);
          } else {
            console.log("Failed to delete row:", response);
          }
        })
        .catch((error) => {
          console.log(error);
          setApiErrorOpen(true);
        });
    }
    setDeleteConfirmationOpen((prevState) => ({
      ...prevState,
      [rowToDelete.id]: false,
    }));
  };
  const columns = useMemo(
    () => [
      { header: "Crop Name", accessorKey: "crop_name", size: 10 },
      { header: "Variety", accessorKey: "crop_variety_name", size: 10 },
      { header: "Zone", accessorKey: "farm_zone", size: 10 },
      { header: "Batch", accessorKey: "batch", size: 10 },
      { header: "Crop Type", accessorKey: "crop_type", size: 10 },
      { header: "No. Of Plants", accessorKey: "no_of_plants", size: 10 },
      { header: "DOS", accessorKey: "formatteddos", size: 10 },
      { header: "Exp DOH", accessorKey: "formattededoh", size: 10 },
      { header: "Damage Date", accessorKey: "formatteddod", size: 10 },
      { header: "Weight(Kg)", accessorKey: "dump_weight", size: 10 },
    ],
    []
  );

  const styles = {
    updateButton: {
      height: "20px",
      // marginBottom: '20px'
    },
  };

  return (
    <>
      <Grid sx={{ marginTop: "2%" }}>
        {isLoading ? ( // Check if data is still loading
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <ScaleLoader color="#2C7B55" />
          </div>
        ) : (
          // <ThemeProvider theme={tableTheme}>
          <div>
            <MaterialReactTable
              columns={columns}
              data={rows}
              positionActionsColumn="last"
              enableRowActions
              enableGrouping
              enableStickyHeader
              enableRowVirtualization
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={false}
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 5 },
              }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: "650",
                  color: "#2c7B55",
                  fontSize: "15px",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  //stripe the rows, make odd rows a darker color
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f8fafc",
                  },
                },
              }}
              muiTableContainerProps={{ sx: { marginBottom: "15px" } }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              renderRowActions={({ row }) => (
                <>
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow placement="right" title="Rollback">
                      <IconButton onClick={() => handleRollBack(row.original)}>
                        <div style={{ rotate: "90deg" }}>
                          <UTurnLeftIcon />
                        </div>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
            />
            <div
           style={{
             display: "flex",
             justifyContent: "flex-end",
             alignItems: "flex-end",
            //  marginTop: "-90%",
           }}
         >
           <Pagination
             count={100}
             onChange={handleChangePage}
             size="small"
           />
         </div>
          </div>
        )}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Revert Confirmation</DialogTitle>
          <DialogContent>
            <div>
              Reverting the damage plants will bring them add back to live
              Inventory.!!!
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="success">
              Cancel
            </Button>
            <Button onClick={() => handleConfirm(selectedRow)} color="success">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
}

export default GridDamage;
