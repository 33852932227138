import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Stack,
  FormControl,
  Typography,
  FormHelperText,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ReactQuill from "react-quill";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";

import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const IrrigationDialog = ({
  dialogOpen,
  setDialogOpen,
  masterDialogState,
  fetchTaskData,
  listOfZones,
}) => {
  console.log("LISTTTT OF ZONES IRRIGATION ", listOfZones);
  const [irrigationDialogState, setIrrigationDialogState] = useState({
    title: "",
    selectedColor: "#3174ad",
    selectedColorDescription: null,
    selectedZone: "",
    description: "",
    showPopup: false,
    open: false,
    selectedProofs: [],
    selectedFields: [],
    savedText: "",
    descriptions: [],
    showAttachment: false,
    showAddDescription: true,
    dataTypes: [],
    volume: "",
    pH: "",
    temperature: null,
    tds: "",
    irrigationType: "",
    ppm: null,
    mS_cm: null,
    singleUseTime: null,
    recirculatingOffTime: null,
    recirculatingOnTime: null,
  });

  const {
    title,
    selectedColor,
    selectedColorDescription,
    selectedZone,
    description,
    showPopup,
    selectedProofs,
    savedText,
    descriptions,
    showAttachment,
    showAddDescription,
    dataTypes,
    volume,
    pH,
    temperature,
    tds,
    irrigationType,
    ppm,
    mS_cm,
    singleUseTime,
    recirculatingOffTime,
    recirculatingOnTime,
  } = irrigationDialogState;

  const updateState = (key, value) => {
    setIrrigationDialogState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [proofDetailsAdded, setProofDetailsAdded] = useState(false);
  const handleSubmit = async (e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);
    console.log("entered !");
    e.preventDefault();
    const formData = {
      task_title: title,
      priority_color: selectedColor,
      priority_color_description: selectedColorDescription,
      zone: selectedZone,
      proof: selectedProofs.length > 0 ? selectedProofs : null, // Set value based on the selectedProofs array
      volume: volume,
      ph: pH,
      temperature: temperature,
      tds: tds,
      irrigationType: irrigationType,
      ppm: ppm,
      mS_cm: mS_cm,
      singleUseTime: singleUseTime,
      recirculatingOffTime: recirculatingOffTime,
      recirculatingOnTime: recirculatingOnTime,
      instruction: description,
      masterDialogData: masterDialogState,
    };
    try {
      // const response = await fetch(
      //   `${BASE_URL}${API_ENDPOINTS.POST_TASK_MANAGEMENT_CREATETASK}`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "content-Type": "application/json",
      //       client_id: client_id,
      //     },
      //     body: JSON.stringify(formData),
      //     signal, // Pass the signal to the fetch request
      //   }
      // );
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_TASK_MANAGEMENT_CREATETASK,
        formData,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

      if (response.status >= 200 && response.status < 305) {
        console.log("Irrigation Task Allocated");
        updateState("showPopup", true);
      } else {
        console.log("Irrigation Task not Allocated");
        alert(
          "Sorry, an error occurred while Adding the task. Please try again later."
        );
      }
      console.log(response.data);
      console.log("in middle !");
    } catch (error) {
      clearTimeout(timeoutId); // Clear the timeout if an error occurs

      console.log(error);
      alert(
        "Sorry, an error occurred while Adding the task. Please try again later."
      );
    }

    console.log({
      formData,
    });

    e.target.reset();
  };

  const handleZoneChange = async (e) => {
    const selectedZone = e.target.value;
    updateState("selectedZone", selectedZone);
  };

  const handleSelectedColorDescriptionChange = async (e) => {
    const selectedColorDescription = e.target.value;
    updateState("selectedColorDescription", selectedColorDescription);
  };

  const handlePopupClose = () => {
    updateState("open", false);
    updateState("showPopup", false);
    setDialogOpen(false);
    fetchTaskData();
  };

  const handleTdsChange = (event) => {
    updateState("tds", event.target.value);
  };

  const handleTitleChange = (event) => {
    updateState("title", event.target.value);
  };

  const handleColorChange = (event) => {
    updateState("selectedColor", event.target.value);
  };

  const handleDescriptionChange = (e, index) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = e.target.value;
    updateState("descriptions", updatedDescriptions);
  };

  const handleDataTypeChange = (e) => {
    updateState("dataTypes", [e.target.value]);
  };

  const handleIrrigationTypeChange = (e) => {
    updateState("irrigationType", e.target.value);
  };

  const handleSave = () => {
    const proofs = descriptions.map((desc, index) => {
      const attachmentType = dataTypes[index] ? `${dataTypes[index]}` : "";
      setProofDetailsAdded(true);
      return { description: desc, attachmentType };
    });
    updateState("selectedProofs", proofs); // Update selectedProofs here
    updateState(
      "savedText",
      proofs
        .map((proof) => `${proof.description} | ${proof.attachmentType}`)
        .join("\n")
    );
    updateState("descriptions", []);
    updateState("dataTypes", []);
    updateState("showAddDescription", true);
    updateState("showAttachment", false);
  };

  const handleAddDescription = () => {
    updateState("showAddDescription", false);
    updateState("showAttachment", true);
    updateState("descriptions", [...descriptions, ""]);
  };

  const modules = {
    toolbar: {
      container: [[{ list: "ordered" }]],
    },
  };

  const formats = ["list"];

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { minWidth: "520px", minHeight: "400px" } }}
    >
      <DialogTitle>
        <Typography variant="h3" color={colors.green.DEFAULT}>
          Irrigation
        </Typography>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} marginTop={"2px"}>
              <TextField
                label="Activity Title"
                value={title}
                onChange={handleTitleChange}
                fullWidth
                size="small"
                autoFocus
                required
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: "-9px" }}>
              <TextField
                id="input-with-icon-textfield"
                label="Priority Color"
                inputProps={{ maxLength: 15 }}
                size="small"
                value={selectedColorDescription}
                onChange={handleSelectedColorDescriptionChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <input
                        type="color"
                        id="color"
                        value={selectedColor}
                        onChange={handleColorChange}
                        style={{
                          width: "24px",
                          height: "24px",
                          padding: "0",
                          border: "none",
                          borderRadius: "50%",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "4px" }}>
              <FormControl fullWidth>
                <InputLabel id="Select Zone">Select Zone</InputLabel>
                <Select
                  labelId="Select Zone"
                  id="Select Zone"
                  fullWidth
                  size="small"
                  value={selectedZone}
                  label="Select Zone"
                  required
                  displayEmpty
                  inputProps={{ "arial-label": "Select Zone" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  onChange={handleZoneChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {listOfZones &&
                    listOfZones?.map((prop) => (
                      <MenuItem key={prop} value={prop}>
                        {prop}
                      </MenuItem>
                    ))}
                  <MenuItem disabled value="">
                    Select Zone
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} marginTop={"18px"}>
              <TextField
                label="Volume per unit plant (in ml)"
                value={volume}
                size="small"
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (!isNaN(value) && value > 0) {
                    updateState("volume", value);
                  } else {
                    updateState("volume", "");
                  }
                }}
                inputProps={{
                  min: 0,
                 
                }}
                required
                fullWidth
                autoFocus
                style={{ marginBottom: "16px" }}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} marginTop={"1%"}>
              <TextField
                label="pH"
                value={pH}
                size="small"
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (!isNaN(value) && value >= 0 && value <= 14) {
                    // setpH(value);
                    updateState("pH", value);
                  } else {
                    // setpH("");
                    updateState("pH", "");
                  }
                }}
                inputProps={{
                  min: 0,
                  max: 14,
                  
                }}
                fullWidth
                autoFocus
                type="number"
                required
              />
              <FormHelperText>range(0 to 14)</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} marginTop={"1%"}>
              <TextField
                label="Temp(°C)"
                value={temperature}
                size="small"
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (!isNaN(value) && value >= 0 && value <= 50) {
                    // setTemperature(value);
                    updateState("temperature", value);
                  } else {
                    // setTemperature("");
                    updateState("temperature", "");
                  }
                }}
                inputProps={{
                  min: 0,
                  max: 50,
                 
                }}
                fullWidth
                autoFocus
                type="number"
              />
              <FormHelperText>range(0 to 50)</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ marginTop: "-2.4%" }}>
              <FormControl fullWidth>
                <InputLabel>EC/TDS </InputLabel>
                <Select
                  labelId="ec"
                  id="ec"
                  size="small"
                  label="EC/TDS"
                  value={tds}
                  onChange={handleTdsChange}
                  fullWidth
                  required
                  displayEmpty
                  inputProps={{ "arial-label": "Select" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  <MenuItem value="ppm">in (ppm)</MenuItem>
                  <MenuItem value="mS_cm">in (mS/cm)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              {tds === "ppm" && (
                <TextField
                  label="ppm"
                  variant="standard"
                  value={ppm}
                  size="small"
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value) && value > 0) {
                      updateState("ppm", value);
                    } else {
                      updateState("ppm", "");
                    }
                  }}
                  inputProps={{
                    min: 1,
                   
                  }}
                  fullWidth
                  autoFocus
                  type="number"
                />
              )}
              {tds === "mS_cm" && (
                <TextField
                  label="mS/cm"
                  variant="standard"
                  fullWidth
                  autoFocus
                  size="small"
                  type="number"
                  value={mS_cm}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value) && value > 0) {
                      updateState("mS_cm", value);
                    } else {
                      updateState("mS_cm", "");
                    }
                  }}
                  inputProps={{
                    min: 0,
                  
                  }}
                />
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "3%" }}>
              <FormControl fullWidth>
                <InputLabel>Irrigation Type</InputLabel>
                <Select
                  labelId="Irrigation Type"
                  id="Irrigation Type"
                  label="Irrigation Type"
                  value={irrigationType}
                  onChange={handleIrrigationTypeChange}
                  fullWidth
                  size="small"
                  required
                  displayEmpty
                  inputProps={{ "arial-label": "Irrigation Type" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  <MenuItem value="Single Use">Single Use</MenuItem>
                  <MenuItem value="Recirculating">Recirculating</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container xs={12} sm={6} spacing={2}>
              {irrigationType === "Single Use" && (
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  <TextField
                    label="Irrigation Interval (in mins)"
                    variant="standard"
                    fullWidth
                    size="small"
                    type="number"
                    autoFocus
                    value={singleUseTime}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value) && value > 0) {
                        updateState("singleUseTime", value);
                      } else {
                        updateState("singleUseTime", "");
                      }
                    }}
                    inputProps={{
                      min: 0,
                     
                    }}
                  />
                </Grid>
              )}
              {irrigationType === "Recirculating" && (
                <>
                  <Grid item xs={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Irrigation Interval ON (in mins)"
                      variant="standard"
                      fullWidth
                      size="small"
                      type="number"
                      autoFocus
                      value={recirculatingOnTime}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value) && value > 0) {
                          updateState("recirculatingOnTime", value);
                        } else {
                          updateState("recirculatingOnTime", "");
                        }
                      }}
                      inputProps={{
                        min: 0,
                     
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Irrigation Interval OFF (in mins)"
                      variant="standard"
                      fullWidth
                      type="number"
                      autoFocus
                      size="small"
                      value={recirculatingOffTime}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value) && value > 0) {
                          updateState("recirculatingOffTime", value);
                        } else {
                          updateState("recirculatingOffTime", "");
                        }
                      }}
                      inputProps={{
                        min: 0,
                        
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "5px" }}>
              {descriptions?.map((description, index) => (
                <TextField
                  key={index}
                  label="Proof title"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={description}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => handleDescriptionChange(e, index)}
                />
              ))}
              {proofDetailsAdded ? (
                <>
                  <Tooltip
                    title="Proof Type already added !"
                    placement="right"
                    disableHoverListener={!proofDetailsAdded}
                  >
                    <Button
                      variant="outlined"
                      endIcon={
                        <AddIcon style={{ color: colors.green.DEFAULT }} />
                      }
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      onClick={handleAddDescription}
                      disabled
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Proof List
                      </Typography>
                    </Button>
                  </Tooltip>
                </>
              ) : (
                showAddDescription && (
                  <Tooltip
                    title="Click to add the attachments that supervisor needs to send as a proof of completion of task !"
                    placement="right"
                  >
                    <Button
                      variant="outlined"
                      endIcon={
                        <AddIcon style={{ color: colors.green.DEFAULT }} />
                      }
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      onClick={handleAddDescription}
                      style={{ marginTop: "5px" }}
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Proof List
                      </Typography>
                    </Button>
                  </Tooltip>
                )
              )}
            </Grid>

            {showAttachment && (
              <>
                <Grid item xs={12} sm={4} style={{ marginTop: "8px" }}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel style={{ marginTop: "-2%" }}>
                      Select Proof type
                    </InputLabel>
                    <Select
                      value={dataTypes[0] || ""}
                      label="Select Proof type"
                      onChange={handleDataTypeChange}
                      displayEmpty
                      size="small"
                      inputProps={{ "aria-label": "Select Proof Type" }}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                    >
                      <MenuItem value="PDF">PDF</MenuItem>
                      <MenuItem value="DOC">DOC</MenuItem>
                      <MenuItem value="Image">Image</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={1.2}>
                  <Button
                    sx={{
                      borderColor: colors.green.DEFAULT,
                      color: colors.green.DEFAULT,
                    }}
                    variant="outlined"
                    style={{
                      float: "left",
                      marginLeft: "16px",
                      marginTop: "19px",
                    }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
              <ReactQuill
                style={{
                  height: "100px",
                  width: "100%",
                }}
                placeholder="Additional instructions for this activity!"
                modules={modules}
                formats={formats}
                value={description} // Add value prop
                onChange={(value) => updateState("description", value)} // Update description state
              />
            </Grid>
            {savedText && (
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  marginTop: "-48%",
                  marginLeft: "52%",
                  backgroundColor: "#ecf4f0",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  # {savedText}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-end"
          ></Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: "75px" }}>
            <Stack
              spacing={1}
              direction="row"
              style={{ height: "32px", marginTop: "2%", marginLeft: "70%" }}
            >
              <Button
                type="submit" // Added type="submit" to trigger form submission
                variant="contained"
                sx={{ backgroundColor: colors.green.DEFAULT }}
                style={{ float: "left" }}
              >
                <Typography variant="h6" color={colors.white.DEFAULT}>
                  Create
                </Typography>
              </Button>
              <Button
                onClick={() => setDialogOpen(false)}
                variant="outlined"
                style={{ float: "left", marginLeft: "15px" }}
              >
                <Typography variant="h6" color={colors.green.DEFAULT}>
                  Cancel
                </Typography>
              </Button>
            </Stack>
          </Grid>

          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <p>Task Added successfully!</p>
                <Button onClick={handlePopupClose}>Close</Button>
              </div>
            </div>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default IrrigationDialog;
