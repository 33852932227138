import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Stack,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Radio,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  RadioGroup,
  FormControlLabel,
  Box,
  Alert,
  Snackbar,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Autocomplete,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  Button,
  Fab,
  Divider,
} from "@mui/material";

import { GrStatusGood } from "react-icons/gr";
import InfoPopUp from "../PopUpMsg/InfoDialog";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import { IoIosPerson } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InvoiceProgressPopUp from "../Reports/InvoiceProgressPopUp";
import { FaCalendarAlt } from "react-icons/fa";
import { IoDocumentTextSharp } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MaterialReactTable } from "material-react-table";
import { useSelector } from "react-redux";
import NullScreen from "../PopUpMsg/NullScreen";
import { MdMenuBook } from "react-icons/md";
import { tokens } from "../WMStheme";
import { soupdateAction } from "../actions/soupdateAction";
import { DialogTitle } from "@material-ui/core";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { CiCalendarDate } from "react-icons/ci";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomPagination from "../CustomPagination";
import ApiErrorAlert from "../../ApiErrorAlert";
import { useDispatch } from "react-redux";
import { setSoAction } from "../actions/soAction";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Label } from "semantic-ui-react";
import { AlertDialog, AlertDialogBox } from "../PopUpMsg/AlertDialog";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
// import AddSKUTable from "./AddSKUTable";
import axios from "axios";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import AddSKUTableSO from "./AddSKUTableSO";
import { FaBedPulse } from "react-icons/fa6";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});
// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

const SONewAddPage = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const theme = useTheme();
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const dummyVendorData = [
    {
      customer_id: "1",
      vendor_name: "sameer",
      vendor_code: "123",
      gst: null,
      pan: null,
      aadhaar: null,
      credit_cycle: null,
      tin: null,
      bank_details: null,
      address: null,
      pan_attach: null,
      active: true,
      created_at: null,
    },
  ];
  const navigate = useNavigate();

  const colors = tokens(theme.palette.mode);
  const globalTheme = useTheme();
  const [currentStatus, setCurrentStatus] = useState("pending");
  const [alignTextToLeft, setAlignTextToLeft] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseId, setWarehouseId] = useState("");
  //const [vendorData, setVendorData] = useState(dummyVendorData);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [senttoStatus, setSentToStatus] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [skuData, setSkuData] = useState([]);
  const [isDateFilled, setIsDateFilled] = useState(false);
  const [skuId, setSKUId] = useState("");
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [errorPopupMessage, setErrorPopupMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorPopupOpen1, setErrorPopupOpen1] = useState(false);
  const [errorMessage4, setErrorMessage4] = useState("");
  const [errorPopupOpen3, setErrorPopupOpen3] = useState(false);
  const [errorPopupOpen4, setErrorPopupOpen4] = useState(false);
  const [orderNum, setOrderNum] = useState("");
  const [sOPONo, setSOPONo] = useState("");
  const [invoiceNum, setInvoiceNum] = useState("");
  const [dateFieldValue, setDateFieldValue] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [SoData, setSoData] = useState();
  const [soLoading, setSoLoading] = useState(false);
  // const [offsetofAddSkuTable,setOffsetofAddSkuTable] =useState(1);
  // const [totalPagesofAddSkuTable,setTotalPageofAddSkuTable] =useState('');
  const [PurchaseData, setPurchaseData] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [isAddSkuButtonEnabled, setIsAddSkuButtonEnabled] = useState(false);
  const [skuMasterData, setSKUMasterData] = useState([]);
  const [tempSkuData, setTempSkuData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isrtvFieldVisible, setIsrtvFieldVisible] = useState(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const dispatch = useDispatch();

  const [isreturnFieldVisible, setIsreturnFieldVisible] = useState(false);

  const [isreceivedqtyFieldVisible, setIsreceivedqtyFieldVisible] =
    useState(false);
  const [quantityValues, setQuantityValues] = useState({});
  const [allocatedquantityValues, setAllocatedquantityValues] = useState({});
  const [shippedquantityValues, setShippedQtyValues] = useState({});
  const [damagequantityValues, setDamageQuantityValues] = useState({});
  const [rtvquantityValues, setRtvQunatityValues] = useState({});
  const [invquantityValues, setInvQunatityValues] = useState({});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
  const [rateValues, setRateValues] = useState({});
  const [pendingFieldVisible, setPendingFieldVisible] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [skudialogpopup, setSkuDialogPopUp] = useState(false);
  const itemsPerPage = 50;
  const [responsedata, setResponseData] = useState({});
  const [popdataToDisplay, setPopDataToDisplay] = useState([]);
  const [soId, setSoId] = useState("");
  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const rowsToDisplay = tempSkuData?.filter((row) => row?.visible !== false);

    const slicedData = rowsToDisplay?.slice(startIndex, endIndex);
    setCurrentData(slicedData);

    const calculatedTotalPages = Math.ceil(tempSkuData?.length / itemsPerPage);
    setTotalPages(calculatedTotalPages);
  }, [tempSkuData, currentPage, itemsPerPage]);

  const handleChangePage1 = (newPage) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    // Assuming currentStatus is a string, modify this switch statement accordingly
    switch (currentStatus) {
      case "pending":
        setIsrtvFieldVisible(false);
        setIsreceivedqtyFieldVisible(false); // Set to true or false based on your requirement
        break;
      case "confirm":
        setIsrtvFieldVisible(false);
        setIsreceivedqtyFieldVisible(true);
        setPendingFieldVisible(false); // Set to true or false based on your requirement
        break;
      case "allocation":
        setIsrtvFieldVisible(false);
        setIsreceivedqtyFieldVisible(true); // Set to true or false based on your requirement
        break;
      case "shipped":
        setIsrtvFieldVisible(true);
        setIsreturnFieldVisible(true);
        setIsreceivedqtyFieldVisible(true); // Set to true or false based on your requirement
        break;
      case "return":
        setIsrtvFieldVisible(true);
        setIsreturnFieldVisible(true);
        setIsreceivedqtyFieldVisible(true); // Set to true or false based on your requirement
        break;
      case "zoho":
        setIsrtvFieldVisible(true);
        setIsreturnFieldVisible(true);
        setIsreceivedqtyFieldVisible(true); // Set to true or false based on your requirement
        break;
      case "cancel":
        setIsrtvFieldVisible(false);
        setIsreturnFieldVisible(true);
        setIsreceivedqtyFieldVisible(true); // Set to true or false based on your requirement
        break;
      default:
        setIsrtvFieldVisible(false); // Set to true or false based on your requirement
    }
  }, [currentStatus]);

  const getStatusDisplayText = (backendStatus) => {
    const statusMappings = {
      pending: "Pending",
      confirm: "Confirm",
      allocation: "Allocated",
      shipped: "Shipped",
      return: "Return",
      zoho: "Send to Zoho",

      cancel: "Cancel",
      draft: "Draft",
      // Add more status mappings as needed
    };

    return statusMappings[backendStatus] || backendStatus;
  };
  const StatusTag = ({ status }) => {
    const displayText = getStatusDisplayText(status);

    let tagColor;

    switch (displayText) {
      case "Pending":
        tagColor = "orange";
        break;
      case "Release":
        tagColor = "green";
        break;
      case "Allocated":
        tagColor = "purple";
        break;
      case "Shipped":
        tagColor = "brown";
        break;
      case "Confirm":
        tagColor = "purple";
        break;
      case "Return":
        tagColor = "blue";
        break;
      case "Send to Zoho":
        tagColor = "black";
        break;
      case "Cancel":
        tagColor = "red";
        break;
      case "Draft":
        tagColor = "pink";
        break;
      // Add more cases as needed for different statuses

      default:
        tagColor = "gray";
        break;
    }

    return (
      <Label
        style={{ backgroundColor: tagColor, color: "white", fontSize: "15px" }}
        tag
      >
        {displayText}
      </Label>
    );
  };
  console.log("currentStatus-----------------", currentStatus);

  const handleQuantityChange = (e, skuId, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs", "pc"];
    const parts = value.toString().split(".");
    if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
      value = Math.floor(Number(value));
      setErrorMessage4(`Quantity cannot be in decimal for SKU with UOM in Piece.       
              Click on Proceed to move ahead.`);
      setErrorPopupOpen4(true);
    } else if (value < 0) {
      console.log("order_qty", original?.order_qty);

      setErrorMessage3("Order quantity should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3(
        "Please enter order quantity upto 2 decimal place only ."
      );

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    }
    // Limit the value to 20000
    else if (parseFloat(value) > 50000) {
      value = 50000;
    }

    setQuantityValues((prevValues) => ({
      ...prevValues,
      [skuId]: parseFloat(value),
    }));

    // Update tempSkuData based on edited quantity
    setTempSkuData((prevData) => {
      return prevData?.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, order_qty: parseFloat(value) }
            : item;
        } else {
          // If action is not 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, order_qty: parseFloat(value) }
            : item;
        }
      });
    });
  };
  const handleGrnQuantityChange = (e, skuId, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs", "pc"];
    const parts = value.toString().split(".");
    if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
      value = Math.floor(Number(value));
      setErrorMessage4(`Quantity cannot be in decimal for SKU with UOM in Piece.       
              Click on Proceed to move ahead.`);
      setErrorPopupOpen4(true);
    } else if (value < 0) {
      console.log("order_qty", original?.order_qty);

      setErrorMessage3("Allocation quantity should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3(
        "Please enter Allocation Qty upto 2 decimal place only ."
      );

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    }
    // Limit the value to 20000
    else if (parseFloat(value) > 50000) {
      value = 50000;
    }

    console.log("value in handleGRN Qunatity", value);
    console.log("parse float value in handleGRN Qunatity", parseFloat(value));
    console.log("parse int value in handleGRN Qunatity", parseInt(value));
    if (1) {
      // Update the quantity values state

      // If the entered value is not empty, update the state with the parsed float value
      setAllocatedquantityValues((prevValues) => ({
        ...prevValues,
        [skuId]: parseFloat(value),
      }));

      // Update tempSkuData based on edited quantity

      // Update tempSkuData based on edited quantity
      setTempSkuData((prevData) => {
        return prevData?.map((item) => {
          if (action === "edit") {
            // If action is 'edit', use sku_id_fk
            return item?.item_id === skuId
              ? { ...item, commit_qty: parseFloat(value) }
              : item;
          } else {
            // If action is not 'edit', use sku_id
            return item?.item_id === skuId
              ? { ...item, commit_qty: parseFloat(value) }
              : item;
          }
        });
      });
    } else {
      // setErrorMessage("Enter quantity ");
      setSaveButtonDisabled(true);
      console.log("Invalid quantity value");
    }
  };
  const handleClosePopUp = () => {
    setSkuDialogPopUp(false);
  };
  const handleShippedQuantityChnage = (e, skuId) => {
    const { value } = e.target;

    console.log("Handling quantity change for skuId:", skuId);
    console.log("New quantity value:", value);

    // Update the quantity values state
    setShippedQtyValues((prevValues) => ({
      ...prevValues,
      [skuId]: value,
    }));

    // Update tempSkuData based on edited quantity

    setTempSkuData((prevData) => {
      return prevData.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, shipped_qty: value }
            : item;
        } else {
          // If action is not 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, shipped_qty: value }
            : item;
        }
      });
    });
  };
  const handleDamageQuantityChnage = (e, skuId, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs", "pc"];
    const parts = value.toString().split(".");
    if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
      value = Math.floor(Number(value));
      setErrorMessage4(`Quantity cannot be in decimal for SKU with UOM in Piece.       
              Click on Proceed to move ahead.`);
      setErrorPopupOpen4(true);
    } else if (value < 0) {
      setErrorMessage3("Damage quantity should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3(
        "Please enter order quantity upto 2 decimal place only ."
      );

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    } else if (parseFloat(value) > 50000) {
      value = 50000;
    }

    console.log("Handling quantity change for skuId:", skuId);
    console.log("New quantity value:", value);
    const numericValue = value !== "" ? parseFloat(value) : 0;
    // Update the quantity values state
    setDamageQuantityValues((prevValues) => ({
      ...prevValues,
      [skuId]: parseFloat(value),
    }));

    // Update tempSkuData based on edited quantity
    validateQuantities(
      skuId,
      numericValue,
      rtvquantityValues[skuId],
      invquantityValues[skuId]
    );

    setTempSkuData((prevData) => {
      return prevData.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, damage_rtn: parseFloat(value) }
            : item;
        } else {
          // If action is not 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, damage_rtn: parseFloat(value) }
            : item;
        }
      });
    });
  };
  const handleRtvQuantityChnage = (e, skuId, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs", "pc"];
    const parts = value.toString().split(".");
    if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
      value = Math.floor(Number(value));
      setErrorMessage4(`Quantity cannot be in decimal for SKU with UOM in Piece.       
              Click on Proceed to move ahead.`);
      setErrorPopupOpen4(true);
    } else if (value < 0) {
      setErrorMessage3("Rtv quantity should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3(
        "Please enter order quantity upto 2 decimal place only ."
      );

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    } else if (parseFloat(value) > 50000) {
      value = 50000;
    }
    // if (parseFloat(value) < 1) {
    //   // Update the quantity values state
    //   setErrorMessage4(
    //     `You are tring to Return very less Quantity ( less than 1)`
    //   );
    //   setErrorPopupOpen4(true);
    // }
    const numericValue = value !== "" ? parseFloat(value) : 0;

    console.log("Handling quantity change for skuId:", skuId);
    console.log("New quantity value:", value);

    // Update the quantity values state
    setRtvQunatityValues((prevValues) => {
      const updatedValues = { ...prevValues };
      updatedValues[skuId] = parseFloat(value) || 0;
      return updatedValues;
    });

    // Update tempSkuData based on edited quantity
    validateQuantities(
      skuId,
      damagequantityValues[skuId],
      value,
      invquantityValues[skuId]
    );

    setTempSkuData((prevData) => {
      return prevData.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, rtv_rtn: parseFloat(value) || 0 }
            : item;
        } else {
          // If action is not 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, rtv_rtn: parseFloat(value) || 0 }
            : item;
        }
      });
    });
  };

  const handleInvQuantityChnage = (e, skuId, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs", "pc"];
    const parts = value.toString().split(".");
    if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
      value = Math.floor(Number(value));
      setErrorMessage4(`Quantity cannot be in decimal for SKU with UOM in Piece.       
              Click on Proceed to move ahead.`);
      setErrorPopupOpen4(true);
    } else if (value < 0) {
      setErrorMessage3("Inventory quantity should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parseFloat(value) > 50000) {
      value = 50000;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3(
        "Please enter order quantity upto 2 decimal place only ."
      );

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    }
    console.log("Handling quantity change for skuId:", skuId);
    console.log("New quantity value:", value);
    const numericValue = value !== "" ? parseFloat(value) : 0;

    // Update the quantity values state
    setInvQunatityValues((prevValues) => ({
      ...prevValues,
      [skuId]: parseFloat(value),
    }));

    // Update tempSkuData based on edited quantity
    validateQuantities(
      skuId,
      damagequantityValues[skuId],
      rtvquantityValues[skuId],
      value
    );

    setTempSkuData((prevData) => {
      return prevData.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, inv_rtn: parseFloat(value) }
            : item;
        } else {
          // If action is not 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, inv_rtn: parseFloat(value) }
            : item;
        }
      });
    });
  };
  const validateQuantities = (skuId, damageQty, rtvQty, invQty) => {
    const allocationQty = allocatedquantityValues[skuId] || 0;

    // Convert values to numbers before addition
    damageQty = parseFloat(damageQty) || 0;
    rtvQty = parseFloat(rtvQty) || 0;
    invQty = parseFloat(invQty) || 0;

    const totalQty = damageQty + rtvQty + invQty;

    if (totalQty > allocationQty) {
      setErrorMessage3(
        `Total Return Quantity for Item should not exceed Allocation quantity.`
      );
      setErrorPopupOpen3(true);
    } else {
      setErrorPopupOpen3(false);
    }
  };

  const handleRateChange = (e, skuId) => {
    let { value } = e.target;
    const parts = value.toString().split(".");
    console.log("Handling rate change for skuId:", skuId);
    console.log("New rate value:", value);
    if (value < 0) {
      console.log("value", value);

      setErrorMessage3("Rate should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3("Please enter rate upto 2 decimal place only .");

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    }
    // Limit the value to 200000
    else if (parseFloat(value) > 500000) {
      value = 500000;
    }
    // Update the rate values state
    setRateValues((prevValues) => ({
      ...prevValues,
      [skuId]: parseFloat(value),
    }));

    // Update tempSkuData based on edited rate
    setTempSkuData((prevData) => {
      return prevData.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, rate: parseFloat(value) }
            : item;
        } else {
          // If action is not 'edit', use item_id
          return item.item_id === skuId
            ? { ...item, rate: parseFloat(value) }
            : item;
        }
      });
    });
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const { action, soData, id } = useSelector((state) => state.so);
  console.log("selected SO DATA ", soData);
  console.log("Action", action);

  //  const updatedAction = useSelector(state => state.so);
  //  console.log("updated Action", updatedAction);

  console.log("selectedWarehouse----------", selectedWarehouse);
  useEffect(() => {
    switch (action) {
      case "edit":
        if (id) {
          // If editing, prefill the form with the warehouseData
          console.log("PO  id in edit form2333333333 ", soData?.so_id);
          console.log("edit action performed id chcek here ", id);
          setSoId(id);
          // Update only the fields present in warehouseData
        }
        break;

      case "add":
        // If adding, clear the form data
        console.log("add action performed");

        // If adding, clear the form data
        setSelectedWarehouse("");
        setSelectedCustomerId("");

        // Clear other form fields
        setSoData({
          date_of_delivery: "",
          ext_order_no: "",
          type: "",
          reference_id: "",
          remark: "",
          purchase_skus: [],
          po_no: "",
          customer_id: "",
          warehouse_id: "",
          status: "pending",
          // Add other fields here
        });
        break;

      // Add more cases if needed

      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, soData, id]);
  useEffect(() => {
    if (action === "add" && !isDateFilled) {
      setInfoMessage("Warehouse and Delivery date is mandatory.");
      setInfoPopupOpen(true);
    }
  }, [action, isDateFilled]);

  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  useEffect(() => {
    setIsAddSkuButtonEnabled(customerId !== null && warehouseId !== null);
  }, [customerId, warehouseId]);

  // useEffect(() => {
  //   console.info(rowSelection);
  // }, [rowSelection]);
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose1 = async () => {
    setSentToStatus(null);
    setIsDialogOpen(false);
  };
  const handleDialogClose = async () => {
    if (senttoStatus) {
      await updateSO();
    }
    setIsDialogOpen(false);
  };
  const handleStatusClick = (status) => {
    setSentToStatus(status);
  };

  const handleSelectSKU = () => {
    setDialogOpen(true);
  };

  const handleStatusChange = (event) => {
    const selectedLabel = event.target.value;
    const selectedOption = statusOptions?.find(
      (option) => option.label === selectedLabel
    );

    // If the selectedOption is found, update the senttoStatus state with its value
    if (selectedOption) {
      setSentToStatus(selectedOption?.value);
    }
  };

  const handleAddClick = (currentSelectedRows, rowSelection) => {
    console.log("currentSelectedRows in handleAddClick", currentSelectedRows);
    console.log(
      "rowselection in so page coming from add skutable ",
      rowSelection
    );

    console.log("in handle ADD CLICK tempskudata initially", tempSkuData);
    // Create a copy of tempSkuData to avoid mutating the state directly
    const updatedSkuData = tempSkuData?.map((row) => {
      const isSelected = rowSelection[row?.item_id];
      return {
        ...row,
        flag: true, // Set flag to true if the row is selected
      };
    });
    console.log("updated sku data each time ", updatedSkuData);
    currentSelectedRows?.forEach((newRow) => {
      const existingRowIndex = updatedSkuData?.findIndex(
        (existingRow) => existingRow?.item_id === newRow?.item_id
      );
      console.log(
        "in handle ADD CLICK currentselectedrows while comapring ",
        tempSkuData
      );
      if (existingRowIndex !== -1) {
        console.log("new row in if statement ", newRow);
        // If the row already exists, replace it with the new data
        updatedSkuData[existingRowIndex] = {
          ...tempSkuData[existingRowIndex],
          flag: rowSelection[newRow?.item_id],
          rate: newRow?.rate,
          order_qty: newRow?.order_qty,
        };
        console.log(
          "updated sku data in if stattemnet if thst row already exist ",
          updatedSkuData
        );
      } else {
        console.log("new row in else sattement ", newRow);
        // If the row doesn't exist, add it to the array
        updatedSkuData?.push({
          ...newRow,
          flag: rowSelection[newRow?.item_id], // Set flag to true if the row is selected
        });
        console.log(
          "updated sku data in else stattemnet if thst row already not exist ",
          updatedSkuData
        );
      }
      setRowSelection((prevRowSelection) => ({
        ...prevRowSelection,
        [newRow?.item_id]: true,
      }));
      console.log("row sleection in handle add clicck ", newRow?.item_id);
      console.log("row sleection in handle add clicck ", rowSelection);
    });
    // Update the rowSelection state
    // Filter out the deselected rows from tempSkuData
    const filteredSkuData = updatedSkuData?.filter(
      (row) => rowSelection[row.item_id]
    );
    console.log("filtered sku data  in handle add clicck ", filteredSkuData);
    // Initialize input and rate values for the selected rows
    const initialQuantityValues = {};
    const initialRateValues = {};
    const initialgrnValues = {};
    const initialRtvValues = {};
    const initialInvValues = {};
    const initialDamageValues = {};
    const initialReturnValues = {};

    updatedSkuData?.forEach((row) => {
      const skuId = action === "edit" ? row.item_id : row.item_id;

      initialQuantityValues[skuId] = row?.order_qty || "";
      initialgrnValues[skuId] = row?.commit_qty || "";
      initialRtvValues[skuId] = row?.shipped_qty || "";
      initialRateValues[skuId] = row?.rate || "";
      initialInvValues[skuId] = row?.inv_rtn || 0;
      initialDamageValues[skuId] = row?.damage_rtn || 0;
      initialReturnValues[skuId] = row?.rtv_rtn || 0; // Change 'rate' to the correct field name
    });

    setQuantityValues(initialQuantityValues);
    setRateValues(initialRateValues);
    setAllocatedquantityValues(initialgrnValues);
    setShippedQtyValues(initialRtvValues);
    setDamageQuantityValues(initialDamageValues);
    setInvQunatityValues(initialInvValues);
    setRtvQunatityValues(initialReturnValues);
    setTempSkuData(updatedSkuData);
    setDialogOpen(false);
  };

  const columns = useMemo(() => {
    const handleDeleteClick = (skuId) => {
      console.log("delete function called ");
      if (action === "edit") {
        setTempSkuData((prevData) =>
          prevData?.map((row) => {
            if (row?.item_id === skuId) {
              return { ...row, flag: false, visible: false };
            }
            return row;
          })
        );
        setRowSelection((prevRowSelection) => {
          const newRowSelection = { ...prevRowSelection };
          delete newRowSelection[skuId];
          return newRowSelection;
        });
      } else {
        setTempSkuData((prevData) =>
          prevData?.filter((row) => {
            return row.item_id !== skuId;
          })
        );

        setRowSelection((prevRowSelection) => {
          const newRowSelection = { ...prevRowSelection };
          delete newRowSelection[skuId];
          return newRowSelection;
        });
      }
    };
    //   // Check both item_id and item_id when action is edit
    //   const existingSkuId = tempSkuData?.find((row) => {
    //     return row.item_id === skuId || row.item_id === skuId;
    //   });

    //   // Filter out the deleted row from tempSkuData
    //   const updatedSkuData = tempSkuData?.filter((row) => {
    //     return existingSkuId ? row.item_id !== skuId && row.item_id !== skuId : row.item_id !== skuId;
    //   });

    //   console.log("updated sku data  ", updatedSkuData);

    //   // Update tempSkuData
    //   setTempSkuData(updatedSkuData);

    //   // If the row is selected, update rowSelection
    //   if (rowSelection[skuId]) {
    //     setRowSelection((prevRowSelection) => {
    //       const newRowSelection = { ...prevRowSelection };
    //       delete newRowSelection[skuId];
    //       return newRowSelection;
    //     });
    //   }
    // };

    //   const handleDeleteClick = (skuId) => {
    //     console.log("delete function called ");
    //     // Conditionally use skuId or skuIdFk based on the action
    //     const actualSkuId = skuId;
    // console.log("sku id ",skuId);
    //  console.log("actual sku id ", skuId);
    //     // Filter out the deleted row from tempSkuData
    //     // Filter out the deleted row from tempSkuData
    //     console.log("tempSky=uData ",tempSkuData);
    //     const updatedSkuData = tempSkuData?.filter((row) => {
    //       return action === "edit"
    //         ? row.item_id !== actualSkuId
    //         : row.item_id !== actualSkuId;
    //     });

    //     console.log("updated sku data  ", updatedSkuData);
    //     // Update tempSkuData
    //     setTempSkuData(updatedSkuData);

    //     // If the row is selected, update rowSelection
    //     if (rowSelection[skuId]) {
    //       setRowSelection((prevRowSelection) => {
    //         const newRowSelection = { ...prevRowSelection };
    //         delete newRowSelection[actualSkuId];
    //         return newRowSelection;
    //       });
    //     }
    //   };
    const additionalColumns3 =
      currentStatus === "pending"
        ? [
            {
              header: "Actions",
              accessorKey: "actions",
              size: 5,
              Cell: ({ row }) => {
                return (
                  <>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        handleDeleteClick(
                          action === "edit"
                            ? row.original?.item_id || row.original?.item_id
                            : row.original.item_id
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                );
              },
            },
          ]
        : [];
    const commonColumns = [
      {
        header: "Item Name",
        accessorKey: "item_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Item Code",
        accessorKey: "item_code",
        size: 10,
        enableEditing: false,
      },
      {
        header: "UOM",
        accessorKey: "uom",
        size: 10,
        enableEditing: false,
      },

      {
        header: "Quantity",
        accessorKey: "order_qty",
        size: 10,

        Cell: ({ row }) => {
          const { original } = row;
          const inputValue = quantityValues[original?.item_id] || "";
          const skuId =
            action === "edit" ? original?.item_id : original?.item_id;
          return (
            <TextField
              value={
                quantityValues[skuId] !== null &&
                quantityValues[skuId] !== undefined
                  ? quantityValues[skuId]
                  : ""
              }
              // value={quantityValues[skuId] || ""}
              size="small"
              type="number"
              onChange={(e) => handleQuantityChange(e, skuId, original)}
              disabled={
                currentStatus === "confirm" ||
                currentStatus === "allocation" ||
                currentStatus === "return" ||
                currentStatus === "shipped" ||
                currentStatus === "zoho" ||
                currentStatus === "cancel"
              }
              inputProps={{
                step: "any",
              }}
            />
          );
        },
      },
      {
        header: "Rate",
        accessorKey: "rate",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const rateinputValue = rateValues[original?.item_id] || "";
          const skuId =
            action === "edit" ? original?.item_id : original?.item_id;

          return (
            <TextField
              value={
                rateValues[skuId] !== null && rateValues[skuId] !== undefined
                  ? rateValues[skuId]
                  : ""
              }
              type="number"
              size="small"
              onChange={(e) => handleRateChange(e, skuId)}
              disabled={
                currentStatus === "confirm" ||
                currentStatus === "zoho" ||
                currentStatus === "cancel" ||
                currentStatus === "allocation" ||
                currentStatus === "shipped" ||
                currentStatus === "return"
              }
              inputProps={{
                min: 0,
              }}
            />
          );
        },
      },
    ];

    const additionalColumns1 = isreceivedqtyFieldVisible
      ? [
          {
            header: "Allocation Quantity",
            accessorKey: "commit_qty",
            size: 10,
            enableEditing: true,
            // enableEditing: isreceivedqtyFieldVisible,
            Cell: ({ row }) => {
              const { original } = row;
              const inputValue =
                allocatedquantityValues[original?.item_id] || "";
              const skuId =
                action === "edit" ? original?.item_id : original?.item_id;
              const orderQty = row.original?.order_qty;
              console.log("sku id in cell rate ", skuId);
              return (
                <TextField
                  value={
                    allocatedquantityValues[skuId] !== null &&
                    allocatedquantityValues[skuId] !== undefined &&
                    allocatedquantityValues[skuId] !== ""
                      ? allocatedquantityValues[skuId]
                      : 0
                  }
                  size="small"
                  type="number"
                  // onChange={(e) => handleGrnQuantityChange(e, skuId)}
                  onChange={(e) => {
                    handleGrnQuantityChange(e, skuId, original);
                    // console.log(
                    //   "-------------------",
                    //   e.target.value,
                    //   allocatedquantityValues[skuId]
                    // );
                  }}
                  disabled={
                    currentStatus === "shipped" ||
                    currentStatus === "return" ||
                    currentStatus === "cancel" ||
                    currentStatus === "zoho"
                  }
                  inputProps={{
                    step: "any",
                  }}
                />
              );
            },
          },
        ]
      : [];
    // const additionalColumns = isrtvFieldVisible
    //   ? [
    //       {
    //         header: "Shipped Qty",
    //         accessorKey: "shipped_qty",
    //         size: 10,
    //         // enableEditing: !isreturnFieldVisible,
    //         Cell: ({ row }) => {
    //           const { original } = row;
    //           const inputValue = shippedquantityValues[original.item_id] || "";
    //           const skuId =
    //             action === "edit" ? original.item_id : original.item_id;
    //           console.log("sku id in cell rate ", skuId);
    //           return (
    //             <TextField
    //               value={shippedquantityValues[skuId] || ""}
    //               size="small"
    //               onChange={(e) => handleShippedQuantityChnage(e, skuId)}
    //               disabled={currentStatus === "zoho"  || currentStatus === "cancel" || currentStatus === "return"}
    //               inputProps={{
    //                 min: "0.1",
    //               }}
    //             />
    //           );
    //         },
    //       },
    //     ]
    //   : [];
    const additionalColumns4 = isreturnFieldVisible
      ? [
          {
            header: "RTV ",
            accessorKey: "rtv_rtn",
            size: 10,
            // enableEditing: !isreturnFieldVisible,
            Cell: ({ row }) => {
              const { original } = row;
              const inputValue = rtvquantityValues[original.item_id] || "";
              const skuId =
                action === "edit" ? original.item_id : original.item_id;
              console.log("sku id in cell rate ", skuId);
              return (
                <TextField
                  value={
                    rtvquantityValues[skuId] !== null &&
                    rtvquantityValues[skuId] !== undefined
                      ? rtvquantityValues[skuId]
                      : ""
                  }
                  size="small"
                  onChange={(e) => handleRtvQuantityChnage(e, skuId, original)}
                  disabled={
                    currentStatus === "return" ||
                    currentStatus === "zoho" ||
                    currentStatus === "cancel"
                  }
                  type="number"
                  inputProps={{
                    step: "any",
                  }}
                />
              );
            },
          },

          {
            header: "Inventory Return",
            accessorKey: "inv_rtn",
            size: 10,
            // enableEditing: !isreturnFieldVisible,
            Cell: ({ row }) => {
              const { original } = row;
              const inputValue = invquantityValues[original.item_id] || "";
              const skuId =
                action === "edit" ? original.item_id : original.item_id;
              console.log("sku id in cell rate ", skuId);
              return (
                <TextField
                  value={
                    invquantityValues[skuId] !== null &&
                    invquantityValues[skuId] !== undefined
                      ? invquantityValues[skuId]
                      : ""
                  }
                  size="small"
                  onChange={(e) => handleInvQuantityChnage(e, skuId, original)}
                  disabled={
                    currentStatus === "return" ||
                    currentStatus === "zoho" ||
                    currentStatus === "cancel"
                  }
                  type="number"
                  inputProps={{
                    step: "any",
                  }}
                />
              );
            },
          },
          {
            header: "Missing Qty/Damage",
            accessorKey: "damage_rtn",
            size: 10,
            // enableEditing: !isreturnFieldVisible,
            Cell: ({ row }) => {
              const { original } = row;
              const inputValue = damagequantityValues[original.item_id] || "";
              const skuId =
                action === "edit" ? original.item_id : original.item_id;
              console.log("sku id in cell rate ", skuId);
              return (
                <TextField
                  value={
                    damagequantityValues[skuId] !== null &&
                    damagequantityValues[skuId] !== undefined
                      ? damagequantityValues[skuId]
                      : ""
                  }
                  size="small"
                  type="number"
                  onChange={(e) =>
                    handleDamageQuantityChnage(e, skuId, original)
                  }
                  disabled={
                    currentStatus === "return" ||
                    currentStatus === "zoho" ||
                    currentStatus === "cancel"
                  }
                  inputProps={{
                    step: "any",
                  }}
                />
              );
            },
          },
        ]
      : [];
    return [
      ...commonColumns,

      ...additionalColumns1,
      // ...additionalColumns,
      ...additionalColumns4,
      ...additionalColumns3,
    ];
  }, [
    currentStatus,
    tempSkuData,
    rowSelection,
    pendingFieldVisible,
    isrtvFieldVisible,
    isreceivedqtyFieldVisible,
    rateValues,
    quantityValues,
    allocatedquantityValues,
    shippedquantityValues,
    damagequantityValues,
    rtvquantityValues,
    invquantityValues,
  ]);
  useEffect(() => {
    // Check if currentStatus is defined before using toLowerCase()
    if (currentStatus) {
      // Define the mapping of currentStatus to options
      const statusMapping = {
        pending: [
          { label: "Pending", value: "pending" },
          { label: "Confirm", value: "confirm" },
          { label: "Cancel", value: "cancel" },
        ],
        confirm: [
          { label: "Confirm", value: "confirm" },
          // { label: "Shipped", value: "shipped" },
          { label: "Allocation", value: "allocation" },
          { label: "Cancel", value: "cancel" },
        ],
        allocation: [
          { label: "Allocation", value: "allocation" },
          { label: "Shipped", value: "shipped" },
        ],
        shipped: [
          { label: "Return", value: "return" },
          { label: "Sent To Zoho", value: "zoho" },
        ],
        return: [{ label: "Sent To Zoho", value: "zoho" }],
        zoho: [],
        // Add more mappings as needed
      };

      // Set the options based on the currentStatus
      setStatusOptions(statusMapping[currentStatus.toLowerCase()] || []);
    }
  }, [currentStatus]);
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const handleChangePage = (currentSelectedRows) => {
    console.log("currentSelectedRows in handleChangePage", currentSelectedRows);
    const updatedSkuData = tempSkuData?.map((existingRow) => {
      const matchingRow = currentSelectedRows?.find(
        (newRow) => newRow?.item_id === existingRow?.item_id
      );
      return matchingRow ? matchingRow : existingRow;
    });
    currentSelectedRows?.forEach((newRow) => {
      const exists = updatedSkuData?.some(
        (existingRow) => existingRow?.item_id === newRow?.item_id
      );
      if (!exists) {
        updatedSkuData.push(newRow);
      }
    });

    setTempSkuData(updatedSkuData);
  };

  const handleCloseDialog = () => {
    console.log("Handle close dialog clicked");
    setDialogOpen(false);
  };

  const createSO = async () => {
    const hasEmptyFields = tempSkuData?.some(
      (row) =>
        !row.rate ||
        row.rate.toString().trim() === "" ||
        !row.order_qty ||
        row.order_qty.toString().trim() === ""
    );

    if (hasEmptyFields) {
      console.log(
        "Error: Please make sure all selected rows have a non-empty quantity & rate."
      );
      // Display an error message and prevent saving
      setErrorMessage(
        "Please make sure all selected rows have a non-empty quantity & rate."
      );
      setErrorPopupOpen(true);
      // setSaveButtonDisabled(true);
      return;
    }

    try {
      setSoLoading(true);
      // Determine the endpoint for creating a new warehouse
      const endpoint = API_ENDPOINTS.POST_WMS_CREATE_SO_ORDER(
        "pending",
        selectedCustomerId,
        selectedWarehouse
      );
      console.log("API Endpoint:", endpoint);
      console.log("tempskuData", tempSkuData);
      const updatedSkuList = tempSkuData?.map((item) => {
        return {
          ...item,
          rate: isNumeric(item?.rate) ? parseFloat(item?.rate) : 0,
          order_qty: isNumeric(item?.order_qty)
            ? parseFloat(item?.order_qty)
            : 0,
          shipped_qty: isNumeric(item?.shipped_qty)
            ? parseFloat(item?.shipped_qty)
            : 0,
          commit_qty: isNumeric(item?.commit_qty)
            ? parseFloat(item?.commit_qty)
            : 0,
          damage_rtn: isNumeric(item?.damage_rtn)
            ? parseFloat(item?.damage_rtn)
            : 0,
          rtv_rtn: isNumeric(item?.rtv_rtn) ? parseFloat(item?.rtv_rtn) : 0,
          inv_rtn: isNumeric(item?.inv_rtn) ? parseFloat(item?.inv_rtn) : 0,
          tax: isNumeric(item?.tax) ? parseFloat(item?.tax) : 0,
        };
      });

      function isNumeric(value) {
        return (
          value !== null && value !== undefined && value !== "" && !isNaN(value)
        );
      }

      console.log("updated SKU List in update SO  ", updatedSkuList);
      // Log the WarehouseData before making the API call
      console.log("Creating Data:", tempSkuData);
      console.log("SO Data:", SoData);
      const payload = {
        date_of_delivery: SoData?.date_of_delivery || null,
        ext_order_no: SoData?.ext_order_no || null,
        po_no: SoData?.po_no || null,
        reference_id: SoData?.reference_id || null,
        remark: SoData?.remark || null,
        sale_items: updatedSkuList || [],
        customer_id: selectedCustomerId || null,
        warehouse_id: selectedWarehouse || null,
        status: "pending",
      };
      console.log("POn payload  Data:", payload);

      // Send the POST request to create a new warehouse
      const response = await axiosInstance.post(
        endpoint,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
        // WarehouseData,
      );

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("SO created successfully!");
        setSuccessPopupOpen(true);
        setSoLoading(false);
        setSoId(response.data?.data?.order_id);
        dispatch(setSoAction("edit", null, response.data?.data?.order_id));
        console.log("updated action in create PO", action);
      } else {
        s;
        console.error("Failed to create the PO");
        setErrorMessage("Failed to create the PO. Please try again.");
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setSoLoading(false);
      console.error("Error occurred while creating the PO:", error);
    }
  };
  useEffect(() => {
    const fetchDataForEdit = async () => {
      //const updatedAction = useSelector(state => state.so.action);

      console.log("SO  id in fetch data function ", soId);
      console.log("action in fetch data ", action);
      console.log("SO  id in edit form ", id);
      if (action === "edit" && id) {
        try {
          // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint for fetching PO data by ID
          const response = await axiosInstance.get(
            API_ENDPOINTS.GET_WMS_SO_DETAILS(id)
          );

          if (response.status === 200) {
            const poDataFromApi = response?.data?.Data;
            const saleItems = poDataFromApi?.sale_items || [];
            console.log(
              "delivery date coming here ",
              poDataFromApi?.date_of_delivery
            );
            const deliveryDate = new Date(
              Number(poDataFromApi?.date_of_delivery)
            );
            console.log("delivery date in so ", deliveryDate);
            const formattedDeliveryDate = `${deliveryDate.getDate()}/${
              deliveryDate.getMonth() + 1
            }/${deliveryDate.getFullYear()}`;
            // Adjust this based on your API response structure
            setSoData((prevData) => ({
              ...prevData,
              ...poDataFromApi,
              date_of_delivery: deliveryDate.getTime(),
            }));

            console.log(
              "formatted  delivery date in so ",
              formattedDeliveryDate
            );
            const selectedVendor = vendorData?.find(
              (vendor) => vendor?.vendor_name === poDataFromApi?.vendor_name
            );
            const skuDetails = poDataFromApi?.sku_list || [];

            // Initialize input and rate values for the selected rows

            const initialQuantityValues = {};
            const initialRateValues = {};
            const initialGrnQuantityValues = {};
            const initialRtvQuantityValues = {};
            const initialDamageValues = {};
            const initialInvValues = {};
            const initialReturnValues = {};
            poDataFromApi?.sale_items?.forEach((sku) => {
              initialQuantityValues[sku?.item_id] = sku?.order_qty || "";
              initialRateValues[sku?.item_id] = sku.rate || "";
              initialGrnQuantityValues[sku?.item_id] = sku?.commit_qty || "";
              initialRtvQuantityValues[sku?.item_id] = sku?.shipped_qty || "";
              initialDamageValues[sku?.item_id] = sku?.damage_rtn || 0;
              initialReturnValues[sku?.item_id] = sku?.rtv_rtn || 0;
              initialInvValues[sku?.item_id] = sku?.inv_rtn || 0;
            });

            console.log("setgrnqunatityvalues", initialGrnQuantityValues);
            console.log("setquantityvalues : ", initialQuantityValues);
            console.log("setratevalues", initialRateValues);
            setQuantityValues(initialQuantityValues);
            setRateValues(initialRateValues);
            setAllocatedquantityValues(initialGrnQuantityValues);
            setShippedQtyValues(initialRtvQuantityValues);
            setDamageQuantityValues(initialDamageValues);
            setInvQunatityValues(initialInvValues);
            setRtvQunatityValues(initialReturnValues);
            setSelectedCustomerId(poDataFromApi?.customer_id);
            setSelectedCustomerName(poDataFromApi?.customer_name);
            setOrderNum(poDataFromApi?.order_no);
            setSOPONo(poDataFromApi?.po_no);
            setInvoiceNum(poDataFromApi?.invoice_no);
            setCustomerId(poDataFromApi?.customer_id);
            setWarehouseId(poDataFromApi?.warehouse_id_fk);
            setSelectedWarehouse(poDataFromApi?.warehouse_id_fk);
            dispatch(setSoAction("edit", SoData, poDataFromApi?.so_id));
            setSoId(poDataFromApi?.so_id);
            setCurrentStatus(poDataFromApi?.sale_order_status);
            setTempSkuData(poDataFromApi?.sale_items);
            console.log(
              "when action edit then tempskudata coming from backend",
              poDataFromApi?.sale_items
            );
            //setCurrentStatus('partialrecieved');

            console.log("current status , ", poDataFromApi?.sale_order_status);
            console.log("testtttttttttttttt", poDataFromApi?.sale_items);
            console.log("PO in edit form ", poDataFromApi);
          } else {
            console.log("Error fetching PO data");
          }
        } catch (error) {
          console.log("Error fetching PO data:", error);
        }
      }
    };

    fetchDataForEdit();
  }, [soData, vendorData, soId, action, id]);

  console.log("temp sku state  ", tempSkuData);
  const updateSO = async () => {
    try {
      console.log("Function called 1111111111", soId);

      if (!soId) {
        console.error("SO id is missing.");
        setErrorMessage("Please try Again !!!");
        return;
      }

      const hasEmptyFields = tempSkuData?.some(
        (row) =>
          !row?.rate ||
          row?.rate.toString().trim() === "" ||
          !row?.order_qty ||
          row?.order_qty.toString().trim() === ""
      );

      console.log("Function called after checking empty fields");

      if (hasEmptyFields) {
        console.log(
          "Error: Please make sure all selected rows have a non-empty quantity & rate."
        );

        // Display an error message and prevent saving
        setErrorMessage(
          "Please make sure all selected rows have a non-empty quantity & rate."
        );
        setErrorPopupOpen(true);
        setSentToStatus(null);
        setSaveButtonDisabled(true);
        console.log("Save button disabled");
      } else {
        console.log("Function called 333333333333");

        console.log("Is dialog open before", isDialogOpen);

        if (senttoStatus === "shipped") {
          const sumReceivedQty = tempSkuData?.reduce(
            (total, item) => total + (parseFloat(item?.commit_qty) || 0),
            0
          );

          if (sumReceivedQty <= 0) {
            // Display error message if sum of received quantities is less than or equal to 0
            console.error(
              "Error: Sum of  quantities is less than or equal to 0."
            );
            setErrorMessage3(
              "You cannot proceed further as you are trying to proceed with total 0  quantity."
            );

            setErrorPopupOpen3(true);
            setSentToStatus(null);
            return;
          }
        }
        if (senttoStatus === "return") {
          console.log("tempsku data in status return ---->", tempSkuData);

          const hasReturnValues = tempSkuData?.some((item) => {
            // Set to 0 if null
            const damageRtn = item.damage_rtn
              ? parseFloat(item?.damage_rtn)
              : 0;
            const invRtn = item.inv_rtn ? parseFloat(item?.inv_rtn) : 0;
            const rtvRtn = item.rtv_rtn ? parseFloat(item?.rtv_rtn) : 0;

            const totalReturnQty = damageRtn + invRtn + rtvRtn;
            console.log(
              "totalReturnQty data in status return ---->",
              totalReturnQty
            );
            return totalReturnQty > parseFloat(item?.shipped_qty);
          });

          if (hasReturnValues) {
            console.log(
              "hasReturnValues  in status return ---->",
              hasReturnValues
            );
            console.error(
              "Error: You are trying to return more than the committed quantity."
            );
            setErrorMessage3(
              "You cannot create a return for this order. Please ensure that the sum of all return quantities is less than or equal to the committed quantity."
            );
            setErrorPopupOpen3(true);
            setSentToStatus(null);
            return;
          }
        }

        setIsDialogOpen(true);
        console.log("Is dialog open after", isDialogOpen);
        console.log("s data ", SoData);
        if (senttoStatus) {
          setSoLoading(true);
          const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_SO_ORDER(
            soId
          )}?status=${encodeURIComponent(senttoStatus)}`;
          console.log("API Endpoint:", endpoint);
          console.log("temp sku state in update SO  ", tempSkuData);

          function isNumeric(value) {
            return (
              value !== null &&
              value !== undefined &&
              value !== "" &&
              !isNaN(value)
            );
          }
          const skuList = Array.isArray(tempSkuData) ? tempSkuData : [];

          const updatedSkuList = skuList?.map((item) => {
            return {
              ...item,
              rate: isNumeric(item?.rate) ? parseFloat(item?.rate) : 0,
              order_qty: isNumeric(item?.order_qty)
                ? parseFloat(item?.order_qty)
                : 0,
              shipped_qty: isNumeric(item?.shipped_qty)
                ? parseFloat(item?.shipped_qty)
                : 0,
              commit_qty: isNumeric(item?.commit_qty)
                ? parseFloat(item?.commit_qty)
                : 0,
              damage_rtn: isNumeric(item?.damage_rtn)
                ? parseFloat(item?.damage_rtn)
                : 0,
              rtv_rtn: isNumeric(item?.rtv_rtn) ? parseFloat(item?.rtv_rtn) : 0,
              inv_rtn: isNumeric(item?.inv_rtn) ? parseFloat(item?.inv_rtn) : 0,
              tax: isNumeric(item?.tax) ? parseFloat(item?.tax) : 0,
            };
          });

          console.log("updated SKU List in update SO  ", updatedSkuList);
          const payload = {
            date_of_delivery: SoData?.date_of_delivery || null,
            ext_order_no: SoData?.ext_order_no || null,
            type: SoData?.type || null,
            reference_id: SoData?.reference_id || null,
            remark: SoData?.remark || null,
            po_no: SoData?.po_no || null,
            sale_items: updatedSkuList,
            order_num: SoData?.order_no || null,
            customer_id: selectedCustomerId || null,
            warehouse_id_fk: selectedWarehouse || null,
            sale_order_status: senttoStatus,
          };

          console.log("payloaddd---", payload);

          try {
            const response = await axiosInstance.put(endpoint, payload, {
              headers: {
                "Content-Type": "application/json",
              },
            });

            console.log("Response:", response);

            if (response.status >= 200 && response.status < 300) {
              // Successful response

              setResponseData(response?.data);
              console.log("Response Data", response?.data);
              const responseDataForDialog = response?.data?.data?.result || [];
              setCurrentStatus(response?.data?.data?.status);

              setSentToStatus(null);
              if (response?.data?.data?.result?.length > 0) {
                setPopDataToDisplay(responseDataForDialog);
                setSkuDialogPopUp(true);
                setSoId(response?.data?.data?.so_id);
                dispatch(
                  setSoAction("edit", SoData, response?.data?.data?.so_id)
                );
                console.log("Opening Dialog");
                console.log(
                  "SO Id in opening dialog ",
                  response?.data?.data?.so_id
                );
              } else {
                console.log("Navigating to /so");
                // navigate("/so");
                dispatch(
                  setSoAction("edit", SoData, response?.data?.data?.so_id)
                );
              }

              setSuccessMessage("SO Edited  Successfully!");
              setSuccessPopupOpen(true);
              setSoLoading(false);
            } else {
              // Non-successful response
              if (response.status === 400) {
                console.error("Bad Request: ", response?.data?.msg);
                setErrorMessage1(response?.data?.msg);
                setErrorPopupOpen1(true);
                setSentToStatus(null);
                await handleDialogClose1();
                setSoLoading(false);
              }
            }
          } catch (error) {
            console.error("Error occurred in API request:", error);
            // setErrorMessage("Failed to update data. Please try again.");
            // setErrorPopupOpen(true);

            if (error.response && error.response.status === 400) {
              console.error("Bad Request: ", error.response?.data?.msg);
              setErrorMessage1(error.response?.data?.msg);
              setErrorPopupOpen1(true);
              setSentToStatus(null);
              await handleDialogClose1();
              setSoLoading(false);
            } else if (
              error.response &&
              error.response.status >= 500 &&
              error.response.status < 600
            ) {
              console.error("Server Error: ", error.response?.data?.msg);
              setErrorMessage("Server Error. Please try again later.");
              setErrorPopupOpen(true);
              setSentToStatus(null);
              setSoLoading(false);
            } else {
              console.error(
                "Failed to update the data",
                error.response?.data?.msg
              );
              setErrorMessage(error.response?.data?.msg);
              setErrorPopupOpen(true);
              setSentToStatus(null);
              setSoLoading(false);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error occurred in try-catch block:", error);
      setErrorMessage("Failed! Please try again.");
      setSentToStatus(null);
      setSoLoading(false);

      setErrorPopupOpen(true);
    }
  };

  const handleSubmit = async () => {
    try {
      if (action === "edit") {
        // If editing, send a PUT request
        await updateSO();
      } else {
        // If adding, send a POST request
        await createSO();
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error("Error submitting po details:", error);
      setErrorMessage("Something Went Wrong !");
    }
  };
  const handleWarehouseChange = (e) => {
    const selectedWarehouseId = e.target.value;
    setSelectedWarehouse(selectedWarehouseId);
    setWarehouseId(selectedWarehouseId);
    // Console log the selected warehouse ID
    console.log("Selected Warehouse ID:", selectedWarehouseId);
    // Check if both warehouse and vendor IDs are selected before making the API call

    // You can store the selected warehouse ID in state for later use
    // setYourOtherState(selectedWarehouseId);
  };
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    // Convert the selected date to epoch format
    const epochDate = selectedDate ? new Date(selectedDate).getTime() : null;
    setIsDateFilled(!!selectedDate);

    setDateFieldValue(selectedDate);
    // Check if epochDate is a valid number
    if (!isNaN(epochDate)) {
      // Update PoData with the epoch date
      handleChange("date_of_delivery", epochDate);
    } else {
      console.error("Invalid date format:", selectedDate);
    }
  };

  const handleChange = (field, value) => {
    setSoData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    const fetchCustomerData = async (id) => {
      console.log(" id in customer function", id);
      setIsLoading(true);
      // setVendorData(dummyVendorData);
      try {
        const response = await axiosInstance.get(
          API_ENDPOINTS.GET_WMS_CUSTOMER_LIST_FILTER,
          {
            params: {
              warehouse_id: id,
              limit: 1000,
              offset: 1,
              status: true,
            },

            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setIsLoading(false);
        if (response.status >= 200 && response.status < 305) {
          const data = response?.data?.data?.customer_list || [];
          setCustomerData(data);

          console.log("customer data", data);
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMessage("Please Try Again");
        setErrorPopupOpen(true);
      }
    };
    if (warehouseId) {
      fetchCustomerData(warehouseId);
    }
  }, [warehouseId]);
  // useEffect(() => {
  //   // Check if both warehouse and vendor IDs are selected before making the API call
  //   if (selectedWarehouse && selectedCustomerId) {
  //     fetchSKUwrtCustomer(selectedWarehouse, selectedCustomerId);
  //   }
  // }, [selectedWarehouse, selectedCustomerId]);
  const handleBack = () => {
    navigate("/so");
  };

  // const fetchSKUwrtCustomer = async (warehouseId, customerId) => {
  //   console.log("warehouse id in vendor function", warehouseId);
  //   setIsLoading(true);

  //   try {
  //     const response = await axiosInstance.get(
  //       API_ENDPOINTS.GET_WMS_SO_CUSTOMER_SKU_LIST,
  //       {
  //         params: {
  //           warehouse_id: warehouseId,
  //           customer_id: customerId,
  //           //customer_id:'01HKQ202D59462BKAV0YEKHF4S',
  //           limit: 6,
  //           offset: offsetofAddSkuTable,
  //         },
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setIsLoading(false);
  //     if (response.status >= 200 && response.status < 305) {
  //       const data = response?.data?.data?.sku_list || [];
  //       setSkuData(data);
  //      // totalPagesofAddSkuTable(response?.data?.data?.total_pages);
  //       console.log("pages in data ", response?.data?.data?.total_pages);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);

  //   }

  // };
  const handleChangeCustomer = (event, newValue) => {
    const selectedCustomerId = newValue?.customer_id || "";
    setSelectedCustomerId(selectedCustomerId);
    setSelectedCustomer(selectedCustomerId);
    setCustomerId(selectedCustomerId);

    console.log("Selected customer ID:", setCustomerId);
    // if (selectedWarehouse && selectedCustomerId) {
    //   fetchSKUwrtCustomer(selectedWarehouse, selectedCustomerId);
    // }
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    height: "50vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };

  const containerStyle = {
    maxHeight: "50vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
        }}
      >
        <Typography
          // variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%", fontSize: 19 }}
        >
          <MdMenuBook
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp; {action === "edit" ? `Orders (${orderNum})` : "Orders"}
        </Typography>

        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            startIcon={
              <FileDownloadIcon
                style={{ color: colors.white.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              backgroundColor: colors.green.DEFAULT,
            }}
            disabled={
              currentStatus === "pending" ||
              currentStatus === "confirm" ||
              currentStatus === "allocation" ||
              currentStatus === "cancel"
            }
            size="small"
            onClick={() => setOpenInvoiceDialog(true)}
          >
            <Typography variant="h7" fontFamily="Lexend Deca">
              Download
            </Typography>
          </Button>
          {saveButtonDisabled && (
            <Button
              variant="contained"
              startIcon={
                <IoMdAddCircleOutline
                  style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                />
              }
              sx={{
                borderColor: colors.green.DEFAULT,
                backgroundColor: colors.green.DEFAULT,
              }}
              disabled={
                (action === "add" && tempSkuData?.length === 0) ||
                currentStatus === "zoho" ||
                currentStatus === "cancel" ||
                (action === "add" && !isDateFilled) ||
                selectedWarehouse === null
              }
              onClick={handleSubmit}
              title="Save"
            >
              <Typography variant="h7" color={colors.white.DEFAULT}>
                Perform Next Action
              </Typography>
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={
              <IoArrowBackCircleOutline
                style={{ color: colors.white.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              backgroundColor: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.white.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
      {soLoading ? (
        <>
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={soLoading} />
          </div>
        </>
      ) : (
        <Grid container spacing={2.5} sx={{ marginTop: "0%" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Box
              p={2}
              bgcolor="#ffff"
              height="20vh"
              borderRadius="10px"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            >
              <Grid
                container
                spacing={1.2}
                sx={{ marginTop: "0%", marginLeft: "0.7%" }}
              >
                <Grid item xs={12} sm={2.2}>
                  <FormControl fullWidth>
                    <InputLabel>Select Warehouse*</InputLabel>
                    <Select
                      label="Select Warehouse"
                      value={selectedWarehouse}
                      onChange={handleWarehouseChange}
                      size="small"
                      disabled={currentStatus !== "pending"}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Warehouse" }}
                      required
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <CiCalendarDate />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {Array.isArray(warehouseData) &&
                        warehouseData?.map((day) => (
                          <MenuItem key={day?.id} value={day?.id}>
                            {day?.wh_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2.5} md={2.5}>
                  {action === "edit" ? (
                    <>
                      <TextField
                        label="Customer"
                        name="Customer"
                        size="small"
                        value={SoData?.customer_name}
                        fullWidth
                        disabled
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <IoIosPerson />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormControl fullWidth>
                        <Autocomplete
                          options={customerData || []}
                          getOptionLabel={(option) =>
                            capitalizeFirstLetter(option?.customer_name)
                          }
                          value={
                            customerData.find(
                              (customer) =>
                                customer.customer_id === selectedCustomerId
                            ) || null
                          }
                          onChange={handleChangeCustomer}
                          disabled={!selectedWarehouse || action === "edit"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer"
                              fullWidth
                              required
                              size="small"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      edge="start"
                                      size="small"
                                      disabled
                                    >
                                      <IoIosPerson />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={2.2} sx={{ marginTop: "-1.2%" }}>
                  <TextField
                    label="Delivery Date"
                    name="date"
                    type="date"
                    size="small"
                    required
                    value={
                      action === "edit"
                        ? SoData?.date_of_delivery
                          ? new Date(Number(SoData?.date_of_delivery))
                              .toISOString()
                              .split("T")[0]
                          : dateFieldValue
                        : dateFieldValue // Use the appropriate field for the initial value
                    }
                    onChange={handleDateChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                      minLength: 3,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <FaCalendarAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2.2} sx={{ marginTop: "-1.2%" }}>
                  <TextField
                    label="External Order Number"
                    name="date"
                    size="small"
                    value={SoData?.ext_order_no}
                    onChange={(e) =>
                      handleChange("ext_order_no", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                      minLength: 3,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <IoDocumentTextSharp />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {action === "edit" ? (
                  <Grid item xs={12} sm={2.2} sx={{ marginTop: "-1.2%" }}>
                    <TextField
                      label="Order Number"
                      name="Order Number"
                      type="Order Number"
                      size="small"
                      value={orderNum}
                      readonly
                      //onChange={(e) => handleChange("reference_id", e.target.value)}
                      // value={newUserRoleState?.new_email}
                      fullWidth
                      // value={customerData?.remark}
                      // onChange={(e) => handleChange('remark', e.target.value)}
                      margin="normal"
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                        minLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              {/* <DescriptionIcon /> */}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={2.2} sx={{ marginTop: "-1.2%" }}>
                    <TextField
                      label="Order Number"
                      name="Order Number"
                      type="Order Number"
                      size="small"
                      value={orderNum}
                      disabled={action == "add"}
                      //onChange={(e) => handleChange("reference_id", e.target.value)}
                      // value={newUserRoleState?.new_email}
                      fullWidth
                      required
                      // value={customerData?.description}
                      // onChange={(e) => handleChange('description', e.target.value)}
                      margin="normal"
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                        minLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              {/* <DescriptionIcon /> */}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                spacing={1.5}
                sx={{ marginTop: "0%", marginLeft: "0.5%" }}
              >
                <Grid item xs={12} sm={2.2}>
                  <TextField
                    label="Description"
                    name="Description"
                    type="Description"
                    size="small"
                    fullWidth
                    value={SoData?.remark}
                    onChange={(e) => handleChange("remark", e.target.value)}
                    // onChange={(e) => handleChange('remark', e.target.value)}
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                      minLength: 3,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            {/* <DescriptionIcon /> */}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2.5}
                    md={2.5}
                    sx={{ marginTop: "0.05%" }}
                  >
                    <TextField
                      label="PO No"
                      name="PO No"
                      // type="number"
                      size="small"
                      // value={sOPONo}
                      value={SoData?.po_no}
                      onChange={(e) =>
                        handleChange("po_no", e.target.value)}
                      fullWidth
                      disabled={
              currentStatus === "zoho" ||
              currentStatus === "cancel"
            }
                      margin="normal"
                      variant="outlined"
                      inputProps={{
                        maxLength: 20,
                        minLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              {/* <DescriptionIcon /> */}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                {action === "edit" && (
                  <Grid item xs={12} sm={2.2} md={2.2}>
                    <TextField
                      label="Invoice Number"
                      name="Invoice Number"
                      type="Invoice Number"
                      size="small"
                      disabled={action == "edit"}
                      value={invoiceNum}
                      readonly
                      //onChange={(e) => handleChange("reference_id", e.target.value)}
                      // value={newUserRoleState?.new_email}
                      fullWidth
                      // value={customerData?.description}
                      // onChange={(e) => handleChange('description', e.target.value)}
                      margin="normal"
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                        minLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              {/* <DescriptionIcon /> */}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                
                
                
                <Grid
                  item
                  xs={12}
                  sm={1.1}
                  md={1.1}
                  sx={{ marginTop: "1.6%", marginLeft: "0.2%" }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: 100 }}>
                    Current Status
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2.2} md={2.2} sx={{ marginTop: "1.2%" }}>
                  <StatusTag status={currentStatus} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              height="60vh"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            >
              <Stack
                spacing={0}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ height: "8vh" }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  startIcon={
                    <IoMdAddCircleOutline
                      style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                    />
                  }
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: colors.white.DEFAULT,
                  }}
                  onClick={handleSelectSKU}
                  title="Add SKU"
                  disabled={
                    !isAddSkuButtonEnabled ||
                    !pendingFieldVisible ||
                    !warehouseId ||
                    !customerId ||
                    currentStatus === "shipped" ||
                    currentStatus === "zoho" ||
                    currentStatus === "return" ||
                    currentStatus === "allocation" ||
                    currentStatus === "cancel"
                  }
                >
                  <Typography variant="h7" color={colors.white.DEFAULT}>
                    Add SKU Here For Order Creation
                  </Typography>
                </Button>
              </Stack>
              <Grid container spacing={1} style={containerStyle}>
                {isLoading ? (
                  <div style={loaderContainerStyle}>
                    <ScaleLoader color="#495057" loading={isLoading} />
                  </div>
                ) : tempSkuData?.length === 0 ? (
                  <NullScreen
                    containerheight="48vh"
                    containerWidth="100%"
                    screenType="Info"
                    message="Add SKU Here For Order Creation"
                  />
                ) : (
                  <>
                    <div style={gridContainerStyle}>
                      <ThemeProvider theme={tableTheme}>
                        <MaterialReactTable
                          columns={columns}
                          data={currentData}
                          getRowId={(row) =>
                            action === "edit" ? row?.item_id : row?.item_id
                          }
                          state={{ rowSelection }}
                          onSelectionModelChange={(selectedModel) => {
                            const newSelection = {};
                            selectedModel.forEach((selectedRow) => {
                              newSelection[selectedRow] = true;
                            });
                            setRowSelection(newSelection);
                          }}
                          enableGrouping
                          enableStickyHeader
                          enableFullScreenToggle={false}
                          enableDensityToggle={false}
                          enableColumnActions={false}
                          enableColumnFilters={false}
                          enablePagination={false}
                          muiTableContainerProps={{ sx: { maxHeight: "32vh" } }}
                          initialState={{
                            density: "compact",
                          }}
                          muiTableHeadCellProps={{
                            sx: {
                              fontWeight: "650",
                              color: "#34519fe6",
                              fontSize: "15px",
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr": {
                                backgroundColor: "#fff",
                              },
                            },
                          }}
                          muiToolbarAlertBannerChipProps={{ color: "primary" }}
                        />
                      </ThemeProvider>
                      <Grid
                        container
                        justifyContent="flex-end"
                        sx={{ marginTop: "0.1%" }}
                      >
                        <CustomPagination
                          totalPages={totalPages}
                          currentPage={currentPage}
                          onPageChange={handleChangePage1}
                        />
                      </Grid>
                    </div>
                  </>
                )}
              </Grid>
            </Box>
            {dialogOpen && (
              <AddSKUTableSO
                handleChangePage={handleChangePage}
                handleAddClick={handleAddClick}
                selectedCustomerId={selectedCustomerId}
                selectedWarehouse={selectedWarehouse}
                warehouseId={warehouseId}
                customerId={customerId}
                handleCloseDialog={handleCloseDialog}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose1}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#f0f0f0",
            padding: "15px",
          }}
        >
          <Typography
            variant="h5"
            style={{ marginLeft: "0.1%" }}
            color={colors.green.DEFAULT}
          >
            Select Status to Proceed
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleDialogClose1();
            }}
            sx={{
              position: "absolute",
              right: 1.9,
              top: 1,
              color: "grey",
            }}
          >
            <CloseSharpIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "2.3%", flexDirection: "column" }}
          >
            {statusOptions?.map((option) => (
              <Grid
                item
                key={option?.label}
                xs={6}
                sm={6}
                md={6}
                sx={{ padding: "3.2%", marginLeft: "25%", marginRight: "20%" }}
              >
                <Fab
                  variant="extended"
                  size="medium"
                  sx={{
                    backgroundColor:
                      senttoStatus === option.value ? "#34519fe6" : "#2949c73d",
                    "&:hover": {
                      backgroundColor: "#34519fe6",
                      color: "#fff",
                    },
                    padding: "3.2%",
                    width: "100%", // Adjust the width as needed
                    height: "100%",
                  }}
                  onClick={() => handleStatusClick(option?.value)}
                >
                  <GrStatusGood
                    size={22}
                    style={{
                      marginRight: "8%",
                      color: "#000",
                    }}
                    sx={{
                      "&:hover": {
                        color: "#fff",
                      },
                    }}
                  />
                  <Typography
                    color={colors.black.DEFAULT}
                    fontWeightBold
                    fontWeight={"900"}
                    fontSize={"28px"}
                    sx={{
                      "&:hover": {
                        color: "#fff",
                      },
                    }}
                  >
                    {option?.label}
                  </Typography>
                </Fab>
              </Grid>
            ))}
          </Grid>

          <Divider
            variant="middle"
            sx={{ color: "#000", marginTop: "2%" }}
          ></Divider>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              fullwidth
              style={{ marginTop: "5%", marginBottom: "2%" }}
            >
              <Button
                variant="contained"
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                }}
                onClick={() => {
                  handleDialogClose();
                }}
                disabled={!senttoStatus}
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={skudialogpopup}
        onClose={handleClosePopUp}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle> Inventory Not Allocated </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SKU Name</TableCell>
                  <TableCell>SKU Code</TableCell>
                  <TableCell>UOM</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popdataToDisplay?.map((sku) => (
                  <TableRow key={sku?.sku_code}>
                    <TableCell>{sku?.sku_name}</TableCell>
                    <TableCell>{sku?.sku_code}</TableCell>
                    <TableCell>{sku?.uom}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopUp} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {openInvoiceDialog && (
        <InvoiceProgressPopUp
          setOpenDialog={setOpenInvoiceDialog}
          so_id={soId}
        />
      )}

      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
      <InfoPopUp
        open={infoPopupOpen}
        onClose={() => setInfoPopupOpen(false)}
        message={infoMessage}
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
      {errorPopupMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setErrorPopupMessage("")}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {errorPopupMessage}
          </Alert>
        </Snackbar>
      )}
      <AlertDialogBox
        open={errorPopupOpen1}
        okayText="Okay"
        handleOkayClick={() => setErrorPopupOpen1(false)}
        onClose={() => setErrorPopupOpen1(false)}
        content={errorMessage1}
      />
      <AlertDialogBox
        open={errorPopupOpen4}
        okayText="Proceed"
        handleOkayClick={() => setErrorPopupOpen4(false)}
        onClose={() => setErrorPopupOpen4(false)}
        content={errorMessage4}
      />
      <AlertDialogBox
        open={errorPopupOpen3}
        okayText="Okay"
        handleOkayClick={() => setErrorPopupOpen3(false)}
        onClose={() => setErrorPopupOpen3(false)}
        content={errorMessage3}
      />
    </>
  );
};

export default SONewAddPage;
