import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Stack,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Typography,
  FormHelperText,
} from "@mui/material";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ReactQuill from "react-quill";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const Seed = ({
  dialogOpen,
  setDialogOpen,
  masterDialogState,
  fetchTaskData,
}) => {
  const [seedState, setSeedState] = useState({
    title: "",
    selectedColor: "#3174ad",
    selectedColorDescription: null,
    description: "",
    startLocation: "",
    endLocation: "",
    showPopup: false,
    open: false,
    savedText: "",
    showAttachment: false,
    showAddDescription: true,
    selectedProofs: [],
    selectedFields: [],
    descriptions: [],
    dataTypes: [],
  });
  const {
    title,
    selectedColor,
    selectedColorDescription,
    description,
    showPopup,
    selectedProofs,
    savedText,
    descriptions,
    showAttachment,
    showAddDescription,
    dataTypes,
    startLocation,
    endLocation,
  } = seedState;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const updateState = (key, value) => {
    setSeedState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [proofDetailsAdded, setProofDetailsAdded] = useState(false);
  const handleSubmit = async (e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);
    console.log("entered !");
    e.preventDefault();
    const formData = {
      task_title: title,
      priority_color: selectedColor,
      priority_color_description: selectedColorDescription,
      startLocation: startLocation,
      endLocation: endLocation,
      proof: selectedProofs?.length > 0 ? selectedProofs : null, // Set value based on the selectedProofs array
      instruction: description,
      masterDialogData: masterDialogState,
    };
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_TASK_MANAGEMENT_CREATETASK,
        formData,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

      if (response.status >= 200 && response.status < 305) {
        console.log("Pruning Task Allocated");
        updateState("showPopup", true);
      } else {
        console.log("Pruning Task not Allocated");
      }
      console.log(response?.data);
      console.log("in middle !");
    } catch (error) {
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      console.log(error);
    }

    console.log({
      formData,
    });

    e.target.reset();
  };

  const handlePopupClose = () => {
    updateState("open", false);
    updateState("showPopup", false);
    setDialogOpen(false);
    fetchTaskData();
  };

  const handleStartLocationChange = (event) => {
    updateState("startLocation", event.target.value);
  };

  const handleSelectedColorDescriptionChange = async (e) => {
    const selectedColorDescription = e.target.value;
    updateState("selectedColorDescription", selectedColorDescription);
  };

  const handleEndLocationChange = (event) => {
    updateState("endLocation", event.target.value);
  };

  const handleTitleChange = (event) => {
    updateState("title", event.target.value);
  };

  const handleColorChange = (event) => {
    updateState("selectedColor", event.target.value);
  };

  const handleDescriptionChange = (e, index) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = e.target.value;
    updateState("descriptions", updatedDescriptions);
  };

  const handleDataTypeChange = (e) => {
    updateState("dataTypes", [e.target.value]);
  };

  const handleSave = () => {
    const proofs = descriptions.map((desc, index) => {
      const attachmentType = dataTypes[index] ? `${dataTypes[index]}` : "";
      setProofDetailsAdded(true);
      return { description: desc, attachmentType };
    });
    updateState("selectedProofs", proofs); // Update selectedProofs here
    updateState(
      "savedText",
      proofs
        .map((proof) => `${proof.description} | ${proof.attachmentType}`)
        .join("\n")
    );
    updateState("descriptions", []);
    updateState("dataTypes", []);
    updateState("showAddDescription", true);
    updateState("showAttachment", false);
  };

  const handleAddDescription = () => {
    updateState("showAddDescription", false);
    updateState("showAttachment", true);
    updateState("descriptions", [...descriptions, ""]);
  };

  const modules = {
    toolbar: {
      container: [[{ list: "ordered" }]],
    },
  };

  const formats = ["list"];

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { minWidth: "480px", minHeight: "480px" } }}
    >
      <DialogTitle>
        <Typography variant="h3" color={colors.green.DEFAULT}>
          Seed Procurement
        </Typography>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} marginTop={"28px"}>
              <TextField
                label="Activity Title"
                value={title}
                onChange={handleTitleChange}
                fullWidth
                size="small"
                autoFocus
                required
                inputProps={{ maxLength: 15 }}
              />
              <FormHelperText>Max limit 15 words</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
              <TextField
                id="input-with-icon-textfield"
                label="Priority Color"
                value={selectedColorDescription}
                size="small"
                inputProps={{ maxLength: 15 }}
                onChange={handleSelectedColorDescriptionChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <input
                        type="color"
                        id="color"
                        value={selectedColor}
                        onChange={handleColorChange}
                        style={{
                          width: "24px",
                          height: "24px",
                          padding: "0",
                          border: "none",
                          borderRadius: "50%",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          {/* <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} marginTop={"12px"}>
              <TextField
                label="Start Location"
                value={startLocation}
                onChange={handleStartLocationChange}
                fullWidth
                autoFocus
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} marginTop={"12px"}>
              <TextField
                label="End Location"
                value={endLocation}
                onChange={handleEndLocationChange}
                fullWidth
                autoFocus
                size="small"
              />
            </Grid>
          </Grid> */}

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
              {descriptions?.map((description, index) => (
                <TextField
                  key={index}
                  label="Proof title"
                  fullWidth
                  size="small"
                  margin="normal"
                  value={description}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => handleDescriptionChange(e, index)}
                />
              ))}
              {proofDetailsAdded ? (
                <>
                  <Tooltip
                    title="Proof Type already added !"
                    placement="right"
                    disableHoverListener={!proofDetailsAdded}
                  >
                    <Button
                      variant="outlined"
                      endIcon={
                        <AddIcon style={{ color: colors.green.DEFAULT }} />
                      }
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      onClick={handleAddDescription}
                      disabled
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Proof List
                      </Typography>
                    </Button>
                  </Tooltip>
                </>
              ) : (
                showAddDescription && (
                  <Tooltip
                    title="Click to add the attachments that supervisor needs to send as a proof of completion of task !"
                    placement="right"
                  >
                    <Button
                      variant="outlined"
                      endIcon={
                        <AddIcon style={{ color: colors.green.DEFAULT }} />
                      }
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      onClick={handleAddDescription}
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Proof List
                      </Typography>
                    </Button>
                  </Tooltip>
                )
              )}
            </Grid>

            {showAttachment && (
              <>
                <Grid item xs={12} sm={4} style={{ marginTop: "10px" }}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Select Proof type</InputLabel>
                    <Select
                      value={dataTypes[0] || ""}
                      label="Select Proof type"
                      inputProps={{ "aria-label": "Select Proof Type" }}
                      displayEmpty
                      size="small"
                      onChange={handleDataTypeChange}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                    >
                      <MenuItem value="PDF">PDF</MenuItem>
                      <MenuItem value="DOC">DOC</MenuItem>
                      <MenuItem value="Image">Image</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={1.2}>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: colors.green.DEFAULT,
                      color: colors.green.DEFAULT,
                    }}
                    style={{
                      float: "left",
                      marginLeft: "16px",
                      marginTop: "19px",
                    }}
                    onClick={handleSave}
                  >
                    <Typography variant="h6" color={colors.green.DEFAULT}>
                      Save
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
              <ReactQuill
                style={{
                  height: "100px",
                  width: "100%",
                }}
                placeholder="Additional instructions for this activity!"
                modules={modules}
                formats={formats}
                value={description} // Add value prop
                onChange={(value) => updateState("description", value)} // Update description state
              />
            </Grid>
            {savedText && (
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  marginTop: "-48%",
                  marginLeft: "52%",
                  backgroundColor: "#ecf4f0",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  # {savedText}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-start"
          ></Grid>

          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-start"
          >
            <Grid item xs={12} sm={12} style={{ marginTop: "90px" }}>
              <Stack
                spacing={1}
                direction="row"
                style={{
                  height: "32px",
                  marginTop: "2%",
                  marginRight: "0%",
                  marginLeft: "69%",
                }}
              >
                <Button
                  type="submit" // Added type="submit" to trigger form submission
                  variant="contained"
                  sx={{ backgroundColor: colors.green.DEFAULT }}
                  style={{ float: "left" }}
                >
                  <Typography variant="h6" color={colors.white.DEFAULT}>
                    Create
                  </Typography>
                </Button>
                <Button
                  onClick={() => setDialogOpen(false)}
                  variant="outlined"
                  style={{ float: "left", marginLeft: "15px" }}
                >
                  <Typography variant="h6" color={colors.green.DEFAULT}>
                    Cancel
                  </Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>

          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <Typography>Task Added successfully!</Typography>
                <Button onClick={handlePopupClose}>Close</Button>
              </div>
            </div>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Seed;
