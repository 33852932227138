import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Stack,
  FormControlLabel,
  Radio,
  useTheme,
  RadioGroup,
  FormControl,
  InputAdornment,
  IconButton,
  Alert,
  Slide,
} from "@mui/material";
import { tokens } from "../WMS/WMStheme";
import logoImage from "../Assets/Logo/NewLogo.png";
import aws from "../Assets/aws.png";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Popover from "@mui/material/Popover";
import axios from "axios";
import Attendanceicon from "../Assets/AttendanceIMG/attendanceIcon.png";
import { BASE_OMS_URL, API_ENDPOINTS } from "../ApiEndpoint/ApiEndpoint";
import "./AttLogin.css"; // Add your custom CSS for styling
import FamboLogo from "../Assets/LogoFambo.png";
import SuccessPopup from "../Attendance/PopupComponent/SuccessPopup";
import ErrorPopup from "../Attendance/PopupComponent/ErrorPopup";
const AttLoginPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedOption, setSelectedOption] = useState("customer");
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = () => {
    localStorage.setItem("userName", email);
    if (email === "hr@fambo.in" && password === "123456789") {
      navigate("/attendance/home");
    } else {
      setErrorMessage("Invalid Credentials");
      setErrorPopupOpen(true);
    }
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  const getButtonColor = () => {
    return selectedOption === "sales" ? "green" : "blue";
  };

  return (
    <>
      <div
        className="login-page4"
        style={{
          backgroundImage: `url(${require("../Assets/AttendanceIMG/AttendanceImg.jpg")})`,
          backdropFilter: "blur(5px)",
        }}
      >
        <Grid container sx={{ width: "70%", height: "100%" }}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-35%",
                marginLeft: "-12%",
              }}
            >
              <img
                src={logoImage}
                alt="Logo"
                style={{ maxWidth: "50%", maxHeight: "50%" }}
                onError={(e) => {
                  console.log("prachi", e.target.src); // Display a placeholder image or a fallback
                  // Alternatively, you can handle the error in a custom way (e.g., show an error message)
                }}
              />
              <Typography variant="h5" color={colors.green.DEFAULT}>
                Cultivating Transformation
              </Typography>
              <Typography
                variant="h5"
                color={colors.green.DEFAULT}
                sx={{ marginTop: "2%" }}
              >
                powered by
              </Typography>
              <img
                src={aws}
                alt="Powered by AWS"
                style={{
                  maxWidth: "30%",
                  maxHeight: "30%",
                  marginTop: "-10%",
                  boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)",
                }}
                onError={(e) => {
                  console.log("prachi", e.target.src);
                }}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFFD9",
                width: "95%",
                height: "67%",
                padding: "50px",
                borderRadius: "20px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                marginRight: "22%",
                marginTop: "-20%",
              }}
            >
              <div className="overlay">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Attendanceicon} // Replace with the actual path to your image
                    alt="Icon"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <Typography variant="h3" color="#F50E16EE" gutterBottom>
                    Attendance Admin Panel
                  </Typography>
                </div>
                <div style={{ marginTop: "2%" }}>
                  <Typography variant="h3" color="#605D5DEE" gutterBottom>
                    Login
                  </Typography>{" "}
                </div>
                <div style={{ marginTop: "12%" }}>
                  <TextField
                    label="Email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div style={{ margin: "14px 0" }}>
                    {/* Add space between email TextField and password TextField */}
                  </div>

                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Password"
                      type={isPasswordVisible ? "text" : "password"}
                      fullWidth
                      variant="outlined"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {isPasswordVisible ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <div style={{ margin: "14px 0" }}></div>
                  <div style={{ marginTop: "18%" }}>
                    <Button
                      className={`loginButton ${getButtonColor()}`}
                      fullWidth
                      variant="contained"
                      onClick={handleLogin}
                      sx={{ backgroundColor: "#F50E16EE" }}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <SuccessPopup
          open={successPopupOpen}
          onClose={() => setSuccessPopupOpen(false)}
          message={successMessage}
        />
        <ErrorPopup
          open={errorPopupOpen}
          onClose={() => setErrorPopupOpen(false)}
          message={errorMessage}
        />
      </div>
    </>
  );
};

export default AttLoginPage;
