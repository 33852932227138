import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Rings } from "react-loader-spinner";
import {
  BASE_URL,
  API_ENDPOINTS,
} from "../../../../src/ApiEndpoint/ApiEndpoint";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

function Map() {
  const [position, setPosition] = useState([28.502160, 77.398257]); // Initial position
  const [locationNames, setLocationNames] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const fetchFarmDetails = async () => {
      try {
        const response = await axiosInstance.get(
          API_ENDPOINTS.GET_STATIC_DASHBOARD_WEATHER,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response?.data?.rows;
        console.log("data in Map.js----------------", data);
        setData(data); // Set the data in state
        if (data?.length > 0) {
          // Get the position from the first farm
          const { x: latitude, y: longitude } = data[0]?.locations_geographic;
          setPosition([latitude, longitude]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchFarmDetails();
  }, []);

  const fetchLocationNames = async () => {
    const promises = data.map(async (farm) => {
      // const { x: latitude, y: longitude } = farm.locations_geographic;
      let latitude, longitude;
      if (
        farm.locations_geographic &&
        farm.locations_geographic.x &&
        farm.locations_geographic.y
      ) {
        latitude = farm.locations_geographic.x;
        longitude = farm.locations_geographic.y;
      } else {
        const defaultGeographicData = JSON.parse(
          localStorage.getItem("locations_geographic")
        );
        if (
          defaultGeographicData &&
          defaultGeographicData.x &&
          defaultGeographicData.y
        ) {
          latitude = defaultGeographicData.x;
          longitude = defaultGeographicData.y;
        } else {
          // If default values are also missing, set some default fallback values
          latitude = 28.502160;
          longitude = 77.398257;
        }
      }
      console.log("Latitudeeeeeeeeeee-------------", latitude);
      console.log("Longitudeeeeeeeeeee-------------", longitude);
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
        );
        const data = await response.json();
        const name = data.display_name;
        return name;
      } catch (error) {
        console.log("Error fetching location name:", error);
        return "Error fetching location name";
      }
    });

    // Wait for all promises to resolve
    Promise.all(promises).then((locationNames) => {
      // Update location names in state
      setLocationNames(locationNames);
    });
  };

  useEffect(() => {
    if (data.length > 0) {
      fetchLocationNames();
    }
  }, [data]); // Fetch location names whenever data changes

  const handleMarkerClick = (event) => {
    const marker = event.target;
    const { lat, lng } = marker.getLatLng();
    fetchLocationNames(lat, lng);
  };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Rings
        height="80"
        width="80"
        color="#4fa94d"
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  ) : (
    <MapContainer
      style={{
        height: "95%",
        width: "97%",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
      center={position}
      zoom={13}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {data.map((farm, index) => {
        let latitude, longitude;

        // Check if locations_geographic exists in farm object
        if (
          farm.locations_geographic &&
          farm.locations_geographic.x &&
          farm.locations_geographic.y
        ) {
          latitude = farm.locations_geographic.x;
          longitude = farm.locations_geographic.y;
        } else {
          // Retrieve default values from local storage if locations_geographic is missing
          const storedLocation = localStorage.getItem("locations_geographic");
          const defaultGeographicData = storedLocation
            ? JSON.parse(storedLocation)
            : null;

          console.log(
            "defaultGeographicData-------------",
            defaultGeographicData
          );

          if (
            defaultGeographicData &&
            defaultGeographicData.x &&
            defaultGeographicData.y
          ) {
            latitude = defaultGeographicData.x;
            longitude = defaultGeographicData.y;
          } else {
            // If default values are also missing, set some default fallback values
            latitude = 28.502160;
            longitude = 77.398257;
          }
        }

        return (
          <Marker
            key={index}
            position={[latitude, longitude]}
            eventHandlers={{ click: handleMarkerClick }}
          >
            <Popup>{locationNames[index] || "Loading..."}</Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
}

export default Map;
