
// reducers.js
const initialState = {
    action: '',
    vendorData: null,
  };
  export const vendorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_VENDOR_ACTION':
        return {
          action: action.payload.action,
          vendorData: action.payload.vendorData,
        };
      default:
        return state;
    }
  };