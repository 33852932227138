
// reducers.js
const initialState = {
    action: '',
    customerGroupData: null,
  };
  export const customerGroupReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_CUSTOMERGROUP_ACTION':
        return {
          action: action.payload.action,
          customerGroupData: action.payload.customerGroupData,
        };
      default:
        return state;
    }
  };