import React, { useState, useEffect } from "react";
import FilterTab from "../../../Components/ExpenseTrackerComponent/ManagementComponent/FilterTab/FilterTab";
import { Grid, Stack } from "@mui/material";
import DisplayTab from "../../../Components/ExpenseTrackerComponent/ManagementComponent/DisplayTab/DisplayTab";
import CardView from "../../../Components/ExpenseTrackerComponent/ManagementComponent/CardView/CardView";
import axios from "axios";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import ApiErrorAlert from "../../../ApiErrorAlert";
import SaveAlert from "../../../SaveAlert";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const ManagementMainPage = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const [filterValues, setFilterValues] = useState({
    start_date: "" || null,
    end_date: "" || null,
    transaction_type: null,
    farm_id: null,
  });
  const [dataFromBackend, setDataFromBackend] = useState(null); // State to hold data from the backend
  // Fetch data from the backend when filterValues change, but only if any filter value is not null
  const [paginationInfo, setPaginationInfo] = useState({
    offset: 1,
    limit: 10, // Set an initial value for limit
  });
 

  useEffect(() => {
    const shouldFetchData =
      filterValues.start_date !== null ||
      filterValues.end_date !== null ||
      filterValues.transaction_type !== null ||
      filterValues.farm_id !== null;

    if (shouldFetchData) {
      fetchDataFromBackend(filterValues);
    }
  }, [paginationInfo]);

  // Function to handle filter changes and update filterValues state
  const handleFilterChange = (newFilterValues) => {
    setFilterValues(newFilterValues);
  };

  const handleApplyClick = () => {
    fetchDataFromBackend(filterValues);
  };

  const handlePaginationChange = (newPaginationInfo) => {
    // Update the paginationInfo state with the new values
    setPaginationInfo(newPaginationInfo);
  };
  // Define a callback function to handle pagination changes

  const fetchDataFromBackend = async (filterValues) => {
    console.log("filter values in fetchDataFromBackend function in ManagementMainPage----------", filterValues);
    try {
      let apiUrl = `${API_ENDPOINTS.GET_COST_MANAGEMENT_ALL_DATA}?`;

      // Include all parameters in the URL, even if they are empty or null
      apiUrl += `start_date=${filterValues.start_date || ""}&`;
      apiUrl += `end_date=${filterValues.end_date || ""}&`;
      apiUrl += `transaction_type=${filterValues.transaction_type || ""}&`;
      apiUrl += `farm_id=${filterValues.farm_id || ""}&`;
      // Include paginationInfo in the URL
      apiUrl += `offset=${paginationInfo.offset || ""}&`;
      apiUrl += `limit=${paginationInfo.limit || ""}&`;

      // Remove the trailing '&' if it exists
      apiUrl = apiUrl.replace(/&$/, "");

      const response = await axiosInstance.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authorization headers
        },
      });

      if (response.status >= 200 && response.status < 305) {
        const data = response?.data;
        console.log("Data from backenddddddddddddddddddd:", data);
        setDataFromBackend(data);
      } else {
        // Handle errors here
        console.log("Error fetching data from backend");
      }
    } catch (error) {
      // Handle network or other errors here
      // setApiErrorOpen(true);
      console.log("Error:", error);
    }
  };

  console.log("dataFromBackendddddd", dataFromBackend);
  console.log("filtered values", filterValues);
  return (
    <>
      <Stack direction="column" spacing={1}>
        <Grid item xs={12} sm={9} md={6} sx={{ borderRadius: "30px" }}>
          <FilterTab
            onFilterChange={handleFilterChange}
            onApplyClick={handleApplyClick}
          />
        </Grid>
        <Grid item xs={12} sm={9} md={6}>
          <CardView data={dataFromBackend} />
        </Grid>
        <Grid item xs={12} sm={9} md={6}>
          <DisplayTab
            paginationInfo={paginationInfo} // Pass the paginationInfo state
            onPaginationChange={handlePaginationChange}
            datasend={dataFromBackend}
          />
        </Grid>
      </Stack>
      {/* <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      /> */}
    </>
  );
};

export default ManagementMainPage;
