import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  Autocomplete,
  TextField,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import NullScreen from "../PopUpMsg/NullScreen";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { tokens } from "../WMStheme";

import { useTheme } from "@mui/material";
import { ulid } from "ulid";

import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import SocketReportPopup from "../../Attendance/HomePage/SocketReportPopup";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../PopUpMsg/SuccessPopUp";
import ErrorPopup from "../PopUpMsg/ErrorPopUp";
import { LuWarehouse } from "react-icons/lu";

import { IoIosPerson } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { GiFruitBowl } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { Divider } from "antd";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

dayjs.extend(customParseFormat);

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const columns = [
  { id: "sku_name", label: "SKU" },
  { id: "sku_code", label: "SKU Code" },
  { id: "sku_uom", label: "UOM" },
  { id: "family_name", label: "Family" },
  { id: "total_purchase_price", label: "Total Purchase Price" },
  { id: "total_selling_price", label: "Total Selling Price" },
  { id: "total_lot_qty", label: "Total Lot Qty." },
  { id: "profit", label: "Profit" },
];

const PNLReport = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  const [salesReportData, setSalesReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [skuNames, setSkuNames] = useState([]);
  const [skuId, setSkuId] = useState(null);
  const [generatedId, setGeneratedId] = useState(null);

  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [filterValues, setFilterValues] = useState({
    start_date: startDate,
    // end_date: endDateTime.getTime(),
    limit: 16,
    offset: offset,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: startDate,
    // end_date: endDateTime.getTime(),
    sku_id: null,
  });
  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = currentDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  console.log("current date:", getCurrentDate());

  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      // end_date: endDate,
      sku_id: skuId,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, skuId]);

  // useEffect(() => {

  //   if (startDate !== null) {
  //     fetchGridData(filterValues);
  //   }
  // }, [filterValues]);
  useEffect(() => {
    fetchSKUNames();
  }, []);

  const handleApplyClick = () => {
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      // end_date: temporaryFilterValues.end_date,
      limit: 16,
      offset: 1,
      sku_id: temporaryFilterValues.sku_id,
    };
    setStartDate(temporaryFilterValues.start_date);
    // setEndDate(temporaryFilterValues.end_date);
    setSkuId(temporaryFilterValues.sku_id);
    setFilterValues(newFilterValues);
    fetchGridData(newFilterValues);
  };

  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchGridData(updatedValues);
  };

  const fetchSKUNames = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_SKU_DATA, {
        params: {
          limit: 1000,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response?.data?.Data?.sku_list;
      console.log("dataaaaa------", data);
      setSkuNames(data);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  // const handleDateChange = (dates, dateStrings) => {
  //   if (dateStrings && dateStrings[0] && dateStrings[1]) {
  //     const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();

  //     setStartDate(startDateTime);
  //     // setEndDate(endDateTime);
  //   }
  // };
  function dateToEpoch(dateString) {
    const epochMilliseconds = moment(dateString, "DD/MM/YYYY").valueOf();
    return epochMilliseconds;
  }

  console.log(dateToEpoch("13/03/2024")); // Example usage

  console.log("current date:", getCurrentDate());

  const handleDateChange = (dates, dateStrings) => {
    console.log("datestrings ", dateStrings);

    const startDateTime = moment(dateStrings, "DD/MM/YYYY").valueOf();

    setStartDate(startDateTime);

    console.log("current date  ", getCurrentDate());

    console.log("start date in epoch ", startDateTime);
    console.log(
      "start date (milliseconds) after converting to dd-mm-yyyy",
      formatDate(startDateTime)
    );
  };

  const fetchGridData = async (newParams) => {
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_PROFITLOSS_GRID_DATA,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      console.log("data in fetchGridData PROFITLOSS--------", data);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setTotalPages(data?.total_pages);
        setSalesReportData(data.sale_report);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleGenerateReportClick = async () => {
    setIsLoading(true);
    const requestId = ulid();
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_PROFITLOSS_GRID_DATA,
        {
          params: {
            ...filterValues,
            download: 1,
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  const handleRefreshClick = () => {
    const startdatedefault = getCurrentDate();
    const initialValues = {
      limit: 16,
      offset: 1,
      start_date: dateToEpoch(startdatedefault),
      // end_date: endDateTime.getTime(),
    };
    setOffSet(1);
    setSkuId(null);
    setStartDate(null);
    // setEndDate(endDateTime.getTime());
    setFilterValues(initialValues);
    setRefreshCount((prevVal) => prevVal + 1);
    setSalesReportData([]);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const containerStyle = {
    maxHeight: "78vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "78vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    marginTop: "1%",
    display: "flex",
    height: "71vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };

  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent() {
    return (
      <TableBody>
        {salesReportData &&
          salesReportData?.map((row, index) => (
            <StyledTableRow key={index}>
              {columns?.map((column) => (
                <StyledTableCell key={column?.id} align="center">
                  {row[column?.id]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
      </TableBody>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Typography variant="h1" color={colors.green.DEFAULT}>
            Profit & Loss Analysis
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "8vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1} marginTop={"6px"}>
              <Grid item xs={12} sm={1.3} md={1.3}>
                <DatePicker
                  format="DD/MM/YYYY"
                  key={refreshCount}
                  onChange={handleDateChange}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={skuNames || []}
                    getOptionLabel={(option) => option?.sku_name || ""}
                    value={skuNames.find((sku) => sku.id === skuId) || null}
                    onChange={(event, newValue) => {
                      setSkuId(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select SKU"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <GiFruitBowl />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!startDate || !endDate}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={5.1} md={5.1}></Grid>
              <Grid item xs={12} sm={2} md={2} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                  }}
                  size="small"
                  onClick={handleGenerateReportClick}
                  disabled={!salesReportData?.length}
                >
                  <Typography variant="h6" fontFamily="Lexend Deca">
                    Download Report
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {openDialog && (
            <SocketReportPopup
              setOpenDialog={setOpenDialog}
              requestId={generatedId}
            />
          )}
        </Grid>

        <Grid container spacing={1} style={containerStyle}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : (
            <>
              {startDate === null ? (
                <NullScreen
                  containerheight="75vh"
                  containerWidth="100%"
                  screenType="Info"
                  message=" Please Select Date"
                />
              ) : salesReportData?.length === 0 ? (
                <NullScreen containerheight="78vh" containerWidth="100%" />
              ) : (
                <>
                  <div style={gridContainerStyle}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 200 }}
                        aria-label="customized table"
                      >
                        <TableHeadComponent />
                        <TableRowsComponent data1={salesReportData} />
                      </Table>
                    </TableContainer>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div style={{ flex: "1" }}></div>
                    <Pagination
                      count={totalPages}
                      page={offset}
                      onChange={handleChangePage}
                      style={{ color: "#5e89c7" }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </Grid>
      </Box>

      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default PNLReport;
