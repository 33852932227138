import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MailIcon from "@mui/icons-material/Mail";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import { FaWarehouse } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { MaterialReactTable } from "material-react-table";
import { createTheme } from "@mui/material/styles";
import { ErrorDialogBox } from "../../PopUpMsg/ErrorDialogPopUp";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useSelector, useDispatch } from "react-redux";
import AWS from "aws-sdk";
import {
  Button,
  Chip,
  OutlinedInput,
  TextField,
  Stack,
  Checkbox,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// import { useLocation } from '@reach/router';
import { HiCurrencyRupee } from "react-icons/hi2";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  MdModeEdit,
  MdPeopleAlt,
  MdLocationOn,
  MdDescription,
} from "react-icons/md";
import axios from "axios";

import { ClipLoader } from "react-spinners";
import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../WMStheme";
import AddIcon from "@mui/icons-material/Add";
const ITEM_HEIGHT = 48;
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "ap-south-1",
});
const s3 = new AWS.S3();
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  zIndex: 9999,

  // backgroundColor: "red",
};
// Separate components for each section
const PersonalDetails = ({
  productDataSet,
  setProductDataSet,
  uomTypeOptions,
  setUOMTypeOptions,
}) => {
  const handleChange = (field, value) => {
    // const updatedValue = field === "status" ? value === "true" : value;

    setProductDataSet((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  useEffect(() => {
    fetchUOMTypeOptions();
  }, []);
  const fetchUOMTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_MODULE_SKU_UOMTYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.Data?.map((row) => row?.dropdown_value);
      setUOMTypeOptions(options);
    } catch (error) {
      console.log("Error", error);
      // Handle error
    }
  };

  return (
    <>
      {/* <Typography variant="h6">Details</Typography> */}

      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Product Name"
            size="small"
            fullWidth
            required
            value={productDataSet?.name}
            onChange={(e) => handleChange("name", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 65, // Set maximum length to 60 characters
              },
            }}
            helperText={
              productDataSet?.name && productDataSet?.name.length > 60
                ? "Cannot enter more than 65 characters"
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Product Code"
            size="small"
            fullWidth
            required
            type="number"
            value={productDataSet?.code}
            onChange={(e) => handleChange("code", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>UOM</InputLabel>
            <Select
              label="UOM"
              fullWidth
              required
              size="small"
              value={productDataSet?.uom}
              onChange={(e) => handleChange("uom", e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "UOM" }}
              startAdornment={
                <InputAdornment position="start">
                  <SupervisedUserCircleIcon />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Select UOM
              </MenuItem>
              {uomTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="HSN"
            name="HSN"
            type="number"
            size="small"
            value={productDataSet?.hsn}
            onChange={(e) => handleChange("hsn", e.target.value)}
            // value={newUserRoleState?.new_email}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <MailIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Sales Price"
            name="Sales Price"
            type="number"
            size="small"
            value={productDataSet?.sale_price}
            onChange={(e) => handleChange("sale_price", e.target.value)}
            // value={newUserRoleState?.new_email}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <FaRupeeSign />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Average Sales Price"
            name=" Average Sales Price"
            type="number"
            size="small"
            value={productDataSet?.avg_sale_price}
            onChange={(e) => handleChange("avg_sale_price", e.target.value)}
            // value={newUserRoleState?.new_email}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <FaRupeeSign />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ marginTop: "1.7%" }}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              fullWidth
              required
              size="small"
              displayEmpty
              value={productDataSet?.status ? true : false}
              onChange={(e) => handleChange("status", e.target.value)}
              inputProps={{ "aria-label": "Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <ToggleOffIcon />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Select Status
              </MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>In-Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Tax(in %)"
            name="Tax(in %)"
            type="number"
            size="small"
            value={productDataSet?.tax}
            onChange={(e) => handleChange("tax", e.target.value)}
            // value={newUserRoleState?.new_email}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <FaRupeeSign />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Description"
            name="Description"
            type="Description"
            size="small"
            // value={newUserRoleState?.new_email}
            fullWidth
            required
            value={productDataSet?.description}
            onChange={(e) => handleChange("description", e.target.value)}
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <DescriptionIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}></Grid>

      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const handleDownloadClick = (fileName) => {
  const presignedUrl = s3.getSignedUrl("getObject", {
    Bucket: "fambo-assets",
    Key: `farmer/${fileName}`,
    Expires: 3600,
  });
  console.log("Presigned URL for viewing:", presignedUrl);
  window.open(presignedUrl, "_blank");
};

const Documents = ({
  images,
  handleCancelImage,
  productData,
  handleImageChange,
  setEditStatusActive,
  editStatusActive,
}) => (
  <>
    <Grid
      container
      spacing={3.5}
      style={{ marginTop: "0.6%", marginLeft: "0.5%" }}
    >
      {images?.map((imageType, index) => (
        <Grid key={index} item xs={12} sm={4.5} md={4.5}>
          <Typography
            variant="h2"
            color="textPrimary"
            fontWeight="400"
            gutterBottom
          >
            Upload&nbsp;
            {imageType.type.charAt(0).toUpperCase() + imageType.type.slice(1)}
            <>
              <Button
                variant="contained"
                startIcon={
                  <FileDownloadIcon
                    style={{ color: "#ffffff", fontSize: 26 }}
                  />
                }
                sx={{
                  backgroundColor: "#34519fe6",
                  marginLeft: "10%",
                }}
                size="small"
                disabled={
                  !editStatusActive || !productData[imageType.type + "_attach"]
                }
                onClick={() =>
                  handleDownloadClick(productData[imageType.type + "_attach"])
                }
              >
                <Typography variant="h7" fontFamily="Lexend Deca">
                  Download
                </Typography>
              </Button>
            </>
          </Typography>
          {imageType?.images.map((image, imgIndex) => (
            <Grid key={imgIndex} item xs={12} sm={5}>
              <Box
                border="1px dashed #000"
                borderRadius="10px"
                height="60px"
                width="60px"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                position="relative"
              >
                <IconButton
                  onClick={() => handleCancelImage(imageType.type, imgIndex)}
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    color: "red",
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <img
                  src={image.data}
                  alt={`${imageType.type} ${imgIndex + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ marginTop: "3%" }}>
            <Box
              border="1px dashed #000"
              borderRadius="10px"
              height="100px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <Typography
                variant="body1"
                color="textSecondary"
                marginBottom="8px"
              >
                Click or drag to add an image
              </Typography>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(imageType.type, event)}
                style={{ display: "none" }}
                id={`image-upload-${index}`}
              />
              <label htmlFor={`image-upload-${index}`}>
                <IconButton component="span">
                  <AddIcon style={{ color: "red" }} />
                  <Typography variant="button" color="red">
                    Select Image
                  </Typography>
                </IconButton>
              </label>
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
    <Grid
      container
      spacing={2.5}
      sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
    >
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
  </>
);

export const Mapping = ({
  warehouseData,
  productDataSet,
  setProductDataSet,
  setFilteredWarehouse,
  filteredWarehouse,
}) => {
  const theme = useTheme();
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Ensure warehouseData is an array
    const warehouses = Array.isArray(warehouseData)
      ? warehouseData
      : [warehouseData];
    console.log("warehouses", warehouses);
    // Update productDataSet with selected warehouses
    const selectedWarehousesWithFlag = warehouses
      .filter((warehouse) => value.includes(warehouse?.wh_name))
      .map((selectedWarehouse) => ({
        warehouse_id: selectedWarehouse?.id,
        wh_name: selectedWarehouse?.wh_name,
        flag: true,
      }));
      // Update the filteredWarehouses state (if needed)
      setFilteredWarehouse(selectedWarehousesWithFlag);
      const updatedWarehouses = warehouses?.map((warehouse) => {
        const selectedWarehouse = selectedWarehousesWithFlag?.find(
          (selectedWarehouse) => selectedWarehouse?.warehouse_id === warehouse.id
        );
        
        return selectedWarehouse
          ? {
              id: warehouse.id,
              warehouse_id: warehouse.id,
              wh_name: warehouse.wh_name,
              flag: selectedWarehouse.flag,
            }
          : { warehouse_id: warehouse.id, wh_name: warehouse.wh_name, flag: false };
      });
      console.log("updatedWarehouses", updatedWarehouses);
        setSelectedWarehouses(updatedWarehouses);
        // Combine selectedWarehouses and other warehouses with flag: false
  const combinedWarehouses = [
    ...updatedWarehouses,
    ...warehouses.filter((warehouse) => !value.includes(warehouse?.wh_name)).map((warehouse) => ({
      id: warehouse.id,
      warehouse_id: warehouse.id,
      wh_name: warehouse.wh_name,
      flag: false,
    })),
  ];
    setProductDataSet((prevData) => ({
      ...prevData,
      warehouse: combinedWarehouses || [],
    }));

    console.log("Selected Warehouses:", value);
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "0.6%", marginLeft: "2.1%", marginRight: "10%" }}
      >
        <Typography>Link to WareHouse</Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%", marginRight: "10%" }}
      >
        <FormControl sx={{ m: 1, width: "95%" }}>
          <InputLabel id="product-chip-label">Link to Warehouses</InputLabel>
          <Select
            labelId="product-chip-label"
            id="product-chip"
            multiple
            value={(filteredWarehouse || [])?.map((wh) => wh?.wh_name)}
            onChange={handleChange}
            input={
              <OutlinedInput
                id="select-product-chip"
                label="Select Warehouses"
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {Array.isArray(warehouseData) &&
              warehouseData?.map((warehouse) => (
                <MenuItem key={warehouse?.id} value={warehouse?.wh_name}>
                  {/* <Checkbox
                    checked={(productDataSet?.warehouse || [])
                      .map((wh) => wh.wh_name)
                      .includes(warehouse.wh_name)}
                  /> */}
                  {warehouse?.wh_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const ProductMaking = ({
  productDataToSet,
  productId,
  setSelectedProduct,
  selectedProduct,
  skuMasterData,
  setSKUMasterData,
  rowSelection,
  setRowSelection,
  selectedRows,
  totalPages,
  updatedRowsArray,
  setUpdatedRowsArray,
  setTotalPages,
  setOffset,
  offset,
  setSelectedRows,
  setProductToSet,
  onsubmit,
  setOnSubmit,
  setProductDataToSet,
  fetchListOfAllSKU,
  action,
  setAddedSKUMasterData,
  addedSKUMasterData,

  handleChangePage,
  setSelectedProductsWithFlag,
  selectedProductsWithFlag,
  onUpdateProductData, // Callback function from the parent
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#fff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const [inputValues, setInputValues] = useState({});

  const handleQuantityChange = (e, original) => {
    const { value } = e.target;

    if (value.length <= 10) {
      const skuId = original.sku_id;

      // Update the row's quantity in the SKU master data
      const updatedSkuMasterData = skuMasterData?.map((item) =>
        item.sku_id === skuId ? { ...item, qty: value } : item
      );
      setAddedSKUMasterData(updatedSkuMasterData);

      // Update the input values state
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [skuId]: value,
      }));

      // Find the corresponding row index in updatedRowsArray
      const index = updatedRowsArray?.findIndex(
        (row) => row?.sku_id === original?.sku_id
      );

      // Update the selected rows with the new flag
      setSelectedRows((prevSelectedRows) => {
        const updatedRows = {
          ...prevSelectedRows,
          [skuId]: { flag: true },
        };

        // Add or update the row in the array
        const updatedRow = {
          ...original,
          qty: value,
          flag: true,
          product_id_fk: productId,
        };

        // Add or update the row in the array
        // if (index !== -1) {
        //   // If row exists in the array, update it
        //   const newArray = [...updatedRowsArray];
        //   newArray[index] = updatedRow;
        //   setUpdatedRowsArray(newArray);
        // } else {
        //   // If row doesn't exist in the array, add it
        //   setUpdatedRowsArray((prevArray) => [...prevArray, updatedRow]);
        // }
        setUpdatedRowsArray((prevArray) => {
          const rowExists = index !== -1;

          // If row exists in the array, update it
          if (rowExists) {
            const newArray = prevArray.map((item, i) =>
              i === index ? updatedRow : item
            );
            return newArray;
          } else {
            // If row doesn't exist in the array, add it
            // Check if the row already exists before adding
            const rowAlreadyExists = prevArray.some(
              (row) => row.sku_id === skuId
            );
            if (!rowAlreadyExists) {
              return [...prevArray, updatedRow];
            } else {
              return prevArray;
            }
          }
        });
        // onUpdateQuantity(updatedRowsArray, isQuantityFilled());
        // Console the updated array here
        console.log("Updated Rows Array:", updatedRowsArray);

        return updatedRows;
      });
    } else {
      console.log("Invalid quantity value");
    }
  };

  const filteredSkuMasterData =
    action === "edit"
      ? skuMasterData?.map((sku) => ({
          ...sku,
          flag: selectedRows[sku?.sku_id]?.flag ?? sku.flag, // Use selectedRows flag if it exists, otherwise use sku.flag
        }))
      : skuMasterData;
  const columns = useMemo(
    () => [
      {
        header: "Select",
        accessorKey: "select",
        size: 10,
        Cell: ({ row }) => (
          <Checkbox
            checked={
              Boolean(selectedRows[row.original.sku_id]?.flag) ||
              Boolean(row.original.flag)
            }
            //checked={Boolean(row.original.flag)}
            color="primary"
            onChange={() => handleRowClick(row)}
            sx={{ padding: "2px", "& .MuiSvgIcon-root": { fontSize: "16px" } }} // Adjust the padding and fontSize as needed
          />
        ),
      },
      {
        header: "Name",
        accessorKey: "sku_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "UOM",
        accessorKey: "uom",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Code",
        accessorKey: "sku_code",
        size: 10,
        enableEditing: false,
      },

      {
        header: "Quantity",
        accessorKey: "qty",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const inputValue = inputValues[original?.sku_id] || "";
          return (
            <TextField
              type="number"
              value={inputValue}
              size="small"
              onChange={(e) => handleQuantityChange(e, original)}
              inputProps={{
                step: "any",
              }}
            />
          );
        },
      },
    ],
    [
      inputValues,
      selectedRows,
      skuMasterData,
      setAddedSKUMasterData,
      updatedRowsArray,
    ]
  );
  const handleRowClick = (row) => {
    console.log("row click function");
    if (row.original) {
      const skuId = row.original.sku_id;

      setSelectedRows((prevSelectedRows) => {
        const updatedRows = {
          ...prevSelectedRows,
          [skuId]: {
            ...prevSelectedRows[skuId],
            flag: !prevSelectedRows[skuId]?.flag,
          },
        };

        // Check if the flag changed from true to false
        if (prevSelectedRows[skuId]?.flag && !updatedRows[skuId]?.flag) {
          // Find the corresponding row in skuMasterData and get its entire data
          const correspondingRow = skuMasterData.find(
            (item) => item.sku_id === skuId
          );

          // Update the flag in the corresponding row and add it to updatedRowsArray
          const updatedRowArray = [
            ...updatedRowsArray.filter((item) => item.sku_id !== skuId),
            { ...correspondingRow, flag: false },
          ];

          setUpdatedRowsArray(updatedRowArray);
          console.log("updated rows array ", updatedRowArray);
        }

        console.log("selected rows", selectedRows);
        console.log("updated rows data", updatedRows);

        return updatedRows;
      });
    } else {
      console.error("Row data is undefined or missing 'original' property");
    }
  };

  // const handleRowClick = (row) => {
  //   console.log("row click function");
  //   if (row.original) {
  //     const skuId = row.original.sku_id;

  //     setSelectedRows((prevSelectedRows) => {
  //       const updatedRows = {
  //         ...prevSelectedRows,
  //         [skuId]: {
  //           ...prevSelectedRows[skuId],
  //           flag: !prevSelectedRows[skuId]?.flag,
  //         },
  //       };

  //       // Check if the flag changed from true to false
  //       if (prevSelectedRows[skuId]?.flag && !updatedRows[skuId]?.flag) {
  //         // Find the corresponding row in updatedRowsArray and update it
  //         const updatedRowArray = updatedRowsArray.map((item) =>
  //           item.sku_id === skuId ? { ...item, flag: false } : item
  //         );
  //         setUpdatedRowsArray(updatedRowArray);
  //         console.log("updated rows array ", updatedRowArray);
  //       }

  //       console.log("selected rows", selectedRows);
  //       console.log("updated rows data", updatedRows);

  //       // If you want to update the entire row data in updatedRowsArray, do it here
  //       const updatedRowArrayWithWholeData = updatedRowsArray.map((item) =>
  //         item.sku_id === skuId
  //           ? { ...item, flag: updatedRows[skuId]?.flag }
  //           : item
  //       );
  //       setUpdatedRowsArray(updatedRowArrayWithWholeData);
  //       console.log(
  //         "updated rows array with whole data",
  //         updatedRowArrayWithWholeData
  //       );

  //       return updatedRows;
  //     });
  //   } else {
  //     console.error("Row data is undefined or missing 'original' property");
  //   }
  // };

  //     const selectedIdsArray = Array.isArray(selectedRowIds)
  //       ? selectedRowIds
  //       : Object.keys(selectedRowIds);

  //     setSelectedRows((prevSelectedRows) => {
  //       const updatedRows = {};
  //       skuMasterData?.forEach((row) => {
  //         const skuId = row.sku_id;
  //         updatedRows[skuId] = {
  //           ...row,
  //           flag: selectedIdsArray.includes(skuId) ? !row.flag : false, // Toggle the flag if SKU is selected, otherwise set to false
  //           qty: inputValues[skuId],
  //         };
  //       });
  //       // Include previously selected rows with flag true
  //     Object.keys(prevSelectedRows).forEach((skuId) => {
  //       if (!updatedRows[skuId]) {
  //         updatedRows[skuId] = prevSelectedRows[skuId];
  //       }
  //     });
  // console.log("updated rows 000000000000",updatedRows);
  //       return updatedRows;
  //     });

  //     console.log("selected rows ", selectedRows);
  //   };

  useEffect(() => {
    if (action === "edit") {
      const initialInputValues = {};
      productDataToSet?.forEach((product) => {
        initialInputValues[product?.sku_id] = product?.qty;
      });
      setInputValues(initialInputValues);
    } else {
      // For "add" action, you may want to clear the input values or set default values
      setInputValues({});
    }
  }, [action, productDataToSet]);
  return (
    <>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        style={{ overflow: "auto", maxHeight: "460px" }}
      >
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={filteredSkuMasterData}
            getRowId={(row) => row?.sku_id}
            state={{ rowSelection }}
            onRowSelectionChange={handleRowClick}
            enableEditing={false}
            enableGrouping
            enableStickyHeader
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 10 },
            }}
            muiTableHeadCellProps={{
              sx: {
                fontWeight: "650",
                color: "#fff",
                backgroundColor: "#34519fe6",
                fontSize: "15px",
              },
            }}
            muiTableBodyProps={{
              sx: {
                "& tr": {
                  backgroundColor: "#fff",
                },
              },
            }}
            muiTableContainerProps={{ sx: { marginBottom: "0%" } }}
            muiToolbarAlertBannerChipProps={{ color: "primary" }}
          />
        </ThemeProvider>
        {/* <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginTop: "10px",
    }}
  >
    <Pagination
      count={totalPages}
      page={offset}
      onChange={handleChangePage}
      size="small"
    />
  </div> */}
      </Grid>

      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.9%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};
const ProductConfig = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { action, productData } = useSelector((state) => state.product);
  console.log("Dataaaaaaaaaaaaaaaaaaaa", productData);
  console.log("action", action);
  const [editStatusActive, setEditStatusActive] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [selectedProductsWithFlag, setSelectedProductsWithFlag] = useState([]);
  const [productToSet, setProductToSet] = useState([]);
  const [addedSKUMasterData, setAddedSKUMasterData] = useState([]);
  // const [selectedProductsOnSubmit, setSelectedProductsOnSubmit] = useState([]);
  // const [customerData23, setCustomerData23] = useState(rowData);
  const [productId, setProductId] = useState(null);
  const [uomTypeOptions, setUOMTypeOptions] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [offset, setOffset] = useState(1);
  const [productActiveValidationError , setProductActiveValidationError] =useState([]);
  const [filteredWarehouse,setFilteredWarehouse] =useState([]);
  const [inverrorDialogOpen , setInvErrorDialogOpen] =useState(false);
  const [inverrorDialogOpenMsg , setInvErrorDialogOpenMsg] =useState(false);
  const [soerrorDialogOpen , setSoErrorDialogOpen] =useState(false);
  const [soerrorDialogOpenMsg , setSoErrorDialogOpenMsg] =useState("");
  const [warehouseerrorDialogOpenMsg , setWarehouseErrorDialogOpenMsg] =useState("");
  const [warehouseInverrorDialogOpen , setWarehouseInvErrorDialogOpen] =useState(false);
  
  const [totalPages, setTotalPages] = useState(null);
  const [skuMasterData, setSKUMasterData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
  const [productLoading, setProductLoading] = useState(false);
  const colors = tokens(theme.palette.mode);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productDataToSet, setProductDataToSet] = useState([]);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [onsubmit, setOnSubmit] = useState(false);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const [warehouseLinkingCompleted, setWarehouseLinkingCompleted] =
    useState(false);
  const [currentSection, setCurrentSection] = useState("Details");
  const sections = [
    { name: "Details", icon: <MdModeEdit /> },
    { name: "Product Image", icon: <MdDescription /> },
    { name: "Connect Warehouse", icon: <FaWarehouse /> },
    { name: "SKU Linking", icon: <MdOutlineProductionQuantityLimits /> },
  ];
  const InvColumn = [
    { id: "lot_no", label: "Lot Number" },
    { id: "avl_qty", label: "Available Qty" },
    
    { id: "created_at", label: "Created At" },
    
   
    
  ];
  
  const SoOrderColumn = [
    { id: "order_no", label: "Order Number" },
    { id: "name", label: "Item Name" },
    
    { id: "code", label: "Item Code" },
    
   
    
  ];
  
  const WarehouseColumn = [
    { id: "lot_no", label: "Lot Number" },
    { id: "avl_qty", label: "Available Qty" },
    
    { id: "created_at", label: "Created At" },
    
   
    
  ];
  useEffect(() => {
    switch (action) {
      case "edit":
        if (productData) {
          // If editing, prefill the form with the warehouseData
          console.log("customer data in edit form ", productData);
          console.log("edit action performed");
          setEditStatusActive(true);
          // Fetch states and cities based on the country and state in productData
          
          
          // Initialize WarehouseData with all fields from JSON structure
          setProductId(productData?.id);
          console.log("customer id", productData?.id);
          const filteredWarehouses = productData?.warehouse
          ? productData?.warehouse?.filter((wh) => wh.flag === true)
          : [];
          setFilteredWarehouse(filteredWarehouses);
          setProductDataSet((prevData) => ({
            ...prevData,
            ...productData,
            warehouse:filteredWarehouses,
            uom: productData?.uom,
          }));
          // Update only the fields present in warehouseData
        }
        break;

      case "add":
        // If adding, clear the form data
        console.log("add action performed");
        setProductDataSet({
          name: "",
          code: "",
          hsn: 0,
          tax: 0,
          sale_price: 0,
          avg_sale_price: 0,
          status: true,
          description: "",
          product_attach: "",
          uom: "",
        });
        break;

      // Add more cases if needed

      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, productData]);

  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the product data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []);
  useEffect(() => {
    fetchListOfAllSKU(productId, offset);
  }, [offset, productId]);

  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
    fetchListOfAllSKU(productId, newPage);
  };
  const [productDataSet, setProductDataSet] = useState({
    name: "",
    code: "",
    hsn: 0,
    tax: 0,
    uom: "",
    sale_price: 0,
    avg_sale_price: 0,
    status: true,
    description: "",
    product_attach: "",
  });
  const updateQuantityCallback = (rowsArray, isDisabled) => {
    setUpdatedRowsArray(rowsArray);

    // Check if the current section is "ProductMaking" before enabling/disabling the Save button
    if (currentSection === "SKU Linking") {
      setSaveButtonDisabled(isDisabled);
    } else {
      // Ensure isSaveButtonDisabled is false when not in the "ProductMaking" section
      setSaveButtonDisabled(false);
    }
  };

  const [images, setImages] = useState([
    { type: "product", images: [], file: null },
  ]);

  const uploadDocuments = async () => {
    const uploadPromises = images?.map((file) => {
      if (file.file) {
        const key = `${file.file.name}`;

        const params = {
          Bucket: "fambo-assets",
          Key: `farmer/${key}`,
          Body: file.file,
        };

        return new Promise((resolve, reject) => {
          s3.upload(params, (err, data) => {
            if (err) {
              console.log("Error uploading file:", err);
              reject(err);
            } else {
              console.log("File uploaded successfully:", data.Location);
              resolve(key);
            }
          });
        });
      }
      return Promise.resolve(null);
    });
    try {
      const uploadedKeys = await Promise.all(uploadPromises);
      console.log("All files uploaded:", uploadedKeys);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleImageChange = (imageType, event) => {
    const file = event.target.files[0];
    console.log("images------------", images);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const updatedImages = [...images];
        const imageTypeIndex = updatedImages.findIndex(
          (type) => type.type === imageType
        );

        if (imageTypeIndex !== -1) {
          updatedImages[imageTypeIndex].file = file;
          const fileName = `${file.name}`;
          updatedImages[imageTypeIndex].images.push({
            name: fileName,
            data: reader.result,
          });

          setImages(updatedImages);

          // Update corresponding state property
          setProductDataSet((prevData) => ({
            ...prevData,
            [`${imageType.toLowerCase()}_attach`]: fileName,
          }));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = (imageType, index) => {
    const updatedImages = [...images];
    const imageTypeIndex = updatedImages.findIndex(
      (type) => type.type === imageType
    );
    if (imageTypeIndex !== -1) {
      updatedImages[imageTypeIndex].images.splice(index, 1);
      setImages(updatedImages);
    }
  };

  const handleNext = async () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex < sections.length - 1) {
      const nextSection = sections[currentIndex + 1].name;
      if (action === "edit" && nextSection === "SKU Linking") {
        await updateProductMasterData();
      } else if (action === "add" && nextSection === "SKU Linking") {
        await createProductMasterData();
      }
      setCurrentSection(nextSection);
    }
  };
  const updateProductMasterData = async () => {
    try {
      if (!productId) {
        console.error("product id is missing in productData.");
        setErrorMessage("Please wait");
        setErrorPopupOpen(true);
        return;
      }
      setProductLoading(true);
      setProductDataSet((prevData) => ({
        ...prevData,
      }));
      const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_PRODUCT_DATA(
        productData?.id
      )}`;
      console.log("API Endpoint:", endpoint);
      setProductId(productData?.id);

      // Log the productData before making the API call
      console.log("Updating custoemrData:", productDataSet);

      const response = await axiosInstance.put(endpoint, productDataSet, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data updated Successfully!");
        // updateProductData(productData?.id , selectedProductsWithFlag);
        setWarehouseLinkingCompleted(true);
        setProductLoading(false);
        // navigate('/viewWarehouse');
      } else {
        console.error("Failed t;o update the transport data");
        setErrorMessage(
          "Failed to update the data. Please try again."
        );
        setErrorPopupOpen(true);
        setProductLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("catch 400 if statemnet");
        console.log("response data",error.response?.data?.data?.error_data);
        switch (error.response?.data?.data?.type) {
          
          
          case "inv_present": {
            setProductActiveValidationError(error.response?.data?.data?.error_data);
            setInvErrorDialogOpenMsg(error.response?.data?.msg);
            setInvErrorDialogOpen(true);
           
            break;
          }
          
          case "so_order": {
            setProductActiveValidationError(error.response?.data?.data?.error_data);
            setSoErrorDialogOpenMsg(error.response?.data?.msg);
            setSoErrorDialogOpen(true);
            break;
          }
          case "warehouse_inv": {
            setProductActiveValidationError(error.response?.data?.data?.error_data);
            setWarehouseErrorDialogOpenMsg(error.response?.data?.msg);
            setWarehouseInvErrorDialogOpen(true);
            break;
          }
          // Add a default case to handle unexpected values
          default: {
            setErrorMessage(error.response?.data?.msg);
            setErrorPopupOpen(true);
            break;
          }
        }
        setProductLoading(false);
        
        
        
      }
      else if (error.response && error.response.status >= 500 && error.response.status < 600) {
        console.error("Server Error: ", error.response?.data?.msg);
        setErrorMessage("Server Error. Please try again later.");
        setErrorPopupOpen(true);
        setProductLoading(false);
      } else {
        setErrorMessage(error.response?.data?.msg);
        setErrorPopupOpen(true);
        setProductLoading(false);
      console.error("Error occurred while updating the data:", error);
    } }
  };
  const handleSubmit = () => {
    uploadDocuments();
    try {
      if (action === "edit") {
        // If editing, send a PUT request
        console.log("updatedRowsArrray in edit function : ", updatedRowsArray);
        updateProductData(productId, updatedRowsArray);
      } else {
        // If adding, send a POST request

        console.log("updatedRowsArrray in add  : ", updatedRowsArray);
        updateProductData(productId, updatedRowsArray);
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error("Error submitting Transport Details:", error);
      setMessage("Error submitting Transport Details");
    }
  };
  const handleSectionChange = async (sectionName) => {
    try {
      // Start loader when changing section
      setProductLoading(true);

       

      // Simulating an async operation
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Stop loader after fetching data or when changing to a non-SKU section
      setProductLoading(false);

      // Set the current section
      setCurrentSection(sectionName);
    } catch (error) {
      console.error("Error handling section change:", error);
      // Handle errors if needed
    }
  };
  useEffect(() => {
    // Effect to navigate after success message is set
    if (successPopupOpen) {
      const timer = setTimeout(() => {
        setSuccessPopupOpen(false);
        navigate("/viewProduct");
        setProductLoading(false);
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [successPopupOpen, navigate]);
  const createProductMasterData = async () => {
    uploadDocuments();
    try {
      // Determine the endpoint for creating a new transport
      const endpoint = `${API_ENDPOINTS.POST_WMS_PRODUCT_ADD_DATA}`;
      console.log("API Endpoint:", endpoint);

      // Log the productData before making the API call
      console.log("Creating cUSTOMERData:", productDataSet);
      setProductLoading(true);
      // Send the POST request to create a new transport
      const response = await axiosInstance.post(endpoint, productDataSet, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        console.log("response--------------", response);
        // Extract productId from the response
        const productId = response.data?.Data?.product_id;
        setProductId(response.data?.Data?.product_id);
        // Call updateProductData with productId and selectedProductsWithFlag
        // updateProductData(productId, selectedProductsWithFlag);
        setWarehouseLinkingCompleted(true);
        setProductLoading(false);
        // setSuccessPopupOpen(true);
        // setProductId(Data?.customer_id);
        // console.log("customer id coming here ? ",Data?.customer_id);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the transport data");
        setErrorMessage(
          "Failed to create the transport data. Please try again."
        );
        setErrorPopupOpen(true);
        setProductLoading(false);
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setProductLoading(false);
      console.error("Error occurred while creating the transport data:", error);
    }
  };
  const updateProductData = async (productId, updatedRowsArray) => {
    console.log("updateProductData function called");
    console.log("productId:", productId);
    console.log("addedSKUMasterData:", updatedRowsArray);
    console.log(
      "selectedproduct in updateproductdata function ",
      updatedRowsArray
    );

    try {
      if (!productId) {
        console.error("product  id is missing in Data.");
        setErrorMessage("Please try Again");
        return;
      }
      setProductLoading(true);
      const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_PRODUCT_ITEMS(productId)}`;
      console.log("API Endpoint:", endpoint);

      // Log the productData before making the API call
      console.log("Updating product data :", updatedRowsArray);
      console.log("Request Body:", JSON.stringify(updatedRowsArray));
      const response = await axiosInstance.put(endpoint, updatedRowsArray, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        console.log("API Response:", response);

        setSuccessMessage("Data updated Successfully!");
        setSuccessPopupOpen(true);

        // navigate('/viewWarehouse');
      } else {
        console.error("Failed to update the product data");
        setErrorMessage("Failed to update the product data. Please try again.");
      }
    } catch (error) {
      setProductLoading(false);
      setErrorPopupOpen(true);
      console.error("Error occurred while updating the product data:", error);
    }
  };

  const handlePrevious = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex > 0) {
      const previousSection = sections[currentIndex - 1].name;
      setCurrentSection(previousSection);
    }
  };
  function handleBack() {
    if (action === "add") {
      navigate("/configuration");
    } else {
      // Navigate to a different location based on your condition

      navigate("/viewProduct");
    }
  }
  const fetchListOfAllSKU = async (productId, offset) => {
    console.log("offset---->", offset);
    const actualOffset = offset === undefined ? 1 : offset;

    setIsLoadingList(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_PRODUCT_SKU_ALL}`,
        {
          params: {
            limit: 1000,
            offset: 1,
            product_id: productId,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data?.items;
        console.log("product data", data);
        setProductDataToSet(data);
        setSKUMasterData(data);
        setTotalPages(data?.totalPages);
        console.log("product  Data", data);
      } else {
        console.log("Error fetching details");
        setErrorMessage("OOPS, Something went Wrong !!!");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoadingList(false);
      // Set loading to false after the request is completed
    }
  };

  useEffect(() => {
    if (productId && selectedProductsWithFlag?.length > 0) {
      updateProductData(productId, selectedProductsWithFlag);
      // Reset the flag after calling updateProductData
      setIsDataSubmitted(false);
    }
  }, [productId, selectedProductsWithFlag, updateProductData]);
  const isLastSection = currentSection === sections[sections.length - 1].name;
  //Details States

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
          // backgroundColor:'pink'
          // backgroundColor: "rgb(44 123 85)",
        }}
      >
        <Typography
          variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%" }}
        >
          <MdPeopleAlt
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp; Product
        </Typography>
        {/* <InventoryRoundedIcon sx={{ fontSize: 32, marginLeft: "1px" }} />
          </Typography> */}

        <Stack spacing={2} direction="row">
          {/* <Button
            variant="contained"
            startIcon={
              <MdModeEdit
                style={{ color: colors.white.DEFAULT, fontSize: 15 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              backgroundColor: colors.green.DEFAULT,
            }}
            title="Edit"
          >
            <Typography variant="h7" color={colors.white.DEFAULT}>
              Edit
            </Typography>
          </Button> */}

          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
{productLoading ? 
(
<>
  <div style={loaderContainerStyle}>
          <ClipLoader color="#495057" loading={productLoading} />
        </div>
 </>
 )  : 
     (
      <Grid container spacing={3} sx={{ marginTop: "1.7%" }}>
        {/* Left Section (30%) */}
        <Grid item xs={12} sm={3}>
          <Box
            p={2}
            bgcolor="#ffff"
            borderRadius="10px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
          >
            {sections?.map((section) => (
              <Typography
                key={section.name}
                onClick={() => handleSectionChange(section.name)}
                // onClick={() => setCurrentSection(section.name)}
                style={{
                  cursor: "pointer",
                  padding: "8px",

                  background:
                    currentSection === section.name
                      ? "#34519F5D"
                      : "transparent",
                  color: currentSection === section.name ? "#000" : "inherit",
                  borderRadius:
                    currentSection === section.name ? "7px" : "inherit",
                  // padding:currentSection === section.name ? '5px' : 'inherit',
                }}
              >
                {section?.icon}
                &nbsp;&nbsp;
                {section?.name}
              </Typography>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Box
            p={2}
            bgcolor="#ffff"
            borderRadius="10px"
            maxHeight="100%"
            height="100%"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            position="relative"
          >
            {/* Dynamically render the current section */}
            {currentSection === "Details" && (
              <PersonalDetails
                productDataSet={productDataSet}
                setProductDataSet={setProductDataSet}
                uomTypeOptions={uomTypeOptions}
                  setUOMTypeOptions={setUOMTypeOptions}
              />
            )}

            {currentSection === "Product Image" && (
              <Documents
                images={images}
                handleCancelImage={handleCancelImage}
                handleImageChange={handleImageChange}
                productData={productData}
                setEditStatusActive={setEditStatusActive}
                editStatusActive={editStatusActive}
              />
            )}
            {currentSection === "Connect Warehouse" && (
              <Mapping
                warehouseData={warehouseData}
                productDataSet={productDataSet}
                setProductDataSet={setProductDataSet}
                setFilteredWarehouse={setFilteredWarehouse}
                filteredWarehouse={filteredWarehouse}
              />
            )}
            {currentSection === "SKU Linking" && (
              <ProductMaking
                productDataToSet={productDataToSet}
                setProductDataToSet={setProductDataToSet}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                productDataSet={productDataSet}
                setProductToSet={setProductToSet}
                productToSet={productToSet}
                productId={productId}
                addedSKUMasterData={addedSKUMasterData}
                setAddedSKUMasterData={setAddedSKUMasterData}
                action={action}
                offset={offset}
                handleChangePage={handleChangePage}
                setOffset={setOffset}
                // onUpdateQuantity={updateQuantityCallback}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                skuMasterData={skuMasterData}
                setSKUMasterData={setSKUMasterData}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setOnSubmit={setOnSubmit}
                onsubmit={onsubmit}
                updatedRowsArray={updatedRowsArray}
                setUpdatedRowsArray={setUpdatedRowsArray}
                fetchListOfAllSKU={fetchListOfAllSKU}
                setProductDataSet={setProductDataSet}
                updateProductData={updateProductData}
                onUpdateProductData={updateProductData} // Pass the callback function
                selectedProductsWithFlag={selectedProductsWithFlag} // Pass the state as a prop
                setSelectedProductsWithFlag={setSelectedProductsWithFlag}
              />
            )}
            <Box
            position="absolute"
            bottom="16px"
            right="20px"
            display="flex"
            gap={2}
          >
            {currentSection !== sections[0].name && (
              <Button
                variant="outlined"
                sx={{
                  borderColor: colors.green.DEFAULT,
                }}
                onClick={handlePrevious}
              >
                <Typography variant="h7" color={colors.green.DEFAULT}>
                  Previous
                </Typography>
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                borderColor: colors.green.DEFAULT,
                backgroundColor: colors.green.DEFAULT,
              }}
              onClick={isLastSection ? handleSubmit : handleNext}
      // disabled={isSaveButtonDisabled}
              
            >
              <Typography variant="h7" color={colors.white.DEFAULT}>
                {isLastSection ? "Submit" : "Next"}
              </Typography>
            </Button>
          </Box>
        </Box>
           
           
          </Grid>

        
        </Grid>
      )}
      <ErrorDialogBox
      open = {inverrorDialogOpen}
      onClose ={() => setInvErrorDialogOpen(false)}
      title= "You are attempting to deactivate this SKU."
      msg= {inverrorDialogOpenMsg}
      data = {productActiveValidationError}
      okayText="Yes"
      columns={InvColumn}
     
       handleOkayClick={()=> setInvErrorDialogOpen(false)}
      />
      <ErrorDialogBox
      open = {soerrorDialogOpen}
      onClose ={() => setSoErrorDialogOpen(false)}
      title= "You are attempting to deactivate this SKU."
      msg= {soerrorDialogOpenMsg}
      data = {productActiveValidationError}
      okayText="Yes"
      columns={SoOrderColumn}
     
       handleOkayClick={()=> setSoErrorDialogOpen(false)}
      />
      <ErrorDialogBox
      open = {warehouseInverrorDialogOpen}
      onClose ={() => setWarehouseInvErrorDialogOpen(false)}
      title= "You are attempting to deactivate this SKU."
      msg= {warehouseerrorDialogOpenMsg}

      data = {productActiveValidationError}
      okayText="Yes"
      columns={WarehouseColumn}
     
       handleOkayClick={()=> setWarehouseInvErrorDialogOpen(false)}
      />

    </>
  );
};

export default ProductConfig;
