import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  useTheme,
  Stack,
  Divider,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import * as XLSX from "xlsx";
import GrassIcon from "@mui/icons-material/Grass";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { tokens } from "../../../theme";
import { ScaleLoader } from "react-spinners";
import Search from "../../../Assets/ConfigurationImg/search.png";

import DynamicDialog from "../../DialogBoxComponent/DynamicDialog";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import ApiErrorAlert from "../../../ApiErrorAlert";
import axios from "axios";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const ReportPage = ({ columns, selectedkey, title }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [farmId, setSelectedFarmId] = useState(null);
  const [farmOptions, setFarmOptions] = useState([]);
  const [isNoDataDialogOpen, setIsNoDataDialogOpen] = useState(false);
  const [dailogBoxOpen, setDialogBoxOpen] = useState(false);
  const [clickedColumns, setClickedColumns] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState(null);

  const columnsPart1 = columns?.slice(0, 10);
  const columnsPart2 = columns?.slice(10,20);

  useEffect(() => {
    fetchFarmData();
  }, []);

  useEffect(() => {
    handleSelectAllToggle();
  }, [columns]);

  const handleSelectAllToggle = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    if (!selectAll) {
      if (columns && Array.isArray(columns) && columns.length > 0) {
        setClickedColumns(columns);
        setSelectedColumns(
          Object?.fromEntries(columns?.map((column) => [column, true]))
        );
      }
    } else {
      setClickedColumns([]);
      if (columns && Array.isArray(columns) && columns.length > 0) {
        setSelectedColumns(
          Object?.fromEntries(columns?.map((column) => [column, false]))
        );
      }
    }
    setSelectAll(!selectAll);
  };

  const fetchFarmData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      const data = await response?.data;
      const farms = data?.farmdetail?.rows || [];
      setFarmOptions(farms);
    } catch (error) {
      setLoading(false);
      setApiErrorOpen(true);
    }
  };

  const getDefaultStartDate = () => {
    const lastMonth = new Date();
    lastMonth?.setMonth(lastMonth.getMonth() - 1);
    return lastMonth?.toISOString().split("T")[0];
  };

  const getDefaultEndDate = () => {
    return new Date().toISOString().split("T")[0];
  };

  const initialSelectedColumns = columns?.reduce((acc, column) => {
    acc[column] = false;
    return acc;
  }, {});

  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [selectedColumns, setSelectedColumns] = useState(
    initialSelectedColumns
  );

  const downloadCSV = (csvData, selectedColumns, name) => {
    const headers = Object.keys(csvData[0]);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      csvData.map((row) => Object?.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document?.createElement("a");
    link.setAttribute("href", encodedUri);
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const formattedTime = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const timeParts = formattedTime.split(":");
    const formattedTimeString = `${timeParts[0]}:${timeParts[1]}:${timeParts[2]}`;

    link.setAttribute("download", `${name}_${date}-${formattedTimeString}.csv`);
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  const downloadExcel = (data, selectedColumns, name) => {
    const selectedColumnNames = Object?.keys(selectedColumns)?.filter(
      (col) => selectedColumns[col]
    );

    const sheetData = data?.map((row) =>
      selectedColumnNames?.map((col) => row[col])
    );
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const formattedTime = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const timeParts = formattedTime.split(":");
    const formattedTimeString = `${timeParts[0]}:${timeParts[1]}:${timeParts[2]}`;

    const worksheetData = [selectedColumnNames, ...sheetData]; // Header row and data rows

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, `${name}_${date}-${formattedTimeString}.xlsx`);
  };

  const downloadPDF = (pdfData, columns, name) => {
    const rows = pdfData?.map((row) => columns?.map((col) => row[col]));

    const doc = new jsPDF();
    doc?.autoTable({
      head: [columns],
      body: rows,
    });
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const formattedTime = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const timeParts = formattedTime?.split(":");
    const formattedTimeString = `${timeParts[0]}:${timeParts[1]}:${timeParts[2]}`;

    doc.save(`${name}_${date}-${formattedTimeString}.pdf`);
  };

  const handleDownload = (format) => {
    setSelectedDownloadFormat(format);
  };
  const fetchDataFromApi = async () => {
    setLoading(true);
    const requestData = {
      name: selectedkey,
      columns: clickedColumns,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_REPORT_MODULE_SELECT_COLUMNDATA,
        requestData,
        {
          params: {
            farm_id: farmId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const responseData = response?.data;

        const newData = responseData?.rows;
        const count = responseData?.rowCount;

        if (count === 0) {
          setIsNoDataDialogOpen(!isNoDataDialogOpen);
        } else {
          setIsNoDataDialogOpen(false);
          setDialogBoxOpen(!dailogBoxOpen);
          switch (selectedDownloadFormat) {
            case "csv":
              downloadCSV(newData, selectedColumns, title);
              break;
            case "excel":
              downloadExcel(newData, selectedColumns, title);
              break;
            case "pdf":
              downloadPDF(
                newData,
                Object?.keys(selectedColumns)?.filter(
                  (col) => selectedColumns[col]
                ),
                title
              );
              break;
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setApiErrorOpen(true);
    }
  };

  const handleColumnToggle = (column) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [column]: !prevSelectedColumns[column],
    }));

    setClickedColumns((prevClickedColumns) => {
      if (prevClickedColumns?.includes(column)) {
        return prevClickedColumns?.filter(
          (clickedCol) => clickedCol !== column
        );
      } else {
        return [...prevClickedColumns, column];
      }
    });
  };

  const handleOkClose = () => {
    setDialogBoxOpen(false);
  };

  const handleDateChange = (dates, dateStrings) => {
    console.log("Formatted Dates:", dateStrings);
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            zIndex: "1",
          }}
        >
          <ScaleLoader color="#2C7B55" />
        </div>
      ) : (
        <>
          <Grid
            sx={{
              marginTop: "1.5%",
              height: "480px",
              marginLeft: "1%",
              backgroundColor: "#ecf4f0",
              boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: 6,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={2} sm={1} marginTop={"6px"}>
                <Checkbox
                  style={{ marginLeft: "43%" }}
                  checked={selectAll}
                  onChange={handleSelectAllToggle}
                />
              </Grid>
              <Grid item xs={12} sm={3} marginTop={"6px"}>
                <RangePicker
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  style={{
                    backgroundColor: "transparent",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2} marginTop={"6px"}>
                <FormControl fullWidth>
                  <InputLabel>Select Farm</InputLabel>
                  <Select
                    label="Select Farm"
                    value={farmId}
                    onChange={(e) => {
                      setSelectedFarmId(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Select Farm" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <GrassIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Farm
                    </MenuItem>
                    {farmOptions.map((farm) => (
                      <MenuItem key={farm.farm_id} value={farm.farm_id}>
                        {farm.farm_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} marginTop={"6px"}>
                <FormControl fullWidth>
                  <InputLabel>Select File Format</InputLabel>
                  <Select
                    label="Select File Format"
                    value={selectedDownloadFormat}
                    onChange={(e) => handleDownload(e.target.value)}
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Select File Format" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <InsertDriveFileIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select File Format
                    </MenuItem>
                    <MenuItem value="csv">CSV</MenuItem>
                    <MenuItem value="excel">Excel</MenuItem>
                    {/* <MenuItem value="pdf">PDF</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} marginTop={"6px"}>
                <Button
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  sx={{ backgroundColor: "#2c7B55", color: "#ffffff" }}
                  onClick={fetchDataFromApi}
                  disabled={
                    selectedDownloadFormat === null ||
                    farmId === null ||
                    clickedColumns?.length === 0
                  }
                >
                  Download
                </Button>
              </Grid>
            </Grid>

            <Stack direction="row" style={{ marginTop: "20px" }}>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "4.2%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "2%" }}
                >
                  Column
                </Typography>
                {columnsPart1?.map((column) => (
                  <div
                    key={column.value}
                    style={{
                      marginLeft: "-6%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={selectAll ? true : selectedColumns[column]}
                      onChange={() => handleColumnToggle(column)}
                      color="primary"
                    />
                    <Typography variant="h5">{column}</Typography>
                  </div>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "14.7%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "20%" }}
                >
                  Status
                </Typography>

                {columnsPart1?.map((column, index) => (
                  <Typography
                    key={column.value}
                    variant="h5"
                    color={colors.black[200]}
                    style={{
                      marginBottom: index !== columns.length - 1 ? "20px" : "0",
                    }}
                  >
                    Active
                  </Typography>
                ))}
              </Grid>
              <Divider
                variant="middle"
                orientation="vertical"
                flexItem
                sx={{ backgroundColor: "#000000", marginLeft: "12%" }}
              />
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "4.2%" }}
              >
                {columnsPart2?.length > 0 && (
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginBottom: "2%" }}
                  >
                    Column
                  </Typography>
                )}

                {columnsPart2?.map((column) => (
                  <div
                    key={column.value}
                    style={{
                      marginLeft: "-6%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={selectAll ? true : selectedColumns[column]}
                      onChange={() => handleColumnToggle(column)}
                      color="primary"
                    />
                    <Typography variant="h5">{column}</Typography>
                  </div>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "14.7%" }}
              >
                {columnsPart2?.length > 0 && (
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginBottom: "20%" }}
                  >
                    Status
                  </Typography>
                )}

                {columnsPart2?.map((column, index) => (
                  <Typography
                    key={column.value}
                    variant="h5"
                    color={colors.black[200]}
                    style={{
                      marginBottom: index !== columns.length - 1 ? "20px" : "0",
                    }}
                  >
                    Active
                  </Typography>
                ))}
              </Grid>
            </Stack>
          </Grid>

          <>
            {isNoDataDialogOpen && (
              <DynamicDialog
                open={isNoDataDialogOpen}
                onClose={() => setIsNoDataDialogOpen(false)}
                content="No Data Found for Report!!!"
                img={Search}
                actionText="OK"
                dialogStyle={{
                  width: "360px",
                  marginLeft: "37%",
                  marginRight: "20%",
                }}
                imgStyle={{ width: "20%", height: "20%" }}
              />
            )}
          </>
          <>
            {dailogBoxOpen && (
              <DynamicDialog
                open={dailogBoxOpen}
                onClose={handleOkClose}
                content="Great! Download Report!!!"
                img={Search}
                actionText="OK"
                dialogStyle={{
                  width: "360px",
                  marginLeft: "37%",
                  marginRight: "20%",
                }}
                imgStyle={{ width: "20%", height: "20%" }}
              />
            )}
          </>
        </>
      )}

      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)}
      />
    </>
  );
};
export default ReportPage;
