import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BillFile from "./BillFile";
import { useTheme } from "@mui/material";
import { tokens } from "../WMStheme";
import { Progress, Spin } from "antd";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BlobProvider } from "@react-pdf/renderer";

import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIAYGYIFM3LE7VZTYU2",
  secretAccessKey: "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",
  region: "ap-south-1",
});

const s3 = new AWS.S3();

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

const BillUploadProgressPopUp = ({ po_id, setUploadConfirm }) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState("In Progress");
  const [isLoading, setIsLoading] = useState(false);
  const [percent, setPercent] = useState(3);
  const [billData, setBillData] = useState({});

  useEffect(() => {
    fetchBillDetails();
  }, []);

  const fetchBillDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_BILL_DATA, {
        params: {
          po_id: po_id,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setBillData(response?.data?.data);
        let currentPercent = 1;
        const interval = setInterval(() => {
          currentPercent += 2;
          if (currentPercent >= 100) {
            setPercent(100);
            clearInterval(interval);
          } else {
            setPercent(currentPercent);
          }
        }, 50);
        setTimeout(() => {
          clearInterval(interval);
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      setResponseMessage("Some Error Occured, Try Again!");
    }
  };

  return (
    <Dialog
      maxWidth="md"
      open={true}
      PaperProps={{
        style: {
          width: "30%",
          height: "35%",
        },
      }}
    >
      <DialogTitle sx={{ position: "sticky", height: "3%" }}>
        <Typography
          // variant="h3"
          color={colors.green.DEFAULT}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
          }}
        >
          {percent === 100 ? "Sending mail to Vendor. Please wait!" : "Order in Progress"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setUploadConfirm(true)}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,
            color: "grey",
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              marginTop: "55px",
              //   height: "10vh",
              zIndex: "1",
            }}
          >
            <ScaleLoader color={colors.green.DEFAULT} loading={isLoading} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "55px",
            }}
          >
            <Progress
              strokeColor={colors.green.DEFAULT}
              type="circle"
              percent={percent}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BlobProvider
          document={<BillFile billData={billData} />}
          fileName={`${billData?.int_po_no}_po.pdf`}
        >
          {({ blob, url, loading, error }) => {
            useEffect(() => {
              const uploadToS3 = async () => {
                if (!loading && blob && percent === 100) {
                  console.log("blob size---", blob.size);
                  const params = {
                    Bucket: "fambo-assets",
                    Key: `wms/${billData?.int_po_no}_po.pdf`,
                    Body: blob,
                  };
                  s3.upload(params, async (err, data) => {
                    if (err) {
                      console.log("error in S3 upload", err);
                    } else {
                      const downloadLink = s3.getSignedUrl("getObject", {
                        Bucket: "fambo-assets",
                        Key: `wms/${billData?.int_po_no}_po.pdf`,
                        Expires: 3600,
                      });
                      console.log(
                        "File uploaded successfully with downloadLink",
                        downloadLink
                      );
                      if (downloadLink) {
                        try {
                          const response = await axiosInstance.post(
                            API_ENDPOINTS.POST_UPLOAD_PO_S3(
                              po_id,
                              "bill",
                              downloadLink
                            ),
                            {
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          );
                          if (response.status >= 200 && response.status < 305) {
                            setUploadConfirm(true);
                          }
                        } catch (error) {
                          setResponseMessage("Some Error Occurred, Try Again!");
                        }
                      }
                    }
                  });
                }
              };
              uploadToS3();
            }, [percent]);
          }}
        </BlobProvider>
      </DialogActions>
    </Dialog>
  );
};

export default BillUploadProgressPopUp;
