import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { BASE_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import { tokens } from "../AttTheme";
import { TiTickOutline } from "react-icons/ti";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../PopupComponent/SuccessPopup";
import ErrorPopup from "../PopupComponent/ErrorPopup";
import { TbMoodEmpty } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { IoIosPerson } from "react-icons/io";
import { MdCoPresent } from "react-icons/md";
import { HiOutlineIdentification } from "react-icons/hi2";
import { IoLocationOutline } from "react-icons/io5";
import { WiDayCloudy } from "react-icons/wi";
import { FaHourglassEnd } from "react-icons/fa6";
import RefreshIcon from "@mui/icons-material/Refresh";

import { ulid } from "ulid";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import SocketReportPopup from "./SocketReportPopup";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const AttHomePage = () => {
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  const [employeeData, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [generatedId, setGeneratedId] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  oneMonthAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);

  const [displayedStartDate, setDisplayedStartDate] = useState(
    oneMonthAgo.getTime()
  );
  const [displayedEndDate, setDisplayedEndDate] = useState(
    endDateTime.getTime()
  );

  const [filterValues, setFilterValues] = useState({
    limit: 50,
    offset: offset,
    start_date: oneMonthAgo.getTime(),
    end_date: endDateTime.getTime(),
  });

  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneMonthAgo.getTime(),
    end_date: endDateTime.getTime(),
    employee_id: null,
  });

  useEffect(() => {
    const updatedFilterValues = {
      start_date: displayedStartDate,
      end_date: displayedEndDate,
      employee_id: employeeId,
    };
    console.log("updatedFilterValues-------", updatedFilterValues);
    setTemporaryFilterValues(updatedFilterValues);
  }, [displayedStartDate, displayedEndDate, employeeId]);

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  useEffect(() => {
    fetchEmployeeAttendanceData(filterValues);
  }, []);

  const handleApplyClick = () => {
    console.log("temporaryFilterValues------------", temporaryFilterValues);
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      limit: 50,
      offset: 1,
      user_id: temporaryFilterValues.employee_id,
    };
    console.log("newFilterValues------------", newFilterValues);
    setDisplayedStartDate(temporaryFilterValues.start_date);
    setDisplayedEndDate(temporaryFilterValues.end_date);
    setEmployeeId(temporaryFilterValues.employee_id);
    setFilterValues(newFilterValues);
    fetchEmployeeAttendanceData(newFilterValues);
  };

  const fetchEmployeeData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_ATTENDANCE_USER_DATA,
        {
          params: {
            limit: 110,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      setEmployeeDetails(data?.data?.user_details);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setDisplayedStartDate(startDateTime);
    setDisplayedEndDate(endOfDay);
  };

  const fetchEmployeeAttendanceData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_ATTENDANCE_EMPLOYEE_DATA,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        const data = await response?.data?.data;
        setTotalPages(data?.total_pages);
        setEmployeeData(data?.attendance_details);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleGenerateReportClick = async () => {
    setIsLoading(true);
    const requestId = ulid();
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_ATTENDANCE_REPORT,
        {
          params: {
            ...filterValues,
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchEmployeeAttendanceData(updatedValues);
  };

  const handleRefreshClick = () => {
    const initialValues = {
      limit: 50,
      offset: 1,
      start_date: oneMonthAgo.getTime(),
      end_date: endDateTime.getTime(),
    };
    setOffSet(1);
    setEmployeeId(null);
    setDisplayedStartDate(oneMonthAgo.getTime());
    setDisplayedEndDate(endDateTime.getTime());
    setFilterValues(initialValues);
    fetchEmployeeAttendanceData(initialValues);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const containerStyle = {
    maxHeight: "71vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
  };

  const NullScreen = () => {
    const nullScreenContainerStyle = {
      top: 0,
      left: 0,
      display: "flex",
      overflowX: "hidden",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "71vh",
      width: "100%",
    };
    return (
      <Box style={nullScreenContainerStyle}>
        <TbMoodEmpty style={{ fontSize: "50px", color: "#5e89c7" }} />
        <Typography variant="h2" color="textPrimary" mt={2}>
          No Data Found
        </Typography>
        {/* <Typography variant="h4" color="textSecondary" mt={1}>
          Please select a farm to intiate fertigation.
        </Typography> */}
      </Box>
    );
  };

  return (
    <>
      {isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "85vh",
              zIndex: "1",
            }}
          >
            <ScaleLoader color="#2c677b" loading={isLoading} />
          </div>
        </>
      ) : (
        <>
          <div>
            <div
              // className="box-content"
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "45px",
                marginTop: "1%",
              }}
            >
              <Typography variant="h1" color={colors.green.DEFAULT}>
                Daily Attendance
              </Typography>
            </div>

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ borderRadius: "30px" }}
            >
              <Grid item xs={12} sm={9} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3} marginTop={"6px"}>
                    <RangePicker
                      onChange={handleDateChange}
                      format={dateFormat}
                      defaultValue={[
                        dayjs(formatDate(displayedStartDate), dateFormat),
                        dayjs(formatDate(displayedEndDate), dateFormat),
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} marginTop={"6px"}>
                    <FormControl fullWidth>
                      <InputLabel>Select Employee</InputLabel>
                      <Select
                        label="Select Employee"
                        value={employeeId}
                        onChange={(e) => {
                          setEmployeeId(e.target.value);
                        }}
                        size="small"
                        displayEmpty
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: "150px" }, // Set the max-height for the menu
                          },
                        }}
                        inputProps={{ "aria-label": "Select Employee" }}
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <IoIosPerson />
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        <MenuItem value="" disabled>
                          Select Employee
                        </MenuItem>
                        {employeeDetails.map((farm) => (
                          <MenuItem key={farm.user_id} value={farm.user_id}>
                            {farm.user_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={1.2} marginTop={"6px"}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<TiTickOutline style={{ fontSize: "26px" }}  />}
                      sx={{ backgroundColor: "#5e89c7", color: "#ffffff" }}
                      onClick={handleApplyClick}
                      disabled={!displayedStartDate || !displayedEndDate}
                    >
                      Apply
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1.2} marginTop={"6px"}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<RefreshIcon style={{ fontSize: "26px" }}  />}
                      sx={{ backgroundColor: "#5e89c7", color: "#ffffff" }}
                      onClick={handleRefreshClick}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} md={2} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#5e89c7",
                  }}
                  size="small"
                  onClick={handleGenerateReportClick}
                >
                  <Typography variant="h6" fontFamily="Lexend Deca">
                    Generate Report
                  </Typography>
                </Button>
              </Grid>
              {openDialog && (
                <SocketReportPopup
                  setOpenDialog={setOpenDialog}
                  requestId={generatedId}
                />
              )}
            </Grid>

            <Grid
              container
              spacing={1}
              sx={{ marginTop: "5px" }}
              style={containerStyle}
            >
              {employeeData?.length > 0 ? (
                <>
                  {employeeData?.map((employee, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                      <Paper
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: "8px",
                          height: "46px",
                          // backgroundColor: index % 2 === 1 ? "white" : "#ECF4FO", // Red for odd indices, white for even indices

                          transition:
                            "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
                          position: "relative", // Make sure the button is positioned relative to the Paper
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "scale(1.01)";
                          e.currentTarget.style.backgroundColor = "#eeeeef";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.backgroundColor = "white";
                        }}
                      >
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={1.3}
                            sm={1.3}
                            md={1.3}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <CiCalendarDate fontSize="small" /> Date
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {formatDate(employee?.date)}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1.3}
                            sm={1.3}
                            md={1.3}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <AccessTimeIcon fontSize="small" /> IN Time
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.inTime}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1.3}
                            sm={1.3}
                            md={1.3}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <AccessTimeIcon fontSize="small" /> OUT Time
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.outTime}
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xs={1.3}
                            sm={1.3}
                            md={1.3}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <IoIosPerson fontSize="small" /> Employee
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.name}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1.3}
                            sm={1.3}
                            md={1.3}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <MdCoPresent fontSize="small" /> Present
                            </Grid>
                            <Grid
                              item
                              style={{
                                color: employee?.is_present ? "green" : "red",
                                textAlign: "center",
                              }}
                            >
                              {employee?.is_present ? "Present" : "Absent"}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1.4}
                            sm={1.4}
                            md={1.4}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <HiOutlineIdentification fontSize="small" />{" "}
                              Employee Id
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.user_id}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1.4}
                            sm={1.4}
                            md={1.4}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <IoLocationOutline fontSize="small" /> Location
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.location}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1.4}
                            sm={1.4}
                            md={1.4}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <FaHourglassEnd fontSize="small" /> Working Hrs.
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.working_hr}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1.3}
                            sm={1.3}
                            md={1.3}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <WiDayCloudy fontSize="small" /> Shift
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.shift}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </>
              ) : (
                <NullScreen />
              )}
            </Grid>
            <Grid container justifyContent="flex-end" sx={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={8.5} md={8.5}></Grid>
              <Grid item xs={12} sm={3.5} md={3.5}>
                {employeeData?.length > 0 && (
                  <Pagination
                    count={totalPages}
                    page={offset}
                    onChange={handleChangePage}
                    style={{ color: "#5e89c7" }}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}

      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default AttHomePage;
