import React, { useState, useEffect } from "react";
import {
  Select,
  InputLabel,
  FormControl,
  TextField,
  MenuItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  InputAdornment,
  Button,
  Alert,
  AlertTitle,
  IconButton,
  Tooltip,
  List,
  ListItem,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
} from "@mui/material";
import "./CropConfig.css";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stepper, Step, StepLabel } from "@mui/material";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";

import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const CropMultiForm = () => {
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const steps = ["Crop Configuration", "Crop Variety", "Confirm Details"];
  const [errors, setErrors] = useState({});
  const [stageName, setStageName] = useState([]);
  const [gradesName, setGradesName] = useState([]);
  const [cropTypeOption, setCropTypeOption] = useState([]);
  const [VaritiesCropTypeOption, setVaritiesCropTypeOption] = useState([]);
  const navigate = useNavigate();
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  var [cropData, setCropData] = useState({
    CropName: null,
    CropType: "",
    CropCode: "",
    CropVarieties: [
      {
        name: "",
        code: "",
        type: "",
        // yield: null,
        exp_yield1: 0,
        exp_yield1_unit: "",
        exp_yield2: 0,
        exp_yield2_unit: "",
        brand: "",
        description: "",
        cycle: "",
        stages: [
          {
            stage_name: null,
            date_aftr_sowing: null,
            stage_desc: "",
          },
        ],
        grades: [{ grades_name: null, grades_desc: null }],
      },
    ],
  });

  const [farmData, setFarmData] = useState(null);
  const [selectedFarm, setSelectedFarm] = useState("");
  const [farmOptions, setFarmOptions] = useState([]);
  const [farm, setFarm] = useState();
  const [farmId, setFarmId] = useState();

  const handleMyFarmChange = async (event) => {
    const selectedFarm = event.target.value;
    setFarm(selectedFarm);
    const selectedFarmObject = farmData?.find(
      (farm) => farm.farm_name === selectedFarm
    );
    const selectedFarmId = selectedFarmObject.farm_id;
    setFarmId(selectedFarmId);
  };

  const Max_grades = 5;
  const Max_stage = 5;
  useEffect(() => {
    fetchFarmData();
    fetchCropTypeOptions();
    fetchVaritiesCropTypeOptions();
    fetchStagesOptions();
    fetchGradesOptions();
  }, []);
  const handleVarietyChange = (index, field, value) => {
    const newVarieties = [...cropData.CropVarieties];
    newVarieties[index][field] = value;
    setCropData((prevState) => ({
      ...prevState,
      CropVarieties: newVarieties,
    }));
  };
  const handleHelpIconClick = () => {
    setOpen(true); // open dialog box
  };

  const handleClose = () => {
    setOpen(false); // close dialog box
  };
  const validateStepOne = () => {
    const errors = {};
    if (!cropData?.CropName) {
      errors.CropName = "Required";
    }
    if (!cropData?.CropCode) {
      errors.CropCode = "Required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleRemoveVariety = (index) => {
    const newVarieties = [...cropData.CropVarieties];
    newVarieties?.splice(index, 1);
    setCropData((prevState) => ({
      ...prevState,
      CropVarieties: newVarieties,
    }));
  };

  const handleAddVariety = () => {
    setCropData((prevState) => ({
      ...prevState,
      CropVarieties: [
        ...prevState.CropVarieties,
        {
          name: "",
          code: "",
          type: "",
          // yield: null,
          exp_yield1: 0,
          exp_yield1_unit: "",
          exp_yield2: 0,
          exp_yield2_unit: "",
          stages: [
            {
              stage_name: null,
              date_aftr_sowing: null,
              stage_desc: "",
            },
          ],
          grades: [
            {
              grades_name: null,
              grades_desc: null,
            },
          ],
          seedBrand: "",
          description: "",
        },
      ],
    }));
  };
  const validateStepTwo = () => {
    const newErrors = {};
    cropData?.CropVarieties?.forEach((variety, index) => {
      if (!variety?.name) {
        newErrors[`name_${index}`] = "Required";
      }
      if (!variety?.code) {
        newErrors[`code_${index}`] = "Required";
      }
      if (!variety?.type) {
        newErrors[`type_${index}`] = "Required";
      }
      // if (!variety?.yield) {
      //   newErrors[`yield_${index}`] = "Required";
      // }
      // const stages = variety.stages;
      // for (let i = 1; i < stages.length; i++) {
      //   if (
      //     Number(stages[i].date_aftr_sowing) <=
      //     Number(stages[i - 1].date_aftr_sowing)
      //   ) {
      //     newErrors[`stage_${index}_${i}`] =
      //       "Please enter a value greater than the previous stage";
      //   } else {
      //     newErrors[`stage_${index}_${i}`] = undefined;
      //   }
      // }
      if (variety?.stages) {
        variety.stages.forEach((stage, stageIndex) => {
          if (!stage?.stage_name) {
            newErrors[`stage_name_${index}_${stageIndex}`] = "Required";
          }
        });
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };
  const addNewGrades = (index) => {
    const grades = { grades_name: null, grades_desc: null };
    const newVarieties = [...cropData.CropVarieties];
    newVarieties[index]?.grades?.push(grades);
    setCropData({ ...cropData, CropVarieties: newVarieties });
  };
  const addNewStage = (index) => {
    const stage = { stage_name: null, date_aftr_sowing: null, stage_desc: "" };
    const newVarieties = [...cropData.CropVarieties];
    newVarieties[index]?.stages?.push(stage);
    setCropData({ ...cropData, CropVarieties: newVarieties });
  };

  const next = () => {
    if (currentStep === 1 && !validateStepOne()) {
      setErrorOpen(true);
      return;
    }
    if (currentStep === 2 && !validateStepTwo()) {
      setErrorOpen(true);
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const previous = () => {
    setCurrentStep(currentStep - 1);
  };
  const CropCard = ({ cropData, index, isVarietyCard }) => {
    const cardTitle = isVarietyCard
      ? `Variety ${index}`
      : `${cropData?.CropName} `;
    const varietyRows = cropData?.CropVarieties[0]?.stages?.map((stage) => {
      return (
        <tr>
          <td>{stage?.stage_name}</td>
          <td>{stage?.stage_desc}</td>
          <td>{stage?.date_aftr_sowing}</td>
        </tr>
      );
    });
    const varietyRows1 = cropData?.CropVarieties[0]?.grades?.map((grade) => {
      return (
        <tr>
          <td>{grade?.grades_name}</td>
          <td
          //  style={{ width: "100px", height: "100px" }}
          >
            {grade?.grades_desc}
          </td>
        </tr>
      );
    });

    return (
      <div
        style={{
          border: "1px solid #ddd",
          boxShadow: "black ",
          padding: "10px",
        }}
      >
        <h6>{cardTitle}</h6>

        <Grid item xs={12} sm={12}>
          <Card
            style={{
              width: "100%",
              height: "auto",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CardHeader title="Confirm Details" />
            <CardContent style={{ marginTop: "-6%" }}>
              <List>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Crop
                    Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {cropData?.CropName}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Crop
                    Code&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {cropData?.CropCode}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Crop
                    Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {cropData?.CropType}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {farm}
                  </Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12}>
          {cropData?.CropVarieties?.map((variety, index) => (
            <Card
              style={{
                width: "100%",
                height: "auto",
              }}
            >
              <CardContent>
                <List>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 28%, #f5faf8 28%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Variety Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {variety?.name}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 28%, #f5faf8 28%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Variety Code&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {variety?.code}
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Yield&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {variety?.exp_yield1}
                      {variety?.exp_yield1_unit}&nbsp;&nbsp;of&nbsp;&nbsp;
                      {variety?.exp_yield2} {variety?.exp_yield2_unit}
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Brand&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {variety?.seedBrand}
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          ))}
        </Grid>

        {isVarietyCard && (
          <table className="varietyTable">
            <thead>
              <tr>
                <th>Stage Name</th>
                <th>Stage Description</th>
                <th>Date after Sowing</th>
              </tr>
            </thead>
            <tbody>{varietyRows}</tbody>
          </table>
        )}
        {isVarietyCard && (
          <table className="varietyTable">
            <thead>
              <tr>
                <th>Grades Name</th>
                <th> Description</th>
              </tr>
            </thead>
            <tbody>{varietyRows1}</tbody>
          </table>
        )}
      </div>
    );
  };
  const handleStageChange = (event, index, stageIndex, key) => {
    const newVarieties = [...cropData.CropVarieties];
    if (newVarieties[index]?.stages?.[stageIndex]?.[key] !== undefined) {
      newVarieties[index].stages[stageIndex][key] = event.target.value;
    }

    setCropData({ ...cropData, CropVarieties: newVarieties });
  };
  const handleDeleteStage = (index, stageIndex) => {
    const newVarieties = [...cropData.CropVarieties];
    newVarieties[index]?.stages?.splice(stageIndex, 1);
    setCropData({ ...cropData, CropVarieties: newVarieties });
  };
  const handlegradesChange = (event, index, gradesIndex, key) => {
    const newVarieties = [...cropData.CropVarieties];
    newVarieties[index].grades[gradesIndex][key] = event.target.value;
    setCropData({ ...cropData, CropVarieties: newVarieties });
  };
  const handleDeletegrades = (index, gradesIndex) => {
    const newVarieties = [...cropData.CropVarieties];
    newVarieties[index]?.grades?.splice(gradesIndex, 1);
    setCropData({ ...cropData, CropVarieties: newVarieties });
  };

  const fetchFarmData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      setFarmData(data?.farmdetail?.rows);
      setFarmOptions(farmData);
      setSelectedFarm(farmData[0]);
    } catch (error) {
      // setApiErrorOpen(true);
      console.log("Error fetching farm data:", error);
    }
    console.log("Names of Farm available", farmData);
  };

  const fetchCropTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_CROPTYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setCropTypeOption(options);
      } else {
        console.log("Error fetching details");
        setApiErrorOpen(true);
      }
    } catch (error) {
      console.log("Error", error);
      setApiErrorOpen(true);
    }
  };
  const fetchVaritiesCropTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_CROP_VARIETY_TYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setVaritiesCropTypeOption(options);
      } else {
        console.log("Error fetching details");
        setApiErrorOpen(true);
      }
    } catch (error) {
      console.log("Error", error);
      setApiErrorOpen(true);
    }
  };
  const fetchStagesOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_STAGENAME,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setStageName(options);
      } else {
        console.log("Error fetching details");
        setApiErrorOpen(true);
      }
    } catch (error) {
      console.log("Error", error);
      setApiErrorOpen(true);
    }
  };
  const fetchGradesOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_GRADESNAME,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setGradesName(options);
      } else {
        console.log("Error fetching details");
        setApiErrorOpen(true);
      }
    } catch (error) {
      console.log("Error", error);
      setApiErrorOpen(true);
    }
  };
  const handleSave = (event) => {
    event.preventDefault();
    cropData = {
      ...cropData,
      farm_id: farmId,
    };
    console.log("Its cropdata:1111111111111111", cropData);

    axiosInstance
      .post(API_ENDPOINTS.POST_CONFIGURATION_CROP, cropData)
      .then((response) => {
        if (response.status >= 200 && response.status < 305) {
          setSuccessOpen(true);
          setErrorOpen(false); // Display the success alert
        } else {
          console.log("Error fetching data:");
          setApiErrorOpen(true);
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
        setApiErrorOpen(true);
      });
    setCropData({}); // Reset the form data
    navigate("/cview");
    console.log("this is navigate page for cview");
  };
  const renderStepOne = () => {
    return (
      <div style={{ marginLeft: "5%", marginRight: "0.9%" }}>
        <Grid container spacing={1}>
          <Grid item xs={9} sm={11.2}>
            <TextField
              label="CropName"
              name="CropName"
              value={cropData?.CropName}
              readOnly
              required
              fullWidth
              size="small"
              margin="normal"
              inputProps={{
                style: { width: "100%" },

                maxLength: 30,
              }}
              onChange={(event) =>
                setCropData({ ...cropData, CropName: event.target.value })
              }
              error={formErrors.CropName !== undefined}
              helperText={formErrors.CropName}
            />
          </Grid>
          <Grid item xs={9} sm={11.2} style={{ marginTop: "-2%" }}>
            <TextField
              label="CropCode"
              name="CropCode"
              value={cropData?.CropCode}
              readOnly
              required
              fullWidth
              size="small"
              margin="normal"
              inputProps={{
                style: { width: "100%" },

                maxLength: 30,
              }}
              onChange={(event) =>
                setCropData({ ...cropData, CropCode: event.target.value })
              }
              error={formErrors.CropCode !== undefined}
              helperText={formErrors.CropCode}
            />
          </Grid>
          <Grid item xs={9} sm={11.2} style={{ marginTop: "1%" }}>
            <FormControl fullWidth>
              <InputLabel style={{ textAlign: "center" }}>Crop Type</InputLabel>
              <Select
                labelId="Crop Type"
                id="Crop Type"
                label="Crop Type"
                value={cropData?.CropType}
                required
                size="small"
                onChange={(event) =>
                  setCropData({ ...cropData, CropType: event.target.value })
                }
                fullWidth
                name="Crop Type"
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: "95px" }, // Set the max-height for the menu
                  },
                }}
                displayEmpty
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Crop Type",
                }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {cropTypeOption?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
                <MenuItem disabled value="">
                  Crop Type
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9} sm={11.2} style={{ marginTop: "3%" }}>
            <FormControl fullWidth>
              <InputLabel style={{ textAlign: "center" }}>
                Select Farm
              </InputLabel>
              <Select
                labelId="Select Farm"
                id="Select Farm"
                label="Select Farm"
                required
                size="small"
                value={farm}
                onChange={handleMyFarmChange}
                fullWidth
                name="Select Farm"
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: "95px" }, // Set the max-height for the menu
                  },
                }}
                displayEmpty
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Select Farm",
                }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {farmData &&
                  farmData?.map((farm) => (
                    <MenuItem key={farm.farm_name} value={farm.farm_name}>
                      {farm.farm_name}
                    </MenuItem>
                  ))}
                <MenuItem disabled value="">
                  Select Farm
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderStepTwo = () => {
    return (
      <div>
        {cropData?.CropVarieties?.map((variety, index) => (
          <div
            key={index}
            style={{
              marginLeft: "-4%",
              overflowX: "hidden",
            }}
          >
            <div
              style={{
                width: "9%",
                color: " #3da58a",
                fontSize: "18px",
                marginTop: "4%",
                alignItems: "center",
                marginLeft: "48%",
              }}
            >
              <Typography variant="h3" color={colors.green.DEFAULT}>
                Variety{index + 1}
              </Typography>
            </div>

            <div
              style={{
                width: "86%",
                height: "100%",
                marginLeft: "8%",
                justifyContent: "center",
                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
                marginTop: "1%",
                border: "1px solid #3da58a",
                padding: "10px",

                backgroundColor: "rgb(248 249 245)",
              }}
            >
              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                display="flex"
                sx={{ marginTop: "-3%" }}
              >
                <Grid item xs={9} sm={4} sx={{ marginTop: "6px" }}>
                  <TextField
                    label="Variety Name"
                    name="VarietyName"
                    value={variety?.name}
                    required
                    fullWidth
                    size="small"
                    margin="normal"
                    borderColor="#000000"
                    inputProps={{
                      style: { width: "100%" },

                      maxLength: 30,
                    }}
                    onChange={(event) =>
                      handleVarietyChange(index, "name", event.target.value)
                    }
                    error={errors[`name_${index}`] !== undefined}
                    helperText={errors[`name_${index}`]}
                  />
                </Grid>
                <Grid item xs={9} sm={4} sx={{ marginTop: "6px" }}>
                  <TextField
                    label="Variety Code"
                    name="VarietyCode"
                    value={variety?.code}
                    required
                    fullWidth
                    size="small"
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      maxLength: 30,
                    }}
                    error={errors[`code_${index}`] !== undefined}
                    helperText={errors[`code_${index}`]}
                    onChange={(event) =>
                      handleVarietyChange(index, "code", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={9} sm={4} sx={{ marginTop: "14px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="VarietyType" sx={{ marginTop: "-2%" }}>
                      VarietyType
                    </InputLabel>
                    <Select
                      labelId="Variety Type"
                      id=" VarietyType"
                      label=" VarietyType"
                      value={variety?.type}
                      size="small"
                      name=" VarietyType"
                      onChange={(event) =>
                        handleVarietyChange(index, "type", event.target.value)
                      }
                      error={errors[`type_${index}`] !== undefined}
                      helperText={errors[`type_${index}`]}
                      fullWidth
                      displayEmpty
                      inputProps={{
                        style: { width: "100%" },
                        "aria-label": "Variety Type",
                      }}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                    >
                      {VaritiesCropTypeOption?.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem disabled value="">
                        Select Crop Variety Type
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                display="flex"
                // sx={{ marginTop: "-3%" }}
              >
                <Grid item xs={9} sm={3} style={{ marginTop: "-4%" }}>
                  <TextField
                    label="Exp. Produce"
                    value={variety?.exp_yield1}
                    fullWidth
                    variant="standard"
                    size="small"
                    type="number"
                    margin="normal"
                    placeholder="Exp. Produce"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 100000,
                    }}
                    onChange={(event) => {
                      let value = parseInt(event.target.value);
                      if (value > 100000) {
                        value = 100000;
                      }
                      handleVarietyChange(index, "exp_yield1", value);
                    }}
                  />
                </Grid>
                <Grid item xs={9} sm={3} style={{ marginTop: "-2%" }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Unit</InputLabel>
                    <Select
                      label="Unit"
                      value={variety?.exp_yield1_unit}
                      onChange={(event) => {
                        let value = event.target.value;
                        handleVarietyChange(index, "exp_yield1_unit", value);
                      }}
                      size="small"
                      inputProps={{ "aria-label": "Unit" }}
                    >
                      <MenuItem value="" disabled>
                        Select Unit
                      </MenuItem>
                      <MenuItem value="kg">Kg</MenuItem>
                      <MenuItem value="tonnes">Tonnes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={9} sm={3} style={{ marginTop: "-4%" }}>
                  <TextField
                    label="Total Produce"
                    value={variety?.exp_yield2}
                    size="small"
                    variant="standard"
                    fullWidth
                    type="number"
                    margin="normal"
                    placeholder="Total Produce"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 100000,
                    }}
                    onChange={(event) => {
                      let value = parseInt(event.target.value);
                      if (value > 100000) {
                        value = 100000;
                      }
                      handleVarietyChange(index, "exp_yield2", value);
                    }}
                  />
                </Grid>

                <Grid item xs={9} sm={3} style={{ marginTop: "-2%" }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Unit</InputLabel>
                    <Select
                      label="Unit"
                      value={variety?.exp_yield2_unit}
                      onChange={(event) => {
                        let value = event.target.value;
                        handleVarietyChange(index, "exp_yield2_unit", value);
                      }}
                      size="small"
                      inputProps={{ "aria-label": "Unit" }}
                    >
                      <MenuItem value="" disabled>
                        Select Unit
                      </MenuItem>
                      <MenuItem value="kg">Kg</MenuItem>
                      <MenuItem value="tonnes">Tonnes</MenuItem>
                      <MenuItem value="square_meters">Square Meters</MenuItem>
                      <MenuItem value="hectares">Hectares</MenuItem>
                      <MenuItem value="acers">Acers</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                onClick={handleHelpIconClick}
                startIcon={
                  <AiOutlineQuestionCircle
                    style={{ color: colors.black[100], marginTop: "-25%" }}
                  />
                }
              >
                <Typography
                  sx={{ fontSize: "9.3px", color: colors.black[100] }}
                >
                  How to calculate expected yield
                </Typography>
              </Button>
             

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>How to calculate expected yield</DialogTitle>
                <DialogContent>
                  <ol>
                    <li>Step 1: Determine the total number of crops </li>
                    <li>Step 2: Multiply with average weight of the crop</li>
                  </ol>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </Dialog>

              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                display="flex"
                sx={{ marginTop: "-5%" }}
              >
                <Grid item xs={9} sm={6}>
                  <TextField
                    label="Seed Brand"
                    name="SeedBrand"
                    value={variety.seedBrand}
                    fullWidth
                    size="small"
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      maxLength: 55,
                    }}
                    onChange={(event) =>
                      handleVarietyChange(
                        index,
                        "seedBrand",
                        event.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={9} sm={6}>
                  <TextField
                    label="Crop Cycle"
                    name="cycle"
                    readOnly
                    value={variety?.cycle}
                    type="number"
                    fullWidth
                    size="small"
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 999,
                      inputMode: "numeric",
                    }}
                    onKeyPress={(event) => {
                      if (event.target.value.length >= 3) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(event) =>
                      handleVarietyChange(index, "cycle", event.target.value)
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={8} sx={{ marginTop: "1%" }}>
                <div style={{ border: "1px solid #ccc", padding: "2px" }}>
                  <Typography variant="h5" color={colors.black[200]}>
                    Stages
                  </Typography>
                  <table
                    style={{
                      width: "120%",
                      marginTop: "1%",
                      marginLeft: "11%",
                    }}
                  >
                    <tbody style={{ marginTop: "-7%" }}>
                      {variety?.stages?.map((stage, stageIndex) => (
                        <Grid>
                          <tr key={stageIndex} style={{ marginTop: "-45%" }}>
                            <td style={{ marginTop: "-1%" }}>
                              <TextField
                                id={`stage-select-${stageIndex}`}
                                select
                                label="Name"
                                value={stage?.stage_name}
                                onChange={(event) =>
                                  handleStageChange(
                                    event,
                                    index,
                                    stageIndex,
                                    "stage_name"
                                  )
                                }
                                displayEmpty
                                helperText="Select a stage Name"
                                variant="standard"
                                required
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: "140px",
                                        overflowY: "auto",
                                      }, // Set the max-height for the menu
                                    },
                                  },
                                }}
                                inputProps={{
                                  style: { width: "100%" },
                                  "aria-label": "Select Stages",
                                  maxLength: 50,
                                }}
                              >
                                {stageName?.map((stageOption, index) => (
                                  <MenuItem key={index} value={stageOption}>
                                    {stageOption}
                                  </MenuItem>
                                ))}
                                <MenuItem disabled value="">
                                  Select Stages
                                </MenuItem>
                              </TextField>
                            </td>

                            <td style={{ marginTop: "1%", marginLeft: "1%" }}>
                              <TextField
                                id="Day after sowing"
                                label="Day after sowing"
                                variant="standard"
                                type="number"
                                multiline
                                onKeyPress={(event) => {
                                  const keyCode = event.keyCode || event.which;
                                  const keyValue = String.fromCharCode(keyCode);
                                  if (/\D/.test(keyValue) && keyCode !== 8) {
                                    event.preventDefault();
                                  }
                                }}
                                // helperText="Enter no. of days after sowing "
                                value={stage?.date_aftr_sowing}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (value.length <= 3) {
                                    handleStageChange(
                                      event,
                                      index,
                                      stageIndex,
                                      "date_aftr_sowing"
                                    );
                                  }
                                }}
                                error={
                                  stage?.date_aftr_sowing?.toString()?.length >
                                  3
                                }
                                helperText={
                                  stage?.date_aftr_sowing?.toString()?.length >
                                  3
                                    ? "Enter 3 or less than 3 digits only"
                                    : "Number  of days (3 digits max)"
                                }
                              />
                            </td>
                            <td>
                              <IconButton
                                style={{ border: "none", marginTop: "12px" }}
                                onClick={() =>
                                  handleDeleteStage(index, stageIndex)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                          <Divider
                            style={{
                              margin: "1px -1px 4px 7px",
                              width: "5%",
                              color: "colors.black[100]",
                            }}
                          />
                        </Grid>
                      ))}
                      {variety?.stages?.length < Max_stage && (
                        <div
                          style={{
                            width: "140%",
                            marginTop: "-1%",
                            marginLeft: "-0.3%",
                          }}
                        >
                          <Button
                            onClick={() => addNewStage(index)}
                            sx={{ marginLeft: "-1.2%" }}
                            startIcon={
                              <AddCircleOutlineOutlinedIcon
                                style={{ color: colors.green.DEFAULT }}
                              />
                            }
                          >
                            <Typography
                              variant="h6"
                              color={colors.green.DEFAULT}
                            >
                              Add more stages
                            </Typography>
                          </Button>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </Grid>

              <Grid item xs={12} sm={8} sx={{ marginTop: "1%" }}>
                <div style={{ border: "1px solid #ccc", padding: "2px" }}>
                  <Typography variant="h5" color={colors.black[200]}>
                    Grades
                  </Typography>
                  <table
                    style={{
                      width: "100%",
                      marginTop: "1%",
                      marginLeft: "11%",
                    }}
                  >
                    <tbody style={{ marginTop: "-7%" }}>
                      {variety?.grades?.map((grades, gradesIndex) => (
                        <Grid>
                          <tr key={gradesIndex} style={{ marginTop: "-35%" }}>
                            <td style={{ marginTop: "-1%" }}>
                              <TextField
                                id={`grades-select-${gradesIndex}`}
                                select
                                label="Name"
                                value={grades?.grades_name}
                                onChange={(event) =>
                                  handlegradesChange(
                                    event,
                                    index,
                                    gradesIndex,
                                    "grades_name"
                                  )
                                }
                                displayEmpty
                                helperText="Please select a grades"
                                variant="standard"
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: "140px",
                                        overflowY: "auto",
                                      }, // Set the max-height for the menu
                                    },
                                  },
                                }}
                                inputProps={{
                                  style: { width: "100%" },
                                  "aria-label": "Select Grades",
                                }}
                                startAdornment={
                                  <InputAdornment position="start"></InputAdornment>
                                }
                              >
                                {gradesName?.map((gradesOption, index) => (
                                  <MenuItem key={index} value={gradesOption}>
                                    {gradesOption}
                                  </MenuItem>
                                ))}
                                <MenuItem disabled value="">
                                  Select Grades
                                </MenuItem>
                              </TextField>
                            </td>
                            <td style={{ marginTop: "1%" }}>
                              <TextField
                                id="standard-basic"
                                variant="standard"
                                label="Description"
                                multiline
                                inputProps={{
                                  style: { width: "100%" },
                                  maxLength: 100,
                                }}
                                helperText="Enter description"
                                value={grades?.grades_desc}
                                onChange={(event) =>
                                  handlegradesChange(
                                    event,
                                    index,
                                    gradesIndex,
                                    "grades_desc"
                                  )
                                }
                              />
                            </td>

                            <td>
                              <IconButton
                                style={{ border: "none", marginTop: "12px" }}
                                onClick={() =>
                                  handleDeletegrades(index, gradesIndex)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                          <Divider
                            style={{
                              margin: "1px -1px 4px 7px",
                              width: "5%",
                              color: "colors.black[100]",
                            }}
                          />
                        </Grid>
                      ))}
                      {variety?.grades?.length < Max_grades && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "-1%",
                            marginLeft: "0.3%",
                          }}
                        >
                          <Button
                            onClick={() => addNewGrades(index)}
                            startIcon={
                              <AddCircleOutlineOutlinedIcon
                                style={{ color: colors.green.DEFAULT }}
                              />
                            }
                          >
                            <Typography
                              variant="h6"
                              color={colors.green.DEFAULT}
                            >
                              Add more grades
                            </Typography>
                          </Button>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </Grid>

              <Grid item xs={9} sm={6} sx={{ marginTop: "-3%" }}>
                <TextField
                  label="Description"
                  name="description"
                  value={variety?.description}
                  fullWidth
                  margin="normal"
                  size="small"
                  inputProps={{
                    style: { width: "100%" },
                    min: 0,
                    max: 255,
                  }}
                  onChange={(event) =>
                    handleVarietyChange(
                      index,
                      "description",
                      event.target.value
                    )
                  }
                />
              </Grid>

              {cropData?.CropVarieties?.length >= 0 && (
                <Button
                  type="button"
                  varaint="outlined"
                  style={{
                    float: "right",
                    marginTop: "-3.2%",
                    marginRight: "-3%",
                  }}
                  onClick={() => handleRemoveVariety(index)}
                >
                  <Typography variant="h6" color={colors.green.DEFAULT}>
                    Cancel
                  </Typography>
                </Button>
              )}
            </div>
          </div>
        ))}

        <Grid
          item
          xs={9}
          sm={6}
          style={{ marginLeft: "30%", marginRight: "4%", marginTop: "1%" }}
        >
          <Button
            sx={{
              borderRadius: "4%",
              borderStyle: "dotted",
              borderWidth: "2px",
            }}
            onClick={handleAddVariety}
            startIcon={
              <AddCircleOutlineOutlinedIcon
                style={{ color: colors.green.DEFAULT }}
              />
            }
          >
            <Typography variant="h5" color={colors.green.DEFAULT}>
              Add More Crop Variety
            </Typography>
          </Button>
        </Grid>
      </div>
    );
  };
  const renderStepThree = () => {
    return (
      <div style={{ width: "92%", marginLeft: "5%" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            style={{ width: "100%", marginBottom: "5px", marginRight: "5%" }}
          >
            {/* <CropCard
              cropData={{
                CropName: cropData?.CropName,
                CropType: cropData?.CropType,
                CropCode: cropData?.CropCode,
                CropVarieties: cropData?.CropVarieties,
              }}
              key={0}
              index={0}
              isVarietyCard={false}
            /> */}
          </div>
          {cropData?.CropVarieties?.map((variety, index) => (
            <div
              style={{
                width: "100%",
                marginBottom: "5px",
                marginRight: "5%",
              }}
            >
              <CropCard
                cropData={{
                  CropName: cropData?.CropName,
                  CropType: cropData?.CropType,
                  CropCode: cropData?.CropCode,
                  CropVarieties: [variety],
                }}
                key={index + 1}
                index={index + 1}
                isVarietyCard={true}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return renderStepOne();
      case 2:
        return renderStepTwo();
      case 3:
        return renderStepThree();
      default:
        return null;
    }
  };
  return (
    <Grid>
      <Slide direction="left" in={errorOpen || successOpen}>
        <Alert
          severity={errorOpen ? "error" : "success"}
          onClose={() => {
            if (errorOpen) {
              setErrorOpen(false);
            } else if (successOpen) {
              setSuccessOpen(false);
            }
          }}
          style={{
            position: "fixed",
            top: "8px",
            right: errorOpen || successOpen ? "2px" : "-100%",
            zIndex: 1000,
            transition: "right 0.3s ease-in-out",
          }}
        >
          <AlertTitle>{errorOpen ? "Error" : "Success"}</AlertTitle>
          {errorOpen
            ? "Please fill out all the required fields before proceeding."
            : "Form submitted successfully!"}
        </Alert>
      </Slide>
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />

      <form>
        <div>
          <Stepper activeStep={currentStep - 1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderSwitch(currentStep)}
        </div>
        <div
          style={{
            overflowY: "auto",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "2%",
          }}
        >
          {currentStep !== 1 && (
            <Button
              sx={{ marginRight: "auto", marginLeft: "3%" }}
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
            >
              <Typography variant="h5" color={colors.green.DEFAULT}>
                Previous
              </Typography>
            </Button>
          )}
          {currentStep !== 3 ? (
            <Button
              contained="primary"
              sx={{
                marginRight: "6.4%",
                backgroundColor: colors.green.DEFAULT,
              }}
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
            >
              <Typography variant="h5" color={colors.white.DEFAULT}>
                Next
              </Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{ marginRight: "4%", backgroundColor: colors.green.DEFAULT }}
            >
              <Typography variant="h5" color={colors.white.DEFAULT}>
                Submit
              </Typography>
            </Button>
          )}
        </div>
      </form>
    </Grid>
  );
};

export default CropMultiForm;
