import React, { useState ,useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import { API_ENDPOINTS} from '../../ApiEndpoint/ApiEndpoint';
import axios from "axios";
import { GiFruitBowl } from "react-icons/gi";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  Box,
  Tab,
  TextField,
  Tabs,
  Autocomplete,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { TiTickOutline } from "react-icons/ti";
import { ScaleLoader } from 'react-spinners';
import SuccessPopUp from '../PopUpMsg/SuccessPopUp';
import ErrorPopUp from '../PopUpMsg/ErrorPopUp';
import { useNavigate } from "react-router-dom";
const axiosInstanceOMS = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_OMS_URL}`,
});

const axiosInstanceWMS = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});
const RatePredictionGraph = () => {
  axiosInstanceWMS.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [orderGraphData, setOrderGraphData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [skuNames, setSkuNames] = useState([]);
  const [skuCode, setSkuCode] = useState('');
  // Sample data for different SKUs
  useEffect(()=>{
    fetchSKUNames();
  },[]);
  // useEffect(()=>{
  //   fetchOrderGraphData();
  // },[skuCode]);
  const fetchSKUNames = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceWMS.get(API_ENDPOINTS.GET_WMS_DASHBOARD_RATE_PREDICTION_GET_SKU, {
        params: {
          limit: 1000,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      const data = await response?.data?.data;
      if (response.status >= 200 && response.status < 305) {
        setSkuNames(data);
        console.log("sku Name ---> ",data);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };
  const fetchOrderGraphData = async (skuCode) => {
    console.log("skucode in fetch function",skuCode);
    
    setIsLoading(true);
    try {
      const response = await axiosInstanceWMS.get(
        API_ENDPOINTS.GET_WMS_DASHBOARD_RATE_PREDICTION_DATA(skuCode),
        {

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data[0]?.data;
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        const transformedData = data.map(item => ({
          x: new Date(item.date).getTime(),
          y: item.rate,
        }));
        setOrderGraphData(transformedData);
        
        console.log("data returned here for sku ", data)
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };
  

  // State to store selected SKU and chart options
  const [selectedSKU, setSelectedSKU] = useState('tomatoes');
  useEffect(() => {
    setChartOptions(prevState => ({
      ...prevState,
      series: [{
        name: 'Rate',
        data: orderGraphData,
      }],
    }));
  }, [orderGraphData]);
  const [chartOptions, setChartOptions] = useState({
    series: [{
      name: 'Rate',
      data: orderGraphData,
    }],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      // title: {
      //   text: 'Rate Prediction',
      //   align: 'left'
      // },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(2);
          }
        },
        title: {
          text: 'Rate'
        }
      },
      xaxis: {
        title: {
          text: "Date",
          offsetX: 1,
          style: {
            fontSize: "15px",
            fontWeight: 400,
            color: "#333",
          },
        },
        
        type: "datetime",
        labels: {
          datetimeUTC: true,
          style: {
            fontSize: "12px",
            fontWeight: 400,
            color: "#333",
          },
        },
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return val.toFixed(2);
          }
        }
      }
    }
  });
  const handleApplyClick = () => {
    console.log("sku code ",skuCode);
    fetchOrderGraphData(skuCode);
  };
  
  return (
    <>
      <div>
      <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}>
      <Grid item xs={12} sm={2.2} md={2.2}>
      <FormControl fullWidth>
      <InputLabel>Select SKU</InputLabel>
      <Select
        label="Select SKU"
        value={skuCode}
        onChange={(e) => {
          setSkuCode(e.target.value);
        }}
        size="small"
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: { maxHeight: "150px" }, // Set the max-height for the menu
          },
        }}
        inputProps={{ "aria-label": "Select Day" }}
        startAdornment={
          <InputAdornment position="start">
            <IconButton edge="start" size="small" disabled>
              <GiFruitBowl />
            </IconButton>
          </InputAdornment>
        }
      >
        
      {Array.isArray(skuNames) && skuNames?.map((item) => (
        <MenuItem key={item.sku_code} value={item.sku_code}>
          {item.sku_name}
        </MenuItem>
      ))}
      </Select>
    </FormControl>
        </Grid>
        <Grid item xs={12} sm={0.8} md={0.8}>
        <Button
          variant="contained"
          startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
          sx={{
            backgroundColor: "#34519fe6",
            color: "#ffffff",
          }}
          size="small"
          onClick={handleApplyClick}
          disabled={!skuCode}
        >
          Apply
        </Button>
      </Grid>
      </Grid>
      </Grid>
      </div>
      <div id="chart">
        <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="area" height={350} />
      </div>
     
    </>
    
  );
};

export default RatePredictionGraph;
