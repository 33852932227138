import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  TextField,
  FormControl,
  Box,
  Autocomplete,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import NullScreen from "../PopUpMsg/NullScreen";
import { BsGraphDownArrow } from "react-icons/bs";
import { BsGraphUpArrow } from "react-icons/bs";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { tokens } from "../WMStheme";
import SocketReportPopup from "../../Attendance/HomePage/SocketReportPopup";
import { useTheme } from "@mui/material";

import { BASE_WMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../PopUpMsg/SuccessPopUp";
import ErrorPopup from "../PopUpMsg/ErrorPopUp";
import { LuWarehouse } from "react-icons/lu";

import { IoIosPerson } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { GiFruitBowl } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { Divider } from "antd";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import Paper from "@mui/material/Paper";
import { ulid } from "ulid";
import { BsGraphUp } from "react-icons/bs";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));

const columns = [
  { id: "is_inc", label: "Inventory Action" },
  { id: "warehouse_name", label: "Warehouse" },
  { id: "name", label: " Name" },
  { id: "code", label: "Code" },
  { id: "item_uom", label: "UOM" },
  { id: "source_type", label: "Source Type" },
  { id: "source_code", label: "Source Code" },

  { id: "qty", label: "Quantity" },
  { id: "reason", label: "Reason" },
  { id: "created_by", label: "Performed By" },
  { id: "created_at", label: "Created At" },
];

const InventoryUpDown = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  const [salesReportData, setSalesReportData] = useState([]);
  // const [salesReportData, setSalesReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [customerNames, setCustomerNames] = useState([]);
  const [warehouseNames, setWarehouseNames] = useState([]);

  const [generatedId, setGeneratedId] = useState(null);

  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [type, setType] = useState(null);
  const [incflag, setIncFlag] = useState(null);
  const [warehouseId, setWarehouseId] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [skuNames, setSkuNames] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [skuId, setSkuId] = useState(null);
  const [downloadActive, setDownloadActive] = useState(false);
  const [filterValues, setFilterValues] = useState({
    limit: 12,
    offset: offset,
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    warehouse_id: null,
    item_id: skuId,
    // type: type,
    // is_inc: incflag,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    limit: 12,
    offset: offset,
    type: type,
    is_inc: incflag,
    item_id: skuId,
    warehouse_id: null,
  });
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,
      limit: 12,
      offset: offset,
      warehouse_id: warehouseId,
      type: type,
      item_id: skuId,
      is_inc: incflag,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, type, incflag, warehouseId,skuId]);

  useEffect(() => {
    fetchGridData(filterValues);
    fetchProductMapped();
    fetchSKUNames();
  }, []);

  const handleApplyClick = () => {
    const startDateObj = new Date(temporaryFilterValues.start_date);
    const endDateObj = new Date(temporaryFilterValues.end_date);
    
    // Calculate the difference in days
    const differenceDays = Math.abs((endDateObj.getTime() - startDateObj.getTime()) / (1000 * 60 * 60 * 24));
    
    // Check if the difference is greater than a week
    const isDifferenceGreaterThanAWeek = differenceDays < 15;
    console.log("difference-----.",isDifferenceGreaterThanAWeek);
    // Set downloadActive based on the condition
    setDownloadActive(!isDifferenceGreaterThanAWeek);
    const newFilterValues = {
      start_date: temporaryFilterValues?.start_date,
      end_date: temporaryFilterValues?.end_date,
      limit: 12,
      offset: 1,
      warehouse_id: temporaryFilterValues.warehouse_id,
      type: temporaryFilterValues?.type,
      item_id: temporaryFilterValues.item_id,
      is_inc: temporaryFilterValues?.is_inc,
    };
    setStartDate(temporaryFilterValues?.start_date);
    setSkuId(temporaryFilterValues?.item_id);
    setEndDate(temporaryFilterValues?.end_date);
    setIncFlag(temporaryFilterValues?.is_inc);
    setWarehouseId(temporaryFilterValues.warehouse_id);
    setType(temporaryFilterValues?.type);
    setFilterValues(newFilterValues);
    fetchGridData(newFilterValues);
  };

  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchGridData(updatedValues);
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const fetchGridData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_INVENTORY_ADJUSTMENT_REPORT_DATA,
        {
          params: {
            ...newParams,
            download: 0,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      console.log("data in fetchGridData in Loss Report Page", data);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setSalesReportData(data?.inv_adjust);
        setTotalPages(data?.total_pages);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleRefreshClick = () => {
    const initialValues = {
      limit: 12,
      offset: 1,
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
    };
    setOffSet(1);
    setIncFlag(null);
    setType(null);
    setWarehouseId(null);
    setSkuId(null);
    setDownloadActive(true);
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    fetchGridData(initialValues);
    // setSalesReportData([]);
  };
  const formatDateIST = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} || ${hours}:${minutes}`;
  };
  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const containerStyle = {
    maxHeight: "78vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "76vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    marginTop: "1%",
    display: "flex",
    height: "71vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };

  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent() {
    return (
      <>
        <TableBody>
          {salesReportData?.map((row, index) => (
            <StyledTableRow key={index}>
              {columns?.map((column) => (
                <StyledTableCell key={column?.id} align="center">
                  {column.id === "is_inc" ? (
                    // Conditionally render arrow icon based on the flag value
                    row[column.id] ? (
                      <BsGraphUpArrow
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          fontSize: "28px",
                        }}
                      />
                    ) : (
                      <BsGraphDownArrow
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "28px",
                        }}
                      />
                    )
                  ) : column?.id === "created_at" ||
                    column?.id === "updated_at" ? (
                    formatDateIST(row[column?.id])
                  ) : (
                    // Render other columns normally
                    row[column.id]
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </>
    );
  }

  const fetchProductMapped = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_REPACK_PRODUCT_LIST,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data?.Data;
      console.log("data in fetchProductMapped-------", data);
      setProductNames(data?.product_list);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  const fetchSKUNames = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_SKU_DATA, {
        params: {
          limit: 1000,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response?.data?.Data?.sku_list;
      setSkuNames(data);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "8vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={0.6}>
              <Grid item xs={12} sm={2.2} md={2.2}>
                <RangePicker
                  onChange={handleDateChange}
                  key={refreshCount}
                  format={dateFormat}
                  defaultValue={[
                    dayjs(formatDate(startDate), dateFormat),
                    dayjs(formatDate(endDate), dateFormat),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    label="Select Warehouse"
                    value={warehouseId}
                    onChange={(e) => {
                      setWarehouseId(e.target.value); // Set warehouseId, not warehouseData
                    }}
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Warehouse" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <CiCalendarDate />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {Array.isArray(warehouseData) &&
                      warehouseData?.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Select Type</InputLabel>
                  <Select
                    label="Select Type"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Type" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <GiFruitBowl />
                        </IconButton>
                      </InputAdornment>
                    }
                    // disabled={!warehouseId}
                  >
                    <MenuItem value="sku">SKU</MenuItem>
                    <MenuItem value="product">Product</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={type === "sku" ? skuNames : productNames || []}
                    getOptionLabel={(option) =>
                      (type === "sku" ? option?.sku_name : option?.name) || ""
                    }
                    value={
                      type === "sku"
                        ? skuNames?.find((sku) => sku?.id === skuId) || null
                        : productNames?.find(
                            (product) => product?.id === skuId
                          ) || null
                    }
                    onChange={(event, newValue) => {
                      setSkuId(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Item"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <GiFruitBowl />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    disabled={!type}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1.5} md={1.5}>
                <FormControl fullWidth>
                  <InputLabel>Adjustment Type</InputLabel>
                  <Select
                    label="Adjustment Type"
                    value={incflag}
                    onChange={(e) => {
                      setIncFlag(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select SKU" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <BsGraphUp />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={true}>Up</MenuItem>
                    <MenuItem value={false}>Down</MenuItem>
                    {/* {skuNames?.map((sku) => (
                      <MenuItem key={sku?.id} value={sku?.id}>
                        {sku?.sku_name}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!startDate || !endDate}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={1.6} md={1.6}></Grid>
              <Grid item xs={12} sm={2} md={2} textAlign="right">
                {/* <Button
              variant="contained"
              startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
              sx={{
                backgroundColor: colors.green.DEFAULT,
              }}
              size="small"
              onClick={handleGenerateReportClick}
             
              disabled = {!salesReportData?.length }
                 title="Please select a 15 days interval for downloading."
            >
              <Typography variant="h6" fontFamily="Lexend Deca">
                Download Report
              </Typography>
            </Button> */}
              </Grid>
            </Grid>
          </Grid>

          {openDialog && (
            <SocketReportPopup
              setOpenDialog={setOpenDialog}
              requestId={generatedId}
            />
          )}
        </Grid>

        <Grid container spacing={1} style={containerStyle}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : salesReportData?.length === 0 ? (
            <NullScreen containerheight="78vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 200 }} aria-label="customized table">
                    <TableHeadComponent />
                    <TableRowsComponent data1={salesReportData} />
                  </Table>
                </TableContainer>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <div style={{ flex: "1" }}></div>
                <Pagination
                  count={totalPages}
                  page={offset}
                  onChange={handleChangePage}
                  style={{ color: "#5e89c7" }}
                />
              </div>
            </>
          )}
        </Grid>
      </Box>

      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default InventoryUpDown;
