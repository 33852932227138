import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { tokens } from "../../../../theme";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  useTheme,
  MenuItem,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Search from "../../../../Assets/ConfigurationImg/search.png";
import { makeStyles } from "@mui/styles";
import jwtDecode from "jwt-decode";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const useStyles = makeStyles((theme) => ({
  menuItem: {
    maxHeight: "50px",
    overflowY: "auto",
  },
}));

const ZoneView = ({ data, handleCloseDialog }) => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const hiddenToken = localStorage.getItem("token");
  const client_id = jwtDecode(hiddenToken).client_id;

  const [updatedData, setUpdatedData] = useState("");
  const [openDialog2, setOpenDialog2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  console.log("data here ", data);
  const [data1, setData1] = useState([]);
  useEffect(() => {
    if (data?.length === 0) return; // Skip if data is empty

    const filteredRows = data?.filter((rows) => rows.farm_name);
    const zones = filteredRows?.map((rows) =>
      rows?.zones?.map((zone) => ({
        zone_name: zone?.zone_name,
        zone_area: zone?.zone_area,
        zone_area_unit: zone?.zone_area_unit,
        zone_capacity: zone?.zone_capacity,
        starting_location: zone?.starting_location,
        ending_location: zone?.ending_location,
        zone_desciption: zone?.zone_desciption,
        zone_id: zone?.zone_id,
      }))
    );
    const flatZones = zones.flat();
    setData1(flatZones);
    setIsLoading(false);
  }, [data]);

  console.log("heyyyy this is data ", data);
  console.log("CFGVHJBHJ", data1);
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#2c7B55",
          },

          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#166534", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    console.log("####", row.original?.zone_id);
    console.log("save button clicked!!!!!!");
    if (!Object?.keys(validationErrors)?.length) {
      // Create a new array to hold the updated rows
      // fetchFarmData();
      // exitEditingMode();

      try {
        if (row && row.original) {
          console.log("Valuesssssssssss:", values);
          console.log("Valuesssssssssss:", row.original);
          const updatedRows1 = {
            ...row.original,
            zone_name: values.zone_name, // Update the specific field with the new value

            zone_capacity: values.zone_capacity,
            starting_location: values.starting_location,
            ending_location: values.ending_location,
            zone_desciption: values.zone_desciption,
          };

          // Update the state with the new data1
          setData1((prevData1) =>
            prevData1?.map((zone) =>
              zone.zone_id === row.original?.zone_id ? updatedRows1 : zone
            )
          );
          //onUpdateData(updatedRows1);
          console.log("heyyyy this is updatedRows data???? ", updatedRows1);
          console.log("heyyyy this is data???? ", data1);

          const response = await axiosInstance.put(
            `${API_ENDPOINTS.PUT_CONFIGURATION_ZONE}`,
            updatedRows1,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("updated data:", updatedRows1);
          if (response.status >= 200 && response.status < 305) {
            // onUpdateData(updatedRows1);
            setData1(data1);
            exitEditingMode();
            setOpenDialog2(true);
          } else {
            console.log("Failed to update the zone data");
          }
        } else {
          console.log("Error: 'row' or 'row.original' is undefined");
        }
      } catch (error) {
        setApiErrorOpen(true);
        console.log("Error", error);
        console.log("Error occurred while updating the zone data:", error);
      }
    } else {
      setData1(data1); // Revert the changes if there are validation errors
    }
  };
  const handleCloseDialog2 = () => {
    // onUpdateData();

    handleCloseDialog();
    setOpenDialog2(false); // Close the dialog
  };

  console.log("zone updated details ", data1);

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        // onBlur: (event) => {
        //   const isValid =

        //   if (!isValid) {
        //     //set validation error for cell if invalid
        //     setValidationErrors({
        //       ...validationErrors,
        //       [cell.id]: `${cell.column.columnDef.header} is required`,
        //     });
        //   } else {
        //     //remove validation error for cell if valid
        //     delete validationErrors[cell.id];
        //     setValidationErrors({
        //       ...validationErrors,
        //     });
        //   }
        // },
      };
    },
    [validationErrors]
  );

  const columns1 = useMemo(
    () => [
      {
        header: "Zone Name",
        accessorKey: "zone_name",
        size: 10,
        enableEditing: false,
        //you can access a cell in many callback column definition options like this
      },
      {
        header: "Zone Area",
        accessorKey: "zone_area",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Area Unit",
        accessorKey: " zone_area_unit",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Zone Capacity",
        accessorKey: "zone_capacity",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
        }),
      },
      // { id: "6",
      //   header: "Capacity Unit",
      //   accessorKey: "zone_capacity_unit",
      //   size: 10,
      // },
      {
        header: "Start Location ",
        accessorKey: "starting_location",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },
      {
        header: "End Location",
        accessorKey: "ending_location",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },
      {
        header: "Description",
        accessorKey: "zone_desciption",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 100 },
        }),
      },
      // {
      //   header: "",
      //   accessorKey: "actions",
      //   size: 10,
      //   Cell: ({ row, table }) => (
      //     <Box sx={{ display: "flex", gap: "1rem" }}>
      //       <Tooltip arrow placement="right" title="Delete">
      //         <IconButton
      //           color="error"
      //           onClick={() => handleDeleteRowZone(row.original.zone_id)}
      //         >
      //           <Delete />
      //         </IconButton>
      //       </Tooltip>
      //     </Box>
      //   ),
      // },
    ],
    [getCommonEditTextFieldProps]
  );

  return (
    <div
      style={{
        // width: "100%",
        backgroundColor: "#ffffff",
        // margin: "55px -21px 15px 30px",
        // justifyItems: "center",
        boxShadow: "rgb(0 0 0 / 21%) 2px 2px 2px 2px",
      }}
    >
      {isLoading ? ( // Check if data is still loading
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <ScaleLoader color="#2C7B55" />
        </div>
      ) : (
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns1}
            data={data1}
            enableGrouping
            enableStickyHeader
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 5 },
            }}
            editingMode="modal"
            enableEditing={userDetails?.config_b_editFarmDetails}
            onEditingRowSave={handleSaveRow}
            muiTableBodyProps={{
              sx: {
                //stripe the rows, make odd rows a darker color
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#f8fafc",
                },
              },
            }}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: "650",
                color: "#2c7B55",
                fontSize: "15px",
              },
            }}
            // onEditingRowCancel={handleCancelRowEdits}
            muiTableContainerProps={{ sx: { marginBottom: "15px" } }}
            muiToolbarAlertBannerChipProps={{ color: "primary" }}
          />
        </ThemeProvider>
      )}
      <Dialog open={openDialog2} onClose={handleCloseDialog2}>
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#198754" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p>Your data has been updated successfully.</p>
        </DialogContent>
        <Button
          onClick={handleCloseDialog2}
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "2%",
            color: "#2c7B55",
          }}
        >
          OK
        </Button>
      </Dialog>
    </div>
  );
};
const FarmView = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const hiddenToken = localStorage.getItem("token");
  const client_id = jwtDecode(hiddenToken).client_id;
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  // console.log("Detailssssssssssssssssssssssssssssssss---->>>>", userDetails);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [farmTypeOptions, setFarmTypeOptions] = useState([]);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const units = [
    {
      label: "acers",
      value: "acers",
      conversionFactor: 0.404686,
    },
    {
      label: "hectares",
      value: "hectares",
      conversionFactor: 1,
    },
    {
      label: "square meters",
      value: "square meters",
      conversionFactor: 0.0001,
    },
  ];
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    fetchFarmTypeOptions();
    fetchFarmData();
    const loaderTimer = setTimeout(() => {
      setIsLoading(false);
    }, 30000); // Display loader for 3 seconds

    fetchFarmData();

    return () => clearTimeout(loaderTimer);
  }, []);
  useEffect(() => {
    // Update the gridData state with the new rows whenever the rows state changes
    setGridData(rows);
  }, [rows]);
  const fetchFarmTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARMTYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setFarmTypeOptions(options);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error", error);

      // Handle error
    }
  };
  const fetchCountryOptions = async () => {
    try {
      const apiKey = "dHQyYmpnOWphektWaUVLTmh5MXFaQVZKNmRoc0xQZnRtekNrRlFmeA==";
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      const response = await fetch(
        "https://api.countrystatecity.in/v1/countries",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch countries");
      }

      const data = await response.json();

      setCountryOptions(data);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error", error);
      // Handle error
      console.log("Error fetching countries:", error);
    }
  };
  useEffect(() => {
    fetchFarmTypeOptions();
    fetchCountryOptions();
  }, []);
  const fetchFarmData = () => {
    axiosInstance
      .get(API_ENDPOINTS.GET_CONFIGURATION_FARM)
      .then((response) => {
        const data = response?.data;
        console.log("PRACHI,PLEASE CHECK DATA IS COMING HERE OR NOT!!!");
        console.log("!!!!!!!!!", data?.result?.rows);

        setRows(data?.result?.rows);
        setIsLoading(false);
      })
      .catch((error) => {
        setApiErrorOpen(true);
        console.log("Error", error);
      });
    setIsLoading(false);
  };
  console.log("cgh", rows);
  console.log("thghh", gridData);
  const updateDataInZone = (updatedData) => {
    // Function to update the data in child component
    console.log("XXXXXXXXXX", updatedData);
    setRows(updatedData);
    console.log("XXXXXXXXXXYYYY", rows);
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "#2c7B55", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        button: {
          color: "#ffffff",

          backgroundColor: "#2c7B55",
        },
        typography: {
          button: {
            //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#ffffff",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
          backgroundColor: "#2c7B55",
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
              root: {
                color: "#ffffff", // set the color of the text inside the button
                backgroundColor: "#2c7B55", // set the background color of the button
                "&:hover": {
                  backgroundColor: "#2c7B55", // set the background color when hovering over the button
                },
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#2c7B55", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const [tableData, setTableData] = useState(() => rows);
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors)?.length) {
      try {
        if (row && row.original) {
          const editedFarmCapacity = parseFloat(values.farm_capacity);
          const updatedRows = {
            ...row.original,
            farm_capacity: editedFarmCapacity,
            farm_growing_media: values?.farm_growing_media,
            farm_incharge: values?.farm_incharge,
            farm_subordinate: values?.farm_subordinate,
            farm_total_farmer: values?.farm_total_farmer,
            farm_id: row.original?.farm_id,
          };
          console.log("!!!!!!!!!!!!!!!!!!", updatedRows);
          // Update the rows state with the updated data
          setRows(updatedRows);

          const response = await axiosInstance.put(
            API_ENDPOINTS.PUT_CONFIGURATION_FARM,
            updatedRows,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("responseeeeeeeeee", response);
          if (response.status >= 200 && response.status < 305) {
            // The data was successfully updated on the backend
            // You can perform any additional actions or update the UI here
            fetchFarmData();
            exitEditingMode();
            setOpenDialog1(true); // Required to exit editing mode and close modal
          } else {
            // Handle the error if the PUT request was not successful
            console.log("Failed to update the farm data");
          }
        } else {
          console.log("Error: 'row' or 'row.original' is undefined");
        }
      } catch (error) {
        setApiErrorOpen(true);
        console.log("Error", error);
        console.log("Error occurred while updating the farm data:", error);
      }
    } else {
      setRows(rows); // Revert the changes if there are validation errors
    }
  };

  const handleCloseDialog1 = () => {
    fetchFarmData();
    setOpenDialog1(false); // Close the dialog
  };
  console.log("outside q55555555", rows);
  const growingMediaOptions = [
    { value: "Water+Nutrient", label: "Water+Nutrient" },
    { value: "Soil", label: "Soil" },
    { value: "Cocopeat", label: "Cocopeat" },
  ];

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };
  const handleRowClick = (row) => {
    setSelectedRow(row);
    console.log("row dispaly:", selectedRow);
    setIsDialogOpen(true);
  };
  console.log("44444444444", selectedRow);
  const handleCloseDialog = () => {
    fetchFarmData();
    setIsDialogOpen(false);
  };
  const handleButtonClick = () => {
    navigate("/mainpage");
  };
  const handleDeleteRow = useCallback(
    async (row) => {
      const confirmed = window.confirm(
        `Are you sure you want to delete ${row.getValue("farm_name")}`
      );
      if (!confirmed) {
        return;
      }
      try {
        // Send the delete request to the API
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/deleteFarm/id=${row.original?.farm_id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              client_id: client_id,
            },
          }
        );

        if (response.ok) {
          // Delete the row from the local table data
          const updatedRows = rows.filter(
            (item) => item?.farm_id !== row.original?.farm_id
          );
          setRows(updatedRows);
        } else {
          console.log("Failed to delete the farm");
        }
      } catch (error) {
        setApiErrorOpen(true);
        console.log("Error", error);
        console.log("Error occurred while deleting the farm:", error);
      }
    },
    [rows]
  );
  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        // onBlur: (event) => {
        //   const isValid =

        //   if (!isValid) {
        //     //set validation error for cell if invalid
        //     setValidationErrors({
        //       ...validationErrors,
        //       [cell.id]: `${cell.column.columnDef.header} is required`,
        //     });
        //   } else {
        //     //remove validation error for cell if valid
        //     delete validationErrors[cell.id];
        //     setValidationErrors({
        //       ...validationErrors,
        //     });
        //   }
        // },
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      {
        header: "Zones",

        size: 4,
        enableEditing: false, //disable editing on this column
        //you can access a cell in many callback column definition options like this
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: () => {
            //setIsDialogOpen(true);
            handleRowClick(cell.row.original);
            console.log("hnirfnthto", cell.row.original);
            console.log(cell.getValue(), cell.id);
          },
          title: "Click to view zone details",
        }),
        //or in the component override callbacks like this
        Cell: ({ cell }) => {
          return (
            <div className="tooltip-cell" title="Click to view zone details">
              <IconButton
                className="icon-wrapper"
                onMouseEnter={() => console.log("Hovering...")}
              >
                <DoubleArrowIcon />
                {/* Replace IconComponent with your desired icon */}
              </IconButton>
              <span className="farm-name">{cell.getValue()}</span>
            </div>
          );
        },
      },
      {
        header: " Name",
        accessorKey: "farm_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Area",
        accessorKey: "farm_area",
        enableEditing: false,
        size: 10,

        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
        }),
        EditableCell: ({ value, onChange, enableEditing }) =>
          enableEditing ? (
            <TextField type="number" value={value} onChange={onChange} />
          ) : (
            <Typography>{value}</Typography>
          ),
      },
      {
        header: " Area Unit",
        accessorKey: "farm_area_unit",
        size: 10,
        enableEditing: false, //disable editing on this column
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: units?.map((unit) => (
            <MenuItem key={unit.value} value={unit.value}>
              {unit.label}
            </MenuItem>
          )),
        },
      },
      {
        header: "Type",
        accessorKey: "farm_type",
        size: 10,
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: farmTypeOptions?.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          )),
        },
      },

      // {
      //   header: "Capacity",
      //   accessorKey: "farm_capacity",
      //   size: 10,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //     type: "number",
      //   }),
      // },
      // {
      //   header: "Unit",
      //   accessorKey: "farm_capacity_unit",
      //   size: 10,
      //   enableEditing: false, //disable editing on this column
      // },
      {
        header: " Growing Media ",
        accessorKey: "farm_growing_media",
        size: 10,
        enableEditing: "true",
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: growingMediaOptions?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )),
        },
      },
      {
        header: " Incharge",
        accessorKey: "farm_incharge",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },
      // {
      //   header: "Subordinate",
      //   accessorKey: "farm_subordinate",
      //   size: 10,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //     inputProps: { maxLength: 40 },
      //   }),
      // },
      {
        header: "Country",
        accessorKey: "farm_country",
        size: 10,

        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: countryOptions?.map((country) => (
            <MenuItem
              key={country.id}
              value={country}
              className={classes.menuItem}
            >
              {country.name}
            </MenuItem>
          )),

          MenuProps: {
            PaperProps: {
              style: { maxHeight: "100px", overflowY: "auto" }, // Set the max-height for the dropdown menu
            },
          },
        },
      },
      {
        header: "State",
        accessorKey: "farm_state",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },
      {
        header: "City",
        accessorKey: "farm_city",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },
      // {
      //   header: "Street",
      //   accessorKey: "farm_street",
      //   size: 10,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //     inputProps: { maxLength: 100 },
      //   }),
      // },
      {
        header: "Total Farmer",
        accessorKey: "farm_total_farmer",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
        }),
      },
      {
        header: " Description",
        accessorKey: "farm_description",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 100 },
        }),
      },
      // {
      //   header: "",
      //   accessorKey: "actions",
      //   size: 10,
      //   Cell: ({ row, table }) => (
      //     <Box sx={{ display: "flex", gap: "1rem" }}>
      //       <Tooltip arrow placement="right" title="Delete">
      //         <IconButton
      //           color="error"
      //           onClick={() => handleDeleteRow(row.original.employee_id)}
      //         >
      //           <Delete />
      //         </IconButton>
      //       </Tooltip>
      //     </Box>
      //   ),
      // },
    ],
    [getCommonEditTextFieldProps]
  );

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Farm Details
        </Typography>

        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon style={{ color: colors.green.DEFAULT }} />}
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Back
          </Typography>
        </Button>
      </Grid>

      <Grid sx={{ marginTop: "2%" }}>
        {isLoading ? ( // Check if data is still loading
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <ScaleLoader color="#2C7B55" />
          </div>
        ) : (
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={gridData} // Use the gridData state here
              enableGrouping
              enableStickyHeader
              // enableColumnVirtualization
              enableRowVirtualization
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 5 },
              }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: "650",
                  color: "#2c7B55",
                  fontSize: "15px",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  //stripe the rows, make odd rows a darker color
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f8fafc",
                  },
                },
              }}
              editingMode="modal" //default
              enableEditing={userDetails?.config_b_editFarmDetails}
              onEditingRowSave={handleSaveRow}
              onEditingRowCancel={handleCancelRowEdits}
              muiTableContainerProps={{ sx: { marginBottom: "15px" } }}
              muiToolbarAlertBannerChipProps={{ color: "secondary" }}
            />
          </ThemeProvider>
        )}
      </Grid>
      <Dialog
        open={
          isDialogOpen &&
          selectedRow &&
          selectedRow?.zones?.length > 0 &&
          selectedRow?.zones[0]?.zone_name !== null
        }
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h2">Zones Details</Typography>
        </DialogTitle>
        <DialogContent>
          {selectedRow &&
            selectedRow.zones &&
            selectedRow?.zones?.length > 0 &&
            selectedRow?.zones[0]?.zone_name !== null && (
              <div style={{ width: "100%" }}>
                <Typography variant="h4" color={colors.black.DEFAULT}>
                  {selectedRow?.farm_name?.toUpperCase()}
                </Typography>
                {selectedRow && selectedRow?.zones[0]?.zone_name ? (
                  <ZoneView
                    data={[selectedRow]}
                    handleCloseDialog={handleCloseDialog}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "10%",
                      textAlign: "center",
                    }}
                  >
                    <img src={Search} style={{ width: "10%", height: "10%" }} />
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Lexend Deca",
                        fontSize: "16px",
                      }}
                    >
                      Zone is not available for this farm.
                    </Typography>
                  </div>
                )}
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{ backgroundColor: "#2c7B55", marginRight: "1%" }}
            variant="contained"
            // color="#2c7B55"
          >
            <Typography variant="h5" color={colors.white.DEFAULT}>
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog1} onClose={handleCloseDialog1}>
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#198754" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p>Your data has been updated successfully.</p>
        </DialogContent>
        <Button
          onClick={handleCloseDialog1}
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "2%",
            color: "#198754",
          }}
        >
          OK
        </Button>
      </Dialog>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};

export default FarmView;
