import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Stack, OutlinedInput, InputAdornment, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";
import { AlertDialog } from "../../PopUpMsg/AlertDialog";
import { ErrorDialogBox } from "../../PopUpMsg/ErrorDialogPopUp";
import { PiUniteSquare } from "react-icons/pi";
import { FaRupeeSign } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { GiFamilyTree } from "react-icons/gi";
import { ScaleLoader } from "react-spinners";
import SuccessPopUp from "../../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../../PopUpMsg/ErrorPopUp";
import { useSelector, useDispatch } from "react-redux";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import {
  Button,
  FormControlLabel,
  TextField,Autocomplete,
  Checkbox,
  IconButton,
  FormControl,Dialog , DialogActions,DialogContent,DialogTitle,
  FormHelperText,
  InputLabel,
  Select,
  Chip,
  MenuItem,
} from "@mui/material";
import { MdModeEdit, MdPeopleAlt } from "react-icons/md";
import { FaWarehouse } from "react-icons/fa6";
import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../WMStheme";
import { SKUOutlined } from "@mui/icons-material";
const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const PersonalDetails = ({
  SKUData,
  setSKUData,
  setSkuNameError,
  setSkuCodeError,
  skuCodeError,
  skuNameError,
  familyData,
  uomError,
  setUomError,
  avgPurchaseError,
  setAvgPurchaseError,
  lastPurchaseError,
  setLastPurchaseError,
 statusError,
 uomTypeOptions,
 setUOMTypeOptions,
 setStatusError,
action,
  setFamilyData,
  familyNameError,
  setFamilyNameError,
}) => {
  // const handleCheckboxChange = (event) => {

  //   setIsProductChecked(event.target.checked);
  // };
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  useEffect (()=>{
    fetchUOMTypeOptions();
  },[]);
  const fetchUOMTypeOptions = async () => {
      try {
        const response = await axiosInstance.get(
          API_ENDPOINTS.GET_CONFIGURATION_MODULE_SKU_UOMTYPE,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response?.data;
        const options = data?.Data?.map((row) => row?.dropdown_value);
        setUOMTypeOptions(options);
      } catch (error) {
        
        console.log("Error", error);
        // Handle error
      }
    };
  
  
  
  const handleChange = (field, value) => {
    if (field === "sku_name") {
      setSkuNameError(value.trim() === "");
    }

    // Validate SKU code
    if (field === "sku_code") {
      setSkuCodeError(value.trim() === "");
    }
    if (field === "last_purchase_price") {
      value = parseInt(value, 10); // 10 is the base for the conversion
    }
    if (field === "avg_puchase_price") {
      value = parseInt(value, 10); // 10 is the base for the conversion
    }
    
    if (field.includes(".")) {
      const [parentField, nestedField] = field.split(".");
      setSKUData((prevData) => ({
        ...prevData,
        [parentField]: {
          ...prevData[parentField],
          [nestedField]: value,
        },
      }));
    } else {
      setSKUData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handleCheckboxChange = (field, value) => {
    handleChange(field, value);
  };

  return (
    <>
      {/* <Typography variant="h6">Personal Details</Typography> */}

      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "2.9%" }}
      >
        <Grid item xs={12} sm={3.8}>
          <TextField
            label="SKU Name"
            size="small"
            fullWidth
            required
            value={SKUData?.sku_name}
            onChange={(e) => handleChange("sku_name", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 65, // Set maximum length to 60 characters
              },
            }}
            error={skuNameError}
            helperText={
              skuNameError
                ? "SKU Name is required"
                : SKUData?.sku_name.length > 60
                ? "Cannot enter more than 60 characters"
                : ""
            }
            
          />
        </Grid>

        <Grid item xs={12} sm={3.8}>
          <TextField
            label="SKU Code"
            size="small"
            fullWidth
            required
            value={SKUData?.sku_code}
            onChange={(e) => handleChange("sku_code", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
            }}
            error={skuCodeError}
            helperText={skuCodeError ? "SKU Code is required" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={3.8}>
        <FormControl fullWidth>
          <InputLabel>Family</InputLabel>
          <Select
            label="Family"
            fullWidth
            
            size="small"
            displayEmpty
            value={SKUData?.family_id} // Update with the appropriate field name
            onChange={(e) => handleChange("family_id", e.target.value)}
            inputProps={{ "aria-label": "Type" }}
            startAdornment={
              <InputAdornment position="start">
                <GiFamilyTree />
              </InputAdornment>
            }
          >
            {familyData?.map((familyOption) => (
              <MenuItem key={familyOption?.id} value={familyOption?.id}>
                {familyOption?.family_name}
              </MenuItem>
            ))}
            {/* error={familyNameError}
            helperText={familyNameError ? "Select Family is required" : ""} */}
          </Select>
        </FormControl>
      </Grid>
      </Grid>
      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.0%", marginLeft: "2.9%" }}
      >
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "0%" }}>
          <TextField
            label="Avg Purchase Price"
            name="Avg Purchase Price"
            size="small"
            value={SKUData?.avg_puchase_price}
            onChange={(e) => handleChange("avg_puchase_price", e.target.value)}
            fullWidth
            required
            type="number"
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              step: "any",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <FaRupeeSign />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={avgPurchaseError}
            helperText={avgPurchaseError ? "Avg Purchase Price is required" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "1.7%" }}>
          <FormControl fullWidth>
            <InputLabel>UOM</InputLabel>
            <Select
              label="UOM"
              fullWidth
              required
              size="small"
              displayEmpty
              value={SKUData?.uom}
              onChange={(e) => handleChange("uom", e.target.value)}
              inputProps={{ "aria-label": "Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <PiUniteSquare />
                </InputAdornment>
              }
              // error={uomError}
              // helperText={uomError ? "UOM is required" : ""}
            
            >
              <MenuItem value="" disabled>
                Select UOM
              </MenuItem>
              {uomTypeOptions?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
            </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={3.8}>
        <TextField
          label="MRP"
          labelId="MRP"
          name="mrp"
          value={SKUData?.mrp}
          onChange={(e) => handleChange('mrp', e.target.value)}
          fullWidth
          size="small"
          margin="normal"
          inputProps={{
            style: { width: "100%" },
            min: 0,
            max: 120,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="start" size="small" disabled>
                <FaRupeeSign />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
       */}
        <Grid item xs={12} sm={3.8}>
          <TextField
            label="Last Purchase Price"
            labelId="Last Purchase Price"
            name="last_purchase_price"
            value={SKUData?.last_purchase_price}
            onChange={(e) =>
              handleChange("last_purchase_price", e.target.value)
            }
            fullWidth
            required
            type="number"
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <GiMoneyStack />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={lastPurchaseError}
            helperText={lastPurchaseError? "Last Purchase Price  is required" : ""}
          
           
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "2.9%" }}
      >
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "1.7%" }}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              fullWidth
              required
              size="small"
              displayEmpty
              value={SKUData?.status ? true : false}
              onChange={(e) => handleChange("status", e.target.value)}
              inputProps={{ "aria-label": "Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <ToggleOffIcon />
                </InputAdornment>
              }
              // error={statusError}
              // helperText={statusError ? "Status  is required" : ""}
            
            >
              <MenuItem value="" disabled>
                Select Status
              </MenuItem>
              <MenuItem value={true}>Active</MenuItem>
    <MenuItem value={false}>In-Active</MenuItem>
             
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3.8}>
          <TextField
            label="Tax(in %)"
            labelId="Tax (in %)"
            name="tax"
            value={SKUData?.tax}
            onChange={(e) => handleChange("tax", e.target.value)}
            fullWidth
            type='number'
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              maxLength: 5,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <GiMoneyStack />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3.8}>
          <TextField
            label="HSN Code"
            labelId="HSN Code"
            name="hsn"
            value={SKUData?.hsn}
            onChange={(e) => handleChange("hsn", e.target.value)}
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <GiMoneyStack />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            sm={3.8}
            sx={{ marginLeft: "1.9%", marginTop: "1.7%" }}
          >
            <FormControl fullWidth>
              <InputLabel>Do u Want Archive this SKU?</InputLabel>
              <Select
                label="Do u Want Archive this SKU?"
                fullWidth
                required
                size="small"
                displayEmpty
                disabled
                value={SKUData?.archive ? true : false}
                onChange={(e) => handleChange("archive", e.target.value)}
                inputProps={{ "aria-label": "Type" }}
                startAdornment={
                  <InputAdornment position="start">
                    <ToggleOffIcon />
                  </InputAdornment>
                }
              >
                <MenuItem value="" disabled>
                  Select Option
                </MenuItem>

                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
         
  <Grid item xs={12} sm={3.8} md={3.8} sx={{ marginTop: "2%" }}>
    <FormControlLabel
      control={
        <Checkbox
          checked={SKUData?.is_product}
          onChange={(e) =>
            handleCheckboxChange("is_product", e.target.checked)
          }
        />
      }
      label="Do you want this SKU considered as a product?"
    />
  </Grid>


        </Grid>
      </Grid>

      <Grid
        container
        spacing={3.5}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};
export const Mapping = ({
  warehouseData,
  SKUData,
  setFilteredWarehouse,
  filteredWarehouse,
  setSKUData,
  warehouseError,
  setWarehouseError,
  warehouseLength,
  setWarehouseLength
}) => {
  const theme = useTheme();
 const [selectedWarehouses, setSelectedWarehouses] = useState([]);
 
const handleChange = (event) => {
  const {
    target: { value },
  } = event;

  setWarehouseLength(value);

  const warehouses = Array.isArray(warehouseData) ? warehouseData : [warehouseData];

  // Update selectedWarehouses with selected warehouses
  const selectedWarehousesWithFlag = warehouses
    .filter((warehouse) => value.includes(warehouse?.wh_name))
    .map((selectedWarehouse) => ({
      id: selectedWarehouse?.id,
      warehouse_id: selectedWarehouse?.id,
      wh_name: selectedWarehouse?.wh_name,
      flag: true,
    }));

  // Set selectedWarehouses state
  setSelectedWarehouses(selectedWarehousesWithFlag);

  // Update the filteredWarehouses state (if needed)
  setFilteredWarehouse(selectedWarehousesWithFlag);

  // Create a copy of the original warehouses and update values
  const updatedWarehouses = warehouses?.map((warehouse) => {
    const selectedWarehouse = selectedWarehousesWithFlag?.find(
      (selectedWarehouse) => selectedWarehouse?.warehouse_id === warehouse.id
    );

    return selectedWarehouse
      ? {
          id: warehouse.id,
          warehouse_id: warehouse.id,
          wh_name: warehouse.wh_name,
          flag: selectedWarehouse.flag,
        }
      : { warehouse_id: warehouse.id, wh_name: warehouse.wh_name, flag: false };
  });

  console.log("updatedWarehouses", updatedWarehouses);
  setSelectedWarehouses(updatedWarehouses);

  // Combine selectedWarehouses and other warehouses with flag: false
  const combinedWarehouses = [
    ...updatedWarehouses,
    ...warehouses.filter((warehouse) => !value.includes(warehouse?.wh_name)).map((warehouse) => ({
      id: warehouse.id,
      warehouse_id: warehouse.id,
      wh_name: warehouse.wh_name,
      flag: false,
    })),
  ];

  setSKUData((prevData) => ({
    ...prevData,
    warehouse: combinedWarehouses || [],
  }));

  setWarehouseError(selectedWarehousesWithFlag?.length === 0);
};


  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "0.6%", marginLeft: "2.1%", marginRight: "10%" }}
      >
        <Typography>Link to WareHouse</Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%", marginRight: "10%" }}
      >
        <FormControl sx={{ m: 1, width: "95%" }}>
          <InputLabel id="warehouse-chip-label">Link to Warehouses</InputLabel>
          <Select
            labelId="warehouse-chip-label"
            id="warehouse-chip"
            multiple
            value={(filteredWarehouse || [])?.map((wh) => wh.wh_name)}
            onChange={handleChange}
            input={
              <OutlinedInput
                id="select-warehouse-chip"
                label="Select Warehouses"
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
            error={warehouseError}
          >
            {Array.isArray(warehouseData) &&
              warehouseData?.map((warehouse) => (
                <MenuItem key={warehouse?.id} value={warehouse?.wh_name}>
                  {/* <Checkbox
                    checked={(SKUData?.warehouse || [])
                      .map((wh) => wh.wh_name)
                      .includes(warehouse.wh_name)}
                  /> */}
                  {warehouse?.wh_name}
                </MenuItem>
              ))}
          </Select>
          {warehouseError && (
            <FormHelperText error>
              Please select at least one warehouse.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid
        container
        spacing={3.5}
        sx={{ marginTop: "3.6%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const SKUConfig = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [SKUData, setSKUData] = useState({
    sku_name: "", // Add other fields as needed
    sku_code: "",
    avg_puchase_price: 0,
    last_puchase_price: 0,
    uom: "",
    mrp: 0,
    tax: 0,
    hsn: "",
    archive: false,
    family_id: "", // Set your default family_id or fetch it from somewhere
    status: true,
    is_product: false,
    product_code: "",
    warehouse: [],
  });
  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };
  const dispatch = useDispatch();
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [SKUId, setSKUId] = useState();
  const [message, setMessage] = useState("");
  const colors = tokens(theme.palette.mode);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [familyData, setFamilyData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [is_product, setIsProductChecked] = useState(true);
  const [warehouseData, setWarehouseData] = useState([]);
  const [skuId, setSkuId] = useState("");
  const [skuNameError, setSkuNameError] = useState(false);
  const [skuCodeError, setSkuCodeError] = useState(false);
  const [familyNameError, setFamilyNameError] = useState(false);
  const [avgPurchaseError ,setAvgPurchaseError]=useState(false);
  const [uomError ,setUomError] =useState(false);
  const [lastPurchaseError,setLastPurchaseError] =useState(false);
  const [statusError,setStatusError] =useState(false);
  const [activeNextButton, setActiveNextButton] = useState(false);
  const [warehousedetail, setWarehouseDetail] = useState([]);
  const [currentSection, setCurrentSection] = useState("SKU Details");
  const [warehouseError, setWarehouseError] = useState(false);
  const [warehouseLength ,setWarehouseLength] = useState('');
  const [skuLoading, setSkuLoading] = useState(false);
  const [skuActiveValidationError , setSkuActiveValidationError] =useState([]);
  const [errorDialogOpen , setErrorDialogOpen] =useState(false);
  const [inverrorDialogOpen , setInvErrorDialogOpen] =useState(false);
  const [allocateerrorDialogOpen , setAllocateErrorDialogOpen] =useState(false);
  const [allocateerrorDialogOpenMsg , setAllocateErrorDialogOpenMsg] =useState("");
  const [poerrorDialogOpen , setPoErrorDialogOpen] =useState(false);
  const [poerrorDialogOpenMsg , setPoErrorDialogOpenMsg] =useState("");
  const [warehouseerrorDialogOpenMsg , setWarehouseErrorDialogOpenMsg] =useState("");
  
  const [warehouseInverrorDialogOpen , setWarehouseInvErrorDialogOpen] =useState(false);
  
  const [isProductAvl,setIsProductAvl] =useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
const [filteredWarehouse,setFilteredWarehouse] =useState([]);
  const [alertIsProductDialog, setAlertIsProductDialog] =useState(false);
  const [uomTypeOptions, setUOMTypeOptions] = useState([]);
  const sections = [
    { name: "SKU Details", icon: <MdModeEdit /> },
    { name: "Connect Warehouse", icon: <FaWarehouse /> },
  ];
  const { action, skuData } = useSelector((state) => state.sku);
  console.log("SKU data ", skuData);
  console.log("action", action);
const ProductColumn = [
  { id: "product_name", label: "Product Name" },
  { id: "product_code", label: "Product Code" },
  
];
const InvColumn = [
  { id: "lot_no", label: "Lot Number" },
  { id: "avl_qty", label: "Available Qty" },
  
  { id: "created_at", label: "Created At" },
  
 
  
];

const AllocateOrderColumn = [
  { id: "order_no", label: "Order Number" },
  { id: "item_name", label: "Item Name" },
  
  { id: "item_code", label: "Item Code" },
  
 
  
];
const PoOrderColumn = [
  { id: "int_po_no", label: "PO Number" },
  { id: "sku_name", label: "Sku Name" },
  
  { id: "sku_code", label: "Sku Code" },
  
 
  
];
const WarehouseColumn = [
  { id: "lot_no", label: "Lot Number" },
  { id: "avl_qty", label: "Available Qty" },
  
  { id: "created_at", label: "Created At" },
  
 
  
];
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the product data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []);
  useEffect(() => {
    fetchFamilyData();
  }, []);
  const fetchFamilyData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_FAMILY_DATA}`,
        {
          params: {
            limit: 1000,
            offset: 1,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = response.data?.data?.family_list;
        setFamilyData(data);
        console.log("family Data, ", data); // Assuming the response is an array of family options
      } else {
        console.log("Error fetching family data");
      }
    } catch (error) {
      console.error("Error fetching family data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if SKUData is not null or empty

    switch (action) {
      case "edit":
        if (skuData) {
          // If editing, prefill the form with the SKUData
          console.log(" in edit form:", skuData);
          console.log("Edit action performed");
          // Initialize SKUData with all fields from the JSON structure
          console.log("sku id:", skuData?.id);
          setSKUId(skuData?.id);
          updatedSKUMasterData(SKUId);
        }
        // Update only the fields present in SKUData
        break;

      case "add":
        // If adding, clear the form data
        console.log("Add action performed");
        setSKUData({
          sku_name: "", // Add other fields as needed
          sku_code: "",
          avg_puchase_price: 0,
          last_puchase_price: 0,
          uom: "",
          archive: false,
          mrp: 0,
          tax: 0,
          hsn: "",
          family_id: "", // Set your default family_id or fetch it from somewhere
          status: true,
          product_code: "",
          is_product: false,
          // Set your default status or fetch it from somewhere
          // Empty array since you're adding a new SKU
        });
        
        break;

      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, skuData]);

  useEffect(() => {
    // Update isValid based on the latest state
    const isSkuNameError = SKUData?.sku_name.trim() === "";
    const isSkuCodeError = SKUData?.sku_code.trim() === "";
    // const isFamilyNameError = SKUData?.family_id.trim() === "";
    // const isAvgPurchaseError = SKUData?.avg_puchase_price.toString().trim() === ""; // Convert to string before calling trim
    // const isLastPurchaseError = SKUData?.last_puchase_price.toString().trim() === ""; // Convert to string before calling trim
    // const isUomError = SKUData?.uom.trim() === "";
    // const isStatusError  = SKUData?.status.trim() === "";
    const isValid = !isSkuNameError && !isSkuCodeError;

    setActiveNextButton(!isValid);
  }, [SKUData?.sku_name, SKUData?.sku_code ]);
  const handleNext = () => {
    let isValid = true;

    switch (currentSection) {
      case "SKU Details":
        // Validation logic for SKU Name
        setSkuNameError(SKUData?.sku_name.trim() === "");
        setSkuCodeError(SKUData?.sku_code.trim() === "");
        if (!SKUData.is_product) {
          // If is_product is false, show confirmation dialog
          setShowConfirmationDialog(true);
          isValid = false;

        } 
        //setAvgPurchaseError(SKUData?.avg_puchase_price.toString().trim() === "");
        //setLastPurchaseError(SKUData?.last_puchase_price.toString().trim() === "");
        // setUomError(SKUData?.uom.trim() === "");
        // setStatusError(SKUData?.status.trim() === "");
         console.log("is_product printing " ,SKUData?.is_product);
        //  if(!SKUData?.is_product){
        //   console.log("need to display alert for fillling is_product or not ! ");
        //   setAlertIsProductDialog(true);
        //  }
        // Check if there are any errors

        break;

      case "Connect Warehouse":
        // Additional validation logic for the "Connect Warehouse" section
        // ...
        const selectedWarehouses = SKUData?.warehouse || [];
        setWarehouseError(selectedWarehouses?.length === 0);

        // Check if there are any errors
        isValid = !warehouseError;
        break;

      // For example, you can set an error state for a specific field in this section
      // setErrorForSomeField(someFieldValue.trim() === '');
      // isValid = isValid && !errorForSomeField;

      // Add more cases for each section as needed

      default:
        break;
    }

    if (isValid) {
      const currentIndex = sections.findIndex(
        (section) => section.name === currentSection
      );
      if (currentIndex < sections.length - 1) {
        const nextSection = sections[currentIndex + 1].name;
        setCurrentSection(nextSection);
      }
    } else {
      // Display an error message or handle the validation error
      console.error("Fill required fields first");
      setMessage("Fill required fields first");
    }
  };
  const handleConfirmation = (confirm) => {
    setShowConfirmationDialog(false);
    if (confirm) {
      // If user clicks 'Yes' in the confirmation dialog
      setSKUData((prevData) => ({
        ...prevData,
        is_product: true,
      }));
      // ... (your existing handleNext logic)
    } else {
      setShowConfirmationDialog(false);
      const currentIndex = sections.findIndex(
        (section) => section.name === currentSection
      );
      if (currentIndex < sections.length - 1) {
        const nextSection = sections[currentIndex + 1].name;
        setCurrentSection(nextSection);
      }
      // If user clicks 'No' in the confirmation dialog
      // ... (your existing handleNext logic without changing is_product)
    }
   // handleNext();

  };
 const  handleIsProductFalse =()=>{
  console.log("false hereeeeeeee");
 };
 const handleIsProductTrue = () =>{
  console.log("true hereeeeeee");
 };
  const handleSubmit = async () => {
    try {
      if (action === "edit") {
        // If editing, send a PUT request
        await updateSKU();
      } else {
        // If adding, send a POST request
        await createSKU();
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error("Error submitting SKU details:", error);
      setMessage("Error submitting SKU details");
    }
  };
  const updatedSKUMasterData = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_DETAILS_OF_SKU(skuData?.id)}`,
        {
          params: {
            limit: 100,
            offset: 1,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSKUId(skuData?.id);
      if (response.status >= 200 && response.status < 305) {
        const data = response.data?.Data;
        console.log("data in updateskumasterdata ", data);
        setWarehouseDetail(data?.warehouse);

        // Filter warehouses with flag === true
        const filteredWarehouses = data
          ? data?.warehouse?.filter((wh) => wh.flag === true)
          : [];
          setFilteredWarehouse(filteredWarehouses);
        setSKUData((prevData) => ({
          ...prevData,
           ...skuData,
           family_id: data?.family_id || "", // Assuming family_id is a unique identifier for family
  family_name: data?.family_name || "",
          is_product: data?.is_product || false,
          warehouse: filteredWarehouses,
        }));
        console.log("sku data in the ",skuData );
        
        console.log("filtered Warehouses: ", filteredWarehouses);
      } else {
        console.log("Error fetching family data");
      }
    } catch (error) {
      console.error("Error fetching family data:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log("sku data hereeeeeeeeeeeeeeeeee----------> " , skuData);
        
  useEffect(() => {
    // Effect to navigate after success message is set
    if (successPopupOpen) {
      const timer = setTimeout(() => {
        setSuccessPopupOpen(false);
        navigate("/viewSKU");
      }, 900);

      return () => clearTimeout(timer);
    }
  }, [successPopupOpen, navigate]);
  const createSKU = async () => {
    setSkuLoading(true);
    try {
      // Determine the endpoint for creating a new SKU
      const endpoint = `${API_ENDPOINTS.POST_WMS_ADD_SKU_DATA}`;
      console.log("API Endpoint:", endpoint);

      // Log the SKUData before making the API call
      console.log("Creating SKUData:", SKUData);

      // Send the POST request to create a new SKU
      const response = await axiosInstance.post(endpoint, SKUData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data created successfully!");
        setSkuId(response.data?.Data?.sku_id);
        setSuccessPopupOpen(true);
        setSkuLoading(false);
        // Optionally, you can perform additional actions after successful creation
      } else {
        if (response.status === 400) {
          console.error("Bad Request: ", response?.data?.msg);
          setErrorMessage(response?.data?.msg);
          setErrorPopupOpen(true);
        
          setSkuLoading(false);
        }
        
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response?.data?.msg);
        setErrorPopupOpen(true);
        setSkuLoading(false);
      }
      else if (error.response && error.response.status >= 500 && error.response.status < 600) {
        console.error("Server Error: ", error.response?.data?.msg);
        setErrorMessage("Server Error. Please try again later.");
        setErrorPopupOpen(true);
        setSkuLoading(false);
      } else {
        setErrorMessage(error.response?.data?.msg);
        setErrorPopupOpen(true);
      setSkuLoading(false);
      console.error("Error occurred while updating the SKU data:", error);
    } 
    }
  };
  const updateSKU = async () => {
    try {
      if (!skuData?.id) {
        setErrorMessage("Please try Again");
        return;
      }

      setSKUData((prevData) => ({
        ...prevData,
      }));
      const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_SKU_DATA(skuData?.id)}`;
      console.log("API Endpoint:", endpoint);

      // Log the SKUData before making the API call
      console.log("Updating SKUData:", SKUData);
      setSkuLoading(true);
      const response = await axiosInstance.put(endpoint, SKUData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSkuLoading(false);
        setSuccessMessage("Data updated Successfully!");

        setSuccessPopupOpen(true);

        navigate("/viewSKU");
      } else {
        if (response.status === 400) {
          console.log("try status 400 if statemnet");
          // console.error("Bad Request: ", response?.data?.msg);
          setSkuActiveValidationError(response?.data?.data?.error_data);
          setErrorDialogOpen(true);
          
          setSkuLoading(false);
        }
        
      }
    } catch (error) {
     
      if (error.response && error.response.status === 400) {
        console.log("catch 400 if statemnet");
        console.log("response data",error.response?.data?.data?.error_data);
        switch (error.response?.data?.data?.type) {
          case "unlink_sku": {
            setSkuActiveValidationError(error.response?.data?.data?.error_data);
            setErrorDialogOpen(true);
            break;
          }
          case "inv_remove": {
            setSkuActiveValidationError(error.response?.data?.data?.error_data);
            setInvErrorDialogOpen(true);
            break;
          }
          case "allocate_order": {
            setSkuActiveValidationError(error.response?.data?.data?.error_data);
 setAllocateErrorDialogOpenMsg(error.response?.data?.msg);

            setAllocateErrorDialogOpen(true);
            break;
          }
          case "po_order": {
            setSkuActiveValidationError(error.response?.data?.data?.error_data);
            setPoErrorDialogOpenMsg(error.response?.data?.msg);
            setPoErrorDialogOpen(true);
            break;
          }
          case "warehouse_inv": {
            setSkuActiveValidationError(error.response?.data?.data?.error_data);
            setWarehouseErrorDialogOpenMsg(error.response?.data?.msg);
            setWarehouseInvErrorDialogOpen(true);
            break;
          }
          // Add a default case to handle unexpected values
          default: {
            setErrorMessage(error.response?.data?.msg);
            setErrorPopupOpen(true);
            break;
          }
        }
        

        setSkuLoading(false);
        
      }
      else if (error.response && error.response.status >= 500 && error.response.status < 600) {
        console.error("Server Error: ", error.response?.data?.msg);
        setErrorMessage("Server Error. Please try again later.");
        setErrorPopupOpen(true);
        setSkuLoading(false);
      } else {
        setErrorMessage(error.response?.data?.msg);
        setErrorPopupOpen(true);
      setSkuLoading(false);
      console.error("Error occurred while updating the SKU data:", error);
    } }
  };
  const handlePrevious = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex > 0) {
      const previousSection = sections[currentIndex - 1].name;
      setCurrentSection(previousSection);
    }
  };
  function handleBack() {
    if (action === "add") {
      navigate("/configuration");
    } else {
      
     
      navigate("/viewSKU");
    }
    
  }
  const isLastSection = currentSection === sections[sections.length - 1].name;
  //Personal Details States

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
          // backgroundColor:'pink'
          // backgroundColor: "rgb(44 123 85)",
        }}
      >
        <Typography
          variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%" }}
        >
          <MdPeopleAlt
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp; SKU
        </Typography>
        {/* <InventoryRoundedIcon sx={{ fontSize: 32, marginLeft: "1px" }} />
          </Typography> */}

        <Stack spacing={2} direction="row">
          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
      {skuLoading ? (
        <>
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={skuLoading} />
          </div>
        </>
      ) : (
        <Grid container spacing={3} sx={{ marginTop: "1.7%" }}>
          {/* Left Section (30%) */}
          <Grid item xs={12} sm={3}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            >
              {sections?.map((section) => (
                <Typography
                  key={section?.name}
                  onClick={() => setCurrentSection(section.name)}
                  style={{
                    cursor: "pointer",
                    padding: "8px",

                    background:
                      currentSection === section.name
                        ? "#34519F5D"
                        : "transparent",
                    color: currentSection === section.name ? "#000" : "inherit",
                    borderRadius:
                      currentSection === section.name ? "7px" : "inherit",
                    // padding:currentSection === section.name ? '5px' : 'inherit',
                  }}
                >
                  {section?.icon}
                  &nbsp;&nbsp;
                  {section?.name}
                </Typography>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              maxHeight="100%"
              height="100%"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
              position="relative"
            >
              {/* Dynamically render the current section */}
              {currentSection === "SKU Details" && (
                <PersonalDetails
                  SKUData={SKUData}
                  setSKUData={setSKUData}
                  skuCodeError={skuCodeError}
                  skuNameError={skuNameError}
                  familyNameError={familyNameError}
                  familyData={familyData}
                  setSkuCodeError={setSkuCodeError}
                  setSkuNameError={setSkuNameError}
                  setFamilyData={setFamilyData}
                  setFamilyNameError={setFamilyNameError}
                  uomError={uomError}
                  setUomError={setUomError}
                  statusError={statusError}
                  uomTypeOptions={uomTypeOptions}
                  setUOMTypeOptions={setUOMTypeOptions}
                  setStatusError={setStatusError}
                  avgPurchaseError={avgPurchaseError}
                  setAvgPurchaseError={setAvgPurchaseError}
                  lastPurchaseError={lastPurchaseError}
                  setLastPurchaseError={setLastPurchaseError}
                  action={action}

                />
              )}
              {currentSection === "Connect Warehouse" && (
                <Mapping
                  warehouseData={warehouseData}
                  SKUData={SKUData}
                  setSKUData={setSKUData}
                  warehouseError={warehouseError}
                  setWarehouseError={setWarehouseError}
                  warehouseLength={warehouseLength}
                  setFilteredWarehouse={setFilteredWarehouse}
                  filteredWarehouse={filteredWarehouse}
                  setWarehouseLength={setWarehouseLength}
                />
              )}

              <Box
                position="absolute"
                bottom="16px"
                right="20px"
                display="flex"
                gap={2}
              >
                {currentSection !== sections[0].name && (
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: colors.green.DEFAULT,
                    }}
                    onClick={handlePrevious}
                  >
                    <Typography variant="h7" color={colors.green.DEFAULT}>
                      Previous
                    </Typography>
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                  }}
                  onClick={isLastSection ? handleSubmit : handleNext}
                  disabled={
                    activeNextButton || 
                    (action === "add" && currentSection === "Connect Warehouse" && warehouseLength?.length === 0)
                    
                  }
                  // Add this line to disable the button if isValid is false
                >
                  <Typography variant="h7" color={colors.white.DEFAULT}>
                    {isLastSection ? "Submit" : "Next"}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <Dialog open={showConfirmationDialog} size="md" onClose={() => handleConfirmation(false)}>
        <DialogTitle sx={{ textAlign: 'center',fontSize: '1.2rem' , color:colors.green.DEFAULT}}> Link SKU as Product ! </DialogTitle>
        <DialogContent>
        <Typography sx={{ fontSize: '1.0rem'  }}>
          Do you want to consider this SKU as a product?
         
          </Typography>
          <Typography sx={{ fontSize: '0.8rem' }}>
          
          Click 'Yes' to mark as a product, 'No' to continue.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmation(true)} variant="contained" sx={{
            borderColor: colors.green.DEFAULT,
            backgroundColor: colors.green.DEFAULT,
          }}>
            Yes
          </Button>
          <Button onClick={() => handleConfirmation(false)} variant="contained" 
          sx={{
            borderColor: colors.green.DEFAULT,
            backgroundColor: colors.green.DEFAULT,
          }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
      <AlertDialog
        open={alertIsProductDialog}
        onClose= {handleIsProductFalse}
        title="Do You Want to ?"
        content="Selected SKUs (if any) will be added"
        discardText="No"
        okayText="Yes"
        handleDiscardClick={handleIsProductFalse}
        handleOkayClick={handleIsProductTrue}
      />
      <ErrorDialogBox
      open = {errorDialogOpen}
      onClose ={() => setErrorDialogOpen(false)}
      title= "You are attempting to deactivate this SKU. However, please unlink this SKU from the following products and then try again to change the status:"
      data = {skuActiveValidationError}
      okayText="Yes"
      columns={ProductColumn}
     
       handleOkayClick={()=> setErrorDialogOpen(false)}
      />
      <ErrorDialogBox
      open = {inverrorDialogOpen}
      onClose ={() => setInvErrorDialogOpen(false)}
      title= "You are attempting to deactivate this SKU. However, Please remove the inventory of this sku first to inactive this sku ."
      data = {skuActiveValidationError}
      okayText="Yes"
      columns={InvColumn}
     
       handleOkayClick={()=> setInvErrorDialogOpen(false)}
      />
      <ErrorDialogBox
      open = {allocateerrorDialogOpen}
      onClose ={() => setAllocateErrorDialogOpen(false)}
      title= "You are attempting to deactivate this SKU ."
      msg={allocateerrorDialogOpenMsg}
      data = {skuActiveValidationError}
      okayText="Yes"
      columns={AllocateOrderColumn}
     
       handleOkayClick={()=> setAllocateErrorDialogOpen(false)}
      />
      <ErrorDialogBox
      open = {poerrorDialogOpen}
      onClose ={() => setPoErrorDialogOpen(false)}
      title= "You are attempting to deactivate this SKU "
      msg= {poerrorDialogOpenMsg}
      data = {skuActiveValidationError}
      okayText="Yes"
      columns={PoOrderColumn}
     
       handleOkayClick={()=> setPoErrorDialogOpen(false)}
      />


      <ErrorDialogBox
      open = {warehouseInverrorDialogOpen}
      onClose ={() => setWarehouseInvErrorDialogOpen(false)}
      title= "You are attempting to deactivate this SKU "
      msg= {warehouseerrorDialogOpenMsg}

      data = {skuActiveValidationError}
      okayText="Yes"
      columns={WarehouseColumn}
     
       handleOkayClick={()=> setWarehouseInvErrorDialogOpen(false)}
      />

      

    </>
  );
};

export default SKUConfig;
