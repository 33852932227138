import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
  InputAdornment,
} from "@mui/material";
import { tokens } from "../../../theme";
import SaveAlert from "../../../SaveAlert";
import ApiErrorAlert from "../../../ApiErrorAlert";

import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const AreaOccupied = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const typography = tokens(theme.palette.mode.typography);
  // const [selectedFarm, setSelectedFarm] = useState(farmOptions[0]);
  const [farmOptions, setFarmOptions] = useState([]);
  const [farmData, setFarmData] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState("");
  const [cropData, setCropData] = useState([]);

  const [totalCapacity, setTotalCapacity] = useState(0);
  const [spaceOccupied, setSpaceOccupied] = useState(0);

  useEffect(() => {
    if (farmOptions?.length > 0) {
      setSelectedFarm(farmOptions[0]);
    }
    // console.log("FARM OPTIONSS Area", farmOptions);
    // console.log(selectedFarm);
  }, [farmOptions]);

  const handleFarmChange = (event) => {
    const selectedFarm = event.target.value;
    setSelectedFarm(selectedFarm);
    updateFarmData(selectedFarm);
  };

  const updateFarmData = (selectedFarm) => {
    const selectedFarmData = cropData.find(
      (crop) => crop?.farm_name === selectedFarm
    );
    if (selectedFarmData) {
      const totalCapacitySum = selectedFarmData?.detail?.total_capacity;
      const spaceOccupiedSum = selectedFarmData?.detail?.crops.reduce(
        (sum, crop) => sum + crop?.no_of_plants,
        0
      );
      setTotalCapacity(totalCapacitySum);
      setSpaceOccupied(spaceOccupiedSum);
    } else {
      setTotalCapacity(0);
      setSpaceOccupied(0);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      // console.log("klientt id", client_id);
      // console.log(
      //   "FARM OPTIONSS Area",
      //   data?.farmdetail?.rows?.map((row) => row?.farm_name).flat()
      // );
      const fetchedFarmOptions = data?.farmdetail?.rows?.map(
        (row) => row?.farm_name
      );
      const fetchedCropData = data?.capacitydetail?.rows;
      // const defaultFarm = fetchedFarmOptions[0];
      setFarmOptions(
        data?.farmdetail?.rows?.map((row) => row?.farm_name).flat()
      );
      setFarmData(data?.farmdetail?.rows[0]?.farm_name);
      // console.log(farmData, ";;;;;;");
      // setFarmOptions(fetchedFarmOptions);
      setCropData(fetchedCropData);
      // setSelectedFarm(defaultFarm);

      setSelectedFarm(fetchedFarmOptions[0]);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching data:", error);
    }
  };
  // console.log(farmData[0], ";;;;jkhjakbdj;;");
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedFarm) {
      updateFarmData(selectedFarm);
    }
  }, [selectedFarm]);

  const getCropsData = () => {
    const selectedFarmData = cropData?.find(
      (crop) => crop?.farm_name === selectedFarm
    );
    return selectedFarmData ? selectedFarmData?.detail?.crops : [];
  };

  const generateTreemapData = () => {
    const cropsData = getCropsData();
    if (cropsData?.length === 0) {
      return [];
    }
    const treemapData = cropsData?.map((crop) => ({
      x: crop?.crop_name,
      y: crop?.no_of_plants,
    }));
    return treemapData;
  };

  const options = {
    series: [
      {
        data: generateTreemapData(),
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: "#FF0000",
            },
            {
              from: 100,
              to: 200,
              color: "#00FF00",
            },
          ],
        },
      },
    ],
    legend: {
      show: false,
    },
    chart: {
      height: 350,
      type: "treemap",
      toolbar: {
        show: false, // Hide the entire toolbar
      },
    },
    colors: [
      "#22c55e",
      "#16a34a",
      "#15803d",
      "#166534",
      "#047857",
      "#14532d",
      "#065f46",
      "#022c22",
      "#10b981",
      "#22c55e",
    ],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
  };

  // console.log("FARMMM OPTIONSSSSSSSS Areaaaaa", farmOptions);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",

            marginTop: "-0.1%",
            marginLeft: "28px",
          }}
        >
          <div>
            <Typography
              variant="h2"
              color={colors.black.DEFAULT}
              fontFamily="Lexend Deca"
              sx={{ marginTop: "4px" }}
            >
              {totalCapacity} units
            </Typography>

            <Typography
              variant="h6"
              color={colors.black[200]}
              fontFamily="Lexend Deca"
              sx={{ marginTop: "4px" }}
            >
              Total Capacity
            </Typography>
          </div>
          <div>
            <Typography
              variant="h2"
              color={colors.black.DEFAULT}
              fontFamily="Lexend Deca"
              sx={{ marginTop: "4px", marginLeft: "50px" }}
            >
              {spaceOccupied} units
            </Typography>

            <Typography
              variant="h6"
              color={colors.black[200]}
              fontFamily="Lexend Deca"
              sx={{ marginTop: "4px", marginLeft: "50px" }}
            >
              Space Occupied
            </Typography>
          </div>
          <div>
            <Typography
              variant="h2"
              color={colors.black.DEFAULT}
              fontFamily="Lexend Deca"
              sx={{ marginTop: "4px", marginLeft: "50px" }}
            >
              {totalCapacity - spaceOccupied} units
            </Typography>

            <Typography
              variant="h6"
              color={colors.black[200]}
              fontFamily="Lexend Deca"
              sx={{ marginTop: "4px", marginLeft: "50px" }}
            >
              Empty Space
            </Typography>
          </div>
        </div>

        <div
          style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
        >
          <FormControl fullWidth>
            <InputLabel>Select Farm</InputLabel>
            <Select
              id="Select Farm"
              label="Select Farm"
              labelId="Select Farm"
              name="Select Farm"
              size="small"
              value={selectedFarm}
              onChange={handleFarmChange}
              style={{ width: "150px" }}
              displayEmpty
              inputProps={{ "aria-label": "Select Farm" }}
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
            >
              {farmOptions &&
                farmOptions?.map((farm) => (
                  <MenuItem key={farm} value={farm}>
                    {farm}
                  </MenuItem>
                ))}
              <MenuItem value="" disabled>
                Select Farm
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div style={{ marginLeft: "25px" }}>
        <ReactApexChart
          options={options}
          series={options.series}
          type="treemap"
          height={240}
        />
      </div>

      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};

export default AreaOccupied;
