import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MailIcon from "@mui/icons-material/Mail";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import { FaWarehouse } from "react-icons/fa6";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { useSelector, useDispatch } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AWS from "aws-sdk";
import {
  Button,
  Chip,
  OutlinedInput,
  TextField,
  Stack,
  Checkbox,
  Autocomplete,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// import { useLocation } from '@reach/router';
import { HiCurrencyRupee } from "react-icons/hi2";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  MdModeEdit,
  MdPeopleAlt,
  MdLocationOn,
  MdDescription,
} from "react-icons/md";
import axios from "axios";

import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../WMStheme";
import AddIcon from "@mui/icons-material/Add";
import { ClipLoader } from "react-spinners";

const ITEM_HEIGHT = 48;
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "ap-south-1",
});
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};
const s3 = new AWS.S3();
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  zIndex: 9999,

  // backgroundColor: "red",
};
// Separate components for each section
const PersonalDetails = ({
  customerDataSet,
  setCustomerDataSet,
  customerTypeOptions,
  setCustomerTypeOptions,
}) => {
  useEffect(() => {
    fetchCustomerTypeOptions();
  }, []);
  const fetchCustomerTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_MODULE_CUSTOMER_CUSTOMERTYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.Data?.map((row) => row?.dropdown_value);
      setCustomerTypeOptions(options);
    } catch (error) {
      console.log("Error", error);
      // Handle error
    }
  };
  const handleChange = (field, value) => {
    const updatedValue = field === "status" ? value === true : value;

    setCustomerDataSet((prevData) => ({
      ...prevData,
      [field]: updatedValue,
    }));
  };
  return (
    <>
      {/* <Typography variant="h6">Personal Details</Typography> */}

      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Customer Name"
            size="small"
            fullWidth
            required
            value={customerDataSet?.name}
            onChange={(e) => handleChange("name", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 65, // Set maximum length to 60 characters
              },
            }}
            helperText={
              customerDataSet?.name && customerDataSet?.name.length > 60
                ? "Cannot enter more than 65 characters"
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Customer Code"
            size="small"
            fullWidth
            required
            type="number"
            value={customerDataSet?.code}
            onChange={(e) => handleChange("code", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 20,
              minLength: 3,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              fullWidth
              required
              size="small"
              value={customerDataSet?.customer_type}
              onChange={(e) => handleChange("customer_type", e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <SupervisedUserCircleIcon />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Select Type
              </MenuItem>

              {customerTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Email"
            name="email"
            type="email"
            size="small"
            value={customerDataSet?.email}
            onChange={(e) => handleChange("email", e.target.value)}
            // value={newUserRoleState?.new_email}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <MailIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Mobile No."
            //   value={newUserRoleState?.new_mobile}
            size="small"
            //   onChange={(event) => {
            //     const { value } = event.target;
            //     // Validate and update the new_mobile state only if it's a valid number
            //     if (
            //       value === "" ||
            //       /^[0-9]*$/.test(value) ||
            //       value?.match(/^[\+0-9]+$/)
            //     ) {
            //       setNewUserRoleState((prevValues) => ({
            //         ...prevValues,
            //         new_mobile: value,
            //       }));
            //     }
            //   }}
            fullWidth
            type="tel"
            required
            margin="normal"
            variant="outlined"
            value={customerDataSet?.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
            inputProps={{
              maxLength: 10,
              inputMode: "numeric", // Shows the numeric keyboard on mobile devices
              pattern: "[0-9]*", // Only allows numeric input (additional validation)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <PhoneIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ marginTop: "1.7%" }}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              fullWidth
              required
              size="small"
              displayEmpty
              value={customerDataSet.status ? true : false}
              onChange={(e) => handleChange("status", e.target.value)}
              inputProps={{ "aria-label": "Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <ToggleOffIcon />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Select Status
              </MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>In-Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Description"
            name="Description"
            type="Description"
            size="small"
            // value={newUserRoleState?.new_email}
            fullWidth
            required
            value={customerDataSet?.description}
            onChange={(e) => handleChange("description", e.target.value)}
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <DescriptionIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {/* for creating spacing */}
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const ShippingAddress = ({
  shippingAddress,
  setShippingAddress,
  customerData,
  action,
}) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateiso, setStateIso] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  // useEffect (()=>{
  //   if ( action && customerData) {

  //     const { country, state , city ,pincode , addressline1, addressline2} = customerData;

  //   //   setShippingAddress(

  //   //     country: country || 0,
  //   // state:  state || 0,
  //   // city:  city || 0,
  //   // pincode:  pincode || 0,
  //   // addressline1 : addressline1 || '',
  //   // addressline2:  addressline2 || '',
  //   //   );
  //   // }
  // },[action , customerData , shippingAddress]);
  useEffect(() => {
    fetchCountryOptions();
  }, []);
  const handleChangeShippingAddress = (field, value) => {
    setShippingAddress((prevData) => ({
      ...prevData,

      [field]: value,
    }));
  };

  console.log("shipping Address", shippingAddress);
  const handlePincodeChange = (event) => {
    const pincode = event.target.value;

    handleChangeShippingAddress("pincode", pincode);
    console.log("pincode111111111111111", pincode);
  };

  const handleAddress1Change = (event) => {
    const address1 = event.target.value;
    handleChangeShippingAddress("addressline1", address1);
  };

  const handleAddress2Change = (event) => {
    const address2 = event.target.value;
    handleChangeShippingAddress("addressline2", address2);
  };
  const handleTimeChange = (event) => {
    const time = event.target.value;
    handleChangeShippingAddress("time", time);
  };
  const fetchCountryOptions = async () => {
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      console.log("SEDRTFGYUHIJOKPL", apiKey);
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      const response = await fetch(
        "https://api.countrystatecity.in/v1/countries",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch countries");
      }

      const data = await response.json();

      setCountryOptions(data);
    } catch (error) {
      setApiErrorOpen(true);
      // Handle error
      console.log("Error fetching countries:", error);
    }
  };
  const fetchStatesForCountry = async (countryCode) => {
    console.log("fetchStatesForCountry", countryCode);
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      console.log("API KeyPOIUYTRSZXCFGBHJKML,:", apiKey);
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      console.log("HEADERSSSSSSSSSSSSSS,:", headers);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      const response = await fetch(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch states");
      }

      const data = await response.json(); // Await for the JSON parsing
      console.log("Statessssss", data);

      setStateOptions(data);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching states:", error);
    }
  };

  const fetchCitiesForCountryAndState = async (countryCode, stateIso) => {
    console.log("countryIso", countryCode);
    console.log("stateIso", stateIso);
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      const url = `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateIso}/cities`;

      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Failed to fetch cities");
      }

      const data = await response.json();
      console.log("citiesss dataaaa", data);
      const cities = data?.map((city) => city?.name);

      setCityOptions(cities);
      console.log("setCityOptions", cities);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching cities:", error);
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    console.log("Selected Country:", selectedCountry);
    // Find the country object based on the selected name
    // const selectedCountry = countryOptions.find(country => country.name === selectedCountryName);

    if (selectedCountry) {
      setSelectedCountryCode(selectedCountry.iso2);
      handleChangeShippingAddress("country", selectedCountry);
      fetchStatesForCountry(selectedCountry);
    } else {
      console.error("Selected country or iso2 is undefined.");
    }
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    if (selectedState) {
      setStateIso(selectedState);
      handleChangeShippingAddress("state", selectedState);
      fetchCitiesForCountryAndState(shippingAddress?.country, selectedState);
    } else {
      console.error("Selected state is undefined.");
    }
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    if (selectedCity) {
      handleChangeShippingAddress("city", selectedCity);
    }
  };
  // Fetch countries when the component mounts

  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={4} sx={{ marginTop: "1.6%" }}>
          <FormControl fullWidth>
            <InputLabel id="Country" style={{ textAlign: "center" }}>
              Select Country
            </InputLabel>
            <Select
              labelId="Select Country"
              id="Select Country"
              label="Select Country"
              // value={customerDataSet?.country}
              value={shippingAddress?.country || ""}
              onChange={(e) => handleCountryChange(e)}
              // value={formData?.country}
              // onChange={handleCountryChange}
              fullWidth
              required
              size="small"
              margin="normal"
              name="Select Country"
              displayEmpty
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              inputProps={{
                style: { width: "100%" },
                "aria-label": "Select Country",
              }}
            >
              {countryOptions?.length === 0 ? (
                <MenuItem disabled>No countries available</MenuItem>
              ) : (
                countryOptions?.map((country) => (
                  <MenuItem key={country.id} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="State"
            value={shippingAddress?.state || ""}
            onChange={(e) => handleStateChange(e)}
            margin="normal"
            name="State"
            size="small"
            InputLabelProps={{
              shrink: true,
              style: { textAlign: "center" },
            }}
            InputProps={{
              style: { width: "100%" },
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="City"
            value={shippingAddress?.city || ""}
            onChange={(e) => handleCityChange(e)}
            margin="normal"
            name="City"
            size="small"
            InputLabelProps={{
              shrink: true,
              style: { textAlign: "center" },
            }}
            InputProps={{
              style: { width: "100%" },
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
          {/* <FormControl fullWidth>
            <InputLabel id="City" style={{ textAlign: "center" }}>
              City
            </InputLabel>
            <Select
              labelId="City"
              id="City"
              label="City"
              required
              value={shippingAddress?.city || ""}
              onChange={(e) => handleCityChange(e)}
              // value={formData?.city}
              // onChange={handleCityChange}
              fullWidth
              margin="normal"
              name="City"
              size="small"
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              inputProps={{
                style: { width: "100%" },
                "aria-label": "City",
              }}
              displayEmpty
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
            >
              {/* {cityOptions?.length === 0 ? (
                <MenuItem disabled>No cities available</MenuItem>
              ) : (
                cityOptions?.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))
              )} */}
          {/* </Select>
          </FormControl> */}
        </Grid>
      </Grid>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Pincode"
            name="pincode"
            // value={formData?.pincode}
            // readOnly
            value={shippingAddress?.pincode}
            onChange={(e) => handlePincodeChange(e)}
            size="small"
            fullWidth
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              maxLength: 6,
              pattern: "^[1-9]{6}$",
              title: "Pincode should be 6 digits long",
              inputMode: "numeric",
            }}
            helperText={"Pincode should be 6 digit long "}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Address 1"
            labelId="Address 1"
            name="addressline1"
            value={shippingAddress?.addressline1 || ""}
            onChange={(e) => handleAddress1Change(e)}
            // value={street}
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Address 2"
            labelId="Address 2"
            name="addressline2"
            value={shippingAddress?.addressline2 || ""}
            onChange={(e) => handleAddress2Change(e)}
            // value={street}
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginTop: "14px" }}>
          <TextField
            id="time"
            label="Delivery time"
            type="time"
            fullWidth
            size="small"
            value={shippingAddress?.time || ""}
            onChange={(e) => handleTimeChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const BillingAddress = ({
  customerDataSet,
  setCustomerDataSet,
  customerData,
}) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateiso, setStateIso] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  // useEffect(() => {
  //   // Check if customerDataSet has data
  //   if (customerData) {
  //     // Set the initial values from the API response
  //     setCustomerDataSet((prevData) => ({
  //       ...prevData,

  //       // country: customerData.country || '',
  //       state: customerData.state || '',
  //       // city: customerData.city || '',
  //       pincode: customerData.pincode || '',
  //       addressline1: customerData.addressline1 || '',
  //       addressline2: customerData.addressline2 || '',

  //     }));
  //   }
  // }, [customerDataSet, setCustomerDataSet]);
  useEffect(() => {
    fetchCountryOptions();
  }, []);
  const handleChangeBillingAddress = (field, value) => {
    setCustomerDataSet((prevData) => ({
      ...prevData,

      [field]: value,
    }));
  };
  // console.log("shipping Address", shippingAddress);
  const handlePincodeChange = (event) => {
    const pincode = event.target.value;
    handleChangeBillingAddress("pincode", pincode);
  };

  const handleAddress1Change = (event) => {
    const address1 = event.target.value;
    handleChangeBillingAddress("address", address1);
  };

  const fetchCountryOptions = async () => {
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      console.log("SEDRTFGYUHIJOKPL", apiKey);
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      const response = await fetch(
        "https://api.countrystatecity.in/v1/countries",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch countries");
      }

      const data = await response.json();

      setCountryOptions(data);
    } catch (error) {
      setApiErrorOpen(true);
      // Handle error
      console.log("Error fetching countries:", error);
    }
  };
  const fetchStatesForCountry = async (countryCode) => {
    console.log("fetchStatesForCountry", countryCode);
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      console.log("API KeyPOIUYTRSZXCFGBHJKML,:", apiKey);
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      console.log("HEADERSSSSSSSSSSSSSS,:", headers);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      const response = await fetch(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch states");
      }

      const data = await response.json(); // Await for the JSON parsing
      console.log("Statessssss", data);

      setStateOptions(data);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching states:", error);
    }
  };

  const fetchCitiesForCountryAndState = async (countryCode, stateIso) => {
    console.log("countryIso", countryCode);
    console.log("stateIso", stateIso);
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      const url = `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateIso}/cities`;

      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Failed to fetch cities");
      }

      const data = await response.json();
      console.log("citiesss dataaaa", data);
      const cities = data?.map((city) => city?.name);

      setCityOptions(cities);
      console.log("setCityOptions", cities);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching cities:", error);
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    console.log("Selected Country:", selectedCountry);
    // Find the country object based on the selected name
    // const selectedCountry = countryOptions.find(country => country.name === selectedCountryName);
    handleChangeBillingAddress("country", selectedCountry);
    if (selectedCountry) {
      setSelectedCountryCode(selectedCountry.iso2);

      fetchStatesForCountry(selectedCountry);
    } else {
      console.error("Selected country or iso2 is undefined.");
    }
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    if (selectedState) {
      setStateIso(selectedState);
      handleChangeBillingAddress("state", selectedState);
      fetchCitiesForCountryAndState(customerDataSet?.country, selectedState);
    } else {
      console.error("Selected state is undefined.");
    }
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    if (selectedCity) {
      handleChangeBillingAddress("city", selectedCity);
    }
  };
  // Fetch countries when the component mounts

  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={4} sx={{ marginTop: "1.6%" }}>
          <FormControl fullWidth>
            <InputLabel id="Country" style={{ textAlign: "center" }}>
              Select Country
            </InputLabel>
            <Select
              labelId="Select Country"
              id="Select Country"
              label="Select Country"
              // value={customerDataSet?.country}
              //value={customerDataSet?.country|| ''}
              value={customerDataSet?.country || ""}
              onChange={(e) => handleCountryChange(e)}
              // value={formData?.country}
              // onChange={handleCountryChange}
              fullWidth
              required
              size="small"
              margin="normal"
              name="Select Country"
              displayEmpty
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              inputProps={{
                style: { width: "100%" },
                "aria-label": "Select Country",
              }}
            >
              {countryOptions?.length === 0 ? (
                <MenuItem disabled>No countries available</MenuItem>
              ) : (
                countryOptions?.map((country) => (
                  <MenuItem key={country?.id} value={country?.name}>
                    {country?.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="State"
            value={customerDataSet?.state || ""}
            onChange={(e) => handleStateChange(e)}
            margin="normal"
            name="State"
            size="small"
            InputLabelProps={{
              shrink: true,
              style: { textAlign: "center" },
            }}
            InputProps={{
              style: { width: "100%" },
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* <FormControl fullWidth>
            <InputLabel id="City" style={{ textAlign: "center" }}>
              City
            </InputLabel>
            <Select
              labelId="City"
              id="City"
              label="City"
              required
              value={customerDataSet?.city || ""}
              onChange={(e) => handleCityChange(e)}
              // value={formData?.city}
              // onChange={handleCityChange}
              fullWidth
              margin="normal"
              name="City"
              size="small"
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              inputProps={{
                style: { width: "100%" },
                "aria-label": "City",
              }}
              displayEmpty
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
            >
              {cityOptions?.length === 0 ? (
                <MenuItem disabled>No cities available</MenuItem>
              ) : (
                cityOptions?.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl> */}
          <TextField
            fullWidth
            label="City"
            value={customerDataSet?.city || ""}
            onChange={(e) => handleCityChange(e)}
            margin="normal"
            name="City"
            size="small"
            InputLabelProps={{
              shrink: true,
              style: { textAlign: "center" },
            }}
            InputProps={{
              style: { width: "100%" },
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Pincode"
            name="pincode"
            value={customerDataSet?.pincode || ""}
            // readOnly
            onChange={handlePincodeChange}
            size="small"
            fullWidth
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              maxLength: 6,
              pattern: "^[1-9]{6}$",
              title: "Pincode should be 6 digits long",
              inputMode: "numeric",
            }}
            helperText={"Pincode should be 6 digit long "}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Address 1"
            labelId="Address 1"
            name="addressline1"
            onChange={handleAddress1Change}
            value={customerDataSet?.address || ""}
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const Payments = ({
  customerDataSet,
  setCustomerDataSet,
  paymentModeTypeOptions,
  setPaymentModeTypeOptions,
  taxZoneTypeOptions,
  setTaxZoneTypeOptions,
}) => {
  useEffect(() => {
    fetchPaymentModeTypeOptions();
    fetchTaxZoneTypeOptions();
  }, []);
  const fetchPaymentModeTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_MODULE_VENDOR_PAYMENTMODETYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.Data?.map((row) => row?.dropdown_value);
      setPaymentModeTypeOptions(options);
    } catch (error) {
      console.log("Error", error);
      // Handle error
    }
  };
  const fetchTaxZoneTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_MODULE_VENDOR_TAXZONETYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.Data?.map((row) => row?.dropdown_value);
      setTaxZoneTypeOptions(options);
    } catch (error) {
      console.log("Error", error);
      // Handle error
    }
  };
  const handleChange = (field, value) => {
    setCustomerDataSet((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  return (
    <>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="Select Mode" style={{ textAlign: "center" }}>
              Payment Mode
            </InputLabel>
            <Select
              labelId="Select Mode"
              id="Select Mode"
              label="Select Mode"
              value={customerDataSet?.payment_terms}
              onChange={(e) => handleChange("payment_terms", e.target.value)}
              // onChange={handleCountryChange}
              fullWidth
              required
              size="small"
              margin="normal"
              name="Select Mode"
              displayEmpty
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              inputProps={{
                style: { width: "100%" },
                "aria-label": "Select Country",
              }}
            >
              <MenuItem value="">Payment Mode</MenuItem>

              {paymentModeTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginTop: "-1.7%" }}>
          <TextField
            label="GST"
            name="GST"
            value={customerDataSet?.gst}
            onChange={(e) => handleChange("gst", e.target.value)}
            // value={formData?.pincode}
            // readOnly onChange={(event) => event.target.value}
            size="small"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="Tax Zone" style={{ textAlign: "center" }}>
              Tax Zone
            </InputLabel>
            <Select
              labelId="Tax Zone"
              id="Tax Zone"
              label="Tax Zone"
              required
              value={customerDataSet?.tax_zone}
              onChange={(e) => handleChange("tax_zone", e.target.value)}
              // value={formData?.state}
              // onChange={handleStateChange}
              fullWidth
              margin="normal"
              name="Tax Zone"
              size="small"
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              displayEmpty
              inputProps={{
                style: { width: "100%" },
                "aria-label": "State",
              }}
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
            >
              <MenuItem value="">Tax Zone</MenuItem>

              {taxZoneTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Aadhaar Number"
            name="Aadhaar Number"
            id="Aadhaar Number"
            value={customerDataSet?.aadhar}
            // readOnly
            fullWidth
            margin="normal"
            onChange={(e) => handleChange("aadhar", e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="PAN Number"
            name="PAN Number"
            value={customerDataSet?.pan}
            // readOnly
            onChange={(e) => handleChange("pan", e.target.value)}
            size="small"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const handleDownloadClick = (fileName) => {
  const presignedUrl = s3.getSignedUrl("getObject", {
    Bucket: "fambo-assets",
    Key: `farmer/${fileName}`,
    Expires: 3600,
  });
  console.log("Presigned URL for viewing:", presignedUrl);
  window.open(presignedUrl, "_blank");
};

const Documents = ({
  images,
  handleCancelImage,
  customerData,
  handleImageChange,
  setEditStatusActive,
  editStatusActive,
}) => (
  <>
    <Grid container spacing={3.5} style={{ marginTop: "0.6%" }}>
      {images?.map((imageType, index) => (
        <Grid key={index} item xs={12} sm={4}>
          <Typography
            variant="h2"
            color="textPrimary"
            fontWeight="400"
            gutterBottom
          >
            Add&nbsp;
            {imageType.type.charAt(0).toUpperCase() + imageType.type.slice(1)}
            <>
              <Button
                variant="contained"
                startIcon={
                  <FileDownloadIcon
                    style={{ color: "#ffffff", fontSize: 26 }}
                  />
                }
                sx={{
                  backgroundColor: "#34519fe6",
                  marginLeft: "22%",
                }}
                size="small"
                disabled={
                  !editStatusActive || !customerData[imageType.type + "_attach"]
                }
                onClick={() =>
                  handleDownloadClick(customerData[imageType.type + "_attach"])
                }
              >
                <Typography variant="h7" fontFamily="Lexend Deca">
                  Download
                </Typography>
              </Button>
            </>
          </Typography>
          {imageType?.images.map((image, imgIndex) => (
            <Grid key={imgIndex} item xs={12} sm={5}>
              <Box
                border="1px dashed #000"
                borderRadius="10px"
                height="60px"
                width="60px"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                position="relative"
              >
                <IconButton
                  onClick={() => handleCancelImage(imageType.type, imgIndex)}
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    color: "red",
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <img
                  src={image.data}
                  alt={`${imageType.type} ${imgIndex + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ marginTop: "3%" }}>
            <Box
              border="1px dashed #000"
              borderRadius="10px"
              height="100px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <Typography
                variant="body1"
                color="textSecondary"
                marginBottom="8px"
              >
                Click or drag to add an image
              </Typography>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(imageType.type, event)}
                style={{ display: "none" }}
                id={`image-upload-${index}`}
              />
              <label htmlFor={`image-upload-${index}`}>
                <IconButton component="span">
                  <AddIcon style={{ color: "red" }} />
                  <Typography variant="button" color="red">
                    Select Image
                  </Typography>
                </IconButton>
              </label>
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
    <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
  </>
);

export const Mapping = ({
  warehouseData,
  customerDataSet,
  setCustomerDataSet,
  setFilteredWarehouse,
  filteredWarehouse,
}) => {
  const theme = useTheme();
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Ensure warehouseData is an array
    const warehouses = Array.isArray(warehouseData)
      ? warehouseData
      : [warehouseData];
    console.log("warehouses", warehouses);
    // Update customerDataSet with selected warehouses
    const selectedWarehousesWithFlag = warehouses
      .filter((warehouse) => value.includes(warehouse?.wh_name))
      .map((selectedWarehouse) => ({
        warehouse_id: selectedWarehouse?.id,
        wh_name: selectedWarehouse?.wh_name,
        flag: true,
      }));
      // Set selectedWarehouses state
      setSelectedWarehouses(selectedWarehousesWithFlag);
    
      // Update the filteredWarehouses state (if needed)
      setFilteredWarehouse(selectedWarehousesWithFlag);
    
      // Create a copy of the original warehouses and update values
      const updatedWarehouses = warehouses?.map((warehouse) => {
        const selectedWarehouse = selectedWarehousesWithFlag?.find(
          (selectedWarehouse) => selectedWarehouse?.warehouse_id === warehouse.id
        );
    
        return selectedWarehouse
          ? {
              id: warehouse.id,
              warehouse_id: warehouse.id,
              wh_name: warehouse.wh_name,
              flag: selectedWarehouse.flag,
            }
          : { warehouse_id: warehouse.id, wh_name: warehouse.wh_name, flag: false };
      });
    
      console.log("updatedWarehouses", updatedWarehouses);
      setSelectedWarehouses(updatedWarehouses);
      // Combine selectedWarehouses and other warehouses with flag: false
      const combinedWarehouses = [
        ...updatedWarehouses,
        ...warehouses.filter((warehouse) => !value.includes(warehouse?.wh_name)).map((warehouse) => ({
          id: warehouse.id,
          warehouse_id: warehouse.id,
          wh_name: warehouse.wh_name,
          flag: false,
        })),
      ];
    
    setCustomerDataSet((prevData) => ({
      ...prevData,
      warehouse: combinedWarehouses || [],
    }));

    console.log("Selected Warehouses:", value);
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "0.6%", marginLeft: "2.1%", marginRight: "10%" }}
      >
        <Typography>Link to WareHouse</Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%", marginRight: "10%" }}
      >
        <FormControl sx={{ m: 1, width: "95%" }}>
          <InputLabel id="product-chip-label">Link to Warehouses</InputLabel>
          <Select
            labelId="product-chip-label"
            id="product-chip"
            multiple
            value={(filteredWarehouse || [])?.map((wh) => wh?.wh_name)}
            onChange={handleChange}
            input={
              <OutlinedInput
                id="select-product-chip"
                label="Select Warehouses"
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {Array.isArray(warehouseData) &&
              warehouseData?.map((warehouse) => (
                <MenuItem key={warehouse?.id} value={warehouse?.wh_name}>
                  {/* <Checkbox
                    checked={(customerDataSet?.warehouse || [])
                      .map((wh) => wh.wh_name)
                      .includes(warehouse.wh_name)}
                  /> */}
                  {warehouse?.wh_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

const ProductMapping = ({
  productData,
  customerId,
  setSelectedProduct,
  selectedProduct,
  setProductToSet,
  onsubmit,
  setOnSubmit,
  setProductData,
  fetchListOfAllProducts,
  action,
  setSelectedProductsWithFlag,
  selectedProductsWithFlag,
  onUpdateProductData, // Callback function from the parent
}) => {
  const theme = useTheme();

  console.log("product data in product mapping ,", productData);
  const updateSelectedProducts = (value, products) => {
    const updatedSelectedProducts =
      typeof value === "string" ? value.split(",") : value;

    // Update isSelected property
    const updatedProductData = products?.map((product) => ({
      ...product,
      isSelected: updatedSelectedProducts?.some(
        (selectedProduct) => selectedProduct?.item_id === product?.item_id
      ),
    }));

    setSelectedProduct(updatedSelectedProducts);

    // Update customerDataSet with selected products
    const updatedSelectedProductsWithFlag = updatedProductData
      .map((selectedProduct) => {
        if (selectedProduct.isSelected) {
          // Check conditions for setting flag and adding to setProductToSet
          if (
            (selectedProduct.flag === false &&
              selectedProduct.isSelected === true) ||
            (selectedProduct.flag === true &&
              selectedProduct.isSelected === true)
          ) {
            // setProductToSet((prevData) => console.log("hgfgtdfghj", prevData));
            setProductToSet((prevData) => ({
              ...prevData,
               product: [
                ...prevData.product,
                {
                  item_id: selectedProduct?.item_id,
                  item_name: selectedProduct?.item_name,
                  customer_id_fk: customerId, // Assuming customerId is available
                  item_code: selectedProduct?.item_code,
                  flag: true,
                },
              ],
            }));
          } else if (
            selectedProduct.flag === true &&
            selectedProduct.isSelected === false
          ) {
            // Handle the case when isSelected is false and flag is true
            setProductToSet((prevData) => ({
              ...prevData,
              product: prevData.product?.map((p) =>
                p.item_id === selectedProduct.item_id
                  ? { ...p, flag: false }
                  : p
              ),
            }));
          }
        } else if (
          selectedProduct.flag === false &&
          selectedProduct.isSelected === false
        ) {
          // Exclude products where isSelected is false and both the current and previous flags are false
          return null;
        }
        return {
          item_id: selectedProduct?.item_id,
          item_name: selectedProduct?.item_name,
          customer_id_fk: customerId,
          item_code: selectedProduct?.item_code,
          flag: selectedProduct?.isSelected,
        };
      })
      .filter(Boolean); // Filter out null values

    setProductToSet((prevData) => ({
      ...prevData,
      product: updatedSelectedProductsWithFlag,
    }));
    setSelectedProductsWithFlag(updatedSelectedProductsWithFlag);

    console.log("Selected Products:", updatedSelectedProductsWithFlag);
    console.log("Updated Product Data:", updatedProductData);
  };

  const handleProductChange = (event, newValue) => {
    const { value } = event.target;
    const products = Array.isArray(productData) ? productData : [productData];
    updateSelectedProducts(newValue, products);
  };
  useEffect(() => {
    // Fetch products for the customer when in 'edit' mode
    if (customerId) {
      fetchListOfAllProducts(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (productData) {
      const selectedProductsFromData = productData
        ?.filter((product) => product.flag === true)
        ?.map((selectedProduct) => selectedProduct);
      console.log("filterddddddddddddddddd", selectedProductsFromData);
      setSelectedProduct(selectedProductsFromData);
      setSelectedProductsWithFlag(productData);
      updateSelectedProducts(selectedProductsFromData, productData);
    }
    
  }, [action, productData]);
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "0.6%", marginLeft: "2.1%", marginRight: "10%" }}
      >
        <Typography>Link to Product</Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%", marginRight: "10%" }}
      >
        <FormControl sx={{ m: 1, width: "95%" }}>
          <Autocomplete
            multiple
            id="Link to Product"
            value={selectedProduct}
            onChange={handleProductChange}
            options={productData}
            getOptionLabel={(option) => option?.item_name}
            renderInput={(params) => (
              <TextField {...params} label="Select Product" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option?.item_id}
                  label={option?.item_name}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </FormControl>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.9%" }}>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};
const CustomerMasterConfig = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  //console.log("Rowdata Here ", rowData);
  // const typography = tokens(theme.palette.mode.typography);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { action, customerData } = useSelector((state) => state.customer);
  console.log("customerDataaaaaaaaaaaaaaaaaaaaaa", customerData);
  console.log("action", action);
  const [editStatusActive, setEditStatusActive] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [selectedProductsWithFlag, setSelectedProductsWithFlag] = useState([]);
  const [productToSet, setProductToSet] = useState([]);
  // const [selectedProductsOnSubmit, setSelectedProductsOnSubmit] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  // const [customerData23, setCustomerData23] = useState(rowData);
  const [customerId, setCustomerId] = useState(null);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingListSubmit, setIsLoadingListSubmit] = useState(false);
  const [paymentModeTypeOptions, setPaymentModeTypeOptions] = useState([]);
  const [taxZoneTypeOptions, setTaxZoneTypeOptions] = useState([]);
  const [customerTypeOptions, setCustomerTypeOptions] = useState([]);
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [filteredWarehouse,setFilteredWarehouse] =useState([]);
  const colors = tokens(theme.palette.mode);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [onsubmit, setOnSubmit] = useState(false);
  const [warehouseLinkingCompleted, setWarehouseLinkingCompleted] =
    useState(false);

  const [currentSection, setCurrentSection] = useState("Personal Details");
  const sections = [
    { name: "Personal Details", icon: <MdModeEdit /> },
    { name: "Shipping Address", icon: <MdLocationOn /> },
    { name: "Billing Address", icon: <MdLocationOn /> },
    { name: "Documents", icon: <MdDescription /> },
    { name: "Payments", icon: <HiCurrencyRupee /> },
    { name: "Connect Warehouse", icon: <FaWarehouse /> },
    { name: "Attach Product", icon: <MdOutlineProductionQuantityLimits /> },
  ];

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };
  const [shippingAddress, setShippingAddress] = useState({
    country: "",
    state: "",
    city: "",
    pincode: 123456,
    addressline1: "",
    addressline2: "",
    time: "",
  });

  useEffect(() => {
    switch (action) {
      case "edit":
        if (customerData) {
          // If editing, prefill the form with the warehouseData
          console.log("customer data in edit form---------- ", customerData);
          console.log("edit action performed");
          setEditStatusActive(true);
          // Fetch states and cities based on the country and state in customerData

          // Initialize WarehouseData with all fields from JSON structure
          setCustomerId(customerData?.id);
          console.log("customer id", customerData?.id);
          const filteredWarehouses = customerData?.warehouse
          ? customerData?.warehouse?.filter((wh) => wh.flag === true)
          : [];
          setFilteredWarehouse(filteredWarehouses);
          setCustomerDataSet((prevData) => ({
            ...prevData,
            ...customerData,
            warehouse: filteredWarehouses,
          }));
          setShippingAddress({
            country: customerData?.shipping_address?.country || "",
            state: customerData?.shipping_address?.state || "",
            city: customerData?.shipping_address?.city || "",
            pincode: customerData?.shipping_address?.pincode || 123456,
            addressline1: customerData?.shipping_address?.addressline1 || "",
            addressline2: customerData?.shipping_address?.addressline2 || "",
            time: customerData?.shipping_address?.time || "",
          });
          // Update only the fields present in warehouseData
        }
        break;

      case "add":
        // If adding, clear the form data
        console.log("add action performed");
        setCustomerDataSet({
          name: "",
          code: "",
          email: "",
          phone: "",
          customer_type: "",
          status: true,
          description: "",
          country: "",
          state: "",
          city: "",
          pincode: 123456,
          addressline1: "",
          addressline2: "",
          shipping_address: shippingAddress,
          gst_attach: "",
          pan_attach: "",
          aadhar_attach: "",
          gst: "",
          pan: "",
          payment_terms: "",

          tax_zone: "",
        });
        break;

      // Add more cases if needed

      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, customerData]);

  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the product data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []);
  useEffect(() => {
    fetchListOfAllProducts(customerId);
  }, []);

  const [customerDataSet, setCustomerDataSet] = useState({
    name: "",
    code: "",
    email: "",
    phone: "",
    customer_type: "",
    status: true,
    description: "",
    country: "",
    state: "",
    city: "",
    pincode: 123456,
    addressline1: "",
    addressline2: "",
    shipping_address: shippingAddress,
    gst_attach: "",
    pan_attach: "",
    aadhar_attach: "",
    gst: "",
    pan: "",
    payment_terms: "",
    tax_zone: "",
  });

  const [images, setImages] = useState([
    { type: "aadhar", images: [], file: null },
    { type: "pan", images: [], file: null },
    { type: "gst", images: [], file: null },
  ]);

  const uploadDocuments = async () => {
    const uploadPromises = images?.map((file) => {
      if (file.file) {
        console.log("TYPE----------------------------------------", file.type);
        console.log("FILE----------------------------------------", file.file);
        const key = `${file.file.name}`;

        const params = {
          Bucket: "fambo-assets",
          Key: `farmer/${key}`,
          Body: file.file,
        };

        return new Promise((resolve, reject) => {
          s3.upload(params, (err, data) => {
            if (err) {
              console.log("Error uploading file:", err);
              reject(err);
            } else {
              console.log("File uploaded successfully:", data.Location);
              resolve(key);
            }
          });
        });
      }
      return Promise.resolve(null);
    });
    try {
      const uploadedKeys = await Promise.all(uploadPromises);
      console.log("All files uploaded:", uploadedKeys);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleImageChange = (imageType, event) => {
    const file = event.target.files[0];
    console.log("images------------", images);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const updatedImages = [...images];
        const imageTypeIndex = updatedImages.findIndex(
          (type) => type.type === imageType
        );

        if (imageTypeIndex !== -1) {
          updatedImages[imageTypeIndex].file = file;
          const fileName = `${file.name}`;
          updatedImages[imageTypeIndex].images.push({
            name: fileName,
            data: reader.result,
          });

          setImages(updatedImages);

          // Update corresponding state property
          setCustomerDataSet((prevData) => ({
            ...prevData,
            [`${imageType.toLowerCase()}_attach`]: fileName,
          }));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = (imageType, index) => {
    const updatedImages = [...images];
    const imageTypeIndex = updatedImages.findIndex(
      (type) => type.type === imageType
    );
    if (imageTypeIndex !== -1) {
      updatedImages[imageTypeIndex].images.splice(index, 1);
      setImages(updatedImages);
    }
  };
  const handleSectionChange = async (sectionName) => {
    try {
      // Start loader when changing section
      setCustomerLoading(true);

      // Your logic for fetching data based on the selected section
      // ...

      // Simulating an async operation
      await new Promise((resolve) => setTimeout(resolve, 400));

      // Stop loader after fetching data or when changing to a non-SKU section
      setCustomerLoading(false);

      // Set the current section
      setCurrentSection(sectionName);
    } catch (error) {
      console.error("Error handling section change:", error);
      // Handle errors if needed
    }
  };
  const handleNext = async () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex < sections.length - 1) {
      const nextSection = sections[currentIndex + 1].name;
      if (action === "edit" && nextSection === "Attach Product") {
        await updateCustomerData();
      } else if (action === "add" && nextSection === "Attach Product") {
        await createCustomerData();
      }
      setCurrentSection(nextSection);
    }
  };
  const updateCustomerData = async () => {
    try {
      if (!customerData?.id) {
        console.error("CUSTOMER id is missing in TransportData.");
        setErrorMessage("Please try Again");
        setErrorPopupOpen(true);
        return;
      }
      setCustomerLoading(true);
      const updatedData = {
        ...customerDataSet,
        shipping_address: shippingAddress,
      };
      console.log("updatedData in this function ", updatedData);

      const endpoint = API_ENDPOINTS.PUT_WMS_EDIT_CUSTOMER_DATA(
        customerData?.id
      );
      console.log("API Endpoint:", endpoint);
      setCustomerId(customerData?.id);

      // Log the TransportData before making the API call
      console.log("Updating custoemrData:", customerDataSet);

      const response = await axiosInstance.put(endpoint, updatedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data updated Successfully!");
        // updateProductData(customerData?.id , selectedProductsWithFlag);
        setWarehouseLinkingCompleted(true);
        setCustomerLoading(false);
        // navigate('/viewWarehouse');
      } else {
        console.error("Failed t;o update the transport data");
        setErrorMessage(
          "Failed to update the transport data. Please try again."
        );
        setCustomerLoading(false);
      }
      console.log("Updated customerData after sending:", updatedData);
    } catch (error) {
      setErrorPopupOpen(true);
      setCustomerLoading(false);
      console.error("Error occurred while updating the transport data:", error);
    }
  };
  const handleSubmit = () => {
    uploadDocuments();
    try {
      if (action === "edit") {
        // If editing, send a PUT request
        setOnSubmit(true);

        updateProductData(customerId, selectedProductsWithFlag);
      } else {
        // If adding, send a POST request
        setOnSubmit(true);
        updateProductData(customerId, selectedProductsWithFlag);
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error("Error submitting Transport Details:", error);
      setMessage("Error submitting Transport Details");
    }
  };

  useEffect(() => {
    // Effect to navigate after success message is set
    if (successPopupOpen) {
      const timer = setTimeout(() => {
        setSuccessPopupOpen(false);
        navigate("/viewCustomer");
        setCustomerLoading(false);
      }, 700);

      return () => clearTimeout(timer);
    }
  }, [successPopupOpen, navigate]);

  const createCustomerData = async () => {
    uploadDocuments();
    try {
      setCustomerLoading(true);
      // Determine the endpoint for creating a new transport
      const endpoint = API_ENDPOINTS.POST_WMS_ADD_CUSTOMER_DATA;
      console.log("API Endpoint:", endpoint);

      // Log the TransportData before making the API call
      console.log(
        "customerDataSet in createCustomerData------------------",
        customerDataSet
      );
      const updatedData = {
        ...customerDataSet,
        shipping_address: shippingAddress,
      };
      // Send the POST request to create a new transport
      const response = await axiosInstance.post(endpoint, updatedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        console.log("response--------------", response);
        // Extract customerId from the response
        const customerId = response.data?.Data?.customer_id;
        setCustomerId(response.data?.Data?.customer_id);
        // Call updateProductData with customerId and selectedProductsWithFlag
        // updateProductData(customerId, selectedProductsWithFlag);

        setWarehouseLinkingCompleted(true);
        setCustomerLoading(false);
        // setSuccessPopupOpen(true);
        // setCustomerId(Data?.customer_id);
        // console.log("customer id coming here ? ",Data?.customer_id);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the transport data");
        setErrorMessage(
          "Failed to create the transport data. Please try again."
        );
        setCustomerLoading(false);
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setCustomerLoading(false);
      console.error("Error occurred while creating the transport data:", error);
    }
  };
  const updateProductData = async (customerId, selectedProductsWithFlag) => {
    setIsLoadingListSubmit(true);

    console.log(
      "selectedproduct in updateproductdata function ",
      selectedProductsWithFlag
    );

    try {
      if (!customerId) {
        console.error("CUSTOMER id is missing in Data.");
        setErrorMessage("Please try Again");
        return;
      }
      setCustomerLoading(true);
      const endpoint =
        API_ENDPOINTS.PUT_WMS_MAPPING_PRODUCT_WITH_CUSTOMER(customerId);
      console.log("API Endpoint:", endpoint);

      // Log the TransportData before making the API call
      console.log("Updating product data :", selectedProductsWithFlag);
      console.log("Request Body:", JSON.stringify(selectedProductsWithFlag));
      const response = await axiosInstance.put(
        endpoint,
        selectedProductsWithFlag,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        console.log("API Response:", response);
        setIsLoadingListSubmit(false);
        setSuccessMessage("Data updated Successfully!");
        setSuccessPopupOpen(true);

        // navigate('/viewCustomer');
      } else {
        console.error("Failed to update the product data");
        setErrorMessage(
          "Failed to update the transport data. Please try again."
        );
      }
    } catch (error) {
      setErrorPopupOpen(true);
      console.error("Error occurred while updating the transport data:", error);
    }
  };
  const handlePrevious = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex > 0) {
      const previousSection = sections[currentIndex - 1].name;
      setCurrentSection(previousSection);
    }
  };
  function handleBack() {
    if (action === "add") {
      navigate("/configuration");
    } else {
      // Navigate to a different location based on your condition
     
      navigate("/viewCustomer");
    }
  }
  const fetchListOfAllProducts = async (customerId) => {
    setIsLoadingList(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_LIST_OF_PRODUCT_FOR_MAPPING,
        {
          params: {
            limit: 1000,
            offset: 1,
            customer_id: customerId,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data?.items;
        console.log("product data", data);
        setProductData(data);

        console.log("product  Data", data);
      } else {
        console.log("Error fetching details");
        setErrorMessage("OOPS, Something went Wrong !!!");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoadingList(false);
      // Set loading to false after the request is completed
    }
  };

  useEffect(() => {
    if (customerId && selectedProductsWithFlag?.length > 0) {
      updateProductData(customerId, selectedProductsWithFlag);
      // Reset the flag after calling updateProductData
      setIsDataSubmitted(false);
    }
  }, [customerId]);
  const isLastSection = currentSection === sections[sections.length - 1].name;
  //Personal Details States

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
          // backgroundColor:'pink'
          // backgroundColor: "rgb(44 123 85)",
        }}
      >
        <Typography
          variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%" }}
        >
          <MdPeopleAlt
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp; Customer
        </Typography>
        {/* <InventoryRoundedIcon sx={{ fontSize: 32, marginLeft: "1px" }} />
          </Typography> */}

        <Stack spacing={2} direction="row">
          {/* <Button
            variant="contained"
            startIcon={
              <MdModeEdit
                style={{ color: colors.white.DEFAULT, fontSize: 15 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              backgroundColor: colors.green.DEFAULT,
            }}
            title="Edit"
          >
            <Typography variant="h7" color={colors.white.DEFAULT}>
              Edit
            </Typography>
          </Button> */}

          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
      {customerLoading ? (
        <>
          <div style={loaderContainerStyle}>
            <ClipLoader color="#495057" loading={customerLoading} />
          </div>
        </>
      ) : (
        <Grid container spacing={3} sx={{ marginTop: "1.7%" }}>
          {/* Left Section (30%) */}
          <Grid item xs={12} sm={3}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            >
              {sections?.map((section) => (
                <Typography
                  key={section.name}
                  onClick={() => handleSectionChange(section.name)}
                  //onClick={() => setCurrentSection(section.name)}
                  style={{
                    cursor: "pointer",
                    padding: "8px",

                    background:
                      currentSection === section.name
                        ? "#34519F5D"
                        : "transparent",
                    color: currentSection === section.name ? "#000" : "inherit",
                    borderRadius:
                      currentSection === section.name ? "7px" : "inherit",
                    // padding:currentSection === section.name ? '5px' : 'inherit',
                  }}
                >
                  {section?.icon}
                  &nbsp;&nbsp;
                  {section?.name}
                </Typography>
              ))}
            </Box>
          </Grid>
          {isLoadingListSubmit ? (
            <div style={loaderContainerStyle}>
              <ClipLoader color="#495057" loading={isLoadingListSubmit} />
            </div>
          ) : (
            <Grid item xs={12} sm={9}>
              <Box
                p={2}
                bgcolor="#ffff"
                borderRadius="10px"
                maxHeight="100%"
                height="100%"
                boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
                position="relative"
              >
                {/* Dynamically render the current section */}
                {currentSection === "Personal Details" && (
                  <PersonalDetails
                    customerDataSet={customerDataSet}
                    setCustomerDataSet={setCustomerDataSet}
                    customerTypeOptions={customerTypeOptions}
                    setCustomerTypeOptions={setCustomerTypeOptions}
                  />
                )}
                {currentSection === "Shipping Address" && (
                  <ShippingAddress
                    customerDataSet={customerDataSet}
                    setCustomerDataSet={setCustomerDataSet}
                    shippingAddress={shippingAddress}
                    setShippingAddress={setShippingAddress}
                    customerData={customerData}
                    action={action}
                  />
                )}
                {currentSection === "Billing Address" && (
                  <BillingAddress
                    customerDataSet={customerDataSet}
                    customerData={customerData}
                    setCustomerDataSet={setCustomerDataSet}
                  />
                )}
                {currentSection === "Payments" && (
                  <Payments
                    customerDataSet={customerDataSet}
                    setCustomerDataSet={setCustomerDataSet}
                    paymentModeTypeOptions={paymentModeTypeOptions}
                    setPaymentModeTypeOptions={setPaymentModeTypeOptions}
                    taxZoneTypeOptions={taxZoneTypeOptions}
                    setTaxZoneTypeOptions={setTaxZoneTypeOptions}
                  />
                )}
                {currentSection === "Documents" && (
                  <Documents
                    images={images}
                    handleCancelImage={handleCancelImage}
                    handleImageChange={handleImageChange}
                    customerData={customerData}
                    setEditStatusActive={setEditStatusActive}
                    editStatusActive={editStatusActive}
                  />
                )}
                {currentSection === "Connect Warehouse" && (
                  <Mapping
                    warehouseData={warehouseData}
                    customerDataSet={customerDataSet}
                    setCustomerDataSet={setCustomerDataSet}
                    setFilteredWarehouse={setFilteredWarehouse}
                  filteredWarehouse={filteredWarehouse}
                  />
                )}
                {currentSection === "Attach Product" && (
                  <ProductMapping
                    productData={productData}
                    setProductData={setProductData}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    customerDataSet={customerDataSet}
                    setProductToSet={setProductToSet}
                    productToSet={productToSet}
                    customerId={customerId}
                    action={action}
                    setOnSubmit={setOnSubmit}
                    onsubmit={onsubmit}
                    fetchListOfAllProducts={fetchListOfAllProducts}
                    setCustomerDataSet={setCustomerDataSet}
                    updateProductData={updateProductData}
                    onUpdateProductData={updateProductData} // Pass the callback function
                    selectedProductsWithFlag={selectedProductsWithFlag} // Pass the state as a prop
                    setSelectedProductsWithFlag={setSelectedProductsWithFlag}
                  />
                )}
                <Box
                  position="absolute"
                  bottom="16px"
                  right="20px"
                  display="flex"
                  gap={2}
                >
                  {currentSection !== sections[0].name && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: colors.green.DEFAULT,
                      }}
                      onClick={handlePrevious}
                    >
                      <Typography variant="h7" color={colors.green.DEFAULT}>
                        Previous
                      </Typography>
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    sx={{
                      borderColor: colors.green.DEFAULT,
                      backgroundColor: colors.green.DEFAULT,
                    }}
                    onClick={isLastSection ? handleSubmit : handleNext}
                  >
                    <Typography variant="h7" color={colors.white.DEFAULT}>
                      {isLastSection ? "Submit" : "Next"}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CustomerMasterConfig;
