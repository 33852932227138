import React, { useState, useEffect } from "react";
import Charts from "react-apexcharts";
import axios from "axios";
import { tokens } from "../../../theme";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@mui/material";

import "./ECTelemetryGraph.css";
import ApiErrorAlert from "../../../ApiErrorAlert";
import { ClipLoader } from "react-spinners";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const PHTelemetryGraph = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chartData, setChartData] = useState([]);
  const theme = useTheme();
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [responseloading, setResponseLoading] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState("30 minutes"); // State for the selected time interval
  const colors = tokens(theme.palette.mode);

  const [showDialog, setShowDialog] = useState(false);
  // Function to fetch data from the backend based on the selected date range
  const fetchData = async () => {
    const currentDate = new Date();
    const endDateEpoch = currentDate.getTime(); // Current date and time in milliseconds
    setEndDate(endDateEpoch); // Update the end date
    let intervalInMilliseconds = 60 * 60 * 1000; // Default to 1 hour in milliseconds
    if (selectedInterval === "30 minutes") {
      intervalInMilliseconds = 30 * 60 * 1000;
    } else if (selectedInterval === "4 hours") {
      intervalInMilliseconds = 4 * 60 * 60 * 1000;
    } else if (selectedInterval === "8 hours") {
      intervalInMilliseconds = 8 * 60 * 60 * 1000;
    } else if (selectedInterval === "12 hours") {
      intervalInMilliseconds = 12 * 60 * 60 * 1000;
    }

    // Calculate the start date based on the selected interval
    const startDateEpoch = currentDate.getTime() - intervalInMilliseconds;
    setStartDate(startDateEpoch); // Update the start date

    try {
      setResponseLoading(true);
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_STATIC_DASHBOARD_EC_PH_TELEMETARY_GRAPH}?start_date=${startDateEpoch}&end_date=${endDateEpoch}&type=ph`, // Update 'type' to 'ph'
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const responseData = response?.data;
        console.log("API RESPONSE", responseData);

        // Assuming responseData is an array of objects like the JSON you provided
        const newData = responseData?.rows?.map((item) => ({
          x: parseInt(item?.timestamp), // Convert the timestamp to a number
          y: parseFloat(item?.ph), // Parse the pH value as a float
        }));

        setChartData(newData);
        setResponseLoading(false);
        console.log("New Data", newData);

        const count = responseData?.rowCount;
        console.log("Count", count);
      }
    } catch (error) {
      setApiErrorOpen(true);

      console.log("API Error:", error);
    }
  };

  // Trigger data fetching when the component mounts and when selectedInterval changes
  useEffect(() => {
    if (selectedInterval === "one week") {
      console.log("Setting showDialog to true");
      setShowDialog(true);
    } else {
      setShowDialog(false);
      fetchData();
    }
  }, [selectedInterval]);

  // Chart options for ApexCharts
  const chartOptions = {
    chart: {
      type: "line",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {},
    fill: {
      type: "solid",
      
    },
    yaxis: {
      title: {
        text: "pH", // Update the y-axis title to 'pH'
      },
    },
    xaxis: {
      title: {
        text: "Time",
      },
      type: "datetime",
      min: startDate, // Set the minimum value of the x-axis to startDate
      max: endDate,
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return val.toFixed(2); // Adjust the formatting as needed
        },
      },
    },
    // #284A6B
    colors: ["#00693E"],    
  };

  const chartSeries = [
    {
      name: "pH", // Update the series name to 'pH'
      data: chartData,
      stroke: {
        width: 5,
      },
    },
  ];

  return (
    <div className="ph-telemetry-graph">
      
      {/* Update the class name */}
      <div className="title-row" style={{ marginLeft: "4%" }}>
        <Typography variant="h2" color={colors.green.DEFAULT}>
          PH Projection
        </Typography>
        <div className="date-fields">
          <FormControl>
            <InputLabel id="Select Time">Select Time</InputLabel>
            <Select
              id="Select Time"
              label="Select Time"
              labelId="Select Time"
              size="small"
              name="Select Time"
              displayEmpty
              inputProps={{ "aria-label": "Select Time" }}
              style={{ width: "120px" }}
              value={selectedInterval}
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
              onChange={(e) => setSelectedInterval(e.target.value)}
            >
              <MenuItem value="30 minutes">30 Minutes</MenuItem>
              <MenuItem value="1 hour">1 Hour</MenuItem>
              <MenuItem value="4 hours">4 Hours</MenuItem>
              <MenuItem value="8 hours">8 Hours</MenuItem>
              <MenuItem value="12 hours">12 Hours</MenuItem>
              <MenuItem value="one week">One Week</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="chart-containerEC">
        <Charts
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={270}
        />
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle>Data is more!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please go to the report section to download the weekly report.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {responseloading && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <ClipLoader color="#2C7B55" />
          </div>
        )}
      </div>
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};

export default PHTelemetryGraph;
