
// reducers.js
const initialState = {
  action: '',
  poData: null,
};
export const poReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PO_ACTION':
      console.log("Payload inside poReducer:", action.payload);
      return {
        action: action.payload.action,
        poData: action.payload.poData,
      };
    default:
      return state;
  }
};
