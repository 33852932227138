import React, { useEffect, useRef, useState } from "react";
import { tokens } from "../AttTheme";
import {
  Card,
  CardContent,
  InputLabel,
  useTheme,
  Grid,
  InputAdornment,
  Stack,
  Button,
  IconButton,
  Switch,
  Typography,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { CenterFocusStrong, PowerSettingsNew } from "@mui/icons-material";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Space,
  TimePicker,
  Input,
  Tag,
  theme,
  Tooltip,
  Divider,
} from "antd";
const { RangePicker } = DatePicker;
const { CheckableTag } = Tag;

const AttReportPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const ScheduleComponent = () => {
    const tagsData = ["Daily", "Weekly", "Monthly"];
    const [selectedTag, setSelectedTag] = useState("Monthly");
    const handleChange = (tag) => {
      if (selectedTag === tag) {
        setSelectedTag(null);
      } else {
        setSelectedTag(tag);
      }
    };
    return (
      <>
        <Space size={[0, 16]} wrap>
          {tagsData.map((tag) => (
            <CheckableTag
              key={tag}
              checked={selectedTag === tag} // Check if the tag is selected
              onChange={() => handleChange(tag)}
              size="large"
            >
              {tag}
            </CheckableTag>
          ))}
        </Space>
      </>
    );
  };

  const EmailComponent = () => {
    const [tags, setTags] = useState(["tech@fambo.in", "sales@fambo.in"]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState("");
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
      if (inputVisible) {
        inputRef.current?.focus();
      }
    }, [inputVisible]);
    useEffect(() => {
      editInputRef.current?.focus();
    }, [editInputValue]);
    const handleClose = (removedTag) => {
      const newTags = tags.filter((tag) => tag !== removedTag);
      console.log(newTags);
      setTags(newTags);
    };
    const showInput = () => {
      setInputVisible(true);
    };
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
      if (inputValue && !tags.includes(inputValue)) {
        setTags([...tags, inputValue]);
      }
      setInputVisible(false);
      setInputValue("");
    };
    const handleEditInputChange = (e) => {
      setEditInputValue(e.target.value);
    };
    const handleEditInputConfirm = () => {
      const newTags = [...tags];
      newTags[editInputIndex] = editInputValue;
      setTags(newTags);
      setEditInputIndex(-1);
      setEditInputValue("");
    };
    const tagInputStyle = {
      width: 64,
      height: 22,
      marginInlineEnd: 8,
      verticalAlign: "top",
    };
    const tagPlusStyle = {
      height: 32,
      background: "#c0cdef8a",
      borderStyle: "dashed",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    return (
      <Space size={[0, 8]} wrap>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={tag}
                size="large"
                style={tagInputStyle}
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              closable
              style={{
                userSelect: "none",
              }}
              onClose={() => handleClose(tag)}
            >
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    setEditInputIndex(index);
                    setEditInputValue(tag);
                    e.preventDefault();
                  }
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="large"
            style={tagInputStyle}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
            Add New Email
          </Tag>
        )}
      </Space>
    );
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        style={{ marginLeft: "9.7%", marginTop: "1%" }}
      >
        <Grid item>
          <Typography
            variant="h2"
            color="#5e89c7"
            fontWeight="600"
            fontFamily="Lexend Deca"
            fontSize="1.65rem"
            style={{ marginLeft: "8px" }} // Adjust the margin between icon and text
          >
            Schedule Report Configuration
          </Typography>
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2%",
          height: "100%",
          width: "100%",
        }}
      >
        <Card style={{ height: "90%", width: "80%" }}>
          <CardContent>
            <Grid
              container
              alignItems="center"
              sx={{ borderRadius: "30px" }}
              marginTop={"5px"}
            >
              <Divider orientation="left" orientationMargin="0">
                Select Date and Time
              </Divider>
              <Grid item xs={12} sm={4.5} md={4.5}>
                <RangePicker
                  // onChange={handleDateChange}
                  size="large"
                  format="DD-MM-YYYY"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <TimePicker size="large" />
              </Grid>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ marginTop: "30px" }}
              >
                Schedule this Report for
              </Divider>
              <Grid item xs={12} sm={6} md={6}>
                <ScheduleComponent size="large" />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              sx={{ borderRadius: "30px" }}
              marginTop={"20px"}
            >
              <Divider orientation="left" orientationMargin="0">
                Add Mail Address
              </Divider>
              <EmailComponent />
            </Grid>
            <Stack
              spacing={1}
              direction="row"
              style={{
                height: "35px",
                marginTop: "2%",
                marginLeft: "84%",
              }}
            >
              <Button
                type="submit"
                sx={{ backgroundColor: "#5E89C7" }}
                variant="contained"
              >
                <Typography
                  variant="h6"
                  color={colors.white.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Schedule
                </Typography>
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default AttReportPage;
