// reducer.js
const initialState = {
    action: '', // Initial state for the 'action' property
    // other properties...
  };
  
 export  const soupdatereducer = (state = initialState, action) => {
    console.log('Reducer Action Type:', action.type);
    console.log('Reducer Current State:', state);
    switch (action.type) {
      case 'UPDATE_ACTION':
        console.log('Reducer Payload:', action.payload);
        return {
          ...state,
          action: action.payload,
        };
      // other cases...
      default:
        return state;
    }
  };
  
  