import React, { useState } from "react";
import { Button, Flex } from "antd";
import { tokens } from "../WMStheme";
import { useTheme, Grid, Divider, Typography } from "@mui/material";
import SKUFilter from "./InventoryFilters/SKUFilter";
import SKULotFilter from "./InventoryFilters/SKULotFilter";
import ProductFilter from "./InventoryFilters/ProductFilter";
import PurchaseSheet from "./InventoryFilters/PurchaseSheet";

const filterTypes = ["SKU WISE", "PRODUCT WISE", "SKU LOT WISE","PURCHASE SHEET"];
const filterParameterMapping = {
  "SKU WISE": "sku",
  "SKU LOT WISE": "lot",
  "PRODUCT WISE": "product",
  "PURCHASE SHEET":"purchase",
};
const InventoryMainPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filterParameter, setFilterParameter] = useState(
    filterParameterMapping["SKU WISE"]
  ); // Set 'sku' as default
  const [selectedFilter, setSelectedFilter] = useState("SKU WISE"); // Set 'SKU' as default

  const handleButtonClick = (filterType) => {
    setSelectedFilter(filterType);
    setFilterParameter(filterParameterMapping[filterType]);
  };
  // Custom style for selected button
  const selectedButtonStyle = {
    width: "15%",
    marginRight: "10px",
    backgroundColor: "#34519fe6", // Replace with your custom color
    borderColor: "#34519fe6",
    color: "#ffff", // Replace with your custom color
  };
  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Inventory
        </Typography>
      </Grid>

      <Grid sx={{ marginTop: "1.0%" }}>
        <Flex gap="large" wrap="wrap">
          {filterTypes?.map((filterType) => (
            <Button
              key={filterType}
              // shape="round"

              style={
                selectedFilter === filterType
                  ? selectedButtonStyle
                  : {
                      width: "20%",
                      marginRight: "10px",
                      borderColor: "#B6B7B7",
                    }
              }
              onClick={() => handleButtonClick(filterType)}
            >
              {`${filterType} `}
            </Button>
          ))}
        </Flex>
      </Grid>
      <Divider
        sx={{
          borderBottom: "1px solid #A4A5A5",
          margin: "10px 0",
        }}
        orientation="horizontal"
        variant="middle"
      ></Divider>
      <Grid item xs={12} sm={9} md={9} sx={{ marginTop: "0.2%" }}>
        {filterParameter && (
          <>
            {filterParameter === "sku" && <SKUFilter param={filterParameter} />}
            {filterParameter === "lot" && (
              <SKULotFilter param={filterParameter} />
            )}
            {filterParameter === "product" && (
              <ProductFilter param={filterParameter} />
            )}
            {filterParameter === "purchase" && (
              <PurchaseSheet />
            )} 
          </>
        )}
      </Grid>
    </>
  );
};

export default InventoryMainPage;
