import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import DeleteIcon from "@mui/icons-material/Delete";
import { tokens } from "../../../../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import {
  Dialog,
  Divider,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
  Table,
  TextField,
  Stack,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  IconButton,
  createTheme,
  ThemeProvider,
  useTheme,
  Select,
  Tooltip,
} from "@mui/material";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import GradesImg from "../../../../Assets/ConfigurationImg/grades.png";
import StagesImg from "../../../../Assets/ConfigurationImg/growth.png";
import Search from "../../../../Assets/ConfigurationImg/search.png";

import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const GradesDialog = ({ isOpen, onClose, grades, name }) => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  console.log("heyyyyy grades here --------------------------------", grades);
  const renderGrades = () => {
    console.log("renderGrades called");
    const displayedGrades = [];

    if (grades && Array.isArray(grades)) {
      grades?.forEach((grade, index) => {
        console.log("each grade-----------", grade);
        {
          displayedGrades?.push(
            <TableRow
              key={index}
              style={{
                backgroundColor:
                  index % 2 === 0 ? "rgb(220 232 220)" : "rgb(239 244 239)",
              }}
            >
              <TableCell>{grade?.grade_name}</TableCell>
              <TableCell>{grade?.grade_desc}</TableCell>
            </TableRow>
          );
        }
      });
    }
    console.log("displayedGrades", displayedGrades);
    return displayedGrades;
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: {
          width: "500px",
          height: "450px",
        },
      }}
    >
      <DialogTitle>
        {grades && name && (
          <div style={{ width: "100%" }}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "800",
                fontFamily: "Lexend Deca",
                fontSize: "18px",
              }}
            >
              Grades for Variety {name}
            </Typography>
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        {grades ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "rgb(120 187 123)",
                    fontWeight: "bold",
                  }}
                >
                  Grade Name
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(120 187 123)",
                    fontWeight: "bold",
                  }}
                >
                  Grade Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderGrades()}</TableBody>
          </Table>
        ) : (
          <div style={{ width: "100%", marginTop: "25%", textAlign: "center" }}>
            <img src={Search} alt="" style={{ width: "10%", height: "10%" }} />
            <Typography
              variant="body1"
              style={{
                fontFamily: "Lexend Deca",
                fontSize: "16px",
              }}
            >
              No grades available for this variety.
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="success" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StagesDialog = ({ isOpen, onClose, stages, name }) => {
  console.log("heyyyyy stages here ", stages);

  const renderStages = () => {
    return stages.map((stage, index) => {
      const phaseNameKey = `phase_${index + 1}_name`;
      const phaseValueKey = `phase_${index + 1}_value`;
      const phaseName = stage[phaseNameKey];
      const phaseValue = stage[phaseValueKey];

      return (
        phaseName && (
          <TableRow
            key={index}
            style={{
              backgroundColor:
                index % 2 === 0 ? "rgb(220 232 220)" : "rgb(239 244 239)",
            }}
          >
            <TableCell>{phaseName}</TableCell>
            <TableCell>{phaseValue}</TableCell>
          </TableRow>
        )
      );
    });
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: {
          width: "500px", // Set your desired width here
          height: "450px", // Set your desired height here
        },
      }}
    >
      <DialogTitle>
        {stages && name && (
          <div style={{ width: "100%" }}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "800",
                fontFamily: "Lexend Deca",
                fontSize: "18px",
              }}
            >
              Stages for Variety {name}
            </Typography>
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  backgroundColor: "rgb(120 187 123)",
                  fontWeight: "bold",
                }}
              >
                Stages Name
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: "rgb(120 187 123)",
                  fontWeight: "bold",
                }}
              >
                Day After Sowing
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderStages()}</TableBody>
        </Table>

        {/* <div style={{ width: "100%", marginTop: "25%", textAlign: "center" }}>
            <img src={Search} alt="" style={{ width: "10%", height: "10%" }} />
            <Typography
              variant="body1"
              style={{
                fontFamily: "Lexend Deca",
                fontSize: "16px",
              }}
            >
              No stages available for this variety.
            </Typography>
          </div>
        )} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="success" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CropVarietyComponent = ({ data, fetchData, handleCloseDialog }) => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  console.log("data here in crop variety :", data);
  // console.log("FETCH DATAAAAAAAAAAAA :", fetchData);
  const [varietyData, setVarietyData] = useState([]);
  const [errors, setErrors] = useState({});
  const [openHelper, setOpenHelper] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenGrades, setIsDialogOpenGrades] = useState(false);
  const [selectedRowStages, setSelectedRowStages] = useState([]);
  const [varietyName, setVarietyName] = useState([]);
  const [selectedRowGrades, setSelectedRowGrades] = useState([]);
  const [VaritiesCropTypeOption, setVaritiesCropTypeOption] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [cropname, setCropName] = useState();
  const [stageName, setStageName] = useState([]);
  const [gradesName, setGradesName] = useState([]);
  const [isDialogOpen2, setIsDailogOpen2] = useState(false);
  const [editVarietyDialog, setEditVarietyDialog] = useState(false);
  const [cropid, setCropId] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Max_stage = 5;
  const Max_grades = 5;

  const [cropVData, setCropVData] = useState({
    name: "",
    code: "",
    type: "",
    // yield: null,
    exp_yield1: 0,
    exp_yield1_unit: "",
    exp_yield2: 0,
    exp_yield2_unit: "",
    brand: "",
    description: "",
    cycle: "",
    stages: [
      {
        stage_name: null,
        date_aftr_sowing: null,
        stage_desc: "",
      },
    ],
    grades: [{ grades_name: null, grades_desc: null }],
    // crop_variety_id:
  });

  useEffect(() => {
    if (data) {
      console.log("lets dispaly data ", data?.CropVarieties);
      console.log("crop Name ::::", data?.CropName);
      setCropName(data?.CropName);
      setCropId(data?.id);
      console.log("id of cropjbfeijibcu3hci", data?.id);
      const allVarietiesData =
        data?.CropVarieties?.map((variety) => ({ ...variety })) || [];
      setVarietyData(allVarietiesData);
      setIsLoading(false);
      fetchData();
      console.log("uehuewhuew", allVarietiesData);
      console.log("wwwwwwwwwwwwwwww", varietyData);
    }
  }, [data]);
  useEffect(() => {
    fetchVaritiesCropTypeOptions();
    fetchStagesOptions();
    fetchGradesOptions();
  }, []);
  const handleHelpIconClick = () => {
    setOpenHelper(true); // open dialog box
  };

  const handleCloseHelper = () => {
    setOpenHelper(false); // close dialog box
  };
  const addNewStage = () => {
    const stage = { stage_name: null, date_aftr_sowing: null, stage_desc: "" };
    const newStages = [...cropVData?.stages, stage];
    setCropVData({ ...cropVData, stages: newStages });
  };
  const addNewGrades = () => {
    const grades = { gardes_name: null, grades_desc: null };
    const newGrades = [...cropVData?.grades, grades];
    setCropVData({ ...cropVData, grades: newGrades });
  };
  const handleDeleteStage = (stageIndex) => {
    const newStages = [...cropVData?.stages];
    newStages?.splice(stageIndex, 1);

    const newCropData = {
      ...cropVData,
      stages: newStages,
    };

    setCropVData(newCropData);
  };
  const handleDeleteGrades = (gradesIndex) => {
    const newGrades = [...cropVData?.grades];
    newGrades?.splice(gradesIndex, 1);

    const newCropData = {
      ...cropVData,
      grades: newGrades,
    };

    setCropVData(newCropData);
  };
  const handleCloseDialog2 = () => {
    //OK DAILOG BOX HANDLECLOSE
    fetchData();

    handleCloseDialog();
    setIsDailogOpen2(false);

    // Close the dialog
  };
  const fetchVaritiesCropTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_CROP_VARIETY_TYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setVaritiesCropTypeOption(options);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error", error);
    }
  };

  const fetchStagesOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_STAGENAME,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setStageName(options);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error", error);
    }
  };
  const fetchGradesOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_GRADESNAME,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setGradesName(options);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error", error);
    }
  };
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "success",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#166534", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    console.log("save function is executing here!!!!!!!!!!!");
    if (validationErrors && !Object.keys(validationErrors)?.length) {
      console.log("entering in if statement========>");
      try {
        console.log("hey!!!!!!inside try stattement=====>>>");
        if (row && row.original) {
          console.log("Values in farm:", values);
          const updatedRows = {
            ...row.original,
            crop_type: values?.crop_type,
            crop_variety_cycle: values?.crop_variety_cycle,
            crop_variety_name: values?.crop_variety_name,
            description: values?.description,
            seed_brand: values?.seed_brand,
            variety_code: values?.variety_code,
            // yield: values?.yield,
            exp_yield1: values?.exp_yield1,
            exp_yield1_unit: values?.exp_yield1_unit,
            exp_yield2: values?.exp_yield2,
            exp_yield2_unit: values?.exp_yield2_unit,
            crop_variety_id: values?.id,
          };

          console.log("!!!!!!!!!!", updatedRows);
          // Update the rows state with the updated data
          setCropVData(updatedRows);
          const idToUpdate3 = row.original?.id;

          const response = await axiosInstance.put(
            `${API_ENDPOINTS.PUT_CONFIGURATION_EDITCROPVARIETY(idToUpdate3)}`,
            updatedRows,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("updated data:", updatedRows);
          if (response.status >= 200 && response.status < 305) {
            // The data was successfully updated on the backend
            // You can perform any additional actions or update the UI here
            fetchData();
            exitEditingMode();
            setIsDailogOpen2(true);
            // Required to exit editing mode and close modal
          } else {
            // Handle the error if the PUT request was not successful
            console.log("Failed to update the farm data");
            // alert("Please fill all fields !");
          }
        } else {
          console.log("Error: 'row' or 'row.original' is undefined");
        }
      } catch (error) {
        setApiErrorOpen(true);
        console.log("Error occurred while updating the farm data:", error);
      }
    } else {
      setCropVData(cropVData); // Revert the changes if there are validation errors
    }
  };

  const cropDataWithName = {
    CropName: cropname,
    crop_variety_id: cropid,
    ...cropVData,
  };
  const handleStageChange = (event, stageIndex, field) => {
    const newStages = [...cropVData.stages];
    newStages[stageIndex][field] = event.target.value;
    setCropVData({ ...cropVData, stages: newStages });
  };

  const handleGradesChange = (event, gradeIndex, field) => {
    const newGrades = [...cropVData.grades];
    newGrades[gradeIndex][field] = event.target.value;
    setCropVData({ ...cropVData, grades: newGrades });
  };

  const handleInputChange = (field, value) => {
    setCropVData((prevCropData) => {
      return { ...prevCropData, [field]: value };
    });
  };
  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        // onBlur: (event) => {

        //   let isValid = true;
        //   if (cell.column.id === "email") {
        //     isValid = validateEmail(event.target.value);
        //   } else if (cell.column.id === "mobile_number") {
        //     isValid = validateMobileNumber(event.target.value);
        //   } else {
        //     isValid = validateRequired(event.target.value);
        //   }
        //   if (!isValid) {
        //     //set validation error for cell if invalid
        //     setValidationErrors({
        //       ...validationErrors,
        //       [cell.id]: `${cell.column.columnDef.header} is required`,
        //     });
        //   }
        //    else {
        //     //remove validation error for cell if valid
        //     delete validationErrors[cell.id];
        //     setValidationErrors({
        //       ...validationErrors,
        //     });
        //   }
        // },
      };
    },
    [validationErrors]
  );
  // Function to handle form submission
  const handleSubmit = () => {
    axiosInstance
      .post(
        API_ENDPOINTS.POST_CONFIGURATION_ADDNEWCROPVARIETY,
        cropDataWithName
      )
      .then((response) => {
        const data = response?.data;
        // Handle the response from the backend if needed
        console.log("Data successfully submitted:", data);
      })
      .catch((error) => {
        setApiErrorOpen(true);
        // Handle any errors that occurred during the POST request
        console.log("Error submitting data:", error);
      });
    fetchData();
    handleCloseDialog();
    // onClose();
    setEditVarietyDialog(false);
  };

  const Varietycolumns = useMemo(
    () => [
      {
        header: "Stages",
        size: 4,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: () => {
            console.log("hnirfnthto", cell.row.original);
            console.log(cell.getValue(), cell.id);
          },
          title: "Click to view Stages",
        }),
        Cell: ({ cell }) => {
          const handleClick = () => {
            setVarietyName(cell.row.original?.crop_variety_name);
            setSelectedRowStages(cell.row.original?.stages);
            console.log("stages dispaly222222222:", cell.row.original?.stages);
            console.log("stages dispaly:", selectedRowStages);
            setIsDialogOpen(true);
          };
          return (
            <div className="tooltip-cell" title="Click to view stages ">
              <IconButton
                className="icon-wrapper"
                onMouseEnter={() => console.log("Hovering...")}
                onClick={handleClick}
              >
                <img
                  src={StagesImg}
                  alt="Stages"
                  className="stages-image"
                  style={{ height: "30px", width: "30px" }}
                />
              </IconButton>
              <span className="farm-name">{cell.getValue()}</span>
            </div>
          );
        },
      },
      {
        header: "Grades",
        size: 4,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: () => {
            //setIsDialogOpen(true);
            //handleRowClick(cell.row.original);
            console.log("hnirfnthto", cell.row.original);
            console.log(cell.getValue(), cell.id);
          },
          title: "Click to view Grades",
        }),
        Cell: ({ cell }) => {
          const handleClickGrade = () => {
           
            setVarietyName(cell.row.original?.crop_variety_name);
            setSelectedRowGrades(cell.row.original.grades);
            console.log("grades dispaly:", selectedRowGrades);
            setIsDialogOpenGrades(true);
          };
          return (
            <div className="tooltip-cell" title="Click to view Grades">
              <IconButton
                className="icon-wrapper"
                onMouseEnter={() => console.log("Hovering...")}
                onClick={handleClickGrade}
              >
                <img
                  src={GradesImg}
                  alt="Stages"
                  className="stages-image"
                  style={{ height: "30px", width: "30px" }}
                />
              </IconButton>
              <span className="farm-name">{cell.getValue()}</span>
            </div>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "crop_variety_name",
        size: 10,
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },
      {
        header: "Variety Code",
        accessorKey: "variety_code",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },
      {
        header: "Variety Cycle",
        accessorKey: "crop_variety_cycle",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
        }),
      },
      {
        header: "Seed Brand",
        accessorKey: "seed_brand",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 40 },
        }),
      },

      {
        header: "Variety Type",
        accessorKey: "crop_type",
        size: 10,
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: VaritiesCropTypeOption?.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          )),
        },
      },

      {
        header: "Yield",
        accessorKey: "yield",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number",
        }),
        Cell: ({ row }) => {
          const combinedYield = `${row.original.exp_yield1}${row.original.exp_yield1_unit} / ${row.original.exp_yield2} (${row.original.exp_yield2_unit})`;
          return <span>{combinedYield}</span>;
        },
      },
      {
        header: "Description",
        accessorKey: "description",
        size: 10,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          inputProps: { maxLength: 100 },
        }),
      },
    ],
    [getCommonEditTextFieldProps]
  );

  console.log("V DATAAAAAAAAAA", varietyData);
  return (
    <Grid
      sx={{
        width: "100%",
        backgroundColor: "#ffffff",
        // margin: "55px -21px 15px 30px",
        justifyItems: "center",
        // boxShadow: "rgb(0 0 0 / 21%) 2px 2px 2px 2px",
      }}
    >
      {isLoading ? ( // Check if data is still loading
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <ScaleLoader color="#2C7B55" />
        </div>
      ) : (
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={Varietycolumns}
            data={varietyData}
            enableGrouping
            enableStickyHeader
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 5 },
            }}
            muiTableBodyProps={{
              sx: {
                //stripe the rows, make odd rows a darker color
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#f8fafc",
                },
              },
            }}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: "650",
                color: "#2c7B55",
                fontSize: "12px",
              },
            }}
            enableEditing={userDetails?.config_b_editCropDetails}
            enableMode="modal"
            onEditingRowSave={handleSaveRow}
            // onEditingRowCancel={handleCancelRowEdits}
            muiTableContainerProps={{ sx: { marginBottom: "0px" } }}
            muiToolbarAlertBannerChipProps={{ color: "primary" }}
            enableRowActions
            renderTopToolbarCustomActions={() => (
              <Button
                sx={{
                  backgroundColor: userDetails?.config_b_editCropDetails
                    ? colors.green.DEFAULT
                    : colors.black[200],
                }}
                onClick={() => setEditVarietyDialog(true)}
                variant="outlined"
                disabled={!userDetails?.config_b_editCropDetails}
              >
                <Typography color={colors.white.DEFAULT}>
                  Add New Crop Variety
                </Typography>
              </Button>
            )}
          />
        </ThemeProvider>
      )}
      <Dialog open={isDialogOpen2} onClose={handleCloseDialog2}>
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#198754" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p>Your data has been updated successfully.</p>
        </DialogContent>
        <Button
          onClick={handleCloseDialog2}
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "2%",
            color: "#198754",
          }}
        >
          OK
        </Button>
      </Dialog>

      <Dialog open={editVarietyDialog} maxWidth="lg">
        <DialogTitle>
          <Typography
            variant="h5"
            style={{
              color: "rgb(44 123 85)",
              marginLeft: "-1%",
            }}
          >
            Add New Variety for {cropname?.toUpperCase()}
          </Typography>
        </DialogTitle>

        <form>
          <DialogContent>
            <Stack
              direction="column"
              sx={{
                overflowX: "hidden",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "0.2rem",
                marginTop: "-5%",
              }}
            >
              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                display="flex"
              >
                <Grid item xs={9} sm={4} sx={{ marginTop: "2px" }}>
                  <TextField
                    label="Variety Name"
                    name="VarietyName"
                    value={cropVData?.name || ""}
                    required
                    fullWidth
                    size="small"
                    margin="normal"
                    borderColor="#000000"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 30,
                    }}
                    onChange={(event) =>
                      handleInputChange("name", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={9} sm={4} sx={{ marginTop: "2px" }}>
                  <TextField
                    label="Variety Code"
                    name="VarietyCode"
                    value={cropVData?.code || ""}
                    required
                    fullWidth
                    size="small"
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 40,
                    }}
                    onChange={(event) =>
                      handleInputChange("code", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={9} sm={4} sx={{ marginTop: "10px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="VarietyType">Variety Type</InputLabel>
                    <Select
                      labelId="Variety Type"
                      id="Variety Type"
                      label="Variety Type"
                      value={cropVData?.type || ""}
                      name="Variety Type"
                      size="small"
                      onChange={(event) =>
                        handleInputChange("type", event.target.value)
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "Variety Type" }}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      required
                      // error={errors[`type_${index}`] !== undefined}
                      // helperText={errors[`type_${index}`]}
                      fullWidth
                    >
                      {VaritiesCropTypeOption?.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem disabled value="">
                        Variety Type
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* <Grid item xs={9} sm={4} sx={{ marginTop: "-8px" }}>
                <TextField
                  label="Expected Yield"
                  name="ExpectedYield"
                  value={cropVData?.yield}
                  required
                  size="small"
                  type="number"
                  fullWidth
                  margin="normal"
                  onKeyPress={(event) => {
                    const keyCode = event.keyCode || event.which;
                    const keyValue = String.fromCharCode(keyCode);
                    const regex = /^[0-9\b%]+$/; // regular expression to match only numerical values, backspace, and %
                    if (!regex.test(keyValue)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter Yield in grams"
                  inputProps={{
                    style: { width: "100%" },
                    min: 0,
                    max: 100000,
                  }}
                  onChange={(event) => {
                    let value = event.target.value;
                    if (value > 100000) {
                      value = 100000;
                    }

                    handleInputChange("yield", event.target.value);
                  }}
                  // error={errors[`yield_${index}`] !== undefined}
                  // helperText={errors[`yield_${index}`]}
                />
                <Button
                  onClick={handleHelpIconClick}
                  startIcon={
                    <AiOutlineQuestionCircle
                      style={{ color: colors.black[100], marginTop: "-25%" }}
                    />
                  }
                >
                  <Typography
                    sx={{ fontSize: "9.3px", color: colors.black[100] }}
                  >
                    How to calculate expected yield
                  </Typography>
                </Button>
                <Dialog open={openHelper} onClose={handleCloseHelper}>
                  <DialogTitle>How to calculate expected yield</DialogTitle>
                  <DialogContent>
                    <ol>
                      <li>Step 1: Determine the total number of crops </li>
                      <li>Step 2: Multiply with average weight of the crop</li>
                    </ol>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseHelper}>Close</Button>
                  </DialogActions>
                </Dialog>
              </Grid> */}
              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                display="flex"
                // sx={{ marginTop: "-3%" }}
              >
                <Grid item xs={9} sm={3} style={{ marginTop: "-4%" }}>
                  <TextField
                    label="Exp. Produce"
                    value={cropVData?.exp_yield1}
                    fullWidth
                    variant="standard"
                    size="small"
                    type="number"
                    margin="normal"
                    placeholder="Exp. Produce"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 100000,
                    }}
                    onChange={(event) => {
                      let value = parseInt(event.target.value);
                      if (value > 100000) {
                        value = 100000;
                      }
                      handleInputChange("exp_yield1", value);
                    }}
                  />
                </Grid>
                <Grid item xs={9} sm={3} style={{ marginTop: "-2%" }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Unit</InputLabel>
                    <Select
                      label="Unit"
                      value={cropVData?.exp_yield1_unit}
                      onChange={(event) => {
                        let value = event.target.value;
                        handleInputChange("exp_yield1_unit", value);
                      }}
                      size="small"
                      inputProps={{ "aria-label": "Unit" }}
                    >
                      <MenuItem value="" disabled>
                        Select Unit
                      </MenuItem>
                      <MenuItem value="kg">Kg</MenuItem>
                      <MenuItem value="tonnes">Tonnes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={9} sm={3} style={{ marginTop: "-4%" }}>
                  <TextField
                    label="Total Produce"
                    value={cropVData?.exp_yield2}
                    size="small"
                    variant="standard"
                    fullWidth
                    type="number"
                    margin="normal"
                    placeholder="Total Produce"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 100000,
                    }}
                    onChange={(event) => {
                      let value = parseInt(event.target.value);
                      if (value > 100000) {
                        value = 100000;
                      }
                      handleInputChange("exp_yield2", value);
                    }}
                  />
                </Grid>

                <Grid item xs={9} sm={3} style={{ marginTop: "-2%" }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Unit</InputLabel>
                    <Select
                      label="Unit"
                      value={cropVData?.exp_yield2_unit}
                      onChange={(event) => {
                        let value = event.target.value;
                        handleInputChange("exp_yield2_unit", value);
                      }}
                      size="small"
                      inputProps={{ "aria-label": "Unit" }}
                    >
                      <MenuItem value="" disabled>
                        Select Unit
                      </MenuItem>
                      <MenuItem value="kg">Kg</MenuItem>
                      <MenuItem value="tonnes">Tonnes</MenuItem>
                      <MenuItem value="square_meters">Square Meters</MenuItem>
                      <MenuItem value="hectares">Hectares</MenuItem>
                      <MenuItem value="acers">Acers</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                onClick={handleHelpIconClick}
                startIcon={
                  <AiOutlineQuestionCircle
                    style={{ color: colors.black[100], marginTop: "-25%" }}
                  />
                }
              >
                <Typography
                  sx={{ fontSize: "9.3px", color: colors.black[100] }}
                >
                  How to calculate expected yield
                </Typography>
              </Button>

              <Dialog open={openHelper} onClose={handleCloseHelper}>
                <DialogTitle>How to calculate expected yield</DialogTitle>
                <DialogContent>
                  <ol>
                    <li>Step 1: Determine the total number of crops </li>
                    <li>Step 2: Multiply with average weight of the crop</li>
                  </ol>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseHelper}>Close</Button>
                </DialogActions>
              </Dialog>
              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                display="flex"
                sx={{ marginTop: "-5%" }}
              >
                <Grid item xs={9} sm={6}>
                  <TextField
                    label="Seed Brand"
                    name="brand"
                    size="small"
                    value={cropVData?.brand || ""}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 55,
                    }}
                    onChange={(event) =>
                      handleInputChange("brand", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={9} sm={6}>
                  <TextField
                    label="Crop Cycle"
                    name="cycle"
                    readOnly
                    value={cropVData?.cycle}
                    type="number"
                    fullWidth
                    size="small"
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 999,
                      inputMode: "numeric",
                    }}
                    onKeyPress={(event) => {
                      if (event.target.value.length >= 3) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(event) =>
                      handleInputChange("cycle", event.target.value)
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={8}>
                <div style={{ padding: "2px", border: "1px solid #ccc" }}>
                  <Typography variant="h5" color={colors.black[200]}>
                    Stages
                  </Typography>
                  <table
                    style={{
                      marginTop: "-2%",
                      marginLeft: "12%",
                      width: "100%",
                    }}
                  >
                    <tbody style={{ marginTop: "-10%" }}>
                      {cropVData?.stages?.map((stage, stageIndex) => (
                        <Grid style={{ marginTop: "1%" }}>
                          <tr
                            key={stageIndex}
                            // style={{ backgroundColor: "rgb(248 248 245" }}
                          >
                            <td style={{ marginTop: "-12%" }}>
                              <TextField
                                id={`stage-select-${stageIndex}`}
                                select
                                label="Stage Name"
                                value={stage?.stage_name}
                                onChange={(event) =>
                                  handleStageChange(
                                    event,
                                    stageIndex,
                                    "stage_name"
                                  )
                                }
                                helperText="Select a stage"
                                variant="standard"
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: "140px",
                                        overflowY: "auto",
                                      }, // Set the max-height for the menu
                                    },
                                  },
                                }}
                                displayEmpty
                                inputProps={{ "aria-label": "Stages Name" }}
                                startAdornment={
                                  <InputAdornment position="start"></InputAdornment>
                                }
                              >
                                {stageName?.map((stageOption, index) => (
                                  <MenuItem key={index} value={stageOption}>
                                    {stageOption}
                                  </MenuItem>
                                ))}
                                <MenuItem disabled value="">
                                  Stages Name
                                </MenuItem>
                              </TextField>
                            </td>

                            <td>
                              <TextField
                                id="standard-basic"
                                label="Day after sowing"
                                variant="standard"
                                type="number"
                                multiline
                                onKeyPress={(event) => {
                                  const keyCode = event.keyCode || event.which;
                                  const keyValue = String.fromCharCode(keyCode);
                                  if (/\D/.test(keyValue) && keyCode !== 8) {
                                    event.preventDefault();
                                  }
                                }}
                                // helperText="Enter no. of days after sowing "
                                value={stage?.date_aftr_sowing}
                                onChange={(event) =>
                                  handleStageChange(
                                    event,
                                    stageIndex,
                                    "date_aftr_sowing"
                                  )
                                }
                                error={
                                  stage?.date_aftr_sowing?.toString()?.length >
                                  3
                                }
                                helperText={
                                  stage?.date_aftr_sowing?.toString()?.length >
                                  3
                                    ? "3 or less than 3 digits"
                                    : "no. of days after sowing "
                                }
                              />
                            </td>
                            <td>
                              <IconButton
                                style={{ border: "none", marginTop: "60px" }}
                                onClick={() => handleDeleteStage(stageIndex)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                          <Divider
                            style={{
                              margin: "1px -1px 4px 7px",
                              width: "5%",
                              color: "colors.black[100]",
                            }}
                          />
                        </Grid>
                      ))}
                      {cropVData?.stages?.length < Max_stage && (
                        <div
                          style={{
                            width: "140%",
                            marginTop: "-1%",
                            marginLeft: "-0.8%",
                          }}
                        >
                          <Button
                            onClick={() => addNewStage()}
                            sx={{ marginLeft: "-2.2%" }}
                            startIcon={
                              <AddCircleOutlineOutlinedIcon
                                style={{ color: colors.green.DEFAULT }}
                              />
                            }
                          >
                            <Typography
                              variant="h6"
                              color={colors.green.DEFAULT}
                            >
                              Add more stages
                            </Typography>
                          </Button>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </Grid>

              <Grid item xs={12} sm={8} sx={{ marginTop: "3px" }}>
                <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                  <Typography variant="h5" color={colors.black[200]}>
                    Grades
                  </Typography>
                  <table
                    style={{
                      width: "100%",
                      marginTop: "1%",
                      marginLeft: "11%",
                    }}
                  >
                    <tbody>
                      {cropVData?.grades?.map((grades, gradesIndex) => (
                        <Grid>
                          <tr key={gradesIndex} style={{ marginTop: "-35%" }}>
                            <td style={{ marginTop: "-1%" }}>
                              <TextField
                                id={`grades-select-${gradesIndex}`}
                                select
                                label="Grades Name"
                                value={grades?.grades_name}
                                onChange={(event) =>
                                  handleGradesChange(
                                    event,
                                    gradesIndex,
                                    "grades_name"
                                  )
                                }
                                helperText="Please select a grades"
                                variant="standard"
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: "140px",
                                        overflowY: "auto",
                                      }, // Set the max-height for the menu
                                    },
                                  },
                                }}
                                inputProps={{
                                  style: { width: "100%" },
                                  "aria-label": "Grades Name",
                                }}
                              >
                                {gradesName?.map((gradesOption, index) => (
                                  <MenuItem key={index} value={gradesOption}>
                                    {gradesOption}
                                  </MenuItem>
                                ))}
                                <MenuItem disabled value="">
                                  Grades Name
                                </MenuItem>
                              </TextField>
                            </td>
                            <td style={{ marginTop: "1%" }}>
                              <TextField
                                id="standard-basic"
                                variant="standard"
                                label="Description"
                                multiline
                                inputProps={{
                                  style: { width: "100%" },
                                  min: 0,
                                  max: 255,
                                }}
                                helperText="Enter description"
                                value={grades?.grades_desc}
                                onChange={(event) =>
                                  handleGradesChange(
                                    event,
                                    gradesIndex,
                                    "grades_desc"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <IconButton
                                style={{ border: "none", marginTop: "60px" }}
                                onClick={() => handleDeleteGrades(gradesIndex)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                          <Divider
                            style={{
                              margin: "1px -1px 4px 7px",
                              width: "5%",
                              color: "colors.black[100]",
                            }}
                          />
                        </Grid>
                      ))}
                      {cropVData?.grades?.length < Max_grades && (
                        <div style={{ width: "140%", marginTop: "-2%" }}>
                          <Button
                            onClick={() => addNewGrades()}
                            startIcon={
                              <AddCircleOutlineOutlinedIcon
                                style={{ color: colors.green.DEFAULT }}
                              />
                            }
                          >
                            <Typography
                              variant="h6"
                              color={colors.green.DEFAULT}
                            >
                              Add more grades
                            </Typography>
                          </Button>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </Grid>

              <Grid item xs={9} sm={6}>
                <TextField
                  label="Description"
                  name="description"
                  value={cropVData?.description}
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    min: 0,
                    max: 255,
                  }}
                  onChange={(event) =>
                    setCropVData({
                      ...cropVData,
                      description: event.target.value,
                    })
                  }
                />
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ p: "1.25rem" }}>
            <Button onClick={() => setEditVarietyDialog(false)}>Cancel</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{ marginRight: "1%", backgroundColor: colors.green.DEFAULT }}
            >
              <Typography variant="h5" color={colors.white.DEFAULT}>
                Add Variety
              </Typography>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <StagesDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        stages={selectedRowStages}
        name={varietyName}
      />

      <GradesDialog
        isOpen={isDialogOpenGrades}
        onClose={() => setIsDialogOpenGrades(false)}
        grades={selectedRowGrades}
        name={varietyName}
      />
    </Grid>
  );
};

const CropView = () => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const [cropdata, setCropData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  useEffect(() => {
    fetchCropData();
    const loaderTimer = setTimeout(() => {
      setIsLoading(false);
    }, 10000); // Display loader for 10 seconds

    fetchCropData();

    return () => clearTimeout(loaderTimer);
  }, []);
  const cropTypeOption = [
    { label: "Vine Crop", value: "Vine Crop" },
    { label: "Food Crop", value: "Food Crop" },
    { label: "Fiber Crop", value: "Fiber Crop" },
    { label: "Oil Crop", value: "Oil Crop" },
  ];
  const handleButtonClick = () => {
    navigate("/mainpage");
  };
  const handleRowClick = (row) => {
    setSelectedRow(row);

    setIsDialogOpen(true);
  };

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "success",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12, // S
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#166534", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };
  const handleCloseDialog = () => {
    fetchCropData();
    setIsDialogOpen(false);
  };
  const handleCloseDialog1 = () => {
    fetchCropData();
    setOpenDialog1(false); // Close the dialog
    console.log("2345678i9op", openDialog1);
  };
  const fetchCropData = () => {
    axiosInstance
      .get(API_ENDPOINTS.GET_CONFIGURATION_CROP)
      .then((response) => {
        const data = response?.data;
        console.log("crop data coming in grid", data);
        const cropData1 = data?.rows?.map((row) => row?.crop_details);

        console.log("Crop Data", cropData1);
        setCropData(cropData1);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setApiErrorOpen(true);
      });
    setIsLoading(false);
  };
  useEffect(() => {
    fetchCropData();
  }, []);
  console.log("CROPPPPPPP DATAAAAAAAAAAAA ", cropdata);
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors)?.length) {
      try {
        if (row && row.original) {
          console.log("Values in farmmmmmmmmmmmmmmmmmm:", values);
          console.log("crop id=====", row.original.id);
          const updatedRows = {
            ...row.original,
            CropCode: values?.CropCode,
            CropType: values?.CropType,
            crop_id: row.original.id,
          };
          console.log("!!!!!!!!!!!!!!!!!!!!!!!", updatedRows);
          // Update the rows state with the updated data
          setCropData(updatedRows);

          const response = await axiosInstance.put(
            API_ENDPOINTS.PUT_CONFIGURATION_EDITCROP,
            updatedRows,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status >= 200 && response.status < 305) {
            // The data was successfully updated on the backend
            // You can perform any additional actions or update the UI here

            exitEditingMode();
            fetchCropData();
            setOpenDialog1(true); // Required to exit editing mode and close modal
          } else {
            // Handle the error if the PUT request was not successful
            console.log("Failed to update the farm data");
          }
        } else {
          console.log("Error: 'row' or 'row.original' is undefined");
        }
      } catch (error) {
        console.log("Error occurred while updating the farm data:", error);
        setApiErrorOpen(true);
      }
    } else {
      setCropData(cropdata); // Revert the changes if there are validation errors
    }
  };
  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      {
        header: "Variety",
        size: 30,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: () => {
            //setIsDialogOpen(true);
            handleRowClick(cell.row.original);
            console.log("hnirfnthto", cell.row.original);
            console.log(cell.getValue(), cell.id);
          },
          title: "Click to view crop Variety details",
        }),
        //or in the component override callbacks like this
        Cell: ({ cell }) => {
          return (
            <div
              className="tooltip-cell"
              title="Click to view crop variety details"
              style={{ lineHeight: "0.1" }}
            >
              <IconButton
                className="icon-wrapper"
                onMouseEnter={() => console.log("Hovering...")}
              >
                <DoubleArrowIcon
                  style={{
                    height: "20px",
                    width: "30px",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1%",
                  }}
                />
              </IconButton>
              <span className="crop-name">{cell.getValue()}</span>
            </div>
          );
        },
      },
      {
        header: "Farm Name",
        accessorKey: "farm_name",
        size: 50,
        enableEditing: false,
      },
      {
        header: "Crop Name",
        accessorKey: "CropName",
        size: 50,
        enableEditing: false,
      },
      {
        header: "Crop Code",
        accessorKey: "CropCode",
        size: 10,
        // enableEditing: false,
      },
      {
        header: "Crop Type",
        accessorKey: "CropType",
        size: 10,
        enableEditing: "true",
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: cropTypeOption?.map((option) => (
            <MenuItem key={option.value} value={option.label}>
              {option.label}
            </MenuItem>
          )),
        },
      },
    ],
    [getCommonEditTextFieldProps]
  );
  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Crop Details
        </Typography>

        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon style={{ color: colors.green.DEFAULT }} />}
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Back
          </Typography>
        </Button>
      </Grid>
      <Grid sx={{ marginTop: "2%" }}>
        {isLoading ? ( // Check if data is still loading
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <ScaleLoader color="#2C7B55" />
          </div>
        ) : (
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  muiTableHeadCellProps: {
                    align: "center",
                  },
                  size: 1,
                },
              }}
              columns={columns}
              data={cropdata && cropdata.length !== 0 ? cropdata : []}
              enableGrouping
              enableStickyHeader
              enableColumnVirtualization
              enableRowVirtualization
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 5 },
              }}
              editingMode="modal"
              enableEditing={userDetails?.config_b_editCropDetails}
              onEditingRowSave={handleSaveRow}
              onEditingRowCancel={handleCancelRowEdits}
              muiTableHeadCellProps={{
                sx: {
                  fontWeight: "650",
                  color: "#2c7B55",
                  fontSize: "15px",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f8fafc",
                  },
                },
              }}
              muiTableContainerProps={{ sx: { marginBottom: "15px" } }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
            />
          </ThemeProvider>
        )}
      </Grid>
      <Dialog open={isDialogOpen} maxWidth="lg">
        <DialogTitle id="form-dialog-title">
          <Typography variant="h2" color={colors.green.DEFAULT}>
            Crop Variety Details: {selectedRow?.CropName?.toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedRow && (
            // <Grid style={{ width: "100%" }}>
            // <Grid>
            <CropVarietyComponent
              data={selectedRow}
              fetchData={fetchCropData}
              handleCloseDialog={handleCloseDialog}
            />
            // </Grid>
            // </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{ marginRight: "1%", backgroundColor: colors.green.DEFAULT }}
            variant="contained"
          >
            <Typography variant="h5" color={colors.white.DEFAULT}>
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog1} onClose={handleCloseDialog1}>
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#198754" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p>Your data has been updated successfully.</p>
        </DialogContent>
        <Button
          onClick={handleCloseDialog1}
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "2%",
            color: "#198754",
          }}
        >
          OK
        </Button>
      </Dialog>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};
export default CropView;
