import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Select,
  InputLabel,
  FormControl,
  TextField,
  MenuItem,
  Grid,
  Slide,
  List,
  ListItem,
  Button,
  Alert,
  InputAdornment,
  AlertTitle,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const FarmConfig = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [employeeNames, setEmployeeNames] = useState([]);
  const [farmTypeOptions, setFarmTypeOptions] = useState([]);
  const [growingMediaOptions, setGrowingMediaOptions] = useState([]);
  const [capacityUnitOptions, setCapacityUnitOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateiso, setStateIso] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [errors, setErrors] = useState({});
  const [totalZoneArea, setTotalZoneArea] = useState(0);
  const [zoneCount, setZoneCount] = useState(0);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const steps = [
    "Farm Configuration",
    "Farm Address",
    "Zone Configuration",
    "Confirm Details",
  ];

  const [selectedLocation, setSelectedLocation] = useState({
    lat: null,
    lng: null,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const navigate = useNavigate();
  // const defaultFarmType = FarmTypeOptions.find(option => option.label === params.row.farm_type)?.label || '';
  const [formData, setFormData] = useState({
    FarmName: "",
    FarmType: "",
    FarmArea: 0,
    AreaUnit: "",
    FarmCapacity: 0,
    CapacityUnit: "",
    GrowingMedia: "",
    FarmIncharge: "",
    ValuesSubordinate: "",
    TotalFarmer: 0,
    country: "",
    state: "",
    pincode: 123456,
    city: "",
    street: "",
    latitude: null,
    longitude: null,
    FarmDescription: "",
    zones: [
      {
        zoneName: "",
        zoneArea: 0,
        ZoneAreaUnit: "",
        locationStart: "",
        locationEnd: "",
        zoneCapacity: 0,
        zoneDescription: "",
      },
    ],
  });

  const setFormData1 = (newFormData) => {
    const errors = {};
    if (!newFormData?.FarmName) {
      errors.FarmName = "Required";
    }
    setFormErrors(errors);
    setFormData(newFormData);
  };
  const [currentStep, setCurrentStep] = useState(1);
  const units = [
    {
      label: "acers",
      value: "acers",
      conversionFactor: 0.404686,
    },
    {
      label: "hectares",
      value: "hectares",
      conversionFactor: 1,
    },
    {
      label: "square meters",
      value: "square meters",
      conversionFactor: 0.0001,
    },
  ];
  const handleZoneAreaChange = (event, index) => {
    const newZoneArea = event.target.value;
    const newZones = [...formData.zones];
    newZones[index].zoneArea = newZoneArea;
    setFormData({ ...formData, zones: newZones });
  };
  // Calculate the total zone area whenever the zones array changes
  useEffect(() => {
    const area = formData?.zones?.reduce(
      (total, zone) => total + zone.zoneArea,
      0
    );
    setTotalZoneArea(area);
  }, [formData.zones]);

  useEffect(() => {
    fetchDataFarmer();
    fetchFarmTypeOptions();
    fetchGrowingMediaOptions();
    fetchCapacityUnitOptions();
    fetchCountryOptions();
  }, []);

  useEffect(() => {
    const selectedCountryyy = selectedCountry;
    console.log("Abhi wali countryy", selectedCountryyy);
  }, [selectedCountry]);

  const fetchDataFarmer = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARMER,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      console.log("oiujhg", data);
      // Extract employee names from the response data
      const names = data?.rows?.map((employee) => employee?.employee_name);

      setEmployeeNames(names);
      console.log("testing farmer name !!!!!!!!", names);
    } catch (error) {
      console.log("Error fetching data:", error);
      setApiErrorOpen(true);
    }
  };

  const fetchFarmTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARMTYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setFarmTypeOptions(options);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error", error);
      // Handle error
    }
  };
  const fetchGrowingMediaOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_GROWINGMEDIA,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setGrowingMediaOptions(options);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error", error);
      // Handle error
    }
  };
  const fetchCapacityUnitOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_CAPACITYUNIT,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setCapacityUnitOptions(options);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error", error);
      // Handle error
    }
  };
  const handleCountryChange = (event) => {
    //  fetch states for the selected country
    const selectedCountry = event.target.value;
    console.log("Kountryyyyyyyyyyy", selectedCountry);
    setSelectedCountryCode(selectedCountry.iso2);
    setFormData({ ...formData, country: selectedCountry });
    fetchStatesForCountry(selectedCountry);
  };
  const handleStateChange = (event) => {
    console.log("inside state change");
    const selectedState = event.target.value;
    console.log("selectedState", selectedState);
    setFormData({ ...formData, state: selectedState });
    setStateIso(selectedState);
    console.log("1111111111111111", formData.country);
    console.log("222222222", selectedState);
    fetchCitiesForCountryAndState(formData.country, selectedState);
  };
  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setFormData({ ...formData, city: selectedCity });
    console.log("formmmmmmm", formData);
  };
  const apiKey34 = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
  console.log("API Key45678:", apiKey34);
  const fetchCountryOptions = async () => {
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      console.log("SEDRTFGYUHIJOKPL", apiKey);
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      const response = await fetch(
        "https://api.countrystatecity.in/v1/countries",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch countries");
      }

      const data = await response.json();

      setCountryOptions(data);
    } catch (error) {
      setApiErrorOpen(true);
      // Handle error
      console.log("Error fetching countries:", error);
    }
  };
  const fetchStatesForCountry = async (countryCode) => {
    console.log("fetchStatesForCountry", countryCode);
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      console.log("API KeyPOIUYTRSZXCFGBHJKML,:", apiKey);
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      console.log("HEADERSSSSSSSSSSSSSS,:", headers);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      const response = await fetch(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch states");
      }

      const data = await response.json(); // Await for the JSON parsing
      console.log("Statessssss", data);

      setStateOptions(data);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching states:", error);
    }
  };

  const fetchCitiesForCountryAndState = async (countryIso, stateIso) => {
    console.log("countryIso", countryIso);
    console.log("stateIso", stateIso);
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      const url = `https://api.countrystatecity.in/v1/countries/${countryIso}/states/${stateIso}/cities`;

      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Failed to fetch cities");
      }

      const data = await response.json();
      console.log("citiesss dataaaa", data);
      const cities = data?.map((city) => city?.name);

      setCityOptions(cities);
      console.log("setCityOptions", cities);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching cities:", error);
    }
  };

  console.log("countryyyyyyyy", countryOptions);
  console.log("statessss", stateOptions);
  console.log("citiessss", cityOptions);

  // Add a helper function to convert farm area to acres
  const getFarmAreaInAcres = () => {
    const { FarmArea, AreaUnit } = formData;
    if (AreaUnit === "acres") {
      return parseFloat(FarmArea);
    } else if (AreaUnit === "hectares") {
      return parseFloat(FarmArea) * 2.47105;
    } else {
      return 0;
    }
  };

  // Add a helper function to convert zone area to acres
  const getZoneAreaInAcres = () => {
    const { ZoneArea, AreaUnit } = formData;
    if (AreaUnit === "acres") {
      return parseFloat(ZoneArea);
    } else if (AreaUnit === "hectares") {
      return parseFloat(ZoneArea) * 2.47105;
    } else if (AreaUnit === "square meters") {
      return parseFloat(ZoneArea) / 4046.86;
    } else {
      return 0;
    }
  };

  // Add a helper function to check if the zone area is valid
  const isZoneAreaValid = () => {
    const farmArea = getFarmAreaInAcres();
    const maxZoneArea = farmArea - totalZoneArea;
    const { zones } = formData;
    const lastZone = zones[zones.length - 1];
    const lastZoneArea = parseFloat(lastZone?.zoneArea || 0);
    return lastZoneArea <= maxZoneArea;
  };

  // Add a helper function to check if the zone area is greater than the farm area
  const isZoneAreaGreaterThanFarmArea = () => {
    const farmAreaInAcres = getFarmAreaInAcres();
    const zoneAreaInAcres = getZoneAreaInAcres();
    return zoneAreaInAcres > farmAreaInAcres;
  };
  const getRemainingArea = () => {
    const farmAreaInAcres = getFarmAreaInAcres();
    const totalZoneAreaInAcres = formData?.zones?.reduce(
      (sum, zone) => sum + parseFloat(zone.zoneArea),
      0
    );
    return farmAreaInAcres - totalZoneAreaInAcres;
  };

  // const handleLocationSelect = (lat, lng) => {
  //   setSelectedLocation({ lat, lng });

  //   const updatedFormData = {
  //     ...formData,

  //     // Update the FarmAddress object with the new latitude and longitude values
  //     FarmAddress: {
  //       ...formData?.FarmAddress,
  //       latitude: lat,
  //       longitude: lng,
  //     },
  //   };

  //   // Update the formData state with the new object
  //   setFormData(updatedFormData);
  //   console.log(updatedFormData);
  // };

  const handleSave = (event) => {
    // Here you can write the code to save the form data to a database or send it to an API endpoint
    console.log("formdata:", formData);
    console.log(selectedLocation);
    setErrorOpen(false); // Open the success alert
    event.preventDefault();
    axiosInstance
      .post(API_ENDPOINTS.POST_CONFIGURATION_FARM, formData)
      .then((response) => {
        if (response.status >= 200 && response.status < 305) {
          setSuccessOpen(true); // Open the success alert
        } else {
          setErrorOpen(false);
        }
        // Handle the API response
      })
      .catch((error) => {
        // Handle the API error
        setApiErrorOpen(true);
        console.log("error", error);
        setMessage("Form submission failed.");
      });

    navigate("/fview");
  };
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleFarmAreaChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "farmArea") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else if (name === "areaUnit") {
      const prevAreaUnit = formData?.AreaUnit;
      const prevFarmArea = formData?.FarmArea;

      const conversionFactor =
        units.find((unit) => unit.value === prevAreaUnit).conversionFactor /
        units.find((unit) => unit.value === value).conversionFactor;
      setFormData({
        FarmArea: (prevFarmArea * conversionFactor).toFixed(2),
        AreaUnit: value,
      });
    }
  };

  const handleZoneRemove = (index) => {
    const newzones = [...formData.zones];
    newzones.splice(index, 1);
    setFormData((prevState) => ({ ...prevState, zones: newzones }));
    let prevCount = zoneCount;
    setZoneCount((prevCount) => prevCount - 1);
  };
  const handleKeyPress1 = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 31 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122) &&
      charCode != 32
    ) {
      event.preventDefault();
    }
  };

  const handleAreaUnitChange = (event) => {
    console.log("event in handleAreaUnitChange", event);
    setFormData({ ...formData, AreaUnit: event.target.value });
  };
  const validateStepOne = () => {
    let isValid = true;
    let errors = {};
    if (!formData?.FarmName || !formData?.FarmName?.trim()) {
      errors.FarmName = "Farm Name is required";
      isValid = false;
    }
    // if (!formData.FarmType) {
    //   errors.FarmType = "Farm Type is required";
    //   isValid = false;
    // }
    if (!formData?.FarmArea || !formData?.FarmArea.trim()) {
      errors.FarmArea = "Farm Area is required";
      isValid = false;
    }
    //debugger;
    // if (!formData.FarmCapacity.trim()) {
    //   errors.FarmCapacity = "Farm Capacity is required";
    //   isValid = false;
    // }
    // if (!formData.GrowingMedia) {
    //   errors.GrowingMedia = "Growing Media is required";
    //   isValid = false;
    // }
    // if (!formData.FarmIncharge.trim()) {
    //   errors.FarmIncharge = "Farm Incharge is required";
    //   isValid = false;
    // }
    // if (!formData.ValuesSubordinate.trim()) {
    //   errors.ValuesSubordinate = "Values Subordinate is required";
    //   isValid = false;
    // }
    // if (!formData.TotalFarmer.trim()) {
    //   errors.TotalFarmer = "Total Farmer is required";
    //   isValid = false;
    // }
    setFormData({ ...formData, errors });
    return isValid;
  };
  const handleZoneAdd = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      zones: [
        ...prevFormData.zones,
        {
          zoneName: "",
          zoneArea: 0,
          ZoneAreaUnit: "",
          locationStart: "",
          locationEnd: "",
          zoneCapacity: "",
          zoneDescription: "",
        },
      ],
    }));
    let prevCount = zoneCount;
    setZoneCount((prevCount) => prevCount + 1);
  };
  const validateStepTwo = () => {
    let isValid = true;
    let errors = {};
    // if (!formData.country.trim()) {
    //   errors.country = "Country is required";
    //   isValid = false;
    // }
    // if (!formData.state.trim()) {
    //   errors.state = "State is required";
    //   isValid = false;
    // }
    // if (!formData.pincode.trim()) {
    //   errors.pincode = "Pincode is required";
    //   isValid = false;
    // }
    // if (!formData.city.trim()) {
    //   errors.city = "City is required";
    //   isValid = false;
    // }
    // if (!formData.street.trim()) {
    //   errors.street = "Street is required";
    //   isValid = false;
    // }
    // if (formData.FarmAddress.latitude === null || isNaN(formData.FarmAddress.latitude)) {
    //   errors.latitude = "Latitude is required and must be a number";
    //   isValid = false;
    // }
    // if (formData.FarmAddress.longitude === null || isNaN(formData.FarmAddress.longitude)) {
    //   errors.longitude = "Longitude is required and must be a number";
    //   isValid = false;
    // }
    // else{
    // setFormData({ ...formData, errors });
    // return isValid;}
  };
  const validateStepThree = () => {
    const newErrors = {};
    formData?.zones?.forEach((zones, index) => {
      if (!zones?.zoneName) {
        newErrors["zoneName_${index}"] = "ZoneName is required";
      }
    });
    setErrors(newErrors);
    return Object?.keys(newErrors)?.length === 0;
  };
  const next = () => {
    let isValid = false;
    console.log("next button click for edit checking ");
    switch (currentStep) {
      case 1:
        isValid = validateStepOne();
        if (!isValid) {
          setErrorOpen(true);
          return;
        }
        break;
      // case 2:
      //   isValid = validateStepTwo();
      //   if (!isValid) {
      //     alert("Please first fill all fields ");
      //     return;
      //   }
      //   break;
      case 3:
        isValid = validateStepThree();
        if (!isValid) {
          setErrorOpen(true);
          return;
        }
        break;
      default:
        break;
    }
    console.log("next button click ");
    setCurrentStep(currentStep + 1);
  };
  const previous = () => {
    setCurrentStep(currentStep - 1);
  };
  const submit = () => {
    setErrorOpen(false); // Open the success alert
    console.log("before calling handlesave");
    handleSave();
    console.log("submit button working");
    setCurrentStep(1);
  };
  console.log(
    "to check whether zone array is existing or not :",
    formData.zones
  );
  const renderStepOne = () => {
    return (
      <div
        style={{
          // marginLeft: "5%",
          justifyContent: "center",
          boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
          marginTop: "-2%",
          marginLeft: "2%",
        }}
      >
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          display="flex"
          style={{ marginTop: "15px" }}
        >
          <Grid item xs={9} sm={5.7}>
            <TextField
              label="FarmName"
              name="FarmName"
              id="FarmName"
              value={formData?.FarmName}
              required
              fullWidth
              size="small"
              placeholder="Enter Farm Name"
              margin="normal"
              inputProps={{
                style: {
                  width: "100%",
                  borderColor: "black !important", // Set the border color to black and override existing styles
                  borderWidth: "1px !important", // Set the border width to 1px and override existing styles
                  borderStyle: "solid !important",
                },

                maxLength: 50, // maximum length of 50 characters
                minLength: 3, // minimum length of 3 characters
                pattern: /^[a-zA-Z ]+$/, // only allows letters and spaces
              }}
              onChange={(event) =>
                setFormData1({ ...formData, FarmName: event.target.value })
              }
              error={Boolean(formErrors?.FarmName)}
              helperText={formErrors?.FarmName}
            />
          </Grid>
          <Grid item xs={9} sm={5.7} marginTop="8px">
            <FormControl fullWidth>
              <InputLabel>Select FarmType</InputLabel>
              <Select
                labelId="Select FarmType"
                label="Select FarmType"
                fullWidth
                size="small"
                value={formData?.FarmType}
                onChange={(event) =>
                  setFormData({ ...formData, FarmType: event.target.value })
                }
                required
                displayEmpty
                inputProps={{ "aria-label": "Select FarmType" }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {farmTypeOptions?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
                <MenuItem disabled value="">
                  Select FarmType
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid container spacing={0} style={{ marginTop: "10px" }}> */}
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          display="flex"
          size="small"
          style={{ marginTop: "5px" }}
        >
          <Grid item xs={9} sm={5.7}>
            <TextField
              label="Farm Area"
              value={formData.FarmArea}
              pattern="^[0-9]+(\\.[0-9]{1,3})?$"
              required
              type="number"
              name="Farm Area"
              fullWidth
              size="small"
              inputProps={{
                style: { width: "100%" },
                min: 0,
                max: 99999,
                // onKeyDown: (event) => {
                //   const key = event.key;
                //   const isValid =
                //     (key >= "0" && key <= "9") ||
                //     key === "Backspace" ||
                //     key === "Delete" ||
                //     key === "ArrowLeft" ||
                //     key === "ArrowRight" ||
                //     key === "Tab" ||
                //     key === "."; // Check for numeric keys, Backspace, Delete, arrow keys, Tab, and decimal point
                //   if (!isValid) {
                //     event.preventDefault();
                //   }
                // },
              }}
              onChange={(event) => {
                // Check if input is greater than 999 and reset to 999 if needed
                const value = parseFloat(event.target.value).toFixed(3);
                if (!isNaN(value) && Math.floor(value) > 99999) {
                  setFormData({ ...formData, FarmArea: "99999" });
                } else {
                  const FarmAreaVal = event.target.value;
                  setFormData({ ...formData, FarmArea: FarmAreaVal });
                }
              }}
              // error={Boolean(formErrors.FarmArea)}
              helperText={formErrors?.FarmArea || ""}
            />
          </Grid>
          <Grid item xs={9} sm={5.7} style={{ marginTop: "-1px" }}>
            <FormControl fullWidth>
              <InputLabel id="AreaUnit" style={{ textAlign: "center" }}>
                Area Unit
              </InputLabel>
              <Select
                labelId="Area Unit"
                id="Area Unit"
                label="Area Unit"
                value={formData?.AreaUnit}
                onChange={handleAreaUnitChange}
                fullWidth
                size="small"
                margin="normal"
                name="Area Unit"
                required
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Select Area Unit",
                }}
                displayEmpty
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {units?.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
                <MenuItem disabled value="">
                  Select Area Unit
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* </Grid> */}

        <Grid container spacing={2} marginTop="10px">
          <Grid item xs={9} sm={11.4}>
            <FormControl fullWidth>
              <InputLabel id="GrowingMedia" style={{ textAlign: "center" }}>
                Growing Media
              </InputLabel>
              <Select
                labelId="Growing Media"
                id="Growing Media"
                label="Growing Media"
                value={formData?.GrowingMedia}
                onChange={(event) =>
                  setFormData({ ...formData, GrowingMedia: event.target.value })
                }
                name="Growing Media"
                size="small"
                displayEmpty
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Select Growing Media",
                }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {growingMediaOptions?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
                <MenuItem disabled value="">
                  Select Growing Media
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          display="flex"
          style={{ marginTop: "15px" }}
        >
          <Grid item xs={9} sm={5.7} style={{ marginTop: "-2px" }}>
            <TextField
              label="Farm Capacity"
              value={formData?.FarmCapacity}
              inputMode="numeric"
              pattern="[0-9]*"
              size="small"
              type="number"
              onChange={(event) => {
                // Check if input is greater than 999 and reset to 999 if needed
                const value = parseInt(event.target.value);
                if (!isNaN(value) && value > 99999) {
                  setFormData({ ...formData, FarmCapacity: "99999" });
                } else {
                  setFormData({
                    ...formData,
                    FarmCapacity: event.target.value,
                  });
                }
              }}
              name="FarmCapacity"
              fullWidth
              inputProps={{
                style: { width: "100%" },
                min: 0,
                max: 100,
                onKeyDown: (event) => {
                  // Prevent alphabetic or special characters
                  const keyCode = event.keyCode || event.which;
                  const isValid =
                    (keyCode >= 48 && keyCode <= 57) || keyCode === 8; // ASCII codes for 0-9 and Backspace
                  if (!isValid) {
                    event.preventDefault();
                  }
                },
              }}
              error={Boolean(formErrors?.FarmCapacity)}
              helperText={formErrors?.FarmCapacity}
            />
          </Grid>
          <Grid item xs={9} sm={5.7} style={{ marginTop: "-3px" }}>
            <FormControl fullWidth>
              <InputLabel id="CapacityUnit" style={{ textAlign: "center" }}>
                Capacity Unit
              </InputLabel>
              <Select
                labelId="Capacity Unit"
                id="Capacity Unit"
                label="Capacity Unit"
                size="small"
                value={formData?.CapacityUnit}
                onChange={(event) =>
                  setFormData({ ...formData, CapacityUnit: event.target.value })
                }
                displayEmpty
                fullWidth
                margin="normal"
                name="Capacity Unit"
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Capacity Unit",
                }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {capacityUnitOptions?.map((options, index) => (
                  <MenuItem key={index} value={options}>
                    {options}
                  </MenuItem>
                ))}
                <MenuItem disabled value="">
                  Capacity Unit
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} marginTop="12px">
          <Grid item xs={9} sm={5.7} style={{ marginTop: "-3px" }}>
            <FormControl fullWidth>
              <InputLabel id="FarmIncharge" style={{ textAlign: "center" }}>
                Farm Incharge
              </InputLabel>
              <Select
                label="Farm Incharge"
                name="Farm Incharge"
                value={formData?.FarmIncharge}
                labelId="Farm Incharge"
                id="Farm Incharge"
                size="small"
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  console.log(selectedValue); // Check the selected value
                  setFormData({ ...formData, FarmIncharge: selectedValue });
                }}
                displayEmpty
                fullWidth
                margin="normal"
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: "130px" }, // Set the max-height for the menu
                  },
                }}
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Farm Incharge",
                  // maxLength: 255, // maximum length of 50 characters
                  //  minLength: 3,
                  // onKeyPress: handleKeyPress1,
                  // pattern: /^[a-zA-Z ]+$/,
                }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {employeeNames?.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
                <MenuItem disabled value="">
                  Farm Incharge
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={9} sm={5.7} style={{ marginTop: "-3px" }}>
            <FormControl fullWidth>
              <InputLabel id="Farm SubOrdinate" style={{ textAlign: "center" }}>
                Farm SubOrdinate
              </InputLabel>
              <Select
                labelId="Farm SubOrdinate"
                id="Farm SubOrdinate"
                label="Farm SubOrdinate"
                value={formData?.ValuesSubordinate}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    ValuesSubordinate: event.target.value,
                  })
                }
                fullWidth
                margin="normal"
                size="small"
                name="ValuesSubordinate"
                displayEmpty
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Farm SubOrdinate",
                }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: "130px" }, // Set the max-height for the menu
                  },
                }}
              >
                {employeeNames?.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
                <MenuItem disabled value="">
                  Farm Subordinate
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid> */}
        <Grid container spacing={2}>
          <Grid item xs={9} sm={5.7} style={{ marginTop: "2px" }}>
            <TextField
              label="TotalFarmer"
              name="TotalFarmer"
              value={formData?.TotalFarmer}
              type="number"
              size="small"
              onChange={(event) => {
                // Check if input is greater than 999 and reset to 999 if needed
                const value = parseInt(event.target.value);
                if (!isNaN(value) && value > 50) {
                  setFormData({ ...formData, TotalFarmer: "50" });
                } else {
                  setFormData({ ...formData, TotalFarmer: event.target.value });
                }
              }}
              onKeyPress={(event) => {
                if (event.target.value.length >= 2) {
                  event.preventDefault();
                }
              }}
              fullWidth
              margin="normal"
              inputProps={{
                style: { width: "100%" },
                min: 0,
                max: 50,
                inputMode: "numeric",
              }}
              // onKeyPress={event => {
              //   const enteredValue = parseInt(event.target.value + event.key);
              //   if (enteredValue > 20) {
              //     event.preventDefault();
              //     setFormErrors({ ...formErrors, TotalFarmer: "Please enter a number between 0 and 20" });
              //   } else {
              //     setFormErrors({ ...formErrors, TotalFarmer: "" });
              //   }
              // }}
              error={Boolean(formErrors?.TotalFarmer)}
              helperText={formErrors?.TotalFarmer || ""}
            />
          </Grid>
          <Grid item xs={9} sm={5.7} style={{ marginTop: "2px" }}>
            <TextField
              label="Description"
              name=" FarmDescription"
              value={formData?.FarmDescription}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  FarmDescription: event.target.value,
                })
              }
              size="small"
              fullWidth
              margin="normal"
              inputProps={{
                style: { width: "100%" },
                min: "0",
                max: "255",
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderStepTwo = () => {
    return (
      // <Address showMap={true}/>
      <div
        style={{
          marginLeft: "2%",
          // marginLeft: "10%",
          justifyContent: "center",
          boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
          marginTop: "5%",
        }}
      >
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          display="flex"
        >
          <Grid item xs={8} sm={5.7}>
            <FormControl fullWidth>
              <InputLabel id="Country" style={{ textAlign: "center" }}>
                Select Country
              </InputLabel>
              <Select
                labelId="Select Country"
                id="Select Country"
                label="Select Country"
                value={formData?.country}
                onChange={handleCountryChange}
                fullWidth
                required
                size="small"
                margin="normal"
                name="Select Country"
                displayEmpty
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: "130px" }, // Set the max-height for the menu
                  },
                }}
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Select Country",
                }}
              >
                {countryOptions.length === 0 ? (
                  <MenuItem disabled>No countries available</MenuItem>
                ) : (
                  countryOptions.map((country) => (
                    <MenuItem key={country.id} value={country.iso2}>
                      {country.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8} sm={5.8}>
            <FormControl fullWidth>
              <InputLabel id="State" style={{ textAlign: "center" }}>
                State
              </InputLabel>
              <Select
                labelId="State"
                id="State"
                label="State"
                required
                value={formData?.state}
                onChange={handleStateChange}
                fullWidth
                margin="normal"
                name="State"
                size="small"
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: "130px" }, // Set the max-height for the menu
                  },
                }}
                displayEmpty
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "State",
                }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {stateOptions?.length === 0 ? (
                  <MenuItem disabled>No States available</MenuItem>
                ) : (
                  stateOptions.map((state) => (
                    <MenuItem key={state.id} value={state.iso2}>
                      {state.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          display="flex"
          style={{ marginTop: "-1%" }}
        >
          <Grid item xs={8} sm={5.7}>
            <TextField
              label="Pincode"
              name="pincode"
              value={formData?.pincode}
              // readOnly
              onChange={(event) =>
                setFormData({ ...formData, pincode: event.target.value })
              }
              // onInput={(event) => {
              //   const inputPincode = event.target.value;
              //   const validatedPincode = inputPincode
              //     .replace(/\D/g, "")
              //     .substring(0, 6); // Remove non-digits and limit to 6 characters
              //     if (validatedPincode !== inputPincode) {
              //       event.target.value = validatedPincode;

              //       setFormData({ ...formData, validatedPincode });
              //     } else {

              //       setFormData({ ...formData, inputPincode });
              //     }
              // }}
              size="small"
              fullWidth
              margin="normal"
              inputProps={{
                style: { width: "100%" },
                maxLength: 6,
                pattern: "^[1-9]{6}$",
                title: "Pincode should be 6 digits long",
                inputMode: "numeric",
                // onKeyDown: (event) => {
                //   // Prevent alphabetic or special characters
                //   const key = event.key;
                //   const isValid =
                //     (key >= "0" && key <= "9") || key === "Backspace"; // Check for numeric keys or Backspace key
                //   if (!isValid) {
                //     event.preventDefault();
                //   }
                // },
              }}
              helperText={"Pincode should be 6 digit long "}
            />
          </Grid>
          <Grid item xs={8} sm={5.8} style={{ marginTop: "-4%" }}>
            {/* <TextField
              labelId="City"
              id="City"
              label="City"
              required
              value={formData?.city}
              onChange={(event) =>
                setFormData({ ...formData, city: event.target.value })
              }
              size="small"
              fullWidth
              margin="normal"
              name="City"
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              inputProps={{
                style: { width: "100%" },
              }}
            /> */}
            <FormControl fullWidth>
              <InputLabel id="City" style={{ textAlign: "center" }}>
                City
              </InputLabel>
              <Select
                labelId="City"
                id="City"
                label="City"
                required
                value={formData?.city}
                onChange={handleCityChange}
                fullWidth
                margin="normal"
                name="City"
                size="small"
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: "130px" }, // Set the max-height for the menu
                  },
                }}
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "City",
                }}
                displayEmpty
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {cityOptions?.length === 0 ? (
                  <MenuItem disabled>No Cities available</MenuItem>
                ) : (
                  cityOptions?.map((city) => (
                    <MenuItem key={city} value={city}>
                      {city}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "-0.4%" }}>
          <Grid item xs={8} sm={11.5} style={{ marginTop: "-17px" }}>
            <TextField
              label="Street"
              labelId="Street"
              name="street"
              value={formData?.street}
              fullWidth
              onChange={(event) =>
                setFormData({ ...formData, street: event.target.value })
              }
              size="small"
              margin="normal"
              inputProps={{
                style: { width: "100%" },
                min: 0,
                max: 120,
              }}
            />
          </Grid>{" "}
        </Grid>
        
      </div>
    );
  };
  const renderStepThree = () => {
    return (
      <div style={{ width: "100%" }}>
        {formData?.zones?.map((zone, index) => (
          // console.log('to check zonearea : ',formData.zones[index].ZoneArea),
          <div
            key={index}
            style={{
              marginLeft: "-4%",
            }}
          >
            <div
              style={{
                width: "9%",
                color: " #3da58a",
                fontSize: "18px",
                marginTop: "5%",

                alignItems: "center",
                marginLeft: "48%",
              }}
            >
              <Typography variant="h3" color={colors.green.DEFAULT}>
                Zone{index + 1}
              </Typography>
            </div>
            <div
              style={{
                width: "90%",
                height: "100%",
                marginLeft: "6%",
                justifyContent: "center",
                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
                marginTop: "1%",
                border: "1px solid #3da58a",
                padding: "18px",
                backgroundColor: "rgb(248 249 245)",
              }}
            >
              <Grid
                container
                style={{
                  marginLeft: "7.6%",
                  marginRight: "3%",
                  marginTop: "1%",
                }}
              >
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  display="flex"
                  // style={{ marginTop: "10px" }}
                >
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="ZoneName"
                      name="zoneName"
                      value={formData?.zoneName}
                      required
                      size="small"
                      fullWidth
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                        min: 0,
                        max: 30,
                      }}
                      onChange={(event) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zones: prevFormData?.zones?.map((zone, i) =>
                            i === index
                              ? { ...zone, zoneName: event.target.value }
                              : zone
                          ),
                        }))
                      }
                      error={errors[`zoneName_${index}`] !== undefined}
                      helperText={errors[`zoneName_${index}`]}
                    />
                  </Grid>

                  {/* <Grid container spacing={0} > */}
                  <Grid item xs={9} sm={3} sx={{ marginTop: "10px" }}>
                    <TextField
                      label="Zone Area"
                      value={formData?.zones[index]?.zoneArea}
                      type="number"
                      name="zoneArea"
                      fullWidth
                      size="small"
                      // pattern="[0-9]*[.]?[0-9]*" // Updated pattern to allow decimal point
                      // onKeyPress={(event) => {
                      //   const keyCode = event.keyCode || event.which;
                      //   const keyValue = String.fromCharCode(keyCode);
                      //   if (/\D/.test(keyValue) && keyCode !== 8 && keyValue !== '.') {
                      //     event.preventDefault();
                      //   }}}
                      onChange={(event) => {
                        // Check if input is greater than 99999 and reset to 99999 if needed
                        const value = parseInt(
                          event.target.value.replace(",", "")
                        ); //to parse integer value
                        console.log("Input value:", event.target.value);
                        console.log("Parsed value:", value);

                        if (!isNaN(value) && value > 99999) {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            zones: prevFormData?.zones?.map((zone, i) => {
                              console.log("Zone before update:", zone);
                              const updatedZone =
                                i === index
                                  ? { ...zone, zoneArea: "99999" }
                                  : zone;
                              console.log("Zone after update:", updatedZone);
                              return updatedZone;
                            }),
                          }));
                        } else {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            zones: prevFormData?.zones?.map((zone, i) => {
                              console.log("Zone before update:", zone);
                              const updatedZone =
                                i === index
                                  ? { ...zone, zoneArea: event.target.value }
                                  : zone;
                              console.log("Zone after update:", updatedZone);
                              return updatedZone;
                            }),
                          }));
                        }
                      }}
                      error={
                        !isZoneAreaValid(index) ||
                        isZoneAreaGreaterThanFarmArea(index) ||
                        formData?.zones[index]?.zoneArea > getRemainingArea()
                      }
                      helperText={
                        formData?.zones[index]?.zoneArea > getRemainingArea()
                          ? ""
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={9} sm={3} sx={{ marginTop: "10px" }}>
                    <FormControl fullWidth>
                      <InputLabel id="AreaUnit">Area Unit</InputLabel>
                      <Select
                        labelId="AreaUnit"
                        id="AreaUnit"
                        label="AreaUnit"
                        value={formData?.zones[index]?.ZoneAreaUnit}
                        onChange={(event) => {
                          const value = event.target.value;
                          console.log("Area unit value ", value);
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            zones: prevFormData?.zones?.map((zone, i) => {
                              const updatedZone =
                                i === index
                                  ? { ...zone, ZoneAreaUnit: value }
                                  : zone;
                              return updatedZone;
                            }),
                          }));
                        }}
                        fullWidth
                        size="small"
                        margin="normal"
                        displayEmpty
                        name="AreaUnit"
                        inputProps={{
                          style: { width: "100%" },
                          "aria-label": "Area Unit",
                        }}
                        startAdornment={
                          <InputAdornment position="start"></InputAdornment>
                        }
                      >
                        {units?.map((unit) => (
                          <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                          </MenuItem>
                        ))}
                        <MenuItem disabled value="">
                          Area Unit
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={1}>
                  <Grid item xs={9} sm={5}>
                    <TextField
                      label="Start Location"
                      name="locationStart"
                      value={formData?.locationStart}
                      readOnly
                      onChange={(event) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zones: prevFormData?.zones?.map((zone, i) =>
                            i === index
                              ? { ...zone, locationStart: event.target.value }
                              : zone
                          ),
                        }))
                      }
                      size="small"
                      fullWidth
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                        min: 0,
                        max: 100,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={5}>
                    <TextField
                      label="End Location"
                      name="locationEnd"
                      value={formData?.locationEnd}
                      readOnly
                      size="small"
                      onChange={(event) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zones: prevFormData?.zones?.map((zone, i) =>
                            i === index
                              ? { ...zone, locationEnd: event.target.value }
                              : zone
                          ),
                        }))
                      }
                      fullWidth
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                        min: 0,
                        max: 100,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={9} sm={5} sx={{ marginTop: "15px" }}>
                    <TextField
                      label="Zone Capacity"
                      value={formData?.zones[index]?.zoneCapacity}
                      inputMode="numeric"
                      name="zoneCapacity"
                      fullWidth
                      type="number"
                      size="small"
                      pattern="[0-9]*"
                      onKeyPress={(event) => {
                        const keyCode = event.keyCode || event.which;
                        const keyValue = String.fromCharCode(keyCode);
                        if (/\D/.test(keyValue) && keyCode !== 8) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        // Check if input is greater than 99999 and reset to 99999 if needed
                        const value = parseInt(
                          event.target.value.replace(",", "")
                        ); //to parse integer value
                        console.log("Input value:", event.target.value);
                        console.log("Parsed value:", value);
                        if (!isNaN(value) && value > 99999) {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            zones: prevFormData?.zones?.map((zone, i) => {
                              console.log("Zone before update:", zone);
                              const updatedZone =
                                i === index
                                  ? { ...zone, zoneCapacity: "99999" }
                                  : zone;
                              console.log("Zone after update:", updatedZone);
                              return updatedZone;
                            }),
                          }));
                        } else {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            zones: prevFormData?.zones?.map((zone, i) => {
                              console.log("Zone before update:", zone);
                              const updatedZone =
                                i === index
                                  ? {
                                      ...zone,
                                      zoneCapacity: event.target.value,
                                    }
                                  : zone;
                              console.log("Zone after update:", updatedZone);
                              return updatedZone;
                            }),
                          }));
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={9} sm={5} sx={{ marginTop: "15px" }}>
                    <FormControl fullWidth>
                      <InputLabel>Capacity Unit</InputLabel>
                      <Select
                        labelId="Capacity Unit"
                        id="Capacity Unit"
                        label="Capacity Unit"
                        value={formData?.CapacityUnit}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            CapacityUnit: event.target.value,
                          })
                        }
                        size="small"
                        fullWidth
                        margin="normal"
                        displayEmpty
                        inputProps={{
                          style: { width: "100%" },
                          "aria-label": "Capacity Unit",
                        }}
                        startAdornment={
                          <InputAdornment position="start"></InputAdornment>
                        }
                      >
                        {capacityUnitOptions?.map((options, index) => (
                          <MenuItem key={index} value={options}>
                            {options}
                          </MenuItem>
                        ))}
                        <MenuItem disabled value="">
                          Select Capacity Unit
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={9} sm={10}>
                    <TextField
                      label="Description"
                      name=" zoneDescription"
                      value={formData?.zoneDescription}
                      readOnly
                      size="small"
                      onChange={(event) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zones: prevFormData?.zones?.map((zone, i) =>
                            i === index
                              ? { ...zone, zoneDescription: event.target.value }
                              : zone
                          ),
                        }))
                      }
                      fullWidth
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                        min: 0,
                        max: 125,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {formData?.zones?.length >= 0 && (
                <Button
                  type="button"
                  varaint="outlined"
                  style={{
                    float: "right",
                    marginTop: "-3%",
                    marginRight: "-4%",
                  }}
                  onClick={() => handleZoneRemove(index)}
                >
                  <Typography variant="h6" color={colors.green.DEFAULT}>
                    Cancel
                  </Typography>
                </Button>
              )}
            </div>
          </div>
        ))}
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginLeft: "40%", marginRight: "4%", marginTop: "1%" }}
        >
          <Button
            sx={{
              borderRadius: "4%",
              borderStyle: "dotted",
              borderWidth: "2px",
            }}
            onClick={handleZoneAdd}
            startIcon={
              <AddCircleOutlineOutlinedIcon
                style={{ color: colors.green.DEFAULT }}
              />
            }
          >
            <Typography variant="h5" color={colors.green.DEFAULT}>
              Add Zone
            </Typography>
          </Button>
        </Grid>
      </div>
    );
  };
  const renderStepFour = () => {
    console.log("next button of render step four called ");
    return (
      <div
        style={{ marginLeft: "2%", marginRight: "4.4%", marginBottom: "3%" }}
      >
        <Card>
          <CardHeader
            title="Confirm Details"
            sx={{ marginLeft: "40%", marginRight: "10%" }}
          />
          <CardContent>
            <Card
              style={{
                height: "auto",
                marginBottom: "16px",
                // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <CardContent>
                <List>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      FarmName&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.FarmName}
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Farm Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.FarmType}
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      FarmArea&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.FarmArea}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>

                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      ( in {formData?.AreaUnit})
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 27%, #f5faf8 27%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Farm
                      Capacity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.FarmCapacity}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>

                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      (in&nbsp;&nbsp; {formData?.CapacityUnit})
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 28%, #f5faf8 28%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Growing Media &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.GrowingMedia}
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 29%, #f5faf8 29%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Farm Incharge&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.FarmIncharge}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Subordinate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.ValuesSubordinate}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Total Farmer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.TotalFarmer}
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Farm Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.street}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{formData?.city}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {formData?.state}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      {formData?.country}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {formData?.latitude}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {formData?.longitude}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </ListItem>
                  <ListItem
                    style={{
                      marginBottom: "5px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Description &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formData?.FarmDescription > 20
                        ? "${formData?.FarmDescription.slice(0, 20)}..."
                        : formData?.FarmDescription}
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
            <Typography>Zone Data</Typography>
            <Grid container spacing={2}>
              {formData?.zones?.length > 0 &&
                formData?.zones?.map((zone, index) => (
                  <Grid item xs={12} sm={12} key={index}>
                    <Grid item>
                      <Card
                        style={{
                          width: "100%",
                          height: "auto",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <CardHeader title={`Zone ${index + 1}`} />
                        <CardContent>
                          <List>
                            <ListItem
                              style={{
                                marginBottom: "5px",
                                background:
                                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                {zone?.zoneName}
                              </Typography>
                            </ListItem>
                            <ListItem
                              style={{
                                marginBottom: "5px",
                                background:
                                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                Area&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                {zone?.zoneArea}
                                &nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                (in {zone?.ZoneAreaUnit})
                              </Typography>
                            </ListItem>
                            <ListItem
                              style={{
                                marginBottom: "5px",
                                background:
                                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 24%, #f5faf8 24%, #f5faf8 100%)",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                StartLocation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                {zone?.locationStart}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                                sx={{
                                  backgroundColor:
                                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 45%, #f5faf8 45%, #f5faf8 100%)",
                                }}
                              >
                                EndLocation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                {zone?.locationEnd}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                            </ListItem>
                            <ListItem
                              style={{
                                marginBottom: "5px",
                                background:
                                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                Capacity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                {zone?.zoneCapacity}&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                ( in {formData?.CapacityUnit})
                              </Typography>
                            </ListItem>
                            <ListItem
                              style={{
                                marginBottom: "5px",
                                background:
                                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                              >
                                {zone?.zoneDescription > 20
                                  ? "${zone?.zoneDescription.slice(0, 20)}..."
                                  : zone?.zoneDescription}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Typography>
                            </ListItem>
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ))}
              {formData?.zones?.length % 2 !== 0 && (
                <Grid item xs={12} sm={6}></Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  };
  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return renderStepOne();
      case 2:
        return renderStepTwo();
      case 3:
        return renderStepThree();
      case 4:
        return renderStepFour();
      default:
        return null;
    }
  };
  return (
    <>
      <Grid>
        <Slide direction="left" in={errorOpen}>
          <Alert
            severity={errorOpen ? "error" : "success"}
            onClose={() =>
              errorOpen ? setErrorOpen(false) : setSuccessOpen(false)
            }
            style={{
              position: "fixed",
              top: "8px",
              right: errorOpen ? "2px" : "-100%", // Hide it initially outside the window
              zIndex: 1000,
              transition: "right 0.3s ease-in-out", // Add a smooth transition effect
            }}
          >
            <AlertTitle>{errorOpen ? "Error" : "Success"}</AlertTitle>
            {errorOpen
              ? "Please fill out all the required fields before proceeding."
              : "Form submitted successfully!"}
          </Alert>
        </Slide>
        <form>
          <Stepper activeStep={currentStep - 1} alternativeLabel>
            {steps?.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderSwitch(currentStep)}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "2%",
            }}
          >
            {currentStep !== 1 && (
              <Button
                sx={{ marginRight: "auto" }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
              >
                <Typography variant="h5" color={colors.green.DEFAULT}>
                  Previous
                </Typography>
              </Button>
            )}
            {currentStep !== 4 ? (
              <Button
                //  color="colors.green.DEFAULT"
                sx={{
                  marginRight: "5.1%",
                  backgroundColor: colors.green.DEFAULT,
                }}
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
              >
                <Typography variant="h5" color={colors.white.DEFAULT}>
                  Next
                </Typography>
              </Button>
            ) : (
              <Button
                sx={{
                  marginRight: "4%",
                  backgroundColor: colors.green.DEFAULT,
                }}
                variant="contained"
                onClick={handleSave}
              >
                <Typography variant="h5" color={colors.white.DEFAULT}>
                  Submit
                </Typography>
              </Button>
            )}
          </div>
        </form>
      </Grid>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};
export default FarmConfig;
