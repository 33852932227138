import React, { useState, useEffect } from "react";
import { faPlantWilt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiOutlineCloudUpload } from "react-icons/ai";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Stack,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Tooltip } from "@mui/material";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";

import {
  BASE_URL,
  API_ENDPOINTS,
} from "../../../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const ActionDamage = ({
  rows,
  noOfPlant,
  date_of_sow,
  handlePageRefresh,
}) => {
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const [preFetchedData, setPreFetchedData] = useState({});
  const [formData, setFormData] = useState({
    farm_name: rows?.original?.farm_name,
    farm_zone: rows?.original?.zone_name,
    batch: rows?.original?.batch,
    old_no_of_plant: rows?.original?.no_of_plant,
    no_of_plants: rows?.original?.no_of_plant,
    id: rows?.original?.id,
    crop_name: rows?.original?.crop_name,
    crop_variety_name: rows?.original?.crop_variety_name,
    crop_type: rows?.original?.crop_type,
    date_of_sow: rows?.original?.date_of_sow,
    dump_weight: rows?.original?.dump_weight,
    exp_date_of_harvest: rows?.original?.exp_date_of_harvest,
    farm_id: rows?.original?.farm_id_fk,
    crop_id: rows?.original?.crop_id_fk,
    crop_variety_id: rows?.original?.crop_variety_id_fk,
    farm_zone_id: rows?.original?.farm_zone_id_fk,
    selected_file: "",
  });

  useEffect(() => {
    setFormData({
      farm_name: rows?.original?.farm_name,
      farm_zone: rows?.original?.zone_name,
      batch: rows?.original?.batch,
      old_no_of_plant: rows?.original?.no_of_plant,
      no_of_plants: rows?.original?.no_of_plant,
      id: rows?.original?.id,
      crop_name: rows?.original?.crop_name,
      crop_variety_name: rows?.original?.crop_variety_name,
      crop_type: rows?.original?.crop_type,
      date_of_sow: rows?.original?.date_of_sow,
      dump_weight: rows?.original?.dump_weight,
      exp_date_of_harvest: rows?.original?.exp_date_of_harvest,
      farm_id: rows?.original?.farm_id_fk,
      crop_id: rows?.original?.crop_id_fk,
      crop_variety_id: rows?.original?.crop_variety_id_fk,
      farm_zone_id: rows?.original?.farm_zone_id_fk,
      selected_file: "",
    });
  }, [rows]);

  const date_of_sowing = moment(date_of_sow).format("YYYY-MM-DD");

  const MAX_PLANTS = noOfPlant;
  function validateNoOfPlants(value) {
    if (value > MAX_PLANTS) {
      return `Number of plants for damage cannot exceed ${MAX_PLANTS}.`;
    }
    return null;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const noOfPlantsError = validateNoOfPlants(formData?.no_of_plants);
    if (noOfPlantsError) {
      setFormErrors({ ...formErrors, no_of_plants: noOfPlantsError });
      return;
    }
    try {
      const response = await axiosInstance.put(
        API_ENDPOINTS.PUT_CROPMANAGEMENT_DAMAGE,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("responseeeeeeeeee", response);
      if (response.status >= 200 && response.status < 305) {
        console.log("Inventory damage record created successfully");
        setOpen(false);
        if (typeof handlePageRefresh === "function") {
          handlePageRefresh();
        }
      } else {
        console.log("Failed to create inventory damage record");
      }
    } catch (error) {
      console.log(
        "An error occurred while creating the inventory damage record",
        error
      );
    }
    setOpen(false);
  };

  const handleFormChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>
      <button
        onClick={handleClickOpen}
        style={{
          borderRadius: "12px",
          backgroundColor: "white",
          border: "0.5px solid #7d7d7d",
          width: "80px",
          display: "flex",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faPlantWilt}
          size="1.4px"
          // width="16px"

          style={{ color: "#000000", fontSize: "16px" }}
        />
        Damage
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="90%"
        // maxHeight="100px"
      >
        <DialogTitle>
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Damage Inventory
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={9} sm={2}>
                <TextField
                  label="From Farm"
                  name="farm_name"
                  value={formData?.farm_name}
                  required
                  readOnly
                  size="small"
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                  }}
                />
              </Grid>

              <Grid item xs={9} sm={2}>
                <TextField
                  label="From Zone"
                  name="farm_zone"
                  value={formData?.farm_zone}
                  required
                  size="small"
                  readOnly
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                  }}
                />
              </Grid>

              <Grid item xs={9} sm={2}>
                <TextField
                  label="Batch Id"
                  name="batch"
                  value={formData?.batch}
                  required
                  readOnly
                  size="small"
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="No. Of Plants"
                  name="no_of_plants"
                  type="number"
                  value={formData?.no_of_plants}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                  margin="normal"
                  error={Boolean(formErrors.no_of_plants)}
                  helperText={formErrors.no_of_plants}
                  inputProps={{
                    style: { width: "100%" },
                    max: MAX_PLANTS,
                    min: 1,
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="Weight(Kg) "
                  name="dump_weight"
                  type="number"
                  value={formData?.dump_weight}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    max: 9999,
                    min: 0,
                    style: { width: "100%" },
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="Date Of Dump"
                  name="date_of_dump"
                  type="date"
                  value={formData?.date_of_dump}
                  onChange={handleFormChange}
                  required
                  size="small"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    // min: date_of_sowing,
                    // max: new Date().toISOString().split("T")[0],
                    style: { width: "100%" },
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="Start Location"
                  name="starting_location"
                  value={formData?.starting_location}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="End Location"
                  name="ending_location"
                  value={formData?.ending_location}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              {/* <Grid item xs={9} sm={2}>
                <Tooltip title="Upload Damage Crop Image">
                  <TextField
                    // label="selectedFile"
                    name="Upload Photo"
                    type="file"
                    value={formData?.selected_file}
                    onChange={handleFormChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AiOutlineCloudUpload size={20} />
                        </InputAdornment>
                      ),
                      style: {
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%",
                      },
                    }}
                  />
                </Tooltip>
              </Grid> */}
              <Grid item xs={9} sm={4}>
                <TextField
                  label="Note"
                  name="note"
                  value={formData?.note}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              {/* <Stack
                spacing={1}
                direction="row"
                style={{ height: "35px", marginTop: "2%", marginLeft: "84%" }}
              >
                <Button
                  type="submit"
                  sx={{
                    marginRight: "2%",
                    backgroundColor: colors.green.DEFAULT,
                  }}
                  variant="contained"
                >
                  <Typography variant="h6" color={colors.white.DEFAULT}>
                    Submit
                  </Typography>
                </Button>
                <Button onClick={handleClose} variant="outlined">
                  <Typography variant="h6" color={colors.green.DEFAULT}>
                    Cancel
                  </Typography>
                </Button>
              </Stack> */}
              <Grid
                container
                spacing={2}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    style={{
                      height: "30px",
                      marginTop: "2%",
                      marginLeft: "86%",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ backgroundColor: colors.green.DEFAULT }}
                    >
                      <Typography variant="h7" color={colors.white.DEFAULT}>
                        Submit
                      </Typography>
                    </Button>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      style={{ marginLeft: "15px" }}
                    >
                      <Typography variant="h7" color={colors.green.DEFAULT}>
                        Cancel
                      </Typography>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ActionDamage;
