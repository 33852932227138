import React, { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { Link } from "react-router-dom";
import SaveAlert from "../../src/SaveAlert";
import ApiErrorAlert from "../../src/ApiErrorAlert";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DescriptionIcon from "@mui/icons-material/Description";
import InventoryIcon from "@mui/icons-material/Inventory";
import logoClosed from "../../src/Assets/Famboremovebg.png";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import logoOpen from "../../src/Assets/NewLogo.png";
import { useNavigate } from "react-router-dom";
import Configuration from "../../src/Assets/Config.png";
import ExitToAppSharpIcon from "@mui/icons-material/ExitToAppSharp";
import "./SideBarAtt.css";

const SideBarAtt = (props) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isFMSDialogOpen, setIsFMSDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleFMSLogout = () => {
    setIsFMSDialogOpen(false);
    localStorage.clear();
    navigate("/attendance");
  };

  const handleMouseEnter = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  const handleFMSLogoutConfirmDialog = () => {
    setIsFMSDialogOpen(true);
  };

  const handleFMSDialogClose = () => {
    setIsFMSDialogOpen(false);
  };
  const handleMouseLeave = (event) => {
    if (event.relatedTarget && event.relatedTarget.classList) {
      const isHoveringSidebar =
        event.relatedTarget?.classList?.contains("sidebaratt");
      if (!isHoveringSidebar && isExpanded) {
        setIsExpanded(false);
      }
    }
  };
  return (
    <>
      <Box
        className={`sidebaratt ${isExpanded ? "expanded" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="sidebaratt-logo">
          {isExpanded ? (
            <img
              src={logoOpen}
              alt="logo"
              className="logo-image logo-open"
              style={{ filter: "invert(100%) saturate(0) brightness(2000%)" }}
            />
          ) : (
            <img
              src={logoClosed}
              alt="logo"
              className="logo-image logo-closed"
              style={{ filter: "invert(100%) saturate(0) brightness(2000%)" }}
            />
          )}
          {isExpanded && <div className="green-line" />}
        </div>
        <Divider
          // variant="middle"
          // fullwidth
          sx={{
            marginTop: "2px",
            marginBottom: "15px",
            marginRight: "6px",
            marginLeft: "5px",
            backgroundColor: "#ffffff",
            // marginRight: "30px",
            height: "2px",
            zIndex: "9999",
          }}
        />

        <div className="sidebaratt-icons">
          <MenuItem
            to="/attendance/home"
            icon={
              <HomeIcon
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Home"
            isExpanded={isExpanded}
          />
          <MenuItem
            to="/attendance/user"
            icon={
              <PeopleAltIcon
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="User"
            isExpanded={isExpanded}
          />
          <MenuItem
            to="/attendance/report"
            icon={
                <img
                  src={Configuration}
                  className={`recipeimg ${isExpanded ? "expanded" : ""}`}
                  alt="Recipe"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginBottom: "20px",
                  }}
                />
              }
            text="Configuration"
            isExpanded={isExpanded}
          />

          
          
          <MenuItem
            icon={
              <ExitToAppSharpIcon
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
                onClick={handleFMSLogoutConfirmDialog}
              />
            }
            text="Logout"
            isExpanded={isExpanded}
            onClick={handleFMSLogoutConfirmDialog}
          />
        </div>

        <Dialog open={isFMSDialogOpen} onClose={handleFMSDialogClose}>
          <DialogTitle>Logout Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to logout?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFMSDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleFMSLogout} color="primary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};

const MenuItem = ({ to, icon, text, isExpanded, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {};

  return (
    <Link to={to} onClick={handleClick}>
      <div
        className={`sidebaratt-icon ${isHovered ? "active" : ""}`}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <div
          className="sidebaratt-icon-text"
          style={{ display: "flex", alignItems: "center" }}
        >
          {isExpanded && (
            <>
              <div className="icon-wrapper">
                <div style={{ marginLeft: "6px" }}>{icon}</div>

                <Typography
                  className="icon-name"
                  style={{
                    fontSize: "15px",
                    textDecoration: "none",
                    color: "#ffffff",
                    marginLeft: "5px",
                    marginTop: "-20px",
                  }}
                >
                  {text}
                </Typography>
              </div>
            </>
          )}
        </div>
        {!isExpanded && icon}
      </div>
    </Link>
  );
};

export default SideBarAtt;
