import { Box, useTheme, Grid, Stack } from "@mui/material";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import StatBox from "../../Components/StatBox";
import Weather from "../../Components/StaticDashComponent/WeatherForecast/Weather";
import Map from "../../Components/StaticDashComponent/GeoMap/Map";
import TabComponent from "../../Components/StaticDashComponent/CropMonitor/TabComponent";
import { BASE_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import RecipeDashboard from "../../Components/StaticDashComponent/Iot/RecipeDashboard";

import ApiErrorAlert from "../../ApiErrorAlert";
import SaveAlert from "../../SaveAlert";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const Dashboard = () => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const currentTimestamp = new Date();
  const formattedDate = currentTimestamp.toLocaleDateString();
  const formattedTime = currentTimestamp.toLocaleTimeString();
  const [farmsCount, setFarmsCount] = useState(0);
  const [cropCount, setCropCount] = useState(0);
  const [harvest, setHarvest] = useState(0);
  const [income, setIncome] = useState(0);
  const [expense, setExpense] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const count = data?.farmdetail?.rowCount;
      const expense = data?.farmoverview?.monthlyexpense;
      const income = data?.farmoverview?.monthlyincome;
      const monthlyHarvest = data?.farmoverview?.monthlyharvest;
      const formattedHarvest = monthlyHarvest
        ? parseFloat(monthlyHarvest).toFixed(1)
        : "0";
      setHarvest(formattedHarvest);
      setFarmsCount(count);
      setIncome(income);
      setExpense(expense);

      const crop = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_CROP,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const cropdata = await crop?.data;
      const cropcount1 = cropdata?.rowCount;

      setCropCount(cropcount1);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching farm names:", error);
    }
  };

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="150px"
        gap="20px"
        borderColor={colors.black[800]}
        boxShadow={colors.black[900]}
        height="100vh" // Set the height to 100% of viewport height
      >
        <Box
          gridColumn="span 8"
          backgroundColor={colors.white[50]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          borderRadius="8px"
        >
          <Weather />
        </Box>

        <Box
          gridColumn="span 4"
          backgroundColor={colors.white[50]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          borderRadius="8px"
          // sx={{margin:"5px"}}
        >
          <Map />
        </Box>

        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.white[50]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="389px"
          borderRadius="8px"
        >
          <TabComponent />
        </Box>

        {userDetails?.home_b_sensorBox === false ? (
          <>
            <Box
              backgroundColor={colors.white[50]}
              display="flex"
              gridColumn="span 3"
              alignItems="center"
              justifyContent="center"
              height="180px"
              borderRadius="8px"
              marginTop="-23%"
            >
              <StatBox
                title="Total Crops"
                subtitle=" "
                value={cropCount || "0"}
              />
            </Box>

            <Box
              backgroundColor={colors.white[50]}
              display="flex"
              gridColumn="span 3"
              alignItems="center"
              justifyContent="center"
              height="180px"
              borderRadius="8px"
              marginTop="-23%"
            >
              <StatBox
                title="Avg. Harvesting"
                value={harvest || "0"}
                subtitle="(for last 30 days)"
              />
            </Box>

            <Box
              backgroundColor={colors.white[50]}
              display="flex"
              gridColumn="span 3"
              alignItems="center"
              justifyContent="center"
              height="180px"
              borderRadius="8px"
              marginTop="-23%"
            >
              <StatBox
                title="Monthly Income"
                subtitle=" "
                value={income || "0"}
              />
            </Box>

            <Box
              backgroundColor={colors.white[50]}
              display="flex"
              gridColumn="span 3"
              alignItems="center"
              justifyContent="center"
              height="180px"
              borderRadius="8px"
              marginTop="-23%"
            >
              <StatBox
                title="Monthly Expense"
                subtitle=" "
                value={expense || "0"}
              />
            </Box>
          </>
        ) : (
          
          <>
            <Box
              backgroundColor={colors.white[50]}
              display="flex"
              gridColumn="span 3"
              alignItems="center"
              justifyContent="center"
              height="150px"
              borderRadius="8px"
              marginTop="-37%"
            >
              <StatBox
                title="Total Crops"
                subtitle=" "
                value={cropCount || "0"}
              />
            </Box>

            <Box
              backgroundColor={colors.white[50]}
              display="flex"
              gridColumn="span 3"
              alignItems="center"
              justifyContent="center"
              height="150px"
              borderRadius="8px"
              marginTop="-37%"
            >
              <StatBox
                title="Avg. Harvesting"
                value={harvest || "0"}
                subtitle="(for last 30 days)"
              />
            </Box>

            <Box
              backgroundColor={colors.white[50]}
              display="flex"
              gridColumn="span 3"
              alignItems="center"
              justifyContent="center"
              height="150px"
              borderRadius="8px"
              marginTop="-37%"
            >
              <StatBox
                title="Monthly Income"
                subtitle=" "
                value={income || "0"}
              />
            </Box>

            <Box
              backgroundColor={colors.white[50]}
              display="flex"
              gridColumn="span 3"
              alignItems="center"
              justifyContent="center"
              height="150px"
              borderRadius="8px"
              marginTop="-37%"
            >
              <StatBox
                title="Monthly Expense"
                subtitle=" "
                value={expense || "0"}
              />
            </Box>

            {farmsCount ? (
              <Box
                gridColumn="span 12"
                gridRow="span 3"
                backgroundColor={colors.white[50]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="10px"
                height="380px"
                borderRadius="8px"
                marginTop="-9%"
              >
                <RecipeDashboard />
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};

export default Dashboard;
