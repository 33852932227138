import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import iconImage from "../../Assets/FamboLOGO.png";
import qrCodeImage from "../../Assets/fambo_payment_QR.png";
import HindRegular from "./Hind-Regular.ttf";

Font.register({
  family: "Hind-Regular",
  src: HindRegular,
});

const styles = StyleSheet.create({
  cellItem: {
    width: "45.2%",
    fontSize: 8,
    marginLeft: "5px",
    fontFamily: "Hind-Regular",
  },
  page: {
    flexDirection: "column",
    padding: "8px",
  },
  section: {
    margin: 10,
    padding: 2,
    flexGrow: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  iconContainer: {
    width: "20%",
    textAlign: "right",
  },
  addressContainer: {
    width: "60%",
    fontSize: 10,
  },
  QRContainer: {
    width: "20%",
    textAlign: "right",
  },
  taxInvoiceContainer: {
    width: "100%",
    fontSize: 13,
  },
  icon: {
    width: 100,
    height: 60,
  },

  QR: {
    width: 90,
    height: 90,
  },
  horizontalDivider: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },
  footer: {
    borderTopColor: "black",
    borderTopWidth: 1,
    fontSize: 10,
    padding: 2,
    textAlign: "left",
  },

  billingShippingContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#f2f2f2",
  },
  totalsContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  billingShippingSection: {
    flex: 1,
    padding: 2,
  },
  billingShippingDivider: {
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  billingShippingHeader: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
  },
  bankAccountHeader: {
    fontSize: 8,
    fontWeight: "bold",
    marginBottom: 4,
  },
  totalItemHeader: {
    fontSize: 8,
    fontWeight: "bold",
    marginBottom: 6,
  },
  invoiceDetailsBoxHeader: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  billingShippingDetails: {
    fontSize: 8,
  },
  signatureDetails: {
    fontSize: 8,
    marginTop: 10,
  },
  itemsTotalDetails: {
    fontSize: 10,
    textAlign: "right",
    padding: 3,
  },
  itemsTotalAmountDetails: {
    fontSize: 10,
    textAlign: "right",
    padding: 3,
    backgroundColor: "#f2f2f2",
  },
  tableContainer: {
    marginTop: 10,
    width: "100%",
    backgroundColor: "#ffffff",
    border: 1,
  },
  tableHeader: {
    flexDirection: "row",
    fontSize: 8,
    borderBottomColor: "black",
    borderBottomWidth: 1,
    backgroundColor: "#f2f2f2",
    padding: 1,
  },
  headerCell: {
    flex: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    padding: 1,
  },
  cellSNo: {
    width: "4%",
    textAlign: "center",
    fontSize: 8,
  },
  cellItemHeader: {
    width: "46.1%",
    textAlign: "center",
    fontSize: 8,
  },
  cellQty: {
    width: "8%",
    textAlign: "center",
    fontSize: 8,
  },
  /*cellpono: {
    width: "9.9%",
    textAlign: "center",
    fontSize: 8,
  },*/
  cellRate: {
    width: "10%",
    textAlign: "center",
    fontSize: 8,
  },
  cellTax: {
    width: "4%",
    textAlign: "center",
    fontSize: 8,
  },
  cellAmount: {
    width: "10%",
    textAlign: "center",
    fontSize: 8,
  },
  cellUOM: {
    width: "8%",
    textAlign: "center",
    fontSize: 8,
  },
  salesDivider: {
    borderRightColor: "black",
    borderRightWidth: 1,
  },
});

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const epochToDDMMYYYY = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "Invalid Timestamp";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const BillingShippingBox = ({ invoiceData }) => {
  return (
    <View style={styles.billingShippingContainer}>
      <View
        style={[styles.billingShippingSection, styles.billingShippingDivider]}
      >
        <Text style={styles.billingShippingHeader}>Billing Address:</Text>
        <Text style={styles.billingShippingDetails}>
          {invoiceData?.billing_address1}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {invoiceData?.billing_city},
        </Text>
        <Text style={styles.billingShippingDetails}>
          {invoiceData?.billing_pincode}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {invoiceData?.billing_state}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {capitalizeFirstLetter(invoiceData?.billing_country)}
        </Text>
        <Text style={styles.billingShippingDetails}>
          Email: {invoiceData?.billing_email}
          {"\n"}
        </Text>
      </View>

      <View style={styles.billingShippingSection}>
        <Text style={styles.billingShippingHeader}>Shipping Address:</Text>
        <Text style={styles.billingShippingDetails}>
          {invoiceData?.shipping_address?.addressline1}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {invoiceData?.shipping_address?.city},
        </Text>
        <Text style={styles.billingShippingDetails}>
          {invoiceData?.shipping_address?.pincode}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {invoiceData?.shipping_address?.state}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {capitalizeFirstLetter(invoiceData?.shipping_address?.country)}
        </Text>
      </View>
    </View>
  );
};

const InvoiceDetailsBox = ({ invoiceData }) => {
  return (
    <View style={styles.billingShippingContainer}>
      <View
        style={[styles.billingShippingSection, styles.billingShippingDivider]}
      >
        <Text style={styles.billingShippingDetails}>
          Customer Name: {capitalizeFirstLetter(invoiceData?.customer_name)}
          {"\n"}
          Invoice No: {invoiceData?.invoice_no}
          {"\n"}
          Order Date:{epochToDDMMYYYY(invoiceData?.order_date)}
          {"\n"}
          Invoice Date: {epochToDDMMYYYY(invoiceData?.invoice_date)}
          {"\n"}
          PO No:  {invoiceData?.po_no}
          {"\n"}
        </Text>
      </View>

      <View style={styles.billingShippingSection}>
        <View style={styles.billingShippingSection}>
          <Text style={styles.billingShippingDetails}>
            Customer GST: {invoiceData?.customer_gst}
            {"\n"}
            Payment Terms: {invoiceData?.payment_terms}
            {"\n"}
            Remark: {invoiceData?.remark}
          </Text>
        </View>
      </View>
    </View>
  );
};

const ItemsTotalBox = ({ invoiceData }) => {
  return (
    <View style={styles.totalsContainer}>
    
      <View
        style={[styles.billingShippingSection, styles.billingShippingDivider]}
      >

        <Text style={styles.totalItemHeader}>Items in Total : {invoiceData?.total_qty}</Text>
        <Text style={styles.bankAccountHeader}>Bank Account Details:</Text>
        <Text style={styles.billingShippingDetails}>
          Account Holder: Fambo Innovation Pvt. Ltd.
        </Text>
        <Text style={styles.billingShippingDetails}>
          Account No.: 50200067028285
        </Text>
        <Text style={styles.billingShippingDetails}>Account Type: Current</Text>
        <Text style={styles.billingShippingDetails}>
          Branch IFSC: HDFC0000329
        </Text>
        <Text style={styles.billingShippingDetails}>
          Branch Name: Vasundhara Enclave, New Delhi
        </Text>
        {/* <Text style={styles.signatureDetails}>Authorised Signatory</Text> */}
      </View>
      <View style={styles.billingShippingSection}>
        <Text style={styles.itemsTotalDetails}>
          Sub Total: {invoiceData?.sub_total}
        </Text>
        <Text style={styles.itemsTotalDetails}>
          Total Tax: {invoiceData?.total_tax}
        </Text>
        <Text style={styles.itemsTotalAmountDetails}>
          Total Amount: {invoiceData?.total_amount}
        </Text>
      </View>
    </View>
  );
};

const SaleItemsTable = ({ saleItems }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.tableHeader}>
        <Text style={[styles.cellSNo, styles.salesDivider]}>S.No.</Text>

        <Text style={[styles.cellItemHeader, styles.salesDivider]}>Item</Text>

        <Text style={[styles.cellQty, styles.salesDivider]}>Qty</Text>
        <Text style={[styles.cellUOM, styles.salesDivider]}>UOM</Text>
        <Text style={[styles.cellRate, styles.salesDivider]}>Rate</Text>
        <Text style={[styles.cellTax, styles.salesDivider]}>Tax</Text>
        <Text style={[styles.headerCell, styles.cellAmount]}>Amount</Text>
      </View>
      {saleItems?.map((item, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={[styles.cellSNo, styles.salesDivider]}>{index + 1}</Text>

          <Text style={[styles.cellItem, styles.salesDivider]}>
            {capitalizeFirstLetter(item?.item_name)} ({item?.item_code})
          </Text>
          
          <Text style={[styles.cellQty, styles.salesDivider]}>{item?.qty}</Text>
          <Text style={[styles.cellUOM, styles.salesDivider]}>{item?.uom}</Text>
          <Text style={[styles.cellRate, styles.salesDivider]}>
            {item?.rate}
          </Text>
          <Text style={[styles.cellTax, styles.salesDivider]}>{item?.tax}</Text>
          <Text style={styles.cellAmount}>{item?.line_amount}</Text>
        </View>
      ))}
    </View>
  );
};

const InvoiceFile = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.header}>
          <View style={styles.iconContainer}>
            <Image style={styles.icon} src={iconImage} />
          </View>
          <View style={styles.addressContainer}>
            <Text>
              Fambo Innovation Pvt. Ltd. Plot No. C-1 Urbtech NPX Tower,Sector-153 
Noida , Gautam buddha Nagar,Uttar Pradesh- 201310, India
            </Text>
            
            <Text>Email: care@fambo.in</Text>
            <Text>Contact : 088004 98719 </Text>
            <Text>GSTIN : 09AAECF9571A1ZL </Text>
          </View>
          <View style={styles.QRContainer}>
            <Image style={styles.QR} src={qrCodeImage} />
          </View>
        </View>
        <View style={styles.taxInvoiceContainer}>
          <Text>TAX INVOICE</Text>
        </View>
        <View style={styles.horizontalDivider} />
        <InvoiceDetailsBox invoiceData={invoiceData} />
        <View style={styles.horizontalDivider} />
        <BillingShippingBox invoiceData={invoiceData} />
        <View style={styles.horizontalDivider} />
        <SaleItemsTable saleItems={invoiceData?.sale_items} />
        <ItemsTotalBox invoiceData={invoiceData} />
        <View style={styles.horizontalDivider} />
      </View>
    </Page>
  </Document>
);

export default InvoiceFile;
