import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Slide,
  Button,
  Alert,
  AlertTitle,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  Card,
  CardHeader,
  CardContent,
  DialogContent,
  DialogActions,
  InputAdornment,
  MenuItem,
  InputLabel,
  Select,
  Tooltip,
  FormControl,
} from "@mui/material";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Stepper, Step, StepLabel } from "@mui/material";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import "./FarmerConfig.css";
import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";

import ApiErrorAlert from "../../../../ApiErrorAlert";
import SaveAlert from "../../../../SaveAlert";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const FarmerConfig = () => {
  const steps = ["Farmer Configuration", "Confirm Details"];
  const [farmData, setFarmData] = useState(null);
  const [selectedFarm, setSelectedFarm] = useState("");
  const [farmOptions, setFarmOptions] = useState([]);
  const [farm, setFarm] = useState();
  const [farmId, setFarmId] = useState();

  const fetchFarmData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      setFarmData(data?.farmdetail?.rows);
      setFarmOptions(farmData);
      setSelectedFarm(farmData[0]);
    } catch (error) {
      // setApiErrorOpen(true);
      console.log("Error fetching farm data:", error);
    }
    console.log("Names of Farm available", farmData);
  };

  const handleMyFarmChange = async (event) => {
    const selectedFarm = event.target.value;
    setFarm(selectedFarm);
    const selectedFarmObject = farmData?.find(
      (farm) => farm.farm_name === selectedFarm
    );
    const selectedFarmId = selectedFarmObject.farm_id;
    setFarmId(selectedFarmId);
  };

  useEffect(() => {
    fetchFarmData();
  }, []);
  const [formValues, setFormValues] = useState({
    fname: "" || null,
    emailId: "" || null,
    age: "" || null,
    dateOfBirth: "" || null,
    gender: "M" || null,
    mobileNo: "" || null,
    // address:""|| null,
    // designation: "" || null,
    department: "" || null,
    description: "" || null,
    selectedFile: "" || null,
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const next = () => {
    let isValid = false;
    if (renderStepOne) {
      isValid = validatestepone();
      if (!isValid) {
        setErrorOpen(true);
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const previous = () => {
    setActiveStep(activeStep - 1);
  };
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // State to control confirmation dialog visibility
  const handleConfirmSubmit = () => {
    setConfirmDialogOpen(true);
    handleSubmit(); // Open the confirmation dialog
  };
  const handleFinalSubmit = () => {
    setConfirmDialogOpen(false); // Close the confirmation dialog
    handleSubmit(); // Call the actual form submission function
  };
  const validatestepone = () => {
    let isValid = true;
    let errors = {};

    // Validate name
    if (!formValues?.fname) {
      errors.fname = "Required";
      isValid = false;
    }
    // Validate phone number
    if (!formValues?.mobileNo) {
      errors.mobileNo = "Required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formValues?.mobileNo)) {
      errors.mobileNo = "Invalid";
      isValid = false;
    }

    setErrors(errors);
    setFormValues({ ...formValues, errors });
    return isValid;
  };
  const handleMobileNoKeyPress = (event) => {
    if (
      (event.which !== 8 && event.which !== 0 && event.which < 48) ||
      event.which > 57
    ) {
      event.preventDefault();
    }
  };
  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 31 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122) &&
      charCode != 32
    ) {
      event.preventDefault();
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // perform validation based on the input field name
    switch (name) {
      case "fname":
        const regex = /^[a-zA-Z\s]+$/;
        if (!regex.test(value)) {
          setErrors({ ...errors, [name]: "Only letters and spaces allowed" });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "emailId":
        if (!value.match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}")) {
          setErrors({ ...errors, [name]: "Invalid email address" });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "age":
        const ageRegex = /^[1-9][0-9]?$|^100$/; // only allows two-digit numbers starting from 10
        if (!ageRegex.test(value) || value.length > 3) {
          setErrors({
            ...errors,
            [name]: "Age must be a number between 10 and 1000",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "mobileNo":
        if (!/^[0-9]{10}$/.test(value)) {
          setErrors({
            ...errors,
            [name]:
              "Mobile number must be 10 digits long and contain only numbers",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "department":
        const departmentRegex = /^[a-zA-Z ]*$/;
        if (!departmentRegex.test(value)) {
          setErrors({
            ...errors,
            [name]: "Only letters,hypens and spaces allowed",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      default:
        // handle default case
        break;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  console.log("farm iddddddddddddddd", farmId);
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.target.reset();
    const isStepOneValid = validatestepone();
    console.log("handleSubmit executed!!!!!!!!!!!!!");
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_CONFIGURATION_FARMER,
        {
          fname: formValues?.fname,
          emailId: formValues?.emailId,
          age: formValues?.age,
          dateOfBirth: formValues?.dateOfBirth,
          gender: formValues?.gender,
          mobileNo: formValues?.mobileNo,
          department: formValues?.department,
          description: formValues?.description,
          farm_id: farmId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      console.log("response status: ", response.status);

      if (response.status >= 200 && response.status < 305) {
        console.log("Response is successful");
        navigate("/Farmerview");
      } else {
        console.log("Response is not successful");
        // Handle the error scenario here if needed
      }
    } catch (error) {
      console.log(error);
    }

    event.target.reset();
    setSuccessOpen(true);
  };

  const renderStepOne = () => {
    return (
      <div style={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          display="flex"
          style={{ marginTop: "15px" }}
        >
          <Grid item xs={9} sm={6}>
            <TextField
              label="Name"
              name="fname"
              value={formValues?.fname}
              onChange={handleInputChange}
              fullWidth
              size="small"
              required
              margin="normal"
              variant="outlined"
              inputProps={{
                maxLength: 30, // maximum length of 50 characters
                minLength: 3, // minimum length of 3 characters
                pattern: /^[a-zA-Z ]+$/, // only allows letters and spaces
                onKeyPress: handleKeyPress,
              }}
              error={!!errors?.fname}
              helperText={errors?.fname}
            />
          </Grid>
          <Grid item xs={9} sm={6}>
            <TextField
              label="D.O.B"
              name="dateOfBirth"
              value={formValues?.dateOfBirth}
              type="date"
              onChange={handleInputChange}
              fullWidth
              size="small"
              margin="normal"
              // variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                style: { width: "100%" },
                max: new Date().toISOString().split("T")[0], // sets max date to today's date
                min: new Date(new Date().getFullYear() - 100, 0, 1)
                  .toISOString()
                  .split("T")[0],
                required: true,
              }}
              error={!!errors?.dateOfBirth}
              helperText={errors?.dateOfBirth}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9} sm={6}>
            <TextField
              label="Email"
              name="emailId"
              type="email"
              size="small"
              value={formValues?.emailId}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{
                type: "email",
                pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}",
                maxLength: 50,
                minLength: 3,
              }}
              error={!!errors?.emailId}
              helperText={errors.emailId ? errors.emailId : ""}
            />
          </Grid>
          <Grid item xs={9} sm={6} sx={{ marginTop: "1.3%" }}>
            <FormControl fullWidth>
              <InputLabel id="Gender">Gender</InputLabel>
              <Select
                labelId="Gender"
                id="Gender"
                label="Gender"
                size="small"
                value={formValues?.gender}
                name="gender"
                onChange={handleInputChange}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9} sm={6}>
            <TextField
              label="Mobile No."
              name="mobileNo"
              size="small"
              value={formValues?.mobileNo}
              onChange={handleInputChange}
              onKeyPress={handleMobileNoKeyPress}
              fullWidth
              type="tel"
              required
              margin="normal"
              variant="outlined"
              inputProps={{
                maxLength: 10,
                pattern: "[0-9]*", // Only allows numeric input
                inputMode: "numeric", // Shows the numeric keyboard on mobile devices
              }}
              error={!!errors?.mobileNo}
              helperText={errors?.mobileNo}
            />
          </Grid>
          <Grid item xs={9} sm={6}>
            <TextField
              label="Department"
              name="department"
              size="small"
              value={formValues?.department}
              onChange={handleInputChange}
              onKeyPress={(event) => {
                const pattern = /^[a-zA-Z0-9_ ]*$/; // regular expression to allow only letters, numbers, spaces and underscore
                const key = event.key;
                if (!pattern.test(key)) {
                  event.preventDefault();
                }
              }}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{
                maxLength: 50,
                minLength: 0,
              }}
              error={Boolean(errors?.department)} // Add error prop and pass Boolean value of errors.department
              helperText={errors?.department}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9} sm={6} style={{ marginTop: "2%" }}>
            <FormControl fullWidth>
              <InputLabel style={{ textAlign: "center" }}>
                Select Farm
              </InputLabel>
              <Select
                labelId="Select Farm"
                id="Select Farm"
                label="Select Farm"
                required
                size="small"
                value={farm}
                onChange={handleMyFarmChange}
                fullWidth
                name="Select Farm"
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: "95px" }, // Set the max-height for the menu
                  },
                }}
                displayEmpty
                inputProps={{
                  style: { width: "100%" },
                  "aria-label": "Select Farm",
                }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {farmData &&
                  farmData?.map((farm) => (
                    <MenuItem key={farm.farm_name} value={farm.farm_name}>
                      {farm.farm_name}
                    </MenuItem>
                  ))}
                <MenuItem disabled value="">
                  Select Farm
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9} sm={6}>
            <TextField
              label="Description"
              name="description"
              size="small"
              value={formValues?.description}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{
                maxLength: 100,
                minLength: 0,
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <div
        style={{
          width: "92%",

          alignItems: "center",
        }}
      >
        <div style={{ marginLeft: "13%", marginRight: "10%" }}>
          <Card
            style={{
              width: "100%",
              height: "auto",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CardHeader title="Confirm Details" />
            <CardContent>
              <List>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {formValues?.fname}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {formValues?.emailId}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 28%, #f5faf8 28%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Date of
                    Birth&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {formValues?.dateOfBirth}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Gender&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {formValues?.gender}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 23%, #f5faf8 23%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Mobile No.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {formValues?.mobileNo}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 26%, #f5faf8 26%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Department
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {formValues?.department}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Farm
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {farm}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 26%, #f5faf8 26%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Description
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {formValues?.description}
                  </Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  };

  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return renderStepOne();
      case 2:
        return renderStepTwo();

      default:
        return null;
    }
  };
  return (
    <Grid>
      <Slide direction="left" in={errorOpen || successOpen}>
        <Alert
          severity={errorOpen ? "error" : "success"}
          onClose={() =>
            errorOpen ? setErrorOpen(false) : setSuccessOpen(false)
          }
          style={{
            position: "fixed",
            top: "8px",
            right: errorOpen ? "2px" : "-100%", // Hide it initially outside the window
            zIndex: 1000,
            transition: "right 0.3s ease-in-out", // Add a smooth transition effect
          }}
        >
          <AlertTitle>{errorOpen ? "Error" : "Success"}</AlertTitle>
          {errorOpen
            ? "Please fill out all the required fields before proceeding."
            : "Form submitted successfully!"}
        </Alert>
      </Slide>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Form Submitted Successfully</DialogTitle>
        <DialogContent>Submitted Successfully!!!!!</DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="success">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={activeStep - 1} alternativeLabel>
          {steps?.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderSwitch(activeStep)}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          {activeStep !== 1 && (
            <Button
              sx={{ marginRight: "62.4%" }}
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
            >
              <Typography variant="h5" color={colors.green.DEFAULT}>
                Previous
              </Typography>
            </Button>
          )}
          {activeStep !== 2 ? (
            <Button
              variant="contained"
              sx={{ marginLeft: "2%", backgroundColor: colors.green.DEFAULT }}
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
            >
              <Typography variant="h5" color={colors.white.DEFAULT}>
                Next
              </Typography>
            </Button>
          ) : (
            <Button
              sx={{ marginRight: "2%", backgroundColor: colors.green.DEFAULT }}
              variant="contained"
              type="submit"
            >
              <Typography variant="h5" color={colors.white.DEFAULT}>
                Submit
              </Typography>
            </Button>
          )}
        </div>
      </form>
    </Grid>
  );
};
export default FarmerConfig;
