import React, { useMemo, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,Divider,
  Box,Switch,InputLabel,
  TextField,
  MenuItem,Select,
  Typography,
  Button,FormControl,
  IconButton,Stack,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { MaterialReactTable } from "material-react-table";
import InputAdornment from "@mui/material/InputAdornment";
import NullScreen from "../PopUpMsg/NullScreen";
import { tokens } from "../WMStheme";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import { IoMdAddCircleOutline } from "react-icons/io";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../PopUpMsg/SuccessPopUp";
import ErrorPopup from "../PopUpMsg/ErrorPopUp";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Label } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import UserAccessEditPage from "./UserAccessEditPage";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));
const containerStyle8 = {
  maxHeight: "70vh",
  height: "100%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));

const columns = [
  { id: "status", label: "Status", type: "tag" },
  { id: "name", label: "Name", type: "string" },
  { id: "username", label: "User Name", type: "string" },
  { id: "email", label: "Email", type: "string" },
  { id: "phone_number", label: "Mobile Number", type: "string" },
];

const UserMainPage = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [userData, setUserData] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isNewUserDialogOpen, setIsNewUserDialogOpen] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRow, setCurrentRow] = useState([]);
  const [updatedValues, setUpdatedValues] = useState({});
  const globalTheme = useTheme();
  const [count, setCount] = useState(1);
  const [rowUserData,setRowUserData] =useState('');
  const [displaySwitchValues, setDisplaySwitchValues] = useState(currentRow);
  const [confirmNewUserAddDialog, setConfirmNewUserAddDialog] = useState(false);
  const [confirmSaveClick, setConfirmSaveClick] = useState(false);
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#fffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        size: 21,
        enableEditing: false,
      },
      {
        header: "User Name",
        accessorKey: "username",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Email",
        accessorKey: "email",
        size: 20,
        enableEditing: false,
      },
      {
        header: "Role",
        accessorKey: "role",
        size: 10,
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: userData.map((role) => (
            <MenuItem key={role.role_id} value={role.role_id}>
              {role.role_name}
            </MenuItem>
          )),
        },
      },
      {
        header: "Mobile Number",

        accessorKey: "mobile",
        size: 15,
      },
    ],
    []
  );
  useEffect(() => {
    fetchUserGridData(currentPage);
  }, []);
  const [userRole, setUserRole] = useState([]);
  const [userRoleState, setUserRoleState] = useState({
    name: currentRow?.name,
    userName: currentRow?.username,
    mobile: currentRow?.mobile,
    role: currentRow?.role,
    email: currentRow?.email,
  });
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  const [cancelChanges, setCancelChanges] = useState(false);
  const { name, userName, mobile, role, email } = userRoleState;
  const [newUserRoleState, setNewUserRoleState] = useState({
    new_name: "",
    new_userName: "",
    new_mobile: "",
    new_role: "",
    new_email: "",
  });
  const { new_name, new_userName, new_mobile, new_role, new_email } =
    newUserRoleState;
  const handleNameChange = (event) => {
    updateState("name", event.target.value);
  };
  const handleUserNameChange = (event) => {
    updateState("userName", event.target.value);
  };
  const handleNumberChange = (event) => {
    updateState("mobile", event.target.value);
  };
  const handleEmailChange = (event) => {
    updateState("email", event.target.value);
  };

  const fetchUserGridData = async (page) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_USER_LIST,
        {
          params: {
            limit: 100,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setUserData(data?.user_list);
        setTotalPages(data?.total_pages);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    fetchUserGridData(newPage);
  };
  const handleCloseUserDialog = () => {
    setIsNewUserDialogOpen(false);
    setNewUserRoleState({
      new_name: "",
      new_userName: "",
      new_mobile: "",
      new_role: "",
      new_email: "",
    });
  };
  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setUpdatedValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
    setDisplaySwitchValues((prevDisplayValues) => ({
      ...prevDisplayValues,
      [name]: checked,
    }));
  };
  const handleSaveButtonClick = async (e) => {
    console.log("Updated valueszzzzzzzzzzzzzzz", displaySwitchValues);
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);
    console.log("entered !");
    e.preventDefault();
    setIsDialogOpen(false);
    try {
      const response = await axiosInstance.put(
        API_ENDPOINTS.PUT_USER_ROLE_ACCESS,
        displaySwitchValues,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("responseeeeeeeeee", response);
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time
      if (response.status >= 200 && response.status < 305) {
        setConfirmSaveClick(true);
        fetchAllUserData();
      } else {
        console.log("Changes not saved due to some error");
        setApiErrorOpen(true);
      }
      console.log("Updated Data of user ", response.data);
    } catch (error) {
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      console.log(error);
      setApiErrorOpen(true);
    }
    e.target.reset();
  };

  const containerStyle = {
    maxHeight: "80vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    height: "70vh",
    width: "100%",
    //  backgroundColor: "blue",
  };

  const StatusTag = ({ status }) => {
    let tagColor;
    switch (status) {
      case true:
        tagColor = "#74749e";
        break;
      case false:
        tagColor = "#997975";
        break;
      default:
        tagColor = "gray";
        break;
    }
    return (
      <Label
        style={{
          backgroundColor: tagColor,
          color: "white",
          fontWeight: typography.h1,
          fontFamily: "Lexend Deca",
          cursor: "pointer",
          width: "auto",
        }}
        tag
      >
        {status === true ? "Active" : "In-Active"}
      </Label>
    );
  };

  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent() {
    return (
      <>
        <TableBody>
          {userData?.map((row, index) => (
            <StyledTableRow key={index}>
              {columns?.map((column) => (
                <StyledTableCell key={column?.id} align="center">
                  {column?.type === "tag" ? (
                    //   <input type="checkbox" checked= disabled />
                    // <Checkbox
                    //   defaultChecked={row[column.id]}
                    //   color="success"
                    //   size="small"
                    //   disabled
                    //       />
                    <StatusTag status={row[column.id]} />
                  ) : (
                    row[column.id] || "-"
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </>
    );
  }
  // For opening Add New User dialog box
  const handleAddNewUser = () => {
    setIsNewUserDialogOpen(true);
  };
  const handleCloseDialog = () => {
    console.log("HITTTTTTTTTTT");
    setIsDialogOpen(false);
    setCancelChanges(true);
    setUpdatedValues({});
  };
  const handleSubmit = async (e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);
    console.log("entered !");
    e.preventDefault();
    const formData = {
      name: new_name,
      username: new_userName,
      mobile: new_mobile,
      role: new_role,
      email: new_email,
    };
    setIsNewUserDialogOpen(false);
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_USER_ROLE_NEWUSER,
        formData,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

      if (response.status >= 200 && response.status < 305) {
        console.log("New User Added !");
        fetchUserGridData();
        setNewUserRoleState({
          new_name: "",
          new_userName: "",
          new_mobile: "",
          new_role: "",
          new_email: "",
        });
      } else {
        console.log("User not added");
        setApiErrorOpen(true);
      }
      // console.log("New user data", response.data);
      setConfirmNewUserAddDialog(true);
    } catch (error) {
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      console.log(error);
      setApiErrorOpen(true);
    }
    e.target.reset();
  };
  const handleRowClick = (row) => {
    console.log("handleRowClick234567895678956789===", row);
    // setCurrentRow(row);
    setRowUserData(row);
    setIsDialogOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
            height: "6vh",
          }}
        >
          <Typography variant="h1" color={colors.green.DEFAULT}>
            User Management
          </Typography>
         
        </Grid>
        <Grid container spacing={1} style={containerStyle8}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : userData?.length === 0 ? (
            <NullScreen containerheight="80vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    columns={columns}
                    data={userData}
                    enableStickyHeader
                    enableStickyFooter
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={true}
                    editingMode="modal"
                    count={count}
                    initialState={{
                      density: "comfortable",
                      pagination: { pageIndex: 0, pageSize: 5 },
                    }}
                    // muiTableContainerProps={{ sx: { marginBottom: "15px" } }}
                    muiToolbarAlertBannerChipProps={{ color: "primary" }}
                    enableRowActions
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        color: "#fff",
                        backgroundColor: "#34519fe6",
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        //stripe the rows, make odd rows a darker color
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: "#ffff",
                        },
                      },
                    }}
                    renderRowActions={({ row, table }) => (
                      <Box
                        sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}
                      >
                        <IconButton
                          // color="#D8D8D8"
                          sx={{ color: "#D8D8D8" }}
                           onClick={() => handleRowClick(row.original)}
                           disabled={!userDetail?.b_users_management_edit}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    )}
                  />
                </ThemeProvider>
              </div>
            </>
          )}
        </Grid>
      </Box>
      <Dialog
        open={isNewUserDialogOpen}
        onClose={handleAddNewUser}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography
            variant="h3"
            color={colors.green.DEFAULT}
            fontWeight={typography.h1}
            fontFamily="Lexend Deca"
          >
            Add New User
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} marginTop={"6px"}>
                <TextField
                  label="Name"
                  value={new_name}
                  onChange={(e) =>
                    updateNewUserState("new_name", e.target.value)
                  }
                  size="small"
                  inputProps={{
                    maxLength: 20,
                    minLength: 5,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <PermIdentityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  autoFocus
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="User Name"
                  value={new_userName}
                  onChange={(e) =>
                    updateNewUserState("new_userName", e.target.value)
                  }
                  size="small"
                  inputProps={{
                    maxLength: 10,
                    minLength: 3,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <AccountCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  autoFocus
                  required
                  error={rows.some(
                    (user) => user.username === newUserRoleState?.new_userName
                  )}
                  helperText={
                    rows.some(
                      (user) => user.username === newUserRoleState?.new_userName
                    )
                      ? "User Name already exists !"
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    label="Role"
                    value={new_role}
                    onChange={(e) =>
                      updateNewUserState("new_role", e.target.value)
                    }
                    fullWidth
                    required
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Role" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <SupervisedUserCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Role
                    </MenuItem>
                    {userRole.map((role) => (
                      <MenuItem key={role.role_id} value={role.role_name}>
                        {role.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  size="small"
                  value={newUserRoleState?.new_email}
                  onChange={(event) => {
                    const { value } = event.target;
                    // Validate and update the new_email state
                    setNewUserRoleState((prevValues) => ({
                      ...prevValues,
                      new_email: value,
                    }));
                  }}
                  fullWidth
                  required
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    maxLength: 50,
                    minLength: 3,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <MailIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    (newUserRoleState?.new_email !== "" && // Check for empty string
                      !newUserRoleState?.new_email?.match(
                        // Validate the email with a less restrictive pattern
                        "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$"
                      )) ||
                    rows.some(
                      (user) => user.email === newUserRoleState?.new_email
                    ) // Check if the email is taken
                  }
                  helperText={
                    (newUserRoleState?.new_email !== "" && // Check for empty string
                    !newUserRoleState?.new_email?.match(
                      // Validate the email with a less restrictive pattern
                      "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$"
                    )
                      ? "Invalid email address"
                      : "") ||
                    (rows.some(
                      (user) => user.email === newUserRoleState?.new_email
                    ) // Check if the email is taken
                      ? "User with this email already exists !"
                      : "")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="Mobile No."
                  value={newUserRoleState?.new_mobile}
                  size="small"
                  onChange={(event) => {
                    const { value } = event.target;
                    // Validate and update the new_mobile state only if it's a valid number
                    if (
                      value === "" ||
                      /^[0-9]*$/.test(value) ||
                      value?.match(/^[\+0-9]+$/)
                    ) {
                      setNewUserRoleState((prevValues) => ({
                        ...prevValues,
                        new_mobile: value,
                      }));
                    }
                  }}
                  fullWidth
                  type="tel"
                  required
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric", // Shows the numeric keyboard on mobile devices
                    pattern: "[0-9]*", // Only allows numeric input (additional validation)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <PhoneIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    newUserRoleState?.new_mobile?.length !== 10 &&
                    newUserRoleState?.new_mobile !== "" // Check for empty string
                  }
                  helperText={
                    newUserRoleState?.new_mobile?.length !== 10 &&
                    newUserRoleState?.new_mobile !== ""
                      ? "Invalid mobile number. It should be 10 digits."
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginRight: "15px" }}>
            <Stack
              spacing={1}
              direction="row"
              style={{ height: "35px", marginTop: "2%", marginLeft: "84%" }}
            >
              <Button
                onClick={handleCloseUserDialog}
                sx={{ color: "#2C7B55", borderColor: "#2C7B55" }}
                variant="outlined"
              >
                Close
              </Button>
              <Button
                type="submit"
                sx={{ backgroundColor: "#2C7B55" }}
                variant="contained"
              >
                <Typography
                  variant="h6"
                  color={colors.white.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Add
                </Typography>
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={confirmNewUserAddDialog}
        //onClose={handleConfirmNewUserAddDialog}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#198754" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p>New User Added Successfully.</p>
        </DialogContent>
        <Button
          //onClick={handleConfirmNewUserAddDialog}
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "2%",
            color: "#198754",
          }}
        >
          OK
        </Button>
      </Dialog>
      {isDialogOpen && <UserAccessEditPage setIsDialogOpen={setIsDialogOpen}  count={count}
      setCount={setCount} rowUserData={rowUserData}/> }
    
      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default UserMainPage;





// <Button
//   variant="outlined"
//   startIcon={
//     <IoMdAddCircleOutline
//       style={{ color: colors.green.DEFAULT, fontSize: 22 }}
//     />
//   }
//   sx={{
//     borderColor: colors.green.DEFAULT,
//     color: colors.green.DEFAULT,
//   }}
//   onClick={() => setIsNewUserDialogOpen(true)}
// >
//   <Typography variant="h5" color={colors.green.DEFAULT}>
//     Add New User
//   </Typography>
// </Button>



