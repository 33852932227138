import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import iconImage from "../../Assets/FamboLOGO.png";
import HindRegular from "./Hind-Regular.ttf";

Font.register({
  family: "Hind-Regular",
  src: HindRegular,
});

const styles = StyleSheet.create({
  cellItem: {
    width: "42%",
    fontSize: 8,
    marginLeft: "5px",
    fontFamily: "Hind-Regular",
  },
  cellItemHeader: {
    width: "42.4%",
    textAlign: "center",
    fontSize: 8,
  },
  page: {
    flexDirection: "column",
    padding: "8px",
  },
  section: {
    margin: 10,
    padding: 2,
    flexGrow: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  iconContainer: {
    width: "20%",
    textAlign: "right",
  },
  addressContainer: {
    width: "40%",
    fontSize: 10,
  },
  QRContainer: {
    width: "40%",
    // textAlign: "right",
    fontSize: 10,
  },
  taxInvoiceContainer: {
    width: "100%",
    fontSize: 13,
  },
  icon: {
    width: 100,
    height: 60,
  },

  QR: {
    width: 90,
    height: 90,
  },
  horizontalDivider: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },
  footer: {
    borderTopColor: "black",
    borderTopWidth: 1,
    fontSize: 10,
    padding: 2,
    textAlign: "left",
  },

  billingShippingContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#f2f2f2",
  },
  totalsContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  billingShippingSection: {
    flex: 1,
    padding: 2,
  },
  billingShippingDivider: {
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  billingShippingHeader: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 10,
  },
  bankAccountHeader: {
    fontSize: 8,
    fontWeight: "bold",
    marginBottom: 10,
  },
  invoiceDetailsBoxHeader: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  billingShippingDetails: {
    fontSize: 8,
  },
  signatureDetails: {
    fontSize: 8,
    marginTop: 10,
  },
  itemsTotalDetails: {
    fontSize: 10,
    textAlign: "right",
    padding: 3,
  },
  itemsTotalAmountDetails: {
    fontSize: 10,
    textAlign: "right",
    padding: 3,
    // backgroundColor: "#f2f2f2",
  },
  tableContainer: {
    marginTop: 10,
    width: "100%",
    backgroundColor: "#ffffff",
    border: 1,
  },
  tableHeader: {
    flexDirection: "row",
    fontSize: 8,
    borderBottomColor: "black",
    borderBottomWidth: 1,
    backgroundColor: "#f2f2f2",
    padding: 1,
  },

  headerCell: {
    flex: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    padding: 1,
  },
  cellSNo: {
    width: "5%",
    textAlign: "center",
    fontSize: 8,
  },
  cellQty: {
    width: "12%",
    textAlign: "center",
    fontSize: 8,
  },
  cellRTV: {
    width: "10%",
    textAlign: "center",
    fontSize: 8,
  },
  cellRate: {
    width: "8%",
    textAlign: "center",
    fontSize: 8,
  },
  cellTax: {
    width: "5%",
    textAlign: "center",
    fontSize: 8,
  },
  cellAmount: {
    width: "10%",
    textAlign: "center",
    fontSize: 8,
  },
  salesDivider: {
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  footer: {
    width: "100%",
    textAlign: "center",
    fontSize: 8,
  },
  footerContainer: {
    marginTop: 4,
    width: "100%",
    backgroundColor: "#ffffff",
    border: 1,
  },
  footerHeader: {
    flexDirection: "row",
    fontSize: 8,
    borderBottomColor: "black",
    borderBottomWidth: 1,
    backgroundColor: "#ffffff",
    padding: 1,
  },
});

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const epochToDDMMYYYY = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "Invalid Timestamp";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const BillingShippingBox = ({ stoData }) => {
  return (
    <View style={styles.billingShippingContainer}>
      <View
        style={[styles.billingShippingSection, styles.billingShippingDivider]}
      >
        <Text style={styles.billingShippingHeader}>Vendor Information</Text>
        <Text style={styles.billingShippingDetails}>
          Vendor Name: {capitalizeFirstLetter(stoData?.vendor_name)}
        </Text>
        <Text style={styles.billingShippingDetails}>
          Vendor Code: {capitalizeFirstLetter(stoData?.vendor_code)}
        </Text>
        <Text style={styles.billingShippingDetails}>
          Vendor GST: {capitalizeFirstLetter(stoData?.vendor_gst)}
        </Text>
      </View>

      <View style={styles.billingShippingSection}>
        <Text style={styles.billingShippingHeader}>Shipping Address</Text>
        <Text style={styles.billingShippingDetails}>
          Warehouse: {stoData?.warehouse_name}
        </Text>
        <Text style={styles.billingShippingDetails}>
          Address: {stoData?.vendor_address?.addressline1}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {stoData?.vendor_address?.city}, {stoData?.vendor_address?.pincode}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {stoData?.vendor_address?.state},{" "}
          {capitalizeFirstLetter(stoData?.vendor_address?.country)}
        </Text>
      </View>
    </View>
  );
};

const ItemsTotalBox = ({ stoData }) => {
  return (
    <View style={styles.totalsContainer}>
      <View style={styles.billingShippingSection}>
        <Text style={styles.itemsTotalDetails}>
          Sub Total: Rs. {stoData?.sub_total}
        </Text>
        <Text style={styles.itemsTotalDetails}>
          Total Tax: {stoData?.total_tax}
        </Text>
        <Text style={styles.itemsTotalAmountDetails}>
          Total Amount: Rs. {stoData?.total_amount}
        </Text>
      </View>
    </View>
  );
};

const SaleItemsTable = ({ stoItems }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.tableHeader}>
        <Text style={[styles.cellSNo, styles.salesDivider]}>S.No.</Text>
        <Text style={[styles.cellItemHeader, styles.salesDivider]}>Item</Text>
        <Text style={[styles.cellQty, styles.salesDivider]}>Code</Text>
        <Text style={[styles.cellRTV, styles.salesDivider]}>Lot No.</Text>
        <Text style={[styles.cellQty, styles.salesDivider]}>Transfer Qty</Text>
        <Text style={[styles.cellQty, styles.salesDivider]}>
          Difference Qty
        </Text>
        <Text style={[styles.cellQty]}>Received Qty</Text>
      </View>
      {stoItems?.map((item, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={[styles.cellSNo, styles.salesDivider]}>{index + 1}</Text>
          <Text style={[styles.cellItem, styles.salesDivider]}>
            {capitalizeFirstLetter(item?.sku_name)}
          </Text>
          <Text style={[styles.cellQty, styles.salesDivider]}>
            {item?.sku_code}
          </Text>
          <Text style={[styles.cellRTV, styles.salesDivider]}>
            {item?.lot_no}
          </Text>
          <Text style={[styles.cellQty, styles.salesDivider]}>
            {item?.transfer_qty}
          </Text>
          <Text style={[styles.cellQty, styles.salesDivider]}>
            {item?.difference_qty}
          </Text>
          <Text style={[styles.cellQty]}>{item?.received_qty}</Text>
        </View>
      ))}
    </View>
  );
};

const Footer = () => {
  return (
    <View style={styles.footerContainer}>
      <View style={styles.footerHeader}>
        <Text style={[styles.footer]}>
          This is a computer generated document
        </Text>
      </View>
    </View>
  );
};

const STOFile = ({ stoData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.header}>
          <View style={styles.iconContainer}>
            <Image style={styles.icon} src={iconImage} />
          </View>
          <View style={styles.addressContainer}>
            <Text>Fambo Innovation Pvt. Ltd</Text>
            <Text>Plot No. C-1 Urbtech NPX Tower, Sector-153</Text>
            <Text>Noida,Gautam buddha Nagar,Uttar Pradesh- 201310</Text>
            <Text>India</Text>
            <Text>GSTIN : 09AAECF9571A1ZL</Text>
          </View>
          <View style={styles.QRContainer}>
            <Text>Stock Transfer Order</Text>
            <Text>STO Number: {stoData?.sto_no}</Text>
            <Text>Date: {epochToDDMMYYYY(stoData?.created_at)}</Text>
            <Text>Stock From: {stoData?.from_wh_name}</Text>
            <Text>Stock To: {stoData?.to_wh_name} </Text>
          </View>
        </View>
        {/* <View style={styles.horizontalDivider} /> */}
        {/* <BillingShippingBox stoData={stoData} /> */}
        <View style={styles.horizontalDivider} />
        <SaleItemsTable stoItems={stoData?.sto_sku} />
        {/* <ItemsTotalBox stoData={stoData} /> */}
        <Footer />
      </View>
    </Page>
  </Document>
);

const DownloadButton = ({ stoData }) => (
  <PDFDownloadLink document={<STOFile stoData={stoData} />} fileName="sto.pdf">
    {({ blob, url, loading, error }) =>
      loading ? "Loading document..." : "Download PDF"
    }
  </PDFDownloadLink>
);

export default { STOFile, DownloadButton };
