import React, { useState } from "react";
import { tokens } from "../WMStheme";
import {
  Card,
  CardContent,
  useTheme,
  Grid,
  Button,
  Switch,
  Typography,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { FaEye } from "react-icons/fa";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import zohoImage from "../../Assets/WMSImage/zoho-logo-web.svg";
import ZohoLogsMainPage from "./ZohoLogsMainPage";
import OMSImage from "../../Assets/WMSImage/OMS.png";
import FleetImage from "../../Assets/WMSImage/FleetX.png";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
const IntegrationMainPage = () => {
  const [isToggleOn3, setIsToggleOn3] = useState(true);
  const [isToggleOn, setIsToggleOn] = useState(true);
  const [isToggleOn1, setIsToggleOn1] = useState(true);
  const [isZohoLogsDialogOpen, setIsZohoLogsDialogOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleZohoLogsDialogClose = () => {
    setIsZohoLogsDialogOpen(false);
  };

  const handleZohoLogsClick = () => {
    setIsZohoLogsDialogOpen(true);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        style={{ marginLeft: "9.7%", marginTop: "1%" }}
      >
        <Grid item>
          <Typography
            variant="h2"
            color="#34519fe6"
            fontWeight="600"
            fontFamily="Lexend Deca"
            fontSize="1.65rem"
            style={{ marginLeft: "8px" }} // Adjust the margin between icon and text
          >
            External App Integration
          </Typography>
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2%",
          height: "100%",
          width: "100%",
        }}
      >
        <Card style={{ height: "90%", width: "80%" }}>
          <CardContent>
            <Grid item xs={12} sm={6} sx={{ marginTop: "1%" }}>
              <Button
                sx={{
                  borderRadius: "4%",
                  borderStyle: "dotted",
                  borderWidth: "2px",
                  color: "#34519fe6",
                }}
                startIcon={
                  <AddCircleOutlineOutlinedIcon
                  // style={{ color: "#34519fe6" }}
                  />
                }
                disabled
              >
                {/* <Typography variant="h5" color={colors.green.DEFAULT}> */}
                Integrate New App
                {/* </Typography> */}
              </Button>
            </Grid>

            <Grid
              container
              alignItems="center"
              spacing={2}
              marginTop={2}
              // style={{ backgroundColor: "blue" }}
            >
              <Grid item xs={12} sm={1}>
                <img
                  src={OMSImage}
                  alt="Order Management System"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h3">Order Management System</Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="h3">Active</Typography>
              </Grid>
              <Grid item xs={12} sm={1} sx={{ marginLeft: "-10%" }}>
                <Switch
                  checked={isToggleOn1}
                  onChange={() => setIsToggleOn1(!isToggleOn1)}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <SettingsIcon style={{ size: 40 }} />
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <Button
                  variant="contained"
                  startIcon={<FaEye />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  // onClick={handleApplyClick}
                  disabled
                >
                  View Logs
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              spacing={2}
              marginTop={2}
              // style={{ backgroundColor: "red" }}
            >
              <Grid item xs={12} sm={1}>
                <img
                  src={zohoImage}
                  alt="Order Management System"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h3">Zoho Books</Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  marginTop: "-17px",
                }}
              />
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="h3">Active</Typography>
              </Grid>
              <Grid item xs={12} sm={1} sx={{ marginLeft: "-10%" }}>
                <Switch
                  checked={isToggleOn}
                  onChange={() => setIsToggleOn(!isToggleOn)}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <SettingsIcon style={{ size: 40 }} />
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <Button
                  variant="contained"
                  startIcon={<FaEye />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleZohoLogsClick}
                >
                  View Logs
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              marginTop={2}
              // style={{ backgroundColor: "blue" }}
            >
              <Grid item xs={12} sm={1}>
                <img
                  src={FleetImage}
                  alt="Order Management System"
                  style={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h3">Fleet Management System</Typography>
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  marginTop: "-17px",
                }}
              />
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="h3">Active</Typography>
              </Grid>

              <Grid item xs={12} sm={1} sx={{ marginLeft: "-10%" }}>
                <Switch
                  checked={isToggleOn3}
                  onChange={() => setIsToggleOn3(!isToggleOn3)}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <SettingsIcon style={{ size: 40 }} />
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <Button
                  variant="contained"
                  startIcon={<FaEye />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  disabled
                >
                  View Logs
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Box m="20px">
        <Dialog
          open={isZohoLogsDialogOpen}
          onClose={handleZohoLogsDialogClose}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            <Typography
              variant="h1"
              style={{ marginLeft: "0.1%" }}
              color={colors.green.DEFAULT}
            >
              View Logs
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleZohoLogsDialogClose}
              sx={{
                position: "absolute",
                right: 1.9,
                top: 1,
                color: "grey",
              }}
            >
              <CloseSharpIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ZohoLogsMainPage
              handleZohoLogsDialogClose={handleZohoLogsDialogClose}
              isZohoLogsDialogOpen={isZohoLogsDialogOpen}
              setIsZohoLogsDialogOpen={setIsZohoLogsDialogOpen}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default IntegrationMainPage;
