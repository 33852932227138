import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TbPlant } from "react-icons/tb";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlantWilt } from "@fortawesome/free-solid-svg-icons";
import PieChartIcon from "@mui/icons-material/PieChart";
import axios from "axios";
import { tokens } from "../../../../theme";
import { ThreeDots } from "react-loader-spinner";
import { Typography, useTheme, Box } from "@mui/material";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import "./MainPage.css";
import { ScaleLoader } from "react-spinners";
import InventoryDataDisplay from "../../Graph/AreaOccupied/AreaOccupied";
import InventoryHarvestingGraph from "../../Graph/Harvesting/Harvesting";
import InventoryCropStage from "../../Graph/Stage/CropStage";
import InventoryDamageGraph from "../../Graph/Damage/Damage";
import { Label } from "semantic-ui-react";

import { API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

function FarmBox(props) {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  // console.log("PROPSSSS FROM FARMBOX", props);
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const customStyle = {
    backgroundColor: isHovered ? "#b5cbbf" : "#2C7B55", // Change background color on hover
    color: isHovered ? colors.green.DEFAULT : colors.white.DEFAULT, // Change background color on hover
    // color: colors.white.DEFAULT,
    fontWeight: typography.h1,
    fontFamily: "Lexend Deca",
    cursor: "pointer",
    width: "auto",
  };
  const navigate = useNavigate();
  const [showTextDamage, setShowTextDamage] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [showTextHarvest, setShowTextHarvest] = useState(false);
  const [showTextStage, setShowTextStage] = useState(false);

  const handleClick = () => {
    console.log("props after handleclick", props);
    // navigate("/inventory-data", { state: { name: props?.name } });
    navigate("/inventory-data", { state: { name: props } });
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    // After 3 seconds (3000 milliseconds), show the text
    const timer = setTimeout(() => {
      setShowTextArea(true);
    }, 3000);
    // After 6 seconds (6000 milliseconds), hide the text

    const damageshowtimer = setTimeout(() => {
      setShowTextDamage(true);
    }, 3000);

    const harvestshowtimer = setTimeout(() => {
      setShowTextHarvest(true);
    }, 3000);

    const stageshowtimer = setTimeout(() => {
      setShowTextStage(true);
    }, 3000);

    // Clear the timer when the component unmounts
    return () => {
      clearTimeout(harvestshowtimer);

      clearTimeout(stageshowtimer);

      clearTimeout(timer);

      clearTimeout(damageshowtimer);
    };
  }, []);
  useEffect(() => {
    if (showTextDamage) {
      const hideTimer = setTimeout(() => {
        setShowTextDamage(false);
      }, 2000);
      return () => {
        clearTimeout(hideTimer);
      };
    }
    if (showTextHarvest) {
      const hideTimer = setTimeout(() => {
        setShowTextHarvest(false);
      }, 2000);
      return () => {
        clearTimeout(hideTimer);
      };
    }
    if (showTextArea) {
      const hideTimer = setTimeout(() => {
        setShowTextArea(false);
      }, 2000);
      return () => {
        clearTimeout(hideTimer);
      };
    }
    if (showTextStage) {
      const hideTimer = setTimeout(() => {
        setShowTextStage(false);
      }, 2000);
      return () => {
        clearTimeout(hideTimer);
      };
    }
  }, [showTextDamage, showTextArea, showTextHarvest, showTextStage]);

  return (
    <div className="farm-box">
      <div style={{ marginBottom: "10px" }}>
        <Label
          style={customStyle}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          tag
        >
          {props?.name?.toUpperCase()} &nbsp;&nbsp;
          <Typography
            variant="h7"
            color={isHovered ? colors.green.DEFAULT : colors.white[300]}
            // fontWeight={typography.h1}
            fontFamily="Lexend Deca"
          >
            (Click here to view details)
          </Typography>
        </Label>
      </div>
      <div className="farm-box-wrapper">
        <div className="grid1-container">
          <div className="cropStage">
            <div>
              <Typography
                variant="h4"
                color={colors.black.DEFAULT}
                fontWeight={typography.h1}
                fontFamily="Lexend Deca"
              >
                Stages
              </Typography>

              <div className="icon-plant">
                <TbPlant size={18} color="green" />
              </div>
              {/* <div>
                {farms.map((farm) => {
                  if (farm.farmId === props?.farmId) {
                    return (
                      <div key={farm.farmId}>
                        <img
                          src={farm.imageUrl}
                          alt="Farm Icon"
                          width={18}
                          height={18}
                          className="green"
                        />
                      </div>
                    );
                  }
                  return (
                    <div className="icon-plant">
                      <TbPlant size={18} color="green" />
                    </div>
                  ); 
                })}
              </div> */}
            </div>
            <div className="stage-wrapper">
              {props?.cropStage !== "-" ? (
                <InventoryCropStage stagedata={props?.cropStage} />
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "50%",
                    }}
                  >
                    {showTextStage ? (
                      <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <Typography
                        variant="h5"
                        fontWeight={600}
                        fontFamily={"Lexend Deca"}
                        sx={{ color: "red" }}
                      >
                        Embrace Greenery on Your Farm!
                      </Typography>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="harvestingGraph">
            <div>
              <Typography variant="h4" color={colors.black.DEFAULT}>
                Harvesting
              </Typography>

              <div className="icon-harvest">
                <AgricultureIcon sx={{ fontSize: 28, color: "orange" }} />
              </div>
            </div>
            <div className="harvest-wrapper">
              {props?.harvesting !== "-" ? (
                <InventoryHarvestingGraph harvestingdata={props?.harvesting} />
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "50%",
                    }}
                  >
                    {showTextHarvest ? (
                      <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <Typography
                        variant="h5"
                        fontWeight={600}
                        fontFamily={"Lexend Deca"}
                        sx={{ color: "red" }}
                      >
                        Cultivate Crops for Bountiful Yield!
                      </Typography>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="dataDisplay">
            <div style={{ marginRight: "auto" }}>
              <Typography variant="h4" color={colors.black.DEFAULT}>
                Occupied Area
              </Typography>
              <div className="icon-area">
                <PieChartIcon sx={{ fontSize: 24, color: "#70889f" }} />
              </div>
            </div>
            {props?.area !== "-" ? (
              <InventoryDataDisplay displaydata={props?.area} />
            ) : (
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "37.7%",
                  }}
                >
                  {showTextArea ? (
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      fontFamily={"Lexend Deca"}
                      sx={{ marginTop: "-10%", color: "red" }}
                    >
                      Open Fields Await!
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="damageGraph">
            <div>
              <Typography variant="h4" color={colors.black.DEFAULT}>
                Damage
              </Typography>
              <div className="icon-damage">
                <FontAwesomeIcon
                  icon={faPlantWilt}
                  size="1.5x"
                  style={{ color: "#dc3545" }}
                />
              </div>
            </div>
            <div className="damage-wrapper">
              {props?.damage !== "-" ? (
                <InventoryDamageGraph damagedata={props?.damage} />
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "90%",
                    }}
                  >
                    {showTextDamage ? (
                      <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <Typography
                        variant="h5"
                        fontWeight={600}
                        fontFamily={"Lexend Deca"}
                        sx={{ color: "red" }}
                      >
                        Damage-Free Farm!
                      </Typography>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function InventoryHome() {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  console.log("InventoryHome component mounted");
  const [data, setData] = useState({});
  // const [count, setCount] = useState(0);
  const [dataAvailable, setDataAvailable] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log("Component rendered!");

  useEffect(() => {
    console.log("useEffect hook triggered!");

    axiosInstance
      .get(API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE)
      .then((response) => {
        console.log("API response received:", response);

        const count = response?.data?.farmdetail?.rowCount;
        console.log("counttttttttttttttt", count);
        // setIsLoading(false);
        if (count === 0) {
          // Set your state here to true, for example:
          setDataAvailable(true);
        } else {
          setData(response?.data);
          setDataAvailable(false);
          // Set your state here to false, for example:
        }
      })
      .catch((error) => {
        setApiErrorOpen(true);
        console.log("error", error);
        // setIsLoading(false);
      });
  }, [dataAvailable]);

  console.log("===========inventory data========:", data);

  const farms = (data && data?.farmdetail && data?.farmdetail?.rows) || [];

  const containerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
  };

  if (!farms || farms?.length === 0) {
    const containerStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100%",
      backgroundSize: "cover",
    };

    return (
      <div className="box1-container">
        <div
          className="box-content"
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "45px",
            marginTop: "1%",
          }}
        >
          <Typography
            variant="h1"
            color={colors.green.DEFAULT}
            style={{
              marginLeft: "2%",
              marginTop: "1%",
            }}
          >
            Monitor Farms
          </Typography>
        </div>

        {dataAvailable ? (
          <Box style={containerStyle}>
            <Typography variant="h1" color={colors.black[200]}>
              No data available
            </Typography>
            <Typography variant="h2" color={colors.black[100]}>
              Please add farms
            </Typography>
          </Box>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <ScaleLoader color="#2C7B55" />
            </div>
            <div>
              {farms?.map((farm) => {
                const stage = data?.phasedetail?.rows?.find(
                  (row) => row?.farm_name === farm
                );
                const harvest = data?.harvestdetail?.rows?.find(
                  (row) => row?.farm_name === farm
                );
                const capacity = data?.capacitydetail?.rows?.find(
                  (row) => row?.farm_name === farm
                );
                const dump = data?.dumpdetail?.rows?.find(
                  (row) => row?.farm_name === farm
                );
                const isNullData = !stage || !harvest || !capacity || !dump;
                console.log("szdxcfkpol[", stage);
                return (
                  <FarmBox
                    key={farm}
                    name={farm}
                    farmId={farm.farm_id}
                    cropStage={stage?.farm_data ?? "-"}
                    harvesting={harvest?.crop_data ?? "-"}
                    area={capacity?.detail ?? "-"}
                    damage={dump?.crop_data ?? "-"}
                    isNullData={isNullData}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="box1-container">
        <div className="Rectwith1-box">
          <div
            className="bo1x-content"
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "15px",
              marginBottom: "5.8%",
            }}
          >
            <Typography variant="h1" color={colors.green.DEFAULT}>
              Monitor Farms
            </Typography>
          </div>
          <div>
            {farms &&
              farms?.map((farm) => {
                const stage = data?.phasedetail?.rows?.find(
                  (rows) => rows?.farm_name === farm.farm_name
                );
                const harvest = data?.harvestdetail?.rows?.find(
                  (rows) => rows?.farm_name === farm.farm_name
                );
                const capacity = data?.capacitydetail?.rows?.find(
                  (rows) => rows?.farm_name === farm.farm_name
                );
                const dump = data?.dumpdetail?.rows?.find(
                  (rows) => rows?.farm_name === farm.farm_name
                );
                const isNullData = !stage || !harvest || !capacity || !dump;

                return (
                  <FarmBox
                    name={farm.farm_name}
                    farmId={farm.farm_id}
                    key={farm.farm_name} // Add a unique key prop
                    cropStage={stage?.farm_data ?? "-"}
                    harvesting={harvest?.crop_data ?? "-"}
                    area={capacity?.detail ?? "-"}
                    damage={dump?.crop_data ?? "-"}
                    isNullData={isNullData}
                  />
                );
              })}
            {!farms || farms?.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100vh",
                }}
              >
                <ScaleLoader color="#2C7B55" />
              </div>
            ) : (
              // <Box style={containerStyle}>
              //   <Typography variant="h1" color={colors.black[200]}>
              //     No data available
              //   </Typography>
              //   <Typography variant="h2" color={colors.black[100]}>
              //     Please add farms
              //   </Typography>
              // </Box>
              <div>
                {farms?.farm_name?.map((farm) => {
                  const stage = data?.phasedetail?.rows?.find(
                    (row) => row?.farm_name === farm
                  );
                  const harvest = data?.harvestdetail?.rows?.find(
                    (row) => row?.farm_name === farm
                  );
                  const capacity = data?.capacitydetail?.rows?.find(
                    (row) => row?.farm_name === farm
                  );
                  const dump = data?.dumpdetail?.rows?.find(
                    (row) => row?.farm_name === farm
                  );
                  const isNullData = !stage || !harvest || !capacity || !dump;

                  return (
                    <FarmBox
                      key={farm}
                      farmId={farm.farm_id}
                      name={farm}
                      cropStage={stage?.farm_data ?? "-"}
                      harvesting={harvest?.crop_data ?? "-"}
                      area={capacity?.detail ?? "-"}
                      damage={dump?.crop_data ?? "-"}
                      isNullData={isNullData}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
}
export default InventoryHome;
