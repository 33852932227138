import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import NullScreen from "../../PopUpMsg/NullScreen";
import { ScaleLoader } from "react-spinners";
import SuccessPopUp from "../../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../../PopUpMsg/ErrorPopUp";
import Pagination from "@mui/material/Pagination";
import { GrChapterAdd } from "react-icons/gr";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ulid } from "ulid";
import SocketReportPopup from "../../../Attendance/HomePage/SocketReportPopup";
import {
  Grid,
  Button,
  Select,
  TextField,
  Box,
  Autocomplete,
  MenuItem,
  IconButton,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { TiTickOutline } from "react-icons/ti";
import { GiFruitBowl } from "react-icons/gi";
import Paper from "@mui/material/Paper";
import { CiCalendarDate } from "react-icons/ci";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IoIosPerson } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import { tokens } from "../../WMStheme";
import AddProductInventory from "./AddProductInventory";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const gridContainerStyle = {
  // marginTop:"1%",
  display: "flex",
  height: "63vh",
  width: "100%",
  overflowY: "hidden",
  //  backgroundColor: "red",
};
const containerStyle = {
  maxHeight: "68vh",
  height: "80%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  //  backgroundColor: "pink",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};
const columns = [
  { id: "name", label: "Product Name" },
  { id: "code", label: "Product Code" },
  { id: "uom", label: "UOM" },
  // { id: "total_qty", label: "Total Quantity" },
  { id: "total_avl_qty", label: "Available Quantity" },
  { id: "total_committed_qty", label: "Allocated Quantity" },
];

const dummyData = [
  {
    skuName: "Product 1",
    skuCode: "ABC123",
    totalQuantity: 100,
    availableQuantity: 75,
    allocatedQuantity: 20,
    openQuantity: 5,
  },
  {
    skuName: "Product 2",
    skuCode: "DEF456",
    totalQuantity: 150,
    availableQuantity: 120,
    allocatedQuantity: 25,
    openQuantity: 5,
  },
  // Add more dummy data as needed
];

function TableHeadComponent() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((column) => (
          <StyledTableCell key={column?.id} align="center">
            {column?.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function TableRowsComponent({ data1, datasending }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [datatocard, setdatatocard] = useState(null);

  const theme = useTheme();

  const handleRowClick = ({ row, datasending }) => {
    setSelectedRow(row);
    // navigate("/addpo");

    const selectedRowData = datasending?.actualledger?.rows?.find(
      (dataRow) =>
        dataRow?.expected?.mandatory_fields?.ledger_id === row?.ledger_id
    );

    setdatatocard(selectedRowData);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const handleSuccess = (newState) => {
    setApiSuccessOpen(newState);
  };

  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const handleFailure = (newState) => {
    setApiErrorOpen(newState);
  };

  const dialogRef = useRef(null);
  const handleBackdropClick = (event) => {
    // Check if the click event is on the dialog's backdrop and not on the dialog content
    if (dialogRef.current === event.target) {
      setOpenDialog(false);
    }
  };

  return (
    <>
      <TableBody>
        {data1?.map((row, index) => (
          <StyledTableRow
            key={index}
            onClick={() => handleRowClick({ row, datasending })}
            style={{ cursor: "default" }}
          >
            {columns?.map((column) => (
              <StyledTableCell key={column?.id} align="center">
                {row[column.id]}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </>
  );
}
const ProductFilter = ({ param }) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const oneWeekAgo = new Date();
  const [productNames, setProductNames] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);

  const [skuId, setSkuId] = useState(null);
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [inventoryData, setInventoryData] = useState([]);
  //const [inventoryData, setInventoryData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [warehouseId, setWarehouseId] = useState(null);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [generatedId, setGeneratedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [filterValues, setFilterValues] = useState({
    limit: 14,
    offset: offset,
  });
  useEffect(() => {
    const storedTemporaryFilterValues =
      localStorage.getItem("ProductfilterValue");
    const parsedFilterValues = JSON.parse(storedTemporaryFilterValues);

    if (storedTemporaryFilterValues) {
      console.log("name ", parsedFilterValues[0]?.name);

      console.log("warehouse_id ", parsedFilterValues[0]?.warehouse_id);
      // console.log("vendor_id ", parsedFilterValues[0]?.vendor_id);

      
      const ParsedFilterValues = {
        type: "product",
        warehouse_id:
          parsedFilterValues && parsedFilterValues[0]?.warehouse_id
            ? parsedFilterValues[0]?.warehouse_id
            : warehouseId,
        name:
          parsedFilterValues && parsedFilterValues[0]?.name
            ? parsedFilterValues[0]?.name : productNames,


        limit: 14,
        offset:
          parsedFilterValues && parsedFilterValues[0]?.offset
            ? parsedFilterValues[0]?.offset
            : offset,
      };
      console.log("parsedValues----------", parsedFilterValues);
      setSkuId(parsedFilterValues[0]?.name);

      setWarehouseId(parsedFilterValues[0]?.warehouse_id);

      setOffSet(parsedFilterValues[0]?.offset);

      setFilterValues(ParsedFilterValues);
      setRefreshCount((prevCount) => prevCount + 1);
      console.log("exist in loclastorage ");
    } else {
      console.log("not exist in loclastorage ");
      // If no stored values are found, set the default values
      const defaultTemporaryFilterValues = {
        type: "product",
        limit: 14,
        offset: offset,
        name: null,

        warehouse_id: null,
        
      };
      setFilterValues(defaultTemporaryFilterValues);
    }
  }, []);
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    limit: 14,
    offset: offset,
    name: null,
    sku_code: null,
    type: "product",
  });
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  useEffect(() => {
    const updatedFilterValues = {
      limit: 14,
      offset: offset,
      warehouse_id: warehouseId,
      name: skuId,
      sku_code: skuCode,
      type: "product",
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, skuId, skuCode, warehouseId]);

  useEffect(() => {
    fetchProductNames();
  }, []);

  useEffect(() => {
    fetchInventoryData(filterValues);
  }, [openAddProductDialog , filterValues,refreshCount]);

  const handleApplyClick = () => {
    const newFilterValues = {
      limit: 14,
      offset: 1,
      warehouse_id: temporaryFilterValues?.warehouse_id,
      name: temporaryFilterValues?.name,
      sku_code: temporaryFilterValues?.sku_code,
      type: "product",
    };

    setStartDate(temporaryFilterValues?.start_date);
    setEndDate(temporaryFilterValues?.end_date);
    setWarehouseId(temporaryFilterValues?.warehouse_id);
    setSkuId(temporaryFilterValues?.name);
    setSkuCode(temporaryFilterValues?.sku_code);
    setFilterValues(newFilterValues);
   
    localStorage.setItem(
      "ProductfilterValue",
      JSON.stringify([newFilterValues])
    );
    setRefreshCount((prevVal) => prevVal + 1);
    setOffSet(1);
    fetchInventoryData(newFilterValues);
  };

  const fetchProductNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_PRODUCT_MASTER_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data?.Data?.customer_list;
      setProductNames(data);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };
  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const fetchInventoryData = async (newParams) => {
    setIsLoading(true);
    if (newParams?.type === "product") {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_INVENTORY_DATA,
        {
          params: newParams,

          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data;
        console.log("data", data);
        setInventoryData(data?.inv_list);
        setTotalPages(data?.total_pages);
        setIsLoading(false);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsLoading(false);
      setErrorMessage("Please Try Again");
      setErrorPopupOpen(true);
    } }
    else {
      console.log("type is null, API call skipped");
    }
  };
  const onPageChange = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    localStorage.setItem("ProductfilterValue", JSON.stringify([updatedValues]));
    setRefreshCount((prevCount) => prevCount + 1);

    fetchInventoryData(updatedValues);
  };
  const handleRefreshClick = () => {
    const initialValues = {
      
      limit: 14,
      offset: 1,
      type: "product",
    };
    setSkuId(null);
    setSkuCode(null);
    setWarehouseId(null);
    
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    localStorage.removeItem("ProductfilterValue");
    fetchInventoryData(initialValues);
  };

  const handleAddProductClick = () => {
    setOpenAddProductDialog(true);
  };

  const handleGenerateReportClick = async () => {
    setIsLoading(true);
    const requestId = ulid();
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_INVENTORY_DATA,
        {
          params: {
            ...filterValues,
            download: 1,
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "10vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1.6}>
              <Grid item xs={12} sm={2} md={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={productNames || []}
                    getOptionLabel={(option) => option?.name || ""}
                    value={
                      productNames?.find(
                        (product) => product?.name === skuId
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setSkuId(newValue?.name || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select PRODUCT"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <GiFruitBowl />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    label="Select Warehouse"
                    value={warehouseId}
                    onChange={(e) => {
                      setWarehouseId(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Warehouse" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <CiCalendarDate />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {Array.isArray(warehouseData) &&
                      warehouseData?.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    borderColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  // disabled={ !warehouseId || !skuCode || !skuId}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    borderColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={2.9} md={2.9}></Grid>
              <Grid item xs={12} sm={2} md={2} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={
                    <GrChapterAdd
                      style={{ color: "#ffffff", fontSize: "23px" }}
                    />
                  }
                  sx={{
                    borderColor: "#34519fe6",
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleAddProductClick}
                  disabled={!userDetail?.b_inventory_add_product_inventory}
                >
                  <Typography variant="h5" color="#ffffff">
                    Add Product Inventory
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={1.5} md={1.5} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                  }}
                  size="small"
                  onClick={handleGenerateReportClick}
                  disabled={!inventoryData?.length}
                >
                  <Typography variant="h6" fontFamily="Lexend Deca">
                    Download Report
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {openDialog && (
            <SocketReportPopup
              setOpenDialog={setOpenDialog}
              requestId={generatedId}
            />
          )}
        </Grid>
        <Grid container spacing={1} style={containerStyle}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : inventoryData?.length === 0 ? (
            <NullScreen containerheight="68vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 900 }} aria-label="customized table">
                    <TableHeadComponent />
                    <TableRowsComponent data1={inventoryData} />
                  </Table>
                </TableContainer>
              </div>
              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: "5px" }}
              >
                <Pagination
                  page={offset}
                  count={totalPages}
                  size="small"
                  defaultPage={
                    JSON.parse(localStorage.getItem("ProductfilterValue"))?.[0]
                      ?.offset || offset
                  }
                  key={refreshCount}
                  onChange={onPageChange}
                />
              </Grid>
            </>
          )}
        </Grid>

        {openAddProductDialog && (
          <AddProductInventory
            setOpenAddProductDialog={setOpenAddProductDialog}
          />
        )}
      </Box>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default ProductFilter;
