import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  Autocomplete,
  TextField,
  FormControl,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import ViewListIcon from "@mui/icons-material/ViewList";

import NullScreen from "../PopUpMsg/NullScreen";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { tokens } from "../WMStheme";

import { useTheme } from "@mui/material";
import { ulid } from "ulid";

import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import SocketReportPopup from "../../Attendance/HomePage/SocketReportPopup";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../PopUpMsg/SuccessPopUp";
import ErrorPopup from "../PopUpMsg/ErrorPopUp";
import { LuWarehouse } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import { IoIosPerson } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { GiFruitBowl } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { Divider } from "antd";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import Paper from "@mui/material/Paper";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const dummyData = [
  {
    product_name: "Product A",
    product_code: "ABC123",
    ratio: 2.5,
    uom: "Each",
    created_at: 1646100000000, // Example 13-digit epoch timestamp
    created_by: "John Doe",
    sku: [
      { sku_name: "apple", ratio: 3, sku_code: "A78" },
      { sku_name: "orange", ratio: 4, sku_code: "O278" },
    ],
  },
  {
    product_name: "Product B",
    product_code: "XYZ456",
    ratio: 1.8,
    uom: "Pack",
    created_at: 1646186400000, // Another example 13-digit epoch timestamp
    created_by: "Jane Doe",
    sku: [
      { sku_name: "banana", ratio: 2, sku_code: "B123" },
      { sku_name: "grape", ratio: 5, sku_code: "G456" },
    ],
  },
];
const columns = [
  { id: "button", label: "View Product Composition", type: "button" },
  { id: "product_name", label: "Product Name" },
  { id: "product_code", label: "Product Code" },
  { id: "product_uom", label: "UOM" },
  // { id: "created_at", label: "Created At" },
  // { id: "created_by", label: "Created By" },
];
const columns1 = [
  { id: "sku_name", label: "SKU Name" },
  { id: "sku_code", label: "SKU Code" },
  { id: "sku_uom", label: "UOM" },
  { id: "sku_product_ratio", label: "Ratio" },
];
const ProductLinkageReport = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  //const [productLinkageReport, setProductLinkageReportData] = useState(dummyData);
  const [productLinkageReport, setProductLinkageReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [customerNames, setCustomerNames] = useState([]);
  const [skuNames, setSkuNames] = useState([]);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseId, setWarehouseId] = useState("");
  const [generatedId, setGeneratedId] = useState(null);
  const [productNames, setProductNames] = useState([]);
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSkuData, setSelectedSkuData] = useState([]);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [SkuRatioDialog, setSkuRatioDialog] = useState(false);
  const [filterValues, setFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    limit: 14,
    offset: offset,
    product_id: null,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),

    product_id: null,
  });

  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,

      product_id: productId,

      limit: 14,
      offset: offset,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, customerId, productId, warehouseId]);

  useEffect(() => {
    fetchProductNames();
    // fetchCustomerNames();
    // fetchWarehouseNames();
    fetchGridData(filterValues);
  }, []);
  const fetchProductNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_PRODUCT_MASTER_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data?.Data?.customer_list;
      setProductNames(data);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };
  const handleApplyClick = () => {
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      limit: 14,
      offset: 1,

      product_id: temporaryFilterValues.product_id,
    };
    setStartDate(temporaryFilterValues.start_date);
    setEndDate(temporaryFilterValues.end_date);
    setCustomerId(temporaryFilterValues.customer_id);
    setProductId(temporaryFilterValues.product_id);

    setFilterValues(newFilterValues);
    fetchGridData(newFilterValues);
  };

  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchGridData(updatedValues);
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const fetchGridData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_REPORT_MODULE_PRODUCT_LINKAGE_REPORT,
        {
          params: {
            ...newParams,
            download: 0,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      console.log("data in fetchGridData--------", data);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setTotalPages(data?.total_pages);
        setProductLinkageReportData(data?.product_skus);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleGenerateReportClick = async () => {
    setIsLoading(true);
    const requestId = ulid();
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_REPORT_MODULE_PRODUCT_LINKAGE_REPORT,
        {
          params: {
            ...filterValues,
            download: 1,
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  const handleRefreshClick = () => {
    const initialValues = {
      limit: 14,
      offset: 1,
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
    };
    setOffSet(1);

    setProductId(null);

    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setProductLinkageReportData([]);
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    fetchGridData(initialValues);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const containerStyle = {
    maxHeight: "78vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "78vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };
  const containerStyle8 = {
    maxHeight: "74vh",
    height: "80%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    //  backgroundColor: "pink",
  };
  const gridContainerStyle = {
    marginTop: "1%",
    display: "flex",
    height: "71vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };
  const formatDateIST = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} || ${hours}:${minutes}`;
  };
  function TableHeadComponentDialog() {
    return (
      <TableHead>
        <TableRow>
          {columns1?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponentDialog({ data1 }) {
    return (
      <>
        <TableBody>
          {/* replace data1 from dummyData  */}
          {data1?.map((row, index) => (
            <StyledTableRow key={index} style={{ cursor: "pointer" }}>
              {columns1?.map((column) => (
                <StyledTableCell key={column?.id} align="center">
                  {["created_at", "updated_at"].includes(column.id)
                    ? formatDateIST(row[column.id])
                    : row[column.id]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </>
    );
  }
  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id}>{column?.label}</StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent() {
    const handleRowClick = (row) => {
      setSelectedSkuData(row);
      console.log("selected row data in tbalerow function", row);

      setSkuRatioDialog(true);
    };
    return (
      <TableBody>
        {productLinkageReport &&
          productLinkageReport?.map((row, index) => (
            <StyledTableRow key={index}>
              {columns?.map((column) => (
                <StyledTableCell key={column?.id}>
                  {["created_at"].includes(column.id) ? (
                    formatDateIST(row[column.id])
                  ) : column?.type === "button" ? (
                    <div
                      className="tooltip-cell"
                      title="Click to Link Customers"
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleRowClick(row)}
                        endIcon={<ViewListIcon />}
                        sx={{ backgroundColor: colors.green.DEFAULT }}
                      >
                        <Typography variant="h7" color={colors.white.DEFAULT}>
                          View Composition
                        </Typography>
                      </Button>
                    </div>
                  ) : (
                    row[column.id]
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
      </TableBody>
    );
  }
  const handleCloseSkuRatioDialog = () => {
    setSkuRatioDialog(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "8vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1.6}>
              <Grid item xs={12} sm={2} md={2} marginTop={"6px"}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={productNames || []}
                    getOptionLabel={(option) => option?.name || ""}
                    value={
                      productNames?.find(
                        (product) => product?.id === productId
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setProductId(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select PRODUCT"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <GiFruitBowl />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8} marginTop={"6px"}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!startDate || !endDate}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8} marginTop={"6px"}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={6.2} md={6.2}></Grid>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                marginTop={"6px"}
                textAlign="right"
              >
                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                  }}
                  size="small"
                  onClick={handleGenerateReportClick}
                  disabled={!productLinkageReport?.length}
                >
                  <Typography variant="h6" fontFamily="Lexend Deca">
                    Download Report
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {openDialog && (
            <SocketReportPopup
              setOpenDialog={setOpenDialog}
              requestId={generatedId}
            />
          )}
        </Grid>

        <Grid container spacing={1} style={containerStyle}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : productLinkageReport?.length === 0 ? (
            <NullScreen containerheight="78vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 200 }} aria-label="customized table">
                    <TableHeadComponent />
                    <TableRowsComponent data1={productLinkageReport} />
                  </Table>
                </TableContainer>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <div style={{ flex: "1" }}></div>
                <Pagination
                  count={totalPages}
                  page={offset}
                  onChange={handleChangePage}
                  style={{ color: "#5e89c7" }}
                />
              </div>
            </>
          )}
        </Grid>
      </Box>
      <Dialog
        open={SkuRatioDialog}
        onClose={handleCloseSkuRatioDialog}
        maxWidth="lg"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          <Typography
            variant="h2"
            style={{ marginLeft: "0.1%" }}
            color="#0C0C0C"
          >
            Product Composition : SKU's are mapped in following ratio's{" "}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseSkuRatioDialog}
            style={{ position: "absolute", right: "8px", top: "1px" }}
          >
            <IoMdClose style={{ fontSize: "32" }} />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ marginTop: "2%" }}>
          <Grid container spacing={1} style={containerStyle8}>
            {isLoading ? (
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={isLoading} />
              </div>
            ) : productLinkageReport?.sku_items?.length === 0 ? (
              <NullScreen containerheight="74vh" containerWidth="100%" />
            ) : (
              <>
                <div style={gridContainerStyle}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 900 }} aria-label="customized table">
                      <TableHeadComponentDialog />
                      <TableRowsComponentDialog
                        data1={selectedSkuData?.sku_items}
                      />
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default ProductLinkageReport;
