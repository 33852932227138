import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  useTheme,
  Stack,
  Divider,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import SaveAlert from "../../../SaveAlert";
import ApiErrorAlert from "../../../ApiErrorAlert";
import GrassIcon from "@mui/icons-material/Grass";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Search from "../../../Assets/ConfigurationImg/search.png";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { tokens } from "../../../theme";

import DynamicDialog from "../../../Components/DialogBoxComponent/DynamicDialog";
import { ScaleLoader } from "react-spinners";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const FinanceReportPage = ({
  dataFinanceactual,
  dataFinanceexp,
  selectedkey,
  title,
}) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  console.log("data in finannace dxcfgvbhnjui", dataFinanceexp);
  console.log("data in actual finnace", dataFinanceactual);

  const [data, setData] = useState([]);
  const theme = useTheme();
  const [farmId, setSelectedFarmId] = useState(null);
  const [farmOptions, setFarmOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const colors = tokens(theme.palette.mode);
  const [clickedColumnsActual, setClickedColumnsActual] = useState([]);

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [isNoDataDialogOpen, setIsNoDataDialogOpen] = useState(false);
  const [dailogBoxOpen, setDialogBoxOpen] = useState(false); // New state for no data condition
  const [clickedColumns, setClickedColumns] = useState([]); // New state to hold clicked columns
  const [dorowCount, setdoRowCount] = useState("");
  const [selectedConverter, setSelectedConverter] = useState(null);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const [dateError, setDateError] = useState("");
  const [download, setDownload] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const getDefaultStartDate = () => {
    const lastMonth = new Date();
    lastMonth?.setMonth(lastMonth.getMonth() - 1);
    return lastMonth?.toISOString().split("T")[0];
  };

  useEffect(() => {
    fetchFarmData();
  }, []);

  const fetchFarmData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      const data = await response?.data;
      const farms = data?.farmdetail?.rows || [];
      setFarmOptions(farms);
    } catch (error) {
      setLoading(false);
      setApiErrorOpen(true);
    }
  };

  // Specify the elements to remove
  const elementsToRemove = [
    "employee_id_fk",
    "farm_id_fk",
    "meta",
    "inv_task",
    "inv_data",
  ];
  const elementsToRemoveActual = ["meta", "attachment"];
  const newArray = dataFinanceexp?.filter(
    (element) => !elementsToRemove.includes(element)
  );
  const newActualArray = dataFinanceactual?.filter(
    (element) => !elementsToRemoveActual.includes(element)
  );
  console.log(newArray);
  console.log(newActualArray);
  const XLSX = require("xlsx");
  const downloadCSV = (
    jsonData,
    csvDataExpected,
    csvDataActual,
    selectedColumnsExpected,
    selectedColumnsActual,
    name
  ) => {
    console.log("jsondata", jsonData);
    console.log("prachi", csvDataExpected);
    console.log("poiuytrfgbn", csvDataActual);
    console.log("333333333333333", selectedColumnsExpected);
    console.log("5555555555555", selectedColumnsActual);
    const combinedHeaders = [
      "",
      "",
      "",
      "Expected",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Actual",
    ];

    const selectedColumnsRow1 = [
      ...selectedColumnsExpected,
      "",
      "",
      "", // Add an empty cell to create a gap
      ...selectedColumnsActual,
    ];
    console.log("headerrrrrrrrrrrrrrrr", selectedColumnsRow1);
    // Create CSV content for the combined data
    const combinedData = [combinedHeaders, selectedColumnsRow1];
    // Insert an empty row for spacing in the first row only

    // Find the maximum number of rows in the Expected and Actual Finance data
    const maxRows = Math.max(csvDataExpected.length, csvDataActual.length);
    // Extract data for selected columns from the JSON data
    const expectedData = jsonData?.map((data) =>
      selectedColumnsExpected?.map((col) => data[col] || "")
    );
    const actualData = jsonData?.map((data) =>
      selectedColumnsActual?.map((col) => data[col] || "")
    );

    // Combine the data for Expected and Actual into a single array
    const combinedData55 = expectedData?.map((row, index) => [
      ...row,
      "",
      ...actualData[index],
    ]);

    console.log("expecteddata34567890", expectedData);
    console.log("actualata34567890", actualData);
    console.log("combineeeeeeeeeeeeeee", combinedData55);
    // Combine the rows from JSON data into the combinedData array
    // Iterate over the maximum number of rows

    console.log("combinedData2w3e45rt6y78ui90op", combinedData);
    // Format date values as 'MM/DD/YYYY' for both expected and actual data
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    // Format the combinedData55 array to replace date strings with the formatted ones
    const formattedCombinedData55 = combinedData55?.map((row) => {
      return row.map((col) => {
        // Check if the column text represents a date and format it
        if (col && col.text && /\d{4}-\d{2}-\d{2}/.test(col)) {
          return formatDate(col);
        }
        return col;
      });
    });

    // Format the combinedData into a CSV string
    const csvContent = [
      combinedHeaders,
      selectedColumnsRow1,
      ...formattedCombinedData55,
    ]
      .map((row) => row.map((col) => `"${col || ""}"`).join(","))
      .join("\n");

    console.log("content", csvContent);

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link

    const link = document.createElement("a");
    const now = new Date();
    link.href = URL.createObjectURL(blob);
    const date = now.toISOString().split("T")[0];
    const formattedTime = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const timeParts = formattedTime.split(":");
    const formattedTimeString = `${timeParts[0]}:${timeParts[1]}:${timeParts[2]}`;

    // link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${name}_${date}-${formattedTimeString}.csv`);

    document.body.appendChild(link);

    // Trigger the download
    link.click();

    document.body.removeChild(link);
  };

  const downloadExcel88 = (
    jsonData,
    dataFinanceexp,
    dataFinanceactual,
    selectedColumnsExpected,
    selectedColumnsActual,
    name
  ) => {
    // Create a new workbook
    console.log("data", jsonData);
    console.log("excelnameeeeeeeeee", name);
    const workbook = XLSX.utils.book_new();

    // Create sheets for Expected and Actual data
    const expectedSheet = XLSX.utils.json_to_sheet(jsonData, {
      header: selectedColumnsExpected,
    });
    const actualSheet = XLSX.utils.json_to_sheet(jsonData, {
      header: selectedColumnsActual,
    });

    // Add the sheets to the workbook
    XLSX.utils.book_append_sheet(workbook, expectedSheet, "Expected");
    XLSX.utils.book_append_sheet(workbook, actualSheet, "Actual");

    // Create a formatted date-time string for the file name
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const formattedTime = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    const timeParts = formattedTime.split(":");
    const formattedTimeString = `${timeParts[0]}-${timeParts[1]}-${timeParts[2]}`;

    // Download the Excel file
    XLSX.writeFile(workbook, `${name}_${date}-${formattedTimeString}.xlsx`);
  };

  // Example usage:
  // downloadExcel(responseData.rows, dataFinanceexp, dataFinanceactual, selectedColumnsExpected, selectedColumnsActual, "FinanceReport");

  const getDefaultEndDate = () => {
    return new Date().toISOString().split("T")[0];
  };
  const [clickedColumnsExpected, setClickedColumnsExpected] = useState([]);
  //   const [clickedColumnsActual, setClickedColumnsActual] = useState([]);
  const [isUpperCheckboxChecked, setIsUpperCheckboxChecked] = useState(false);
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [selectedColumnsExpected, setSelectedColumnsExpected] = useState([]);
  const [selectedColumnsActual, setSelectedColumnsActual] = useState([]);
  const handleDownload = (format) => {
    setSelectedDownloadFormat(format);
    setIsPlaceholderVisible(false);
  };

  const handleColumnToggle = (column, isExpectedFinance) => {
    if (isExpectedFinance) {
      setClickedColumns((prevClickedColumns) => {
        if (prevClickedColumns?.includes(column)) {
          // return prevClickedColumns.filter((clickedCol) => clickedCol !== column);
        } else {
          return [...prevClickedColumns, column];
        }
      });
    } else {
      setClickedColumnsActual((prevClickedColumnsActual) => {
        if (prevClickedColumnsActual?.includes(column)) {
          return prevClickedColumnsActual?.filter(
            (clickedCol) => clickedCol !== column
          );
        } else {
          return [...prevClickedColumnsActual, column];
        }
      });
    }
  };

  const fetchDataFromApi = async () => {
    const requestData = {
      name: selectedkey,
      budget_plan: selectedColumnsExpected,
      budget_actual: selectedColumnsActual,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_REPORT_MODULE_SELECT_COLUMNDATA,
        requestData,
        {
          params: {
            farm_id: farmId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const responseData = response?.data;
        console.log(
          "APIIIIIIIIIIIIIIIIII RESPONSEEEEEEEEEEEEEEEE",
          responseData
        );
        const newData = responseData?.rows;
        setData(newData);

        const count = responseData?.rowCount;
        console.log("Countttttttttttttt", count);

        if (count === 0) {
          setIsNoDataDialogOpen(!isNoDataDialogOpen);
          setIsSubmitClicked(false);
        } else {
          setIsNoDataDialogOpen(false);

          setDialogBoxOpen(!dailogBoxOpen);
          setIsSubmitClicked(true);
          switch (selectedDownloadFormat) {
            case "csv":
              // Handle CSV download
              downloadCSV(
                responseData.rows,
                dataFinanceexp,
                dataFinanceactual,
                selectedColumnsExpected,
                selectedColumnsActual,
                "FinanceReport"
              );

              break;
            case "excel":
              // Handle Excel download
              downloadExcel88(
                responseData.rows,
                dataFinanceexp,
                dataFinanceactual,
                selectedColumnsExpected,
                selectedColumnsActual,
                "FinanceReport"
              );
              break;
            // case 'pdf':
            //   // Handle PDF download
            //   downloadPDF(newData, Object?.keys(selectedColumns)?.filter(col => selectedColumns[col]),title);

            // break;
            default:
              // Handle other formats if needed
              break;
          }
        }
      }
    } catch (error) {
      console.log("API Error:", error);
      setApiErrorOpen(true);
    }
  };
  const handleStartDateBlur = () => {
    if (endDate && new Date(startDate) >= new Date(endDate)) {
      setDateError("Start date should be less than end date");
    } else {
      setDateError("");
    }
  };
  const handleEndDateBlur = () => {
    if (startDate && new Date(startDate) >= new Date(endDate)) {
      setDateError("Start date should be less than end date");
    } else {
      setDateError("");
    }
  };

  const handleUpperCheckboxClick = () => {
    if (!isUpperCheckboxChecked) {
      setSelectedColumnsExpected(newArray);
      setSelectedColumnsActual(newActualArray);
      setIsCheckboxSelected(true);
    } else {
      setSelectedColumnsExpected([]);
      setSelectedColumnsActual([]);
      setIsCheckboxSelected(false);
    }
    setIsUpperCheckboxChecked(!isUpperCheckboxChecked);
  };

  const handleOkClose = () => {
    setDialogBoxOpen(false);
    setApiSuccessOpen(true);
  };

  // Function to handle individual column checkbox click for Expected Finance
  const handleExpectedFinanceCheckboxClick = (column) => {
    if (!selectedColumnsExpected?.includes(column)) {
      setSelectedColumnsExpected([...selectedColumnsExpected, column]);
      setIsCheckboxSelected(true);
    } else {
      setSelectedColumnsExpected(
        selectedColumnsExpected?.filter((col) => col !== column)
      );
      setIsCheckboxSelected(false);
    }
  };
  console.log("expecetd column clicked", selectedColumnsExpected);
  // Function to handle individual column checkbox click for Actual Finance
  const handleActualFinanceCheckboxClick = (column) => {
    if (!selectedColumnsActual?.includes(column)) {
      setSelectedColumnsActual([...selectedColumnsActual, column]);
      setIsCheckboxSelected(true);
    } else {
      setSelectedColumnsActual(
        selectedColumnsActual?.filter((col) => col !== column)
      );
      setIsCheckboxSelected(false);
    }
  };
  console.log("actual column", selectedColumnsActual);
  const sixMonthsAgo = new Date();
  sixMonthsAgo?.setMonth(sixMonthsAgo.getMonth() - 6);
  const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split("T")[0];
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState(null);

  const handleDateChange = (dates, dateStrings) => {
    console.log("Formatted Dates:", dateStrings);
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            zIndex: "1",
          }}
        >
          <ScaleLoader color="#2C7B55" />
        </div>
      ) : (
        <>
          <Grid
            sx={{
              marginTop: "1.5%",
              height: "480px",
              marginLeft: "1%",
              backgroundColor: "#ecf4f0",
              boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: 6,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={2} sm={1} marginTop={"6px"}>
                <Checkbox
                  style={{ marginLeft: "43%" }}
                  onChange={handleUpperCheckboxClick}
                />
              </Grid>
              <Grid item xs={12} sm={3} marginTop={"6px"}>
                <RangePicker
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  style={{
                    backgroundColor: "transparent",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2} marginTop={"6px"}>
                <FormControl fullWidth>
                  <InputLabel>Select Farm</InputLabel>
                  <Select
                    label="Select Farm"
                    value={farmId}
                    onChange={(e) => {
                      setSelectedFarmId(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Select Farm" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <GrassIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Farm
                    </MenuItem>
                    {farmOptions.map((farm) => (
                      <MenuItem key={farm.farm_id} value={farm.farm_id}>
                        {farm.farm_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} marginTop={"6px"}>
                <FormControl fullWidth>
                  <InputLabel>Select File Format</InputLabel>
                  <Select
                    label="Select File Format"
                    value={selectedDownloadFormat}
                    onChange={(e) => handleDownload(e.target.value)}
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Select File Format" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <InsertDriveFileIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select File Format
                    </MenuItem>
                    <MenuItem value="csv">CSV</MenuItem>
                    <MenuItem value="excel">Excel</MenuItem>
                    {/* <MenuItem value="pdf">PDF</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} marginTop={"6px"}>
                <Button
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  sx={{ backgroundColor: "#2c7B55", color: "#ffffff" }}
                  onClick={fetchDataFromApi}
                  disabled={selectedDownloadFormat === null || farmId === null}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={49}>
              <div style={{ marginLeft: "4%" }}>
                <Typography variant="h2" sx={{ fontWeight: 300 }}>
                  Expected Finance
                </Typography>
              </div>
              <div>
                <Typography variant="h2" sx={{ fontWeight: 300 }}>
                  Actual Finance
                </Typography>
              </div>
            </Stack>

            <Stack direction="row" style={{ marginTop: "20px" }}>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "4.2%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "2%" }}
                >
                  Column
                </Typography>
                {newArray?.map((column) => (
                  <div
                    key={column.value}
                    style={{
                      marginLeft: "-6%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={selectedColumnsExpected?.includes(column)}
                      onChange={() =>
                        handleExpectedFinanceCheckboxClick(column)
                      }
                      color="primary"
                    />
                    <Typography variant="h5">{column}</Typography>
                  </div>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "14.7%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "20%" }}
                >
                  Status
                </Typography>

                {newArray?.map((column, index) => (
                  <Typography
                    key={column.value}
                    variant="h5"
                    color={colors.black[200]}
                    style={{
                      marginBottom:
                        index !== newArray.length - 1 ? "20px" : "0",
                    }}
                  >
                    Active
                  </Typography>
                ))}
              </Grid>
              <Divider
                variant="middle"
                orientation="vertical"
                flexItem
                sx={{ backgroundColor: "#000000", marginLeft: "12%" }}
              />
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "4.2%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "2%" }}
                >
                  Column
                </Typography>
                {newActualArray?.map((column) => (
                  <div
                    key={column.value}
                    style={{
                      marginLeft: "-6%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={selectedColumnsActual?.includes(column)} // Use the new state variable for "Actual Finance"
                      onChange={() => handleActualFinanceCheckboxClick(column)}
                      color="primary"
                    />
                    <Typography variant="h5">{column}</Typography>
                  </div>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "14.7%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "20%" }}
                >
                  Status
                </Typography>

                {newActualArray?.map((column, index) => (
                  <Typography
                    key={column.value}
                    variant="h5"
                    color={colors.black[200]}
                    style={{
                      marginBottom:
                        index !== newActualArray?.length - 1 ? "20px" : "0",
                    }}
                  >
                    Active
                  </Typography>
                ))}
              </Grid>
            </Stack>
          </Grid>
          <>
            {isNoDataDialogOpen && (
              <DynamicDialog
                open={isNoDataDialogOpen}
                onClose={() => setIsNoDataDialogOpen(false)}
                content="No Data Found for Report!!!"
                img={Search}
                actionText="OK"
                dialogStyle={{
                  width: "360px",
                  marginLeft: "37%",
                  marginRight: "20%",
                }}
                imgStyle={{ width: "20%", height: "20%" }}
              />
            )}
          </>
          <>
            {dailogBoxOpen && (
              <DynamicDialog
                open={dailogBoxOpen}
                onClose={handleOkClose}
                content="Great! Download Report!!!"
                img={Search}
                actionText="OK"
                dialogStyle={{
                  width: "360px",
                  marginLeft: "37%",
                  marginRight: "20%",
                }}
                imgStyle={{ width: "20%", height: "20%" }}
              />
            )}
          </>
        </>
      )}

      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)}
      />
    </>
  );
};

export default FinanceReportPage;
