import { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import { MdMenuBook } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import { IoIosPerson } from "react-icons/io";
import { GrChapterAdd } from "react-icons/gr";
import dayjs from "dayjs";
import SocketReportPopUp from "../../Attendance/HomePage/SocketReportPopup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ulid } from "ulid";

import axios from "axios";
import NullScreen from "../PopUpMsg/NullScreen";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import { setPoAction } from "../actions/poAction";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AutoComplete, DatePicker } from "antd";
import { useDispatch } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { ScaleLoader } from "react-spinners";
import { setSelectedSO } from "../actions/soAction";
import {
  Dialog,
  Stack,
  DialogContent,
  useTheme,
  Grid,
  Autocomplete,
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Select,
  FormControl,
  Divider,
  Button,
  InputAdornment,
  InputLabel,
  DialogTitle,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Label } from "semantic-ui-react";
import { tokens } from "../WMStheme";
import POStatContainer from "./POStatContainer";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const columns = [
  { id: "int_po_no", label: "PO no." },
  { id: "wh_name", label: "Warehouse" },
  { id: "vendor_name", label: "Vendor" },
  { id: "status", label: "Status" },
  { id: "type", label: "PO Type" },
  { id: "delivery_date", label: "Delivery Date" },
  { id: "created_at", label: "Created At" },
];

const formatDate = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return;
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatDateIST = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} || ${hours}:${minutes}`;
};
const gridContainerStyle = {
  display: "flex",
  height: "66vh",
  width: "100%",
  overflowY: "hidden",
  backgroundColor: "red",
};
const containerStyle8 = {
  maxHeight: "74vh",
  height: "80%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  // backgroundColor: "pink",
};
const statContainerStyle = {
  height: "10vh",
  // backgroundColor: "blue",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};
function TableHeadComponent() {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <StyledTableCell key={column.id} align="center">
            {column.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TableRowsComponent({ data1, datasending, status, setStatus }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [datatocard, setdatatocard] = useState(null);
  const theme = useTheme();

  const getStatusDisplayText = (backendStatus) => {
    const statusMappings = {
      pending: "Pending",
      release: "Release",
      partialreceived: "Pending For Receiving",
      received: "Receiving Done ",
      return: "GRN Confirmation Pending",
      zoho: "Sent to Zoho",

      cancel: "Cancel",
      // Add more status mappings as needed
    };

    return statusMappings[backendStatus] || backendStatus;
  };
  const StatusTag = ({ status }) => {
    const displayText = getStatusDisplayText(status);

    let tagColor;

    switch (displayText) {
      case "Pending":
        tagColor = "orange";
        break;
      case "Release":
        tagColor = "green";
        break;
      case "Pending For Receiving":
        tagColor = "purple";
        break;
      case "Receiving Done ":
        tagColor = "brown";
        break;
      case "GRN Confirmation Pending":
        tagColor = "blue";
        break;
      case "Sent to Zoho":
        tagColor = "magenta";
        break;
      case "Cancel":
        tagColor = "red";
        break;
      // Add more cases as needed for different statuses

      default:
        tagColor = "gray";
        break;
    }

    return (
      <Label
        style={{ backgroundColor: tagColor, color: "white" }}
        tag
        size="small"
      >
        {displayText}
      </Label>
    );
  };
  const handleRowClick = ({ row, datasending }) => {
    setSelectedRow(row);
    dispatch(setPoAction("edit", row));
    navigate("/addpo");

    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const handleSuccess = (newState) => {
    setApiSuccessOpen(newState);
  };
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const handleFailure = (newState) => {
    setApiErrorOpen(newState);
  };
  const dialogRef = useRef(null);
  const handleBackdropClick = (event) => {
    // Check if the click event is on the dialog's backdrop and not on the dialog content
    if (dialogRef.current === event.target) {
      setOpenDialog(false);
    }
  };
  return (
    <>
      <TableBody>
        {data1?.map((row, index) => (
          <StyledTableRow
            key={index}
            onClick={() => handleRowClick({ row, datasending })}
            style={{ cursor: "pointer" }}
          >
            {columns?.map((column) => (
              <StyledTableCell key={column?.id} align="center">
                {column?.id === "status" ? (
                  <StatusTag status={row[column?.id]} />
                ) : column?.id === "created_at" ? (
                  formatDateIST(row[column?.id])
                ) : column?.id === "delivery_date" ? (
                  formatDate(row[column?.id])
                ) : (
                  row[column?.id]
                )}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </>
  );
}

const POMainPage = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const theme = useTheme();
  const dateFormat = "DD/MM/YYYY";
  const navigate = useNavigate();
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [poNum, setPoNum] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [vendorNames, setVendorNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [poData, setPoData] = useState(dummyData);
  const [poData, setPoData] = useState([]);
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const handleAddNew = () => {
    dispatch(setPoAction("add", null));

    navigate("/addpo");
  };
  const statusOptions = [
    { key: "pending", value: "Pending" },
    { key: "release", value: "Release" },
    { key: "partialreceived", value: "Pending For Receiving" },
    { key: "received", value: "Receiving Done" },
    { key: "return", value: "GRN Confirmation Pending" },
    { key: "zoho", value: "Sent to Zoho" },

    { key: "cancel", value: "Cancel" },
    // Add more status options as needed
  ];

  const [refreshCount, setRefreshCount] = useState(1);
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [warehouseId, setWarehouseId] = useState(null);
  const [downloadActive, setDownloadActive] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [offset, setOffSet] = useState(1);
  const [generatedId, setGeneratedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [poStats, setPoStats] = useState({});
  const [filterValues, setFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    limit: 12,
    offset: offset,
  });
  useEffect(() => {
    const storedTemporaryFilterValues = localStorage.getItem("POfilterValue");
    const parsedFilterValues = JSON.parse(storedTemporaryFilterValues);

    if (storedTemporaryFilterValues) {
      console.log("start date ", parsedFilterValues[0]?.start_date);
      console.log("End date ", parsedFilterValues[0]?.end_date);
      console.log("warehouse_id ", parsedFilterValues[0]?.warehouse_id);
      console.log("vendor_id ", parsedFilterValues[0]?.vendor_id);

      const ParsedFilterValues = {
        start_date:
          parsedFilterValues && parsedFilterValues[0]?.start_date
            ? parsedFilterValues[0]?.start_date
            : startDate,

        end_date:
          parsedFilterValues && parsedFilterValues[0]?.end_date
            ? parsedFilterValues[0]?.end_date
            : endDate,

        warehouse_id:
          parsedFilterValues && parsedFilterValues[0]?.warehouse_id
            ? parsedFilterValues[0]?.warehouse_id
            : warehouseId,
        vendor_id:
          parsedFilterValues && parsedFilterValues[0]?.vendor_id
            ? parsedFilterValues[0]?.vendor_id
            : vendorId,

        int_po_no:
          parsedFilterValues && parsedFilterValues[0]?.int_po_no
            ? parsedFilterValues[0]?.int_po_no
            : poNum,

        status:
          parsedFilterValues && parsedFilterValues[0]?.status
            ? parsedFilterValues[0]?.status
            : status,

        limit: 12,
        offset:
          parsedFilterValues && parsedFilterValues[0]?.offset
            ? parsedFilterValues[0]?.offset
            : offset,
      };
      console.log("parsedValues----------", parsedFilterValues);
      setStartDate(parsedFilterValues[0]?.start_date);
      setEndDate(parsedFilterValues[0]?.end_date);

      setWarehouseId(parsedFilterValues[0]?.warehouse_id);
      setVendorId(parsedFilterValues[0]?.vendor_id);
      setPoNum(parsedFilterValues[0]?.int_po_no);
      setStatus(parsedFilterValues[0]?.status);
      setOffSet(parsedFilterValues[0]?.offset);

      setFilterValues(ParsedFilterValues);
      setRefreshCount((prevCount) => prevCount + 1);
      console.log("exist in loclastorage ");
    } else {
      console.log("not exist in loclastorage ");
      // If no stored values are found, set the default values
      const defaultTemporaryFilterValues = {
        start_date: oneWeekAgo.getTime(),
        end_date: endDateTime.getTime(),
        limit: 12,
        offset: offset,
      };
      setFilterValues(defaultTemporaryFilterValues);
    }
  }, []);

  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    sku_id: null,
    int_po_no: null,
    warehouse_id: null,
    status: status,
  });

  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, [warehouseId]); // The empty dependency array ensures that this effect runs only once on component mount

  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,

      end_date: endDate,
      warehouse_id: warehouseId,
      vendor_id: vendorId,
      int_po_no: poNum,
      status: status,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, vendorId, status, poNum, warehouseId]);

  useEffect(() => {
    fetchVendorNames();
  }, []);

  useEffect(() => {
    fetchPOData(filterValues);
  }, [filterValues, refreshCount]);
  const handleDateChange = (dates, dateStrings) => {
    console.log("dateStrings--------", dateStrings);
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
    console.log("startDateTime--------", startDateTime);
    console.log("endOfDay--------", endOfDay);
  };
  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const handleApplyClick = () => {
    const startDateObj = new Date(temporaryFilterValues.start_date);
    const endDateObj = new Date(temporaryFilterValues.end_date);
    
    // Calculate the difference in days
    const differenceDays = Math.abs((endDateObj.getTime() - startDateObj.getTime()) / (1000 * 60 * 60 * 24));
    
    // Check if the difference is greater than a week
    const isDifferenceGreaterThanAWeek = differenceDays < 15;
    console.log("difference-----.",isDifferenceGreaterThanAWeek);
    // Set downloadActive based on the condition
    setDownloadActive(!isDifferenceGreaterThanAWeek);
    
    const newFilterValues = {
      start_date: temporaryFilterValues?.start_date,
      end_date: temporaryFilterValues?.end_date,
      warehouse_id: temporaryFilterValues?.warehouse_id,
      vendor_id: temporaryFilterValues?.vendor_id,
      int_po_no: temporaryFilterValues?.int_po_no,
      status: temporaryFilterValues?.status,
      limit: 12,
      offset: 1,
    };
    console.log("int po no ", temporaryFilterValues?.int_po_no);
    setStartDate(temporaryFilterValues?.start_date);
    setEndDate(temporaryFilterValues?.end_date);
    setWarehouseId(temporaryFilterValues?.warehouse_id);
    setVendorId(temporaryFilterValues?.vendor_id);
    setPoNum(temporaryFilterValues?.int_po_no);
    setStatus(temporaryFilterValues?.status);
    setFilterValues(newFilterValues);
    localStorage.setItem("POfilterValue", JSON.stringify([newFilterValues]));
    setOffSet(1);
    fetchPOData(newFilterValues);
  };
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const fetchVendorNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_VENDOR_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data?.data?.vendor_list;
      setVendorNames(data); // Remove the square brackets
    } catch (error) {
      console.log("Error fetching vendor data:", error);
    }
  };

  const fetchPOData = async (newParams) => {
    setIsLoading(true);
    const updatedFilterValues = { ...newParams };

    // Iterate over each key in the filterValues object
    for (const key in updatedFilterValues) {
      // Check if the value corresponding to the key is a string and is an empty string
      if (
        typeof updatedFilterValues[key] === "string" &&
        updatedFilterValues[key].trim() === ""
      ) {
        // Set the value to null
        updatedFilterValues[key] = null;
      }
    }

    // Log the updated filterValues
    console.log("updated filtervalues in fetch data ------->", updatedFilterValues);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_PO_LIST, {
        params: updatedFilterValues,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.Data;
        console.log("data", data);
        setPoData(data?.po_list);
        setTotalPages(data?.total_pages);
        setPoStats(data?.po_stats);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };
  const onPageChange = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    localStorage.setItem("POfilterValue", JSON.stringify([updatedValues]));
    setRefreshCount((prevCount) => prevCount + 1);

    fetchPOData(updatedValues);
  };
  const handleGenerateReportClick = async () => {
    setIsLoading(true);
    const requestId = ulid();
    console.log("FilterValues:", filterValues);

    // Clone the original filterValues object

    // Clone the original filterValues object
    const updatedFilterValues = { ...filterValues };

    // Iterate over each key in the filterValues object
    for (const key in updatedFilterValues) {
      // Check if the value corresponding to the key is a string and is an empty string
      if (
        typeof updatedFilterValues[key] === "string" &&
        updatedFilterValues[key].trim() === ""
      ) {
        // Set the value to null
        updatedFilterValues[key] = null;
      }
    }

    // Log the updated filterValues
    console.log("updated filtervalues------->", updatedFilterValues);

    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_PO_DOWNLOAD_ORDERS,
        {
          params: {
            ...updatedFilterValues,

            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };
  const handleRefreshClick = () => {
    const initialValues = {
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
      limit: 12,
      offset: 1,
    };
    setOffSet(1);
    setDownloadActive(true);
    setVendorId(null);
    setPoNum("");
    setWarehouseId(null);
    setStatus(null);
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    localStorage.removeItem("POfilterValue");

    fetchPOData(initialValues);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Typography variant="h1" color={colors.green.DEFAULT}>
            Purchase Order
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "5vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
                <RangePicker
                  onChange={handleDateChange}
                  // size="large"
                  key={refreshCount}
                  format={dateFormat}
                  defaultValue={[
                    dayjs(formatDate(startDate), dateFormat),
                    dayjs(formatDate(endDate), dateFormat),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={1} md={1}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    displayEmpty
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Status" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <IoIosPerson />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {statusOptions?.map((option) => (
                      <MenuItem key={option?.key} value={option?.key}>
                        {option?.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Autocomplete
                  id="combo-box-demo"
                  options={vendorNames || []}
                  getOptionLabel={(option) =>
                    capitalizeFirstLetter(option?.vendor_name)
                  }
                  // getOptionSelected={(option, value) => option.id === value.id}
                  value={
                    vendorNames.find((vendor) => vendor.id === vendorId) || null
                  }
                  onChange={(event, newValue) => {
                    setVendorId(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Vendor"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <IoIosPerson />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <FormControl fullWidth>
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    label="Select Warehouse"
                    value={warehouseId}
                    onChange={(e) => {
                      setWarehouseId(e.target.value);
                    }}
                    displayEmpty
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Warehouse" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <IoIosPerson />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {Array.isArray(warehouseData) &&
                      warehouseData?.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <TextField
                  label="PO Number"
                  size="small"
                  fullWidth
                  value={poNum}
                  onChange={(e) => {
                    setPoNum(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermIdentityIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!startDate || !endDate}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={1.4} md={1.4} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={
                    <GrChapterAdd
                      style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                  }}
                  onClick={handleAddNew}
                  title="Add New"
                  disabled={!userDetail?.b_purchase_order_create_po}
                >
                  <Typography variant="h7" color={colors.white.DEFAULT}>
                    Create New PO
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
              <Button
                variant="contained"
                startIcon={
                  <FileDownloadIcon
                    style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                  />
                }
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                }}
                onClick={handleGenerateReportClick}
                disabled = {downloadActive}
                title="Please select a 15 days interval for downloading."
              >
                <Typography variant="h7" color={colors.white.DEFAULT}>
                  Download
                </Typography>
              </Button>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
        {openDialog && (
          <SocketReportPopUp
            setOpenDialog={setOpenDialog}
            requestId={generatedId}
          />
        )}
        <Grid style={statContainerStyle}>
          <POStatContainer statData={poStats} />
        </Grid>
        <Grid container spacing={1} style={containerStyle8}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : poData?.length === 0 ? (
            <NullScreen containerheight="70vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 200 }} aria-label="customized table">
                    <TableHeadComponent />
                    <TableRowsComponent
                      data1={poData}
                      status={status}
                      setStatus={setStatus}
                    />
                  </Table>
                </TableContainer>
              </div>
              <Grid
                container
                justifyContent="flex-end"
                // style={{ marginTop: "5px" }}
              >
                <Pagination
                  count={totalPages}
                  defaultPage={
                    JSON.parse(localStorage.getItem("POfilterValue"))?.[0]
                      ?.offset || offset
                  }
                  page={offset}
                  key={refreshCount}
                  size="small"
                  onChange={onPageChange}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default POMainPage;
