
// reducers.js
const initialState = {
    action: '',
    transportData: null,
  };
  export const transportReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_TRANSPORT_ACTION':
        return {
          action: action.payload.action,
          transportData: action.payload.transportData,
        };
      default:
        return state;
    }
  };