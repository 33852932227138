import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NullScreen from "../../WMS/PopUpMsg/NullScreen";
import { tokens } from "../../WMS/WMStheme";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useTheme } from "@mui/material";
import { GrChapterAdd } from "react-icons/gr";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import MailIcon from "@mui/icons-material/Mail";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { ScaleLoader } from "react-spinners";
import PhoneIcon from "@mui/icons-material/Phone";
import SuccessPopup from "../../WMS/PopUpMsg/SuccessPopUp";
import ErrorPopup from "../../WMS/PopUpMsg/ErrorPopUp";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Label } from "semantic-ui-react";
import {
  OutlinedInput,
  DialogContentText,
  Dialog,
  MenuItem,
  Select,
  InputLabel,
  DialogTitle,
  FormControl,
  Chip,
  InputAdornment,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const ITEM_HEIGHT = 28;

const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 680,
    },
  },
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));

const columns = [
  { id: "status", label: "Status", type: "tag" },
  { id: "name", label: "Name", type: "string" },
  { id: "username", label: "User Name", type: "string" },
  { id: "email", label: "Email", type: "string" },
  { id: "phone_number", label: "Mobile Number", type: "string" },
  { id: "password", label: "Password", type: "string" },
];

const WMSUserPage = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const typography = tokens(theme.palette.mode.typography);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAddNewOpen, setDialogAddNewOpen] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [skuLoading, setSkuLoading] = useState(false);
  const [newData, setNewData] = useState();
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
 
const [filteredWarehouse,setFilteredWarehouse] =useState([]);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    name: "",
    email: "",
    phone: "",
    warehouse: [],
    status: true,
  });
  useEffect(() => {
    fetchUserGridData(currentPage);
  }, [newData]);

  const fetchUserGridData = async (page) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_USER_LIST,
        {
          params: {
            limit: 13,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setUserData(data?.user_list);
        setTotalPages(data?.total_pages);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };
  const handleAddNewUser = () => {
    setDialogAddNewOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleAddNewCloseDialog = () => {
    setDialogAddNewOpen(false);
  };
  const handleBack = () => {
    // Navigate to the WMS User's page
    navigate("/userconfig");
  };
  useEffect(() => {
    fetchWarehouseData();
  }, []);

  const fetchWarehouseData = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_WAREHOUSE_DATA}`,
        {
          params: {
            limit: 1000,
            offset: 1,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data;
        console.log("data", data);
        setWarehouseData(data?.warehouse_list);

        console.log("warehouse Data", data?.warehouse_list);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleInputChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    //  setWarehouseLength(value);
   
    const warehouses = Array.isArray(warehouseData)
      ? warehouseData
      : [warehouseData];
    console.log("warehouses", warehouses);
    // Update productDataSet with selected warehouses
    const selectedWarehousesWithFlag = warehouses
      .filter((warehouse) => value.includes(warehouse?.wh_name))
      .map((selectedWarehouse) => ({
        warehouse_id: selectedWarehouse?.id,
        wh_name: selectedWarehouse?.wh_name,
        flag: true,
      }));
// Set selectedWarehouses state
setSelectedWarehouses(selectedWarehousesWithFlag);

// Update the filteredWarehouses state (if needed)
setFilteredWarehouse(selectedWarehousesWithFlag);
// Create a copy of the original warehouses and update values
const updatedWarehouses = warehouses?.map((warehouse) => {
  const selectedWarehouse = selectedWarehousesWithFlag?.find(
    (selectedWarehouse) => selectedWarehouse?.warehouse_id === warehouse.id
  );

  return selectedWarehouse
    ? {
        id: warehouse.id,
        warehouse_id: warehouse.id,
        wh_name: warehouse.wh_name,
        flag: selectedWarehouse.flag,
      }
    : { warehouse_id: warehouse.id, wh_name: warehouse.wh_name, flag: false };
});

console.log("updatedWarehouses", updatedWarehouses);
setSelectedWarehouses(updatedWarehouses);
// Combine selectedWarehouses and other warehouses with flag: false
const combinedWarehouses = [
  ...updatedWarehouses,
  ...warehouses.filter((warehouse) => !value.includes(warehouse?.wh_name)).map((warehouse) => ({
    id: warehouse.id,
    warehouse_id: warehouse.id,
    wh_name: warehouse.wh_name,
    flag: false,
  })),
];
    setFormData((prevData) => ({
      ...prevData,
      warehouse: combinedWarehouses || [],
    }));
  };
  const handleSubmit = async () => {
    // Format the data as needed before sending it
    const formattedData = {
      username: formData.username,
      password: formData.password,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      warehouse: formData.warehouse,
      status: formData.status, // Assuming 'status' is a boolean
    };
    setSkuLoading(true);
    try {
      // Determine the endpoint for creating a new SKU
      const endpoint = `${API_ENDPOINTS.POST_WMS_ADD_NEW_USER_LIST}`;
      console.log("API Endpoint:", endpoint);

      // Send the POST request to create a new SKU
      const response = await axiosInstance.post(endpoint, formattedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data created successfully!");
        setNewData(response?.data?.Data);
        setSuccessPopupOpen(true);
        setDialogAddNewOpen(false);
        setSkuLoading(false);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the SKU data");
        //   setErrorMessage("Failed to create the SKU data. Please try again.");
      }
    } catch (error) {
      // setErrorPopupOpen(true);
      console.error("Error occurred while creating the SKU data:", error);
    }
    // Now you can send 'formattedData' to your backend or perform any other actions
    console.log(formattedData);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    fetchUserGridData(newPage);
  };
  const containerStyle = {
    maxHeight: "80vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    marginTop: "1%",
    display: "flex",
    height: "74vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };

  const StatusTag = ({ status }) => {
    let tagColor;
    switch (status) {
      case true:
        tagColor = "#74749e";
        break;
      case false:
        tagColor = "#997975";
        break;
      default:
        tagColor = "gray";
        break;
    }
    return (
      <Label
        style={{
          backgroundColor: tagColor,
          color: "white",
          fontWeight: typography.h1,
          fontFamily: "Lexend Deca",
          cursor: "default",
          width: "auto",
        }}
        tag
      >
        {status === true ? "Active" : "In-Active"}
      </Label>
    );
  };

  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent() {
    return (
      <>
        <TableBody>
          {userData?.map((row, index) => (
            <StyledTableRow key={index}>
              {columns?.map((column) => (
                <StyledTableCell key={column?.id} align="center">
                  {column?.type === "tag" ? (
                    //   <input type="checkbox" checked= disabled />
                    // <Checkbox
                    //   defaultChecked={row[column.id]}
                    //   color="success"
                    //   size="small"
                    //   disabled
                    //       />
                    <StatusTag status={row[column.id]} />
                  ) : (
                    row[column.id] || "-"
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </>
    );
  }
  return (
    <>
      {skuLoading ? (
        <>
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={skuLoading} />
          </div>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "100%",
            }}
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",

                marginTop: "1%",
              }}
            >
              <Typography variant="h1" color={colors.green.DEFAULT}>
                WMS User
              </Typography>

              <Grid item xs={6} md={6} sm={6}>
                <Button
                  variant="outlined"
                  startIcon={
                    <AddCircleOutlineOutlinedIcon
                      style={{ color: colors.green.DEFAULT }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    color: colors.green.DEFAULT,
                    marginRight: "2px",
                  }}
                  onClick={handleAddNewUser}
                >
                  <Typography variant="h5" color={colors.green.DEFAULT}>
                    Add New User
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  startIcon={
                    <IoArrowBackCircleOutline
                      style={{ color: colors.green.DEFAULT }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    color: colors.green.DEFAULT,
                  }}
                  onClick={handleBack}
                >
                  <Typography variant="h5" color={colors.green.DEFAULT}>
                    Back
                  </Typography>
                </Button>{" "}
              </Grid>
            </Grid>
            <Grid container spacing={1} style={containerStyle}>
              {isLoading ? (
                <div style={loaderContainerStyle}>
                  <ScaleLoader color="#495057" loading={isLoading} />
                </div>
              ) : userData?.length === 0 ? (
                <NullScreen containerheight="80vh" containerWidth="100%" />
              ) : (
                <>
                  <div style={gridContainerStyle}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 200 }}
                        aria-label="customized table"
                      >
                        <TableHeadComponent />
                        <TableRowsComponent />
                      </Table>
                    </TableContainer>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div style={{ flex: "1" }}></div>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handleChangePage}
                      size="small"
                    />
                  </div>
                </>
              )}
            </Grid>
          </Box>

          <Dialog
            open={dialogAddNewOpen}
            onClose={handleAddNewCloseDialog}
            maxWidth="md"
          >
            <DialogTitle>
              <Typography
                variant="h2"
                color={colors.green.DEFAULT}
                textAlign="center"
              >
                Add User
              </Typography>
            </DialogTitle>

            <DialogContent>
              <DialogContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <TextField
                      label="Name"
                      value={formData.name}
                      onChange={handleInputChange("name")}
                      size="small"
                      inputProps={{
                        maxLength: 20,
                        minLength: 5,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <PermIdentityIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      autoFocus
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <TextField
                      label="User Name"
                      value={formData.username}
                      onChange={handleInputChange("username")}
                      size="small"
                      inputProps={{
                        maxLength: 20,
                        minLength: 5,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <PermIdentityIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      autoFocus
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <TextField
                      label="Password"
                      value={formData.password}
                      onChange={handleInputChange("password")}
                      size="small"
                      inputProps={{
                        maxLength: 10,
                        minLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <AccountCircleIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      autoFocus
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <FormControl fullWidth>
                      <InputLabel id="Status">Status</InputLabel>
                      <Select
                        label="Status"
                        labelId="Status"
                        name="Status"
                        id="Status"
                        value={formData.status ? true : false}
                        onChange={handleInputChange("status")}
                        size="small"
                        required
                        fullWidth
                        autoFocus
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      size="small"
                      value={formData.email}
                      onChange={handleInputChange("email")}
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                        minLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <MailIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <TextField
                      label="Mobile No."
                      size="small"
                      value={formData.phone}
                      onChange={handleInputChange("phone")}
                      fullWidth
                      type="tel"
                      required
                      margin="normal"
                      variant="outlined"
                      inputProps={{
                        maxLength: 10,
                        inputMode: "numeric", // Shows the numeric keyboard on mobile devices
                        pattern: "[0-9]*", // Only allows numeric input (additional validation)
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <PhoneIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}></Grid>
                  <Grid
                    container
                    spacing={3}
                    sx={{ marginTop: "0.6%", marginLeft: "1.1%" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="warehouse-chip-label">
                        Link to Warehouses
                      </InputLabel>
                      <Select
                        labelId="warehouse-chip-label"
                        id="warehouse-chip"
                        multiple
                        value={(filteredWarehouse || [])?.map(
                          (wh) => wh.wh_name
                        )}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            id="select-warehouse-chip"
                            label="Select Warehouses"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected?.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {Array.isArray(warehouseData) &&
                          warehouseData?.map((warehouse) => (
                            <MenuItem
                              key={warehouse?.id}
                              value={warehouse?.wh_name}
                            >
                              {warehouse?.wh_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ marginRight: "15px" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  style={{
                    height: "35px",
                    marginTop: "2%",
                    marginLeft: "84%",
                  }}
                >
                  <Button
                    onClick={handleAddNewCloseDialog}
                    sx={{ color: "#5E89C7", borderColor: "#5E89C7" }}
                    variant="outlined"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    sx={{ backgroundColor: "#5E89C7" }}
                    variant="contained"
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Submit
                    </Typography>
                  </Button>
                </Stack>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </>
      )}
      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default WMSUserPage;
