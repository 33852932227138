import React, { useState, useEffect, useRef, useMemo } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { createTheme } from "@mui/material/styles";
import { GiSave } from "react-icons/gi";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import NullScreen from "../PopUpMsg/NullScreen";
import { ScaleLoader } from "react-spinners";
import { CiCalendarDate } from "react-icons/ci";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import { IoArrowBackCircleOutline, IoSaveOutline } from "react-icons/io5";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import Pagination from "@mui/material/Pagination";
import { IoIosAddCircleOutline } from "react-icons/io";

import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import {
  Grid,
  Button,
  Select,
  Box,
  Divider,
  Dialog,
  Autocomplete,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  MenuItem,
  ThemeProvider,
  IconButton,
  InputLabel,
  FormControl,
  Typography,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { TiTickOutline } from "react-icons/ti";
import { GiFruitBowl } from "react-icons/gi";
import Paper from "@mui/material/Paper";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import { tokens } from "../WMStheme";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const gridContainerStyle = {
  // marginTop:"1%",
  display: "flex",
  // height: "20vh",
  width: "100%",
  // overflowY: "hidden",
  // backgroundColor: "green",
};
const containerStyle8 = {
  maxHeight: "80vh",
  height: "78vh",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};

const PerformCycleCount = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const oneWeekAgo = new Date();
  const [skuNames, setSkuNames] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [initialTextVisible, setInitialTextVisible] = useState(true);
  const [isApplyButtonDisabled, setApplyButtonDisabled] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [skuId, setSkuId] = useState(null);
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const [newObj, setNewObj] = useState([]);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [reasonsFilled, setReasonsFilled] = useState(true); // Initialize to true assuming all reasons are filled initially

  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [errorPopover, setErrorPopover] = useState(null);
  // const [inventoryData, setInventoryData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [warehouseId, setWarehouseId] = useState(null);
  const [lotNo, setLotNo] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [selectedType, setSelectedType] = useState("sku");
  const [productCode, setProductCode] = useState("");
  const [productNames, setProductNames] = useState("");
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
  const [updatedRowsArray1, setUpdatedRowsArray1] = useState([]);
  const [addedSKUMasterData, setAddedSKUMasterData] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [quantityErrors, setQuantityErrors] = useState({});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [reasonInputValues, setReasonInputValues] = useState({});
  const [productId, setProductId] = useState(null);
  const globalTheme = useTheme();
  const [cycleLoading, setCycleLoading] = useState(false);
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#0A0A0A",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#040404", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const [inventoryData, setInventoryData] = useState([]);
  const [filterValues, setFilterValues] = useState({
    limit: 1000,
    offset: offset,
    type: selectedType,
    item_id: skuId,
    warehouse_id: warehouseId,
    lot_no:lotNo,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    limit: 1000,
    offset: offset,
    type: selectedType,
    item_id: skuId,
    warehouse_id: warehouseId,
    lot_no:lotNo,
    // type: param,
  });
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount
  useEffect(() => {
    const updatedFilterValues = {
      // start_date: startDate,
      // end_date: endDate,
      limit: 1000,
      offset: offset,
      type: selectedType,
      item_id: skuId,
      warehouse_id: warehouseId,
      lot_no:lotNo,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [offset, warehouseId, skuId, selectedType,lotNo]);
  useEffect(() => {
    if (warehouseId && skuId && selectedType) {
      const updatedFilterValues = { ...filterValues };

      // Iterate over each key in the filterValues object
      for (const key in updatedFilterValues) {
        // Check if the value corresponding to the key is a string and is an empty string
        if (
          typeof updatedFilterValues[key] === "string" &&
          updatedFilterValues[key].trim() === " "
        ) {
          // Set the value to null
          updatedFilterValues[key] = null;
        }
      }
  
      // Log the updated filterValues
      console.log("updated filtervalues------->", updatedFilterValues);
      fetchInventoryData(updatedFilterValues);
    }
  }, [filterValues ,refreshCount]);
  
  useEffect(() => {
    fetchSKUNames();
  }, []);
  useEffect(() => {
   
     fetchProductMapped();
  }, []);
  const fetchSKUNames = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_SKU_DATA, {
        params: {
          limit: 1000,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response?.data?.Data?.sku_list;
      setSkuNames(data);
      console.log("sku data---------------->testing----------->",data);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };
 
  const fetchProductMapped = async (warehouse_id) => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_REPACK_PRODUCT_LIST,
        {
          params: {
            limit: 1000,
            offset: 1,
            warehouse_id: warehouse_id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data?.Data;
      console.log("data in fetchProductMapped-------", data);
      setProductNames(data?.product_list);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };
  const handleApplyClick = () => {
    const newFilterValues = {
      // start_date: temporaryFilterValues.start_date,
      // end_date: temporaryFilterValues.end_date,
      limit: 16,
      offset: 1,
      warehouse_id: temporaryFilterValues?.warehouse_id,
      type: temporaryFilterValues?.type,
      item_id: temporaryFilterValues?.item_id,
      lot_no:temporaryFilterValues?.lot_no === '' ? null : temporaryFilterValues?.lot_no,

      // type: param,
    };
    console.log("lot number", temporaryFilterValues?.lot_no);
    setLotNo(temporaryFilterValues?.lot_no);
    setWarehouseId(temporaryFilterValues?.warehouse_id);
    setSkuId(temporaryFilterValues?.item_id);
    setSelectedType(temporaryFilterValues?.type);
    setFilterValues(newFilterValues);
    fetchInventoryData(newFilterValues);
    setInitialTextVisible(false);
  };
  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };
  const handleRowClick = (row) => {
    if (row.original) {
      const skuId = row.original?.inv_id;
      const selectedRow = {
        count: row.original?.count,
        inv_id: skuId,
        item_id: row.original?.item_id,
        type: row.original?.type,
        from_qty: row.original?.from_qty,
        lot_no: row.original?.lot_no,
        warehouse_id: row.original?.warehouse_id,
        flag: !selectedRows[skuId]?.flag,
        to_qty: inputValues[skuId] || 0,
      };

      setSelectedRows((prevSelectedRows) => {
        const updatedRows = { ...prevSelectedRows };
        updatedRows[skuId] = selectedRow;
        return updatedRows;
      });
    } else {
      console.error("Row data is undefined or missing 'original' property");
    }
  };
 
  const onRowSelectionChange = (selectedRowIds) => {
    const selectedIdsArray = Array.isArray(selectedRowIds)
      ? selectedRowIds
      : Object.keys(selectedRowIds);

    const updatedRows = inventoryData
      ?.filter((row) => selectedIdsArray.includes(row.inv_id))
      .map((row) => ({
        count: row?.count,
        inv_id: row?.inv_id,
        item_id: row?.item_id,
        type: row?.type,
        from_qty: row?.from_qty,
        lot_no: row?.lot_no,
        reason: row?.reason,
        warehouse_id: row?.warehouse_id,
        flag: !row?.flag,
        to_qty: inputValues[row?.inv_id] || 0,
      }));

    setSelectedRows(updatedRows);
    console.log("selected rows ", updatedRows);
    // Check if any selected row has an empty to_qty
    // const hasEmptyToQty = updatedRows?.some((row) => !row.to_qty || row.to_qty === 0);

    // // Disable the save button if there is any selected row with an empty to_qty
    // setSaveButtonDisabled(hasEmptyToQty);
  };

  const handleQuantityChange = (e, original) => {
    const { value } = e.target;

    if (1) {
      // Update the row's quantity in the SKU master data
      // const updatedSkuMasterData = inventoryData?.map((item) =>
      //   item?.inv_id === original?.inv_id ? { ...item, to_qty: numericValue } : item
      // );
      // setAddedSKUMasterData(updatedSkuMasterData);

      // Update the input values state
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [original.inv_id]: parseFloat(value),
      }));

      // Update the selected rows with the new flag
      setSelectedRows((prevSelectedRows) => ({
        ...prevSelectedRows,
        [original?.inv_id]: { flag: true },
      }));

      // Add or update the row in the array
      const updatedRow = { ...original, to_qty: parseFloat(value), flag: true };
      const index = updatedRowsArray?.findIndex(
        (row) => row?.inv_id === original?.inv_id
      );
      console.log("updated Row in handleQuantity Chnage 1", updatedRow);

      if (index !== -1) {
        // If row exists in the array, update it
        const newArray = [...updatedRowsArray];
        console.log("new Array in handleQuantity Chnage 1", newArray);
        newArray[index] = updatedRow;
        console.log(
          "updated row in index  in handleQuantity Chnage 1",
          updatedRow
        );
        setUpdatedRowsArray(newArray);
        // // console.log("new Array in handleQuantity Chnage",newArray);
        // // If row exists in the array, update it
        // setUpdatedRowsArray((prevArray) => [
        //   ...prevArray.slice(0, index),
        //   updatedRow,
        //   ...prevArray.slice(index + 1),
        // ]);
        // console.log("New Array hereeeeeeee in handle quantity:", newArray);
        setUpdatedRowsArray1(updatedRowsArray);
        console.log(
          "Updated Rows Array hereeeeeeee in handle quantity:",
          updatedRowsArray
        );
        // setUpdatedRowsArrayForCount(newArray);
      } else {
        console.log(
          "updated row in else @@@@@@@  in handleQuantity Chnage 1",
          updatedRow
        );
        console.log(
          "updated row Array in else @@@@@@@  in handleQuantity Chnage 1",
          updatedRowsArray
        );
        // If row doesn't exist in the array, add it
        setUpdatedRowsArray((prevArray) => [...prevArray, updatedRow]);
        setUpdatedRowsArray1(updatedRowsArray);
        console.log(
          "Updated Rows Array hereeeeeeee1234567890 in handle quantity:",
          updatedRowsArray
        );
      }
      setSaveButtonDisabled(false);
      console.log("Updated Rows Array in if :", updatedRowsArray);

      setErrorPopover(null);
      // Console the updated array here
    } else {
      console.log("Updated Rows Array in else :", updatedRowsArray);
      // setErrorMessage("Enter quantity ");
      setSaveButtonDisabled(true);
      console.log("Invalid quantity value");
    }
  };
  console.log("OUTSIDEEEEEEEEEEEEE  in handle quantity:", updatedRowsArray);
  const handleReasonQuantityChange = (e, original) => {
    const { value } = e.target;

    // Perform your checks here without restricting to whole numbers
    if (
      value.length <= 80
      //  value <= fromQty
    ) {
      // Update the row's quantity in the SKU master data
      const updatedSkuMasterData = inventoryData?.map((item) =>
        item?.inv_id === original?.inv_id ? { ...item, reason: value } : item
      );
      setAddedSKUMasterData(updatedSkuMasterData);

      // Update the input values state
      setReasonInputValues((prevInputValues) => ({
        ...prevInputValues,
        [original.inv_id]: value,
      }));

      // Update the selected rows with the new flag
      setSelectedRows((prevSelectedRows) => ({
        ...prevSelectedRows,
        [original.inv_id]: { flag: true },
      }));

      // Add or update the row in the array
      const updatedRow = { ...original, reason: value, flag: true };
      const index = updatedRowsArray?.findIndex(
        (row) => row?.inv_id === original?.inv_id
      );

      if (index !== -1) {
        // If row exists in the array, update it
        const newArray = [...updatedRowsArray];
        newArray[index] = updatedRow;
        setUpdatedRowsArray(newArray);
        setUpdatedRowsArray1(updatedRowsArray);
        console.log("Updated Rows Array hereeeeeeee:", updatedRowsArray);
      } else {
        // If row doesn't exist in the array, add it
        setUpdatedRowsArray((prevArray) => [...prevArray, updatedRow]);
        setUpdatedRowsArray1(updatedRowsArray);
      }

      //   setErrorPopover(null);
      // Console the updated array here
      console.log("Updated Rows Array outsideeeeeeeeeeeee:", updatedRowsArray);
    } else {
      // setErrorMessage("Enter quantity ");

      console.log("Invalid quantity value");
    }
  };
  
  useEffect(() => {
    const reasonsAreFilled = updatedRowsArray?.every(
      (item) =>
        item.reason !== "" &&
        Object.prototype.hasOwnProperty.call(item, "reason")
    );
    setReasonsFilled(!reasonsAreFilled);
  }, [updatedRowsArray]);
  
  const handleAddCycleCount = async () => {
   
    try {
      const endpoint = API_ENDPOINTS.GET_WMS_ADD_CYCLE_COUNT(warehouseId);
      console.log("API Endpoint:", endpoint);
      setCycleLoading(true);

      // Log the FamilyData before making the API call
      console.log("Creating Count Data 88888888888:", updatedRowsArray);

      const inv_list = updatedRowsArray?.map((item) => ({
        count: item?.count,
        inv_id: item?.inv_id,
        item_id: item?.item_id,
        type: item?.type,
        from_qty: item?.from_qty,
        lot_no: item?.lot_no,
        reason: item?.reason,
        warehouse_id: item?.warehouse_id,
        flag: item?.flag,
        to_qty: inputValues[item?.inv_id] || 0,
      }));

      console.log("inv list in POST function : ", inv_list);

      // Send the POST request to create a new family
      const response = await axiosInstance.post(
        endpoint,
        {
          inv_list: inv_list,
          warehouse_id: warehouseId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Cycle count performed successfully!");
        setSuccessPopupOpen(true);

        navigate("/cyclecount");
        setUpdatedRowsArray([]);
    setUpdatedRowsArray1([]);
        //  setOpenPerformCycleCount(false);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the cycle count");
        setErrorMessage("Failed to create the cycle count. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to create the cycle count. Please try again.");
      setErrorPopupOpen(true);
      setCycleLoading(false);
      console.error("Error occurred while creating the cycle count", error);
    }
  };
  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    if (successPopupOpen) {
      // Display success popup for 3 seconds (adjust the duration as needed)
      const timer = setTimeout(() => {
        // Navigate to the next page

        // setOpenPerformCycleCount(false)
        setCycleLoading(false);
        setSuccessPopupOpen(false);
      }, 800);

      // Clear the timer if the component unmounts or if successPopupOpen becomes false
      return () => clearTimeout(timer);
    }
  }, [successPopupOpen]);
  const columns = useMemo(
    () => [
      {
        header: "Select",
        accessorKey: "select",
        size: 10,
        Cell: ({ row }) => (
          <Checkbox
            checked={Boolean(selectedRows[row.original?.inv_id]?.flag)}
            //checked={Boolean(row.original.flag)}
            color="primary"
            onChange={() => handleRowClick(row)}
            sx={{ padding: "2px", "& .MuiSvgIcon-root": { fontSize: "15px" } }} // Adjust the padding and fontSize as needed
          />
        ),
      },
      {
        header: "Name",
        accessorKey: "item_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Code",
        accessorKey: "item_code",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Warehouse",
        accessorKey: "wh_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Lot",
        accessorKey: "lot_no",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        size: 10,
        Cell: ({ row }) => {
          const created_at = row?.original?.created_at;

          return <span>{created_at ? formatDate(created_at) : "-"}</span>;
        },
      },
      {
        header: "Type",
        accessorKey: "type",
        size: 10,
        enableEditing: false,
      },

      {
        header: "Available Qty",
        accessorKey: "from_qty",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Physical Qty",
        accessorKey: "to_qty",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const inputValue = inputValues[original?.inv_id] || "";

          return (
            <TextField
              name={inputValue}
              value={
                inputValues[original?.inv_id] !== null &&
                inputValues[original?.inv_id] !== undefined
                  ? inputValues[original?.inv_id]
                  : ""
              }
              size="small"
              type="number"
              onChange={(e) => handleQuantityChange(e, original)}
              inputProps={{
                step: "any",
              }}
            />
          );
        },
      },
      {
        header: "Reason",
        accessorKey: "reason",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const reasonInputValue = reasonInputValues[original?.inv_id] || "";

          return (
            <TextField
              name={reasonInputValue}
              value={reasonInputValue}
              size="small"
              onChange={(e) => handleReasonQuantityChange(e, original)}
            />
          );
        },
      },
    ],
    [
      inputValues,
      reasonInputValues,
      selectedRows,
      inventoryData,
      setAddedSKUMasterData,
      updatedRowsArray,
    ]
  );

  const fetchInventoryData = async (newParams) => {
    console.log("warehouse id in inventory data function", warehouseId);
    console.log("newparams in fetchinventoryfunction", newParams);
    // Clone the original filterValues object
   

    setWarehouseId(newParams?.warehouse_id);
    console.log(
      "warehouse id in after setting warehouse id in inventory data function",
      warehouseId
    );
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_INVENTORY_ITEM_WISE_FOR_CYCLE_COUNT,
        {
          params: newParams,

          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data?.inv_list;
        console.log("data", data);
        setInventoryData(data);
        setIsLoading(false);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsLoading(false);
      // setErrorMessage("Please Try Again");
      // setErrorPopupOpen(true);
    }
  };
  const handleBackToCycleCount = () => {
    navigate("/cyclecount");
  };
  const handleAddClick = () => {
    setOpenAddDialog(true);
    setUpdatedRowsArray1(updatedRowsArray);
  };
  const handleAddMore = () => {
    console.log(
      "updated RowsArray in handleAddMore cycle count ",
      updatedRowsArray
    );
    setNewObj((prevObj) => {
      const updatedRowsIds = updatedRowsArray?.map((row) => row?.inv_id);
      console.log("updated Rows Id ", updatedRowsIds);
      console.log("prev obj  ", prevObj);
      // Filter out existing rows with the same inv_id
      const filteredRows = prevObj?.filter(
        (row) => !updatedRowsIds?.includes(row?.inv_id)
      );
      console.log("filtered Rows ", filteredRows);
      return [...filteredRows, ...updatedRowsArray];
    });

    console.log("new obj ", newObj);
    setOpenAddDialog(false);
  };
  const handleAddCloseDialog = () => {
    setOpenAddDialog(false);
  };
  const handleRefreshClick = () => {
    const initialValues = {
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
    };
    setUpdatedRowsArray([]);
    setUpdatedRowsArray1([]);
    setSkuId(null);
    setSkuCode(null);
    setWarehouseId(null);
    setLotNo("");
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    setInventoryData([]);
  };
  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginTop: "1%" }}
        >
          <Typography variant="h2" color={colors.green.DEFAULT}>
            Perform Cycle Count
          </Typography>
          <div>
            <Typography
              variant="h5"
              style={{ marginTop: "5px", marginLeft: "4px" }}
              color={colors.green.DEFAULT}
            >
              (Ensure PO is in 'SENT TO ZOHO' Status)
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={openAddDialog}
        onClose={handleAddCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle sx={{ position: "sticky", height: "10vh" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1%",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row", // Stack items vertically
                alignItems: "center",
                marginBottom: "2%",
              }}
            >
              <Typography variant="h2" color={colors.green.DEFAULT}>
                Preview of Item Added For Cycle Count
              </Typography>
            </Grid>
          </Grid>
          <IconButton
            aria-label="close"
            onClick={handleAddCloseDialog}
            sx={{
              position: "absolute",
              right: 1.9,
              top: 1,
              color: "grey",
            }}
          >
            <CloseSharpIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={updatedRowsArray1}
              getRowId={(row) => row?.inv_id}
              onRowSelectionChange={onRowSelectionChange}
              state={{ rowSelection }}
              enableEditing={false}
              enableGrouping
              key={refreshCount}
              enableStickyHeader
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={false}
              initialState={{
                density: "compact",
                pagination: {
                  pageIndex: 0,
                  pageSize: 10,
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  fontWeight: "650",
                  color: "#fff",
                  backgroundColor: "#34519fe6",
                  fontSize: "15px",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  "& tr": {
                    backgroundColor: "#fff",
                  },
                },
              }}
              muiTableContainerProps={{
                sx: { maxHeight: "74vh" },
              }}
              muiToolbarAlertBannerChipProps={{
                color: "primary",
              }}
              renderTopToolbarCustomActions={() => (
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Grid container spacing={1} justifyContent="flex-start">
                    <Grid item xs={12} sm={1.8} md={1.8}>
                      <Button
                        variant="contained"
                        startIcon={
                          <IoIosAddCircleOutline style={{ fontSize: "26px" }} />
                        }
                        size="small"
                        sx={{
                          backgroundColor: "#34519fe6",
                          color: "#ffffff",
                        }}
                        onClick={handleAddMore}
                      >
                        Add More Items
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={1.8} md={1.8}>
                      <Button
                        variant="contained"
                        startIcon={
                          <IoSaveOutline style={{ fontSize: "26px" }} />
                        }
                        size="small"
                        sx={{
                          backgroundColor: "#34519fe6",
                          color: "#ffffff",
                        }}
                        disabled={reasonsFilled}
                        onClick={handleAddCycleCount}
                      >
                        Save Cycle Count
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
          </ThemeProvider>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      {cycleLoading ? (
        <>
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={cycleLoading} />
          </div>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "10vh" }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Select Warehouse</InputLabel>
                    <Select
                      label="Select Warehouse"
                      value={warehouseId}
                      onChange={(e) => {
                        setWarehouseId(e.target.value); // Set warehouseId, not warehouseData
                      }}
                      size="small"
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Warehouse" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <CiCalendarDate />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {Array.isArray(warehouseData) &&
                        warehouseData?.map((day) => (
                          <MenuItem key={day?.id} value={day?.id}>
                            {day?.wh_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Select Type</InputLabel>
                    <Select
                      label="Select Type"
                      value={selectedType}
                      onChange={(e) => {
                        setSelectedType(e.target.value);
                      }}
                      size="small"
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Type" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <GiFruitBowl />
                          </IconButton>
                        </InputAdornment>
                      }
                      disabled={!warehouseId}
                    >
                      <MenuItem value="sku">SKU</MenuItem>
                      <MenuItem value="product">Product</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={
                        selectedType === "sku" ? skuNames : productNames || []
                      }
                      getOptionLabel={(option) =>
                        (selectedType === "sku"
                          ? option?.sku_name
                          : option?.name) || ""
                      }
                      value={
                        selectedType === "sku"
                          ? skuNames?.find((sku) => sku?.id === skuId) || null
                          : productNames?.find(
                              (product) => product?.id === skuId
                            ) || null
                      }
                      onChange={(event, newValue) => {
                        setSkuId(newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Item"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton edge="start" size="small" disabled>
                                  <GiFruitBowl />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      disabled={!selectedType}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                <TextField
                  label="Lot Number"
                  size="small"
                  fullWidth
                  value={lotNo}
                  onChange={(e) => {
                    setLotNo(e.target.value);
                  }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermIdentityIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                
              </Grid>
                <Grid item xs={12} sm={0.8} md={0.8}>
                  <Button
                    variant="contained"
                    startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                    sx={{
                      backgroundColor: "#34519fe6",
                      color: "#ffffff",
                    }}
                    size="small"
                    onClick={handleApplyClick}
                    disabled={!skuId}
                  >
                    Apply
                  </Button>
                </Grid>

                <Grid item xs={12} sm={0.8} md={0.8}>
                  <Button
                    variant="contained"
                    startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                    size="small"
                    sx={{
                      backgroundColor: "#34519fe6",
                      color: "#ffffff",
                    }}
                    onClick={handleRefreshClick}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={12} sm={1.6} md={1.6}></Grid>

                <Grid item xs={12} sm={0.8} md={0.8}>
                  <Button
                    variant="contained"
                    startIcon={
                      <IoArrowBackCircleOutline
                        style={{
                          color: colors.white.DEFAULT,
                          fontSize: "24px",
                        }}
                      />
                    }
                    sx={{
                      borderColor: colors.green.DEFAULT,
                      backgroundColor: colors.green.DEFAULT,
                      color: "#ffff",
                    }}
                    onClick={handleBackToCycleCount}
                  >
                    <Typography variant="h5" color={colors.white[100]}>
                      Back
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={containerStyle8}>
            {isLoading ? (
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={isLoading} />
              </div>
            ) : (
              <>
                {warehouseId === null ? (
                  <NullScreen
                    containerheight="75vh"
                    containerWidth="100%"
                    screenType="Info"
                    message="Select Warehouse"
                  />
                ) : (
                  <>
                    {inventoryData?.length === 0 ? (
                      <NullScreen
                        containerheight="75vh"
                        containerWidth="100%"
                      />
                    ) : (
                      <>
                        <Grid container spacing={1} sx={{ marginTop: "0%" }}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              bgcolor="#ffff"
                              height="74vh"
                              borderRadius="10px"
                              // backgroundColor="green"
                              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
                            >
                              <div style={gridContainerStyle}>
                                <Grid item sm={12} xs={12} md={12}>
                                  <ThemeProvider theme={tableTheme}>
                                    <MaterialReactTable
                                      columns={columns}
                                      data={inventoryData}
                                      getRowId={(row) => row?.inv_id}
                                      onRowSelectionChange={
                                        onRowSelectionChange
                                      }
                                      state={{ rowSelection }}
                                      enableEditing={false}
                                      enableGrouping
                                      enableStickyHeader
                                      key={refreshCount}
                                      enableFullScreenToggle={false}
                                      enableDensityToggle={false}
                                      enableColumnActions={false}
                                      renderBottomToolbarCustomActions={() => (
                                        <Grid
                                          container
                                          justifyContent="flex-end"
                                          alignItems="center"
                                        >
                                          <Grid item xs={12} sm={3} md={3}>
                                            <Button
                                              onClick={handleAddClick}
                                              variant="contained"
                                              sx={{
                                                backgroundColor:
                                                  colors.green.DEFAULT,
                                              }}
                                              style={{ float: "right" }}
                                              // disabled ={currentStatus === 'received'}
                                            >
                                              <Typography
                                                variant="h6"
                                                color={colors.white.DEFAULT}
                                              >
                                                Add & Preview
                                              </Typography>
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      )}
                                      enableColumnFilters={false}
                                      enablePagination={false}
                                      initialState={{
                                        density: "compact",
                                        pagination: {
                                          pageIndex: 0,
                                          pageSize: 10,
                                        },
                                      }}
                                      muiTableHeadCellProps={{
                                        sx: {
                                          fontWeight: "650",
                                          color: "#fff",
                                          backgroundColor: "#34519fe6",
                                          fontSize: "15px",
                                        },
                                      }}
                                      muiTableBodyProps={{
                                        sx: {
                                          "& tr": {
                                            backgroundColor: "#fff",
                                          },
                                        },
                                      }}
                                      muiTableContainerProps={{
                                        sx: { maxHeight: "65vh" },
                                      }}
                                      muiToolbarAlertBannerChipProps={{
                                        color: "primary",
                                      }}
                                    />
                                  </ThemeProvider>
                                </Grid>
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Box>
      )}

      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default PerformCycleCount;
