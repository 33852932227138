import React from "react";
import { Typography, Box, Stack, useTheme, Grid } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Divider from "@mui/material/Divider";
import { tokens } from "../WMStheme";
const CardBox = ({ title, subtitle, title2 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  return (
    <Box mb="1px">
      <Typography
        variant="h4"
        color={colors.black.DEFAULT}
        fontWeight={typography.h1}
        fontFamily="Lexend Deca"
        sx={{ m: "0 0 0px 0" }}
        width="103%"
        textAlign="center"
        margin="-34px 30px 9px -5px"
      >
        {subtitle}
      </Typography>
      <Typography
        variant="h6"
        textAlign="center"
        marginTop="6px"
        color={colors.black[200]}
      >
        {title}
      </Typography>
      <Typography
        fontSize={"8px"}
        textAlign="center"
        marginTop="1px"
        color={colors.black[200]}
      >
        {title2}
      </Typography>
    </Box>
  );
};
const AmountCardBox = ({ title, subtitle, title2 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  return (
    <Box mb="1px">
      <Typography
        variant="h4"
        color={colors.black.DEFAULT}
        fontWeight={typography.h1}
        fontFamily="Lexend Deca"
        sx={{ m: "0 0 0px 0" }}
        width="103%"
        textAlign="center"
        margin="-34px 30px 9px -5px"
      >
        <CurrencyRupeeIcon fontSize="small" /> {subtitle}
      </Typography>
      <Typography
        variant="h6"
        textAlign="center"
        marginTop="6px"
        color={colors.black[200]}
      >
        {title}
      </Typography>
      <Typography
        fontSize={"8px"}
        textAlign="center"
        marginTop="1px"
        color={colors.black[200]}
      >
        {title2}
      </Typography>
    </Box>
  );
};
const WMSStatContainer = ({ statData }) => {
  // console.log("statData in AnalyticsStatContainer", statData);
  return (
    <Stack direction="row" spacing={1}>
      <Box
        backgroundColor="#ffffff"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="63px"
        borderRadius="8px"
        width="50%"
      >
        <Grid container spacing={1}>
          <Grid item xs={4} sm={4} md={4}>
            <CardBox
              title="Total Orders"
              subtitle={statData?.order_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />
          <Grid item xs={3.5} sm={3.5} md={3.5}>
            <CardBox
              title="Total Distinct Customers"
              subtitle={statData?.distinct_customer_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />
          <Grid item xs={4} sm={4} md={4}>
            <CardBox
              title="Total Product Sold"
              subtitle={statData?.distinct_sku_sold || "0"}
              title2="(per selected filter)"
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor="#ffffff"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="63px"
        borderRadius="8px"
        width="50%"
      >
        <Grid container spacing={1}>
          <Grid item xs={4} sm={4} md={4}>
            <AmountCardBox
              title="Minimum Order Amount"
              subtitle={statData?.min_amount || "0"}
              title2="(per selected filter)"
            />
          </Grid>

          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />

          <Grid item xs={3.5} sm={3.5} md={3.5}>
            <AmountCardBox
              title="Maximum Order Amount"
              subtitle={statData?.max_amount || "0"}
              title2="(per selected filter)"
            />
          </Grid>

          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />

          <Grid item xs={4} sm={4} md={4}>
            <AmountCardBox
              title="Total Order Amount"
              subtitle={statData?.total_amount || "0"}
              title2="(per selected filter)"
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default WMSStatContainer;
