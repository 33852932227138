import React, { useState, useEffect } from "react";
import {
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import GrassIcon from "@mui/icons-material/Grass";
import { CalendarOutlined } from "@ant-design/icons";
import axios from "axios";
import { DatePicker, Space } from "antd";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const { RangePicker } = DatePicker;
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const FilterTab = ({ onFilterChange, onApplyClick }) => {
  const moment = require("moment");
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [transaction_type, setSelectedType] = useState(null);
  const [farmOptions, setFarmOptions] = useState([]);
  const [farm_id, setSelectedFarmId] = useState(null);

  const transactionType = [
    { value: "expense", label: "Expense" },
    { value: "income", label: "Income" },
  ];

  useEffect(() => {
    if (start_date && end_date && transaction_type && farm_id) {
      const startDateEpoch = moment(start_date, "DD-MM-YYYY").valueOf();
      const endDateEpoch = moment(end_date, "DD-MM-YYYY").valueOf();
      onFilterChange({
        start_date: startDateEpoch,
        end_date: endDateEpoch,
        transaction_type,
        farm_id,
      });
    }
  }, [start_date, end_date, transaction_type, farm_id]);

  useEffect(() => {
    fetchFarmData();
  }, []);

  const fetchFarmData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_ALL_FARM_NAMES,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      console.log("data in fetchFarmData", data);
      setFarmOptions(data?.data);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    console.log("Formatted Dates:", dateStrings);
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };


  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} marginTop={"6px"}>
          <RangePicker onChange={handleDateChange} format="DD-MM-YYYY" />
        </Grid>

        <Grid item xs={12} sm={2} marginTop={"6px"}>
          <FormControl fullWidth>
            <InputLabel>Transaction Type</InputLabel>
            <Select
              label="Transaction Type"
              value={transaction_type}
              onChange={(e) => {
                setSelectedType(e.target.value);
              }}
              size="small"
              displayEmpty
              inputProps={{ "aria-label": "Transaction Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <CurrencyRupeeIcon />
                  </IconButton>
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Transaction Type
              </MenuItem>
              {transactionType.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2} marginTop={"6px"}>
          <FormControl fullWidth>
            <InputLabel>Select Farm</InputLabel>
            <Select
              label="Select Farm"
              value={farm_id}
              onChange={(e) => {
                console.log("e.target.value----------", e.target.value);
                setSelectedFarmId(e.target.value);
              }}
              size="small"
              displayEmpty
              inputProps={{ "aria-label": "Select Farm" }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <GrassIcon />
                  </IconButton>
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Select Farm
              </MenuItem>
              {farmOptions.map((farm) => (
                <MenuItem key={farm.farm_id_fk} value={farm.farm_id_fk}>
                  {farm.farm_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2} marginTop={"6px"}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#2c7B55", color: "#ffffff" }}
            onClick={onApplyClick}
            disabled={!start_date || !end_date || !transaction_type || !farm_id }
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterTab;
