import React, { useState } from "react";
import Chart from "react-apexcharts";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCropAlt, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tokens } from "../../../../theme";
import { Typography, useTheme } from "@mui/material";
import {
  Select,
  InputLabel,
  FormControl,
  TextField,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";
library.add(faCropAlt, faSeedling);

const CropStage = ({ stagedata }) => {
  const theme = useTheme();
  const colorss = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [selectedCrop, setSelectedCrop] = useState(stagedata[0]?.crop_name);

  const handleCropChange = (event) => {
    setSelectedCrop(event.target.value);
  };

  const getCropData = (cropName) => {
    return stagedata?.find((crop) => crop?.crop_name === cropName);
  };

  const colors = [
    "#22c55e",
    "#16a34a",
    "#15803d",
    "#166534",
    "#14532d",
    "#4ade80",
    "#052e16",
  ];

  const chartOptions = {
    chart: {
      id: "harvesting-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: getCropData(selectedCrop)?.stages
        ? getCropData(selectedCrop)?.stages?.map((data) => data?.phase || "")
        : [], // Empty array if stages is not available
      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      title: {
        text: "No Of Plants",
        offsetX: 6,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#333",
        },
      },
    },
    tooltip: {
      enabled: true,
    },
    // colors: ['#ADD8E6'],
    colors: colors?.slice(0, getCropData(selectedCrop)?.stages?.length),

    plotOptions: {
      bar: {
        distributed: true,
        barWidth: "20%", // set the bar width to 40%
        dataLabels: {
          position: "top",
        },
      },
      dataLabels: {
        position: "top",
        style: {
          fontSize: "12px",
          fontWeight: 200,
          colors: ["#333"],
        },
      },
    },

    // colors: ['#3CB371','#f4f4f4'],
    stroke: {
      curve: "smooth",
      width: 0.5,
      // dashArray:[5,2],
      // colors: ['#0a6e21']
    },
  };

  const chartSeries = [];
  if (!stagedata) {
    // Render some fallback UI or return null if stagedata is not available
    return (
      <div>
        <></>
      </div>
    );
  }
  const cropDataStages = getCropData(selectedCrop)?.stages;
  if (cropDataStages) {
    chartSeries?.push({
      name: `${selectedCrop} Stage`,
      data: cropDataStages?.map((data) => data?.value),
      
    });
  }

  return (
    <Grid sx={{marginRight:'2%'}}>
      <Grid>
        <Grid container spacing={2} alignItems="center">
          {/* <Grid item xs={12} sm={8} marginTop={"28px"}>
            <Typography
              variant="h5"
              fontFamily={"Lexend Deca"}
              sx={{
                marginRight: "10px",
                backgroundColor: "#d2e4df",
                display: "inline-flex",
                alignItems: "center",
                // marginLeft: "-102px",
                borderRadius: "4px",
              }}
            >
              Crop
              <span style={{ marginLeft: "8px" }}>
                <FontAwesomeIcon icon={faSeedling} className="fal" />
              </span>
            </Typography>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={4}
            style={{ marginTop: "3%", marginLeft: "68%" }}
          >
            <FormControl fullWidth>
              <InputLabel id="Crop" style={{ textAlign: "center" }}>
                Crop
                <span style={{ marginRight: "30px" }}>
                  <FontAwesomeIcon icon={faSeedling} className="fal" />
                </span>
              </InputLabel>
              <Select
                labelId="Crop"
                id="Crop"
                label="Crop"
                name="Crop"
                margin="normal"
                value={selectedCrop}
                onChange={handleCropChange}
                size="small"
                placeholder="Select Crop"
                style={{ width: "80px",height:'20px'  }}
              >
                {stagedata?.map((crop) => (
                  <MenuItem key={crop?.crop_name} value={crop?.crop_name}>
                    <Typography variant="h6" fontFamily="Lexend Deca">
                      {crop?.crop_name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={248}
          width={290}
        />
      </Grid>
    </Grid>
  );
};

export default CropStage;