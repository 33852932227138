import { useTheme, Grid, Stack } from "@mui/material";

const TopBarWMS = () => {
  // const { username } = useAuth(); // Use the username from the AuthContext
  const name = localStorage.getItem("name");
  console.log("username coming in TopBarWMS or not :", name);
  const username = "Fambo";

  const currentTimestamp = new Date();

  const dateFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const formattedDate = currentTimestamp.toLocaleDateString(
    "en-GB",
    dateFormatOptions
  );

  const timeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedTime = currentTimestamp.toLocaleTimeString(
    undefined,
    timeFormatOptions
  );
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  // console.log("username is coming or not ::::", username);
  return (
    <Grid
      sx={{
        // backgroundColor: "#b5cbbf",
        backgroundColor: "#34519F83",
        width: "100%",
        borderRadius: "40px",
        marginTop: "0.07%",
      }}
    >
      <Stack
        direction="row"
        sx={{
          marginLeft: "30%",
          height: "%",
          marginTop: "0.12%",
          color: "#ffffff",
        }}
      >
        <Grid sx={{ marginLeft: "9%", fontWeight: "600" }}>
          Logged In :&nbsp; {username}&nbsp;({capitalizeFirstLetter(name)})
        </Grid>
        <Grid sx={{ marginLeft: "2%", fontWeight: "500" }}>
          |&nbsp; {formattedDateTime}
        </Grid>
      </Stack>
    </Grid>
  );
};

export default TopBarWMS;
