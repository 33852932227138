import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Tab,
  Tabs,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";
import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import SaveAlert from "../../../SaveAlert";
import ApiErrorAlert from "../../../ApiErrorAlert";

import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const Damage = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  // const client_id = localStorage.getItem("client_id");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [selectedFarm, setSelectedFarm] = useState("");
  const [selectedCrop, setSelectedCrop] = useState("");
  const [selectedTab, setSelectedTab] = useState("monthly");
  const [farmOptions, setFarmOptions] = useState([]);
  const [cropOptions, setCropOptions] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [farmData, setFarmData] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [monthlyAverage, setMonthlyAverage] = useState(0);
  const [weeklyAverage, setWeeklyAverage] = useState(0);
  const [clientFarmDetails, setClientFarmDetails] = useState([]);
  const [countOfDamage, setCountOfDamage] = useState();

  useEffect(() => {
    fetchFarmData();
  }, []);

  useEffect(() => {
    if (farmOptions?.length > 0) {
      setSelectedFarm(farmOptions[0]);
    }
    console.log(farmOptions);
    console.log(selectedFarm);
  }, [farmOptions]);
  console.log(farmOptions);
  console.log(selectedFarm);

  useEffect(() => {
    console.log(cropOptions);
    if (cropOptions?.length > 0) {
      setSelectedCrop(cropOptions[0]);
      console.log("mmkn", cropOptions, cropOptions?.length);
    }
  }, [cropOptions]);
  console.log("eged83g", cropOptions);

  const fetchFarmData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      setClientFarmDetails(data?.clientFarmInvStat);
      setFarmData(data?.farmdetail?.rows[0]?.farm_name);
      setFarmOptions(
        data?.farmdetail?.rows?.map((row) => row?.farm_name).flat()
      );
      // setFarmOptions([data.farmdetail.rows.map((row) => row.farm_name)]);

      setCropData(data?.dumpdetail?.rows);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching farm data:", error);
    }
  };

  const fetchCropData = async () => {
    try {
      console.log("data fetch ke liye aggya", selectedFarm);
      const cropData1 = cropData?.find(
        (row) => row?.farm_name === selectedFarm
      );
      console.log(cropData);
      if (cropData1) {
        setCropOptions(cropData1?.crop_data?.map((crop) => crop?.crop_name));
      } else {
        setCropOptions([]);
        setSelectedCrop("");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching crop data:", error);
    }
  };

  useEffect(() => {
    if (selectedFarm) {
      fetchCropData();
      const selectedObject = clientFarmDetails?.find(
        (row) => row?.farm_name === selectedFarm
      );
      let count = selectedObject?.total_dump_plants;
      if (count == null) {
        count = 0;
      }
      setCountOfDamage(count);
    }
  }, [selectedFarm]);

  const handleFarmChange = (event) => {
    const selectedFarm = event.target.value;
    setSelectedFarm(selectedFarm);
    setSelectedCrop("");
    setChartSeries([]); // Clear the chart series
  };

  const handleCropChange = (event) => {
    setSelectedCrop(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getChartData = () => {
    try {
      const cropData1 = cropData?.find(
        (row) => row?.farm_name === selectedFarm
      );
      const selectedCropData = cropData1?.crop_data?.find(
        (crop) => crop?.crop_name === selectedCrop
      );

      // Calculate Monthly Average
      const currentDate = new Date();
      const monthlyData = selectedCropData?.daily_data?.filter((data) => {
        const date = new Date(data?.date);
        return (
          date.getMonth() === currentDate?.getMonth() &&
          date.getFullYear() === currentDate?.getFullYear()
        );
      });
      const monthlyTotal = monthlyData?.reduce(
        (total, data) => total + data?.no_of_plants,
        0
      );
      const monthlyAverage1 =
        monthlyData.length > 0 ? monthlyTotal / monthlyData.length : 0;
      const monthlyAverage = monthlyAverage1.toFixed(1);
      setMonthlyAverage(monthlyAverage);

      // Calculate Weekly Average
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      const weeklyData = selectedCropData?.daily_data?.filter((data) => {
        const date = new Date(data?.date);
        return date >= oneWeekAgo && date <= currentDate;
      });
      const weeklyTotal = weeklyData?.reduce(
        (total, data) => total + data?.no_of_plants,
        0
      );
      const weekly1Average =
        weeklyData.length > 0 ? weeklyTotal / weeklyData.length : 0;
      const weeklyAverage = weekly1Average?.toFixed(1);
      setWeeklyAverage(weeklyAverage);

      const updatedChartData = selectedCropData?.daily_data.map((data) => ({
        x: new Date(data.date)?.getTime(),
        y: data?.no_of_plants,
      }));
      setChartData(updatedChartData);
      const updatedChartSeries = [
        {
          name: `${selectedCrop} Damage`,
          data: updatedChartData,
        },
      ];
      setChartSeries(updatedChartSeries);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    if (selectedFarm && selectedCrop) {
      getChartData();
    } else {
      setChartData([]);
      setChartSeries([]);
    }
  }, [selectedFarm, selectedCrop]);

  // Rest of the code...

  const chartOptions = {
    colors: ["#de0707"],

    toolbar: {
      show: true,
      autoSelected: "zoom",
      tools: {
        zoom: true,
        zoomin: true,
        zoomout: true,
        reset: true,
      },
    },
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 800,
      animateGradually: {
        delay: 150,
        enabled: true,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 400,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#333",
        },
      },
      // type: "category",
      // labels: {
      //   formatter: function (value) {
      //     const date = new Date(value);
      //     const day = String(date.getDate()).padStart(2, "0");
      //     const month = String(date.getMonth() + 1).padStart(2, "0");
      //     const year = String(date.getFullYear());
      //     return `${day}/${month}/${year}`;
      //   },
      //   style: {
      //           fontSize: "12px",
      //           fontWeight: 400,
      //           color: "#333",
      //         },
      // },
    },
    yaxis: {
      title: {
        text: "No. Of Plants",
        offsetX: 2,
        style: {
          fontSize: "16px",
          fontWeight: 400,
          color: "#333",
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 1,
      colors: ["#de0707"],
    },
    tooltip: {
      enabled: true,
      x: {
        format: "dd/MM/yyyy",
      },
      style: {
        fontSize: "12px",
        fontWeight: 400,
      },
    },
    fill: {
      opacity: 0.5,
    },
    series: chartSeries,
  };

  const updatedChartSeries = [
    {
      name: `${selectedCrop} Damage`,
      data: chartData,
    },
  ];

  console.log("FARMMM OPTIONSSSSSSSS Damageee", farmOptions);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-0.4%",
            marginLeft: "45px",
          }}
        >
          <div>
            {/* <p style={{ fontSize: "28px", marginBottom: "4px" }}>
              {selectedTab === "monthly"
                ? `${monthlyAverage}`
                : `${weeklyAverage}`}
            </p>
            <p style={{ fontSize: "16px", color: "#A5A5A5", marginTop: "4px" }}>
            </p> */}

            <Typography
              variant="h1"
              color={colors.black.DEFAULT}
              fontFamily="Lexend Deca"
              style={{ marginBottom: "1px" }}
            >
              {countOfDamage}
            </Typography>

            <Typography
              variant="h6"
              color={colors.black[200]}
              fontFamily="Lexend Deca"
              sx={{ marginTop: "4px" }}
            >
              Total Damage(No. of plants)
            </Typography>

            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="#ffffff"
              textColor="#ffffff"
              sx={{ marginTop: "8px" }}
            >
              <Tab
                label="Monthly"
                value="monthly"
                style={{
                  backgroundColor:
                    selectedTab === "monthly" ? "#2C7B55" : "#ecf4f0",
                  color: selectedTab === "monthly" ? "#ffffff" : "inherit",
                  // border: "1px solid #adadad",
                }}
              />
              {/* <Tab
                label="Weekly"
                value="weekly"
                style={{
                  backgroundColor:
                    selectedTab === "weekly" ? "#2C7B55" : "#ecf4f0",
                  color: selectedTab === "weekly" ? "#ffffff" : "inherit",
                  // border: "1px solid #adadad",
                }}
              /> */}
            </Tabs>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "40px",
              marginTop: "-17%",
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Select Farm</InputLabel>
              <Select
                id="Select Farm"
                label="Select Farm"
                labelId="Select Farm"
                name="Select Farm"
                size="small"
                value={selectedFarm}
                onChange={handleFarmChange}
                style={{ width: "120px" }}
                displayEmpty
                inputProps={{ "aria-label": "Select Farm" }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {farmOptions &&
                  farmOptions?.map((farm) => (
                    <MenuItem key={farm} value={farm}>
                      {farm}
                    </MenuItem>
                  ))}
                <MenuItem value="" disabled>
                  Select Farm
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginLeft: "7%" }}>
              <InputLabel id="SelectCrop" style={{ marginTop: "-1.7%" }}>
                Select Crop
              </InputLabel>
              <Select
                id="Select Crop"
                value={selectedCrop}
                label="Select Crop"
                labelId="Select Crop"
                size="small"
                name="Select Crop"
                onChange={handleCropChange}
                displayEmpty
                inputProps={{ "aria-label": "Select Crop" }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
                style={{ width: "120px" }}
              >
                {cropOptions &&
                  cropOptions?.map((crop) => (
                    <MenuItem key={crop} value={crop}>
                      {crop}
                    </MenuItem>
                  ))}
                <MenuItem disabled value="">
                  Select Crop
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div style={{ marginRight: "25px" }}>
        <Chart
          options={chartOptions}
          series={updatedChartSeries}
          type="area"
          height={194}
        />
      </div>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};

export default Damage;
