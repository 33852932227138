import React, { useState } from "react";
import { Typography, useTheme, Button, Card, Grid } from "@mui/material";
import { tokens } from "../../../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import ReportPage from "../../../Components/ReportComponent/ReportPage/ReportPage";
import FinanceReportPage from "../../Reports/FinanceReportPage/FinanceReportPage";
import Report from "../ReportDisplayPage/Report";
import ApiErrorAlert from "../../../ApiErrorAlert";
import SaveAlert from "../../../SaveAlert";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const MainPage = () => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [columns, setColumns] = useState([]);
  const [financeActualData, setFinanceActualData] = useState([]);
  const [financeExpectedData, setFinanceExpectedData] = useState([]);
  const [title, setTitle] = useState("");
  const options = [
    { key: "Crop", label: "Crop", title: "Crop Report" },
    {
      key: "Harvesting",
      label: "Harvesting",
      title: "Harvesting Report",
    },
    { key: "Damage", label: "Damage", title: "Damage Report" },
    { key: "Area_occupied", label: "Area", title: "Area Report" },
    { key: "Farms", label: "Farm", title: "Farm Report" },
    {
      key: "Seedling",
      label: "Plantation",
      title: "Plantation Report",
    },
    {
      key: "Finance",
      label: "Finance",
      title: "Expense Report",
    },
    
  ];

  if (userDetails?.home_b_sensorBox) {
    options.push({
      key: "Sensor",
      label: "EC/Ph Telemetary Data",
      title: "EC/Ph Telemetary Data",
    });
  }
  const handleButtonClick = () => {
    setSelectedOption(null);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleCardClick = (option, option_title) => {
    getColumnsFromBackend(option); // Call the getColumnsFromBackend function directly
    setSelectedOption(option);
    setTitle(option_title);
    console.log("title:::::::", title);
  };
  console.log("title outside handle click function ", title);
  const getColumnsFromBackend = (option) => {
    axiosInstance
      .get(`${API_ENDPOINTS.GET_REPORT_MODULE_SELECT_OPTIONS}${option}`)
      .then((response) => {
        console.log("Dataaaaa", response.data);
        const financeD = response?.data?.budget_plan;
        const financeD2 = response?.data?.budget_actual;
        setFinanceExpectedData(financeD);
        setFinanceActualData(financeD2);
        const columnNames = response?.data?.rows?.map(
          (item) => item?.column_name
        ); // Extract column names
        console.log("Columns from backend:", columnNames);
        setColumns(columnNames);
      })
      .catch((error) => {
        setApiErrorOpen(true);
        console.log("Error fetching columns from backend:", error);
      });
  };

  return (
    <>
      <div>
        {selectedOption === null ? (
          <div
            className="box-content"
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "45px",
              marginTop: "1%",
            }}
          >
            <Typography
              variant="h1"
              color={colors.green.DEFAULT}
              style={{
                marginLeft: "2.4%",
              }}
            >
              Reports
            </Typography>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1%",
              }}
            >
              <div style={{ width: "40%" }}>
                <Typography
                  variant="h1"
                  style={{ marginLeft: "3.8%" }}
                  color={colors.green.DEFAULT}
                >
                  {title}
                </Typography>
              </div>
              <div style={{ width: "8%", marginRight: "-0.1%" }}>
                <Button
                  variant="outlined"
                  startIcon={
                    <ArrowBackIcon style={{ color: colors.green.DEFAULT }} />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    color: colors.green.DEFAULT,
                  }}
                  onClick={handleButtonClick}
                >
                  <Typography variant="h5" color={colors.green.DEFAULT}>
                    Back
                  </Typography>
                </Button>
              </div>
            </div>
          </>
        )}
        <div>
          {selectedOption === null ? (
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "4%", marginLeft: "1%" }}
            >
              {options?.map((optionData, index) => (
                <Grid key={index} item xs={6} sm={6} md={4} lg={3}>
                  <Card
                    variant="outlined"
                    sx={{
                      width: "250px",
                      height: "130px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#b5cbbf",
                      color: "#2c7B55",
                      borderRadius: "20px",
                      transition: "background-color 0.3s",
                      boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
                      marginBottom: "8%",
                      "&:hover": {
                        backgroundColor: "#66a587",
                        color: "#ffffff",
                        cursor: "pointer",
                        boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                    onClick={() =>
                      handleCardClick(optionData?.key, optionData?.title)
                    }
                  >
                    <Typography variant="h2">{optionData?.label}</Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : selectedOption === "Finance" ? (
            <FinanceReportPage
              dataFinanceactual={financeActualData}
              dataFinanceexp={financeExpectedData}
              title={title}
              selectedkey={selectedOption}
            />
          ) : (
            <ReportPage
              columns={columns}
              title={title}
              selectedkey={selectedOption}
            />
          )}
        </div>
      </div>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};
export default MainPage;
