import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Stack,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Typography,
  FormHelperText,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";


import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BorderColor, ErrorOutlineOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ReactQuill from "react-quill";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";

import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const TransportDialog = ({
  dialogOpen,
  setDialogOpen,
  mandatoryFields,
  fetchTaskData,
  listOfZones,
  closeMasterDialog,
}) => {
  const selectedPurchaseDate = new Date().toISOString().split("T")[0];

  const [transportDialogState, setTransportDialogState] = useState({
    vehicle_number: "",
    selectedColor: "#3174ad",
    selectedColorDescription: null,
    description: "",
    startLocation: "",
    endLocation: "",
    showPopup: false,
    open: false,
    savedText: "",
    showAttachment: false,
    showAddDescription: true,
    selectedProofs: [],
    selectedFields: [],
    descriptions: [],
    dataTypes: [],
    amount: 0,
    labor: 0,
    date_of_travel: selectedPurchaseDate,
    proof_url: "",
  });
  const {
    vehicle_number,
    selectedColor,
    selectedColorDescription,
    description,
    showPopup,
    selectedProofs,
    savedText,
    descriptions,
    showAttachment,
    showAddDescription,
    dataTypes,
    startLocation,
    endLocation,
    amount,
    labor,
    date_of_travel,
    proof_url,
  } = transportDialogState;
  // console.log("mandatoryFields", mandatoryFields);

  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const hiddenToken = localStorage.getItem("token");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const moment = require("moment");

  const updateState = (key, value) => {
    setTransportDialogState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [proofDetailsAdded, setProofDetailsAdded] = useState(false);
  const handleSubmit = async (e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);
    console.log("entered !");
    e.preventDefault();
    const formData = {
      optional_fields: {
        activity_name: "",
        priority_color: selectedColor,
        priority_color_description: selectedColorDescription,
        start_location: startLocation,
        end_location: endLocation,
        proof: selectedProofs?.length > 0 ? selectedProofs : null, // Set value based on the selectedProofs array
        instruction: description,
        amount: amount,
        labor: labor,
        vehicle_number: vehicle_number,
        date_of_travel: moment(date_of_travel, "YYYY/MM/DD").valueOf(),
        proof_url: proof_url,
      },
      mandatory_fields: mandatoryFields,
    };
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_FINANCE_PLANNING,
        formData,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

      if (response.status >= 200 && response.status < 305) {
        console.log("Purchase Task Allocated");
        updateState("showPopup", true);
      } else {
        setApiErrorOpen(true);
      }
      console.log(response?.data);
      console.log("in middle !");
    } catch (error) {
      setApiErrorOpen(true);
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      setApiErrorMessage(error.response.data.msg);
    }

    e.target.reset();
  };

  const handlePopupClose = () => {
    updateState("open", false);
    updateState("showPopup", false);
    closeMasterDialog();
    setDialogOpen(false);
    fetchTaskData();
  };

  const handleStartLocationChange = (event) => {
    updateState("startLocation", event.target.value);
  };

  const handleSelectedColorDescriptionChange = async (e) => {
    const selectedColorDescription = e.target.value;
    updateState("selectedColorDescription", selectedColorDescription);
  };

  const handleEndLocationChange = (event) => {
    updateState("endLocation", event.target.value);
  };

  const handleTravelDateChange = async (event) => {
    updateState("date_of_travel", event.target.value);
  };
  const handleVehicleNumberChange = (event) => {
    updateState("vehicle_number", event.target.value);
  };

  const handleColorChange = (event) => {
    updateState("selectedColor", event.target.value);
  };

  const handleDescriptionChange = (e, index) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = e.target.value;
    updateState("descriptions", updatedDescriptions);
  };

  const handleDataTypeChange = (e) => {
    updateState("dataTypes", [e.target.value]);
  };

  const handleSave = () => {
    const proofs = descriptions.map((desc, index) => {
      const attachmentType = dataTypes[index] ? `${dataTypes[index]}` : "";
      setProofDetailsAdded(true);
      return { description: desc, attachmentType };
    });
    updateState("selectedProofs", proofs); // Update selectedProofs here
    updateState(
      "savedText",
      proofs
        .map((proof) => `${proof.description} | ${proof.attachmentType}`)
        .join("\n")
    );
    updateState("descriptions", []);
    updateState("dataTypes", []);
    updateState("showAddDescription", true);
    updateState("showAttachment", false);
  };

  const handleAddDescription = () => {
    updateState("showAddDescription", false);
    updateState("showAttachment", true);
    updateState("descriptions", [...descriptions, ""]);
  };

  const modules = {
    toolbar: {
      container: [[{ list: "ordered" }]],
    },
  };

  const formats = ["list"];

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { minWidth: "480px", minHeight: "480px" } }}
      >
        <DialogTitle>
          <Typography variant="h3" color={colors.green.DEFAULT}>
            Transport
          </Typography>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} marginTop={"15px"}>
                <TextField
                  label="Vehicle Number"
                  value={vehicle_number}
                  onChange={handleVehicleNumberChange}
                  fullWidth
                  size="small"
                  autoFocus
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
                <TextField
                  id="input-with-icon-textfield"
                  label="Priority Color"
                  value={selectedColorDescription}
                  inputProps={{ maxLength: 15 }}
                  size="small"
                  onChange={handleSelectedColorDescriptionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          id="color"
                          value={selectedColor}
                          onChange={handleColorChange}
                          style={{
                            width: "24px",
                            height: "24px",
                            padding: "0",
                            border: "none",
                            borderRadius: "50%",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} marginTop={"15px"}>
                <TextField
                  label="Pickup Location"
                  value={startLocation}
                  onChange={handleStartLocationChange}
                  fullWidth
                  autoFocus
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"15px"}>
                <TextField
                  label="Destination"
                  value={endLocation}
                  onChange={handleEndLocationChange}
                  fullWidth
                  autoFocus
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <TextField
                    label="Enter Total Amount"
                    name="Enter Total Amount"
                    value={amount}
                    type="number"
                    size="small"
                    onChange={(event) => {
                      const value = parseInt(event.target.value);
                      if (!isNaN(value) && value > 1000000) {
                        updateState("amount", 1000000);
                      } else {
                        updateState("amount", value);
                      }
                    }}
                    fullWidth
                    required
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 1000000,
                      inputMode: "numeric",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "18px" }}>
                <FormControl fullWidth>
                  <TextField
                    label="Date Of Travel"
                    type="date"
                    size="small"
                    value={date_of_travel}
                    fullWidth
                    onChange={handleTravelDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                {descriptions?.map((description, index) => (
                  <TextField
                    key={index}
                    label="Proof title"
                    fullWidth
                    size="small"
                    margin="normal"
                    value={description}
                    inputProps={{ maxLength: 15 }}
                    onChange={(e) => handleDescriptionChange(e, index)}
                  />
                ))}
                {proofDetailsAdded ? (
                  <>
                    <Tooltip
                      title="Proof Type already added !"
                      placement="right"
                      disableHoverListener={!proofDetailsAdded}
                    >
                      <Button
                        variant="outlined"
                        endIcon={
                          <AddIcon style={{ color: colors.green.DEFAULT }} />
                        }
                        sx={{
                          borderColor: colors.green.DEFAULT,
                          color: colors.green.DEFAULT,
                        }}
                        onClick={handleAddDescription}
                        disabled
                      >
                        <Typography variant="h6" color={colors.green.DEFAULT}>
                          Proof List
                        </Typography>
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  showAddDescription && (
                    <Tooltip
                      title="Click to add the attachments that supervisor needs to send as a proof of completion of task !"
                      placement="right"
                    >
                      <Button
                        variant="outlined"
                        endIcon={
                          <AddIcon style={{ color: colors.green.DEFAULT }} />
                        }
                        sx={{
                          borderColor: colors.green.DEFAULT,
                          color: colors.green.DEFAULT,
                        }}
                        onClick={handleAddDescription}
                      >
                        <Typography variant="h6" color={colors.green.DEFAULT}>
                          Proof List
                        </Typography>
                      </Button>
                    </Tooltip>
                  )
                )}
              </Grid>

              {showAttachment && (
                <>
                  <Grid item xs={12} sm={4} style={{ marginTop: "10px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Select Proof type</InputLabel>
                      <Select
                        value={dataTypes[0] || ""}
                        label="Select Proof type"
                        inputProps={{ "aria-label": "Select Proof Type" }}
                        displayEmpty
                        size="small"
                        onChange={handleDataTypeChange}
                        startAdornment={
                          <InputAdornment position="start"></InputAdornment>
                        }
                      >
                        <MenuItem value="PDF">PDF</MenuItem>
                        <MenuItem value="DOC">DOC</MenuItem>
                        <MenuItem value="Image">Image</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={1.2}>
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      style={{
                        float: "left",
                        marginLeft: "16px",
                        marginTop: "19px",
                      }}
                      onClick={handleSave}
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Save
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
                <ReactQuill
                  style={{
                    height: "100px",
                    width: "100%",
                  }}
                  placeholder="Additional instructions for this activity!"
                  modules={modules}
                  formats={formats}
                  value={description} // Add value prop
                  onChange={(value) => updateState("description", value)} // Update description state
                />
              </Grid>
              {savedText && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginTop: "-48%",
                    marginLeft: "52%",
                    backgroundColor: "#ecf4f0",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    # {savedText}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="flex-start"
            ></Grid>

            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={12} style={{ marginTop: "90px" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  style={{
                    height: "32px",
                    marginTop: "2%",
                    marginRight: "0%",
                    marginLeft: "69%",
                  }}
                >
                  <Button
                    type="submit" // Added type="submit" to trigger form submission
                    variant="contained"
                    sx={{ backgroundColor: colors.green.DEFAULT }}
                    style={{ float: "left" }}
                  >
                    <Typography variant="h6" color={colors.white.DEFAULT}>
                      Create
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => setDialogOpen(false)}
                    variant="outlined"
                    style={{ float: "left", marginLeft: "15px" }}
                  >
                    <Typography variant="h6" color={colors.green.DEFAULT}>
                      Cancel
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            
          </form>
          <Dialog open={apiErrorOpen} onClose={() => setApiErrorOpen(false)}>
            <DialogTitle style={{ textAlign: "center" }}>
              <ErrorOutlineOutlined
                style={{ width: "60px", height: "60px", color: "#A45A52" }}
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              {apiErrorMessage ? (
                <Typography
                  variant="h4"
                  color={colors.red[100]}
                  fontFamily="Lexend Deca"
                >
                  {apiErrorMessage}
                </Typography>
              ) : (
                <Typography
                  variant="h4"
                  color={colors.red[100]}
                  fontFamily="Lexend Deca"
                >
                  An error occurred, please try again
                </Typography>
              )}
            </DialogContent>
            <Button
              onClick={() => {
                setApiErrorOpen(false);
              }}
            >
              <Typography
                variant="h4"
                color={colors.red[100]}
                fontFamily="Lexend Deca"
              >
                OK
              </Typography>
            </Button>
          </Dialog>

          <Dialog open={showPopup} onClose={() => setDialogOpen(false)}>
            <DialogTitle style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ width: "60px", height: "60px", color: "#2C7B55" }}
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                color={colors.green.DEFAULT}
                // fontWeight={typography.h1}
                fontFamily="Lexend Deca"
              >
                New activity added!
              </Typography>
            </DialogContent>
            <Button onClick={handlePopupClose}>
              <Typography
                variant="h4"
                color={colors.green.DEFAULT}
                // fontWeight={typography.h1}
                fontFamily="Lexend Deca"
              >
                OK
              </Typography>
            </Button>
          </Dialog>
        </DialogContent>
      </Dialog>
      
    </>
  );
};

export default TransportDialog;
