import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";
import { IoDocumentsOutline } from "react-icons/io5";
import { FaTruckMoving } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import SuccessPopUp from "../../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../../PopUpMsg/ErrorPopUp";
import { useSelector, useDispatch } from "react-redux";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import {
  Button,
  TextField,
  Stack,
  Paper,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { MdModeEdit, MdPeopleAlt, MdDescription } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../WMStheme";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AWS from "aws-sdk";
import { ScaleLoader } from "react-spinners";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "ap-south-1",
});
const s3 = new AWS.S3();
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  zIndex: 9999,

  // backgroundColor: "red",
};
const PersonalDetails = ({ TransportData, setTransportData }) => {
  
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(TransportData);

  const handleChange = (field, value) => {
    // Use the appropriate nested structure based on your JSON
    if (field.includes(".")) {
      const [parentField, nestedField] = field.split(".");
      setTransportData((prevData) => ({
        ...prevData,
        [parentField]: {
          ...prevData[parentField],
          [nestedField]: value,
        },
      }));
    } else {
      setTransportData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };
  const validateField = (field, value) => {
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "This field is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Define your required fields here
    const requiredFields = [
      "transporter_name",
      "transporter_code",
      "dl",
      "vehical_no",
      // Add other required fields
    ];

    requiredFields.forEach((field) => {
      const value = formValues[field];
      if (!value) {
        newErrors[field] = "This field is required";
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  return (
    <>
      {/* <Typography variant="h6">Personal Details</Typography> */}

      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "2.9%" }}
      >
        <Grid item xs={12} sm={3.8}>
          <TextField
            label="Transporter Name"
            size="small"
            fullWidth
            required
            value={TransportData?.transporter_name}
            error={Boolean(errors.transporter_name)}
            helperText={errors.transporter_name}
            onChange={(e) => {
              handleChange("transporter_name", e.target.value);
              validateField("transporter_name", e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3.8}>
          <TextField
            label="Transporter Code"
            size="small"
            fullWidth
            required
            value={TransportData?.transporter_code}
            error={Boolean(errors.transporter_code)}
            helperText={errors.transporter_code}
            onChange={(e) => {
              handleChange("transporter_code", e.target.value);
              validateField("transporter_code", e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.6%" }}>
          <TextField
            label="Driving License"
            name="Driving License"
            size="small"
            value={TransportData?.dl}
            onChange={(e) => handleChange("dl", e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <PermIdentityIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.0%", marginLeft: "2.9%" }}
      >
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.6%" }}>
          <TextField
            label="Vehicle Number"
            name="Vehicle Number"
            size="small"
            value={TransportData?.vehical_no}
            onChange={(e) => handleChange("vehical_no", e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <IoDocumentsOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.6%" }}>
          <TextField
            label="Address "
            labelId="Address 1"
            name="addressline1"
            value={TransportData?.wh_address?.address}
            onChange={(e) =>
              handleChange("wh_address?.address", e.target.value)
            }
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.6%" }}>
          <TextField
            label="PAN Number"
            name="PAN Number"
            size="small"
            value={TransportData?.pan}
            onChange={(e) => handleChange("pan", e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <IoDocumentsOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "2.9%" }}
      >
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.6%" }}>
          <TextField
            label="Aadhaar Number"
            name="Aadhaar Number"
            size="small"
            value={TransportData?.aadhaar}
            onChange={(e) => handleChange("aadhaar", e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <IoDocumentsOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3.5}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const handleDownloadClick = (fileName) => {
  const presignedUrl = s3.getSignedUrl("getObject", {
    Bucket: "fambo-assets",
    Key: `farmer/${fileName}`,
    Expires: 3600,
  });
  console.log("Presigned URL for viewing:", presignedUrl);
  window.open(presignedUrl, "_blank");
};

const Documents = ({
  images,
  handleCancelImage,
  TransportData,
  handleImageChange,
  setEditStatusActive,
  editStatusActive,
}) => (
  <>
    <Grid
      container
      spacing={3.5}
      style={{ marginTop: "0.6%", marginLeft: "0.5%" }}
    >
      {images?.map((imageType, index) => (
        <Grid key={index} item xs={12} sm={3.85}>
          <Typography
            variant="h2"
            color="textPrimary"
            fontWeight="400"
            gutterBottom
          >
            Upload&nbsp;
            {imageType.type.charAt(0).toUpperCase() + imageType.type.slice(1)}
            <>
              <Button
                variant="contained"
                startIcon={
                  <FileDownloadIcon
                    style={{ color: "#ffffff", fontSize: 26 }}
                  />
                }
                sx={{
                  backgroundColor: "#34519fe6",
                  marginLeft: "15%",
                }}
                size="small"
                disabled={
                  !editStatusActive ||
                  !TransportData[imageType.type + "_attach"]
                }
                onClick={() =>
                  handleDownloadClick(TransportData[imageType.type + "_attach"])
                }
              >
                <Typography variant="h7" fontFamily="Lexend Deca">
                  Download
                </Typography>
              </Button>
            </>
          </Typography>
          {imageType?.images.map((image, imgIndex) => (
            <Grid key={imgIndex} item xs={12} sm={5}>
              <Box
                border="1px dashed #000"
                borderRadius="10px"
                height="60px"
                width="60px"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                position="relative"
              >
                <IconButton
                  onClick={() => handleCancelImage(imageType.type, imgIndex)}
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    color: "red",
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <img
                  src={image.data}
                  alt={`${imageType.type} ${imgIndex + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ marginTop: "3%" }}>
            <Box
              border="1px dashed #000"
              borderRadius="10px"
              height="100px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <Typography
                variant="body1"
                color="textSecondary"
                marginBottom="8px"
              >
                Click or drag to add an image
              </Typography>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(imageType.type, event)}
                style={{ display: "none" }}
                id={`image-upload-${index}`}
              />
              <label htmlFor={`image-upload-${index}`}>
                <IconButton component="span">
                  <AddIcon style={{ color: "red" }} />
                  <Typography variant="button" color="red">
                    Select Image
                  </Typography>
                </IconButton>
              </label>
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
    <Grid
      container
      spacing={2.5}
      sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
    >
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
  </>
);

const TransportConfig = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const [TransportData, setTransportData] = useState();
  const dispatch = useDispatch();
  const [WarehouseId, setWarehouseId] = useState();
  const [message, setMessage] = useState("");
  const colors = tokens(theme.palette.mode);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState("Transport Details");
  const [editStatusActive, setEditStatusActive] = useState(false);
  const [transportLoading,setTransportLoading] =useState(false);
  const [transportNameError,setTransportNameError] =useState(false);
  const [transportCodeError ,setTransportCodeError] =useState(false);
  const [drivingLicenseError ,setDrivingLicenseError] =useState(false);
  const [vehicleError ,setVehicleError] = useState(false);
  const [addressError,setAddressError] =useState(false);
  const [panNumberError,setPanNumberError]=useState(false);
  const [aadhaarError , setAadharError] =useState(false);
const [nextButtonClickable , setNextButtonClickable] =useState(false);
  const sections = [
    { name: "Transport Details", icon: <MdModeEdit /> },
    { name: "Documents", icon: <MdDescription /> },
  ];
  const { action, transportData } = useSelector((state) => state.transport);
  console.log("transport data ", transportData);

  useEffect(() => {
    switch (action) {
      case "edit":
        if (transportData) {
          // If editing, prefill the form with the transportData
          console.log("transportData in edit form ", transportData);
          console.log("edit action performed");
          setEditStatusActive(true);
          setWarehouseId(transportData?.id);
          console.log("transport id", transportData?.id);
          setTransportData((prevData) => ({
            ...prevData,
            ...transportData,
          }));
          // Update only the fields present in transportData
        }
        break;

      case "add":
        // If adding, clear the form data
        console.log("add action performed");
        setTransportData({
          transporter_name: "",
          transporter_code: "",
          address: {
            address: "",
          },
          dl: "",
          vehical_no: "",
          pan: "",
          aadhaar: "",
          type: "",
          pan_attach: "",
          dl_attach: "",
          aadhar_attach: "",
          active: true,
        });
        break;

      // Add more cases if needed

      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, transportData]);

  useEffect (()=>{
    const isTransportNameError = TransportData?.transporter_name.trim() === "";
    const isTransportCodeError =TransportData?.transporter_code.trim() === "";
    const isDrivingLicenseError =TransportData?.dl.trim() === "";
    const  isVehicleError = TransportData?.vehical_no.trim() === "";
    const  isPanError  =TransportData?.pan.trim() === "";
    const isAadharError = TransportData?.aadhaar.trim() === "";
    const isValid = !isTransportCodeError && !isTransportNameError && !isDrivingLicenseError && !isPanError &&isAadharError && isVehicleError
    setNextButtonClickable(!isValid);


},[TransportData?.transporter_name ,TransportData?.transporter_code ,TransportData?.dl ,TransportData?.vehicle_no , TransportData?.pan ,TransportData?.aadhaar]);
  const handleNext = () => {
    let isValid = true;
switch (currentSection) { 
  case "Transport Details":

    setTransportNameError();
    setTransportCodeError();
    setAadharError();
    setPanNumberError();
    setDrivingLicenseError();
    setVehicleError();
    set
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex < sections.length - 1) {
      const nextSection = sections[currentIndex + 1].name;
      setCurrentSection(nextSection);
    }
  }
  };
  const handleSubmit = async () => {
    uploadDocuments();
    try {
      if (action === "edit") {
        // If editing, send a PUT request

        await updateTransport();
      } else {
        // If adding, send a POST request
        await createTransport();
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error("Error submitting Transport Details:", error);
      setMessage("Error submitting Transport Details");
    }
  };
  const updateTransport = async () => {
    try {
      if (!TransportData?.id) {
        console.error("Transport id is missing in TransportData.");
        setErrorMessage("Please try Again");
        return;
      }
setTransportLoading(true);
      const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_TRANSPORT_DATA(
        TransportData?.id
      )}`;
      console.log("API Endpoint:", endpoint);

      // Log the TransportData before making the API call
      console.log("Updating TransportData:", TransportData);

      const response = await axiosInstance.put(endpoint, TransportData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data updated Successfully!");

        setSuccessPopupOpen(true);
        // navigate('/viewWarehouse');
      } else {
        console.error("Failed to update the transport data");
        setErrorMessage(
          "Failed to update the transport data. Please try again."
        );
      }
    } catch (error) {
      setErrorPopupOpen(true);
      console.error("Error occurred while updating the transport data:", error);
    }
  };
  useEffect(() => {
    // Effect to navigate after success message is set
    if (successPopupOpen) {
      const timer = setTimeout(() => {
        setSuccessPopupOpen(false);
        navigate("/viewTransport");
        setTransportLoading(false);
      }, 700);

      return () => clearTimeout(timer);
    }
  }, [successPopupOpen, navigate]);
  const createTransport = async () => {
    uploadDocuments();
    try {
      // Determine the endpoint for creating a new transport
      const endpoint = `${API_ENDPOINTS.POST_WMS_ADD_TRANSPORT_DATA}`;
      console.log("API Endpoint:", endpoint);

      // Log the TransportData before making the API call
      console.log("Creating TransportData:", TransportData);
      setTransportLoading(true);
      // Send the POST request to create a new transport
      const response = await axiosInstance.post(endpoint, TransportData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data created successfully!");
        setSuccessPopupOpen(true);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the transport data");
        setErrorMessage(
          "Failed to create the transport data. Please try again."
        );
      }
    } catch (error) {
      setErrorPopupOpen(true);
      console.error("Error occurred while creating the transport data:", error);
    }
  };

  const handlePrevious = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex > 0) {
      const previousSection = sections[currentIndex - 1].name;
      setCurrentSection(previousSection);
    }
  };

  const [images, setImages] = useState([
    { type: "aadhar", images: [], file: null },
    { type: "pan", images: [], file: null },
    { type: "dl", images: [], file: null },
  ]);

  const uploadDocuments = async () => {
    const uploadPromises = images?.map((file) => {
      if (file.file) {
        const key = `${file.file.name}`;

        const params = {
          Bucket: "fambo-assets",
          Key: `farmer/${key}`,
          Body: file.file,
        };

        return new Promise((resolve, reject) => {
          s3.upload(params, (err, data) => {
            if (err) {
              console.log("Error uploading file:", err);
              reject(err);
            } else {
              console.log("File uploaded successfully:", data.Location);
              resolve(key);
            }
          });
        });
      }
      return Promise.resolve(null);
    });
    try {
      const uploadedKeys = await Promise.all(uploadPromises);
      console.log("All files uploaded:", uploadedKeys);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleImageChange = (imageType, event) => {
    const file = event.target.files[0];
    console.log("images------------", images);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const updatedImages = [...images];
        const imageTypeIndex = updatedImages.findIndex(
          (type) => type.type === imageType
        );

        if (imageTypeIndex !== -1) {
          updatedImages[imageTypeIndex].file = file;
          const fileName = `${file.name}`;
          updatedImages[imageTypeIndex].images.push({
            name: fileName,
            data: reader.result,
          });

          setImages(updatedImages);

          // Update corresponding state property
          setTransportData((prevData) => ({
            ...prevData,
            [`${imageType.toLowerCase()}_attach`]: fileName,
          }));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = (imageType, index) => {
    const updatedImages = [...images];
    const imageTypeIndex = updatedImages.findIndex(
      (type) => type.type === imageType
    );
    if (imageTypeIndex !== -1) {
      updatedImages[imageTypeIndex].images.splice(index, 1);
      setImages(updatedImages);
    }
  };

  function handleBack() {
    if (action === "add") {
      navigate("/configuration");
    } else {
      // Navigate to a different location based on your condition
     
      navigate("/viewTransport");
    }
   
  }
  const isLastSection = currentSection === sections[sections.length - 1].name;
  //Personal Details States

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
          // backgroundColor:'pink'
          // backgroundColor: "rgb(44 123 85)",
        }}
      >
        <Typography
          variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%" }}
        >
          <MdPeopleAlt
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp; Transport
        </Typography>
        {/* <InventoryRoundedIcon sx={{ fontSize: 32, marginLeft: "1px" }} />
          </Typography> */}

        <Stack spacing={2} direction="row">
          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
{transportLoading ? (<>  
<div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={transportLoading} />
          </div> </>) : (

          
      <Grid container spacing={3} sx={{ marginTop: "1.7%" }}>
        {/* Left Section (30%) */}
        <Grid item xs={12} sm={3}>
          <Box
            p={2}
            bgcolor="#ffff"
            borderRadius="10px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
          >
            {sections?.map((section) => (
              <Typography
                key={section?.name}
                onClick={() => setCurrentSection(section.name)}
                style={{
                  cursor: "pointer",
                  padding: "8px",

                  background:
                    currentSection === section.name
                      ? "#34519F5D"
                      : "transparent",
                  color: currentSection === section.name ? "#000" : "inherit",
                  borderRadius:
                    currentSection === section.name ? "7px" : "inherit",
                  // padding:currentSection === section.name ? '5px' : 'inherit',
                }}
              >
                {section?.icon}
                &nbsp;&nbsp;
                {section?.name}
              </Typography>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Box
            p={2}
            bgcolor="#ffff"
            borderRadius="10px"
            maxHeight="100%"
            height="100%"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            position="relative"
          >
            {/* Dynamically render the current section */}
            {currentSection === "Transport Details" && (
              <PersonalDetails
                TransportData={TransportData}
                setTransportData={setTransportData}
              />
            )}
            {currentSection === "Documents" && (
              <Documents
                images={images}
                handleCancelImage={handleCancelImage}
                handleImageChange={handleImageChange}
                TransportData={TransportData}
                setEditStatusActive={setEditStatusActive}
                editStatusActive={editStatusActive}
              />
            )}

            <Box
              position="absolute"
              bottom="16px"
              right="20px"
              display="flex"
              gap={2}
            >
              {currentSection !== sections[0].name && (
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: colors.green.DEFAULT,
                  }}
                  onClick={handlePrevious}
                >
                  <Typography variant="h7" color={colors.green.DEFAULT}>
                    Previous
                  </Typography>
                </Button>
              )}
              <Button
                variant="contained"
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                }}
                onClick={isLastSection ? handleSubmit : handleNext}
              >
                <Typography variant="h7" color={colors.white.DEFAULT}>
                  {isLastSection ? "Submit" : "Next"}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      )}
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default TransportConfig;
