// store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../../WMS/reducers/index'; // Import the root reducer

const store = configureStore({
  reducer: rootReducer,
  // Add middleware, enhancers, and other configurations as needed
});

export default store;
