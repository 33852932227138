//POC  OF REPORT MODULE
import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  useTheme,
  Stack,
  Divider,
  Button,
  MenuItem,
  Checkbox,
  Typography,
} from "@mui/material";
import { ScaleLoader } from "react-spinners";
import { tokens } from "../../../theme";

import ApiErrorAlert from "../../../ApiErrorAlert";
import SaveAlert from "../../../SaveAlert";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const Report = ({ columns, selectedkey }) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const title = "Dummy Report";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);
  console.log("name or key whatever is coming here or not ", selectedkey);
  const getDefaultStartDate = () => {
    const lastMonth = new Date();
    lastMonth?.setMonth(lastMonth.getMonth() - 1);
    return lastMonth?.toISOString().split("T")[0];
  };
  const getDefaultEndDate = () => {
    return new Date().toISOString().split("T")[0];
  };
  const initialData = [
    { name: "Prachi", age: 21, country: "USA" },
    { name: "Sahil", age: 23, country: "Canada" },
    { name: "Ayush", age: 23, country: "UK" },
    { name: "Shivan Sir", age: 27, country: "Australia" },
  ];

  // const [data, setData] = useState(initialData);
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState(null);
  const [dorowCount, setdoRowCount] = useState("");
  const [clickedColumns, setClickedColumns] = useState([]);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const [dateError, setDateError] = useState("");
  console.log("data ,columns coming here or not", columns);
  const handleStartDateBlur = () => {
    if (endDate && new Date(startDate) >= new Date(endDate)) {
      setDateError("Start date should be less than end date");
    } else {
      setDateError("");
    }
  };
  const handleEndDateBlur = () => {
    if (startDate && new Date(startDate) >= new Date(endDate)) {
      setDateError("Start date should be less than end date");
    } else {
      setDateError("");
    }
  };
  const handleColumnToggle = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      if (prevSelectedColumns?.includes(column)) {
        return prevSelectedColumns?.filter((col) => col !== column);
      } else {
        return [...prevSelectedColumns, column];
      }
    });
    // Check if any checkbox is selected
    // setIsCheckboxSelected();
    setIsCheckboxSelected(true);
    setClickedColumns((prevClickedColumns) => {
      if (prevClickedColumns?.includes(column)) {
        return prevClickedColumns?.filter(
          (clickedCol) => clickedCol !== column
        );
      } else {
        return [...prevClickedColumns, column];
      }
    });
  };
  const columnsPart1 = columns?.slice(0, 10); // First 10 elements
  const columnsPart2 = columns?.slice(10); // Remaining elements

  const handleSelectAllToggle = () => {
    console.log("state here..............", selectAll);

    console.log("state here.2222222!!!!!...", selectAll);
    setSelectAll((prevSelectAll) => !prevSelectAll);

    if (!selectAll) {
      // Select all columns
      setClickedColumns(columns);
      setSelectedColumns(
        Object.fromEntries(columns?.map((column) => [column, true]))
      );
      setIsCheckboxSelected(true);
    } else {
      // Deselect all columns
      setClickedColumns([]);
      setSelectedColumns(
        Object.fromEntries(columns?.map((column) => [column, false]))
      );
      setIsCheckboxSelected(false);
    }
    setSelectAll(!selectAll);

    console.log("state here!!!!!!!.", selectAll);
    console.log("gvbhnjmk", selectedColumns);
  };
  console.log("state here098765.", selectAll);
  useEffect(() => {
    handleSelectAllToggle();
  }, [columns]);
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split("T")[0];

  // Function to toggle the "Select All" checkbox
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns([]); // Deselect all columns
    } else {
      setSelectedColumns(["name", "age", "country"]); // Select all columns
    }
    setSelectAll(!selectAll); // Toggle select all state
  };

  const toggleColumnSelection = (columnName) => {
    if (selectedColumns.includes(columnName)) {
      setSelectedColumns(selectedColumns?.filter((col) => col !== columnName));
    } else {
      setSelectedColumns([...selectedColumns, columnName]);
    }
  };

  const downloadCSV = () => {
    const csvData = [
      selectedColumns.join(","), // Header row with selected columns
      ...data?.map((row) => selectedColumns?.map((col) => row[col])?.join(",")), // Data rows with selected columns
    ].join("\n"); // Join rows with newline characters

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };
  const downloadExcel = () => {
    const sheetData = data.map((row) => selectedColumns.map((col) => row[col]));

    const worksheet = XLSX.utils.aoa_to_sheet([selectedColumns, ...sheetData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, `${title}.xlsx`);
  };
  const downloadPDF = () => {
    const columns = selectedColumns;
    const rows = data.map((row) => selectedColumns.map((col) => row[col]));

    const doc = new jsPDF();
    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save(`${title}.pdf`);
  };
  const handleDownload = (format) => {
    setSelectedDownloadFormat(format);
  };

  const handleSubmit = async () => {
    // Send selected columns to the backend
    const requestData = {
      name: selectedkey,
      columns: clickedColumns,
      start_date: startDate,
      end_date: endDate,
    };
    setLoading(true);
    // try {
    //   const response = await fetch('/api/download', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ selectedColumns }),
    //   });

    //   if (response.ok) {
    // Handle successful response from the backend
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_REPORT_MODULE_SELECT_COLUMNDATA,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        switch (selectedDownloadFormat) {
          case "csv":
            // Handle CSV download
            downloadCSV();
            break;
          case "excel":
            // Handle Excel download
            downloadExcel();
            break;
          case "pdf":
            // Handle PDF download
            downloadPDF();
            break;
          default:
            // Handle other formats if needed
            break;
        }
      } else {
        // Handle error response
        console.log("Error fetching data from the server");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            zIndex: "1",
          }}
        >
          <ScaleLoader color="#2C7B55" />
        </div>
      ) : (
        <>
          <Grid
            sx={{
              marginTop: "1.5%",
              height: "480px",
              marginLeft: "1%",
              backgroundColor: "#ecf4f0",
              boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: 6,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <div style={{ marginTop: "2%", marginLeft: "40px" }}>
                <Stack direction="row" spacing={2}>
                  <Grid>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "-10%",
                        marginRight: "4px",
                      }}
                    >
                      <Checkbox
                        style={{ marginRight: "-18px" }}
                        checked={selectAll}
                        onChange={handleSelectAllToggle}
                      />
                    </div>
                  </Grid>
                  <TextField
                    label="Start Date"
                    type="date"
                    size="small"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: formattedSixMonthsAgo,
                        max: new Date().toISOString().split("T")[0],
                      },
                    }}
                    onBlur={handleStartDateBlur}
                    sx={{ width: 200, borderRadius: 30 }}
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    size="small"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 200, borderRadius: 30 }}
                    onBlur={handleEndDateBlur}
                    InputProps={{
                      inputProps: {
                        max: new Date().toISOString().split("T")[0],
                        min: startDate,
                      },
                    }}
                  />
                </Stack>
                {dateError && (
                  <Typography color="error">{dateError}</Typography>
                )}
              </div>
              <div style={{ marginTop: "2%", marginRight: "84px" }}>
                <label>Select Download Format:</label>
                <select
                  onChange={(e) => handleDownload(e.target.value)}
                  disabled={loading}
                >
                  <option value="">Select Format</option>
                  <option value="csv">CSV</option>
                  <option value="excel">Excel</option>
                  <option value="pdf">PDF</option>
                </select>
              </div>
            </Stack>

            <Stack direction="row" style={{ marginTop: "20px" }}>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "4.2%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "2%" }}
                >
                  Column
                </Typography>
                {columnsPart1?.map((column) => (
                  <div
                    key={column.value}
                    style={{
                      marginLeft: "-6%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={selectAll ? true : selectedColumns[column]}
                      onChange={() => handleColumnToggle(column)}
                      color="primary"
                    />
                    <Typography variant="h5">{column}</Typography>
                  </div>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "14.7%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "20%" }}
                >
                  Status
                </Typography>

                {columnsPart1?.map((column, index) => (
                  <Typography
                    key={column.value}
                    variant="h5"
                    color={colors.black[200]}
                    style={{
                      marginBottom: index !== columns.length - 1 ? "20px" : "0",
                    }}
                  >
                    Active
                  </Typography>
                ))}
              </Grid>
              <Divider
                variant="middle"
                orientation="vertical"
                flexItem
                sx={{ backgroundColor: "#000000", marginLeft: "12%" }}
              />
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "7.3%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "2%" }}
                >
                  Column
                </Typography>
                {columnsPart2?.map((column) => (
                  <div
                    key={column?.value}
                    style={{
                      marginLeft: "-6%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={selectAll ? true : selectedColumns[column]}
                      onChange={() => handleColumnToggle(column)}
                      color="primary"
                    />
                    <Typography variant="h5">{column}</Typography>
                  </div>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                sx={{ marginLeft: "18.7%" }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "20%" }}
                >
                  Status
                </Typography>

                {columnsPart2?.map((column, index) => (
                  <Typography
                    key={column?.value}
                    variant="h5"
                    color={colors.black[200]}
                    style={{
                      marginBottom: index !== columns.length ? "20px" : "0",
                    }}
                  >
                    Active
                  </Typography>
                ))}
              </Grid>
            </Stack>
          </Grid>

          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end", // Align items to the extreme right
              marginTop: "-3%",
              marginRight: "7%",
              marginLeft: "auto",

              // Semi-transparent background
            }}
          >
            {isCheckboxSelected && selectedDownloadFormat && (
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: colors.green.DEFAULT,
                  alignSelf: "center", // Center the button horizontally
                }}
                onClick={handleSubmit}
              >
                <Typography variant="h5" color="#ffffff">
                  Submit
                </Typography>
              </Button>
            )}
          </Grid>
        </>
      )}
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};

export default Report;
