import React, { useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import { Typography, useTheme, Divider } from "@mui/material";
import { tokens } from "../WMS/WMStheme";
import { MdOutlineComputer } from "react-icons/md";
import { MdOutlinePhonelinkSetup } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Stack,
  DialogContent,
  IconButton,
} from "@mui/material";
const CDMainPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const handleWMSUserClick = () => {
    // Navigate to the WMS User's page
    navigate("/wmsuser");

   
  };
  const handleOMSUserClick = () => {
    // Navigate to the WMS User's page
    navigate("/omsuser");

   
  };
  return (
    <div 
    style={{
      display: 'flex',
      flexDirection: 'column',
      // backgroundColor:'red',
      // Center the content horizontally
      justifyContent: 'center', // Center the content vertically
      height: '45vh', // Make the container take up the full height of the viewport
    }}>
    <div
    className="box-content"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems:'center',
      height: "100%",
      // backgroundColor:'green',
      marginTop: "0%",
    }}
  >
    <Typography
      variant="h1"
      color={colors.green.DEFAULT}
      
    >
      CONFIGURATION
    </Typography>
  </div>
  <div className="row" style={{ marginLeft: "20%", marginRight: "1%" }}>
  <div
    className="col-lg-4 col-md-4 col-sm-12 mb-4"
    style={{ marginBottom: "10rem" ,marginLeft: "2%", marginRight: "2%"}}
  >
    <Card
      style={{
        width: "100%",
        boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
        // borderRadius: "8%",
        overflow: "hidden",
        backgroundColor: "#ffffff",
      }}
      onClick={handleWMSUserClick}
    >
      <Card.Body className="d-flex flex-column justify-content-center">
        <Card.Title
          className="text-center"
          style={{ alignItems: "center" }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.8}
          >
          <MdOutlineComputer
          style={{ width: "100px", height: "59px", color: "#34519fe6" }}
        />
         
           
          </Stack>

          <Divider
            sx={{
              my: 1.1,
              backgroundColor: "#0B0B0B",
              color: "#080808",
              zIndex: "9999",
              borderBottom: "1px solid #000000",
            }}
          />
        </Card.Title>
        <div className="d-flex justify-content-center">
        <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" }}
                      >
                        WMS USER'S
                      </Typography>   </div>
      </Card.Body>
    </Card>
  </div>
  <div
    className="col-lg-4 col-md-4 col-sm-12 mb-4"
    style={{ marginBottom: "10rem" ,marginLeft: "2%", marginRight: "2%"}}
  >
    <Card
      style={{
        width: "100%",
        boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
        // borderRadius: "8%",
        overflow: "hidden",
        backgroundColor: "#ffffff",
      }}
      onClick={handleOMSUserClick}
    >
      <Card.Body className="d-flex flex-column justify-content-center">
        <Card.Title
          className="text-center"
          style={{ alignItems: "center" }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.8}
          >
          <MdOutlinePhonelinkSetup
          style={{ width: "100px", height: "59px", color: "#34519fe6" }}
        />
         
           
          </Stack>

          <Divider
            sx={{
              my: 1.1,
              backgroundColor: "#0B0B0B",
              color: "#080808",
              zIndex: "9999",
              borderBottom: "1px solid #000000",
            }}
          />
        </Card.Title>
        <div className="d-flex justify-content-center">
        <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" }}
                      >
                       OMS USER'S
                      </Typography>   </div>
      </Card.Body>
    </Card>
  </div>
 
</div>
</div>
  );
}

export default CDMainPage