import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Box,
  Select,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { IconButton, Grid } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { OutlinedInput } from "@mui/material";
import axios from "axios";
import { capitalize } from "lodash";
import Sensor from "./Sensor";
import ControlBox from "./ControlBox";
import FertigationControl from "./FertigationControl";
import IrrigationControl from "./IrrigationControl";
import { tokens } from "../../../theme";
import Divider from "@mui/material/Divider";

import RecipeActions from "./RecipeActions";
import RecipeSetPoints from "./RecipeSetPoints";
import ApiErrorAlert from "../../../ApiErrorAlert";
import SaveAlert from "../../../SaveAlert";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const RecipeDashboard = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;

  const [value, setValue] = useState(0);
  const [selectedFarm, setSelectedFarm] = useState("");
  const [selectedFarmId, setSelectedFarmId] = useState("");
  const [farmNames, setFarmNames] = useState([]);
  const [farmObject, setFarmObject] = useState([]);

  const [iotData, setIotData] = useState([]);
  const [prevSetPoints, setPrevSetPoints] = useState([]);
  const [filteredSetPoints, setFilteredSetPoints] = useState([]);

  const [isSetPointsChanged, setIsSetPointsChanged] = useState(false);

  const handleFetchNewSetPoints = () => {
    setIsSetPointsChanged(true);
  };

  useEffect(() => {
    fetchFarmNames();
    fetchIOTData();
    fetchSetPoints();
  }, []);

  useEffect(() => {
    fetchSetPoints();
  }, [isSetPointsChanged]);

  useEffect(() => {
    // console.log("inside dashboard use effect");
    // console.log(
    //   "filteredSetPoints in dashboard use effect file",
    //   filteredSetPoints
    // );

    if (farmNames && farmNames.length !== 0) {
      setSelectedFarm(farmNames[0]);
      const selectedFarm = farmNames[0];
      const farmId = farmObject?.find(
        (farm) => farm?.farm_name === selectedFarm
      );
      setSelectedFarmId(farmId);
      if (prevSetPoints) {
        // console.log("inside second if in useEffect");
        const filteredSetPoints = prevSetPoints?.find(
          (setPoint) => setPoint?.farm_id === farmId
        );
        setFilteredSetPoints(filteredSetPoints);
      }
    }
  }, [farmNames, prevSetPoints]);

  const fetchFarmNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const fetchedFarmNames = data?.farmdetail?.rows
        ?.map((row) => row?.farm_name)
        .flat();
      const fetchedFarmObject = data?.farmdetail?.rows
        ?.map((row) => row)
        .flat();
      setFarmNames(fetchedFarmNames);
      setFarmObject(fetchedFarmObject);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching farm names:", error);
    }
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedIndex = farmNames?.findIndex((tab) => tab === selectedValue);

    if (selectedIndex !== -1) {
      setValue(selectedIndex);
      const selectedFarmName = farmNames[selectedIndex] || "";
      setSelectedFarm(selectedFarmName);
      const farmId = farmObject?.find(
        (farm) => farm?.farm_name === selectedFarmName
      );
      setSelectedFarmId(farmId);
    }
  };

  const handleFarmSelect = (selectedFarmName) => {
    setSelectedFarm(selectedFarmName);
    const farmId = farmObject?.find(
      (farm) => farm?.farm_name === selectedFarmName
    );
    setSelectedFarmId(farmId);
    const selectedIndex = farmNames?.findIndex(
      (farmName) => farmName === selectedFarmName
    );

    setValue(selectedIndex);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedFarmName = farmNames[newValue] || "";

    setSelectedFarm(selectedFarmName);
    const farmId = farmObject?.find(
      (farm) => farm?.farm_name === selectedFarmName
    );
    setSelectedFarmId(farmId);
  };

  const formatFarmName = (farmName) => {
    const words = farmName?.split(" ");
    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return formattedWords.join(" ");
  };

  const fetchIOTData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_FERTIGATION_IOTDATA,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("responseeeeee from IOT function", response);
      const data = await response?.data;
      // console.log("Dataaaaaaaaaa from IOT function", data);
      setIotData(data);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching IOT Data:", error);
    }
  };

  // console.log("IOTDATAAAAAAAAAAAA", iotData);

  const fetchSetPoints = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_FERTIGATION_SETPOINTS,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("responseeeeee from Get Setpoints function", response);
      const data = await response?.data;
      // console.log("Dataaaaaaaaaa from Get Setpoints function", data[0]?.rows);
      setPrevSetPoints(data[0]?.rows);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching get Setpoints Data:", error);
    }
  };

  // console.log("prevSetPoints in dashboard file", prevSetPoints);
  // console.log("filteredSetPoints in dashboard file", filteredSetPoints);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "red",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1,
            // backgroundColor: "green",
          }}
        >
          <Box sx={{ flex: 1, overflowX: "auto" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable tabs"
              sx={{ width: "100%" }}
              indicatorColor="#b5cbbf"
            >
              {farmNames?.map((farmName, index) => (
                <Tab
                  key={index}
                  label={
                    <Typography
                      variant="h5"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {formatFarmName(farmName)}
                    </Typography>
                  }
                  sx={{
                    "&.Mui-selected": {
                      color: "#2C7B55",
                      backgroundColor: "#b5cbbf",
                      borderRadius: "6px",
                      transition: "background-color 0.3s ease", // Add transition here
                    },
                  }}
                  onClick={() => handleFarmSelect(farmName)}
                />
              ))}
              <Tab
                key="placeholder"
                disabled
                label={
                  <Typography
                    variant="h5"
                    color={colors.black[100]}
                    fontFamily="Lexend Deca"
                  >
                    Add farms
                  </Typography>
                }
                sx={{
                  // Styles for the placeholder tab
                  color: colors.grey.DEFAULT,
                  borderRadius: "6px",
                }}
              />
            </Tabs>
          </Box>

          {/* <Select
          value={selectedFarm}
          onChange={handleSelectChange}
          displayEmpty
          size="small"
          input={<OutlinedInput />}
          sx={{
            marginRight: "33px",
            fontSize: "10px", // Adjust the font size as desired
            padding: "2px", // Adjust the padding as desired
          }}
        >
          <MenuItem value="" disabled>
            Select Farm
          </MenuItem>
          {farmNames?.map((farmName) => (
            <MenuItem key={farmName} value={farmName}>
              <Typography
                variant="h6"
                color={colors.black[200]}
                fontFamily="Lexend Deca"
              >
                {farmName}
              </Typography>
            </MenuItem>
          ))}
        </Select> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            // backgroundColor: "green",
            p: 1,
            marginTop: "15px",
          }}
        >
          <Box
            sx={{
              // backgroundColor: "green",
              width: "33%",
            }}
          >
            <RecipeSetPoints
              iotData={iotData}
              farmObject={selectedFarmId}
              prevSetPoints={prevSetPoints}
            />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              width: 2,
              height: "80%",
              backgroundColor: "gray",
              marginTop: "40px",
            }}
          />
          <Box
            sx={{
              // backgroundColor: "green",
              width: "33%",
            }}
          >
            <RecipeActions
              farmObject={selectedFarmId}
              onChange={handleFetchNewSetPoints}
            />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              width: 2,
              height: "80%",
              backgroundColor: "gray",
              marginTop: "40px",
            }}
          />
          <Box
            sx={{
              // backgroundColor: "green",
              width: "33%",
            }}
          >
            <Sensor farmObject={selectedFarmId} />
          </Box>
        </Box>
      </Box>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};
export default RecipeDashboard;
