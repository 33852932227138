import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  useTheme,
  CardHeader,
  Grid,
  Avatar,
} from "@mui/material";
import Ec from "./Ec";
import Ph from "./Ph";
import { tokens } from "../../../theme";
import "./Sensor.css";

let activeSocket = null;
const token = localStorage.getItem("token");

const Sensor = ({ farmObject }) => {
  const farmName = farmObject?.farm_name;
  const farmId = farmObject?.farm_id;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [payloadData, setPayloadData] = useState({});

  useEffect(() => {
    console.log("Use Effectttttttttttttttttt farm id", farmId);

    setPayloadData({});
    const socketEndPoint = process.env.REACT_APP_SOCKET_ENDPOINT;
    if (activeSocket) {
      console.log("Closing previous WebSocket connection");
      activeSocket.close();
    }
    const socket = new WebSocket(socketEndPoint);
    activeSocket = socket;
    socket.onopen = () => {
      console.log("WebSocket connection is open");
    };
    socket.onmessage = (event) => {
      console.log("New messageeeeeeeeeeeeeee", event);
      try {
        const message = JSON.parse(event?.data);
        if (typeof message === "object" && message !== null) {
          if (farmId !== undefined) {
            console.log("farmId", farmId);
            if (message?.farm_id === farmId) {
              setPayloadData(message);
            } else {
              console.log("Condition is", message?.farm_id === farmId);
            }
          } else {
            console.log("Id is undefined, cannot compare");
          }
        } else {
          console.log("Received message is not a valid JSON object");
        }
      } catch (error) {
        console.log("Error parsing JSON:", error);
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection is closed");
    };

    return () => {
      if (activeSocket) {
        console.log("Closing WebSocket connection on unmount");
        activeSocket.close();
        activeSocket = null;
      }
    };
  }, [farmId]);

  console.log("PAYLOAD DATAAAAAAAA", payloadData);

  const devicesData = [
    { id: 1, name: "Ph", sensorKey: "ph", value: "7" },
    { id: 2, name: "Ec", sensorKey: "ec", value: "2.7" },
  ];

  return farmName ? (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item sx={{ marginTop: "-83px" }}>
          <Avatar
            sx={{
              backgroundColor: "#2c7B55",
              width: 20,
              height: 20,
            }}
          >
            <Typography
              variant="h7"
              color={colors.white.DEFAULT}
              fontFamily="Lexend Deca"
            >
              3
            </Typography>
          </Avatar>
        </Grid>
        <Grid item sx={{ marginTop: "-83px" }}>
          <Typography
            variant="h3"
            color={colors.black[200]}
            fontFamily="Lexend Deca"
          >
            Sensor Values (LIVE)
          </Typography>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          marginLeft: "50px",
          flexDirection: "row",
          height: "150px",
          width: "300px",
        }}
      >
        {devicesData.map((device) => (
          <Card
            key={device.id}
            sx={{
              flex: "1",
              width: "50%",
            }}
          >
            <CardHeader
              avatar={
                <Typography
                  variant="h4"
                  color={colors.black[200]}
                  fontFamily="Lexend Deca"
                >
                  {device.name}
                </Typography>
              }
            />
            <CardContent>
              {device?.name == "Ph" && (
                <Ph phValue={payloadData?.live_ph || "0"} />
              )}
              {device?.name == "Ec" && (
                <Ec ecValue={payloadData?.live_ec || "0"} />
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  ) : (
    <>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h3"
          color={colors.black[100]}
          fontFamily="Lexend Deca"
        >
          No sensor data available
        </Typography>
      </div>
    </>
  );
};

export default Sensor;
