import React, { useState,useEffect }  from 'react';
import {FaWarehouse} from "react-icons/fa6";
import {tokens} from "../WMStheme";
import {useTheme} from "@mui/material/styles";
import { API_ENDPOINTS } from '../../ApiEndpoint/ApiEndpoint';
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Dialog,Divider,
    DialogTitle,
    DialogContent,
    Grid,IconButton,
    Box,Switch,
    Typography,
    Button,
  } from "@mui/material";
const UserAccessEditPage = ({rowUserData ,setIsDialogOpen ,count,setCount}) => {
    console.log("user id in access page ",rowUserData?.user_id);
    const axiosInstance = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
      });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const typography = tokens(theme.palette.mode.typography);
    const [initialUserAccessData, setInitialUserAccessData] = useState({});

    const [loading, setLoading] = useState(false);
    const [currentSection, setCurrentSection] = useState("Home Module");
    const [userAccessData,setUserAccessData] =useState([]);
    const [toggledSwitches, setToggledSwitches] = useState([]);
    const [activeNextButton, setActiveNextButton] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
     const [changes, setChanges] = useState([]);
    // Define keys and labels for each section
    const sections = {
        "Home Module": [
            { key: "m_home", label: "Home Module", icon: <FaWarehouse /> },
            
        ],
        "Configuration": [
            { key: "m_config", label: "Config Module", icon: <FaWarehouse /> },
            { key: "b_config_view_sku", label: "View SKU", icon: <FaWarehouse /> },
            { key: "b_config_add_sku", label: "Add SKU", icon: <FaWarehouse /> },
            { key: "b_config_edit_sku", label: "Edit SKU", icon: <FaWarehouse /> },
            { key: "b_config_view_product", label: "View Product", icon: <FaWarehouse /> },
            { key: "b_config_edit_product", label: "Edit Product", icon: <FaWarehouse /> },
            { key: "b_config_add_product", label: "Add Product", icon: <FaWarehouse /> },
            { key: "b_config_view_family", label: "View Family", icon: <FaWarehouse /> },
            { key: "b_config_edit_family", label: "Edit Family", icon: <FaWarehouse /> },
            { key: "b_config_add_family", label: "Add Family", icon: <FaWarehouse /> },
            { key: "b_config_view_vendor", label: "View Vendor", icon: <FaWarehouse /> },
            { key: "b_config_edit_vendor", label: "Edit Vendor ", icon: <FaWarehouse /> },
            { key: "b_config_add_vendor", label: "Add Vendor ", icon: <FaWarehouse /> },
            { key: "b_config_view_customer", label: "View Customer", icon: <FaWarehouse /> },
            { key: "b_config_edit_customer", label: "Edit Customer", icon: <FaWarehouse /> },
            { key: "b_config_add_customer", label: "Add Customer", icon: <FaWarehouse /> },
            { key: "b_config_view_customergroup", label: "View Customer Group ", icon: <FaWarehouse /> },
            { key: "b_config_edit_customergroup", label: "Edit Customer Group ", icon: <FaWarehouse /> },
            { key: "b_config_add_customergroup", label: "Add Customer Group ", icon: <FaWarehouse /> },
            { key: "b_config_view_transport", label: "View Transport", icon: <FaWarehouse /> },
            { key: "b_config_edit_transport", label: "Edit Transport ", icon: <FaWarehouse /> },
            { key: "b_config_add_transport", label: "Add Transport", icon: <FaWarehouse /> },
            // Add more keys, labels, and icons for Configuration section
        ],
        "Purchase": [
            { key: "m_purchase_order", label: "Purchase Module", icon: <FaWarehouse /> },
            { key: "b_purchase_order_create_po", label: "Create PO", icon: <FaWarehouse /> },
            { key: "b_purchase_order_send_to_zoho", label: "Sent To Zoho", icon: <FaWarehouse /> },
            { key: "b_po_receiving_status", label: "Receiving Status", icon: <FaWarehouse /> },
            { key: "b_po_rate", label: "PO Rate", icon: <FaWarehouse /> },
            { key: "b_po_receiving_qty", label: "Receiving Qty Edit", icon: <FaWarehouse /> },
            { key: "b_po_rtv_qty", label: "Rtv Qty Edit", icon: <FaWarehouse /> },
            

            
        ],
        "Orders": [
            { key: "m_orders", label: "Orders Module", icon: <FaWarehouse /> },
            { key: "b_orders_create_order", label: "Create Order", icon: <FaWarehouse /> },
            
        ],
        "Inventory": [
            { key: "m_inventory", label: "Inventory Module", icon: <FaWarehouse /> },
            { key: "b_inventory_add_product_inventory", label: "Product Inventory", icon: <FaWarehouse /> },
           
        ],
        "Cycle Count": [
            { key: "m_cycle_count", label: "Cycle Count Module", icon: <FaWarehouse /> },
            { key: "b_cycle_count_perform_cycle_count", label: "Perform Cycle Count", icon: <FaWarehouse /> },
           
            
        ],
        "Transfer": [
            { key: "m_stock_transfer", label: "Stock Transfer", icon: <FaWarehouse /> },
            { key: "b_stock_transfer_perform_stock_transfer", label: "Perform Stock Transfer", icon: <FaWarehouse /> },
           
        ],
        "Reports": [
            { key: "m_reports", label: "Reports Module", icon: <FaWarehouse /> },
           
            
        ],
        "P & L Analysis": [
            { key: "m_profit_n_loss", label: "Profit & Loss Analysis Module", icon: <FaWarehouse /> },
            
            
        ],
        "Users": [
            { key: "m_user_management", label: "User Module", icon: <FaWarehouse /> },
            { key: "b_users_management_edit", label: "User Module Edit", icon: <FaWarehouse /> },
            
        ],
        "Integration": [
            { key: "m_integration", label: "Integration Module", icon: <FaWarehouse /> },
            
            
        ],
        // Add keys, labels, and icons for other sections as needed
    };
    axiosInstance.interceptors.request.use(
        async (config) => {
          const token = localStorage.getItem("token");
      
          if (token) {
            const currentTime = Date.now();
            const exp = localStorage.getItem("exp");
      
            // Check if the token is still valid
            if (currentTime <= exp) {
              console.log("comapring the current time and exp time  ");
              config.headers.Authorization = `Bearer ${token}`;
              return config;
            } else {
              // Token has expired, redirect to login page
              localStorage.removeItem("token");
              localStorage.removeItem("name");
              localStorage.removeItem("user-id");
              localStorage.removeItem("exp");
              localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
              // Optionally, you can redirect to the login page programmatically
              navigate("/");
            }
          }
      
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      
      
    // Get the keys of the sections object
const sectionKeys = Object.keys(sections);
useEffect(() => {
    fetchAccessData();
  }, []);
const fetchAccessData = async () => {
   
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_USER_ACESS_DATA(rowUserData?.user_id)}`,
        {
          params: {
            limit: 1000,
            offset: 1,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = response.data?.data?.user_acess_details;
        setUserAccessData(data[0]);
        // Assuming the response is an array of family options
      } else {
        console.log("Error fetching family data");
      }
    } catch (error) {
      console.error("Error fetching family data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleBack=() => {
    setIsDialogOpen(false);
  };
// Determine if the current section is the last section
const isLastSection = currentSection === sectionKeys[sectionKeys.length - 1];
console.log("userAccessData:", userAccessData);
useEffect(() => {
    // Initialize toggledSwitches with keys from userAccessData that are initially true
    const initialToggledSwitches = Object.keys(userAccessData)?.filter(key => userAccessData[key]);
    setToggledSwitches(initialToggledSwitches);
    // Store the initial user access data
    setInitialUserAccessData(userAccessData);
}, [userAccessData,currentSection ]);

 //const isLastSection = currentSection === sections[sections.length - 1].key;
    // Initialize state for storing key values
    const [keyValues, setKeyValues] = useState({});
    // Function to handle toggle switch change
    const handleToggleChange = (key) => {
        setToggledSwitches(prevState => {
            if (prevState.includes(key)) {
                return prevState.filter(item => item !== key);
            } else {
                return [...prevState, key];
            }
        });
        
        }
        useEffect(() => {
            // Update changes state based on toggle changes
            const updatedChanges = {};
        
            sections[currentSection].forEach((item) => {
              updatedChanges[item.key] = toggledSwitches.includes(item.key);
            });
        
           

            
            // Update userAccessData state
           
            setChanges(updatedChanges);
        }, [toggledSwitches, currentSection]);
        
        
        
    const handleSave =() => {
        
        console.log("Changes to be saved:", changes);
        // Create a copy of userAccessData
  const updatedUserAccessData = { ...userAccessData };

  // Update userAccessData with changes
  Object.keys(changes).forEach(key => {
    updatedUserAccessData[key] = changes[key];
  });

  // Update userAccessData state
  UpdateAccessData(updatedUserAccessData);
  console.log("Changes to be saved:", updatedUserAccessData);
  
    };
    const UpdateAccessData = async (updatedUserAccessData) => {
   
        try {
            const endpoint = `${API_ENDPOINTS.PUT_WMS_USER_ACESS_UPDATE_DATA(rowUserData?.user_id)}`;
            console.log("API Endpoint:", endpoint);
      
            
            setLoading(true);
            const response = await axiosInstance.put(endpoint,updatedUserAccessData , {
              headers: {
                "Content-Type": "application/json",
              },
            });
          
    
          if (response.status >= 200 && response.status < 305) {
            
            setSuccessMessage("Data updated Successfully!");
            setCount((prevcount) => prevcount + 1);
            setIsDialogOpen(false);

            setSuccessPopupOpen(true);
            // Assuming the response is an array of family options
          } else {
            console.log("Error fetching family data");
          }
        } catch (error) {
          console.error("Error fetching family data:", error);
        } finally {
          setLoading(false);
        }
      }; 
    
  return (
   <>
   <Dialog  open={true} fullScreen sx={{ borderRadius: "20px" }}>
   <DialogTitle id="form-dialog-title">
   <Grid
   container
   alignItems="flex-start"
   justifyContent="space-between"
 >
   <Grid item marginTop={"6px"}>
     <Typography
       variant="h2"
       color={colors.green.DEFAULT}
       fontWeight={typography.h1}
       fontFamily="Lexend Deca"
     >
       {rowUserData.name} : {rowUserData.role}
     </Typography>
   </Grid>
   <Grid sx={{ marginTop: "1%" }}>
   <Button
   variant="outlined"
   startIcon={
     <ArrowBackIcon
       style={{ color: colors.green.DEFAULT, fontSize: 26 }}
     />
   }
   sx={{
     borderColor: colors.green.DEFAULT,
     color: colors.green.DEFAULT,
   }}
   onClick={handleBack}
 >
   <Typography variant="h7" color={colors.green.DEFAULT}>
     Back
   </Typography>
 </Button>
   </Grid>
   </Grid>

   </DialogTitle>
<DialogContent>
<Grid container spacing={3} sx={{ marginTop: "0%" }}>
          {/* Left Section (30%) */}
          <Grid item xs={12} sm={3}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            >
             
            {Object.keys(sections).map((sectionName) => (
                <Typography
                    key={sectionName}
                    onClick={() => setCurrentSection(sectionName)}
                    style={{
                        cursor: "pointer",
                        padding: "8px",
                        background: currentSection === sectionName ? "#34519F5D" : "transparent",
                        color: currentSection === sectionName ? "#000" : "inherit",
                        borderRadius: currentSection === sectionName ? "7px" : "inherit",
                    }}
                >
                    {sections[sectionName][0]?.icon}
                    &nbsp;&nbsp;
                    {sectionName}
                </Typography>
            ))}
            
            </Box>
          
            
          </Grid> 
          <Grid item xs={12} sm={9}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              maxHeight="100%"
              height="100%"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
              position="relative"
            >
           
            <Grid container spacing={1}>
            {sections[currentSection].map((item, index) => (
                <React.Fragment key={index}>
                    <Grid item xs={12} sm={4} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                            <Typography>{item.label}</Typography>
                            {currentSection === "Home Module" ? (
                                <Switch
                                    checked={true}
                                    disabled // Disable the toggle button for the first section
                                    color="primary"
                                />
                            ) : (
                                <Switch
                                    checked={toggledSwitches.includes(item.key)}
                                    onChange={() => handleToggleChange(item.key)}
                                    color="primary"
                                />
                            )}
<Divider  
variant="middle"
orientation="vertical"
flexItem
sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
/>
                        </Box>
                    </Grid>
                   
                </React.Fragment>
            ))}
        </Grid>
        

        
              <Box
                position="absolute"
                bottom="16px"
                right="20px"
                display="flex"
                gap={2}
              >

              <Button
              variant="contained"
              sx={{
                borderColor: colors.green.DEFAULT,
                backgroundColor: colors.green.DEFAULT,
                width: "100%",
              }}
              onClick={handleSave}
            >
              <Typography variant="h7" color={colors.white.DEFAULT}>
                 Save Changes
              </Typography>
            </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
</DialogContent>
   </Dialog>
   <SuccessPopUp
   open={successPopupOpen}
   onClose={() => setSuccessPopupOpen(false)}
   message={successMessage}
 />
   </>
  )
}

export default UserAccessEditPage