// reducers/index.js
import { combineReducers } from 'redux';
import {customerReducer} from  './customerReducer';
import {  poReducer }from './poReducer'; // Import the PO reducer
import { warehouseReducer } from './warehouseReducer';
import { skuReducer } from './skuReducer';
import { transportReducer } from './transportReducer';
import { vendorReducer } from './vendorReducer';
import {soReducer} from './soReducer';
import { productReducer } from './productReducer';
import { familyReducer } from './familyReducer';
import { customerGroupReducer } from './customerGroupReducer';
import {soupdatereducer} from './soUpdateReducer';
const rootReducer = combineReducers({
 
  po: poReducer,
  warehouse: warehouseReducer,
  sku:skuReducer,
  transport:transportReducer,
  so: soReducer,
  customer: customerReducer,
  vendor: vendorReducer,
  product: productReducer,
  family: familyReducer,
  customerGroup : customerGroupReducer,
  soupdatereducer: soupdatereducer,
  // Add other reducers here if needed
});

export default rootReducer;
