import React, { useState, useEffect, useRef, useMemo } from 'react'
import NullScreen from "../PopUpMsg/NullScreen";
import {
    Grid,
    Button,
    Select,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    TextField,
    ThemeProvider,
    MenuItem,
    createTheme,
    IconButton,
    InputLabel,
    FormControl,
    Typography,
    InputAdornment,
    useTheme,
  } from "@mui/material";
  import axios from "axios";
  import moment from "moment";
import { ScaleLoader } from "react-spinners";
import { MaterialReactTable } from "material-react-table";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import { useNavigate } from "react-router-dom";
import { tokens } from "../WMStheme";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
  });
const STOMiddleWareDialog = (stoId) => {
    axiosInstance.interceptors.request.use(
        async (config) => {
          const token = localStorage.getItem("token");
      
          if (token) {
            const currentTime = Date.now();
            const exp = localStorage.getItem("exp");
      
            // Check if the token is still valid
            if (currentTime <= exp) {
              console.log("comapring the current time and exp time  ");
              config.headers.Authorization = `Bearer ${token}`;
              return config;
            } else {
              // Token has expired, redirect to login page
              localStorage.removeItem("token");
              localStorage.removeItem("name");
              localStorage.removeItem("user-id");
              localStorage.removeItem("exp");
              localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
              // Optionally, you can redirect to the login page programmatically
              navigate("/");
            }
          }
      
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    const gridContainerStyle = {
        marginTop:"-4%",
       //   display: "flex",
       height: "74%",
       width: "100%",
        overflowY: "hidden",
       //  backgroundColor: "red",
     };
     const containerStyle8 = {
       // maxHeight: "70vh",
       // height: "80%",
    //    display: "flex",
       overflowY: "auto",
       overflowX: "hidden",
       //  backgroundColor: "pink",
     };
     const loaderContainerStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        width: "100%",
        overflowY: "hidden",
        // backgroundColor: "red",
      };
    const [inputValues, setInputValues] = useState({});
    const [selectedRows, setSelectedRows] = useState({});
    const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
    const [sTOData, setSTOData] = useState([]);
    const [addedSKUMasterData, setAddedSKUMasterData] = useState([]);
    const [stoLoading ,setStoLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isdailogLoading, setIsDialogLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [selectedSTOData, setSelectedSTOData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const globalTheme = useTheme();

    const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
            primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
            info: {
              main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
            },
            background: {
              default:
                globalTheme.palette.mode === "light"
                  ? "#ffff" //random light yellow color for the background in light mode
                  : "#000", //pure black table in dark mode for fun
            },
          },
          typography: {
            button: {
              variant: "contained", //customize typography styles for all buttons in table by default
              fontSize: "0.8rem",
              color: "#0A0A0A",
            },
            fontFamily: "Lexend Deca", // Add your desired font family here
            fontWeightRegular: 400, // Set the desired font weight for regular entries
            fontWeightBold: 700, // Set the desired font weight for bold entries
            fontSize: 12,
          },
          components: {
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  fontSize: "0.8rem", //override to make tooltip font size larger
                },
              },
            },
            MuiSwitch: {
              styleOverrides: {
                thumb: {
                  color: "#040404", //change the color of the switch thumb in the columns show/hide menu to pink
                },
              },
            },
          },
        }),
      [globalTheme]
    );
    const columns = useMemo(
        () => [
          
          {
            header: "Item",
            accessorKey: "sku_name",
            size: 10,
            enableEditing: false,
          },
          {
            header: "Code",
            accessorKey: "sku_code",
            size: 10,
            enableEditing: false,
          },
          {
            header: "Lot Number",
            accessorKey: "lot_no",
            size: 10,
            enableEditing: false,
          },
          {
            header: "Type",
            accessorKey: "type",
            size: 10,
            enableEditing: false,
          },
    
          {
            header: "Available Qty",
            accessorKey: "avl_qty",
            size: 10,
            enableEditing: false,
          },
          {
            header: "Transfer Qty",
            accessorKey: "transfer_qty",
            size: 10,
            Cell: ({ row }) => {
              const { original } = row;
              const inputValue = inputValues[original?.inv_id] || "";
    
              return (
                <TextField
                  
                  value={ inputValues[original?.inv_id] !== null &&
                    inputValues[original?.inv_id] !== undefined &&
                    inputValues[original?.inv_id] !== ""
                      ? inputValues[original?.inv_id]
                      : ""}
                  size="small"
                //   onChange={(e) => handleQuantityChange(e, original)}
                  inputProps={{
                    step: "any",
                    max: original.avl_qty,
                  }}
                />
              );
            },
          },
        ],
        [
          inputValues,
          selectedRows,
          sTOData,
          setAddedSKUMasterData,
          updatedRowsArray,
        ]
      );
      useEffect (()=>{
        if(stoId.stoId){
            console.log("sto Id in useeffect" , stoId.stoId);
            fetchSTODataForApproval(stoId.stoId);
        }
       
      },[]);
  const fetchSTODataForApproval = async () => {
    setIsDialogLoading(true);
    console.log("sto id in fetch function  " , stoId.stoId);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_STO_DETAIL_OF_STO(stoId.stoId),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsDialogLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data;
        console.log("data", data[0]?.sto_sku);
        setSelectedSTOData(data[0]?.sto_sku);
        // setTotalPages(data?.total_pages);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsDialogLoading(false);
      setErrorMessage("Please Try Again");
      setErrorPopupOpen(true);
    }
  };
  return (
    <>
    <Grid container spacing={1} style={containerStyle8}>
    {isLoading ? (
      <div style={loaderContainerStyle}>
        <ScaleLoader color="#495057" loading={isLoading} />
      </div>
    ) 
     
  :(selectedSTOData?.length === 0 ? (
      <NullScreen containerheight="80vh" containerWidth="100%" />
  ) : (
      <>
        <div style={gridContainerStyle}>
        <Grid item sm={12} xs={12} md={12}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={selectedSTOData}
              enableGrouping
              getRowId={(row) => row?.inv_id}
            //   onRowSelectionChange={onRowSelectionChange}
              state={{ rowSelection }}
              enableEditing={false}
              enableStickyHeader
              enableRowVirtualization
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              editingMode="table"
              enablePagination={false}
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 100 },
              }}
              muiTableHeadCellProps={{
                sx: {
                  fontWeight: "650",
                  color: "#fff",
                  backgroundColor: "#34519fe6",
                  fontSize: "12px",
                },
              }}
              muiTableContainerProps={{ sx: { maxHeight: "65vh" } }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              muiTableBodyProps={{
                sx: {
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#ffff",
                  },
                },
              }}
            />
          </ThemeProvider>
          </Grid>
        </div>
      </>
      )
              
            )}
          
  </Grid>
  <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
  </>
  )
  
}

export default STOMiddleWareDialog