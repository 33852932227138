import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import iconImage from "../../Assets/FamboLOGO.png";
import qrCodeImage from "../../Assets/fambo_payment_QR.png";
import HindRegular from "./Hind-Regular.ttf";

Font.register({
  family: "Hind-Regular",
  src: HindRegular,
});

const styles = StyleSheet.create({
  cellItem: {
    width: "42%",
    fontSize: 8,
    marginLeft: "5px",
    fontFamily: "Hind-Regular",
  },
  cellItemHeader: {
    width: "42.8%",
    textAlign: "center",
    fontSize: 8,
  },
  page: {
    flexDirection: "column",
    padding: "8px",
  },
  section: {
    margin: 10,
    padding: 2,
    flexGrow: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  iconContainer: {
    width: "20%",
    textAlign: "right",
  },
  addressContainer: {
    width: "50%",
    fontSize: 10,
  },
  QRContainer: {
    width: "30%",
    // textAlign: "right",
    fontSize: 10,
  },
  taxInvoiceContainer: {
    width: "100%",
    fontSize: 13,
  },
  icon: {
    width: 100,
    height: 60,
  },

  QR: {
    width: 90,
    height: 90,
  },
  horizontalDivider: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },
  footer: {
    borderTopColor: "black",
    borderTopWidth: 1,
    fontSize: 10,
    padding: 2,
    textAlign: "left",
  },

  billingShippingContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#f2f2f2",
  },
  totalsContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  billingShippingSection: {
    flex: 1,
    padding: 2,
  },
  billingShippingDivider: {
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  billingShippingHeader: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 10,
  },
  bankAccountHeader: {
    fontSize: 8,
    fontWeight: "bold",
    marginBottom: 10,
  },
  invoiceDetailsBoxHeader: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  billingShippingDetails: {
    fontSize: 8,
  },
  signatureDetails: {
    fontSize: 8,
    marginTop: 10,
  },
  itemsTotalDetails: {
    fontSize: 10,
    textAlign: "right",
    padding: 3,
  },
  itemsTotalAmountDetails: {
    fontSize: 10,
    textAlign: "right",
    padding: 3,
    // backgroundColor: "#f2f2f2",
  },
  tableContainer: {
    marginTop: 10,
    width: "100%",
    backgroundColor: "#ffffff",
    border: 1,
  },
  tableHeader: {
    flexDirection: "row",
    fontSize: 7,
    borderBottomColor: "black",
    borderBottomWidth: 1,
    backgroundColor: "#f2f2f2",
    padding: 1,
  },

  headerCell: {
    flex: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    padding: 1,
  },
  cellSNo: {
    width: "5%",
    textAlign: "center",
    fontSize: 8,
  },
  cellQty: {
    width: "7%",
    textAlign: "center",
    fontSize: 7,
  },
  cellRTV: {
    width: "7%",
    textAlign: "center",
    fontSize: 7,
  },
  cellRate: {
    width: "7%",
    textAlign: "center",
    fontSize: 7,
  },
  cellTax: {
    width: "5%",
    textAlign: "center",
    fontSize: 7,
  },
  cellAmount: {
    width: "8%",
    textAlign: "center",
    fontSize: 7,
  },
  salesDivider: {
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  footer: {
    width: "100%",
    textAlign: "center",
    fontSize: 8,
  },
  footerContainer: {
    marginTop: 2,
    width: "100%",
    backgroundColor: "#ffffff",
    border: 1,
  },
  footerHeader: {
    flexDirection: "row",
    fontSize: 8,
    borderBottomColor: "black",
    borderBottomWidth: 1,
    backgroundColor: "#ffffff",
    padding: 1,
  },
});

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const epochToDDMMYYYY = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "Invalid Timestamp";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const BillingShippingBox = ({ billData }) => {
  return (
    <View style={styles.billingShippingContainer}>
      <View
        style={[styles.billingShippingSection, styles.billingShippingDivider]}
      >
        <Text style={styles.billingShippingHeader}>Vendor Information</Text>
        <Text style={styles.billingShippingDetails}>
          Vendor Name: {capitalizeFirstLetter(billData?.vendor_name)}
        </Text>
        <Text style={styles.billingShippingDetails}>
          Vendor Code: {capitalizeFirstLetter(billData?.vendor_code)}
        </Text>
        <Text style={styles.billingShippingDetails}>
          Vendor GST: {capitalizeFirstLetter(billData?.vendor_gst)}
        </Text>
      </View>

      <View style={styles.billingShippingSection}>
        <Text style={styles.billingShippingHeader}>Shipping Address</Text>
        <Text style={styles.billingShippingDetails}>
          Warehouse: {billData?.warehouse_name}
        </Text>
        <Text style={styles.billingShippingDetails}>
          Address: {billData?.vendor_address?.addressline1}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {billData?.vendor_address?.city}, {billData?.vendor_address?.pincode}
        </Text>
        <Text style={styles.billingShippingDetails}>
          {billData?.vendor_address?.state},{" "}
          {capitalizeFirstLetter(billData?.vendor_address?.country)}
        </Text>
      </View>
    </View>
  );
};

const ItemsTotalBox = ({ billData }) => {
  return (
    <View style={styles.totalsContainer}>
      <View style={styles.billingShippingSection}>
        <Text style={styles.itemsTotalDetails}>
          Sub Total: Rs. {billData?.sub_total}
        </Text>
        <Text style={styles.itemsTotalDetails}>
          Total Tax: {billData?.total_tax}
        </Text>
        <Text style={styles.itemsTotalAmountDetails}>
          Total Amount: Rs. {billData?.total_amount}
        </Text>
      </View>
    </View>
  );
};

const SaleItemsTable = ({ saleItems }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.tableHeader}>
        <Text style={[styles.cellSNo, styles.salesDivider]}>S.No.</Text>
        <Text style={[styles.cellItemHeader, styles.salesDivider]}>Item</Text>
        <Text style={[styles.cellQty, styles.salesDivider]}>Order Qty</Text>
        <Text style={[styles.cellQty, styles.salesDivider]}>Rcvd Qty</Text>
        <Text style={[styles.cellRTV, styles.salesDivider]}>RTV Qty</Text>
        <Text style={[styles.cellQty, styles.salesDivider]}>Final Qty</Text>
        <Text style={[styles.cellRate, styles.salesDivider]}>Rate</Text>
        <Text style={[styles.cellTax, styles.salesDivider]}>Tax</Text>
        <Text style={[styles.headerCell, styles.cellAmount]}>Amount</Text>
      </View>
      {saleItems?.map((item, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={[styles.cellSNo, styles.salesDivider]}>{index + 1}</Text>
          <Text style={[styles.cellItem, styles.salesDivider]}>
            {capitalizeFirstLetter(item?.sku_name)} ({item?.sku_code})
          </Text>
          <Text style={[styles.cellQty, styles.salesDivider]}>
            {item?.order_qty}
          </Text>
          <Text style={[styles.cellQty, styles.salesDivider]}>
            {item?.rcvd_qty}
          </Text>
          <Text style={[styles.cellRTV, styles.salesDivider]}>
            {item?.rtv_qty}
          </Text>
          <Text style={[styles.cellQty, styles.salesDivider]}>{item?.qty}</Text>
          <Text style={[styles.cellRate, styles.salesDivider]}>
            {item?.rate}
          </Text>
          <Text style={[styles.cellTax, styles.salesDivider]}>{item?.tax_amount}</Text>
          <Text style={[styles.cellAmount]}>{item?.line_amount}</Text>
        </View>
      ))}
    </View>
  );
};

const Footer = () => {
  return (
    <View style={styles.footerContainer}>
      <View style={styles.footerHeader}>
        <Text style={[styles.footer]}>
          This is a computer generated document
        </Text>
      </View>
    </View>
  );
};

const BillFile = ({ billData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.header}>
          <View style={styles.iconContainer}>
            <Image style={styles.icon} src={iconImage} />
          </View>
          <View style={styles.addressContainer}>
            <Text>Fambo Innovation Pvt. Ltd</Text>
            <Text>Plot No. C-1 Urbtech NPX Tower, Sector-153</Text>
            <Text>Noida,Gautam buddha Nagar,Uttar Pradesh - 201310</Text>
            <Text>India</Text>
            <Text>GSTIN : 09AAECF9571A1ZL</Text>
            
          </View>
          <View style={styles.QRContainer}>
            <Text>{billData?.status === 'release' ? 'Purchase Order' : 'Goods Received Note'}</Text>
            <Text>PO: {billData?.int_po_no}</Text>
            <Text>PO Date: {epochToDDMMYYYY(billData?.order_date)}</Text>
            <Text>GRN Date: {epochToDDMMYYYY(billData?.bill_date)}</Text>
            <Text>Amount: Rs.{billData?.total_amount}</Text>
          </View>
        </View>
        <View style={styles.horizontalDivider} />
        <BillingShippingBox billData={billData} />
        <View style={styles.horizontalDivider} />
        <SaleItemsTable saleItems={billData?.purchase_skus} />
        <ItemsTotalBox billData={billData} />
        <Footer />
      </View>
    </Page>
  </Document>
);

export default BillFile;
