import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { BASE_OMS_URL, API_ENDPOINTS } from "../ApiEndpoint/ApiEndpoint";
import { tokens } from "../../src/theme";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { VscGraph } from "react-icons/vsc";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import TopBarForOMS from "./TobBarForOMS";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../Attendance/PopupComponent/SuccessPopup";
import ErrorPopup from "../Attendance/PopupComponent/ErrorPopup";
import { TbMoodEmpty } from "react-icons/tb";
import { IoIosPerson } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { GiFruitBowl } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { Divider } from "antd";
import { MdCoPresent } from "react-icons/md";
import { HiOutlineIdentification } from "react-icons/hi2";
import { IoLocationOutline } from "react-icons/io5";
import { WiDayCloudy } from "react-icons/wi";
import { FaHourglassEnd } from "react-icons/fa6";
import RefreshIcon from "@mui/icons-material/Refresh";
import AnalyticsReportPopup from "./AnalyticsReportPopup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import AnalyticsStatContainer from "./AnalyticsStatContainer";
import AnalyticsOrderGraphComponent from "./AnalyticsOrderGraphComponent";
import AnalyticsQuantityGraphComponent from "./AnalyticsQuantityGraphComponent";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_OMS_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const AnalyticsHome = () => {
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  const [orderGraphData, setOrderGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [customerNames, setCustomerNames] = useState([]);
  const [skuNames, setSkuNames] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [skuId, setSkuId] = useState(null);
  const [dayId, setDayId] = useState(null);
  const [statData, setStatData] = useState(null);
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);

  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());

  const [refreshCount, setRefreshCount] = useState(1);

  const [filterValues, setFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
  });

  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    customer_id: null,
    item_id: null,
    day: null,
  });

  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,
      customer_id: customerId,
      item_id: skuId,
      day: dayId,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, customerId, skuId, dayId]);

  useEffect(() => {
    fetchCustomerNames();
    fetchSKUNames();
    fetchWeekDays();
    fetchOrderGraphData(filterValues);
  }, []);

  const handleApplyClick = () => {
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      customer_id: temporaryFilterValues.customer_id,
      item_id: temporaryFilterValues.item_id,
      day: temporaryFilterValues.day,
    };
    setStartDate(temporaryFilterValues.start_date);
    setEndDate(temporaryFilterValues.end_date);
    setCustomerId(temporaryFilterValues.customer_id);
    setSkuId(temporaryFilterValues.item_id);
    setDayId(temporaryFilterValues.day);
    setFilterValues(newFilterValues);
    fetchOrderGraphData(newFilterValues);
  };

  const fetchCustomerNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CUSTOMER_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      // console.log("data in fetchCustomerNames", data?.data?.data);
      setCustomerNames(data?.data?.data);
    } catch (error) {
      console.log("Error fetching CustomerNames", error);
    }
  };
  const fetchWeekDays = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WEEK_DAYS, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response?.data;
      // console.log("data in fetchWeekDays", data?.data);
      setWeekDays(data?.data);
    } catch (error) {
      console.log("Error fetching WeekDays", error);
    }
  };
  const fetchSKUNames = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_SKU_DATA, {
        params: {
          limit: 1000,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response?.data;
      // console.log("data in fetchSKUNames", data);
      setSkuNames(data);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const fetchOrderGraphData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_ORDER_GRAPH_DATA,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      // console.log("data in fetchOrderGraphData", data);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setOrderGraphData(data);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };



  const handleRefreshClick = () => {
    const initialValues = {
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
    };
    setCustomerId(null);
    setSkuId(null);
    setDayId(null);
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    fetchOrderGraphData(initialValues);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const containerStyle = {
    maxHeight: "63vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };
  const statContainerStyle = {
    height: "15vh",
    // backgroundColor: "blue",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "67vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const NullScreen = () => {
    const nullScreenContainerStyle = {
      top: 0,
      left: 0,
      display: "flex",
      overflowX: "hidden",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "67vh",
      width: "100%",
      // backgroundColor: "red",
    };
    return (
      <Box style={nullScreenContainerStyle}>
        <TbMoodEmpty
          style={{ fontSize: "50px", color: colors.green.DEFAULT }}
        />
        <Typography variant="h2" color="textPrimary" mt={2}>
          No Data Found
        </Typography>
        {/* <Typography variant="h4" color="textSecondary" mt={1}>
          Please select a farm to intiate fertigation.
        </Typography> */}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
       
        <Grid container alignItems="center" sx={{ height: "5vh" }}>
          <Grid item>
            <VscGraph fontSize="large" style={{ color: "#495057" }} />
          </Grid>
          <Grid item>
            <Typography
              variant="h3"
              fontWeight="400"
              fontFamily="Lexend Deca"
              color="#495057"
              style={{ marginLeft: "8px" }}
            >
              Analytics [ Includes Mobile App & Automated Pipeline ]
            </Typography>
          </Grid>
        </Grid>

        <div style={{ paddingRight: "10px" }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "6vh" }}
          >
            <Grid item xs={12} sm={9} md={9}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} marginTop={"6px"}>
                  <RangePicker
                    onChange={handleDateChange}
                    // size="large"
                    key={refreshCount}
                    format={dateFormat}
                    defaultValue={[
                      dayjs(formatDate(startDate), dateFormat),
                      dayjs(formatDate(endDate), dateFormat),
                    ]}
                  />
                </Grid>

                <Grid item xs={12} sm={2.5} marginTop={"6px"}>
                  <FormControl fullWidth>
                    <InputLabel>Select Customer</InputLabel>
                    <Select
                      label="Select Customer"
                      value={customerId}
                      onChange={(e) => {
                        setCustomerId(e.target.value);
                      }}
                      displayEmpty
                      size="small"
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Customer" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <IoIosPerson />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {customerNames?.map((customer) => (
                        <MenuItem
                          key={customer.customer_id}
                          value={customer.customer_id}
                        >
                          {customer.customer_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2.5} marginTop={"6px"}>
                  <FormControl fullWidth>
                    <InputLabel>Select SKU</InputLabel>
                    <Select
                      label="Select SKU"
                      value={skuId}
                      onChange={(e) => {
                        setSkuId(e.target.value);
                      }}
                      size="small"
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select SKU" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <GiFruitBowl />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {skuNames?.map((sku) => (
                        <MenuItem key={sku.item_id} value={sku.item_id}>
                          {sku.item_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} marginTop={"6px"}>
                  <FormControl fullWidth>
                    <InputLabel>Select Day</InputLabel>
                    <Select
                      label="Select Day"
                      value={dayId}
                      onChange={(e) => {
                        setDayId(e.target.value);
                      }}
                      size="small"
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Day" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <CiCalendarDate />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {weekDays?.map((day) => (
                        <MenuItem key={day.value} value={day.value}>
                          {day.day}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={1} marginTop={"6px"}>
                  <Button
                    variant="contained"
                    startIcon={<TiTickOutline />}
                    sx={{
                      backgroundColor: "#495057",
                      color: "#ffffff",
                    }}
                    size="small"
                    onClick={handleApplyClick}
                    disabled={!startDate || !endDate}
                  >
                    Apply
                  </Button>
                </Grid>

                <Grid item xs={12} sm={1} marginTop={"6px"}>
                  <Button
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    size="small"
                    sx={{
                      backgroundColor: "#495057",
                      color: "#ffffff",
                    }}
                    onClick={handleRefreshClick}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid style={statContainerStyle}>
            <AnalyticsStatContainer statData={orderGraphData?.generic_stats} />
          </Grid>

          <Grid container spacing={1} style={containerStyle}>
            {isLoading ? (
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={isLoading} />
              </div>
            ) : orderGraphData?.length === 0 ? (
              <NullScreen />
            ) : (
              <>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    flexGrow: 1,
                    // backgroundColor: "red",
                  }}
                >
                  <Divider
                    style={{
                      background: "rgba(0, 0, 0, 0.2)",
                      height: "2px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      color={colors.white.DEFAULT}
                      style={{
                        backgroundColor: "#495057",
                        padding: "6px",
                        borderRadius: "6px",
                      }}
                    >
                      Order Analysis
                    </Typography>
                  </Divider>
                  <AnalyticsOrderGraphComponent
                    graphData={orderGraphData?.order_stats}
                  />
                  <Divider
                    style={{
                      background: "rgba(0, 0, 0, 0.2)",
                      height: "2px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      color={colors.white.DEFAULT}
                      style={{
                        backgroundColor: "#495057",
                        padding: "6px",
                        borderRadius: "6px",
                      }}
                    >
                      SKU Analysis
                    </Typography>
                  </Divider>
                  <AnalyticsQuantityGraphComponent
                    graphData={orderGraphData?.order_stats}
                  />
                </div>
              </>
            )}
          </Grid>
        </div>
      </Box>

      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default AnalyticsHome;
