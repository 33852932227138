import React, { useState } from "react";
import {
  Typography,
  useTheme,
  Grid,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { tokens } from "../../../theme";
import SendIcon from "@mui/icons-material/Send";
import { ClockLoader } from "react-spinners";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import SaveAlert from "../../../SaveAlert";
import ApiErrorAlert from "../../../ApiErrorAlert";
import SyncIcon from "@mui/icons-material/Sync";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const RecipeActions = ({ farmObject, onChange }) => {
  const farmName = farmObject.farm_name;
  const farmId = farmObject.farm_id;
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const [isCompleted, setIsCompleted] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isDefault, setIsDefault] = useState(true);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirm = () => {
    handleSubmit();
    setIsConfirmDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    const formData = localStorage?.getItem("setPoints");
    console.log("formData", formData);

    try {
      setIsPending(true);
      setIsDefault(false);
      setIsCompleted(false);

      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_FERTIGATION_SETPOINTS,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        console.log("Response is good");
        onChange();

        setTimeout(() => {
          setApiSuccessOpen(true);
          setIsCompleted(true);
          setIsPending(false);
        }, 5000);
      } else {
        console.log("API not working");
        localStorage.removeItem("setPoints");

        setTimeout(() => {
          setApiErrorOpen(true);
          setIsDefault(true);
          setIsPending(false);
        }, 5000);
      }
    } catch (error) {
      console.log("Error:", error);

      setTimeout(() => {
        setApiErrorOpen(true);
        setIsDefault(true);
        setIsPending(false);
      }, 5000);
    }
    setTimeout(() => {
      setIsCompleted(false);
      setIsPending(false);
      setIsDefault(true);
    }, 10000);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item sx={{ marginTop: "-133px" }}>
          <Avatar
            sx={{
              backgroundColor: "#2c7B55", // Background color of the circle
              width: 20, // Adjust the size of the circle as needed
              height: 20,
            }}
          >
            <Typography
              variant="h7"
              color={colors.white.DEFAULT}
              fontFamily="Lexend Deca"
            >
              2
            </Typography>
          </Avatar>
        </Grid>
        <Grid item sx={{ marginTop: "-133px" }}>
          <Typography
            variant="h3"
            color={colors.black[200]}
            fontFamily="Lexend Deca"
          >
            Actions
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" spacing={0.5}>
        <Grid item sm={8} xs={8}>
          <Button
            sx={{ backgroundColor: "#2C7B55" }}
            variant="contained"
            endIcon={<SendIcon />}
            onClick={() => setIsConfirmDialogOpen(true)}
          >
            <Typography variant="h1" color="white" fontFamily="Lexend Deca">
              Send To Farm
            </Typography>
          </Button>
        </Grid>

        <Grid item sm={2} xs={2}>
          {isPending && <ClockLoader color="#2C7B55" />}
          {isDefault && (
            <CloudSyncOutlinedIcon
              variant="outlined"
              style={{ fontSize: 50, color: "#2c7B55" }}
            />
          )}
          {isCompleted && (
            <CloudDoneOutlinedIcon
              variant="outlined"
              style={{ fontSize: 50, color: "#2c7B55" }}
            />
          )}
        </Grid>
      </Grid>
      <Dialog open={isConfirmDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Initiate Fertigation</DialogTitle>
        <DialogContent>
          Are you sure you want to Start Fertigation Unit?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="success">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="success">
            Start
          </Button>
        </DialogActions>
      </Dialog>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};

export default RecipeActions;
