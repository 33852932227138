import React, { useState } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PurchaseReport from './PurchaseReport';
import SalesReport from "./ProductSalesReport";
import LossReport from "./LossReport";
// import PurchaseSheet from "../InventoryModule/InventoryFilters/PurchaseSheet";
import InventoryUpDown from "./InventoryUpDown";
import ProductLinkageReport from "./ProductLinkageReport";
import ProductSalesReport from "./ProductSalesReport";

const ReportsMainPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginTop: "-0.8%",
        flexItem: "space-between",
        // backgroundColor:"#2C7B55"
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab
          label={
            <Typography
              variant="body1"
              style={{
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
               Sales Report
            </Typography>
          }
          sx={{
            "&.Mui-selected": { color: "#2949c73d" },
          }}
        />
      
        
         <Tab
          label={
            <Typography
              variant="body1"
              style={{
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              Purchase Report
            </Typography>
          }
          sx={{
            "&.Mui-selected": { color: "#2949c73d" },
          }}
        />
        <Tab
          label={
            <Typography
              variant="body1"
              style={{
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
             Inventory Adjustment Report
            </Typography>
          }
          sx={{
            "&.Mui-selected": { color: "#2949c73d" },
          }}
        />
        <Tab
        label={
          <Typography
            variant="body1"
            style={{
              fontSize: "14px",
              textDecoration: "none",
            }}
          >
           Product Linkage Report
          </Typography>
        }
        sx={{
          "&.Mui-selected": { color: "#2949c73d" },
        }}
      />
      
      </Tabs>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {value === 0 && (
          <div
            style={{ backgroundColor: "white", padding: "10px", flexGrow: 1 }}
          >
            <ProductSalesReport/>
          </div>
        )}
        
        
        {value === 1 && (
          <div
            style={{ backgroundColor: "white", padding: "10px", flexGrow: 1 }}
          >
            
            <PurchaseReport/>
          </div>
        )}
         {value === 2 && (
          <div
            style={{ backgroundColor: "white", padding: "10px", flexGrow: 1 }}
          >
            <InventoryUpDown/>
            {/* <Purchase /> */}
          </div>
        )}
        {value === 3 && (
          <div
            style={{ backgroundColor: "white", padding: "10px", flexGrow: 1 }}
          >
            <ProductLinkageReport/>
            {/* <Purchase /> */}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default ReportsMainPage;
