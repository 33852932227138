import React, { useState, useEffect } from "react";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const ErrorPopup = ({ open, onClose, message }) => {
  const [isVisible, setIsVisible] = useState(open);

  const defaultMessage = "Oops! Something went wrong";
  const errorMessage = message === undefined ? defaultMessage : message;

  useEffect(() => {
    setIsVisible(open);
  }, [open]);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    <Slide direction="left" in={isVisible}>
      <Alert
        severity="error"
        onClose={onClose}
        style={{
          position: "fixed",
          top: "8px",
          right: isVisible ? "2px" : "-100%",
          zIndex: 1000,
          transition: "right 0.3s ease-in-out",
        }}
      >
        <AlertTitle>Error</AlertTitle>
        {errorMessage}
      </Alert>
    </Slide>
  );
};

export default ErrorPopup;
