import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { ScaleLoader } from "react-spinners";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { tokens } from "../AttTheme";
import { Progress, Spin } from "antd";

let activeSocket = null;

const SocketReportPopup = ({ setOpenDialog, requestId }) => {
  console.log("requestId in SocketReportPopup-----", requestId);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(true);
  const [downloadURL, setDownloadURL] = useState(null);

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const socketEndPoint = process.env.REACT_APP_SOCKET_ENDPOINT;
    if (activeSocket) {
      console.log("Closing previous WebSocket connection in SocketReportPopup");
      activeSocket.close();
    }
    const socket = new WebSocket(socketEndPoint);
    activeSocket = socket;
    socket.onopen = () => {
      console.log("WebSocket connection is open in SocketReportPopup");
      setLoading(false);
    };
    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event?.data);
        if (typeof message === "object" && message !== null) {
          console.log("New message received", message);
          if (message?.request_id === requestId) {
            const updatedPercent = message?.percent_progress;
            if (updatedPercent > percent) {
              setPercent(updatedPercent);
            }
            if (updatedPercent === 100) {
              setDownloadURL(message?.download_link);
            }
          }
        } else {
          console.log("Received message is not a valid JSON object");
        }
      } catch (error) {
        console.log("Error parsing JSON:", error);
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection is closed in SocketReportPopup");
    };

    return () => {
      if (activeSocket) {
        console.log("Closing WebSocket connection on unmount in SocketReportPopup");
        activeSocket.close();
        activeSocket = null;
      }
    };
  }, []);

  const handleDownloadClick = () => {
    console.log("File Download Link", downloadURL);
    window.open(downloadURL, "_blank");
  };

  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "30%",
          height: "40%",
        },
      }}
    >
      <DialogTitle sx={{ position: "sticky", height: "3%" }}>
        <Typography
          variant="h3"
          color={colors.green.DEFAULT}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {percent === 100 ? "Report Generated!" : "In Progress..."}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,
            color: "grey",
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              marginTop: "55px",
              //   height: "10vh",
              zIndex: "1",
            }}
          >
            <ScaleLoader color="#2c677b" loading={loading} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              //   alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Progress strokeColor="#5e89c7" type="circle" percent={percent} />
          </div>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#5e89c7",
          }}
          startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
          disabled={!downloadURL}
          onClick={handleDownloadClick}
        >
          <Typography
            variant="h6"
            color={colors.white.DEFAULT}
            fontFamily="Lexend Deca"
          >
            Download
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SocketReportPopup;
