import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import NullScreen from "../../WMS/PopUpMsg/NullScreen";
import { tokens } from "../../WMS/WMStheme";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import ViewListIcon from "@mui/icons-material/ViewList";
import MailIcon from "@mui/icons-material/Mail";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../../WMS/PopUpMsg/SuccessPopUp";
import ErrorPopup from "../../WMS/PopUpMsg/ErrorPopUp";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Label } from "semantic-ui-react";

import {
  OutlinedInput,
  DialogContentText,
  Dialog,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  InputLabel,
  DialogTitle,
  FormControl,
  Chip,
  Autocomplete,
  InputAdornment,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

const axiosInstanceOMS = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_OMS_URL}`,
});

// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const ITEM_HEIGHT = 28;

const ITEM_PADDING_TOP = 8;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));

const columns = [
  { id: "button", label: "Actions", type: "button" },
  { id: "status", label: "Status", type: "tag" },
  { id: "name", label: "Name", type: "string" },
  { id: "type", label: "Type", type: "string" },
  { id: "email", label: "Email", type: "string" },
  { id: "role", label: "Role", type: "string" },
  { id: "password", label: "Password", type: "string" },
];

const OMSUserViewPage = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [userData, setUserData] = useState([]);
  //   const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogAddNewOpen, setDialogAddNewOpen] = useState(false);
  const [skuLoading, setSkuLoading] = useState(false);
  const [newData, setNewData] = useState();
  const [customerName, setCustomerName] = useState([]);
  const [newCustomer, setNewCustomer] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectedProductsWithFlag, setSelectedProductsWithFlag] = useState([]);
  const [productToSet, setProductToSet] = useState([]);
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [customerLinkDialog, setCustomerLinkDialog] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    name: "",
    email: "",
    phone: "",
    type: "",
    role: "",
    status: true,
  });
  useEffect(() => {
    fetchUserGridData(currentPage);
  }, [newData]);

  const fetchUserGridData = async (page) => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceOMS.get(
        API_ENDPOINTS.GET_OMS_USER_LIST
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setUserData(data?.oms_users);
        setTotalPages(data?.total_pages);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };
  useEffect(() => {
    fetchCustomerName();
  }, []);

  const fetchCustomerName = async (groupId) => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_CUSTOMER_GROUP_CUSTOMER_LIST}`,
        {
          params: {
            limit: 1000,
            offset: 1,
            group_id: groupId,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data?.customers;
        setCustomerName(data);
        console.log("data---------------", data);
        // setNewCustomer()
      } else {
        console.log("Error fetching details");
        setErrorMessage("OOPS, Something went Wrong !!!");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      // setIsLoadingList(false);
      // Set loading to false after the request is completed
    }
  };

  const handleLinkCustomer = async () => {
    setIsLoading(true);
    try {
      const endpoint = `${API_ENDPOINTS.POST_OMS_ADD_NEW_CUSTOMER(
        selectedRowData.oms_user
      )}`;
      const response = await axiosInstance.post(
        endpoint,
        selectedProductsWithFlag,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setCustomerLinkDialog(false);
        setSuccessMessage("Customer Linking Successful");
        setSuccessPopupOpen(true);
      } else {
        setErrorPopupOpen(true);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    fetchUserGridData(newPage);
  };
  const handleAddNewUser = () => {
    setDialogAddNewOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleAddNewCloseDialog = () => {
    setDialogAddNewOpen(false);
  };
  // const handleLinkCustomer = () => {
  //   POST_OMS_ADD_NEW_CUSTOMER
  //   console.log("link here");
  // };
  const handleBack = () => {
    // Navigate to the WMS User's page
    navigate("/userconfig");
  };

  const handleInputChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    // Format the data as needed before sending it
    const formattedData = {
      type: formData.type,
      password: formData.password,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      role: formData.role,
      status: formData.status, // Assuming 'status' is a boolean
    };
    setSkuLoading(true);
    try {
      // Determine the endpoint for creating a new SKU
      const endpoint = `${API_ENDPOINTS.POST_OMS_ADD_NEW_USER_LIST}`;
      console.log("API Endpoint:", endpoint);

      // Send the POST request to create a new SKU
      const response = await axiosInstanceOMS.post(endpoint, formattedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data created successfully!");
        setNewData(response?.data?.Data);
        setSuccessPopupOpen(true);
        setDialogAddNewOpen(false);
        setSkuLoading(false);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the SKU data");
        //   setErrorMessage("Failed to create the SKU data. Please try again.");
      }
    } catch (error) {
      // setErrorPopupOpen(true);
      console.error("Error occurred while creating the SKU data:", error);
    }
    // Now you can send 'formattedData' to your backend or perform any other actions
    console.log(formattedData);
  };
  const containerStyle = {
    maxHeight: "80vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    marginTop: "1%",
    display: "flex",
    height: "74vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };

  const StatusTag = ({ status }) => {
    let tagColor;
    switch (status) {
      case true:
        tagColor = "#74749e";
        break;
      case false:
        tagColor = "#997975";
        break;
      default:
        tagColor = "gray";
        break;
    }
    return (
      <Label
        style={{
          backgroundColor: tagColor,
          color: "white",
          fontWeight: typography.h1,
          fontFamily: "Lexend Deca",
          cursor: "default",
          width: "auto",
        }}
        tag
      >
        {status === true ? "Active" : "In-Active"}
      </Label>
    );
  };
  const handleCloseCustomerLinkDialog = () => {
    setCustomerLinkDialog(false);
  };
  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent() {
    const handleRowClick = (row) => {
      setSelectedRowData(row);
      setCustomerLinkDialog(true);
    };

    return (
      <>
        <TableBody>
          {userData?.map((row, index) => (
            <StyledTableRow key={index}>
              {columns?.map((column) => (
                <StyledTableCell
                  key={column?.id}
                  align="center"
                  // onClick={() => handleRowClick(row)}
                  style={{ cursor: "default" }}
                >
                  {column?.type === "tag" ? (
                    <StatusTag status={row[column.id]} />
                  ) : column?.type === "button" ? (
                    <div
                      className="tooltip-cell"
                      title="Click to Link Customers"
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleRowClick(row)}
                        endIcon={<ViewListIcon />}
                        sx={{ backgroundColor: colors.green.DEFAULT }}
                      >
                        <Typography variant="h7" color={colors.white.DEFAULT}>
                          Link Customers
                        </Typography>
                      </Button>
                    </div>
                  ) : (
                    row[column.id] || "-"
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </>
    );
  }
  useEffect(() => {
    // Fetch products for the customer when in 'edit' mode
    if (selectedRowData.oms_user) {
      fetchListOfAllCustomer(selectedRowData.oms_user);
    }
  }, [selectedRowData?.oms_user]);

  const fetchListOfAllCustomer = async (userId) => {
    // setIsLoadingList(true);
    console.log("oms user id in the fetchlistfunction", userId);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_OMS_CUSTOMER_LINK_WRT_USER}`,
        {
          params: {
            limit: 1000,
            offset: 1,
            oms_user: userId,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data?.customer_list;

        // setNewCustomer(filteredData);
        setProductData(data);

        console.log("product data", data);

        console.log("product  Data", data);
      } else {
        console.log("Error fetching details");
        setErrorMessage("OOPS, Something went Wrong !!!");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    // Fetch products for the customer when in 'edit' mode
    if (productData) {
      const filteredData = productData?.filter((obj) => obj.flag === true);

      setSelectedProduct(filteredData);
      setSelectedProductsWithFlag(productData);
      updateSelectedProducts(filteredData, productData);
    }
  }, [productData]);

  const updateSelectedProducts = (value, products) => {
    const updatedSelectedProducts =
      typeof value === "string" ? value.split(",") : value;

    // Update isSelected property
    const updatedProductData = products?.map((product) => ({
      ...product,
      isSelected: updatedSelectedProducts?.some(
        (selectedProduct) =>
          selectedProduct?.customer_id === product?.customer_id
      ),
    }));
    console.log("updated product data ", updatedProductData);
    console.log("updated selected product data ", updatedSelectedProducts);
    setSelectedProduct(updatedSelectedProducts);

    const updatedSelectedProductsWithFlag = updatedProductData
      .map((selectedProduct) => {
        if (selectedProduct.isSelected) {
          // Check conditions for setting flag and adding to setProductToSet
          if (
            (selectedProduct.flag === false &&
              selectedProduct.isSelected === true) ||
            (selectedProduct.flag === true &&
              selectedProduct.isSelected === true)
          ) {
            setProductToSet((prevData) => [
              ...prevData,
              {
                customer_id: selectedProduct?.customer_id,
                customer_name: selectedProduct?.customer_name,

                customer_code: selectedProduct?.customer_code,
                flag: true,
              },
            ]);
          } else if (
            selectedProduct.flag === true &&
            selectedProduct.isSelected === false
          ) {
            // Handle the case when isSelected is false and flag is true

            setProductToSet((prevData) => [
              ...prevData,
              selectedProduct.map((p) =>
                p.customer_id === selectedProduct.customer_id
                  ? { ...p, flag: false }
                  : p
              ),
            ]);
          }
        } else if (
          selectedProduct.flag === false &&
          selectedProduct.isSelected === false
        ) {
          // Exclude products where isSelected is false and both the current and previous flags are false
          return null;
        }
        return {
          customer_id: selectedProduct?.customer_id,
          customer_name: selectedProduct?.customer_name,

          customer_code: selectedProduct?.customer_code,
          flag: selectedProduct?.isSelected,
        };
      })
      .filter(Boolean);

    console.log(
      "updated selected product with flag data ",
      updatedSelectedProductsWithFlag
    );
    setProductToSet(updatedSelectedProductsWithFlag);

    setSelectedProductsWithFlag(updatedSelectedProductsWithFlag);

    console.log("Selected Products:", updatedSelectedProductsWithFlag);
    console.log("Updated Product Data:", updatedProductData);
  };
  const handleProductChange = (event, newValue) => {
    const { value } = event.target;
    const products = Array.isArray(productData) ? productData : [productData];
    updateSelectedProducts(newValue, products);
  };
  const handleCustomerLinkChange = (event, newValue, reason) => {
    console.log("Reason:", reason);
    if (reason === "removeOption") {
      const updatedValue = newCustomer.map((item) => ({
        ...item,
        flag: newValue.find(
          (newItem) => newItem.customer_id === item.customer_id
        )
          ? item.flag
          : false,
      }));
      setNewCustomer(updatedValue);
    } else {
      const updatedValue = newValue.map((item) => ({ ...item, flag: true }));
      setNewCustomer(updatedValue);
    }
  };

  console.log("NewCustomer", newCustomer);

  return (
    <>
      {skuLoading ? (
        <>
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={skuLoading} />
          </div>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "100%",
            }}
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",

                marginTop: "1%",
              }}
            >
              <Typography variant="h1" color={colors.green.DEFAULT}>
                OMS User's
              </Typography>
              <Grid item xs={6} md={6} sm={6}>
                <Button
                  variant="outlined"
                  startIcon={
                    <AddCircleOutlineOutlinedIcon
                      style={{ color: colors.green.DEFAULT }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    color: colors.green.DEFAULT,
                    marginRight: "2px",
                  }}
                  onClick={handleAddNewUser}
                >
                  <Typography variant="h5" color={colors.green.DEFAULT}>
                    Add New User
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  startIcon={
                    <IoArrowBackCircleOutline
                      style={{ color: colors.green.DEFAULT }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    color: colors.green.DEFAULT,
                  }}
                  onClick={handleBack}
                >
                  <Typography variant="h5" color={colors.green.DEFAULT}>
                    Back
                  </Typography>
                </Button>{" "}
              </Grid>
            </Grid>
            <Grid container spacing={1} style={containerStyle}>
              {isLoading ? (
                <div style={loaderContainerStyle}>
                  <ScaleLoader color="#495057" loading={isLoading} />
                </div>
              ) : userData?.length === 0 ? (
                <NullScreen containerheight="80vh" containerWidth="100%" />
              ) : (
                <>
                  <div style={gridContainerStyle}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 200 }}
                        aria-label="customized table"
                      >
                        <TableHeadComponent />
                        <TableRowsComponent />
                      </Table>
                    </TableContainer>
                  </div>
                </>
              )}
            </Grid>
          </Box>
          <Dialog
            fullScreen
            open={customerLinkDialog}
            onClose={handleCloseCustomerLinkDialog}
            // maxWidth=""
          >
            <DialogTitle>
              <Typography
                variant="h2"
                // style={{ marginLeft: "0.1%" }}
                color="#0C0C0C"
              >
                Link Customers for: {selectedRowData?.name}
              </Typography>
            </DialogTitle>

            <DialogContent>
              <>
                <List>
                  <ListItem>
                    <ListItemText />
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      display="flex"
                      // style={{ marginTop: "1%" }}
                    >
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        // style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Name"
                          fullWidth
                          variant="standard"
                          size="small"
                          // margin="normal"
                          value={selectedRowData.name}
                          disabled
                          placeholder="Name"
                          InputProps={{
                            readOnly: true,
                            className: "custom-disabled-textfield",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        // style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Role"
                          fullWidth
                          variant="standard"
                          size="small"
                          // margin="normal"
                          value={selectedRowData?.role}
                          placeholder="Role"
                          disabled
                          InputProps={{
                            readOnly: true,
                            className: "custom-disabled-textfield",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        // style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Status"
                          fullWidth
                          variant="standard"
                          size="small"
                          // margin="normal"
                          disabled
                          value={selectedRowData?.status}
                          placeholder="Status"
                          InputProps={{
                            readOnly: true,
                            className: "custom-disabled-textfield",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        // style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Email"
                          fullWidth
                          variant="standard"
                          size="small"
                          // margin="normal"
                          disabled
                          value={selectedRowData?.email}
                          placeholder="Email"
                          InputProps={{
                            readOnly: true,
                            className: "custom-disabled-textfield",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        // style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Password"
                          fullWidth
                          variant="standard"
                          disabled
                          size="small"
                          // margin="normal"
                          value={selectedRowData?.password}
                          placeholder="Password"
                          InputProps={{
                            readOnly: true,
                            className: "custom-disabled-textfield",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  display="flex"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}

                  >
                    <FormControl sx={{ m: 1, width: "96%" }}>
                      <Autocomplete
                        multiple
                        id="Link to SKU"
                        value={selectedProduct}
                        onChange={handleProductChange}
                        options={productData}
                        getOptionLabel={(option) => option.customer_name}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Product" />
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={option.customer_id}
                              label={option.customer_name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}></Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions sx={{ marginRight: "15px" }}>
              <Stack
                spacing={1}
                direction="row"
                style={{
                  height: "35px",
                  marginTop: "2%",
                  marginLeft: "84%",
                }}
              >
                <Button
                  onClick={handleCloseCustomerLinkDialog}
                  sx={{ color: "#5E89C7", borderColor: "#5E89C7" }}
                  variant="outlined"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  onClick={handleLinkCustomer}
                  sx={{ backgroundColor: "#5E89C7" }}
                  variant="contained"
                >
                  <Typography
                    variant="h6"
                    color={colors.white.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Save
                  </Typography>
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
          <Dialog
            open={dialogAddNewOpen}
            onClose={handleAddNewCloseDialog}
            maxWidth="md"
          >
            <DialogTitle>
              <Typography
                variant="h2"
                color={colors.green.DEFAULT}
                textAlign="center"
              >
                Add User
              </Typography>
            </DialogTitle>

            <DialogContent>
              <DialogContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <TextField
                      label="Name"
                      value={formData.name}
                      onChange={handleInputChange("name")}
                      size="small"
                      inputProps={{
                        maxLength: 20,
                        minLength: 5,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <PermIdentityIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      autoFocus
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <FormControl fullWidth>
                      <InputLabel id="Select Role">Select Role</InputLabel>
                      <Select
                        label="Select Role"
                        labelId="Select Role"
                        name="Select Role"
                        id="Select Role"
                        value={formData.role}
                        onChange={handleInputChange("role")}
                        size="small"
                        required
                        fullWidth
                        autoFocus
                      >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="normal">Normal</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <TextField
                      label="Password"
                      value={formData.password}
                      onChange={handleInputChange("password")}
                      size="small"
                      inputProps={{
                        maxLength: 15,
                        minLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <AccountCircleIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      autoFocus
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <FormControl fullWidth>
                      <InputLabel id="Status">Status</InputLabel>
                      <Select
                        label="Status"
                        labelId="Status"
                        name="Status"
                        id="Status"
                        value={formData.status ? true : false}
                        onChange={handleInputChange("status")}
                        size="small"
                        required
                        fullWidth
                        autoFocus
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      size="small"
                      value={formData.email}
                      onChange={handleInputChange("email")}
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                        minLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <MailIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} marginTop={"6px"}>
                    <FormControl fullWidth>
                      <InputLabel id="Select Type">Select Type</InputLabel>
                      <Select
                        label="Select Type"
                        labelId="Select Type"
                        name="Select Type"
                        id="Select Type"
                        value={formData.type}
                        onChange={handleInputChange("type")}
                        size="small"
                        required
                        fullWidth
                        autoFocus
                      >
                        <MenuItem value="sourcing">Sourcing</MenuItem>
                        <MenuItem value="customer">Customer</MenuItem>
                        <MenuItem value="sales">Sales</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ marginRight: "15px" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  style={{
                    height: "35px",
                    marginTop: "2%",
                    marginLeft: "84%",
                  }}
                >
                  <Button
                    onClick={handleAddNewCloseDialog}
                    sx={{ color: "#5E89C7", borderColor: "#5E89C7" }}
                    variant="outlined"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    sx={{ backgroundColor: "#5E89C7" }}
                    variant="contained"
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Submit
                    </Typography>
                  </Button>
                </Stack>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </>
      )}
      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default OMSUserViewPage;
