import React, { useState, useEffect, useRef } from "react";
import { Card, CardActionArea } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import SaveAlert from "../../../SaveAlert";
import Pagination from "@mui/material/Pagination";

import ApiErrorAlert from "../../../ApiErrorAlert";
import LocalFloristOutlinedIcon from "@mui/icons-material/LocalFloristOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { tokens } from "../../../theme";
import Collapse from "@mui/material/Collapse";
import Scheduler from "../../../Components/ExpenseTrackerComponent/PlanningComponent/Scheduler";
import SideDrawer from "./SideDrawer";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TimerIcon from "@mui/icons-material/Timer";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  Typography,
} from "@mui/material";
import { Label } from "semantic-ui-react";
import TextField from "@mui/material/TextField";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import TableRowsIcon from "@mui/icons-material/TableRows";

import axios from "axios";
import MasterDialog from "./FormDialog/MasterDialog";
import EventIcon from "@mui/icons-material/Event";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const AllCards = ({ taskData, fetchTaskData }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  // const [isOpen, setIsOpen] = useState(Array(taskData.length).fill(false)); // Array to store open/close state of each card

  const [isOpen, setIsOpen] = useState({});

  const handleOpen = (uuid) => {
    setIsOpen((prevState) => {
      console.log("Opening drawer:", uuid);
      return { ...prevState, [uuid]: true }; // Set the corresponding UUID to true
    });
  };

  const handleClose = (uuid) => {
    console.log("Closing drawer:", uuid);
    setIsOpen((prevState) => {
      const newState = { ...prevState };
      delete newState[uuid]; // Remove the corresponding UUID
      return newState;
    });
    console.log("isOpen state:", isOpen);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const handlePopupClose = () => {
    setShowPopUp(false);
  };
  const handleMasterDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <div
        style={{
          width: "115%",
          height: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)", // Divide the screen into 3 equal columns
          gap: "2.4%", // Adjust the gap between cards
        }}
      >
        <div
          style={{
            height: "90%",
            width: "100%", // Set the width to 30% for three cards in a row
            margin: "2% 1%", // Adjust margins as needed
            padding: "10px",
            border: "1px dashed #84899C",
            borderRadius: "10px",
            overflow: "hidden",
            textAlign: "center",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            transition: "transform 0.2s ease-in-out",
          }}
          onClick={() => setIsDialogOpen(true)}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          <AddCircleOutlineIcon fontSize="large" style={{ color: "#84899C" }} />

          <Typography
            variant="h6"
            color={colors.black[200]}
            fontFamily="Lexend Deca"
          >
            Add New Activity
          </Typography>
        </div>
        {userDetails?.task_action_addTask ? (
          <MasterDialog
            isOpen={isDialogOpen}
            onClose={handleMasterDialogClose}
            // selectedStartDate={selectedStartDate} // Pass the formatted start date
            // selectedEndDate={selectedEndDate} // Pass the formatted end date
            fetchTaskData={fetchTaskData}
          />
        ) : (
          showPopUp && (
            <div className="popup">
              <div className="popup-content">
                <p>You do not have access to this action!</p>
                <Button onClick={handlePopupClose}>Close</Button>
              </div>
            </div>
          )
        )}
        {taskData?.map((ledger) => (
          <div
            style={{
              width: "100%",
              margin: "2% 1%",
              padding: "6px",
              height: "90%",
              border: "1px solid #ccc",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              transition: "transform 0.2s ease-in-out",
              backgroundColor: "#ecf4f0",
            }}
            key={ledger.mandatory_field.id}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <Label
                style={{
                  fontFamily: "Lexend Deca",
                  width: "90%",
                  fontSize: 9,
                  color: "#FFFFFF",
                  // backgroundColor: "#2c7B55",
                  backgroundColor:
                    ledger.mandatory_field.status === "Archive"
                      ? "#A45A52"
                      : "#2c7B55",
                }}
                tag
              >
                {ledger.mandatory_field.status}
              </Label>
            </div>
            <Typography
              style={{
                marginBottom: "8px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
              variant="h2"
              color={colors.black.DEFAULT}
              fontFamily="Lexend Deca"
            >
              <CurrencyRupeeIcon fontSize="inherit" />
              {ledger.mandatory_field.amount?.length > 10
                ? `${ledger.mandatory_field.amount?.slice(0, 10)}...`
                : ledger.mandatory_field.amount}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row", // Set to "row" to align items horizontally
                alignItems: "center", // Vertically center align the items
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h7"
                  color={colors.black[200]}
                  fontFamily="Lexend Deca"
                >
                  <EventIcon fontSize="inherit" />

                  {ledger.mandatory_field.ledger_name?.length > 11
                    ? `${ledger.mandatory_field.ledger_name?.slice(0, 8)}...`
                    : ledger.mandatory_field.ledger_name}
                </Typography>
                <Typography
                  variant="h7"
                  color={colors.black[200]}
                  fontFamily="Lexend Deca"
                >
                  <PersonIcon fontSize="inherit" />
                  {ledger.mandatory_field.employee_name}
                </Typography>
                <Typography
                  variant="h7"
                  color={colors.black[200]}
                  fontFamily="Lexend Deca"
                >
                  <EventIcon fontSize="inherit" />
                  {/* {ledger.mandatory_field.end_date} */}
                  {moment(ledger.mandatory_field.end_date).format("DD/MM/YY")}
                </Typography>
              </div>
              <Divider
                orientation="vertical" // Make the divider vertical
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  height: "100%",
                  marginRight: "4px",
                  marginLeft: "13px",
                }}
              />
              <Tooltip title="View More" placement="top">
                <Button
                  variant="text"
                  aria-label="show more"
                  endIcon={
                    <KeyboardDoubleArrowRightIcon
                      sx={{
                        fontSize: 1,
                        color: "#fff",
                        backgroundColor: "#2c7B55", // Set the background color to green
                        padding: "4px", // Add some padding to the button
                        borderRadius: "50%", // Make the button round
                      }}
                    />
                  }
                  onClick={() => handleOpen(ledger.mandatory_field.id)}
                ></Button>
              </Tooltip>
            </div>
            {isOpen[ledger.mandatory_field.id] && (
              <SideDrawer
                isOpen={isOpen[ledger.mandatory_field.id]}
                onClose={() => handleClose(ledger.mandatory_field.id)}
                details={taskData}
                task_id={ledger.mandatory_field.id}
                fetchTaskData={fetchTaskData}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

const PlanningMainPage = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [taskData, setTaskData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [offset, setOffSet] = useState(1);
  const [dataAvailable, setDataAvailable] = useState(false);

  useEffect(() => {
    fetchTaskData(1);
  }, [dataAvailable]);
  const fetchTaskData = async (page) => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_FINANCE_PLANNING,

        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            limit: 10,
            offset: page,
          },
        }
      );

      const data = await response?.data;
      const count = data?.rowCount;

      if (count === 0) {
        setDataAvailable(true);
      } else {
        setTaskData(data);
        setTotalPages(data[0].total_pages);
        setDataAvailable(false);
      }
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  const boxStyles = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    height: "100%",
    gap: "12px",
    gridAutoRows: "1fr",
  };

  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    setOffSet(newPage);
    fetchTaskData(newPage);
  };

  console.log("offset", offset);


  return (
    <>
      <Box sx={{ height: "83vh", overflowY: "hidden" }}>
        <Box style={boxStyles}>
          <Box
            backgroundColor={colors.white[50]}
            display="flex"
            flexDirection="column"
            gridAutoRows="100%"
          >
            <Scheduler fetchTaskData={fetchTaskData} taskData={taskData} />
          </Box>
          <Box
            sx={{ overflowY: "scroll" }}
            backgroundColor={colors.white[50]}
            display="flex"
            flexDirection="column"
            height="100%"
            marginLeft="0px"
          >
            <Grid
              container
              spacing={1}
              alignItems="center"
              sx={{ width: "calc(100%)", padding: "14px", marginLeft: "0px" }}
            >
              <Grid
                item
                xs={12}
                sm={10.2}
                md={10.3}
                style={{ width: "100%", height: "125%" }}
              >
                {dataAvailable ? (
                  <div>
                    <Typography variant="h3" color={colors.black[100]}>
                      No task scheduled yet.
                    </Typography>
                  </div>
                ) : (
                  <>
                    <AllCards
                      taskData={taskData}
                      fetchTaskData={fetchTaskData}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        // position: "sticky",
                        // bottom: 0,
                        width: "115%",
                        // backgroundColor: "red",
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={offset}
                        onChange={handleChangePage}
                        size="small"
                      />
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};

export default PlanningMainPage;
