import { React, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  InputLabel,
  Select,
  FormControl,
  Typography,
  Menu,
  MenuItem,
  List,
  ListItem,
  useTheme,
  InputAdornment,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Avatar,
} from "@mui/material";
import { tokens } from "../../../theme";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import ApiErrorAlert from "../../../ApiErrorAlert";
import SaveAlert from "../../../SaveAlert";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const RecipeSetPoints = ({ farmObject, iotData, prevSetPoints }) => {
  const farmName = farmObject?.farm_name;
  const farmId = farmObject?.farm_id;
  // console.log("prevSetPoints in setpoints file", prevSetPoints);
  const selectedIotDevice = iotData?.find(
    (device) => device?.farm_id_fk === farmId
  );
  const device_id = selectedIotDevice?.id;
  // console.log("deviceeee_id", device_id);
  // const setPoints = JSON.parse(localStorage.getItem("setPoints"));

  const currentSetPoints = prevSetPoints.find(
    (setPoint) => setPoint.farm_id === farmId
  );

  // console.log("currentSetPoints", currentSetPoints);

  // const setPoints = currentSetPoints
  //   ? currentSetPoints
  //   : JSON.parse(localStorage.getItem("setPoints")) || {};

  const localStorageSetPoints =
    JSON.parse(localStorage.getItem("setPoints")) || {};
  const isTrue = localStorageSetPoints?.volume_per_plant !== "";
  const setPoints = isTrue ? localStorageSetPoints : currentSetPoints;

  // console.log("SetPoints", setPoints);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = () => {
    setDialogOpen(true);
  };

  const [showPopup, setShowPopUp] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);

  const handlePopupClose = () => {
    setShowPopUp(false);
  };

  const [newSetPoints, setNewSetPoints] = useState({
    ec: "",
    ph: "",
    total_plants: "",
    volume_per_plant: "",
  });

  const { ec, ph, total_plants, volume_per_plant } = newSetPoints;

  useEffect(() => {
    localStorage.setItem("setPoints", JSON.stringify(newSetPoints));
  }, [newSetPoints]);

  const updateSetPointValues = (fieldName, value) => {
    setNewSetPoints((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNewSetPoints((prevValues) => ({
      ...prevValues,
      farm_id: farmId,
      id: device_id,
    }));
    setDialogOpen(false);
    setApiSuccessOpen(true);
  };

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: "#2c7B55", // Background color of the circle
              width: 20, // Adjust the size of the circle as needed
              height: 20,
            }}
          >
            <Typography
              variant="h7"
              color={colors.white.DEFAULT}
              fontFamily="Lexend Deca"
            >
              1
            </Typography>
          </Avatar>
        </Grid>
        <Grid item>
          <Typography
            variant="h3"
            color={colors.black[200]}
            fontFamily="Lexend Deca"
          >
            Latest Set Points
          </Typography>
        </Grid>
      </Grid>
      <div style={{ margin: "15px" }}>
        <>
          <div style={{ marginTop: "15px" }}>
            <List>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12}>
                  <ListItem
                    style={{
                      marginBottom: "15px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {farmName}
                    </Typography>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <ListItem
                    style={{
                      marginBottom: "15px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      EC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {setPoints?.ec}
                    </Typography>
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ListItem
                    style={{
                      marginBottom: "15px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      pH&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {setPoints?.ph}
                    </Typography>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12}>
                  {" "}
                  <ListItem
                    style={{
                      marginBottom: "15px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 35%, #f5faf8 35%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Number of plants&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {setPoints?.total_plants}
                    </Typography>
                  </ListItem>{" "}
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12}>
                  <ListItem
                    style={{
                      marginBottom: "15px",
                      background:
                        "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 45%, #f5faf8 45%, #f5faf8 100%)",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Volume per plant (in ml)&nbsp;&nbsp;
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      {setPoints?.volume_per_plant}
                    </Typography>
                  </ListItem>
                </Grid>
              </Grid>
            </List>
          </div>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                sx={{ backgroundColor: "#2C7B55" }}
                variant="contained"
                onClick={handleClick}
              >
                <Typography
                  variant="h7"
                  color={colors.white.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Add New Setpoints
                </Typography>
              </Button>
              <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                // fullWidth
                maxWidth="sm"
                // PaperProps={{
                //   style: { minWidth: "300px", minHeight: "400px" },
                // }}
              >
                <DialogTitle>
                  <Typography variant="h3" color={colors.green.DEFAULT}>
                    Enter New Set Points
                  </Typography>
                </DialogTitle>

                <DialogContent>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={12} marginTop={"10px"}>
                        <TextField
                          label="EC"
                          value={ec}
                          size="small"
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (!isNaN(value) && value >= 0 && value <= 5000) {
                              updateSetPointValues("ec", value);
                            } else {
                              updateSetPointValues("ec", "");
                            }
                          }}
                          inputProps={{
                            min: 0,
                            max: 5000,
                          }}
                          fullWidth
                          autoFocus
                          type="number"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} marginTop={"2px"}>
                        <TextField
                          label="pH"
                          value={ph}
                          size="small"
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (!isNaN(value) && value >= 0 && value <= 15) {
                              updateSetPointValues("ph", value);
                            } else {
                              updateSetPointValues("ph", "");
                            }
                          }}
                          inputProps={{
                            min: 0,
                            max: 15
                          }}
                          fullWidth
                          autoFocus
                          type="number"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Total number of plants"
                          value={total_plants}
                          size="small"
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (!isNaN(value) && value >= 0 && value <= 10000) {
                              updateSetPointValues("total_plants", value);
                            } else {
                              updateSetPointValues("total_plants", "");
                            }
                          }}
                          inputProps={{
                            min: 1,
                            max: 10000
                          }}
                          required
                          fullWidth
                          autoFocus
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Volume per unit plant (in ml)"
                          value={volume_per_plant}
                          size="small"
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (!isNaN(value) && value >= 0 && value <= 10000) {
                              updateSetPointValues("volume_per_plant", value);
                            } else {
                              updateSetPointValues("volume_per_plant", "");
                            }
                          }}
                          inputProps={{
                            min: 1,
                            max: 10000
                          }}
                          required
                          fullWidth
                          autoFocus
                          type="number"
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ backgroundColor: colors.green.DEFAULT }}
                      >
                        <Typography variant="h6" color={colors.white.DEFAULT}>
                          Save
                        </Typography>
                      </Button>
                      <Button
                        onClick={() => setDialogOpen(false)}
                        variant="outlined"
                        style={{ marginLeft: "15px" }}
                      >
                        <Typography variant="h6" color={colors.green.DEFAULT}>
                          Cancel
                        </Typography>
                      </Button>
                    </Grid> */}
                    <Grid
                      container
                      spacing={2}
                      alignItems="flex-end"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                        <Stack
                          spacing={1}
                          direction="row"
                          style={{
                            height: "32px",
                            marginTop: "2%",
                            marginRight: "0%",
                            marginLeft: "60%",
                          }}
                        >
                          <Button
                            type="submit" // Added type="submit" to trigger form submission
                            variant="contained"
                            sx={{ backgroundColor: colors.green.DEFAULT }}
                            style={{ float: "left" }}
                          >
                            <Typography
                              variant="h6"
                              color={colors.white.DEFAULT}
                            >
                              Save
                            </Typography>
                          </Button>
                          <Button
                            onClick={() => setDialogOpen(false)}
                            variant="outlined"
                            style={{ float: "left", marginLeft: "15px" }}
                          >
                            <Typography
                              variant="h6"
                              color={colors.green.DEFAULT}
                            >
                              Cancel
                            </Typography>
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
          <SaveAlert
            open={apiSuccessOpen}
            onClose={() => setApiSuccessOpen(false)} // Close the alert
          />
        </>
      </div>
    </>
  );
};

export default RecipeSetPoints;
