import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  useTheme,InfoOutlined, 
  IconButton,
} from "@mui/material";

import { GoAlert } from "react-icons/go";
import { GoAlertFill } from "react-icons/go";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { tokens } from "../WMStheme";
//add latest sku while creating PO 
const AlertDialog = ({
  open,
  onClose,
  title,
  content,
  discardText,
  okayText,
  handleDiscardClick,
  handleOkayClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle sx={{ position: "sticky", height: "3%" }}>
        <Typography variant="h4" color={colors.black[200]}>
          {title}
        </Typography>
        {/* <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,
            color: "grey",
          }}
        >
          <CloseSharpIcon />
        </IconButton> */}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" color={colors.black[200]}>
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDiscardClick}>
          <Typography variant="h7" color={colors.green.DEFAULT}>
            {discardText}
          </Typography>
        </Button>
        <Button
          onClick={handleOkayClick}
          variant="contained"
          sx={{ backgroundColor: colors.green.DEFAULT }}
        >
          <Typography variant="h7" color={colors.white.DEFAULT}>
            {okayText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
// ALERT DIALOG BOX 
const AlertDialogBox = ({
  open,
  onClose,
  title,
  content,
  discardText,
  okayText,
  handleDiscardClick,
  handleOkayClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function formatContentWithLineBreaks(content) {
    // Replace full stops with full stop + line break + spacing
    return content.replace(/\./g, '.<br />');
  }
  return (
    <Dialog open={open} onClose={onClose}  size="md" sx={{ maxWidth: '100%', maxHeight: '100%' }}>
      <DialogTitle sx={{ position: 'sticky', width:'600px', height: '3%' }}>
        <Typography variant="h4" color={colors.black[200]}>
           {title}
         
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 1.9,
            top: 1,
            color: 'grey',
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <GoAlertFill style={{ fontSize: '48px', color: colors.redAccent[500] ,marginBottom:'3%' }} />

        <Typography variant="h5" color={colors.redAccent[500]}  sx={{marginRight:'1%'}}
        dangerouslySetInnerHTML={{ __html: formatContentWithLineBreaks(content) }}
       />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        {/* <Button onClick={handleDiscardClick}>
          <Typography variant="h7" color={colors.green.DEFAULT}>
            {discardText}
          </Typography>
        </Button> */}
        <Button
          onClick={handleOkayClick}
          variant="contained"
          sx={{ backgroundColor: colors.redAccent[500] }}
        >
          <Typography variant="h7" color={colors.white.DEFAULT}>
            {okayText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export {AlertDialog,AlertDialogBox};
