import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  ListItem,
  ListItemText,
  TextField,
  List,
  InputLabel,
  Grid,
  FormControl,
  Box,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { MdCancel } from "react-icons/md";

import Paper from "@mui/material/Paper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { BASE_OMS_URL, API_ENDPOINTS } from "../ApiEndpoint/ApiEndpoint";
import { MaterialReactTable } from "material-react-table";
import ViewListIcon from "@mui/icons-material/ViewList";
import { tokens } from "../../src/theme";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { VscGraph } from "react-icons/vsc";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import TopBarForOMS from "./TobBarForOMS";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../Attendance/PopupComponent/SuccessPopup";
import ErrorPopup from "../Attendance/PopupComponent/ErrorPopup";
import { TbMoodEmpty } from "react-icons/tb";
import { IoIosPerson } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { GiFruitBowl } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { Divider } from "antd";
import { MdCoPresent } from "react-icons/md";
import { HiOutlineIdentification } from "react-icons/hi2";
import { IoLocationOutline } from "react-icons/io5";
import { WiDayCloudy } from "react-icons/wi";
import { FaHourglassEnd } from "react-icons/fa6";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";

import AnalyticsReportPopup from "./AnalyticsReportPopup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import AnalyticsStatContainer from "./AnalyticsStatContainer";
import AnalyticsOrderGraphComponent from "./AnalyticsOrderGraphComponent";
import AnalyticsQuantityGraphComponent from "./AnalyticsQuantityGraphComponent";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_OMS_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const AnalyticsDataPreview = () => {
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  const [orderGraphData, setOrderGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [customerNames, setCustomerNames] = useState([]);
  const [skuNames, setSkuNames] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [skuId, setSkuId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const [dayId, setDayId] = useState(null);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [statData, setStatData] = useState(null);
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [isViewOrderDialogOpen, setIsViewOrderDialogOpen] = useState(false);
  const [dummyOrderData, setDummyOrderData] = useState();
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [offset, setOffSet] = useState(1);

  const handleCloseDialog = () => {
    setIsViewOrderDialogOpen(false);
  };
  const [refreshCount, setRefreshCount] = useState(1);

  const [filterValues, setFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    limit: 11,
    offset: offset,
  });

  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    customer_id: null,
    item_id: null,
    limit: 11,
    offset: offset,
  });
  const [isSKUDialogOpen, setIsSKUDialogOpen] = useState(false);
  const handleSKUDataGridClose = () => {
    setIsSKUDialogOpen(false);
  };

  const handleSubmitClick = () => {
    handleSKUDataGridClose();
  };

  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,
      customer_id: customerId,
      item_id: skuId,
      limit: 11,
      offset: offset,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, customerId, skuId]);

  useEffect(() => {
    fetchCustomerNames();
    fetchSKUNames();
    fetchWeekDays();
  }, []);
  useEffect(() => {
    fetchOrderData(filterValues);
  }, [filterValues]);
  const handleApplyClick = () => {
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      customer_id: temporaryFilterValues.customer_id,
      item_id: temporaryFilterValues.item_id,
      limit: 11,
      offset: 1,
    };
    setStartDate(temporaryFilterValues.start_date);
    setEndDate(temporaryFilterValues.end_date);
    setCustomerId(temporaryFilterValues.customer_id);
    setSkuId(temporaryFilterValues.item_id);
    // setDayId(temporaryFilterValues.day);
    setFilterValues(newFilterValues);

    fetchOrderData(newFilterValues);
    setOrderId(null);
  };

  const fetchCustomerNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CUSTOMER_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      // console.log("data in fetchCustomerNames", data?.data?.data);
      setCustomerNames(data?.data?.data);
    } catch (error) {
      console.log("Error fetching CustomerNames", error);
    }
  };
  const fetchWeekDays = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WEEK_DAYS, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response?.data;
      // console.log("data in fetchWeekDays", data?.data);
      setWeekDays(data?.data);
    } catch (error) {
      console.log("Error fetching WeekDays", error);
    }
  };
  const fetchSKUNames = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_SKU_DATA, {
        params: {
          limit: 1000,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response?.data;
      // console.log("data in fetchSKUNames", data);
      setSkuNames(data);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };
  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
  const fetchOrderData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_ORDER_LIST, {
        params: newParams,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      const data = await response?.data?.data;
      console.log("data in FOR GRID $$$$$$$$$", data);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setOrderGraphData(data);
        setTotalPages(data?.total_pages);
        setDummyOrderData(data?.orders);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleRefreshClick = () => {
    const initialValues = {
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
      limit: 11,
      offset: offset,
    };
    setCustomerId(null);
    setSkuId(null);
    setDayId(null);
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    fetchOrderData(initialValues);
    setOrderId(null);
  };

  const containerStyle = {
    maxHeight: "78vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "67vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const NullScreen = () => {
    const nullScreenContainerStyle = {
      top: 0,
      left: 0,
      display: "flex",
      overflowX: "hidden",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "65vh",
      width: "100%",
      // backgroundColor: "red",
    };
    return (
      <Box style={nullScreenContainerStyle}>
        <TbMoodEmpty
          style={{ fontSize: "50px", color: colors.green.DEFAULT }}
        />
        <Typography variant="h2" color="textPrimary" mt={2}>
          No Data Found
        </Typography>
        {/* <Typography variant="h4" color="textSecondary" mt={1}>
          Please select a farm to intiate fertigation.
        </Typography> */}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
          overflowY: "hidden",
        }}
      >
        <Grid
          container
          alignItems="center"
          sx={{ height: "4vh", overflowY: "hidden" }}
        >
          <Grid item>
            <VscGraph fontSize="large" style={{ color: "#495057" }} />
          </Grid>
          <Grid item>
            <Typography
              variant="h3"
              fontWeight="400"
              fontFamily="Lexend Deca"
              color="#495057"
              style={{ marginLeft: "8px" }}
            >
              Data Preview [ Includes Mobile App & Automated Pipeline ]
            </Typography>
          </Grid>
        </Grid>

        <div style={{ paddingRight: "10px", overflowY: "hidden" }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "10vh" }}
          >
            <Grid item xs={12} sm={9} md={9}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} marginTop={"6px"}>
                  <RangePicker
                    onChange={handleDateChange}
                    // size="large"
                    key={refreshCount}
                    format={dateFormat}
                    defaultValue={[
                      dayjs(formatDate(startDate), dateFormat),
                      dayjs(formatDate(endDate), dateFormat),
                    ]}
                  />
                </Grid>

                <Grid item xs={12} sm={2.5} marginTop={"6px"}>
                  <FormControl fullWidth>
                    <InputLabel>Select Customer</InputLabel>
                    <Select
                      label="Select Customer"
                      value={customerId}
                      onChange={(e) => {
                        setCustomerId(e.target.value);
                      }}
                      displayEmpty
                      size="small"
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Customer" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <IoIosPerson />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {customerNames?.map((customer) => (
                        <MenuItem
                          key={customer.customer_id}
                          value={customer.customer_id}
                        >
                          {customer.customer_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={2.5} marginTop={"6px"}>
                  <FormControl fullWidth>
                    <InputLabel>Select SKU</InputLabel>
                    <Select
                      label="Select SKU"
                      value={skuId}
                      onChange={(e) => {
                        setSkuId(e.target.value);
                      }}
                      size="small"
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select SKU" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <GiFruitBowl />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {skuNames?.map((sku) => (
                        <MenuItem key={sku.item_id} value={sku.item_id}>
                          {sku.item_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} marginTop={"6px"}>
                  <FormControl fullWidth>
                    <InputLabel>Select Day</InputLabel>
                    <Select
                      label="Select Day"
                      value={dayId}
                      onChange={(e) => {
                        setDayId(e.target.value);
                      }}
                      size="small"
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Day" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <CiCalendarDate />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {weekDays?.map((day) => (
                        <MenuItem key={day.value} value={day.value}>
                          {day.day}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12} sm={1} marginTop={"6px"}>
                  <Button
                    variant="contained"
                    startIcon={<TiTickOutline />}
                    sx={{
                      backgroundColor: "#495057",
                      color: "#ffffff",
                    }}
                    size="small"
                    onClick={handleApplyClick}
                    disabled={!startDate || !endDate}
                  >
                    Apply
                  </Button>
                </Grid>

                <Grid item xs={12} sm={1} marginTop={"6px"}>
                  <Button
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    size="small"
                    sx={{
                      backgroundColor: "#495057",
                      color: "#ffffff",
                    }}
                    onClick={handleRefreshClick}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={containerStyle}>
            {isLoading ? (
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={isLoading} />
              </div>
            ) : orderGraphData?.length === 0 ? (
              <NullScreen />
            ) : (
              <div style={{ flexGrow: 1, overflowY: "hidden" }}>
                <AnalyticsDataGrid
                  dummyOrderData={dummyOrderData}
                  setDummyOrderData={setDummyOrderData}
                  offset={offset}
                  setTotalPages={setTotalPages}
                  setOffSet={setOffSet}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  orderId={orderId}
                  setOrderId={setOrderId}
                  setErrorMessage={setErrorMessage}
                  totalPages={totalPages}
                  setErrorPopupOpen={setErrorPopupOpen}
                  formatDate={formatDate}
                  colors={colors}
                />
              </div>
            )}
          </Grid>
        </div>
      </Box>

      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default AnalyticsDataPreview;
const AnalyticsDataGrid = ({
  dummyOrderData,
  setDummyOrderData,
  offset,
  setTotalPages,
  setOffSet,
  totalPages,
  filterValues,
  setFilterValues,
  orderId,
  setOrderId,
  setErrorMessage,
  setErrorPopupOpen,
  formatDate,
  colors,
}) => {
  const [totalItemInDialog, setTotalItemInDialog] = useState(1);
  const [isViewOrderDialogOpen, setIsViewOrderDialogOpen] = useState(false);
  const [OrderData, setOrderData] = useState([]);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [statData, setStatData] = useState(null);
  const [offsetDialog, setOffSetDialog] = useState(1);
  const isDialogOpenedRef = useRef(false);

  const formatDateIST = (epochTimestamp) => {
    // const epochTimestampAsNumber = parseInt(epochTimestamp, 10);
    // const epochTimestamp1 = epochTimestampAsNumber + 19800000;
    // console.log("ersdtfhuij", epochTimestamp);
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Adjust for IST (UTC+5:30)

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleViewOrderDialogClose = () => {
    setIsViewOrderDialogOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    // Reset the dialog state when changing the page
    setIsViewOrderDialogOpen(false);
    setOrderId(null);
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    //  setIsViewOrderDialogOpen(false);
  };
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#fff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#495057",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#495057", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const fetchAndOpenDialog = async () => {
    setIsLoadingOrder(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_OMS_ORDER_DETAILS,
        {
          params: {
            order_id: orderId,
            limit: 1000,
            offset: 1,
          },
        }
      );
      setIsLoadingOrder(false);
      const data = response?.data;

      if (response?.data) {
        setOrderData(data);
        setIsViewOrderDialogOpen(true); // Open dialog after fetching data
        // Set the ref to true after opening the dialog
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoadingOrder(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };
  useEffect(() => {
    if (!isViewOrderDialogOpen && orderId) {
      fetchAndOpenDialog();
    }
  }, [orderId]);
  const openOrderDialog = async (orderId) => {
    // Reset the dialog state when opening the dialog
    setIsViewOrderDialogOpen(false);

    await setOrderId(orderId);
    //  setIsViewOrderDialogOpen(true);
  };
  const handleClick = (cell) => {
    setIsViewOrderDialogOpen(false);
    console.log("Before setting order ID");
    // setOrderId(cell.row.original?.order_id);
    console.log(
      "After setting order ID, before opening dialog",
      isViewOrderDialogOpen
    );
    openOrderDialog(cell.row.original?.order_id);
  };

  const columns = useMemo(
    () => [
      {
        header: "Order Items",
        size: 2,
        enableEditing: false,
        Cell: ({ cell }) => (
          <div className="tooltip-cell" title="Click to view Order Items">
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#495057",
                cursor: isViewOrderDialogOpen ? "not-allowed" : "pointer", // Set cursor to not-allowed when isViewOrderDialogOpen is true
              }}
              onClick={() => !isViewOrderDialogOpen && handleClick(cell)}
            >
              <Typography variant="h7" color={colors.white.DEFAULT}>
                View
              </Typography>
            </Button>
            <span className="farm-name">{cell.getValue()}</span>
          </div>
        ),
      },
      {
        header: "Customer Name",
        accessorKey: "customer_name",

        Cell: ({ row }) => {
          const customer_name = row?.original?.customer_name;

          return (
            <span>
              {customer_name ? capitalizeFirstLetter(customer_name) : "-"}
            </span>
          );
        },
      },
      {
        header: "Order Date",
        accessorKey: "order_date",
        size: 10,
        Cell: ({ row }) => {
          const order_date = row?.original?.order_date;

          return <span>{order_date ? formatDate(order_date) : "-"}</span>;
        },
      },
      {
        header: "Created Date",
        accessorKey: "created_at",
        size: 10,
        Cell: ({ row }) => {
          const created_at = row?.original?.created_at;

          return <span>{created_at ? formatDateIST(created_at) : "-"}</span>;
        },
      },
      {
        header: "Order No.",
        accessorKey: "order_no",
        size: 20,
      },
      {
        header: "Bill Amount",
        accessorKey: "bill_amount",
        size: 10,
      },
      {
        header: "Channel",
        accessorKey: "channel",
        size: 10,
      },
    ],
    [orderId]
  );

  return (
    <>
      <Grid
        sx={{
          marginLeft: "1.2%",
          overflowY: "hidden",
          height: "73vh",
        }}
      >
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={dummyOrderData}
            enableGrouping
            // enableRowVirtualization
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enablePagination={false}
            enableColumnFilters={false}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0 },
            }}
            muiTableHeadCellProps={{
              sx: {
                fontWeight: "650",
                color: "#495057",
                fontSize: "15px",
              },
            }}
            muiTableBodyProps={{
              sx: {
                overflowY: "hidden",
                "& tr:": {
                  backgroundColor: "#ffffff",
                },
              },
            }}
            muiToolbarAlertBannerChipProps={{ color: "primary" }}
          />
        </ThemeProvider>
      </Grid>
      <Grid container justifyContent="flex-end">
        {dummyOrderData?.length > 0 && (
          <Pagination
            count={totalPages}
            page={offset}
            onChange={handleChangePage}
            style={{ color: "#A8A9AA" }}
          />
        )}
      </Grid>

      <OrderDialog
        isViewOrderDialogOpen={isViewOrderDialogOpen}
        orderData={OrderData}
        handleViewOrderDialogClose={handleViewOrderDialogClose}
        setIsViewOrderDialogOpen={setIsViewOrderDialogOpen}
      />
    </>
  );
};

const OrderDialog = ({
  isViewOrderDialogOpen,
  orderData,
  handleViewOrderDialogClose,
  setIsViewOrderDialogOpen,
}) => {
  const newData = [orderData];
  const dialogRef = useRef(null);

  const handleBackdropClick = (event) => {
    // Check if the click event is on the dialog's backdrop and not on the dialog content
    if (dialogRef.current === event.target) {
      handleViewOrderDialogClose();
    }
  };
  console.log("newData--------------", newData);

  return (
    <Dialog
      open={isViewOrderDialogOpen}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={handleViewOrderDialogClose}
      maxWidth="md"
      ref={dialogRef}
      onClick={handleBackdropClick}
    >
      <DialogTitle>
        <Typography variant="h2" style={{ marginLeft: "0.1%" }} color="#0C0C0C">
          View Order Items
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setIsViewOrderDialogOpen(false)}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,

            color: "red",
          }}
        >
          <MdCancel />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {newData ? (
          newData?.length > 0 &&
          newData?.map((order, index) => (
            <div key={index}>
              <List>
                <ListItem style={{ marginLeft: "-3%" }}>
                  <ListItemText
                    primary={`Order For: ${order[0]?.customer_name} `}
                  />
                </ListItem>
                {order?.map((orderItem, idx) => (
                  <div key={idx}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      display="flex"
                      key={index}
                      style={{ marginTop: "2%" }}
                    >
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Item Name"
                          fullWidth
                          variant="standard"
                          size="small"
                          margin="normal"
                          value={orderItem?.item_name}
                          placeholder="SKU Name"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={1.3}
                        md={1.3}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="UOM"
                          fullWidth
                          variant="standard"
                          size="small"
                          margin="normal"
                          value={orderItem?.uom}
                          placeholder="UOM"
                        />
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={1.2}
                        md={1.2}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="SKU Code"
                          fullWidth
                          variant="standard"
                          size="small"
                          margin="normal"
                          value={orderItem?.item_code}
                          placeholder="SKU Code"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        md={1}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="MIS"
                          fullWidth
                          variant="standard"
                          disabled
                          size="small"
                          margin="normal"
                          value={orderItem?.mis}
                          placeholder="MIS"
                        />
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        sm={1.5}
                        md={1.5}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Quantity"
                          fullWidth
                          variant="standard"
                          disabled
                          size="small"
                          margin="normal"
                          value={orderItem?.qty}
                          placeholder="Quantity"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={1.5}
                        md={1.5}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Rate"
                          fullWidth
                          variant="standard"
                          disabled
                          size="small"
                          type="number"
                          margin="normal"
                          value={orderItem?.rate}
                          placeholder="Rate"
                          inputProps={{
                            style: { width: "100%" },
                            min: 0,
                            max: 100000,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={1.2}
                        md={1.2}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Status"
                          fullWidth
                          variant="standard"
                          size="small"
                          margin="normal"
                          value={orderItem?.status}
                          placeholder="Status"
                          InputProps={{
                            style: {
                              color:
                                orderItem?.status === "confirm"
                                  ? "green"
                                  : orderItem?.status === "pending"
                                  ? "red"
                                  : "inherit",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </List>
              <Divider
                sx={{
                  borderBottom: "2px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />
            </div>
          ))
        ) : (
          <NullScreen />
        )}

        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          justifyContent="flex-start"
        >
          {/* <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
            <Stack
              spacing={1}
              direction="row"
              style={{
                height: "32px",
                marginRight: "0%",
                marginLeft: "84%",
              }}
            >
              <Button
                onClick={handleViewOrderDialogClose}
                variant="outlined"
                style={{
                  float: "left",
                  marginLeft: "15px",
                  borderColor: "#495057",
                }}
              >
                <Typography variant="h6" color="#495057">
                  Cancel
                </Typography>
              </Button>
            </Stack>
          </Grid> */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
