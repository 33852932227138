import React, { useState, useEffect } from "react";
import AgricultureRoundedIcon from "@mui/icons-material/AgricultureRounded";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineCloudUpload } from "react-icons/ai";
import ApiErrorAlert from "../../../../../ApiErrorAlert";
import SaveAlert from "../../../../../SaveAlert";
import {
  BASE_URL,
  API_ENDPOINTS,
} from "../../../../../ApiEndpoint/ApiEndpoint";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Stack,
  InputAdornment,
  Select,
  InputLabel,
  FormControl,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import { date } from "yup";
import { Tooltip } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const ActionHarvesting = ({ rows, handlePageRefresh }) => {
  const [open, setOpen] = useState(false);
  const [gradesName, setGradesName] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MAX_PLANTS = rows?.original?.no_of_plant;
  console.log(
    "rows.original in ActionHarvesting---------------",
    rows?.original
  );

  const [formData, setFormData] = useState({
    farm_name: rows?.original?.farm_name,
    farm_zone: rows?.original?.zone_name,
    batch: rows?.original?.batch,
    no_of_plants: MAX_PLANTS,
    date_of_harvest: "",
    crop_type: rows?.original?.crop_type,
    exp_date_of_harvest: rows?.original?.exp_date_of_harvest,
    crop_name: rows?.original?.crop_name,
    crop_variety_name: rows?.original?.crop_variety_name,
    id: rows?.original?.id,
    old_no_of_plant: rows?.original?.no_of_plant,
    selected_file: "",
    harvest_grade: rows?.original?.harvest_grade,
    date_of_sow: rows?.original?.date_of_sow,
    farm_id: rows?.original?.farm_id_fk,
    crop_id: rows?.original?.crop_id_fk,
    crop_variety_id: rows?.original?.crop_variety_id_fk,
    farm_zone_id: rows?.original?.farm_zone_id_fk,
    action: "Harvesting",
    harvest_type: "Complete",
  });

  useEffect(() => {
    setFormData({
      farm_name: rows?.original?.farm_name,
      farm_zone: rows?.original?.zone_name,
      batch: rows?.original?.batch,
      no_of_plants: MAX_PLANTS,
      date_of_harvest: "",
      crop_type: rows?.original?.crop_type,
      exp_date_of_harvest: rows?.original?.exp_date_of_harvest,
      crop_name: rows?.original?.crop_name,
      crop_variety_name: rows?.original?.crop_variety_name,
      id: rows?.original?.id,
      old_no_of_plant: rows?.original?.no_of_plant,
      selected_file: "",
      harvest_grade: rows?.original?.harvest_grade,
      date_of_sow: rows?.original?.date_of_sow,
      farm_id: rows?.original?.farm_id_fk,
      crop_id: rows?.original?.crop_id_fk,
      crop_variety_id: rows?.original?.crop_variety_id_fk,
      farm_zone_id: rows?.original?.farm_zone_id_fk,
      action: "Harvesting",
      harvest_type: "Complete",
    });
  }, [rows]);

  console.log("formData in ActionHarvesting---------------", formData);

  useEffect(() => {
    fetchGradesOptions();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchGradesOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_GRADESNAME,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setGradesName(options);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const updatedFormData = {
        ...formData,
        grades: JSON.stringify(grades),
      };
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_CROPMANAGEMENT_HARVEST,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("responseeeeeeeeee", response);
      if (response.status >= 200 && response.status < 305) {
        console.log("Inventory harvesting record created successfully");
        setOpen(false);
        if (typeof handlePageRefresh === "function") {
          handlePageRefresh();
        }
      } else {
        console.log("Failed to create inventory harvesting record");
      }
    } catch (error) {
      console.log(
        "An error occurred while creating the inventory harvesting record",
        error
      );
    }
    setOpen(false);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    const parsedValue = name === "no_of_plants" ? parseInt(value, 10) : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };

  const initialGrade = { name: "", numberOfPlants: 0, weight: 0, price: 0 };
  const [grades, setGrades] = useState([initialGrade]);

  const handleDeleteGrades = (index) => {
    const updatedGrades = [...grades];
    updatedGrades.splice(index, 1);
    setGrades(updatedGrades);
  };

  const addNewGrades = () => {
    setGrades([...grades, initialGrade]);
  };

  const handleGradeChange = (index, field, value) => {
    const updatedGrades = [...grades];
    updatedGrades[index][field] =
      field === "numberOfPlants" || field === "weight" || field === "price"
        ? parseInt(value, 10)
        : value;
    setGrades(updatedGrades);
  };

  useEffect(() => {
    console.log("grades--------", grades);
  }, [grades]);

  return (
    <div>
      <button
        onClick={handleClickOpen}
        style={{
          borderRadius: "12px",
          backgroundColor: "white",
          border: "0.5px solid #7d7d7d",
          width: "80px",
          display: "flex",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AgricultureRoundedIcon sx={{ fontSize: 16 }} />
        Harvesting
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="40%"
        maxHeight="100px"
      >
        <DialogTitle>
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Harvesting Inventory
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={9} sm={2}>
                <TextField
                  label="Farm Name"
                  name="farm_name"
                  value={formData?.farm_name}
                  readOnly
                  required
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="Zone Name"
                  name="farm_zone"
                  value={formData?.farm_zone}
                  readOnly
                  required
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="Batch Id"
                  name="batch"
                  value={formData?.batch}
                  readOnly
                  fullWidth
                  size="small"
                  required
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="Crop Type"
                  name="crop_type"
                  value={formData?.crop_type}
                  readOnly
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="No. of Plants"
                  name="no_of_plants"
                  type="number"
                  value={formData?.no_of_plants}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: MAX_PLANTS,
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="Weight(Kg) "
                  name="harvest_weight"
                  type="number"
                  value={formData?.harvest_weight}
                  onChange={handleFormChange}
                 
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    pattern: "^[0-9]+(\\.[0-9]{1,2})?$", // matches 1 or 2 decimal places
                    max: 9999,
                    
                    style: { width: "100%" },
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="DOH"
                  name="date_of_harvest"
                  type="date"
                  size="small"
                  value={formData?.date_of_harvest}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    
                    style: { width: "100%" },
                  }}
                />
              </Grid>

              <Grid item xs={9} sm={2}>
                <TextField
                  label="Start Location"
                  name="starting_location"
                  value={formData?.starting_location}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                  size="small"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={2}>
                <TextField
                  label="End Location"
                  name="ending_location"
                  value={formData?.ending_location}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                  size="small"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              {/* <Grid item xs={9} sm={2} style={{ marginTop: "9px" }}>
                <FormControl fullWidth>
                  <InputLabel id="Harvesting Type" style={{ marginTop: "-2%" }}>
                    Harvesting Type*
                  </InputLabel>
                  <Select
                    labelId="Harvesting Type"
                    id="Harvesting Type"
                    label="Harvesting Type"
                    required
                    size="small"
                    value={formData?.harvest_type}
                    name="harvest_type"
                    onChange={handleFormChange}
                  >
                    <MenuItem value="Partial">Partial</MenuItem>
                    <MenuItem value="Complete">Complete</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={9} sm={4}>
                <TextField
                  label="Note"
                  name="note"
                  value={formData?.note}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ marginTop: "1%" }}>
                <div
                  style={{
                    border: "1px solid #ccc",
                    padding: "2px",
                    borderRadius: "6px",
                  }}
                >
                  <Typography
                    variant="h5"
                    color={colors.black[200]}
                    style={{
                      width: "100%",
                      marginTop: "1%",
                      marginLeft: "3%",
                    }}
                  >
                    Grades:
                  </Typography>
                  <table
                    style={{
                      width: "100%",
                      marginTop: "2%",
                      marginLeft: "3%",
                    }}
                  >
                    <tbody style={{ marginTop: "-7%" }}>
                      <tr style={{ marginTop: "-35%" }}>
                        {grades.map((grade, index) => (
                          <Grid
                            container
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            display="flex"
                            key={index}
                            style={{ marginTop: "1%" }}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              style={{ marginTop: "-4%" }}
                            >
                              <TextField
                                select
                                label="Name"
                                fullWidth
                                displayEmpty
                                variant="standard"
                                value={grade.name}
                                onChange={(e) =>
                                  handleGradeChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: "140px",
                                        overflowY: "auto",
                                      },
                                    },
                                  },
                                }}
                                inputProps={{
                                  style: { width: "100%" },
                                  "aria-label": "Select Grades",
                                }}
                                startAdornment={
                                  <InputAdornment position="start"></InputAdornment>
                                }
                              >
                                {gradesName?.map((gradesOption, index) => (
                                  <MenuItem key={index} value={gradesOption}>
                                    {gradesOption}
                                  </MenuItem>
                                ))}
                                <MenuItem disabled value="">
                                  Select Grades
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              md={3}
                              style={{ marginTop: "-5%" }}
                            >
                              <TextField
                                label="No. of Plants"
                                fullWidth
                                variant="standard"
                                size="small"
                                type="number"
                                margin="normal"
                                value={grade.numberOfPlants}
                                onChange={(e) =>
                                  handleGradeChange(
                                    index,
                                    "numberOfPlants",
                                    e.target.value
                                  )
                                }
                                placeholder="No. of Plants"
                                inputProps={{
                                  style: { width: "100%" },
                                  min: 0,
                                  max: 100000,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              md={3}
                              style={{ marginTop: "-5%" }}
                            >
                              <TextField
                                label="Weight(Kg)"
                                fullWidth
                                variant="standard"
                                size="small"
                                type="number"
                                margin="normal"
                                value={grade.weight}
                                onChange={(e) =>
                                  handleGradeChange(
                                    index,
                                    "weight",
                                    e.target.value
                                  )
                                }
                                placeholder="Weight(Kg)"
                                inputProps={{
                                  style: { width: "100%" },
                                  min: 0,
                                  max: 100000,
                                }}
                              />
                            </Grid>
                            <IconButton
                              style={{ border: "none", marginTop: "12px" }}
                              onClick={() => handleDeleteGrades(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        ))}
                      </tr>
                      <Divider
                        style={{
                          margin: "1px -1px 4px 7px",
                          width: "5%",
                          color: "colors.black[100]",
                        }}
                      />

                      <div
                        style={{
                          width: "100%",
                          marginTop: "1%",
                          marginLeft: "-1%",
                        }}
                      >
                        {/* <Button
                          startIcon={
                            <AddCircleOutlineOutlinedIcon
                              style={{ color: colors.green.DEFAULT }}
                            />
                          }
                          onClick={addNewGrades}
                          disabled={grades?.length===gradesName?.length}
                        >
                          <Typography variant="h6" color={colors.green.DEFAULT}>
                            Add more grades
                          </Typography>
                        </Button> */}
                        <Button
                          startIcon={
                            <AddCircleOutlineOutlinedIcon
                              style={{
                                color:
                                  grades?.length === gradesName?.length
                                    ? "grey"
                                    : colors.green.DEFAULT,
                              }}
                            />
                          }
                          onClick={addNewGrades}
                          disabled={grades?.length === gradesName?.length}
                        >
                          <Typography
                            variant="h6"
                            color={
                              grades?.length === gradesName?.length
                                ? "grey"
                                : colors.green.DEFAULT
                            }
                          >
                            Add more grades
                          </Typography>
                        </Button>
                      </div>
                    </tbody>
                  </table>
                </div>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    style={{
                      height: "30px",
                      marginTop: "2%",
                      marginLeft: "86%",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ backgroundColor: colors.green.DEFAULT }}
                    >
                      <Typography variant="h7" color={colors.white.DEFAULT}>
                        Submit
                      </Typography>
                    </Button>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      style={{ marginLeft: "15px" }}
                    >
                      <Typography variant="h7" color={colors.green.DEFAULT}>
                        Cancel
                      </Typography>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ActionHarvesting;
