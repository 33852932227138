import React, { useState, useEffect } from "react";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const ApiErrorAlert = ({ open, onClose }) => {
  const [isVisible, setIsVisible] = useState(open);

  useEffect(() => {
    setIsVisible(open);
  }, [open]);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    <Slide direction="left" in={isVisible}>
      <Alert
        severity="error"
        onClose={onClose}
        style={{
          position: "fixed",
          top: "8px",
          right: isVisible ? "2px" : "-100%",
          zIndex: 1000,
          transition: "right 0.3s ease-in-out",
        }}
      >
        <AlertTitle>Error</AlertTitle>
        {"Something went wrong, please try again later"}
      </Alert>
    </Slide>
  );
};

export default ApiErrorAlert;

// Import
// import ApiErrorAlert from "../../ApiErrorAlert";

// Create state
// const [apiErrorOpen, setApiErrorOpen] = useState(false);

// set state to true in catch dialog
// setApiErrorOpen(true);

// To display error message 
//  <ApiErrorAlert
//  open={apiErrorOpen}
//  onClose={() => setApiErrorOpen(false)} // Close the alert
// />
