import Button from "react-bootstrap/Button";
import React, { useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import { Typography, useTheme, Divider } from "@mui/material";
import { tokens } from "../WMStheme";
import SKUGif from "../../Assets/WMSImage/SKU1.gif";
import VendorImg from "../../Assets/WMSImage/Vendor4.gif";
import CustomerImg from "../../Assets/WMSImage/customer4.gif";
import { BsShop } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IoIosPerson } from "react-icons/io";
import { setFamilyAction } from "../actions/familyAction";
import { FaWarehouse } from "react-icons/fa6";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
// import CustomerConfig from "../WMSConfiguration/Customer/CustomerConfig";
import VendorConfig from "../WMSConfiguration/Vendor/VendorConfig";
import SKUConfig from "../WMSConfiguration/SKU/SKUConfig";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PostAddIcon from "@mui/icons-material/PostAdd";
import viewcrop from "../../Assets/ConfigurationImg/view.png";
import { PiTruckFill } from "react-icons/pi";
import { TbPackages } from "react-icons/tb";
import { setWarehouseAction } from "../actions/warehouseAction";
import { setSkuAction } from "../actions/skuAction";
import { setVendorAction } from "../actions/vendorActions";
import { setProductAction } from "../actions/productActions";
import { GiFruitBowl } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { setTransportAction } from "../actions/transportAction";
import { IoIosPeople } from "react-icons/io";
import { setCustomerGroupAction } from "../actions/customerGroupAction";
import { FaPeopleLine } from "react-icons/fa6";
import { GiFamilyTree } from "react-icons/gi";
import { setCustomerAction } from "../actions/customerActions";
import { FaPeopleCarry } from "react-icons/fa";
import { MdOutlinePeople } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import Farmicon from "../../Assets/ConfigurationImg/field.png";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  Dialog,
  DialogTitle,
  Stack,
  DialogContent,
  IconButton,
} from "@mui/material";
function WMSConfigMainPage() {
  console.log("On WMS Home Page");
  
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [showFarmForm, setShowFarmForm] = useState(false);
  const [showCropForm, setShowCropForm] = useState(false);
  const [showFarmerForm, setShowFarmerForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFarmButtonClick = () => {
    dispatch(setCustomerAction("add", null));
    navigate("/addCustomer");
    //setShowFarmForm(true);
  };
  const handleCropButtonClick = () => {
    dispatch(setVendorAction("add", null));
    navigate("/addVendor");
  };
  const handleFarmerButtonClick = () => {
    setShowFarmerForm(true);
  };
  const handleViewButtonClick = () => {
    navigate("/viewCustomer");
  };
  const handleProductViewButtonClick = () => {
    navigate("/viewProduct");
  };
  const handleProductButtonClick = () => {
    dispatch(setProductAction("add", null));
    navigate("/addProduct");
  };
  const handleFamilyViewButtonClick = () => {
    navigate("/viewFamily");
  };
  const handleFamilyButtonClick = () => {
    dispatch(setFamilyAction("add", null));
    navigate("/addFamily");
  };
  const handleTransportViewButtonClick = () => {
    navigate("/viewTransport");
  };
  const handleTransportButtonClick = () => {
    dispatch(setTransportAction("add", null));
    navigate("/addTransport");
  };
  const handleVendorViewButtonClick = () => {
    navigate("/viewVendor");
  };
  const handleCustomerGroupViewButtonClick = () => {
    navigate("/viewCustomerGroup");
  };
  const handleCustomerGroupButtonClick = () => {
    dispatch(setCustomerGroupAction("add", null));
    navigate("/addCustomerGroup");
  };
  const handleFarmerViewButtonClick = () => {
    navigate("/viewSKU");
  };
  const handleWarehouseButtonClick = () => {
    dispatch(setWarehouseAction("add", null));
    navigate("/addWarehouse");
  };

  const handleSKUButtonClick = () => {
    dispatch(setSkuAction("add", null));

    navigate("/addSku");
  };
  const handleWarehouseViewButtonClick = () => {
    navigate("/viewWarehouse");
  };
  const [fahover, setFaHover] = useState(true);
  const [fahover1, setFaHover1] = useState(true);
  const [fahover2, setFaHover2] = useState(true);

  const farmhandleHover = () => {
    setFaHover(!fahover);
  };

  const farmhandleHover1 = () => {
    setFaHover1(!fahover1);
  };
  const [hover, setHover] = useState(true);
  const [hover1, setHover1] = useState(true);

  const crophandleHover = () => {
    setHover(!hover);
  };
  const crophandleHover1 = () => {
    setHover1(!hover1);
  };

  const [fhover, setFHover] = useState(true);
  const [fhover1, setFHover1] = useState(true);

  const farmerhandleHover = () => {
    setFHover(!fhover);
  };
  const farmerhandleHover1 = () => {
    setFHover1(!fhover1);
  };
  const dialogRef = useRef(null);
  const handleBackdropClick = (event) => {
    // Check if the click event is on the dialog's backdrop and not on the dialog content
    if (dialogRef.current === event.target) {
      setShowFarmForm(false);
    }
  };
  const handleClickableDivClick = () => {
    // Handle the click logic
    handleVendorViewButtonClick(); // Example function call, you can replace it with your logic
  };

  return (
    <div>
      <div
        className="box-content"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "45px",
          marginTop: "1%",
        }}
      >
        <Typography
          variant="h1"
          color={colors.green.DEFAULT}
          style={{
            marginLeft: "8%",
          }}
        >
          Configuration
        </Typography>
      </div>

      <div className="row" style={{ marginLeft: "5%", marginRight: "1%" }}>
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem" ,marginLeft: "2%", marginRight: "2%"}}
        >
          <Card
            style={{
              width: "100%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                 
                  <GiFruitBowl
                    style={{ width: "100px", height: "59px", color: "#34519fe6" }}
                  />
                  <Typography
                    variant="h5"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "13px" }}
                  >
                    SKU
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
              {userDetail?.b_config_view_sku  && (
                <Stack
                  variant="success"
                  onClick={handleFarmerViewButtonClick}
                  style={{ backgroundColor: "white" }}
                  
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <img
                      src={viewcrop}
                      alt="Farm view"
                      style={{ height: "15px", width: "18px", cursor:'pointer' }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" , cursor:'pointer' }}
                      >
                        View SKU's  
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                  )}
                  {userDetail?.b_config_view_sku  && (
                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                />
                  )}
                {userDetail?.b_config_add_sku && (
                
                <Stack
                  variant="success"
                  onClick={handleSKUButtonClick}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <PostAddIcon
                      fontSize="small"
                      style={{ color: "#000000", cursor:'pointer' }}
                    />
                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" , cursor:'pointer'}}
                      >
                        Add SKU
                      </Typography>
                    )}
                  </Stack>
                </Stack>
               )}
              </div>

              <Dialog
                open={showFarmerForm}
                onClose={() => setShowFarmerForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                onClick={handleBackdropClick}
              >
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowFarmerForm(false)}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                  <SKUConfig />
                </DialogContent>
              </Dialog>
            </Card.Body>
          </Card>
        </div>
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem" ,marginLeft: "2%", marginRight: "2%"}}
        >
          <Card
            style={{
              width: "100%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <TbPackages
                    style={{ height: "59px", width: "100px", color: "#34519fe6" }}
                  />
                  <Typography
                    variant="h2"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "13px" }}
                  >
                    Product
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
              {userDetail?.b_config_view_product  && (
                <Stack
                  variant="success"
                  onClick={handleProductViewButtonClick}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    // alignItems="center" // Align items in the center horizontally
                  >
                    <img
                      src={viewcrop}
                      alt="Farm view"
                      style={{ height: "15px", width: "18px", cursor:'pointer' }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" , cursor:'pointer'}}
                      >
                        View Product
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                  )}
                  {userDetail?.b_config_view_product && (
                    <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                />  )}
                {userDetail?.b_config_add_product  && (
                
                <Stack
                  variant="success"
                  onClick={handleProductButtonClick}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <ManageAccountsIcon
                      fontSize="small"
                      style={{ color: "#000000", cursor:'pointer' }}
                    />

                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px"  , cursor:'pointer' }}
                      >
                        Add Product
                      </Typography>
                    )}
                  </Stack>
                </Stack> )}
              </div>

              <Dialog
                open={showCropForm}
                onClose={() => setShowCropForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                onClick={handleBackdropClick}
              >
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowCropForm(false)}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                  <VendorConfig />
                </DialogContent>
              </Dialog>
            </Card.Body>
          </Card>
        </div>

        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem" ,marginLeft: "2%", marginRight: "2%"}}
        >
          <Card
            style={{
              width: "100%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <GiFamilyTree
                    style={{ height: "59px", width: "100px", color: "#34519fe6" }}
                  />
                  <Typography
                    variant="h2"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "13px" }}
                  >
                    Family
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
              {userDetail?.b_config_view_family   && (
                <Stack
                  variant="success"
                  onClick={handleFamilyViewButtonClick}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    // alignItems="center" // Align items in the center horizontally
                  >
                    <img
                      src={viewcrop}
                      alt="Farm view"
                      style={{ height: "15px", width: "18px", cursor:'pointer' }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px", cursor:'pointer' }}
                      >
                        View Family
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                  )}
                  {userDetail?.b_config_view_family   && (
                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                /> )}
                {userDetail?.b_config_add_family   && (
                <Stack
                  variant="success"
                  onClick={handleFamilyButtonClick}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <GiFamilyTree
                      fontSize="small"
                      style={{ color: "#000000", cursor:'pointer' }}
                    />

                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px", cursor:'pointer' }}
                      >
                        Add Family
                      </Typography>
                    )}
                  </Stack>
                </Stack> )}
              </div>

              <Dialog
                open={showCropForm}
                onClose={() => setShowCropForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                onClick={handleBackdropClick}
              >
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowCropForm(false)}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                  <VendorConfig />
                </DialogContent>
              </Dialog>
            </Card.Body>
          </Card>
        </div>

        {/* <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem" }}
        >
          <Card
            style={{
              width: "100%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <FaWarehouse
                    style={{ height: "59px", width: "100px", color: "#34519fe6" }}
                  />
                  <Typography
                    variant="h2"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "13px" }}
                  >
                    Warehouse
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
                <Stack
                  variant="success"
                  onClick={handleWarehouseViewButtonClick}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    // alignItems="center" // Align items in the center horizontally
                  >
                    <img
                      src={viewcrop}
                      alt="Warehouse view"
                      style={{ height: "15px", width: "18px" }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" }}
                      >
                        View Warehouse
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                />
                <Stack
                  variant="success"
                  onClick={handleWarehouseButtonClick}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <FaWarehouse
                      fontSize="small"
                      style={{ color: "#000000" }}
                    />

                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" }}
                      >
                        Add Warehouse
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </div>

              <Dialog
                open={showCropForm}
                onClose={() => setShowCropForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                onClick={handleBackdropClick}
              >
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowCropForm(false)}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                  <VendorConfig />
                </DialogContent>
              </Dialog>
            </Card.Body>
          </Card>
        </div> */}

        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem" ,marginTop:"2%",marginLeft: "2%", marginRight: "2%"}}
        >
          <Card
            style={{
              width: "100%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <BsShop
                    style={{ height: "59px", width: "100px", color: "#34519fe6" }}
                  />
                  <Typography
                    variant="h2"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "13px" }}
                  >
                    Vendor
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
              {userDetail?.b_config_view_vendor    && (
                <Stack
                  variant="success"
                  onClick={handleVendorViewButtonClick}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    // alignItems="center" // Align items in the center horizontally
                  >
                    <img
                      src={viewcrop}
                      alt="Farm view"
                      style={{ height: "15px", width: "18px", cursor:'pointer' }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px", cursor:'pointer' }}
                      >
                        View Vendors
                      </Typography>
                    )}
                  </Stack>
                </Stack> )}
                {userDetail?.b_config_view_vendor    && (
                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                /> )}
                {userDetail?.b_config_add_vendor    && (
                <Stack
                  variant="success"
                  onClick={handleCropButtonClick}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <BsShop fontSize="small" style={{ color: "#000000", cursor:'pointer' }} />

                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px", cursor:'pointer' }}
                      >
                        Add Vendor
                      </Typography>
                    )}
                  </Stack>
                </Stack> )}
              </div>

              <Dialog
                open={showCropForm}
                onClose={() => setShowCropForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                onClick={handleBackdropClick}
              >
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowCropForm(false)}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                  <VendorConfig />
                </DialogContent>
              </Dialog>
            </Card.Body>
          </Card>
        </div>
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem",marginTop:"2%" ,marginLeft: "2%", marginRight: "2%"}}
        >
          <Card
            style={{
              width: "100%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  {/* Add your icon component here */}
                  <IoIosPerson
                    style={{ width: "100px", height: "59px", color: "#34519fe6", cursor:'pointer' }}
                  />
                  <Typography
                    variant="h2"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "13px" }}
                  >
                    Customer
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content">
              {userDetail?.b_config_view_customer     && (
                <Stack
                  variant="success"
                  onClick={handleViewButtonClick}
                  style={{ backgroundColor: "white",marginLeft: "8%" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    //alignItems="center" 
                    // alignItems="center" // Align items in the center horizontally
                  >
                    <img
                      src={viewcrop}
                      alt="Farm view"
                      style={{ height: "15px", width: "18px" , cursor:'pointer'}}
                    />
                    {fahover && (
                      <Typography
                      className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" , cursor:'pointer'}}
                      >
                        View Customer
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                  )}
                  {userDetail?.b_config_view_customer     && (
                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                />
                )}
                {userDetail?.b_config_add_customer     && (
                <Stack
                  variant="success"
                  onClick={handleFarmButtonClick}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" 
                    // Align items in the center horizontally
                  >
                    <IoIosPerson
                      fontSize="small"
                      style={{ color: "#000000", cursor:'pointer' }}
                    />

                    <Typography
                      className="ms-2"
                      color={colors.green.DEFAULT}
                      sx={{ fontSize: "11px" , cursor:'pointer'}}
                    >
                      Add Customer
                    </Typography>
                  </Stack>
                </Stack>
                )}
              </div>

              <Dialog
                open={showFarmForm}
                onClose={() => setShowFarmForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "md",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                onClick={handleBackdropClick}
              >
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowFarmForm(false)}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                  {/* <CustomerConfig /> */}
                </DialogContent>
              </Dialog>
            </Card.Body>
          </Card>
        </div>

        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem",marginTop:"2%" ,marginLeft: "2%", marginRight: "2%"}}
        >
          <Card
            style={{
              width: "100%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <IoIosPeople
                    style={{ height: "59px", width: "100px", color: "#34519fe6" }}
                  />
                  <Typography
                    variant="h2"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "13px" }}
                  >
                    Customer Group
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
              {userDetail?.b_config_view_customergroup      && (
                <Stack
                  variant="success"
                  onClick={handleCustomerGroupViewButtonClick}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    // alignItems="center" // Align items in the center horizontally
                  >
                    <img
                      src={viewcrop}
                      alt="Farm view"
                      style={{ height: "15px", width: "18px" , cursor:'pointer'}}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px", cursor:'pointer' }}
                      >
                        View Customer Group
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                  )}
                  {userDetail?.b_config_view_customergroup      && (
                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                />
                  )}
                  {userDetail?.b_config_add_customergroup      && (
                <Stack
                  variant="success"
                  onClick={handleCustomerGroupButtonClick}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <ManageAccountsIcon
                      fontSize="small"
                      style={{ color: "#000000", cursor:'pointer' }}
                    />

                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px" , cursor:'pointer'}}
                      >
                        Add Customer Group
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                  )}
              </div>

              <Dialog
                open={showCropForm}
                onClose={() => setShowCropForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                onClick={handleBackdropClick}
              >
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowCropForm(false)}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                  <VendorConfig />
                </DialogContent>
              </Dialog>
            </Card.Body>
          </Card>
        </div>
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem",marginTop:"2%" ,marginLeft: "2%", marginRight: "2%"}}
        ></div>
         
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem",marginTop:"2%" ,marginLeft: "2%", marginRight: "2%"}}
        >
          <Card
            style={{
              width: "100%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <PiTruckFill
                    style={{ height: "59px", width: "100px", color: "#34519fe6" }}
                  />
                  <Typography
                    variant="h2"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "13px" }}
                  >
                    Transport
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
              {userDetail?.b_config_view_transport       && (
                <Stack
                  variant="success"
                  onClick={handleTransportViewButtonClick}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    // alignItems="center" // Align items in the center horizontally
                  >
                    <img
                      src={viewcrop}
                      alt="Farm view"
                      style={{ height: "15px", width: "18px", cursor:'pointer' }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px", cursor:'pointer' }}
                      >
                        View Transport
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                  )}
                  {userDetail?.b_config_view_transport       && (
                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                />
                  )}
                  {userDetail?.b_config_add_transport       && (
                <Stack
                  variant="success"
                  onClick={handleTransportButtonClick}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <PiTruckFill
                      fontSize="small"
                      style={{ color: "#000000", cursor:'pointer' }}
                    />

                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "11px", cursor:'pointer' }}
                      >
                        Add Transport
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                  )}
              </div>

              <Dialog
                open={showCropForm}
                onClose={() => setShowCropForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                onClick={handleBackdropClick}
              >
                <DialogTitle sx={{ position: "sticky", height: "4%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowCropForm(false)}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                  <VendorConfig />
                </DialogContent>
              </Dialog>
            </Card.Body>
          </Card>
        </div>
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem",marginTop:"2%" ,marginLeft: "2%", marginRight: "2%"}}
        ></div>
      </div>
    </div>
  );
}

export default WMSConfigMainPage;
