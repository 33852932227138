import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  DialogContentText,
  Dialog,
  MenuItem,
  Select,
  InputLabel,
  DialogTitle,
  FormControl,
  Box,
  InputAdornment,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Paper from "@mui/material/Paper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import { BASE_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import ScienceIcon from "@mui/icons-material/Science";
import ForestIcon from "@mui/icons-material/Forest";
import Divider from "@mui/material/Divider";
import BiotechIcon from "@mui/icons-material/Biotech";
import { tokens } from "../AttTheme";
import GrassIcon from "@mui/icons-material/Grass";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { PiFlowerTulipLight } from "react-icons/pi";
import { FcElectricalSensor } from "react-icons/fc";
import { IoLogoElectron } from "react-icons/io5";
import { GiWaterFlask } from "react-icons/gi";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import BadgeIcon from "@mui/icons-material/Badge";
import { Label } from "semantic-ui-react";
import axios from "axios";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { ScaleLoader } from "react-spinners";
import EditIcon from "@mui/icons-material/Edit";
import SuccessPopup from "../PopupComponent/SuccessPopup";
import ErrorPopup from "../PopupComponent/ErrorPopup";
import { TbMoodEmpty } from "react-icons/tb";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const AttUserPage = () => {
  const moment = require("moment");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dialogOpenstatus, setDialogOpenstatus] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [dialogAddNewOpen, setDialogAddNewOpen] = useState(false);
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [newStatus, setNewStatus] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  useEffect(() => {
    fetchEmployeeData();
  }, [offset]);
  // Pagination
  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
  };

  const fetchEmployeeData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_ATTENDANCE_USER_DATA,
        {
          params: {
            limit: 50,
            offset: offset,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("responseeee------------", response?.data);
      setIsLoading(false);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        const data = await response?.data?.data;
        setTotalPages(data?.total_pages);
        setEmployeeData(data?.user_details);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("API ERROR ");
      // setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
      console.log("Error fetching cycle data:", error);
    }
  };

  console.log("USERRRRRRDataaaa", employeeData);

  const handleOpenDialog = (employee) => {
    // console.log("data of these row ",employee);
    setSelectedEmployee(employee);
    setDialogOpen(true);
  };
  console.log("selected employee data : ", selectedEmployee);
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleAddNewUser = () => {
    setDialogAddNewOpen(true);
  };
  const handleAddNewCloseDialog = () => {
    setDialogAddNewOpen(false);
  };
  const handleOpenDialogstatus = (employee, newStatus) => {
    setSelectedEmployee(employee);
    setNewStatus(newStatus); //
    setDialogOpenstatus(true);
  };

  const handleCloseDialogstatus = () => {
    setDialogOpenstatus(false);
  };
  const handleStatusChange = () => {
    // Update the status in the frontend immediately for a better user experience
    const updatedEmployee = {
      ...selectedEmployee,
      status: newStatus === "Active" ? "Inactive" : "Active",
    };
    setEmployeeData((prevData) =>
      prevData?.map((emp) =>
        emp.user_id === updatedEmployee.user_id ? updatedEmployee : emp
      )
    );

    // Close the dialog
    setDialogOpenstatus(false);
  };

  const containerStyle = {
    maxHeight: "75vh",
    height: "100%",
    display: "flex",
    // flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
  };

  function getStatusColor(fertigation_status) {
    switch (fertigation_status) {
      case "completed":
        return "#257850";
      case "in-progress":
        return "#ce962e";
      case "cancelled":
        return "#b43652";
      case "active":
        return "#0f6e9f";
      default:
        return "#7f1d1d";
    }
  }

  const customStyle = (fertigation_status) => {
    return {
      fontFamily: "Lexend Deca",
      position: "absolute",
      right: "10%",
      width: "7%",
      fontSize: 10,
      color: "#FFFFFF",
      backgroundColor: getStatusColor(fertigation_status), // Function to get color based on fertigation_status
    };
  };

  const NullScreen = () => {
    const nullScreenContainerStyle = {
      top: 0,
      left: 0,
      display: "flex",
      overflowX: "hidden",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "71vh",
      width: "100%",
    };
    return (
      <Box style={nullScreenContainerStyle}>
        <TbMoodEmpty style={{ fontSize: "50px", color: "#5e89c7" }} />
        <Typography variant="h2" color="textPrimary" mt={2}>
          No Data Found
        </Typography>
        {/* <Typography variant="h4" color="textSecondary" mt={1}>
          Please select a farm to intiate fertigation.
        </Typography> */}
      </Box>
    );
  };

  return (
    <>
      {isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "85vh",
              zIndex: "1",
            }}
          >
            <ScaleLoader color="#2c677b" loading={isLoading} />
          </div>
        </>
      ) : (
        <>
          <div>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // Vertically center the content
                marginTop: "1%",
              }}
            >
              <Typography
                variant="h1"
                color={colors.green.DEFAULT}
                style={{
                  marginLeft: "2%",
                }}
              >
                User Details
              </Typography>

              <Button
                variant="outlined"
                startIcon={
                  <AddCircleOutlineOutlinedIcon
                    style={{ color: colors.green.DEFAULT }}
                  />
                }
                sx={{
                  borderColor: colors.green.DEFAULT,
                  color: colors.green.DEFAULT,
                }}
                onClick={handleAddNewUser}
              >
                <Typography variant="h5" color={colors.green.DEFAULT}>
                  Add New User
                </Typography>
              </Button>
            </Grid>
            <Grid
              container
              spacing={1}
              sx={{ marginTop: "5px" }}
              style={containerStyle}
            >
              {employeeData?.length > 0 ? (
                <>
                  {employeeData?.map((employee, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                      <Paper
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: "10px",
                          height: "40px",
                          // backgroundColor: index % 2 === 1 ? "white" : "#ECF4FO", // Red for odd indices, white for even indices

                          transition:
                            "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
                          position: "relative", // Make sure the button is positioned relative to the Paper
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "scale(1.01)";
                          e.currentTarget.style.backgroundColor = "#5E8AC785";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.backgroundColor = "white";
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            container
                            direction="column"
                          >
                            <Grid item style={{ color: "grey" }}>
                              <AccountCircleIcon fontSize="small" /> Employee
                            </Grid>
                            <Grid item style={{ color: "black" }}>
                              {employee?.user_name}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            sm={1}
                            md={1}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <BadgeIcon fontSize="small" /> Employee Id
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.user_id}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <AddLocationAltIcon fontSize="small" /> Location
                            </Grid>
                            <Grid
                              item
                              style={{ color: "black", textAlign: "center" }}
                            >
                              {employee?.location}
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            container
                            direction="column"
                          >
                            <Grid
                              item
                              style={{ color: "grey", textAlign: "center" }}
                            >
                              <AddTaskIcon fontSize="small" /> Status
                            </Grid>
                            <Grid
                              item
                              style={{
                                color: employee?.status ? "green" : "red",
                                textAlign: "center",
                              }}
                            >
                              {employee?.status ? "Active" : "InActive"}
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xs={1}
                            sm={1}
                            md={1}
                            container
                            sx={{ marginTop: "0.07%" }}
                          >
                            <Button
                              variant="contained"
                              // disabled={

                              // }
                              startIcon={
                                <EditIcon style={{ color: "#ffffff" }} />
                              }
                              style={{
                                backgroundColor: "#5e89c7",
                                borderColor: "#5e89c7",
                                position: "absolute",
                                borderRadius: "40px",
                                right: "10px",
                              }}
                              onClick={() => handleOpenDialog(employee)}
                            >
                              <Typography
                                variant="h7"
                                fontFamily="Lexend Deca"
                                color="white"
                              >
                                Edit Details
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </>
              ) : (
                <NullScreen />
              )}
            </Grid>

            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm">
              <DialogTitle>
                <Typography variant="h3" color={colors.green.DEFAULT}>
                  Edit User Details
                </Typography>
              </DialogTitle>

              <DialogContent>
                <form>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} marginTop={"10px"}>
                      <TextField
                        label="Name"
                        size="small"
                        value={selectedEmployee?.user_name || ""}
                        //   value={ec}
                        //   onChange={(e) => {
                        //     const value = parseFloat(e.target.value);
                        //     if (!isNaN(value) && value >= 0 && value <= 5000) {
                        //       updateSetPointValues("ec", value);
                        //     } else {
                        //       updateSetPointValues("ec", "");
                        //     }
                        //   }}
                        inputProps={{
                          min: 0,
                          max: 5000,
                          step: 0.1, // Add step for decimal values (e.g., increments of 0.1)
                        }}
                        fullWidth
                        autoFocus
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} marginTop={"2px"}>
                      <TextField
                        label="Employee Id "
                        size="small"
                        value={selectedEmployee?.user_id || ""}
                        //   value={ph}
                        //   onChange={(e) => {
                        //     const value = parseFloat(e.target.value);
                        //     if (!isNaN(value) && value >= 0 && value <= 15) {
                        //       updateSetPointValues("ph", value);
                        //     } else {
                        //       updateSetPointValues("ph", "");
                        //     }
                        //   }}
                        inputProps={{
                          min: 0,
                          max: 15,
                          step: 0.1, // Add step for decimal values (e.g., increments of 0.1)
                        }}
                        fullWidth
                        autoFocus
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Location"
                        size="small"
                        value={selectedEmployee?.location || ""}
                        //   value={total_plants}
                        //   onChange={(e) => {
                        //     const value = parseFloat(e.target.value);
                        //     if (!isNaN(value) && value >= 0 && value <= 10000) {
                        //       updateSetPointValues("total_plants", value);
                        //     } else {
                        //       updateSetPointValues("total_plants", "");
                        //     }
                        //   }}

                        fullWidth
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <InputLabel id="Status">Status</InputLabel>
                        <Select
                          label="Status"
                          labelId="Status"
                          name="Status"
                          id="Status"
                          value={selectedEmployee?.status || ""}
                          // value={status}
                          onChange={(e) => {
                            const newStatus = e.target.value;
                            setSelectedEmployee((prevEmployee) => {
                              // Check if the status is changing
                              if (prevEmployee.status !== newStatus) {
                                // Open the dialog when the status is changing
                                handleOpenDialogstatus(prevEmployee, newStatus);
                              }
                              // Update the status
                              return { ...prevEmployee, status: newStatus };
                            });
                          }}
                          // onChange={handleStatusChange}
                          size="small"
                          required
                          fullWidth
                          autoFocus
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="InActive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    alignItems="flex-end"
                    justifyContent="flex-start"
                  >
                    <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                      <Stack
                        spacing={1}
                        direction="row"
                        style={{
                          height: "32px",
                          marginTop: "2%",
                          marginRight: "0%",
                          marginLeft: "70%",
                        }}
                      >
                        <Button
                          type="submit" // Added type="submit" to trigger form submission
                          variant="contained"
                          sx={{
                            backgroundColor: colors.green.DEFAULT,
                            borderColor: "#5e89c7",
                          }}
                          style={{ float: "left" }}
                        >
                          <Typography variant="h6" color={colors.white.DEFAULT}>
                            Save
                          </Typography>
                        </Button>
                        <Button
                          onClick={handleCloseDialog}
                          variant="outlined"
                          style={{ float: "left", marginLeft: "15px" }}
                        >
                          <Typography variant="h6" color={colors.green.DEFAULT}>
                            Cancel
                          </Typography>
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </DialogContent>
            </Dialog>
            <Dialog
              open={dialogAddNewOpen}
              onClose={handleAddNewCloseDialog}
              maxWidth="sm"
            >
              <DialogTitle>
                <Typography
                  variant="h2"
                  color={colors.green.DEFAULT}
                  textAlign="center"
                >
                  Add User
                </Typography>
              </DialogTitle>

              <DialogContent>
                <DialogContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} marginTop={"6px"}>
                      <TextField
                        label="Name"
                        // value={new_name}
                        // onChange={(e) =>
                        //   updateNewUserState("new_name", e.target.value)
                        // }
                        size="small"
                        inputProps={{
                          maxLength: 20,
                          minLength: 5,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <PermIdentityIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        autoFocus
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} marginTop={"6px"}>
                      <TextField
                        label="Employee Id"
                        // value={new_userName}
                        // onChange={(e) =>
                        //   updateNewUserState("new_userName", e.target.value)
                        // }
                        size="small"
                        inputProps={{
                          maxLength: 10,
                          minLength: 3,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <AccountCircleIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        autoFocus
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} marginTop={"6px"}>
                      <FormControl fullWidth>
                        <InputLabel id="Status">Status</InputLabel>
                        <Select
                          label="Status"
                          labelId="Status"
                          name="Status"
                          id="Status"
                          // value={status}
                          // onChange={handleStatusChange}
                          size="small"
                          required
                          fullWidth
                          autoFocus
                        >
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} marginTop={"6px"}>
                      <TextField
                        label="Email"
                        name="email"
                        type="email"
                        size="small"
                        // value={newUserRoleState?.new_email}
                        // onChange={(event) => {
                        //   const { value } = event.target;
                        //   // Validate and update the new_email state
                        //   setNewUserRoleState((prevValues) => ({
                        //     ...prevValues,
                        //     new_email: value,
                        //   }));
                        // }}
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          maxLength: 50,
                          minLength: 3,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <MailIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} marginTop={"6px"}>
                      <TextField
                        label="Mobile No."
                        // value={newUserRoleState?.new_mobile}
                        size="small"
                        onChange={(event) => {
                          const { value } = event.target;
                          // Validate and update the new_mobile state only if it's a valid number
                          if (
                            value === "" ||
                            /^[0-9]*$/.test(value) ||
                            value?.match(/^[\+0-9]+$/)
                          ) {
                            // setNewUserRoleState((prevValues) => ({
                            //   ...prevValues,
                            //   new_mobile: value,
                            // }));
                          }
                        }}
                        fullWidth
                        type="tel"
                        required
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          maxLength: 10,
                          inputMode: "numeric", // Shows the numeric keyboard on mobile devices
                          pattern: "[0-9]*", // Only allows numeric input (additional validation)
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <PhoneIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} marginTop={"6px"}>
                      <TextField
                        label="D.O.B"
                        name="dateOfBirth"
                        // value={formValues?.dateOfBirth}
                        type="date"
                        // onChange={handleInputChange}
                        fullWidth
                        size="small"
                        margin="normal"
                        // variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          style: { width: "100%" },
                          max: new Date().toISOString().split("T")[0], // sets max date to today's date
                          min: new Date(new Date().getFullYear() - 100, 0, 1)
                            .toISOString()
                            .split("T")[0],
                          required: true,
                        }}
                        // error={!!errors?.dateOfBirth}
                        // helperText={errors?.dateOfBirth}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} marginTop={"6px"}>
                      <FormControl fullWidth>
                        <InputLabel id="Shift">Shift</InputLabel>
                        <Select
                          label="Shift"
                          labelId="Shift"
                          name="Shift"
                          id="Shift"
                          // value={status}
                          // onChange={handleStatusChange}
                          size="small"
                          required
                          fullWidth
                          autoFocus
                        >
                          <MenuItem value="active">Day</MenuItem>
                          <MenuItem value="inactive">Night</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ marginRight: "15px" }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    style={{
                      height: "35px",
                      marginTop: "2%",
                      marginLeft: "84%",
                    }}
                  >
                    <Button
                      onClick={handleAddNewCloseDialog}
                      sx={{ color: "#5E89C7", borderColor: "#5E89C7" }}
                      variant="outlined"
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      sx={{ backgroundColor: "#5E89C7" }}
                      variant="contained"
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Submit
                      </Typography>
                    </Button>
                  </Stack>
                </DialogActions>
              </DialogContent>
            </Dialog>
            <Dialog
              open={dialogOpenstatus}
              onClose={handleCloseDialogstatus}
              maxWidth="sm"
            >
              <DialogTitle>
                <Typography variant="h3" color={colors.green.DEFAULT}>
                  Change Status
                </Typography>
              </DialogTitle>

              <DialogContent>
                <Typography>
                  Do you really want to change the status of{" "}
                  {selectedEmployee?.user_name} to{" "}
                  {newStatus === "Active" ? "Active" : "Inactive"}?
                </Typography>

                <Stack spacing={1} direction="row" style={{ marginTop: "3%" }}>
                  <Button
                    variant="contained"
                    onClick={handleStatusChange}
                    sx={{
                      backgroundColor: colors.green.DEFAULT,
                      borderColor: "#5e89c7",
                    }}
                  >
                    <Typography variant="h6" color={colors.white.DEFAULT}>
                      Yes
                    </Typography>
                  </Button>
                  <Button
                    onClick={handleCloseDialogstatus}
                    variant="outlined"
                    style={{ marginLeft: "15px" }}
                  >
                    <Typography variant="h6" color={colors.green.DEFAULT}>
                      No
                    </Typography>
                  </Button>
                </Stack>
              </DialogContent>
            </Dialog>

            <Grid container justifyContent="flex-end" sx={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={9} md={10}></Grid>
              <Grid item xs={12} sm={9} md={2}>
                <Pagination
                  count={totalPages}
                  page={offset}
                  onChange={handleChangePage}
                  style={{ color: "#5e89c7" }}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}

      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default AttUserPage;
