import React, { useState, useEffect, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { ScaleLoader } from "react-spinners";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BorderColor, ErrorOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Typography,
  IconButton,
} from "@mui/material";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import Pagination from "@mui/material/Pagination";
import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import { createTheme, ThemeProvider } from "@mui/material";
import moment from "moment";
import { Tooltip, Stack, useTheme } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../../../theme";
import jwtDecode from "jwt-decode";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const HarvestingDialog = ({
  dialogOpen,
  setDialogOpen,
  mandatoryFields,
  listOfZones,
  fetchTaskData,
  closeMasterDialog,
}) => {
  const [receivedData, setReceivedData] = useState(null);

  const handleDataReceived = (data) => {
    setReceivedData(data);
  };

  console.log("receivedData in Parent", receivedData);

  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const [isCropTableDialogOpen, setIsCropTableDialogOpen] = useState(false);
  const handleCropTableOpenDialog = () => {
    setIsCropTableDialogOpen(true);
  };

  useEffect(() => {
    handleCropTableCloseDialog();
  }, [receivedData]);

  const handleCropTableCloseDialog = () => {
    setIsCropTableDialogOpen(false);
  };

  console.log("LISTTTTTTTTTTTTTTTTTTTTT", mandatoryFields);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [harvestingDialogState, setHarvestingDialogState] = useState({
    title: "",
    selectedColor: "#3174ad",
    selectedColorDescription: null,
    weightInKg: "",
    weightInPlants: "",
    selectedCrop: "",
    selectedZone: "",
    description: "",
    showPopup: false,
    open: false,
    selectedProofs: [],
    selectedFields: [],
    savedText: "",
    descriptions: [],
    showAttachment: false,
    showAddDescription: true,
    dataTypes: [],
    amount: 0,
    labor: 0,
    zone_id: 0,
    proof_url: "",
  });
  const {
    title,
    selectedColor,
    selectedColorDescription,
    selectedCrop,
    selectedZone,
    description,
    showPopup,
    selectedProofs,
    selectedFields,
    savedText,
    descriptions,
    showAttachment,
    showAddDescription,
    dataTypes,
    weightInKg,
    weightInPlants,
    amount,
    labor,
    zone_id,
    proof_url,
  } = harvestingDialogState;

  const [proofDetailsAdded, setProofDetailsAdded] = useState(false);
  const updateState = (key, value) => {
    setHarvestingDialogState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async (e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);

    console.log("Handle Submit Entered !");
    e.preventDefault();
    const formData = {
      optional_fields: {
        activity_name: title,
        priority_color: selectedColor,
        zone: selectedZone,
        zone_id: zone_id,
        crop: selectedCrop,
        weight_in_kg: weightInKg,
        weight_in_plants: weightInPlants,
        proof: selectedProofs.length > 0 ? selectedProofs : null, // Set value based on the selectedProofs array
        instruction: description,
        priority_color_description: selectedColorDescription,
        amount: amount,
        labor: labor,
        proof_url: proof_url,
      },
      mandatory_fields: {
        ...mandatoryFields,
        inv_task: true,
      },
      inv_data: receivedData,
    };

    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_FINANCE_PLANNING,
        formData,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

      if (response.status >= 200 && response.status < 305) {
        console.log("Harvesting Task Allocated");
        updateState("showPopup", true);
      } else {
        setApiErrorOpen(true);
      }
    } catch (error) {
      setApiErrorOpen(true);
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      setApiErrorMessage(error.response.data.msg);
    }

    e.target.reset();
  };

  const handleSelectedColorDescriptionChange = async (e) => {
    const selectedColorDescription = e.target.value;
    updateState("selectedColorDescription", selectedColorDescription);
  };

  const handlePopupClose = () => {
    updateState("open", false);
    updateState("showPopup", false);
    closeMasterDialog();
    setDialogOpen(false);
    fetchTaskData();
  };

  const handleTitleChange = (event) => {
    updateState("title", event.target.value);
  };

  const handleColorChange = (event) => {
    updateState("selectedColor", event.target.value);
  };

  const handleDescriptionChange = (e, index) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = e.target.value;
    updateState("descriptions", updatedDescriptions);
  };

  const handleDataTypeChange = (e) => {
    updateState("dataTypes", [e.target.value]);
  };

  const handleSave = () => {
    const proofs = descriptions.map((desc, index) => {
      const attachmentType = dataTypes[index] ? `${dataTypes[index]}` : "";
      setProofDetailsAdded(true);
      return { description: desc, attachmentType };
    });
    updateState("selectedProofs", proofs); // Update selectedProofs here
    updateState(
      "savedText",
      proofs
        .map((proof) => `${proof.description} | ${proof.attachmentType}`)
        .join("\n")
    );
    updateState("descriptions", []);
    updateState("dataTypes", []);
    updateState("showAddDescription", true);
    updateState("showAttachment", false);
  };

  const handleAddDescription = () => {
    updateState("showAddDescription", false);
    updateState("showAttachment", true);
    updateState("descriptions", [...descriptions, ""]);
  };

  const modules = {
    toolbar: {
      container: [[{ list: "ordered" }]],
    },
  };

  const formats = ["list"];

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { minWidth: "300px", minHeight: "300px" } }}
      >
        <DialogTitle>
          <Typography variant="h3" color={colors.green.DEFAULT}>
            Harvesting
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} marginTop={"15px"}>
                <TextField
                  label="Activity Title"
                  value={title}
                  onChange={handleTitleChange}
                  fullWidth
                  size="small"
                  autoFocus
                  required
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
                <TextField
                  id="input-with-icon-textfield"
                  label="Priority Color"
                  inputProps={{ maxLength: 15 }}
                  size="small"
                  value={selectedColorDescription}
                  onChange={handleSelectedColorDescriptionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          id="color"
                          value={selectedColor}
                          onChange={handleColorChange}
                          style={{
                            width: "24px",
                            height: "24px",
                            padding: "0",
                            border: "none",
                            borderRadius: "50%",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <TextField
                    label="Enter Total Amount"
                    name="Enter Total Amount"
                    value={amount}
                    type="number"
                    size="small"
                    onChange={(event) => {
                      const value = parseInt(event.target.value);
                      if (!isNaN(value) && value > 1000000) {
                        updateState("amount", 1000000);
                      } else {
                        updateState("amount", value);
                      }
                    }}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 1000000,
                      inputMode: "numeric",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <TextField
                    label="No. of Labor"
                    name="No. of Labor"
                    value={labor}
                    type="number"
                    size="small"
                    onChange={(event) => {
                      const value = parseInt(event.target.value);
                      if (!isNaN(value) && value > 50) {
                        updateState("labor", 50);
                      } else {
                        updateState("labor", value);
                      }
                    }}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 50,
                      inputMode: "numeric",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                {descriptions?.map((description, index) => (
                  <TextField
                    key={index}
                    label="Proof title"
                    fullWidth
                    size="small"
                    margin="normal"
                    value={description}
                    inputProps={{ maxLength: 15 }}
                    onChange={(e) => handleDescriptionChange(e, index)}
                  />
                ))}
                {proofDetailsAdded ? (
                  <>
                    <Tooltip
                      title="Proof Type already added!"
                      placement="right"
                      disableHoverListener={!proofDetailsAdded}
                    >
                      <Button
                        variant="outlined"
                        endIcon={
                          <AddIcon style={{ color: colors.green.DEFAULT }} />
                        }
                        sx={{
                          borderColor: colors.green.DEFAULT,
                          color: colors.green.DEFAULT,
                        }}
                        onClick={handleAddDescription}
                        disabled
                      >
                        <Typography variant="h6" color={colors.green.DEFAULT}>
                          Proof List
                        </Typography>
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  showAddDescription && (
                    <Tooltip
                      title="Click to add the attachments that supervisor needs to send as a proof of completion of task !"
                      placement="right"
                    >
                      <Button
                        variant="outlined"
                        endIcon={
                          <AddIcon style={{ color: colors.green.DEFAULT }} />
                        }
                        sx={{
                          borderColor: colors.green.DEFAULT,
                          color: colors.green.DEFAULT,
                        }}
                        onClick={handleAddDescription}
                      >
                        <Typography variant="h6" color={colors.green.DEFAULT}>
                          Proof List
                        </Typography>
                      </Button>
                    </Tooltip>
                  )
                )}
              </Grid>

              {showAttachment && (
                <>
                  <Grid item xs={12} sm={4} style={{ marginTop: "10px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Select Proof type</InputLabel>
                      <Select
                        value={dataTypes[0] || ""}
                        label="Select Proof type"
                        onChange={handleDataTypeChange}
                        displayEmpty
                        size="small"
                        inputProps={{ "aria-label": "Select Proof type" }}
                        startAdornment={
                          <InputAdornment position="start"></InputAdornment>
                        }
                      >
                        <MenuItem value="PDF">PDF</MenuItem>
                        <MenuItem value="DOC">DOC</MenuItem>
                        <MenuItem value="Image">Image</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={1.2}>
                    <Button
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      variant="outlined"
                      style={{
                        float: "left",
                        marginLeft: "16px",
                        marginTop: "19px",
                      }}
                      onClick={handleSave}
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Save
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
                <ReactQuill
                  style={{
                    height: "100px",
                    width: "100%",
                  }}
                  placeholder="Additional instructions for this activity!"
                  modules={modules}
                  formats={formats}
                  value={description} // Add value prop
                  onChange={(value) => updateState("description", value)} // Update description state
                />
              </Grid>
              {savedText && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginTop: "-48%",
                    marginLeft: "52%",
                    backgroundColor: "#ecf4f0",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    # {savedText}
                  </Typography>
                </Grid>
              )}
            </Grid>

            {receivedData && (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} style={{ marginTop: "50px" }}>
                    <TextField
                      label="Farm Name"
                      name="Farm Name"
                      value={receivedData?.farm_name}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "50px" }}>
                    <TextField
                      label="Crop Name"
                      name="Crop Name"
                      value={receivedData?.crop_name}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Crop Variety"
                      name="Crop Variety"
                      value={receivedData?.crop_variety_name}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Crop Type"
                      name="Crop Type"
                      value={receivedData?.crop_type}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Zone"
                      name="Zone"
                      value={receivedData?.zone_name}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Batch"
                      name="Batch"
                      value={receivedData?.batch}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Date of Sow"
                      name="Date of Sow"
                      value={receivedData?.formatteddos}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Expected DOH."
                      name="Expected DOH."
                      value={receivedData?.formattededoh}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Expected phase"
                      name="Expected phase"
                      value={receivedData?.current_phase}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Actual phase"
                      name="Actual phase"
                      value={receivedData?.actual_phase}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Next phase"
                      name="Next phase"
                      value={receivedData?.next_phase}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="No. of plants"
                      name="No. of plants"
                      value={receivedData?.no_of_plant}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                    <TextField
                      label="Day"
                      name="Day"
                      value={receivedData?.diff_days_phase}
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Grid item xs={12} sm={6} style={{ marginTop: "50px" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  style={{
                    height: "32px",
                    marginTop: "2%",
                    marginRight: "1%",
                    marginLeft: "40%",
                  }}
                >
                  {receivedData ? (
                    <>
                      <Button
                        type="submit" // Added type="submit" to trigger form submission
                        variant="contained"
                        sx={{ backgroundColor: colors.green.DEFAULT }}
                        style={{ float: "left" }}
                      >
                        <Typography variant="h6" color={colors.white.DEFAULT}>
                          Create
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Click to add a batch for this activity">
                        <Button
                          onClick={handleCropTableOpenDialog}
                          variant="outlined"
                          sx={{
                            backgroundColor: colors.green.DEFAULT,
                            width: "180%",
                          }}
                          startIcon={
                            <AddIcon
                              sx={{
                                marginLeft: "-8px",
                                marginRight: "-5px",
                                color: colors.white.DEFAULT,
                              }}
                            />
                          }
                          style={{ float: "left" }}
                        >
                          <Typography variant="h6" color={colors.white.DEFAULT}>
                            Batch
                          </Typography>
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Button
                    onClick={() => setDialogOpen(false)}
                    variant="outlined"
                    style={{ float: "left", marginLeft: "15px" }}
                  >
                    <Typography variant="h6" color={colors.green.DEFAULT}>
                      Cancel
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>

          <Dialog open={apiErrorOpen} onClose={() => setApiErrorOpen(false)}>
            <DialogTitle style={{ textAlign: "center" }}>
              <ErrorOutlineOutlined
                style={{ width: "60px", height: "60px", color: "#A45A52" }}
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              {apiErrorMessage ? (
                <Typography
                  variant="h4"
                  color={colors.red[100]}
                  fontFamily="Lexend Deca"
                >
                  {apiErrorMessage}
                </Typography>
              ) : (
                <Typography
                  variant="h4"
                  color={colors.red[100]}
                  fontFamily="Lexend Deca"
                >
                  An error occurred, please try again
                </Typography>
              )}
            </DialogContent>
            <Button
              onClick={() => {
                setReceivedData(null);
                setApiErrorOpen(false);
              }}
            >
              <Typography
                variant="h4"
                color={colors.red[100]}
                fontFamily="Lexend Deca"
              >
                OK
              </Typography>
            </Button>
          </Dialog>

          <Dialog open={showPopup} onClose={() => setDialogOpen(false)}>
            <DialogTitle style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ width: "60px", height: "60px", color: "#2C7B55" }}
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                color={colors.green.DEFAULT}
                // fontWeight={typography.h1}
                fontFamily="Lexend Deca"
              >
                New activity added!
              </Typography>
            </DialogContent>
            <Button onClick={handlePopupClose}>
              <Typography
                variant="h4"
                color={colors.green.DEFAULT}
                // fontWeight={typography.h1}
                fontFamily="Lexend Deca"
              >
                OK
              </Typography>
            </Button>
          </Dialog>
        </DialogContent>
        <Box m="20px">
          <Dialog
            open={isCropTableDialogOpen}
            onClose={handleCropTableCloseDialog}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle>
              <Typography
                variant="h1"
                style={{ marginLeft: "0.1%" }}
                color={colors.green.DEFAULT}
              >
                Live Inventory
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Box m="0px 0 0 0" sx={{ marginBottom: "20px" }}>
                <Box>
                  <CropDataGrid onDataReceived={handleDataReceived} />
                </Box>
              </Box>
            </DialogContent>
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "1.0%",
              }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={handleCropTableCloseDialog}
              >
                Close
              </Button>
            </Box>
          </Dialog>
        </Box>
      </Dialog>
    </>
  );
};

const CropDataGrid = ({ onDataReceived }) => {
  const theme = useTheme();
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(1);
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchCropData();
    const loaderTimer = setTimeout(() => {
      setIsLoading(false);
    }, 30000); // Display loader for 3 seconds

    fetchCropData();

    return () => clearTimeout(loaderTimer);
  }, [limit, offset]);

  const fetchCropData = async (newPage) => {
    console.log("newpage", newPage);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_TASK_MANAGEMENT_CROPDATA(limit, offset)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = response?.data;
        console.log("!!!!!!!!!", data?.rows);
        const formattedData = data?.rows?.map((item) => {
          const formatteddos = moment(item?.date_of_sow)?.format("DD-MM-YYYY");
          let formatteddot = moment(item?.date_of_transplant)?.format(
            "DD-MM-YYYY"
          );
          if (formatteddot === "Invalid date") {
            formatteddot = "NT";
          }
          const formattededoh = moment(item?.exp_date_of_harvest).format(
            "DD-MM-YYYY"
          );

          return {
            ...item,
            formatteddos,
            formatteddot,
            formattededoh,
          };
        });
        setRows(formattedData);
        setIsLoading(false);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
    fetchCropData();
  };
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "#000000", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        button: {
          color: "#ffffff",

          backgroundColor: "#2c7B55",
        },
        typography: {
          button: {
            //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "success",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12, // S
          backgroundColor: "#2c7B55",
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
              root: {
                color: "#ffffff", // set the color of the text inside the button
                backgroundColor: "#2c7B55", // set the background color of the button
                "&:hover": {
                  backgroundColor: "#2c7B55", // set the background color when hovering over the button
                },
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#2c7B55", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const columns = useMemo(
    () => [
      {
        header: "Add",
        size: 4,
        enableEditing: false,
        Cell: ({ cell }) => {
          const handleClick = () => {
            console.log("Console when clicked on cell", cell.row.original);
            onDataReceived(cell.row.original);
          };
          return (
            <div className="tooltip-cell" title="Click to Add this batch">
              <IconButton className="icon-wrapper" onClick={handleClick}>
                <AddIcon
                  style={{
                    color: colors.green.DEFAULT,
                    height: "17px",
                    width: "17px",
                  }}
                />
              </IconButton>
              <span className="farm-name">{cell.getValue()}</span>
            </div>
          );
        },
      },
      {
        header: "Farm",
        accessorKey: "farm_name",
        size: 10,
      },
      {
        header: "Variety",
        accessorKey: "crop_variety_name",
        size: 10,
      },
      {
        header: "Crop",
        accessorKey: "crop_name",
        size: 10,
      },

      {
        header: "Zone",
        accessorKey: "zone_name",
        size: 10,
      },
      {
        header: "Batch",
        accessorKey: "batch",
        size: 10,
      },
      {
        header: "No. of Plants",
        accessorKey: "no_of_plant",
        size: 10,
      },
      {
        header: "Crop Type",
        accessorKey: "crop_type",
        size: 10,
      },
      {
        header: "Expected Phase",
        accessorKey: "current_phase",
        size: 10,
      },
      {
        header: "Actual Phase",
        accessorKey: "actual_phase",
        size: 10,
      },
      {
        header: "Next Phase",
        accessorKey: "next_phase",
        size: 10,
      },
      {
        header: "Day",
        accessorKey: "diff_days_phase",
        size: 10,
      },
      {
        header: "Exp. DOH",
        accessorKey: "formattededoh",
        size: 10,
      },
      {
        header: "Date Of Sow",
        accessorKey: "formatteddos",
        size: 10,
      },
    ],
    []
  );

  return (
    <div>
      <div>
        {isLoading ? ( // Check if data is still loading
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <ScaleLoader color="#2C7B55" />
          </div>
        ) : (
          <div>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={rows}
                enableGrouping
                enableStickyHeader
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                initialState={{
                  density: "compact",
                  pagination: { pageIndex: 0, pageSize: 5 },
                }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: "650",
                    color: "#2c7B55",
                    fontSize: "15px",
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    //stripe the rows, make odd rows a darker color
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#f8fafc",
                    },
                  },
                }}
                muiTableContainerProps={{ sx: { marginBottom: "0%" } }}
                muiToolbarAlertBannerChipProps={{ color: "primary" }}
              />
            </ThemeProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                //  marginTop: "-90%",
              }}
            >
              <Pagination
                count={100}
                onChange={handleChangePage}
                size="small"
              />
            </div>
          </div>
        )}
      </div>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};

export default HarvestingDialog;
