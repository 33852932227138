import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

import { BASE_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import { BorderColor, ErrorOutlineOutlined } from "@mui/icons-material";
import moment from "moment";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { tokens } from "../../theme";
import {
  Button,
  Drawer,
  Grid,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AWS from "aws-sdk";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
AWS.config.update({
  accessKeyId: "AKIAYGYIFM3LE7VZTYU2",
  secretAccessKey: "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",
  region: "ap-south-1",
});

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const s3 = new AWS.S3();

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "#FDFDFD",
    borderRight: "1px solid #EAEAEA",
    width: "30%",
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function SideDrawer({ isOpen, onClose, details, task_id, fetchTaskData }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const handleClose = () => {
    onClose(task_id); // Close the specific drawer based on task_id
  };
  const currentDetail = details?.find((item) => item?.task_id === task_id);
  const selectedTask = currentDetail?.task;
  const [status, setStatus] = useState(currentDetail?.priority);
  const [myLabel, setmyLabel] = useState(currentDetail?.label);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [fileName, setFileName] = useState(currentDetail.proof_url); // The fileName field from the backend

  useEffect(() => {
    fetchTaskData();
    console.log("fileeeee", fileName);
  }, [fileName]);

  const handleDownloadClick = () => {
    const presignedUrl = s3.getSignedUrl("getObject", {
      Bucket: "fambo-assets",
      Key: `farmer/${fileName}`,
      Expires: 3600,
    });
    console.log("Presigned URL for viewing:", presignedUrl);
    window.open(presignedUrl, "_blank");
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleMyLabelChange = (event) => {
    setmyLabel(event.target.value);
  };

  const getPriorityButtonColor = (priority) => {
    switch (priority) {
      case "To-Do":
        return "#EF5C48";
      case "In Progress":
        return "#579dff";
      case "Completed":
        return "#7EE2B8";
      default:
        return "";
    }
  };

  const getLabelButtonColor = (label) => {
    switch (label) {
      case "Urgent":
        return "#ef4444";
      case "High":
        return "#eab308";
      case "Low":
        return "#0284c7";
      default:
        return "";
    }
  };

  const extractTextFromHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const paragraphs = Array.from(doc.querySelectorAll("p"));
    const lists = Array.from(doc.querySelectorAll("ol"));
    const extractedText = [];

    const maxCount = Math.max(paragraphs.length, lists.length);

    for (let i = 0; i < maxCount; i++) {
      const heading = paragraphs[i]?.textContent || "";
      const points = lists[i]?.querySelectorAll("li");
      const pointsText = points
        ? Array.from(points).map((li) => li.textContent)
        : [];
      extractedText.push({ heading, points: pointsText });
    }

    return extractedText;
  };

  const extractedText = extractTextFromHTML(currentDetail.instruction);
  console.log("INSTRUCTIONSSSSSSSSSS", extractedText);

  const handleAttachmentClick = () => {
    setShowTextField(true);
  };

  const handleTaskDelete = () => {
    setShowConfirmation(true);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleConfirmationOK = () => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);

    // fetch(
    //   `${BASE_URL}${API_ENDPOINTS.DELETE_TASK_MANAGEMENT_TASK}${encodeURIComponent(task_id)}`,
    //   {
    //     method: "DELETE",
    //     headers: {
    //       "Content-Type": "application/json",
    //       client_id: client_id,
    //     },
    //     body: JSON.stringify({}),
    //     signal, // Pass the signal to the fetch request
    //   }
    // )
    axiosInstance
      .delete(
        `${API_ENDPOINTS.DELETE_TASK_MANAGEMENT_TASK}${encodeURIComponent(
          task_id
        )}`
      )
      .then((response) => {
        clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

        if (response.status >= 200 && response.status < 305) {
          console.log("Delete request sent successfully");
          fetchTaskData();
          console.log("Initial state of showPopUp", showPopup);
          handleClose();
          setShowConfirmation(false);
          setShowPopup(true);
          console.log("Changed state of showPopUp", showPopup);
        } else {
          console.log("Error sending delete request");
          alert(
            "Sorry, an error occurred while deleting the task. Please try again later."
          );
        }
        console.log("Changed state of showPopUp 2nd time", showPopup);
      })
      .catch((error) => {
        clearTimeout(timeoutId); // Clear the timeout if an error occurs
        console.log(
          "Backend is not active, delete request cannot be sent",
          error
        );
        setShowConfirmation(false);
        handleClose();
        alert(
          "Sorry, an error occurred while deleting the task. Please try again later."
        );
      });
  };

  const [isAttachmentDialog, setIsAttachmentDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleAttachmentOpen = () => {
    setIsAttachmentDialog(true);
  };

  const handleAttachmentClose = () => {
    setIsAttachmentDialog(false);
    setSelectedFile(null);
  };

  // Using aws-sdk
  const handleSaveAttachmentClick = async () => {
    if (selectedFile) {
      const key = `${Date.now()}_${selectedFile.name}`;

      const params = {
        Bucket: "fambo-assets",
        Key: `farmer/${key}`,
        Body: selectedFile,
      };

      s3.upload(params, async (err, data) => {
        if (err) {
          console.log("Error uploading file:", err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          setFileName(key);
        }
      });

      const formData = {
        label: currentDetail.label,
        priority: currentDetail.priority,
        assign_to: currentDetail.assign_to,
        assign_to_id_fk: currentDetail.assign_to_id_fk,
        start_date: currentDetail.start_date,
        proof_url: key,
        task_id: currentDetail.task_id,
      };
      try {
        const response = await axiosInstance.put(
          API_ENDPOINTS.PUT_TASK_MANAGEMENT_TASK,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 305) {
          console.log("Task edit successful");
        } else {
          console.log("No changes made");
        }
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
      console.log("EDIT DATAA", { formData });
    }
    handleAttachmentClose();
  };

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={handleClose}>
      {selectedTask === "Cleaning" && (
        <>
          <List>
            <ListItem
              style={{
                backgroundColor: "#b5cbbf",
                borderRadius: "8px",
                marginBottom: "10px",
                padding: "10px",
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",

                  borderRadius: "8px",
                }}
                primary={
                  <Typography
                    variant="h2"
                    color={colors.white.DEFAULT}
                    fontWeight={typography.h1}
                  >
                    {currentDetail.task_title.length > 15
                      ? `${currentDetail.task_title.slice(0, 15)}...`
                      : currentDetail.task_title}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem style={{ marginBottom: "15px" }}>
              <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                <FormControl>
                  <Select
                    value={currentDetail.priority}
                    onChange={handleStatusChange}
                    displayEmpty
                    disabled
                    inputProps={{ "aria-label": "Select Status" }}
                    style={{
                      width: "100px",
                      height: "30px",
                      backgroundColor: getPriorityButtonColor(
                        currentDetail.priority
                      ),
                    }}
                  >
                    <MenuItem value={currentDetail.priority}>
                      {currentDetail.priority}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    value={currentDetail.label}
                    onChange={handleMyLabelChange}
                    displayEmpty
                    disabled
                    inputProps={{ "aria-label": "Select Status" }}
                    style={{
                      width: "85px",
                      height: "30px",
                      backgroundColor: getLabelButtonColor(currentDetail.label),
                    }}
                  >
                    <MenuItem value={currentDetail.label}>
                      {currentDetail.label}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail.farm}
              </Typography>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 30%, #f5faf8 30%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail.assign_to}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 25%, #f5faf8 25%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {moment(currentDetail.due_time, "HH:mm:ss").format("HH:mm")}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 25%, #f5faf8 25%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {moment(currentDetail?.end_date).format("DD/MM/YY")}
              </Typography>
            </ListItem>

            {currentDetail?.field1_value && (
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 32%, #f5faf8 32%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Start Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.field1_value}
                </Typography>
              </ListItem>
            )}
            {currentDetail?.field2_value && (
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 33%, #f5faf8 33%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  End Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.field2_value}
                </Typography>
              </ListItem>
            )}

            <Divider
              sx={{
                borderBottom: "1px solid #000000", // Adjust the border color as needed
                margin: "16px 0", // Adjust the margin as needed
              }}
              variant="middle"
            />

            {extractedText?.length > 0 ? (
              <>
                <ListItem style={{ marginTop: "15px" }}>
                  <Typography
                    variant="h4"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                    gutterBottom
                  >
                    Additional Instructions!
                  </Typography>
                </ListItem>

                <List>
                  {extractedText?.map((item, index) => (
                    <>
                      <ListItem key={index}>
                        <ListItemIcon sx={{ minWidth: "18px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                        >
                          {item.heading}
                        </Typography>
                      </ListItem>
                      <List style={{ paddingLeft: "35px", marginTop: "-15px" }}>
                        {item?.points?.map((point, pointIndex) => (
                          <ListItem key={pointIndex}>
                            {pointIndex + 1}.
                            <Typography
                              variant="h6"
                              color={colors.black.DEFAULT}
                              fontFamily="Lexend Deca"
                              sx={{ marginLeft: "3px" }}
                            >
                              {point}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  ))}
                </List>
              </>
            ) : null}

            {currentDetail?.proof_title1 ? (
              <>
                <ListItem style={{ marginTop: "15px" }}>
                  <Typography
                    variant="h4"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                    gutterBottom
                  >
                    Task Completed! Proof Attached.
                  </Typography>
                </ListItem>

                <ListItem sx={{ height: "64px" }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={1}>
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        <RadioButtonUncheckedIcon sx={{ fontSize: "small" }} />
                      </ListItemIcon>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography
                        variant="h6"
                        color={colors.black.DEFAULT}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginBottom: "6px",
                        }}
                      >
                        {currentDetail?.proof_title1}
                      </Typography>
                    </Grid>
                    {!showTextField && (
                      <Grid item xs={6} sm={7}>
                        {userDetails?.task_b_addAttachment && (
                          <>
                            <div>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Button
                                  onClick={handleAttachmentOpen}
                                  startIcon={<AttachmentIcon />}
                                  size="small"
                                  disabled={fileName}
                                  sx={{
                                    color: "#2C7B55",
                                    borderColor: "#2C7B55",
                                    marginRight: "5px",
                                  }}
                                  variant="outlined"
                                >
                                  <Typography
                                    variant="h6"
                                    fontFamily="Lexend Deca"
                                  >
                                    Add
                                  </Typography>
                                </Button>

                                <Button
                                  variant="contained"
                                  startIcon={<FileDownloadIcon />}
                                  sx={{
                                    backgroundColor: "#2C7B55", // Default background color
                                  }}
                                  size="small"
                                  disabled={!fileName}
                                  onClick={handleDownloadClick}
                                >
                                  <Typography
                                    variant="h6"
                                    fontFamily="Lexend Deca"
                                  >
                                    Download
                                  </Typography>
                                </Button>
                              </Stack>
                              <Dialog
                                open={isAttachmentDialog}
                                onClose={handleAttachmentClose}
                              >
                                <DialogTitle
                                  sx={{ position: "sticky", height: "4%" }}
                                >
                                  Upload Document
                                  <IconButton
                                    aria-label="close"
                                    onClick={handleAttachmentClose}
                                    sx={{
                                      position: "absolute",
                                      right: 1.9,
                                      top: 1,
                                      color: "grey",
                                    }}
                                  >
                                    <CloseSharpIcon />
                                  </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                  {selectedFile && (
                                    <img
                                      src={URL.createObjectURL(selectedFile)}
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "300px",
                                        marginBottom: "20px",
                                      }}
                                    />
                                  )}
                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                    accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                  />
                                </DialogContent>

                                <DialogActions
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginRight: "32px",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      backgroundColor: "#2C7B55", // Default background color
                                    }}
                                    onClick={handleSaveAttachmentClick}
                                  >
                                    <Typography
                                      variant="h6"
                                      color={colors.white.DEFAULT}
                                      fontFamily="Lexend Deca"
                                    >
                                      Save
                                    </Typography>
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </>
            ) : null}
          </List>

          <div style={{ marginTop: "auto" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                onClick={handleClose}
                sx={{
                  color: "#2C7B55",
                  borderColor: "#2C7B55",
                  marginRight: "5px",
                }}
                variant="outlined"
              >
                <Typography
                  variant="h6"
                  color={colors.green.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Close
                </Typography>
              </Button>
              {userDetails?.task_b_delete && (
                <>
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<DeleteIcon />}
                    sx={{
                      backgroundColor: "#2C7B55", // Default background color
                      "&:hover": {
                        backgroundColor: "#A45A52", // Background color on hover
                      },
                    }}
                    onClick={handleTaskDelete}
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Delete
                    </Typography>
                  </Button>
                </>
              )}

              <Dialog open={showConfirmation} onClose={handleConfirmationClose}>
                <DialogTitle style={{ textAlign: "center" }}>
                  <ErrorOutlineOutlined
                    style={{ width: "60px", height: "60px", color: "#A45A52" }}
                  />
                </DialogTitle>
                <DialogContent style={{ textAlign: "center" }}>
                  <Typography
                    variant="h4"
                    color={colors.red[100]}
                    fontWeight={typography.h1}
                    fontFamily="Lexend Deca"
                  >
                    Are you sure you want to delete this task?
                  </Typography>
                </DialogContent>
                <DialogActions sx={{ marginRight: "15px" }}>
                  <IconButton
                    aria-label="close"
                    onClick={handleConfirmationClose}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                  <Button
                    onClick={handleConfirmationOK}
                    sx={{
                      backgroundColor: "#2C7B55", // Default background color
                      "&:hover": {
                        backgroundColor: "#A45A52", // Background color on hover
                      },
                    }}
                    variant="contained"
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Ok
                    </Typography>
                  </Button>
                </DialogActions>
              </Dialog>
              {showPopup && (
                <div className="popup">
                  <div className="popup-content">
                    <p>Task Deleted!</p>
                    <Button onClick={() => setShowPopup(false)}>Close</Button>
                  </div>
                </div>
              )}
            </Stack>
          </div>
        </>
      )}
      {selectedTask === "Harvesting" && (
        <>
          <List>
            <ListItem
              style={{
                backgroundColor: "#b5cbbf",
                borderRadius: "8px",
                marginBottom: "10px",
                padding: "10px",
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",

                  borderRadius: "8px",
                }}
                primary={
                  <Typography
                    variant="h2"
                    color={colors.white.DEFAULT}
                    fontWeight={typography.h1}
                  >
                    {currentDetail.task_title.length > 15
                      ? `${currentDetail.task_title.slice(0, 15)}...`
                      : currentDetail.task_title}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem style={{ marginBottom: "15px" }}>
              <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                <FormControl>
                  <Select
                    value={currentDetail?.priority}
                    onChange={handleStatusChange}
                    displayEmpty
                    disabled
                    inputProps={{ "aria-label": "Select Status" }}
                    style={{
                      width: "100px",
                      height: "30px",
                      backgroundColor: getPriorityButtonColor(
                        currentDetail?.priority
                      ),
                    }}
                  >
                    <MenuItem value={currentDetail?.priority}>
                      {currentDetail?.priority}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    value={currentDetail?.label}
                    onChange={handleMyLabelChange}
                    displayEmpty
                    disabled
                    inputProps={{ "aria-label": "Select Status" }}
                    style={{
                      width: "85px",
                      height: "30px",
                      backgroundColor: getLabelButtonColor(
                        currentDetail?.label
                      ),
                    }}
                  >
                    <MenuItem value={currentDetail?.label}>
                      {currentDetail?.label}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail?.farm}
              </Typography>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 30%, #f5faf8 30%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail.assign_to}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 25%, #f5faf8 25%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {moment(currentDetail.due_time, "HH:mm:ss").format("HH:mm")}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 25%, #f5faf8 25%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {moment(currentDetail?.end_date).format("DD/MM/YY")}
              </Typography>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Zone&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail?.zone}
              </Typography>
            </ListItem>

            {currentDetail.field2_value ? (
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 38%, #f5faf8 38%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Weight (in plants)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.field2_value}
                </Typography>
              </ListItem>
            ) : null}

            {currentDetail?.field1_value ? (
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 38%, #f5faf8 38%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Weight (in Kg)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.field1_value}
                </Typography>
              </ListItem>
            ) : null}

            <Divider
              sx={{
                borderBottom: "2px solid #000000", // Adjust the border color as needed
                margin: "16px 0", // Adjust the margin as needed
              }}
              variant="middle"
            />

            {extractedText?.length > 0 ? (
              <>
                <ListItem style={{ marginTop: "15px" }}>
                  <Typography
                    variant="h4"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                    gutterBottom
                  >
                    Additional Instructions!
                  </Typography>
                </ListItem>

                <List>
                  {extractedText?.map((item, index) => (
                    <>
                      <ListItem key={index}>
                        <ListItemIcon sx={{ minWidth: "18px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                        {item.heading && (
                          <Typography
                            variant="h5"
                            color={colors.black.DEFAULT}
                            fontFamily="Lexend Deca"
                          >
                            {item.heading}
                          </Typography>
                        )}
                      </ListItem>
                      <List style={{ paddingLeft: "35px", marginTop: "-15px" }}>
                        {item?.points?.map((point, pointIndex) => (
                          <ListItem key={pointIndex}>
                            {pointIndex + 1}.
                            <Typography
                              variant="h6"
                              color={colors.black.DEFAULT}
                              fontFamily="Lexend Deca"
                              sx={{ marginLeft: "3px" }}
                            >
                              {point}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  ))}
                </List>
              </>
            ) : null}

            {currentDetail?.proof_title1 ? (
              <>
                <ListItem style={{ marginTop: "15px" }}>
                  <Typography
                    variant="h4"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                    gutterBottom
                  >
                    Task Completed! Proof Attached.
                  </Typography>
                </ListItem>

                <ListItem sx={{ height: "64px" }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={1}>
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        <RadioButtonUncheckedIcon sx={{ fontSize: "small" }} />
                      </ListItemIcon>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography
                        variant="h6"
                        color={colors.black.DEFAULT}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginBottom: "6px",
                        }}
                      >
                        {currentDetail?.proof_title1}
                      </Typography>
                    </Grid>
                    {!showTextField && (
                      <Grid item xs={6} sm={7}>
                        {userDetails?.task_b_addAttachment && (
                          <>
                            <div>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Button
                                  onClick={handleAttachmentOpen}
                                  startIcon={<AttachmentIcon />}
                                  size="small"
                                  disabled={fileName}
                                  sx={{
                                    color: "#2C7B55",
                                    borderColor: "#2C7B55",
                                    marginRight: "5px",
                                  }}
                                  variant="outlined"
                                >
                                  <Typography
                                    variant="h6"
                                    fontFamily="Lexend Deca"
                                  >
                                    Add
                                  </Typography>
                                </Button>

                                <Button
                                  variant="contained"
                                  startIcon={<FileDownloadIcon />}
                                  sx={{
                                    backgroundColor: "#2C7B55", // Default background color
                                  }}
                                  size="small"
                                  disabled={!fileName}
                                  onClick={handleDownloadClick}
                                >
                                  <Typography
                                    variant="h6"
                                    fontFamily="Lexend Deca"
                                  >
                                    Download
                                  </Typography>
                                </Button>
                              </Stack>
                              <Dialog
                                open={isAttachmentDialog}
                                onClose={handleAttachmentClose}
                              >
                                <DialogTitle
                                  sx={{ position: "sticky", height: "4%" }}
                                >
                                  Upload Document
                                  <IconButton
                                    aria-label="close"
                                    onClick={handleAttachmentClose}
                                    sx={{
                                      position: "absolute",
                                      right: 1.9,
                                      top: 1,
                                      color: "grey",
                                    }}
                                  >
                                    <CloseSharpIcon />
                                  </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                  {selectedFile && (
                                    <img
                                      src={URL.createObjectURL(selectedFile)}
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "300px",
                                        marginBottom: "20px",
                                      }}
                                    />
                                  )}
                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                    accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                  />
                                </DialogContent>

                                <DialogActions
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginRight: "32px",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      backgroundColor: "#2C7B55", // Default background color
                                    }}
                                    onClick={handleSaveAttachmentClick}
                                  >
                                    <Typography
                                      variant="h6"
                                      color={colors.white.DEFAULT}
                                      fontFamily="Lexend Deca"
                                    >
                                      Save
                                    </Typography>
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </>
            ) : null}
          </List>
          <div style={{ marginTop: "auto" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                onClick={handleClose}
                sx={{
                  color: "#2C7B55",
                  borderColor: "#2C7B55",
                  marginRight: "5px",
                }}
                variant="outlined"
              >
                <Typography
                  variant="h6"
                  color={colors.green.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Close
                </Typography>
              </Button>
              {userDetails?.task_b_delete && (
                <>
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<DeleteIcon />}
                    sx={{
                      backgroundColor: "#2C7B55", // Default background color
                      "&:hover": {
                        backgroundColor: "#A45A52", // Background color on hover
                      },
                    }}
                    onClick={handleTaskDelete}
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Delete
                    </Typography>
                  </Button>
                </>
              )}

              <Dialog open={showConfirmation} onClose={handleConfirmationClose}>
                <DialogTitle style={{ textAlign: "center" }}>
                  <ErrorOutlineOutlined
                    style={{ width: "60px", height: "60px", color: "#A45A52" }}
                  />
                </DialogTitle>
                <DialogContent style={{ textAlign: "center" }}>
                  <Typography
                    variant="h4"
                    color={colors.red[100]}
                    fontWeight={typography.h1}
                    fontFamily="Lexend Deca"
                  >
                    Are you sure you want to delete this task?
                  </Typography>
                </DialogContent>
                <DialogActions sx={{ marginRight: "15px" }}>
                  <IconButton
                    aria-label="close"
                    onClick={handleConfirmationClose}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                  <Button
                    onClick={handleConfirmationOK}
                    sx={{
                      backgroundColor: "#2C7B55", // Default background color
                      "&:hover": {
                        backgroundColor: "#A45A52", // Background color on hover
                      },
                    }}
                    variant="contained"
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Ok
                    </Typography>
                  </Button>
                </DialogActions>
              </Dialog>
              {showPopup && (
                <div className="popup">
                  <div className="popup-content">
                    <p>Task Deleted!</p>
                    <Button onClick={() => setShowPopup(false)}>Close</Button>
                  </div>
                </div>
              )}
            </Stack>
          </div>
        </>
      )}
      {selectedTask === "Irrigation" && (
        <>
          <List>
            <ListItem
              style={{
                backgroundColor: "#b5cbbf",
                borderRadius: "8px",
                marginBottom: "10px",
                padding: "10px",
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                  borderRadius: "8px",
                }}
                primary={
                  <Typography
                    variant="h2"
                    color={colors.white.DEFAULT}
                    fontWeight={typography.h1}
                  >
                    {currentDetail.task_title.length > 15
                      ? `${currentDetail.task_title.slice(0, 15)}...`
                      : currentDetail.task_title}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem style={{ marginBottom: "5px" }}>
              <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                <FormControl>
                  <Select
                    value={currentDetail?.priority}
                    onChange={handleStatusChange}
                    displayEmpty
                    disabled
                    inputProps={{ "aria-label": "Select Status" }}
                    style={{
                      width: "100px",
                      height: "30px",
                      backgroundColor: getPriorityButtonColor(
                        currentDetail?.priority
                      ),
                    }}
                  >
                    <MenuItem value={currentDetail?.priority}>
                      {currentDetail?.priority}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    value={currentDetail?.label}
                    onChange={handleMyLabelChange}
                    disabled
                    displayEmpty
                    inputProps={{ "aria-label": "Select Status" }}
                    style={{
                      width: "85px",
                      height: "30px",
                      backgroundColor: getLabelButtonColor(currentDetail.label),
                    }}
                  >
                    <MenuItem value={currentDetail?.label}>
                      {currentDetail?.label}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail?.farm}
              </Typography>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 30%, #f5faf8 30%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail.assign_to}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "15px",
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 25%, #f5faf8 25%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {moment(currentDetail.due_time, "HH:mm:ss").format("HH:mm")}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 25%, #f5faf8 25%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {moment(currentDetail?.end_date).format("DD/MM/YY")}
              </Typography>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Zone&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail?.zone}
              </Typography>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 58%, #f5faf8 58%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Volume per unit plant (in
                ml)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail?.field1_value}
              </Typography>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 32%, #f5faf8 32%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                pH of solution&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail?.field2_value}
              </Typography>
            </ListItem>

            <ListItem
              style={{
                marginBottom: "5px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 63%, #f5faf8 63%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Temperature of solution (in
                °C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {currentDetail?.field3_value}
              </Typography>
            </ListItem>

            {currentDetail?.field6_value ? (
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 63%, #f5faf8 63%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  EC/TDS of solution&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.field6_value}
                </Typography>
              </ListItem>
            ) : null}

            {currentDetail?.field7_value ? (
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 58%, #f5faf8 58%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  EC/TDS of solution&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail.field7_value}
                </Typography>
              </ListItem>
            ) : null}

            {currentDetail?.field8_value ? (
              <ListItem style={{ marginBottom: "15px" }}>
                <Typography
                  display="block"
                  variant="subtitle1"
                  component="div"
                  sx={{ fontWeight: "bold", color: "#4F4F4F" }}
                >
                  Irrigation Interval (in
                  mins)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography variant="body1" component="div">
                  {currentDetail?.field8_value}
                </Typography>
              </ListItem>
            ) : null}

            {currentDetail?.field10_value ? (
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 61%, #f5faf8 61%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Irrigation Interval ON (in
                  mins)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.field10_value}
                </Typography>
              </ListItem>
            ) : null}

            {currentDetail?.field9_value ? (
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 62%, #f5faf8 62%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Irrigation Interval OFF (in
                  mins)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.field9_value}
                </Typography>
              </ListItem>
            ) : null}

            <Divider
              sx={{
                borderBottom: "2px solid #000000", // Adjust the border color as needed
                margin: "16px 0", // Adjust the margin as needed
              }}
              variant="middle"
            />

            {extractedText?.length > 0 ? (
              <>
                <ListItem style={{ marginTop: "15px" }}>
                  <Typography
                    variant="h4"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                    gutterBottom
                  >
                    Additional Instructions!
                  </Typography>
                </ListItem>

                <List>
                  {extractedText?.map((item, index) => (
                    <>
                      <ListItem key={index}>
                        <ListItemIcon sx={{ minWidth: "18px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                        >
                          {item.heading}
                        </Typography>
                      </ListItem>
                      <List style={{ paddingLeft: "35px", marginTop: "-15px" }}>
                        {item?.points?.map((point, pointIndex) => (
                          <ListItem key={pointIndex}>
                            {pointIndex + 1}.
                            <Typography
                              variant="h6"
                              color={colors.black.DEFAULT}
                              fontFamily="Lexend Deca"
                              sx={{ marginLeft: "3px" }}
                            >
                              {point}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  ))}
                </List>
              </>
            ) : null}

            {currentDetail?.proof_title1 ? (
              <>
                <ListItem style={{ marginTop: "15px" }}>
                  <Typography
                    variant="h4"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                    gutterBottom
                  >
                    Task Completed! Proof Attached.
                  </Typography>
                </ListItem>

                <ListItem sx={{ height: "64px" }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={1}>
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        <RadioButtonUncheckedIcon sx={{ fontSize: "small" }} />
                      </ListItemIcon>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography
                        variant="h6"
                        color={colors.black.DEFAULT}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginBottom: "6px",
                        }}
                      >
                        {currentDetail?.proof_title1}
                      </Typography>
                    </Grid>
                    {!showTextField && (
                      <Grid item xs={6} sm={7}>
                        {userDetails?.task_b_addAttachment && (
                          <>
                            <div>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Button
                                  onClick={handleAttachmentOpen}
                                  startIcon={<AttachmentIcon />}
                                  size="small"
                                  disabled={fileName}
                                  sx={{
                                    color: "#2C7B55",
                                    borderColor: "#2C7B55",
                                    marginRight: "5px",
                                  }}
                                  variant="outlined"
                                >
                                  <Typography
                                    variant="h6"
                                    fontFamily="Lexend Deca"
                                  >
                                    Add
                                  </Typography>
                                </Button>

                                <Button
                                  variant="contained"
                                  startIcon={<FileDownloadIcon />}
                                  sx={{
                                    backgroundColor: "#2C7B55", // Default background color
                                  }}
                                  size="small"
                                  disabled={!fileName}
                                  onClick={handleDownloadClick}
                                >
                                  <Typography
                                    variant="h6"
                                    fontFamily="Lexend Deca"
                                  >
                                    Download
                                  </Typography>
                                </Button>
                              </Stack>
                              <Dialog
                                open={isAttachmentDialog}
                                onClose={handleAttachmentClose}
                              >
                                <DialogTitle
                                  sx={{ position: "sticky", height: "4%" }}
                                >
                                  Upload Document
                                  <IconButton
                                    aria-label="close"
                                    onClick={handleAttachmentClose}
                                    sx={{
                                      position: "absolute",
                                      right: 1.9,
                                      top: 1,
                                      color: "grey",
                                    }}
                                  >
                                    <CloseSharpIcon />
                                  </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                  {selectedFile && (
                                    <img
                                      src={URL.createObjectURL(selectedFile)}
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "300px",
                                        marginBottom: "20px",
                                      }}
                                    />
                                  )}
                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                    accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                  />
                                </DialogContent>

                                <DialogActions
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginRight: "32px",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      backgroundColor: "#2C7B55", // Default background color
                                    }}
                                    onClick={handleSaveAttachmentClick}
                                  >
                                    <Typography
                                      variant="h6"
                                      color={colors.white.DEFAULT}
                                      fontFamily="Lexend Deca"
                                    >
                                      Save
                                    </Typography>
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </>
            ) : null}
          </List>
          <div style={{ marginTop: "auto" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                onClick={handleClose}
                sx={{
                  color: "#2C7B55",
                  borderColor: "#2C7B55",
                  marginRight: "5px",
                }}
                variant="outlined"
              >
                <Typography
                  variant="h6"
                  color={colors.green.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Close
                </Typography>
              </Button>
              {userDetails?.task_b_delete && (
                <>
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<DeleteIcon />}
                    sx={{
                      backgroundColor: "#2C7B55", // Default background color
                      "&:hover": {
                        backgroundColor: "#A45A52", // Background color on hover
                      },
                    }}
                    onClick={handleTaskDelete}
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Delete
                    </Typography>
                  </Button>
                </>
              )}

              <Dialog open={showConfirmation} onClose={handleConfirmationClose}>
                <DialogTitle style={{ textAlign: "center" }}>
                  <ErrorOutlineOutlined
                    style={{ width: "60px", height: "60px", color: "#A45A52" }}
                  />
                </DialogTitle>
                <DialogContent style={{ textAlign: "center" }}>
                  <Typography
                    variant="h4"
                    color={colors.red[100]}
                    fontWeight={typography.h1}
                    fontFamily="Lexend Deca"
                  >
                    Are you sure you want to delete this task?
                  </Typography>
                </DialogContent>
                <DialogActions sx={{ marginRight: "15px" }}>
                  <IconButton
                    aria-label="close"
                    onClick={handleConfirmationClose}
                    sx={{
                      position: "absolute",
                      right: 1.9,
                      top: 1,

                      color: "grey",
                    }}
                  >
                    <CloseSharpIcon />
                  </IconButton>
                  <Button
                    onClick={handleConfirmationOK}
                    sx={{
                      backgroundColor: "#2C7B55", // Default background color
                      "&:hover": {
                        backgroundColor: "#A45A52", // Background color on hover
                      },
                    }}
                    variant="contained"
                  >
                    <Typography
                      variant="h6"
                      color={colors.white.DEFAULT}
                      fontFamily="Lexend Deca"
                    >
                      Ok
                    </Typography>
                  </Button>
                </DialogActions>
              </Dialog>
              {showPopup && (
                <div className="popup">
                  <div className="popup-content">
                    <p>Task Deleted!</p>
                    <Button onClick={() => setShowPopup(false)}>Close</Button>
                  </div>
                </div>
              )}
            </Stack>
          </div>
        </>
      )}
    </StyledDrawer>
  );
}

export default SideDrawer;
