import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Typography,
  FormHelperText,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import Chip from "@mui/material/Chip";
import { Tooltip, Stack, useTheme } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../../theme";
import jwtDecode from "jwt-decode";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const HarvestingDialog = ({
  dialogOpen,
  setDialogOpen,
  masterDialogState,
  listOfZones,
  fetchTaskData,
}) => {
  // const [listOfZones, setListOfZones] = useState([]);

  console.log("LISTTTTTTTTTTTTTTTTTTTTT", listOfZones);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [harvestingDialogState, setHarvestingDialogState] = useState({
    title: "",
    selectedColor: "#3174ad",
    selectedColorDescription: null,
    weightInKg: "",
    weightInPlants: "",
    selectedCrop: "",
    selectedZone: "",
    description: "",
    showPopup: false,
    open: false,
    selectedProofs: [],
    selectedFields: [],
    savedText: "",
    descriptions: [],
    showAttachment: false,
    showAddDescription: true,
    dataTypes: [],
  });
  const {
    title,
    selectedColor,
    selectedColorDescription,
    selectedCrop,
    selectedZone,
    description,
    showPopup,
    selectedProofs,
    selectedFields,
    savedText,
    descriptions,
    showAttachment,
    showAddDescription,
    dataTypes,
    weightInKg,
    weightInPlants,
  } = harvestingDialogState;

  const hiddenToken = localStorage.getItem("token");
  const client_id = jwtDecode(hiddenToken).client_id;
  const [proofDetailsAdded, setProofDetailsAdded] = useState(false);
  const updateState = (key, value) => {
    setHarvestingDialogState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async (e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);

    console.log("Handle Submit Entered !");
    e.preventDefault();
    const formData = {
      task_title: title,
      priority_color: selectedColor,
      zone: selectedZone,
      crop: selectedCrop,
      weightInKg: weightInKg,
      weightInPlants: weightInPlants,
      proof: selectedProofs.length > 0 ? selectedProofs : null, // Set value based on the selectedProofs array
      instruction: description,
      priority_color_description: selectedColorDescription,
      masterDialogData: masterDialogState,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/taskschedule/create`,
        {
          method: "POST",
          headers: {
            "content-Type": "application/json",
            client_id: client_id,
          },
          body: JSON.stringify(formData),
          signal, // Pass the signal to the fetch request
        }
      );
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

      if (response.ok) {
        console.log("Task Allocated");
        updateState("showPopup", true);
      } else {
        console.log("Harvesting Task not Allocated");
        alert(
          "Sorry, an error occurred while Adding the task. Please try again later."
        );
      }
    } catch (error) {
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      console.log(error);
      alert(
        "Sorry, an error occurred while Adding the task. Please try again later."
      );
    }
    console.log("DATA AFTER CREATE TASK IS CLICKED", formData);
    e.target.reset();
    console.log("HARVESTING FORM DATA", formData);
  };

  const [cropData, setCropData] = useState([]);

  useEffect(() => {
    console.log("Selected zone changed:", selectedZone);
    fetchCropData();
  }, [selectedZone]);

  const fetchCropData = async () => {
    const farmName = masterDialogState.myFarm;
    const zoneName = selectedZone;
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_TASK_MANAGEMENT_ZONE_CROP(farmName, zoneName)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        setCropData(data?.rows);
      } else {
        console.log("Crop data not received");
      }
    } catch (error) {
      console.log("Error fetching crop data:", error);
    }
  };

  console.log(
    "Crop Dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    cropData
  );

  const handleCropChange = async (e) => {
    const selectedCrop = e.target.value;
    updateState("selectedCrop", selectedCrop);
  };

  const handleSelectedColorDescriptionChange = async (e) => {
    const selectedColorDescription = e.target.value;
    updateState("selectedColorDescription", selectedColorDescription);
  };

  const handleZoneChange = async (e) => {
    const selectedZone = e.target.value;
    updateState("selectedZone", selectedZone);
  };

  const handlePopupClose = () => {
    updateState("open", false);
    updateState("showPopup", false);
    setDialogOpen(false);
    fetchTaskData();
  };

  const handleFieldChange = (event) => {
    updateState("selectedFields", event.target.value);
  };

  const handleTitleChange = (event) => {
    updateState("title", event.target.value);
  };

  const handleColorChange = (event) => {
    updateState("selectedColor", event.target.value);
  };

  const handleDescriptionChange = (e, index) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = e.target.value;
    updateState("descriptions", updatedDescriptions);
  };

  const handleDataTypeChange = (e) => {
    updateState("dataTypes", [e.target.value]);
  };

  const handleSave = () => {
    const proofs = descriptions.map((desc, index) => {
      const attachmentType = dataTypes[index] ? `${dataTypes[index]}` : "";
      setProofDetailsAdded(true);
      return { description: desc, attachmentType };
    });
    updateState("selectedProofs", proofs); // Update selectedProofs here
    updateState(
      "savedText",
      proofs
        .map((proof) => `${proof.description} | ${proof.attachmentType}`)
        .join("\n")
    );
    updateState("descriptions", []);
    updateState("dataTypes", []);
    updateState("showAddDescription", true);
    updateState("showAttachment", false);
  };

  const handleAddDescription = () => {
    updateState("showAddDescription", false);
    updateState("showAttachment", true);
    updateState("descriptions", [...descriptions, ""]);
  };

  const modules = {
    toolbar: {
      container: [[{ list: "ordered" }]],
    },
  };

  const formats = ["list"];

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { minWidth: "300px", minHeight: "300px" } }}
    >
      <DialogTitle>
        <Typography variant="h3" color={colors.green.DEFAULT}>
          Harvesting
        </Typography>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} marginTop={"28px"}>
              <TextField
                label="Activity Title"
                value={title}
                onChange={handleTitleChange}
                fullWidth
                size="small"
                autoFocus
                required
                inputProps={{ maxLength: 15 }}
                // style={{ marginBottom: "16px" }}
              />
              <FormHelperText>Max limit 15 words</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
              <TextField
                id="input-with-icon-textfield"
                label="Priority Color"
                inputProps={{ maxLength: 15 }}
                value={selectedColorDescription}
                size="small"
                onChange={handleSelectedColorDescriptionChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <input
                        type="color"
                        id="color"
                        value={selectedColor}
                        onChange={handleColorChange}
                        style={{
                          width: "24px",
                          height: "24px",
                          padding: "0",
                          border: "none",
                          borderRadius: "50%",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "13px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Zone</InputLabel>
                <Select
                  labelId="Select Zone"
                  id="Select Zone"
                  fullWidth
                  value={selectedZone}
                  label="Select Zone"
                  required
                  size="small"
                  displayEmpty
                  inputProps={{ "arial-label": "Select Zone" }}
                  onChange={handleZoneChange}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {listOfZones &&
                    listOfZones?.map((prop) => (
                      <MenuItem key={prop} value={prop}>
                        {prop}
                      </MenuItem>
                    ))}
                  <MenuItem disabled value="">
                    Select Zone
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: "13px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Crop</InputLabel>
                <Select
                  labelId="Select Crop"
                  id=" Select Crop"
                  fullWidth
                  value={selectedCrop}
                  label="Select Crop"
                  size="small"
                  displayEmpty
                  inputProps={{ "arial-label": "Select Crop" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  onChange={handleCropChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {cropData && cropData?.length > 0 ? (
                    cropData?.map((prop) => (
                      <MenuItem key={prop?.crop_name} value={prop.crop_name}>
                        {prop?.crop_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>
                      No Crop found for selected Zone
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "19px" }}>
              <FormControl fullWidth>
                <InputLabel>Harvesting Quantity</InputLabel>
                <Select
                  labelId=" Harvesting Quantity"
                  id=" Harvesting Quantity"
                  label="Harvesting Quantity"
                  multiple
                  required
                  size="small"
                  value={selectedFields}
                  onChange={handleFieldChange}
                  displayEmpty
                  inputProps={{ "arial-label": "Harvesting Quantity" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {selected?.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  fullWidth
                >
                  <MenuItem value="No. of Plants">Number of Plants</MenuItem>
                  <MenuItem value="Kg">Kg</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {selectedFields?.includes("Kg") && (
                <TextField
                  label="in Kg"
                  variant="standard"
                  fullWidth
                  size="small"
                  value={weightInKg}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value) && value > 0) {
                      // setpH(value);
                      updateState("weightInKg", value);
                    } else {
                      // setpH("");
                      updateState("weightInKg", "");
                    }
                  }}
                  inputProps={{
                    min: 0,
                   
                  }}
                  autoFocus
                  type="number"
                />
              )}
              {selectedFields?.includes("No. of Plants") && (
                <TextField
                  label="No. of Plants"
                  variant="standard"
                  fullWidth
                  size="small"
                  value={weightInPlants}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value) && value > 0) {
                      // setpH(value);
                      updateState("weightInPlants", value);
                    } else {
                      // setpH("");
                      updateState("weightInPlants", "");
                    }
                  }}
                  inputProps={{
                    min: 0,
                 
                  }}
                  autoFocus
                  type="number"
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
              {descriptions?.map((description, index) => (
                <TextField
                  key={index}
                  label="Proof title"
                  fullWidth
                  size="small"
                  margin="normal"
                  value={description}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => handleDescriptionChange(e, index)}
                />
              ))}
              {proofDetailsAdded ? (
                <>
                  <Tooltip
                    title="Proof Type already added!"
                    placement="right"
                    disableHoverListener={!proofDetailsAdded}
                  >
                    <Button
                      variant="outlined"
                      endIcon={
                        <AddIcon style={{ color: colors.green.DEFAULT }} />
                      }
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      onClick={handleAddDescription}
                      disabled
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Proof List
                      </Typography>
                    </Button>
                  </Tooltip>
                </>
              ) : (
                showAddDescription && (
                  <Tooltip
                    title="Click to add the attachments that supervisor needs to send as a proof of completion of task !"
                    placement="right"
                  >
                    <Button
                      variant="outlined"
                      endIcon={
                        <AddIcon style={{ color: colors.green.DEFAULT }} />
                      }
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      onClick={handleAddDescription}
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Proof List
                      </Typography>
                    </Button>
                  </Tooltip>
                )
              )}
            </Grid>

            {showAttachment && (
              <>
                <Grid item xs={12} sm={4} style={{ marginTop: "10px" }}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Select Proof type</InputLabel>
                    <Select
                      value={dataTypes[0] || ""}
                      label="Select Proof type"
                      onChange={handleDataTypeChange}
                      displayEmpty
                      size="small"
                      inputProps={{ "aria-label": "Select Proof type" }}
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                    >
                      <MenuItem value="PDF">PDF</MenuItem>
                      <MenuItem value="DOC">DOC</MenuItem>
                      <MenuItem value="Image">Image</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={1.2}>
                  <Button
                    sx={{
                      borderColor: colors.green.DEFAULT,
                      color: colors.green.DEFAULT,
                    }}
                    variant="outlined"
                    style={{
                      float: "left",
                      marginLeft: "16px",
                      marginTop: "19px",
                    }}
                    onClick={handleSave}
                  >
                    <Typography variant="h6" color={colors.green.DEFAULT}>
                      Save
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
              <ReactQuill
                style={{
                  height: "100px",
                  width: "100%",
                }}
                placeholder="Additional instructions for this activity!"
                modules={modules}
                formats={formats}
                value={description} // Add value prop
                onChange={(value) => updateState("description", value)} // Update description state
              />
            </Grid>
            {savedText && (
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  marginTop: "-48%",
                  marginLeft: "52%",
                  backgroundColor: "#ecf4f0",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  # {savedText}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Grid item xs={12} sm={6} style={{ marginTop: "90px" }}>
              <Stack
                spacing={1}
                direction="row"
                style={{
                  height: "32px",
                  marginTop: "2%",
                  marginRight: "1%",
                  marginLeft: "40%",
                }}
              >
                <Button
                  type="submit" // Added type="submit" to trigger form submission
                  variant="contained"
                  sx={{ backgroundColor: colors.green.DEFAULT }}
                  style={{ float: "left" }}
                >
                  <Typography variant="h6" color={colors.white.DEFAULT}>
                    Create
                  </Typography>
                </Button>
                <Button
                  onClick={() => setDialogOpen(false)}
                  variant="outlined"
                  style={{ float: "left", marginLeft: "15px" }}
                >
                  <Typography variant="h6" color={colors.green.DEFAULT}>
                    Cancel
                  </Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>

          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <p>Task Added successfully!</p>
                <Button onClick={handlePopupClose}>Close</Button>
              </div>
            </div>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default HarvestingDialog;
