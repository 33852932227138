import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  useTheme,
  InfoOutlined,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import { GoAlert } from "react-icons/go";
import { GoAlertFill } from "react-icons/go";
import { MdErrorOutline } from "react-icons/md";

import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { tokens } from "../WMStheme";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const containerStyle = {
  maxHeight: "78vh",
  height: "100%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  // backgroundColor: "green",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "78vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};
const containerStyle8 = {
  maxHeight: "74vh",
  height: "80%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  //  backgroundColor: "pink",
};
const gridContainerStyle = {
  marginTop: "1%",
  display: "flex",
  height: "71vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "blue",
};

const ErrorDialogBox = ({
  open,
  onClose,
  title,
  content,
  discardText,
  okayText,
  handleDiscardClick,
  handleOkayClick,
  msg,
  columns,
  data,
}) => {
  function formatContentWithLineBreaks(content) {
    // Replace full stops with full stop + line break + spacing
    return content.replace(/\./g, ".<br />");
  }
  const formatDateIST = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} || ${hours}:${minutes}`;
  };
  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent({ data }) {
    return (
      <>
        <TableBody>
          {data?.map((row, index) => (
            <StyledTableRow key={index} style={{ cursor: "pointer" }}>
              {columns?.map((column) => (
                <StyledTableCell key={column?.id} align="center">
                  {column?.id === "created_at"
                    ? formatDateIST(row[column?.id])
                    : column?.id === "updated_at"
                    ? formatDateIST(row[column?.id])
                    : row[column.id]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </>
    );
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function formatContentWithLineBreaks(content) {
    // Replace full stops with full stop + line break + spacing
    return content.replace(/\./g, ".<br />");
  }
  console.log("data in error dialog", data);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      size="md"
      sx={{ maxWidth: "100%", maxHeight: "100%" }}
    >
      <DialogTitle sx={{ position: "sticky", width: "600px", height: "3%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MdErrorOutline
            style={{
              fontSize: "48px",
              color: colors.redAccent[500],
              marginBottom: "3%",
            }}
          />
        </div>
        <Typography
          variant="h4"
          color={colors.redAccent[500]}
          sx={{ marginRight: "1%" }}
          dangerouslySetInnerHTML={{
            __html: formatContentWithLineBreaks(title),
          }}
        />
        <Typography
          variant="h4"
          color={colors.redAccent[500]}
          sx={{ marginRight: "1%" }}
        >
          {msg}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,
            color: "grey",
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          variant="h5"
          color={colors.redAccent[500]}
          sx={{ marginRight: "1%" }}
        ></Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 200 }} aria-label="customized table">
            <TableHeadComponent columns={columns} />
            <TableRowsComponent columns={columns} data={data} />
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export { ErrorDialogBox };
