import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../../theme";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import InventoryTable from "../../GridDisplay/Live/Live";
import InventoryTableDamage from "../../GridDisplay/Damage/Damage";
import InventoryTableHarvesting from "../../GridDisplay/Harvesting/Harvesting";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
// import InventoryConfig from "../../Form/ConfigForm/Configuration";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AgricultureRoundedIcon from "@mui/icons-material/AgricultureRounded";
import { faPlantWilt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import AddIcon from "@mui/icons-material/Add";
// import "./DataDisplay.css";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Stack,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";

import { API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
function DataDisplay(name) {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // const farm_name = location.state.name;
  const props = location?.state?.name;
  const farm_name = location?.state?.name?.name;
  const farm_id = props?.farmId;
 
  const [tableKey, setTableKey] = useState(0);
  const [activeButton, setActiveButton] = useState("live");
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State to control dialog visibility
  const [track, setTrack] = useState(false);
  const [open, setOpen] = useState(false);
  const [hoveredButton, setHoveredButton] = useState("");
  const [showDailog, setShowDailog] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleButtonHover = (buttonName) => {
    setHoveredButton(buttonName);
  };
  //console.log("data2",data2);
  function handleButtonClick(type) {
    setActiveButton(type);
  }

  function handleBack() {
    navigate("/inventory");
  }

  const handleOpen = () => {
    console.log("i got clickeddddddd");
    setIsDialogOpen(true); // Open the dialog when the button is clicked
    setOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false); // Close the dialog when needed
    setOpen(false);
  };
  const handleButtonClick1 = () => {
    setShowDailog(true);
  };
  const buttonStyle = {
    border: "0.5px solid black",
    color: "#000000",
  };

  const [isActionPerformed, setIsActionPerformed] = useState(false);

  // Define a function to be triggered by the child
  const handleChildAction = () => {
    // setIsActionPerformed(true);
    setTableKey((prevKey) => prevKey + 1);
    setApiSuccessOpen(true);
    // console.log("i am trueeeeeeeeeeeeeeeeeeeeeeeeeeeee");
  };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
          // backgroundColor:'pink'
          // backgroundColor: "rgb(44 123 85)",
        }}
      >
        <Typography
          variant="h1"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "2.6%" }}
        >
          Monitor Farms
        </Typography>
        {/* <InventoryRoundedIcon sx={{ fontSize: 32, marginLeft: "1px" }} />
          </Typography> */}

        <Stack spacing={2} direction="row">
          {activeButton === "live" && (
            <Button
              variant="contained"
              startIcon={<AddIcon style={{ color: colors.white.DEFAULT }} />}
              sx={{
                borderColor: colors.green.DEFAULT,
                backgroundColor: colors.green.DEFAULT,
              }}
              onClick={handleOpen}
              title="Add Inventory"
            >
              <Typography variant="h5" color={colors.white.DEFAULT}>
                Add Live Inventory
              </Typography>
            </Button>
          )}

          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon style={{ color: colors.green.DEFAULT }} />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h5" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
      {isDialogOpen && (
        <InventoryConfig
          farmname={farm_name}
          onClose={handleClose}
          isDialogOpen={isDialogOpen}
          onChildAction={handleChildAction}
          farm_id={farm_id}
        />
      )}
      <Stack
        direction="row"
        spacing={0.6}
        style={{ marginLeft: "36%", width: "700px", marginTop: "1.5%" }}
      >
        <Button
          className="button-with-icon"
          style={{
            ...buttonStyle,
            border:
              activeButton === "live"
                ? "2px solid #29B94A"
                : "0.5px solid #7d7d7d",
            color: activeButton === "live" ? "#29B94A" : "#000000",
            borderRadius: "15px",
            width: "160px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "10px",
          }}
          startIcon={<LiveTvIcon sx={{ fontSize: 14 }} />}
          onClick={() => handleButtonClick("live")}
        >
          <Typography variant="h6">Live</Typography>
        </Button>

        <Button
          className="button-with-icon"
          style={{
            border:
              activeButton === "harvesting"
                ? "2px solid #eeba27"
                : "0.5px solid #7d7d7d",
            color: activeButton === "harvesting" ? "#eeba27" : "#000000",
            borderRadius: "15px",
            width: "199px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "10px",
          }}
          startIcon={
            <AgricultureRoundedIcon sx={{ fontSize: 14 }} Harvesting />
          }
          onClick={() => handleButtonClick("harvesting")}
        >
          <Typography variant="h6">Harvest</Typography>
        </Button>

        <Button
          className="button-with-icon"
          style={{
            border:
              activeButton === "damage"
                ? "2px solid #ff5555"
                : "0.5px solid #7d7d7d",
            color: activeButton === "damage" ? "#ff5555" : "#000000",

            borderRadius: "15px",
            width: "190px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "4px",
          }}
          onClick={() => handleButtonClick("damage")}
          startIcon={
            <FontAwesomeIcon
              icon={faPlantWilt}
              size="0.4px"
              width="16px"
              style={{
                color: activeButton === "damage" ? "#ff5555" : "#000000",
              }}
            />
          }
        >
          <Typography variant="h6">Damage</Typography>
        </Button>
        {/* <InventoryConfig farmname={farm_name} /> */}
      </Stack>

      {activeButton === "live" && (
        <InventoryTable
          farmname={farm_name}
          key={tableKey}
          farmId={farm_id}
          // isChanged={isActionPerformed}
        />
      )}
      {activeButton === "harvesting" && (
        <InventoryTableHarvesting farmname={farm_name} farmId={farm_id} />
      )}
      {activeButton === "damage" && (
        <InventoryTableDamage farmname={farm_name} farmId={farm_id} />
      )}

      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)}
      />
      
    </div>
  );
}

function InventoryConfig({
  farmname,
  onClose,
  isDialogOpen,
  onChildAction,
  farm_id,
}) {
  
  const [cropList, setCropList] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState("");
  const [selectedCropId, setSelectedCropId] = useState("");
  const [varietyList, setVarietyList] = useState([]);
  const [selectedVariety, setSelectedVariety] = useState("");
  const [phaseList, setPhaseList] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState("");
  const [zones, setZones] = useState([]);
  const [data, setData] = useState({});

  const [selectedFarm, setSelectedFarm] = useState(farmname);

  const [selectedZone, setSelectedZone] = useState("");
  const [selectedZoneId, setSelectedZoneId] = useState(0);
  const [selectedVarietyId, setSelectedVarietyId] = useState(0);
  const [batch, setBatch] = useState("");
  const [numPlants, setNumPlants] = useState(0);
  const [sowingDate, setSowingDate] = useState("");
  const [locationStart, setLocationStart] = useState("");
  const [locationEnd, setLocationEnd] = useState("");
  const [description, setDescription] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    fetchCropVarieties();
    fetchFarmZones();
  }, []);
  
  const fetchCropVarieties = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_CONFIG_CROPNAME,
        {
          params: {
            farm_id: farm_id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const cropVarieties = response?.data?.rows?.map((row) => ({
          crop: row?.crop_name,
          crop_id: row?.crop_id_fk,
          varieties: Array.isArray(row?.varieties) ? row?.varieties : [],
        }));
        setCropList(cropVarieties);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const fetchFarmZones = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_CONFIG_FARMNAME,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data1 = await response?.data;
      setData(data1);
      // Find the farm object that matches the specified farmname
      const matchingFarm = data1?.rows?.find(
        (row) => row?.farm_name === farmname
      );

      if (matchingFarm) {
        const zones = matchingFarm.zones || []; // Access the zones property of the matching farm

        setData(data1);
        setZones(zones);
      } else {
        console.log("No matching farm found for the provided farmname.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const fetchPhase = async (selectedCrop, selectedVariety, id) => {
    console.log("selectedVarietyId from function", id);
    try {
      const response = await axiosInstance.put(
        API_ENDPOINTS.PUT_CROPMANAGEMENT_PHASE,
        {
          crop: selectedCrop,
          crop_id: selectedCropId,
          variety: selectedVariety,
          crop_variety_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("responseeeeeeeeee", response);

      const phase = Object?.keys(response?.data?.result)?.map((key) => ({
        phase: response?.data?.result[key],
      }));

      // console.log("Phase:", phase);
      setPhaseList(phase);
    } catch (error) {
      console.log("Error fetching phases:", error);
    }
  };

  const fetchData = async () => {
    try {
     
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_CROPMANAGEMENT_CONFIG_LIVEINVENTORY(
          farm_id,
          5,
          1
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        let data = response?.data;
        const formattedData = data?.rows.map((item) => {
          const formatteddos = moment(item.date_of_sow)?.format("DD-MM-YYYY");
          let formatteddot = moment(item.date_of_transplant)?.format(
            "DD-MM-YYYY"
          );
          if (formatteddot === "Invalid date") {
            formatteddot = "NT";
          }
          const formattededoh = moment(item?.exp_date_of_harvest).format(
            "DD-MM-YYYY"
          );
          return {
            ...item,
            formatteddos,
            formatteddot,
            formattededoh,
          };
        });
        data = formattedData;
        console.log("data", data);
        setRows(data);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleCropChange = async (e) => {
    const selectedCrop = e.target.value;
    setSelectedCrop(selectedCrop);
    setSelectedVariety("");
    const selectedCropObject = cropList?.find(
      (crop) => crop.crop === selectedCrop
    );
    console.log("selected_crop:", selectedCrop);
    console.log("selected_crop:", selectedCrop);

    console.log("selectedCropObject", selectedCropObject);
    if (selectedCropObject) {
      setVarietyList(selectedCropObject.varieties);
      setSelectedCropId(selectedCropObject.crop_id);
    } else {
      setVarietyList([]);
    }
  };

  

  const handleZoneChange = async (event) => {
    const selectedZone = event.target.value;

    const selectedZoneObject = zones?.find(
      (zone) => zone.zone_name === selectedZone
    );

    setSelectedZone(selectedZone);
    setSelectedZoneId(selectedZoneObject.zone_id);
  };

  const handleVarietyChange = (e) => {
    const selectedVariety = e.target.value;
    console.log("selectedVariety", selectedVariety);
    const selectedVarietyObject = varietyList?.find(
      (variety) => variety.variety_name === selectedVariety
    );
    console.log("selectedVarietyObject", selectedVarietyObject);
    const id = selectedVarietyObject.variety_id;
    console.log("iddddddddddddddd", id);
    setSelectedVarietyId(selectedVarietyObject.variety_id);
    console.log("selectedVarietyId", id);
    setSelectedVariety(selectedVariety);
    fetchPhase(selectedCrop, selectedVariety, id);
  };

  const handlePhaseChange = async (e) => {
    const selectedPhase = e.target.value;
    setSelectedPhase(selectedPhase);
  };
  
  const handlePopupClose = () => {
    setOpen(false);
    setShowPopup(false);
    // navigate('/');
  };
  const handleClose = () => {
    onClose();
    setOpen(false);
    setShowPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.target.reset();
    const formData = {
      crop_name: selectedCrop,
      crop_variety_name: selectedVariety,
      batch: batch,
      tofarmName: selectedFarm,
      tozoneName: selectedZone,
      no_of_plants: numPlants,
      date_of_sow: sowingDate,
      starting_location: locationStart,
      ending_location: locationEnd,
      note: description,
      actual_phase: selectedPhase,
      farm_id: farm_id,
      farm_zone_id: selectedZoneId,
      crop_id: selectedCropId,
      crop_variety_id: selectedVarietyId,
    };
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_CROPMANAGEMENT_CONFIG_ADDINVENTORY,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("responseeeeeeeeee", response);
      console.log(response?.data);
    } catch (error) {
      console.log("error", error);
    }
    console.log("BEFORE FETCH DATA------");
    await fetchData();

    setShowPopup(true);
    onChildAction();
    onClose();
    console.log("AFTER FETCH DATA------");
  };

  return (
    <>
      
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        Width="200%"
        sx={{ borderRadius: "29px" }}
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={9} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="Select Crop">Select Crop*</InputLabel>
                  <Select
                    labelId="Select Crop"
                    id="Select Crop"
                    value={selectedCrop}
                    label="Select Crop"
                    required
                    size="small"
                    displayEmpty
                    inputProps={{ "arial-label": "Select Crop" }}
                    onChange={handleCropChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "200px",
                          overflowY: "auto",
                        },
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }

                    // style="margin-bottom: 10px"
                  >
                    <MenuItem value="">Select Crop</MenuItem>
                    {cropList?.map((cropOption) => (
                      <MenuItem
                        key={cropOption?.crop_name}
                        value={cropOption?.crop}
                      >
                        {cropOption?.crop}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9} sm={6}>
                {/* {selectedCrop && ( */}
                <FormControl fullWidth>
                  <InputLabel id="Select Variety">Select Variety*</InputLabel>
                  <Select
                    labelId="Select Variety"
                    id="Select Variety"
                    label="Select Variety"
                    size="small"
                    value={selectedVariety}
                    onChange={handleVarietyChange}
                    required
                    displayEmpty
                    inputProps={{ "arial-label": "Select Variety" }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  >
                    <MenuItem value="">Select Variety</MenuItem>
                    {varietyList?.map((varietyOption) => (
                      <MenuItem
                        key={varietyOption.variety_id}
                        value={varietyOption.variety_name}
                      >
                        {varietyOption.variety_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* )} */}
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={9} sm={6}>
                <TextField
                  label="Batch"
                  name="batch"
                  value={batch}
                  size="small"
                  onChange={(event) => setBatch(event.target.value)}
                  readOnly
                  required
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={6} style={{ marginTop: "8px" }}>
                <FormControl
                  fullWidth
                  disabled={!selectedCrop || !selectedVariety}
                >
                  <InputLabel id="Select Phase">Select Phase*</InputLabel>
                  <Select
                    labelId="Select Phase"
                    id="Select Phase"
                    value={selectedPhase}
                    label="Select Phase"
                    size="small"
                    required
                    displayEmpty
                    inputProps={{ "arial-label": "Select Phase" }}
                    onChange={handlePhaseChange}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }

                    // style="margin-bottom: 10px"
                  >
                    <MenuItem value="">Select Phase</MenuItem>
                    {phaseList?.map((phaseOption) => (
                      <MenuItem
                        key={phaseOption?.phase}
                        value={phaseOption?.phase}
                      >
                        {phaseOption?.phase}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={9} sm={6} style={{ marginTop: "15px" }}>
                <FormControl fullWidth>
                  <TextField
                    id="farm"
                    label="Farm"
                    value={selectedFarm}
                    fullWidth
                    required
                    size="small"
                    InputProps={{
                      readOnly: true,
                      style: { width: "100%" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={9} sm={6} style={{ marginTop: "15px" }}>
                <FormControl fullWidth>
                  <InputLabel id="Select Zone"> Select Zone*</InputLabel>
                  <Select
                    labelId=" Select Zone"
                    id="Select Zone"
                    label="Select Zone"
                    size="small"
                    value={selectedZone}
                    // onChange={(event) => setSelectedZone(event.target.value)}
                    onChange={handleZoneChange}
                    required
                    displayEmpty
                    inputProps={{ "arial-label": "Select Zone" }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  >
                    {zones?.map((zone) => (
                      <MenuItem key={zone.zone_id} value={zone.zone_name}>
                        {zone.zone_name}
                      </MenuItem>
                    ))}
                    <MenuItem disabled value="">
                      Select Zone
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={9} sm={6} style={{ marginTop: "5px" }}>
                <TextField
                  label="No. of Plants"
                  name="plants"
                  type="number"
                  size="small"
                  pattern="^[0-9]+(\\.[0-9]{1,3})?$"
                  required
                  value={numPlants}
                  onChange={(event) =>
                    setNumPlants(parseInt(event.target.value, 10))
                  }
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    min: 1,
                    max: 99999,
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={6} style={{ marginTop: "5px" }}>
                <TextField
                  label="Date Of Sowing"
                  name="sowingDate"
                  type="date"
                  size="small"
                  value={sowingDate}
                  onChange={(event) => setSowingDate(event.target.value)}
                  required
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    style: { width: "100%" },
                    min: new Date(new Date().getFullYear(), 0, 30)
                      .toISOString()
                      .split("T")[0],
                    max: new Date().toISOString().split("T")[0],
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={9} sm={6}>
                <TextField
                  label="Start Location"
                  name="locationStart"
                  value={locationStart}
                  size="small"
                  onChange={(event) => setLocationStart(event.target.value)}
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  label="End Location"
                  name="locationEnd"
                  value={locationEnd}
                  size="small"
                  onChange={(event) => setLocationEnd(event.target.value)}
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              {/* <Grid item xs={9} sm={6}>
                  <Tooltip title="Upload Sowing Crop Image">
                    <TextField
                      name="Upload Photo"
                      type="file"
                      value={selectedFile}
                      onChange={(event) => setSelectedFile(event.target.value)}
                      fullWidth
                      size="small"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AiOutlineCloudUpload size={20} />
                          </InputAdornment>
                        ),
                        style: {
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "100%",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "100%",
                        },
                      }}
                    />
                  </Tooltip>
                </Grid> */}

              <Grid item xs={12} sm={12}>
                <TextField
                  label="Description"
                  name="description"
                  size="small"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { width: "100%" },
                    max: 256,
                  }}
                />
              </Grid>
            </Grid>
            <Stack
              spacing={1}
              direction="row"
              style={{ height: "35px", marginLeft: "61%", marginTop: "2%" }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: colors.green.DEFAULT }}
              >
                <Typography variant="h6" color={colors.white.DEFAULT}>
                  Submit
                </Typography>
              </Button>
              <Button onClick={handleClose} variant="outlined">
                <Typography variant="h6" color={colors.green.DEFAULT}>
                  Cancel
                </Typography>
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
      {showPopup && (
        <div className="popup">
          <div className="popup-content" style={{ width: "250px" }}>
            <Typography variant="h4" style={{ marginTop: "8%" }}>
              Inventory Added successfully!
            </Typography>

            <Button onClick={handlePopupClose} style={{ marginTop: "10%" }}>
              <Typography variant="h4" color={colors.green.DEFAULT}>
                Close
              </Typography>
            </Button>
          </div>
        </div>
      )}
      {/* </div> */}
    </>
  );
}

export default DataDisplay;
