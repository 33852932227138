import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";
import SuccessPopUp from "../../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../../PopUpMsg/ErrorPopUp";
import { useSelector, useDispatch } from "react-redux";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import {
  Button,
  Autocomplete,
  TextField,
  Stack,
  Chip,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { MdModeEdit, MdPeopleAlt } from "react-icons/md";

import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../WMStheme";
import { WarehouseOutlined } from "@mui/icons-material";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  zIndex: 9999,

  // backgroundColor: "red",
};
const PersonalDetails = ({ CustomerGroupData, setCustomerGroupData }) => {
  const handleChange = (field, value) => {
    // Use the appropriate nested structure based on your JSON
    if (field.includes(".")) {
      const [parentField, nestedField] = field.split(".");
      setCustomerGroupData((prevData) => ({
        ...prevData,
        [parentField]: {
          ...prevData[parentField],
          [nestedField]: value,
        },
      }));
    } else {
      setCustomerGroupData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };
  return (
    <>
      {/* <Typography variant="h6">Personal Details</Typography> */}

      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "2.9%" }}
      >
        <Grid item xs={12} sm={3.8}>
          <TextField
            label="Group Name"
            size="small"
            fullWidth
            required
            value={CustomerGroupData?.group_name}
            onChange={(e) => handleChange("group_name", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 65, // Set maximum length to 60 characters
              },
            }}
            helperText={
              CustomerGroupData?.group_name &&
              CustomerGroupData?.group_name.length > 60
                ? "Cannot enter more than 65 characters"
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={3.8}>
          <TextField
            label="Customer Group Code"
            size="small"
            fullWidth
            required
            value={CustomerGroupData?.code}
            onChange={(e) => handleChange("code", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.6%" }}>
          <TextField
            label="CRM Name"
            name="CRM Name"
            size="small"
            value={CustomerGroupData?.crm_name}
            onChange={(e) => handleChange("crm_name", e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <PermIdentityIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "2.9%" }}
      >
        <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.7%" }}>
          <TextField
            label="CRM Phone"
            size="small"
            fullWidth
            type="tel"
            required
            margin="normal"
            variant="outlined"
            value={CustomerGroupData?.crm_phone}
            onChange={(e) => handleChange("crm_phone", e.target.value)}
            inputProps={{
              maxLength: 10,
              inputMode: "numeric", // Shows the numeric keyboard on mobile devices
              pattern: "[0-9]*", // Only allows numeric input (additional validation)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <PhoneIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.6%" }}>
          <TextField
            label="Description"
            name="Description"
            size="small"
            value={CustomerGroupData?.description}
            onChange={(e) => handleChange("description", e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <PermIdentityIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3.5}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};
const ITEM_PADDING_TOP = 8;
const ITEM_HEIGHT = 48;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};
const CustomerConnect = ({
  CustomerGroupData,
  groupId,
  setSelectedProduct,
  selectedProduct,
  setProductToSet,
  fetchCustomerName,
  action,
  customerName,
  setCustomerName,
  setSelectedNameWithFlag,
  selectedNameWithFlag,
  setCustomerGroupData,
  onUpdateProductData, // Callback function from the parent
}) => {
  const theme = useTheme();

  console.log("customer Name in mapping ,", customerName);
  const updateSelectedProducts = (value, products) => {
    const updatedSelectedProducts =
      typeof value === "string" ? value.split(",") : value;
   
    // Update isSelected property
    const updatedProductData = products?.map((product) => ({
      ...product,
      isSelected: updatedSelectedProducts?.some(
        (selectedProduct) =>
          selectedProduct?.customer_id === product?.customer_id
      ),
    }));
    console.log("updated products printing here ", updatedProductData);
    console.log("updated selected product data ", updatedSelectedProducts);
    setSelectedProduct(updatedSelectedProducts);

    // Update customerDataSet with selected products
    const updatedSelectedProductsWithFlag = updatedProductData
      .map((selectedProduct) => {
        if (selectedProduct.isSelected) {
          // Check conditions for setting flag and adding to setProductToSet
          if (
            (selectedProduct.flag === false &&
              selectedProduct.isSelected === true) ||
            (selectedProduct.flag === true &&
              selectedProduct.isSelected === true)
          ) {
            setProductToSet((prevData) =>  ({
              
            ...prevData,
            product: [
              ...(prevData.product || []),
            {
              customer_id: selectedProduct?.customer_id,
              customer_name: selectedProduct?.customer_name,

              customer_code: selectedProduct?.customer_code,
              flag: true,
            },
          ],
        }));
          } else if (
            selectedProduct.flag === true &&
            selectedProduct.isSelected === false
          ) {
            // Handle the case when isSelected is false and flag is true
            setProductToSet((prevData) => ({
              ...prevData,
              product: (prevData.product || [])?.map((p) =>
                p.customer_id === selectedProduct.customer_id
                  ? { ...p, flag: false }
                  : p
              ),
            }));
          }
        } else if (
          selectedProduct.flag === false &&
          selectedProduct.isSelected === false
        ) {
          // Exclude products where isSelected is false and both the current and previous flags are false
          return null;
        }
        return {
          customer_id: selectedProduct?.customer_id,
          customer_name: selectedProduct?.customer_name,
          customer_code: selectedProduct?.customer_code,
          customer_group_id_fk: groupId, // Assuming customerId is available
          // item_code: selectedProduct?.item_code,

          flag: selectedProduct?.isSelected,
        };
      })
      .filter(Boolean);

    console.log(
      "updated selected product with flag data ",
      updatedSelectedProductsWithFlag
    );
    setProductToSet((prevData) => ({
      ...prevData,
      product: updatedSelectedProductsWithFlag,
    }));

    setSelectedNameWithFlag(updatedSelectedProductsWithFlag);
    console.log("Selected Products:", updatedSelectedProductsWithFlag);
    console.log("Updated Product Data:", updatedProductData);
  };
  const handleProductChange = (event, newValue) => {
    const { value } = event.target;
    const products = Array.isArray(customerName)
      ? customerName
      : [customerName];
    updateSelectedProducts(newValue, products);
  };
  useEffect(() => {
    // Fetch products for the customer when in 'edit' mode
    if (groupId) {
      fetchCustomerName(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (customerName) {
      const selectedProductsFromData = customerName
        .filter((product) => product.flag === true)
        .map((selectedProduct) => selectedProduct);
      console.log("filterddddddddddddddddd", selectedProductsFromData);
      setSelectedProduct(selectedProductsFromData);
      setSelectedNameWithFlag(customerName);
      updateSelectedProducts(selectedProductsFromData, customerName);
    }
  }, [action, customerName]);
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "0.6%", marginLeft: "2.1%", marginRight: "10%" }}
      >
        <Typography>Link to Customer</Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%", marginRight: "10%" }}
      >
        <FormControl sx={{ m: 1, width: "95%" }}>
          <Autocomplete
            multiple
            id="Link to Customer"
            value={selectedProduct}
            onChange={handleProductChange}
            options={customerName}
            getOptionLabel={(option) => option.customer_name}
            renderInput={(params) => (
              <TextField {...params} label="Select Customer" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.customer_id}
                  label={option.customer_name}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </FormControl>
      </Grid>
      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.9%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};
const CustomerGroupConfig = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("navigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();

  const navigate = useNavigate();
  const [CustomerGroupData, setCustomerGroupData] = useState();
  const dispatch = useDispatch();
  const [CustomerGroupId, setCustomerGroupId] = useState();
  const [message, setMessage] = useState("");
  const colors = tokens(theme.palette.mode);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [customerName, setCustomerName] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  // const [CustomerGroupLoading, setCustomerGroupLoading] = useState(false);
  const [selectedNameWithFlag, setSelectedNameWithFlag] = useState([]);
  const [currentSection, setCurrentSection] = useState(
    "Customer Group Details"
  );
  const sections = [
    { name: "Customer Group Details", icon: <MdModeEdit /> },
    { name: "Connect Customers", icon: <MdOutlinePeopleAlt /> },
  ];
  const [customerGroupLoading, setCustomerGroupLoading] = useState(false);
  const [productToSet, setProductToSet] = useState([]);
  const [groupId, setGroupId] = useState("");
  const { action, customerGroupData } = useSelector(
    (state) => state?.customerGroup
  );
  console.log("customergroup data ", customerGroupData);

  useEffect(() => {
    switch (action) {
      case "edit":
        if (customerGroupData) {
          // If editing, prefill the form with the customerGroupData
          console.log("customergroup in edit form ", customerGroupData);
          console.log("edit action performed");
          // Initialize CustomerGroupData with all fields from JSON structure
          setCustomerGroupId(customerGroupData?.id);
          setGroupId(customerGroupData?.id);
          console.log("customergroup id", customerGroupData?.id);
          setCustomerGroupData((prevData) => ({
            ...prevData,
            ...customerGroupData,
          }));
          // Update only the fields present in customerGroupData
        }
        break;

      case "add":
        // If adding, clear the form data
        console.log("add action performed");
        setCustomerGroupData({
          group_name: "",

          crm_name: "",
          crm_phone: "",
          description: "",
          code: "",
        });
        break;

      // Add more cases if needed

      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, customerGroupData]);

  useEffect(() => {
    fetchCustomerName();
  }, []);

  const handleNext = async () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex < sections.length - 1) {
      const nextSection = sections[currentIndex + 1].name;
      if (action === "edit" && nextSection === "Connect Customers") {
        await updateCustomerGroup();
      } else if (action === "add" && nextSection === "Connect Customers") {
        await createCustomerGroup();
      }
      setCurrentSection(nextSection);
    }
  };
  const handleSubmit = async () => {
    try {
      if (action === "edit") {
        // If editing, send a PUT request
        await AttachCustomerToGroup(groupId, selectedNameWithFlag);
      } else {
        // If adding, send a POST request
        await AttachCustomerToGroup(groupId, selectedNameWithFlag);
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error("Error submitting customergroup details:", error);
      setMessage("Error submitting customergroup details");
    }
  };
  const handleSectionChange = async (sectionName) => {
    try {
      // Start loader when changing section
      setCustomerGroupLoading(true);

       

      // Simulating an async operation
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Stop loader after fetching data or when changing to a non-SKU section
      setCustomerGroupLoading(false);

      // Set the current section
      setCurrentSection(sectionName);
    } catch (error) {
      console.error("Error handling section change:", error);
      // Handle errors if needed
    }
  };
  useEffect(() => {
    if (groupId && selectedNameWithFlag?.length > 0) {
      AttachCustomerToGroup(groupId, selectedNameWithFlag);
      // Reset the flag after calling updateProductData
    }
  }, [groupId]);
  const updateCustomerGroup = async () => {
    try {
      if (!CustomerGroupData?.id) {
        console.error("Customer Group id is missing in CustomerGroupData.");
        setErrorMessage("Please try Again");
        return;
      }
      setCustomerGroupLoading(true);
      const endpoint = API_ENDPOINTS.PUT_WMS_CUSTOMERGROUP_DATA(
        CustomerGroupData?.id
      );
      console.log("API Endpoint:", endpoint);

      // Log the CustomerGroupData before making the API call
      console.log("Updating CustomerGroupData:", CustomerGroupData);

      const response = await axiosInstance.put(endpoint, CustomerGroupData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data updated Successfully!");
        setCustomerGroupLoading(false);
        // navigate('/viewWarehouse');
      } else {
        console.error("Failed to update the customergroup data");
        setCustomerGroupLoading(false);
        setErrorMessage(
          "Failed to update the customergroup data. Please try again."
        );
        setErrorPopupOpen(true);
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setCustomerGroupLoading(false);
      console.error(
        "Error occurred while updating the customergroup data:",
        error
      );
    }
  };
  const AttachCustomerToGroup = async (groupId, selectedNameWithFlag) => {
    console.log(
      "selectedname in updateproductdata function ",
      selectedNameWithFlag
    );
    try {
      if (!groupId) {
        console.error("Group Id is missing in Data.");
        setErrorMessage("Please try Again");
        return;
      }
      setCustomerGroupLoading(true);
      const endpoint =
        API_ENDPOINTS.PUT_WMS_CUSTOMER_GROUP_EDIT_CUSTOMER_MAPPED(groupId);
      console.log("API Endpoint:", endpoint);

      // Log the TransportData before making the API call
      console.log("Updating  data :", selectedNameWithFlag);
      console.log("Request Body:", JSON.stringify(selectedNameWithFlag));
      const response = await axiosInstance.put(endpoint, selectedNameWithFlag, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        console.log("API Response:", response);
        setSuccessMessage("Data updated Successfully!");
        setSuccessPopupOpen(true);
        // navigate('/viewCustomer');
      } else {
        console.error("Failed to update the customer group  data");
        setErrorMessage(
          "Failed to update the customer group  data. Please try again."
        );
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setCustomerGroupLoading(false);
      console.error("Error occurred :", error);
    }
  };
  useEffect(() => {
    // Effect to navigate after success message is set
    if (successPopupOpen) {
      const timer = setTimeout(() => {
        setSuccessPopupOpen(false);
        navigate("/viewCustomerGroup");
        setCustomerGroupLoading(false);
      }, 700);

      return () => clearTimeout(timer);
    }
  }, [successPopupOpen, navigate]);
  const createCustomerGroup = async () => {
    try {
      setCustomerGroupLoading(true);
      // Determine the endpoint for creating a new customergroup
      const endpoint = API_ENDPOINTS.POST_WMS_ADD_CUSTOMERGROUP_DATA;
      console.log("API Endpoint:", endpoint);

      // Log the CustomerGroupData before making the API call
      console.log("Creating CustomerGroupData:", CustomerGroupData);

      // Send the POST request to create a new customergroup
      const response = await axiosInstance.post(endpoint, CustomerGroupData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setGroupId(response.data?.group_id);
        // localStorage.setItem("warehouse_data",customergroup);
        console.log("group id", response.data?.group_id);
        setCustomerGroupLoading(false);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the customergroup data");
        setErrorMessage(
          "Failed to create the customergroup data. Please try again."
        );
        setErrorPopupOpen(true);
        setCustomerGroupLoading(false);
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setCustomerGroupLoading(false);
      console.error(
        "Error occurred while creating the customergroup data:",
        error
      );
    }
  };
  const fetchCustomerName = async (groupId) => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_CUSTOMER_GROUP_CUSTOMER_LIST}`,
        {
          params: {
            limit: 1000,
            offset: 1,
            group_id: groupId,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data?.customers;
        console.log("Customer name  data", data);
        setCustomerName(data);

        console.log("customer name  Data", data);
      } else {
        console.log("Error fetching details");
        setErrorMessage("OOPS, Something went Wrong !!!");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      // setIsLoadingList(false);
      // Set loading to false after the request is completed
    }
  };

  const handlePrevious = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex > 0) {
      const previousSection = sections[currentIndex - 1].name;
      setCurrentSection(previousSection);
    }
  };
  function handleBack() {
    if (action === "add") {
      navigate("/configuration");
    } else {
      // Navigate to a different location based on your condition

      navigate("/viewCustomerGroup");
    }
  }
  const isLastSection = currentSection === sections[sections.length - 1].name;
  //Personal Details States

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
          // backgroundColor:'pink'
          // backgroundColor: "rgb(44 123 85)",
        }}
      >
        <Typography
          variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%" }}
        >
          <MdPeopleAlt
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp; Customer Group
        </Typography>
        {/* <InventoryRoundedIcon sx={{ fontSize: 32, marginLeft: "1px" }} />
          </Typography> */}

        <Stack spacing={2} direction="row">
          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
      {customerGroupLoading ? (
        <>
          <div style={loaderContainerStyle}>
            <ClipLoader color="#495057" loading={customerGroupLoading} />
          </div>
        </>
      ) : (
        <Grid container spacing={3} sx={{ marginTop: "1.7%" }}>
          {/* Left Section (30%) */}
          <Grid item xs={12} sm={3}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            >
              {sections?.map((section) => (
                <Typography
                  key={section?.name}
                  onClick={() => handleSectionChange(section.name)}
                  //onClick={() => setCurrentSection(section.name)}
                  
                  style={{
                    cursor: "pointer",
                    padding: "8px",

                    background:
                      currentSection === section.name
                        ? "#34519F5D"
                        : "transparent",
                    color: currentSection === section.name ? "#000" : "inherit",
                    borderRadius:
                      currentSection === section.name ? "7px" : "inherit",
                    // padding:currentSection === section.name ? '5px' : 'inherit',
                  }}
                >
                  {section?.icon}
                  &nbsp;&nbsp;
                  {section?.name}
                </Typography>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              maxHeight="100%"
              height="100%"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
              position="relative"
            >
              {/* Dynamically render the current section */}
              {currentSection === "Customer Group Details" && (
                <PersonalDetails
                  CustomerGroupData={CustomerGroupData}
                  setCustomerGroupData={setCustomerGroupData}
                />
              )}
              {currentSection === "Connect Customers" && (
                <CustomerConnect
                  CustomerGroupData={CustomerGroupData}
                  setCustomerGroupData={setCustomerGroupData}
                  customerName={customerName}
                  setCustomerName={setCustomerName}
                  selectedNameWithFlag={selectedNameWithFlag}
                  setSelectedProduct={setSelectedProduct}
                  fetchCustomerName={fetchCustomerName}
                  action={action}
                  groupId={groupId}
                  selectedProduct={selectedProduct}
                  setSelectedNameWithFlag={setSelectedNameWithFlag}
                  setProductToSet={setProductToSet}
                  productToSet={productToSet}
                />
              )}
              <Box
                position="absolute"
                bottom="16px"
                right="20px"
                display="flex"
                gap={2}
              >
                {currentSection !== sections[0].name && (
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: colors.green.DEFAULT,
                    }}
                    onClick={handlePrevious}
                  >
                    <Typography variant="h7" color={colors.green.DEFAULT}>
                      Previous
                    </Typography>
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                  }}
                  onClick={isLastSection ? handleSubmit : handleNext}
                >
                  <Typography variant="h7" color={colors.white.DEFAULT}>
                    {isLastSection ? "Submit" : "Next"}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default CustomerGroupConfig;
