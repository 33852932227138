import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import { useState, useEffect } from "react";
import { ScaleLoader } from "react-spinners";
import Pagination from "@mui/material/Pagination";
import {
  Box,
  createTheme,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeProvider,
  useTheme,
  IconButton,
  Table,
  TextField,
  Stack,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import FormConfiguration from "../../Form/ActionForm/Harvesting/Harvesting";
import FormTransplant from "../../Form/ActionForm/Transplant/Transplant";
import FormDamage from "../../Form/ActionForm/Damage/Damage";
import { API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import StagesImg from "../../../../Assets/ConfigurationImg/growth.png";

import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const LiveDataGrid = ({ farmname, farmId }) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  console.log("farm_id in Live page", farmId);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(1);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  // const [dataFetch, setDataFetch] = useState(isChanged);
  

  useEffect(() => {
    fetchData();
  }, [ limit, offset]);

  useEffect(() => {
    fetchData();

    const loaderTimer = setTimeout(() => {
      setIsLoading(false);
    }, 30000);

    return () => clearTimeout(loaderTimer);
  }, [farmname]);

  const handleOpen = (row) => {
    setOpen(true);
    setSelectedRow(row);
  };
  const handleRollBack = (row) => {
    handleOpen(row);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = async (rowData) => {
    setOpen(false);
    if (selectedRow) {
      try {
        const response = await axiosInstance.put(
          `${API_ENDPOINTS.PUT_CROPMANAGEMENT_LIVE_ROLLBACK}`,
          rowData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API call successful", response);
        if (response.status >= 200 && response.status < 305) {
          // updateRows(rowData);
          // fetchData();
        } else {
          console.log("API CALL failed");
        }
      } catch (error) {
        setApiErrorOpen(true);
        console.log("API call failed", error);
      }
    }
  };

  const fetchData = async (newPage) => {
    console.log("newpage", newPage);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_CROPMANAGEMENT_CONFIG_LIVEINVENTORY(
          farmId,
          limit,
          offset
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const formattedData = data?.rows?.map((item) => {
          const formatteddos = moment(item?.date_of_sow).format("DD-MM-YYYY");
          let formatteddot = moment(item?.date_of_transplant).format(
            "DD-MM-YYYY"
          );
          if (formatteddot === "Invalid date") {
            formatteddot = "NT";
          }
          const formattededoh = moment(item?.exp_date_of_harvest).format(
            "DD-MM-YYYY"
          );
          return {
            ...item,
            formatteddos,
            formatteddot,
            formattededoh,
          };
        });
        setRows(formattedData);
        setIsLoading(false);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };
  // console.log("THISSSSSSSSSS DATAAAAAAAAAAA", rows);

  const [phaseData, setPhaseData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // console.log(data);
  const columns = useMemo(
    () => [
      {
        header: "Phase",
        size: 4,
        enableEditing: false,

        Cell: ({ cell }) => {
          const handleClick = () => {
            setPhaseData(cell.row.original);
            setIsDialogOpen(true);
          };
          return (
            <div className="tooltip-cell" title="Click to view phase details ">
              <IconButton
                className="icon-wrapper"
                onMouseEnter={() => console.log("Hovering...")}
                onClick={handleClick}
              >
                <img
                  src={StagesImg}
                  alt="Stages"
                  className="stages-image"
                  style={{ height: "25px", width: "25px" }}
                />
              </IconButton>
              <span className="farm-name">{cell.getValue()}</span>
            </div>
          );
        },
      },
      {
        header: "Crop",
        accessorKey: "crop_name",
        size: 20,
      },
      {
        header: "Variety",
        accessorKey: "crop_variety_name",
        size: 20,
      },
      {
        header: "Zone",
        accessorKey: "zone_name",
        size: 20,
      },
      {
        header: "Batch",
        accessorKey: "batch",
        size: 20,
      },
      {
        header: "No. of Plants",
        accessorKey: "no_of_plant",
        size: 20,
      },
      {
        header: "Crop Type",
        accessorKey: "crop_type",
        size: 40,
      },
      // {
      //   header: "Expected Phase",
      //   accessorKey: "current_phase",
      //   size: 40,
      // },
      // {
      //   header: "Actual Phase",
      //   accessorKey: "actual_phase",
      //   size: 40,
      // },
      // {
      //   header: "Next Phase",
      //   accessorKey: "next_phase",
      //   size: 20,
      // },
      // {
      //   header: "Day",
      //   accessorKey: "diff_days_phase",
      //   size: 20,
      // },
      {
        header: "Exp. DOH",
        accessorKey: "formattededoh",
        size: 30,
      },
      {
        header: "Date Of Sow",
        accessorKey: "formatteddos",
        size: 30,
      },
      {
        header: "Rollback",

        size: 1,
        enableEditing: false, //disable editing on this column
        //you can access a cell in many callback column definition options like this
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: () => {
            //setIsDialogOpen(true);
            handleRollBack(cell.row.original);
            console.log("hnirfnthto", cell.row.original);
          },
          title: "Click to rollback details",
        }),
        //or in the component override callbacks like this
        Cell: ({ cell }) => {
          return (
            <div className="tooltip-cell" title="Click to view zone details">
              <IconButton
                className="icon-wrapper"
                onMouseEnter={() => console.log("Hovering...")}
              >
                <div style={{ rotate: "90deg" }}>
                  <UTurnLeftIcon />
                </div>
              </IconButton>
              <span className="farm-name">{cell.getValue()}</span>
            </div>
          );
        },
      },
    ],
    []
  );

  // if (isChanged) {
  //   fetchData();
  // }

  const PhaseDialog = ({ isOpen, onClose, phaseData }) => {
    console.log("phaseData-------------", phaseData);

    return (
      <Dialog
        open={isOpen}
        PaperProps={{
          style: {
            width: "450px",
            height: "350px",
          },
        }}
      >
        <DialogTitle>
          {phaseData && (
            <div style={{ width: "100%" }}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "800",
                  fontFamily: "Lexend Deca",
                  fontSize: "18px",
                }}
              >
                Phase details for : {phaseData?.crop_name}
              </Typography>
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "rgb(220 232 220)",
                    fontWeight: "bold",
                  }}
                >
                  Expected Phase
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(220 232 220)",
                    fontWeight: "bold",
                  }}
                >
                  {phaseData.current_phase}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "rgb(239 244 239)",
                    fontWeight: "bold",
                  }}
                >
                  Actual Phase
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(239 244 239)",
                    fontWeight: "bold",
                  }}
                >
                  {phaseData.actual_phase}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "rgb(220 232 220)",
                    fontWeight: "bold",
                  }}
                >
                  Next Phase
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(220 232 220)",
                    fontWeight: "bold",
                  }}
                >
                  {phaseData.next_phase}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "rgb(239 244 239)",
                    fontWeight: "bold",
                  }}
                >
                  Days Left
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(239 244 239)",
                    fontWeight: "bold",
                  }}
                >
                  {phaseData.diff_days_phase}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{ marginRight: "2.5%" }}
            color="success"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handlePageRefresh = () => {
    fetchData();
    setApiSuccessOpen(true);
  };

  return (
    <>
      <Grid sx={{ marginTop: "2%" }}>
        {isLoading ? ( // Check if data is still loading
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <ScaleLoader color="#2C7B55" />
          </div>
        ) : (
          <div>
            <MaterialReactTable
              columns={columns}
              data={rows}
              positionActionsColumn="last"
              enableRowActions
              enableGrouping
              enableStickyHeader
              enableRowVirtualization
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enablePagination={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 5 },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "Actions", //change header text
                  size: 170, //make actions column wider
                },
              }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: "650",
                  color: "#2c7B55",
                  fontSize: "15px",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  overflow: "hidden",
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f8fafc",
                  },
                },
              }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              renderRowActions={({ row }) => (
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <FormConfiguration
                      rows={row}
                      handlePageRefresh={handlePageRefresh}
                    />
                    <FormTransplant rows={row} handlePageRefresh={handlePageRefresh} />
                    <FormDamage rows={row} handlePageRefresh={handlePageRefresh} />
                  </div>
                </Box>
              )}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                //  marginTop: "-90%",
              }}
            >
              <Pagination
                count={100}
                onChange={handleChangePage}
                size="small"
              />
            </div>
          </div>
        )}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Revert Confirmation</DialogTitle>
          <DialogContent>
            <div>Are you sure you want to revert this selection?</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="success">
              Cancel
            </Button>
            <Button onClick={() => handleConfirm(selectedRow)} color="success">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <PhaseDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          phaseData={phaseData}
        />
      </Grid>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};

export default LiveDataGrid;
