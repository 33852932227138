import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,Autocomplete,TextField,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";

import NullScreen from "../PopUpMsg/NullScreen";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { tokens } from "../WMStheme";
import SocketReportPopup from "../../Attendance/HomePage/SocketReportPopup";
import { useTheme } from "@mui/material";

import { BASE_WMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../PopUpMsg/SuccessPopUp";
import ErrorPopup from "../PopUpMsg/ErrorPopUp";
import { LuWarehouse } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import { IoIosPerson } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { GiFruitBowl } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { Divider } from "antd";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import Paper from "@mui/material/Paper";
import { ulid } from "ulid";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const columns = [
  { id: "item_name", label: " Name" },
  { id: "item_code", label: "SKU Code" },
  { id: "warehouse_name", label: "Warehouse" },
  { id: "type", label: "Type" },
  { id: "reason", label: "Reason" },
  { id: "qty", label: "Quantity" },
];

const LossReport = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  const [salesReportData, setSalesReportData] = useState([]);
  // const [salesReportData, setSalesReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [customerNames, setCustomerNames] = useState([]);
  const [skuNames, setSkuNames] = useState([]);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [skuId, setSkuId] = useState(null);
  const [warehouseId, setWarehouseData] = useState(null);
  const [generatedId, setGeneratedId] = useState(null);

  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [filterValues, setFilterValues] = useState({
    limit: 15,
    offset: offset,
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    customer_id: null,
    sku_id: null,
    warehouse_id: null,
  });

  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,
      customer_id: customerId,
      sku_id: skuId,
      warehouse_id: warehouseId,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, customerId, skuId, warehouseId]);

  useEffect(() => {
    fetchSKUNames();
    fetchCustomerNames();
    fetchWarehouseNames();
    fetchGridData(filterValues);
  }, []);

  const handleApplyClick = () => {
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      limit: 15,
      offset: 1,
      customer_id: temporaryFilterValues.customer_id,
      sku_id: temporaryFilterValues.sku_id,
      warehouse_id: temporaryFilterValues.warehouse_id,
    };
    setStartDate(temporaryFilterValues.start_date);
    setEndDate(temporaryFilterValues.end_date);
    setCustomerId(temporaryFilterValues.customer_id);
    setSkuId(temporaryFilterValues.sku_id);
    setWarehouseData(temporaryFilterValues.warehouse_id);
    setFilterValues(newFilterValues);
    fetchGridData(newFilterValues);
  };

  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchGridData(updatedValues);
  };

  const fetchCustomerNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_CUSTOMER_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      console.log("data in fetchCustomerNames", data?.data?.customer_list);
      setCustomerNames(data?.data?.customer_list);
    } catch (error) {
      console.log("Error fetching CustomerNames", error);
    }
  };
  const fetchWarehouseNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_WAREHOUSE_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      console.log("data in fetchWarehouseNames", data?.data?.warehouse_list);
      setWarehouseNames(data?.data?.warehouse_list);
    } catch (error) {
      console.log("Error fetching WeekDays", error);
    }
  };
  const fetchSKUNames = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_SKU_DATA, {
        params: {
          limit: 1000,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response?.data?.Data;
      console.log("data in Loss report", data);

      setSkuNames(data?.sku_list);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const fetchGridData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_INVENTORY_GRID_DATA,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      console.log("data in fetchGridData in Loss Report Page", data);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setSalesReportData(data?.damage_list);
        setTotalPages(data?.total_pages);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleGenerateReportClick = async () => {
    setIsLoading(true);
    const requestId = ulid();
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_DOWNLOAD_INVENTORY_REPORT,
        {
          params: {
            ...filterValues,
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  const handleRefreshClick = () => {
    const initialValues = {
      limit: 15,
      offset: 1,
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
    };
    setOffSet(1);
    setCustomerId(null);
    setSkuId(null);
    setWarehouseData(null);
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    fetchGridData(initialValues);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const containerStyle = {
    maxHeight: "78vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "78vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    marginTop: "1%",
    display: "flex",
    height: "71vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };

  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent() {
    return (
      <>
        <TableBody>
          {salesReportData?.map((row, index) => (
            <StyledTableRow key={index}>
              {columns?.map((column) => (
                <StyledTableCell key={column?.id} align="center">
                  {row[column.id]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "8vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2.5} md={2.5}>
                <RangePicker
                  onChange={handleDateChange}
                  // size="large"
                  key={refreshCount}
                  format={dateFormat}
                  defaultValue={[
                    dayjs(formatDate(startDate), dateFormat),
                    dayjs(formatDate(endDate), dateFormat),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2.5} md={2.5}>
                <FormControl fullWidth>
                <Autocomplete
                options={skuNames || []}
                getOptionLabel={(option) => option?.sku_name || ''}
                value={skuNames.find((sku) => sku.id === skuId) || null}
                onChange={(event, newValue) => {
                  setSkuId(newValue?.id|| '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select SKU"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <GiFruitBowl />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}> 
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!startDate || !endDate}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={3.2} md={3.2}></Grid>
              <Grid item xs={12} sm={2} md={2} textAlign="right">
            <Button
              variant="contained"
              startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
              sx={{
                backgroundColor: colors.green.DEFAULT,
              }}
              size="small"
              onClick={handleGenerateReportClick}
              disabled={!salesReportData?.length}
            >
              <Typography variant="h6" fontFamily="Lexend Deca">
                Download Report
              </Typography>
            </Button>
          </Grid>
            </Grid>
          </Grid>
      
          {openDialog && (
            <SocketReportPopup
              setOpenDialog={setOpenDialog}
              requestId={generatedId}
            />
          )}
        </Grid>

        <Grid container spacing={1} style={containerStyle}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : salesReportData?.length === 0 ? (
            <NullScreen containerheight="78vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 200 }} aria-label="customized table">
                    <TableHeadComponent />
                    <TableRowsComponent data1={salesReportData} />
                  </Table>
                </TableContainer>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <div style={{ flex: "1" }}></div>
                <Pagination
                  count={totalPages}
                  page={offset}
                  onChange={handleChangePage}
                  style={{ color: "#5e89c7" }}
                />
              </div>
            </>
          )}
        </Grid>
      </Box>

      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default LossReport;
