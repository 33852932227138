import React, { useState, useEffect, useMemo } from "react";
import { IoMdSad } from "react-icons/io";

import {
  Grid,
  Stack,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Radio,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Box,
  Fab,
  Divider,
  Select,
  Alert,
  Snackbar,
  TextField,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import { GrStatusGood } from "react-icons/gr";
import { MdFileUpload } from "react-icons/md";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import { IoIosPerson } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BillProgressPopUp from "../Reports/BillProgressPopUp";
import BillUploadProgressPopUp from "../Reports/BillUploadProgressPopUp";
import { FaCalendarAlt } from "react-icons/fa";
import { IoDocumentTextSharp } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MaterialReactTable } from "material-react-table";
import { useSelector } from "react-redux";
import NullScreen from "../PopUpMsg/NullScreen";
import { MdMenuBook } from "react-icons/md";
import { tokens } from "../WMStheme";
import { DialogTitle } from "@material-ui/core";
import { Label } from "semantic-ui-react";
import { CiCalendarDate } from "react-icons/ci";
import { API_ENDPOINTS, BASE_WMS_URL } from "../../ApiEndpoint/ApiEndpoint";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoPopUp from "../PopUpMsg/InfoDialog";
import ApiErrorAlert from "../../ApiErrorAlert";
import { useDispatch } from "react-redux";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import POMainPage from "./POMainPage";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import AddSKUTable from "./AddSKUTable";
import axios from "axios";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import CustomPagination from "../CustomPagination";
import Pagination from "@mui/material/Pagination";
import { AlertDialog, AlertDialogBox } from "../PopUpMsg/AlertDialog";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "ap-south-1",
});
const s3 = new AWS.S3();

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

const PONewAddPage = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const theme = useTheme();
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const dummyVendorData = [
    {
      vendor_id: "1",
      vendor_name: "sameer",
      vendor_code: "123",
      gst: null,
      pan: null,
      aadhaar: null,
      credit_cycle: null,
      tin: null,
      bank_details: null,
      address: null,
      pan_attach: null,
      active: true,
      created_at: null,
    },
  ];

  const colors = tokens(theme.palette.mode);
  const globalTheme = useTheme();
  const [currentStatus, setCurrentStatus] = useState("pending");
  const [alignTextToLeft, setAlignTextToLeft] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseId, setWarehouseId] = useState("");
  //const [vendorData, setVendorData] = useState(dummyVendorData);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [senttoStatus, setSentToStatus] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [dateFieldValue, setDateFieldValue] = useState("");
  const [selectedVendorName, setSelectedVendorName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [skuData, setSkuData] = useState([]);
  const [skuId, setSKUId] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorPopupOpen3, setErrorPopupOpen3] = useState(false);
  const [errorPopupMessage1, setErrorPopupMessage1] = useState("");
  const [errorPopupOpen1, setErrorPopupOpen1] = useState(false);
  const [errorPopupMessage, setErrorPopupMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [poLoading, setPoLoading] = useState(false);
  const [PoData, setPoData] = useState();
  const navigate = useNavigate();
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [isDateFilled, setIsDateFilled] = useState(false);
  const [PurchaseData, setPurchaseData] = useState();
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [isAddSkuButtonEnabled, setIsAddSkuButtonEnabled] = useState(false);
  const [skuMasterData, setSKUMasterData] = useState([]);
  const [tempSkuData, setTempSkuData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isrtvFieldVisible, setIsrtvFieldVisible] = useState(false);
  const [openBillDialog, setOpenBillDialog] = useState(false);
  const [openBillUploadDialog, setOpenBillUploadDialog] = useState(false);
  const [onUploadConfirm, setUploadConfirm] = useState(false);
  const [receivedPoId, setReceivedPoId] = useState(null);
  const [isreturnFieldVisible, setIsreturnFieldVisible] = useState(false);
  const [isreceivedqtyFieldVisible, setIsreceivedqtyFieldVisible] =
    useState(false);
  const [purchaseSkuId, setPurchaseSKuId] = useState("");
  const [quantityValues, setQuantityValues] = useState({});
  const [grnquantityValues, setGrnQuantityValues] = useState({});
  const [rtvquantityValues, setRtvQuantityValues] = useState({});
  const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [rateValues, setRateValues] = useState({});
  const [fileName, setFileName] = useState(null);
  const [isAttachmentDialog, setIsAttachmentDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pendingFieldVisible, setPendingFieldVisible] = useState(true);
  const [clickedRowId, setClickedRowId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [poTypeOptions, setPOTypeOptions] = useState([]);
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorPopupOpen2, setErrorPopupOpen2] = useState(false);
  const [billData, setBillData] = useState({});
  const itemsPerPage = 50;
  const handleAttachmentClose = () => {
    setIsAttachmentDialog(false);
    setSelectedFile(null);
    setClickedRowId(null);
  };
  const handleAttachmentOpen = (currentClickedRow) => {
    setClickedRowId(currentClickedRow.sku_code);
    setIsAttachmentDialog(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    if (onUploadConfirm) {
      setOpenBillUploadDialog(false);
      setSuccessPopupOpen(true);
      setPoLoading(false);
      setSentToStatus(null);
      navigate("/po");
    }
  }, [onUploadConfirm]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    // Assuming tempSkuData is an array of objects with sku_id, flag, and visible properties

    // Render the table using rowsToDisplay
    // Assuming tempSkuData is an array of objects with sku_id, flag, and visible properties
    const rowsToDisplay = tempSkuData?.filter((row) => row?.visible !== false);

    // Render the table using rowsToDisplay

    const slicedData = rowsToDisplay?.slice(startIndex, endIndex);
    setCurrentData(slicedData);

    const calculatedTotalPages = Math.ceil(tempSkuData?.length / itemsPerPage);
    setTotalPages(calculatedTotalPages);
  }, [tempSkuData, currentPage, itemsPerPage]);
  useEffect(() => {
    fetchPOTypeOptions();
  }, []);
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const handleChangePage1 = (newPage) => {
    setCurrentPage(newPage);
  };
  const userId = localStorage.getItem("user-id");
  console.log("user id in po page ", userId);
  const columns = useMemo(() => {
    const handleDeleteClick = (skuId) => {
      console.log("Delete function called for SKU ID:", skuId);

      if (action === "edit") {
        setTempSkuData((prevData) =>
          prevData?.map((row) => {
            if (row?.sku_id === skuId) {
              return { ...row, flag: false, visible: false };
            }
            return row;
          })
        );
        setRowSelection((prevRowSelection) => {
          const newRowSelection = { ...prevRowSelection };
          delete newRowSelection[skuId];
          return newRowSelection;
        });
      } else {
        setTempSkuData((prevData) =>
          prevData?.filter((row) => row.sku_id !== skuId)
        );

        setRowSelection((prevRowSelection) => {
          const newRowSelection = { ...prevRowSelection };
          delete newRowSelection[skuId];
          return newRowSelection;
        });
      }
    };

    const additionalColumns3 =
      currentStatus === "pending"
        ? [
            {
              header: "Actions",
              accessorKey: "actions",
              size: 5,
              Cell: ({ row }) => (
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteClick(row.original.sku_id)}
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ]
        : [];

    const commonColumns = [
      {
        header: "Name",
        accessorKey: "sku_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Code",
        accessorKey: "sku_code",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Lot No.",
        accessorKey: "lot_no",
        size: 10,
        enableEditing: false,
      },
      {
        header: "UOM",
        accessorKey: "uom",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Tax(%)",
        accessorKey: "tax",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Last Purchase Price",
        accessorKey: "last_purchase_price",
        size: 10,
        enableEditing: false,
      },

      {
        header: "PO Quantity",
        accessorKey: "order_qty",
        size: 10,
        enableEditing: currentStatus !== "release",
        Cell: ({ row }) => {
          const { original } = row;
          const inputValue = quantityValues[original?.sku_id] || "";
          const skuId = action === "edit" ? original?.sku_id : original?.sku_id;
          return (
            <TextField
              value={quantityValues[skuId] || ""}
              size="small"
              name={inputValue}
              type="number"
              onChange={(e) => handleQuantityChange(e, skuId, original)}
              disabled={
                currentStatus !== "pending" ||
                currentStatus === "zoho" ||
                currentStatus === "return" ||
                currentStatus === "cancel"
              }
              inputProps={{
                step: "any",
              }}
            />
          );
        },
      },
      {
        header: "Rate",
        accessorKey: "rate",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const rateinputValue = rateValues[original?.sku_id] || "";
          const skuId = action === "edit" ? original?.sku_id : original?.sku_id;
          const maxRate =
            original?.last_purchase_price + original?.last_purchase_price * 0.1; // 10% of Last Purchase Price
          console.log("max rate  in po add new page ", maxRate);
        // operation cant add/edit the rate after release status 
          const allowedUserIds = [
            "01HNEYZ5E1TGK69M1CMRGXWTQM",
            "01HPGZX5JXS8FMV6ESAPPY9E6T",
            "01HMDZ7YB4TRC134JPTPBKH6D4",
          ];

          const isUserAllowed = allowedUserIds.includes(userId);

          return (
            <TextField
              value={
                rateValues[skuId] !== null && rateValues[skuId] !== undefined
                  ? rateValues[skuId]
                  : ""
              }
              size="small"
              type="number"
              name={rateinputValue}
              onChange={(e) => handleRateChange(e, skuId, original)}
              disabled={
                (currentStatus === "release" && !userDetail?.b_po_rate) ||
                currentStatus === "zoho" ||
                currentStatus === "partialreceived" ||
                currentStatus === "received" ||
                currentStatus === "cancel" ||
                (currentStatus === "return" && !userDetail?.b_po_rate)
              }
              inputProps={{
                step: "any",
              }}
            />
          );
        },
      },
    ];

    const additionalColumns1 = isreceivedqtyFieldVisible
      ? [
          {
            header: "Receiving Quantity",
            accessorKey: "rcvd_qty",
            size: 10,
            enableEditing: true,
            // enableEditing: isreceivedqtyFieldVisible,
            Cell: ({ row }) => {
              const { original } = row;
              const inputValue = grnquantityValues[original?.sku_id] || "";
              const skuId =
                action === "edit" ? original?.sku_id : original?.sku_id;
              console.log("sku id in cell rate ", skuId);
              const orderQty = row?.original?.order_qty;
              console.log("order qtyyyyy ", orderQty);
              const allowedUserIds = [
                "01HNEYZ5E1TGK69M1CMRGXWTQM", //sourcing
                "01HPGZX5JXS8FMV6ESAPPY9E6T", // finance
              ];

              const isUserAllowed = allowedUserIds.includes(userId);
              return (
                <TextField
                  value={
                    grnquantityValues[skuId] !== null &&
                    grnquantityValues[skuId] !== undefined &&
                    grnquantityValues[skuId] !== ""
                      ? grnquantityValues[skuId]
                      : 0
                  }
                  type="number"
                  // value={grnquantityValues[skuId]}
                  size="small"
                  onBlur={(e) => {
                    // Trigger handleGrnQuantityChange on blur with the current value
                    handleGrnQuantityChange(e, skuId, original);
                  }}
                  // onChange={(e) => handleGrnQuantityChange(e, skuId)}
                  onChange={(e) => {
                    handleGrnQuantityChange(e, skuId, original);
                  }}
                  //sourcing  cant add/edit receiving qty after release status
                  disabled={
                    (currentStatus === "release" &&
                    !userDetail?.b_po_receiving_qty) ||
                    currentStatus === "received" ||
                    currentStatus === "return" ||
                    currentStatus === "cancel" ||
                    currentStatus === "zoho"
                  }
                  inputProps={{
                    max: orderQty,
                  }}
                />
              );
            },
          },
          {
            header: "Upload Proof",
            size: 30,
            enableEditing: false,
            muiTableBodyCellProps: ({ cell }) => ({
              onClick: () => {
                handleAttachmentOpen(cell.row.original);
              },
              title: "Click to Upload Image",
            }),
            //or in the component override callbacks like this
            Cell: ({ cell }) => {
              return (
                <div
                  className="tooltip-cell"
                  title="Click to Upload Image"
                  style={{ lineHeight: "0.1" }}
                >
                  <IconButton className="icon-wrapper">
                    <MdFileUpload
                      style={{
                        height: "20px",
                        width: "30px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1%",
                        color: "red",
                      }}
                    />
                  </IconButton>
                  {/* <span className="crop-name">{cell.getValue()}</span> */}
                </div>
              );
            },
          },
          {
            header: "Download Proof",
            size: 30,
            enableEditing: false,
            muiTableBodyCellProps: ({ cell }) => ({
              onClick: () => {
                console.log("PoData----------------------", PoData);
                const fileName = cell.row.original.purchase_attach;
                console.log("fileName-----------", fileName);
                if (fileName) {
                  const presignedUrl = s3.getSignedUrl("getObject", {
                    Bucket: "fambo-assets",
                    Key: `farmer/${fileName}`,
                    Expires: 3600,
                  });
                  console.log("Presigned URL for viewing:", presignedUrl);
                  window.open(presignedUrl, "_blank");
                } else {
                  setErrorMessage("Image not available to Download");
                  setErrorPopupOpen(true);
                }
              },
              title: "Click to Download Image",
            }),
            //or in the component override callbacks like this
            Cell: ({ cell }) => {
              return (
                <div
                  className="tooltip-cell"
                  title="Click to Download Image"
                  style={{ lineHeight: "0.1" }}
                >
                  <IconButton className="icon-wrapper">
                    <FileDownloadIcon
                      style={{
                        height: "20px",
                        width: "30px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1%",
                        color: "green",
                      }}
                    />
                  </IconButton>
                  {/* <span className="crop-name">{cell.getValue()}</span> */}
                </div>
              );
            },
          },
        ]
      : [];
    const additionalColumns = isrtvFieldVisible
      ? [
          {
            header: "RTV",
            accessorKey: "rtv_qty",
            size: 10,
            // enableEditing: !isreturnFieldVisible,
            Cell: ({ row }) => {
              const { original } = row;
              const inputValue = rtvquantityValues[original?.sku_id] || 0;
              const skuId =
                action === "edit" ? original?.sku_id : original?.sku_id;
              console.log("sku id in cell rate ", skuId);
              return (
                <TextField
                  value={
                    rtvquantityValues[skuId] !== null &&
                    rtvquantityValues[skuId] !== undefined &&
                    rtvquantityValues[skuId] !== ""
                      ? rtvquantityValues[skuId]
                      : 0
                  }
                  size="small"
                  onChange={(e) => handleRtvQuantityChange(e, skuId, original)}
                  onBlur={(e) => {
                    // Trigger handleGrnQuantityChange on blur with the current value
                    handleRtvQuantityChange(e, skuId, original);
                  }}
                  type="number"
                  // after received status ,sourcing cant edit/add rtv qty 
                  disabled={
                    (currentStatus === "received" &&
                    !userDetail?.b_po_rtv_qty) ||
                    currentStatus === "zoho" ||
                    currentStatus === "cancel" ||
                    currentStatus === "return"
                  }
                  inputProps={{
                    step: "any",

                    max: original?.rcvd_qty,
                  }}
                />
              );
            },
          },
        ]
      : [];

    return [
      ...commonColumns,
      ...additionalColumns3,
      ...additionalColumns1,
      ...additionalColumns,
    ];
  }, [
    currentStatus,
    tempSkuData,
    rowSelection,
    pendingFieldVisible,
    isrtvFieldVisible,
    isreceivedqtyFieldVisible,
    rateValues,
    quantityValues,
    grnquantityValues,
    rtvquantityValues,
  ]);
  useEffect(() => {
    // Assuming currentStatus is a string, modify this switch statement accordingly
    switch (currentStatus) {
      case "pending":
        setIsrtvFieldVisible(false);
        setIsreceivedqtyFieldVisible(false); // Set to true or false based on your requirement
        break;
      case "release":
        setIsrtvFieldVisible(false);
        setIsreceivedqtyFieldVisible(true);
        setPendingFieldVisible(false); // Set to true or false based on your requirement
        break;
      case "partialreceived":
        setIsrtvFieldVisible(false);
        setIsreceivedqtyFieldVisible(true);
        setPendingFieldVisible(false); // Set to true or false based on your requirement
        break;
      case "received":
        setIsrtvFieldVisible(true);
        setIsreceivedqtyFieldVisible(true);
        setPendingFieldVisible(false); // Set to true or false based on your requirement
        break;
      case "return":
        setIsrtvFieldVisible(true);
        setIsreturnFieldVisible(true);
        setIsreceivedqtyFieldVisible(true);
        setPendingFieldVisible(false); // Set to true or false based on your requirement
        break;
      case "zoho":
        setIsrtvFieldVisible(true);
        setIsreceivedqtyFieldVisible(true);
        setPendingFieldVisible(false); // Set to true or false based on your requirement
        break;
      case "cancel":
        setIsrtvFieldVisible(false);
        setIsreceivedqtyFieldVisible(false);
        setPendingFieldVisible(false);
        // setIsreceivedqtyFieldVisible(true); // Set to true or false based on your requirement
        break;
      default:
        setIsrtvFieldVisible(false); // Set to true or false based on your requirement
    }
  }, [currentStatus]);

  const getStatusDisplayText = (backendStatus) => {
    const statusMappings = {
      pending: "Pending",
      release: "Release",
      partialreceived: "Pending For Receiving",
      received: "Receiving Done ",
      return: "GRN Confirmation Pending",
      zoho: "Sent to Zoho",

      cancel: "Cancel",
      // Add more status mappings as needed
    };

    return statusMappings[backendStatus] || backendStatus;
  };
  const StatusTag = ({ status }) => {
    const displayText = getStatusDisplayText(status);

    let tagColor;

    switch (displayText) {
      case "Pending":
        tagColor = "orange";
        break;
      case "Release":
        tagColor = "green";
        break;
      case "Pending For Receiving":
        tagColor = "purple";
        break;
      case "Receiving Done ":
        tagColor = "brown";
        break;
      case "GRN Confirmation Pending":
        tagColor = "blue";
        break;
      case "Sent to Zoho":
        tagColor = "magenta";
        break;
      case "Cancel":
        tagColor = "red";
        break;
      // Add more cases as needed for different statuses

      default:
        tagColor = "red";
        break;
    }

    return (
      <Label style={{ backgroundColor: tagColor, color: "white" }} tag>
        {displayText}
      </Label>
    );
  };
  console.log("currentStatus-----------------", currentStatus);

  const handleSaveClick = async () => {
    if (selectedFile) {
      const key = `${Date.now()}_${selectedFile.name}`;
      const params = {
        Bucket: "fambo-assets",
        Key: `farmer/${key}`,
        Body: selectedFile,
      };
      s3.upload(params, async (err, data) => {
        if (err) {
          console.log("Error uploading file:", err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          console.log("clickedRowId----------", clickedRowId);

          const updatedPoData = PoData?.purchase_skus?.map((sku) => {
            if (sku?.sku_code === clickedRowId) {
              console.log("i am the clicked row", sku);
              return {
                ...sku,
                purchase_attach: key,
              };
            }
            return sku;
          });

          setTempSkuData(updatedPoData);
          setFileName(key);
          handleAttachmentClose();
        }
      });
    }
  };

  console.log("PoData-------------", PoData);

  const handleQuantityChange = (e, skuId, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs", "pc"];
    const parts = value.toString().split(".");
    console.log("parts", parts);
    if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
      value = Math.floor(Number(value));
      setErrorMessage1(`Quantity cannot be in decimal for SKU with UOM in Piece.       
              Click on Proceed to move ahead.`);
      setErrorPopupOpen1(true);
    } else if (value < 0) {
      console.log("order_qty", original?.order_qty);

      setErrorMessage3("Order quantity should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3(
        "Please enter order quantity upto 2 decimal place only ."
      );

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    }
    // Limit the value to 50000
    else if (parseFloat(value) > 50000) {
      value = 50000;
    }

    // Update the quantity values state
    setQuantityValues((prevValues) => ({
      ...prevValues,
      [skuId]: parseFloat(value),
    }));

    // Update tempSkuData based on edited quantity
    setTempSkuData((prevData) => {
      return prevData?.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use sku_id
          return item?.sku_id === skuId
            ? { ...item, order_qty: parseFloat(value) }
            : item;
        } else {
          // If action is not 'edit', use sku_id
          return item?.sku_id === skuId
            ? { ...item, order_qty: parseFloat(value) }
            : item;
        }
      });
    });
  };
  const handleGrnQuantityChange = (e, skuId, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs", "pc"];
    const parts = value.toString().split(".");
    if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
      value = Math.floor(Number(value));
      setErrorMessage1(`Quantity cannot be in decimal for SKU with UOM in Piece.       
              Click on Proceed to move ahead.`);
      setErrorPopupOpen1(true);
    } else if (value > original?.order_qty) {
      console.log("order_qty", original?.order_qty);
      console.log("value in grn qty ", parseFloat(value));

      setErrorMessage3(
        "Receiving quantity should not be greater than order quantity."
      );

      setErrorPopupOpen3(true);
      value = original?.order_qty;
    } else if (value < 0) {
      console.log("order_qty", original?.order_qty);

      setErrorMessage3("Receiving quantity should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3(
        "Please enter Receiving Qty upto 2 decimal place only ."
      );

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    }

    // If the entered value is not empty, update the state with the parsed float value
    setGrnQuantityValues((prevValues) => ({
      ...prevValues,
      [skuId]: parseFloat(value) || 0,
    }));

    // Update tempSkuData based on edited quantity

    // Update tempSkuData based on edited quantity
    setTempSkuData((prevData) => {
      return prevData?.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use sku_id
          return item?.sku_id === skuId
            ? { ...item, rcvd_qty: parseFloat(value) || 0 }
            : item;
        } else {
          // If action is not 'edit', use sku_id
          return item?.sku_id === skuId
            ? { ...item, rcvd_qty: parseFloat(value) || 0 }
            : item;
        }
      });
    });
  };

  const handleRtvQuantityChange = (e, skuId, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs", "pc"];
    const parts = value.toString().split(".");
    if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
      value = Math.floor(Number(value));
      setErrorMessage1(`Quantity cannot be in decimal for SKU with UOM in Piece.       
              Click on Proceed to move ahead.`);
      setErrorPopupOpen1(true);
    }

    console.log("Handling quantity change for skuId:", skuId);
    console.log("New quantity value:", value);
    const rcvdQty = original?.rcvd_qty;
    if (value < 0) {
      // Update the quantity values state
      setErrorMessage2(
        `You are tring to Return very less Quantity .Please ensure that the entered quantity for return is greater than 0.`
      );
      setErrorPopupOpen2(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3("Please enter Return Qty upto 2 decimal place only .");

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    }

    if (value <= rcvdQty) {
      // Update the quantity values state
      setRtvQuantityValues((prevValues) => ({
        ...prevValues,
        [skuId]: parseFloat(value) || 0,
      }));

      // Update tempSkuData based on edited quantity

      setTempSkuData((prevData) => {
        return prevData?.map((item) => {
          if (action === "edit") {
            // If action is 'edit', use sku_id
            return item.sku_id === skuId
              ? { ...item, rtv_qty: parseFloat(value) || 0 }
              : item;
          } else {
            // If action is not 'edit', use sku_id
            return item.sku_id === skuId
              ? { ...item, rtv_qty: parseFloat(value) || 0 }
              : item;
          }
        });
      });
    } else {
      // setErrorMessage("Enter quantity ");
      setSaveButtonDisabled(true);
      console.log("Invalid quantity value");
    }
  };

  const handleRateChange = (e, skuId, original) => {
    let { value } = e.target;
    const lastPurchasePrice = original.last_purchase_price || 0;
    const rateLimit = lastPurchasePrice + lastPurchasePrice * 0.1; // 10% of last purchase price
    const parts = value.toString().split(".");
    console.log("Handling rate change for skuId:", skuId);
    console.log("New rate value:", value);
    if (parseFloat(value) > rateLimit) {
      // Display an error message or handle it as needed
      setErrorMessage1(`Amount entered exceeded 10% of last purchase price.       
       An email alert will be automatically sent to the respective supervisor.       Click on Proceed to move ahead.`);
      setErrorPopupOpen1(true);
      // You can set an error state here or display a notification
      // setErrorState("Rate exceeds the limit!");
      // Do not update state or continue if rate exceeds the limit
    } else if (value < 0) {
      console.log("value", value);

      setErrorMessage3("Rate should  be greater than 0.");

      setErrorPopupOpen3(true);
      value = 0;
    } else if (parts?.length >= 0 && parts[1]?.length > 2) {
      // Convert to maximum 2 decimal places
      setErrorMessage3("Please enter rate upto 2 decimal place only .");

      setErrorPopupOpen3(true);

      value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
      console.log("value here after decimal round off ", value);
    }
    // Limit the value to 500000
    else if (parseFloat(value) > 500000) {
      value = 500000;
    }
    // Update the rate values state
    setRateValues((prevValues) => ({
      ...prevValues,
      [skuId]: parseFloat(value),
    }));

    // Update tempSkuData based on edited rate
    setTempSkuData((prevData) => {
      return prevData?.map((item) => {
        if (action === "edit") {
          // If action is 'edit', use sku_id
          return item.sku_id === skuId
            ? { ...item, rate: parseFloat(value) }
            : item;
        } else {
          // If action is not 'edit', use sku_id
          return item.sku_id === skuId
            ? { ...item, rate: parseFloat(value) }
            : item;
        }
      });
    });
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const { action, poData } = useSelector((state) => state?.po);
  console.log("selected PO DATA ", poData);
  console.log("Action", action);
  const fetchPOTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_PO_MODULE_POTYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.Data?.map((row) => row?.dropdown_value);
      setPOTypeOptions(options);
    } catch (error) {
      console.log("Error", error);
      // Handle error
    }
  };
  useEffect(() => {
    if (action === "add" && !isDateFilled) {
      setInfoMessage("Warehouse and Delivery date is mandatory.");
      setInfoPopupOpen(true);
    }
  }, [action, isDateFilled]);

  useEffect(() => {
    switch (action) {
      case "edit":
        if (poData) {
          // If editing, prefill the form with the warehouseData
          console.log("PO  id in edit form ", poData?.po_id);
          console.log("edit action performed");

          // Update only the fields present in warehouseData
        }
        break;

      case "add":
        // If adding, clear the form data
        console.log("add action performed");

        // If adding, clear the form data
        setSelectedWarehouse("");
        setSelectedVendorId("");

        // Clear other form fields
        setPoData({
          delivery_date: "",
          ext_po_no: "",
          type: "",
          reference_id: "",
          description: "",
          purchase_skus: [],
          vendor_id: "",
          warehouse_id: "",
          status: "pending",
          // Add other fields here
        });
        break;

      // Add more cases if needed

      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, poData]);

  useEffect(() => {
    const fetchDataForEdit = async () => {
      console.log("PO  id in edit form ", poData?.po_id);
      if (action === "edit" && poData?.po_id) {
        try {
          // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint for fetching PO data by ID
          const response = await axiosInstance.get(
            API_ENDPOINTS.GET_WMS_PO_DETAILS(poData?.po_id)
          );

          if (response.status === 200) {
            const poDataFromApi = response?.data?.Data;
            const deliveryDate = new Date(Number(poDataFromApi?.delivery_date));
            const formattedDeliveryDate = `${deliveryDate.getDate()}/${
              deliveryDate.getMonth() + 1
            }/${deliveryDate.getFullYear()}`;
            const selectedVendor = vendorData?.find(
              (vendor) => vendor?.vendor_name === poDataFromApi?.vendor_name
            );
            const skuDetails = poDataFromApi?.purchase_skus || [];

            // Initialize input and rate values for the selected rows

            const initialQuantityValues = {};
            const initialRateValues = {};
            const initialGrnQuantityValues = {};
            const initialRtvQuantityValues = {};
            poDataFromApi?.purchase_skus?.forEach((sku) => {
              initialQuantityValues[sku.sku_id] = sku.order_qty || "";
              initialRateValues[sku.sku_id] = sku.rate || "";
              initialGrnQuantityValues[sku.sku_id] = sku.rcvd_qty || 0;
              initialRtvQuantityValues[sku.sku_id] = sku.rtv_qty || 0;
            });
            console.log("setgrnqunatityvalues", initialGrnQuantityValues);
            console.log("setquantityvalues : ", initialQuantityValues);
            console.log("setratevalues", initialRateValues);
            setQuantityValues(initialQuantityValues);
            setRateValues(initialRateValues);
            setGrnQuantityValues(initialGrnQuantityValues);
            setRtvQuantityValues(initialRtvQuantityValues);
            setSelectedVendorId(poDataFromApi?.vendor_id);
            setSelectedVendorName(poDataFromApi?.vendor_name);
            setWarehouseId(poDataFromApi?.warehouse_id);
            setVendorId(poDataFromApi?.vendor_id);

            // Adjust this based on your API response structure
            setPoData((prevData) => ({
              ...prevData,
              ...poDataFromApi,
              delivery_date: deliveryDate.getTime(),
            }));

            setSelectedWarehouse(poDataFromApi?.warehouse_id);
            setTempSkuData(poDataFromApi?.purchase_skus);
            //setCurrentStatus('partialrecieved');
            setCurrentStatus(poDataFromApi?.purchase_order_status);
            console.log(
              "current status , ",
              poDataFromApi?.purchase_order_status
            );
            console.log("testtttttttttttttt", poDataFromApi?.purchase_skus);
            console.log("PO in edit form ", poDataFromApi);
          } else {
            console.log("Error fetching PO data");
          }
        } catch (error) {
          console.log("Error fetching PO data:", error);
        }
      }
    };

    fetchDataForEdit();
  }, [poData, vendorData]);

  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  useEffect(() => {
    setIsAddSkuButtonEnabled(vendorId !== null && warehouseId !== null);
  }, [vendorId, warehouseId]);

  // useEffect(() => {
  //   console.info(rowSelection);
  // }, [rowSelection]);
  const handleDialogOpen = () => {
    // Check if the current status is "received"
    if (currentStatus === "received") {
      // If it is, set senttoStatus to "return" and don't open the dialog
      setSentToStatus("return");
      setIsDialogOpen(true);
    } else {
      // If not, open the dialog
      setIsDialogOpen(true);
    }
  };

  const handleStatusClick = (status) => {
    setSentToStatus(status);
  };
  const handleDialogClose1 = async () => {
    setSentToStatus(null);
    setIsDialogOpen(false);
  };
  const handleDialogClose = async () => {
    if (senttoStatus) {
      await updatePO();
    }

    setIsDialogOpen(false);
  };
  const handleSelectSKU = () => {
    setDialogOpen(true);
  };

  const handleStatusChange = (event) => {
    const selectedLabel = event.target.value;
    const selectedOption = statusOptions?.find(
      (option) => option?.label === selectedLabel
    );

    // If the selectedOption is found, update the senttoStatus state with its value
    if (selectedOption) {
      setSentToStatus(selectedOption.value);
    }
  };

  const handleAddClick = (currentSelectedRows, rowSelection) => {
    console.log("currentSelectedRows in handleAddClick", currentSelectedRows);
    console.log(
      "rowselection in po page coming from add skutable ",
      rowSelection
    );
    console.log("in handle ADD CLICK tempskudata initially", tempSkuData);
    // Create a copy of tempSkuData to avoid mutating the state directly
    const updatedSkuData = tempSkuData?.map((row) => {
      const isSelected = rowSelection[row?.sku_id];
      return {
        ...row,
        flag: true, // Set flag to true if the row is selected
      };
    });
    currentSelectedRows?.forEach((newRow) => {
      const existingRowIndex = updatedSkuData?.findIndex(
        (existingRow) => existingRow?.sku_id === newRow?.sku_id
      );
      console.log(
        "in handle ADD CLICK currentselectedrows while comapring ",
        tempSkuData[existingRowIndex]
      );
      if (existingRowIndex !== -1) {
        console.log("new row in if statement ", newRow);
        // If the row already exists, replace it with the new data
        updatedSkuData[existingRowIndex] = {
          ...tempSkuData[existingRowIndex],
          flag: rowSelection[newRow?.sku_id],
          rate: newRow?.rate,
          order_qty: newRow?.order_qty,
        };
        console.log(
          "updated sku data in if stattemnet if thst row already exist ",
          updatedSkuData
        );
      } else {
        // If the row doesn't exist, add it to the array
        updatedSkuData?.push({
          ...newRow,
          flag: rowSelection[newRow?.sku_id],
        });
        console.log(
          "updated sku data in else stattemnet if thst row already not exist ",
          updatedSkuData
        );
      }
      setRowSelection((prevRowSelection) => ({
        ...prevRowSelection,
        [newRow.sku_id]: true,
      }));
    });
    // console.log("row sleection in handle add clicck ",newRow?.item_id);
    console.log("row sleection in handle add clicck ", rowSelection);
    // Update the rowSelection state
    // Filter out the deselected rows from tempSkuData
    const filteredSkuData = updatedSkuData?.filter(
      (row) => rowSelection[row.sku_id]
    );
    console.log("filtered sku data  in handle add clicck ", filteredSkuData);
    // Initialize input and rate values for the selected rows
    const initialQuantityValues = {};
    const initialRateValues = {};
    const initialgrnValues = {};
    const initialRtvValues = {};
    updatedSkuData?.forEach((row) => {
      const skuId = action === "edit" ? row.sku_id : row.sku_id;

      initialQuantityValues[skuId] = row?.order_qty || 0;
      initialgrnValues[skuId] = row?.rcvd_qty || 0;
      initialRtvValues[skuId] = row?.rtv_qty || 0;
      initialRateValues[skuId] = row?.rate || ""; // Change 'rate' to the correct field name
    });

    setQuantityValues(initialQuantityValues);
    setRateValues(initialRateValues);
    setGrnQuantityValues(initialgrnValues);
    setRtvQuantityValues(initialRtvValues);
    setTempSkuData(updatedSkuData);

    setDialogOpen(false);
  };
  useEffect(() => {
    // Define the mapping of currentStatus to options
    //sourcing can't  have access  of another status like only have access of pending , 
    //release,sent to zoho
    const statusMapping = {
      pending: [
        { label: "Pending", value: "pending" },
        { label: "Release", value: "release" },
        { label: "Cancel", value: "cancel" },
      ],
      
      release: [
        ...(userDetail?.b_po_receiving_status   
          ? []
          : [{ label: "Pending For Receiving", value: "partialreceived" }]),
        { label: "Cancel", value: "cancel" },
      ],
      partialreceived: [
        ...(userDetail?.b_po_receiving_status
          ? []
          : [
              { label: "Pending For Receiving", value: "partialreceived" },
              { label: "Receiving Done ", value: "received" },
            ]),
      ],
      received: [
        ...(userDetail?.b_po_receiving_status
          ? []
          : [{ label: "GRN Confirmation Pending", value: "return" }]),
      ],
      return: [  ...((userDetail?.b_purchase_order_send_to_zoho) 
      ? []
      :[{ label: "Sent to Zoho", value: "zoho" }]),      ],

      //zoho: [{ label: "Sent To Zoho", value: "zoho" }],
      // Add more mappings as needed
    };

    // Set the options based on the currentStatus
    setStatusOptions(statusMapping[currentStatus.toLowerCase()] || []);
  }, [currentStatus]);

  const handleChangePage = (currentSelectedRows) => {
    console.log("currentSelectedRows in handleChangePage", currentSelectedRows);
    const updatedSkuData = tempSkuData?.map((existingRow) => {
      const matchingRow = currentSelectedRows?.find(
        (newRow) => newRow?.sku_id === existingRow?.sku_id
      );
      return matchingRow ? matchingRow : existingRow;
    });
    currentSelectedRows?.forEach((newRow) => {
      const exists = updatedSkuData?.some(
        (existingRow) => existingRow?.sku_id === newRow?.sku_id
      );
      if (!exists) {
        updatedSkuData.push(newRow);
      }
    });

    setTempSkuData(updatedSkuData);
  };

  const handleCloseDialog = () => {
    console.log("Handle close dialog clicked");
    setDialogOpen(false);
  };

  const createPO = async () => {
    const hasEmptyFields = tempSkuData?.some(
      (row) =>
        !row.rate ||
        row.rate.toString().trim() === "" ||
        !row.order_qty ||
        row.order_qty.toString().trim() === ""
    );

    if (hasEmptyFields) {
      console.log(
        "Error: Please make sure all selected rows have a non-empty quantity & rate."
      );
      // Display an error message and prevent saving
      setErrorMessage(
        "Please make sure all selected rows have a non-empty quantity & rate."
      );
      setSentToStatus(null);
      setErrorPopupOpen(true);
      // setSaveButtonDisabled(true);
      return;
    }
    try {
      setPoLoading(true);
      // Determine the endpoint for creating a new warehouse
      const endpoint = API_ENDPOINTS.POST_WMS_CREATE_PO_ORDER(
        "pending",
        selectedVendorId,
        selectedWarehouse
      );
      console.log("API Endpoint:", endpoint);

      console.log("Creating Data:", tempSkuData);
      const updatedSkuList = tempSkuData?.map((item) => {
        return {
          ...item,
          rate: isNumeric(item?.rate) ? parseFloat(item?.rate) : 0,
          order_qty: isNumeric(item?.order_qty)
            ? parseFloat(item?.order_qty)
            : 0,
          rcvd_qty: isNumeric(item?.rcvd_qty) ? parseFloat(item?.rcvd_qty) : 0,
          rtv_qty: isNumeric(item?.rtv_qty) ? parseFloat(item?.rtv_qty) : 0,

          tax: isNumeric(item?.tax) ? parseFloat(item?.tax) : 0,
        };
      });

      function isNumeric(value) {
        return (
          value !== null && value !== undefined && value !== "" && !isNaN(value)
        );
      }
      console.log("PO Data:", PoData);
      const payload = {
        delivery_date: PoData?.delivery_date || null,
        ext_po_no: PoData?.ext_po_no || null,
        type: PoData?.type || null,
        reference_id: PoData?.reference_id || null,
        description: PoData?.description || null,
        purchase_skus: updatedSkuList || [],
        vendor_id: selectedVendorId || null,
        warehouse_id: selectedWarehouse || null,
        status: "pending",
      };
      console.log("POn payload  Data:", payload);

      // Send the POST request to create a new warehouse
      const response = await axiosInstance.post(
        endpoint,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
        // WarehouseData,
      );

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("PO created successfully!");
        setSuccessPopupOpen(true);
        // localStorage.setItem("warehouse_data",warehouse);

        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the PO");
        setErrorMessage("Failed to create the PO. Please try again.");
        setErrorPopupOpen(true);
        setSentToStatus(null);
      }
    } catch (error) {
      //setErrorPopupOpen(true);
      // setPoLoading(false);
      setSentToStatus(null);
      console.error("Error occurred while creating the PO:", error);
    }
  };
  console.log("temp sku state  ", tempSkuData);
  useEffect(() => {
    if (successPopupOpen) {
      // Display success popup for 3 seconds (adjust the duration as needed)
      const timer = setTimeout(() => {
        // Navigate to the next page
        navigate("/po");
        setPoLoading(false);
        setSuccessPopupOpen(false);
      }, 700);

      // Clear the timer if the component unmounts or if successPopupOpen becomes false
      return () => clearTimeout(timer);
    }
  }, [successPopupOpen]);

  const fetchBillDetails = async (poId) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_BILL_DATA, {
        params: {
          po_id: poId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setBillData(response?.data?.data);
      }
    } catch (error) {
      setIsLoading(false);
      setResponseMessage("Some Error Occured, Try Again!");
    }
  };

  const updatePO = async () => {
    try {
      if (!poData?.po_id) {
        console.error("PO id is missing.");
        setErrorMessage("Please try Again !!!");
        return;
      }

      const hasEmptyFields = tempSkuData?.some(
        (row) =>
          !row?.rate ||
          row?.rate.toString().trim() === "" ||
          !row?.order_qty ||
          row?.order_qty.toString().trim() === ""
      );

      console.log("Function called after checking empty fields");

      if (hasEmptyFields) {
        console.log(
          "Error: Please make sure all selected rows have a non-empty quantity & rate."
        );

        // Display an error message and prevent saving
        setErrorMessage3(
          "Please make sure all selected rows have a non-empty quantity & rate."
        );
        setSentToStatus(null);
        setErrorPopupOpen3(true);
        setSaveButtonDisabled(true);
        // setSaveButtonDisabled(false);
        console.log("Save button disabled");
      } else {
        console.log("else statement ");
        // Check if order_qty > rcvd_qty for every item
        const hasInvalidQty = tempSkuData?.some(
          (item) => parseFloat(item?.order_qty) < parseFloat(item?.rcvd_qty)
        );
        if (hasInvalidQty) {
          console.error(
            "Error: Order quantity should be greater than or equal to received quantity."
          );
          setErrorMessage3(
            "Order quantity should be greater than or equal to received quantity.Please recheck received quantity of each item."
          );
          setErrorPopupOpen3(true);
          setSentToStatus(null);
          return;
        }
      }
      // // Check if order_qty, rcvd_qty, rtv_qty, rate are greater than 0 for every item
      // const hasInvalidValues = tempSkuData?.some(
      //     (item) =>
      //         parseFloat(item?.order_qty) <= 0 ||
      //         parseFloat(item?.rcvd_qty) <= 0 ||
      //         parseFloat(item?.rtv_qty) < 0 ||
      //         parseFloat(item?.rate) <= 0
      // );
      // if (hasInvalidValues) {
      //     console.error(
      //         "Error: Please ensure that all order qty, rcvd qty, rtv qty, and rate are greater than 0."
      //     );
      //     setErrorMessage3(
      //         "Please ensure that all order qty, rcvd qty, rtv qty, and rate are greater than 0."
      //     );
      //     setErrorPopupOpen3(true);
      //     setSentToStatus(null);
      //     return;
      // }

      if (senttoStatus === "partialreceived" || senttoStatus === "received") {
        // Calculate sum of received quantities
        const sumReceivedQty = tempSkuData?.reduce(
          (total, item) => total + (parseFloat(item?.rcvd_qty) || 0),
          0
        );

        if (sumReceivedQty <= 0) {
          // Display error message if sum of received quantities is less than or equal to 0
          console.error(
            "Error: Sum of receiving quantities is less than or equal to 0."
          );
          setErrorMessage3(
            "You cannot proceed further as you are trying to proceed with 0 receiving quantity."
          );

          setErrorPopupOpen3(true);
          setSentToStatus(null);
          return;
        }
      }
      if (senttoStatus === "zoho") {
        const sumReceivedQty = tempSkuData?.reduce(
          (total, item) => total + (parseFloat(item?.rcvd_qty) || 0),
          0
        );
        console.log("sumReceivedQty qty in zoho status", sumReceivedQty);
        const sumRtvQty = tempSkuData?.reduce(
          (total, item) => total + (parseFloat(item?.rtv_qty) || 0),
          0
        );
        console.log("sumRtvQty qty", sumRtvQty);
        const overallQty = sumReceivedQty - sumRtvQty;
        console.log("overall qty", overallQty);
        if (overallQty <= 0) {
          // Display error message if sum of received quantities is less than or equal to 0
          console.error(
            "Error: Sum of receiving quantities is less than or equal to 0."
          );
          setErrorMessage3(
            "You cannot proceed further as you are trying to proceed with 0  quantity."
          );

          setErrorPopupOpen3(true);
          setSentToStatus(null);
          return;
        }
      }

      // Proceed with API call
      console.log("Proceeding with API call...");

      setPoLoading(true);
      const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_PO_ORDER(
        poData?.po_id
      )}?status=${encodeURIComponent(senttoStatus)}`;
      console.log("API Endpoint:", endpoint);
      console.log("temp sku state in update pO  ", tempSkuData);

      const skuList = Array.isArray(tempSkuData) ? tempSkuData : [];
      const updatedSkuList = skuList?.map((item) => {
        return {
          ...item,
          rate: isNumeric(item?.rate) ? parseFloat(item?.rate) : 0,
          order_qty: isNumeric(item?.order_qty)
            ? parseFloat(item?.order_qty)
            : 0,
          rcvd_qty: isNumeric(item?.rcvd_qty) ? parseFloat(item?.rcvd_qty) : 0,
          rtv_qty: isNumeric(item?.rtv_qty) ? parseFloat(item?.rtv_qty) : 0,
          tax: isNumeric(item?.tax) ? parseFloat(item?.tax) : 0,
        };
      });

      function isNumeric(value) {
        return (
          value !== null && value !== undefined && value !== "" && !isNaN(value)
        );
      }
      console.log("updated SKU List in update SO  ", updatedSkuList);
      const payload = {
        delivery_date: poData?.delivery_date || null,
        ext_po_no: poData?.ext_po_no || null,
        type: poData?.type || null,
        reference_id: poData?.reference_id || null,
        description: poData?.description || null,
        purchase_skus: updatedSkuList,
        vendor_id: selectedVendorId || null,
        warehouse_id: selectedWarehouse || null,
        purchase_order_status: senttoStatus,
      };

      console.log("Payload sent in edit API-------", payload);

      // handleDialogOpen();
      const response = await axiosInstance.put(
        endpoint,

        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("PO updated Successfully!");
        setReceivedPoId(response?.data?.Data?.po_id);
        if (response?.data?.Data?.status === "zoho") {
          setOpenBillUploadDialog(true);
          // setTimeout(() => {
          //   setSuccessPopupOpen(true);
          //   navigate("/po");
          //   setPoLoading(false);
          //   setSentToStatus(null);
          // }, 4000);
        } else {
          setSuccessPopupOpen(true);
          navigate("/po");
          setPoLoading(false);
          setSentToStatus(null);
        }
      } else {
        if (response.status === 400) {
          console.error("Bad Request: ", response?.data?.msg);
          setErrorMessage(response?.data?.msg);
          setErrorPopupOpen(true);
          setSentToStatus(null);
          setPoLoading(false);
        }
      }
    } catch (error) {
      // setErrorPopupOpen(true);
      console.error("Error occurred while updating the warehouse data:", error);
      if (error.response && error.response.status === 400) {
        console.error("Bad Request: ", error.response?.data?.msg);
        setErrorMessage(error.response?.data?.msg);
        setErrorPopupOpen(true);
        setSentToStatus(null);
        setPoLoading(false);
      } else if (
        error.response &&
        error.response.status >= 500 &&
        error.response.status < 600
      ) {
        console.error("Server Error: ", error.response?.data?.msg);
        setErrorMessage("Server Error. Please try again later.");
        setErrorPopupOpen(true);
        setSentToStatus(null);
        setPoLoading(false);
      } else {
        console.error("Failed to update the data", error.response?.data?.msg);
        setErrorMessage(error.response?.data?.msg);
        setErrorPopupOpen(true);
        setSentToStatus(null);
        setPoLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (action === "edit") {
        // If editing, send a PUT request
        //  await updatePO();
        handleDialogOpen();
      } else {
        // If adding, send a POST request
        await createPO();
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error("Error submitting po details:", error);
      setErrorMessage("Something Went Wrong !");
    }
  };
  const handleWarehouseChange = (e) => {
    const selectedWarehouseId = e.target.value;
    setSelectedWarehouse(selectedWarehouseId);
    setWarehouseId(selectedWarehouseId);
    // Console log the selected warehouse ID
    console.log("Selected Warehouse ID:", selectedWarehouseId);
    // Check if both warehouse and vendor IDs are selected before making the API call

    // You can store the selected warehouse ID in state for later use
    // setYourOtherState(selectedWarehouseId);
  };
  const handleChange = (field, value) => {
    setPoData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  useEffect(() => {
    const fetchVendorData = async (id) => {
      console.log(" id in vendor function", id);
      setIsLoading(true);
      // setVendorData(dummyVendorData);
      try {
        const response = await axiosInstance.get(
          API_ENDPOINTS.GET_WMS_VENDOR_LIST_FILTER,
          {
            params: {
              warehouse_id: id,
              limit: 1000,
              offset: 1,
              status: true,
            },

            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setIsLoading(false);
        if (response.status >= 200 && response.status < 305) {
          const data = response?.data?.data?.vendor_list || [];
          setVendorData(data);

          console.log("vendor data", data);
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMessage("Please Try Again");
        setErrorPopupOpen(true);
      }
    };
    if (warehouseId) {
      fetchVendorData(warehouseId);
    }
  }, [warehouseId]);
  // useEffect(() => {
  //   // Check if both warehouse and vendor IDs are selected before making the API call
  //   if (selectedWarehouse && selectedVendorId) {
  //     fetchSKUwrtVendor(selectedWarehouse, selectedVendorId);
  //   }
  // }, [selectedWarehouse, selectedVendorId]);
  const handleBack = () => {
    navigate("/po");
  };
  // const fetchSKUwrtVendor = async (warehouseId, vendorId) => {
  //   console.log("warehouse id in vendor function", warehouseId);
  //   setIsLoading(true);

  //   try {
  //     const response = await axiosInstance.get(
  //       API_ENDPOINTS.GET_WMS_PO_VENDOR_SKU_LIST,
  //       {
  //         params: {
  //           warehouse_id: warehouseId,
  //           vendor_id: vendorId,
  //           //vendor_id:'01HKQ202D59462BKAV0YEKHF4S',
  //           limit: 100,
  //           offset: 1,
  //         },
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setIsLoading(false);
  //     if (response.status >= 200 && response.status < 305) {
  //       const data = response?.data?.data?.purchase_skus || [];
  //       setSkuData(data);
  //       console.log("SKU wrt vendor data", data);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     setErrorMessage("Please Try Again");
  //     setErrorPopupOpen(true);
  //   }
  // };
  const handleChangeVendor = (event, newValue) => {
    const selectedVendorId = newValue?.vendor_id || "";
    setSelectedVendorId(selectedVendorId);
    setSelectedVendor(selectedVendorId);
    setVendorId(selectedVendorId);

    console.log("Selected Vebndor ID:", selectedVendorId);
    // if (selectedWarehouse && selectedVendorId) {
    //   fetchSKUwrtVendor(selectedWarehouse, selectedVendorId);
    // }
  };

  console.log("vendor id ", selectedVendorId);

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "48vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    maxHeight: "52vh",
    width: "100%",
    marginTop: "-1%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };

  const containerStyle = {
    maxHeight: "48vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    // Convert the selected date to epoch format
    const epochDate = selectedDate ? new Date(selectedDate).getTime() : null;
    setIsDateFilled(!!selectedDate);

    setDateFieldValue(selectedDate);
    // Check if epochDate is a valid number
    if (!isNaN(epochDate)) {
      // Update PoData with the epoch date
      handleChange("delivery_date", epochDate);
    } else {
      console.error("Invalid date format:", selectedDate);
    }
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
        }}
      >
        <Typography
          // variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%", fontSize: 19 }}
        >
          <MdMenuBook
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp;{" "}
          {action === "edit"
            ? `Purchase Order (${poData?.int_po_no})`
            : "Purchase Order"}
        </Typography>

        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            startIcon={
              <FileDownloadIcon
                style={{ color: colors.white.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              backgroundColor: colors.green.DEFAULT,
            }}
            disabled={currentStatus === "pending" || currentStatus === "cancel"}
            size="small"
            onClick={() => setOpenBillDialog(true)}
          >
            <Typography variant="h7" fontFamily="Lexend Deca">
              Download
            </Typography>
          </Button>
          {saveButtonDisabled && (
            <Button
              variant="contained"
              startIcon={
                <IoMdAddCircleOutline
                  style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                />
              }
              sx={{
                borderColor: colors.green.DEFAULT,
                backgroundColor: colors.green.DEFAULT,
              }}
              disabled={
                tempSkuData?.length === 0 ||
                currentStatus === "zoho" ||
                (action === "add" && !isDateFilled) ||
                currentStatus === "cancel"
              }
              onClick={handleSubmit}
              title="Save"
            >
              <Typography variant="h7" color={colors.white.DEFAULT}>
                {action === "edit" && currentStatus === "return"
                  ? "Perform Next Action"
                  : "Perform Next Action"}
              </Typography>
            </Button>
          )}
          <Button
            variant="outlined"
            startIcon={
              <IoArrowBackCircleOutline
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "0%" }}>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            p={2}
            bgcolor="#ffff"
            height="21vh"
            borderRadius="10px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
          >
            <Grid
              container
              spacing={1.2}
              sx={{ marginTop: "0%", marginLeft: "0.7%" }}
            >
              <Grid item xs={12} sm={2.2} md={2.2}>
                <FormControl fullWidth>
                  <InputLabel>Select Warehouse*</InputLabel>
                  <Select
                    label="Select Warehouse"
                    value={selectedWarehouse}
                    onChange={handleWarehouseChange}
                    size="small"
                    disabled={action === "edit"}
                    displayEmpty
                    required
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Warehouse" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <CiCalendarDate />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {Array.isArray(warehouseData) &&
                      warehouseData?.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2.2} md={2.2}>
                {action === "edit" ? (
                  <>
                    <TextField
                      label="Vendor"
                      name="Vendor"
                      size="small"
                      value={PoData?.vendor_name}
                      fullWidth
                      disabled
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <IoIosPerson />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                ) : (
                  <>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={vendorData || []}
                        getOptionLabel={(option) => option?.vendor_name || ""}
                        value={
                          vendorData.find(
                            (vendor) => vendor.vendor_id === selectedVendorId
                          ) || null
                        }
                        onChange={handleChangeVendor}
                        disabled={!selectedWarehouse || action === "edit"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Vendor"
                            fullWidth
                            required
                            size="small"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    edge="start"
                                    size="small"
                                    disabled
                                  >
                                    <IoIosPerson />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={2.2} md={2.2}>
                <FormControl fullWidth>
                  <InputLabel>PO Type</InputLabel>
                  <Select
                    label="PO Type"
                    fullWidth
                    required
                    size="small"
                    value={PoData?.type || ""}
                    onChange={(e) => handleChange("type", e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Type" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SupervisedUserCircleIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="">PO Type</MenuItem>
                    {poTypeOptions?.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}

                    {/* <MenuItem value="Advanced">Advanced</MenuItem>
                    <MenuItem value="JIT">JIT</MenuItem>
                    <MenuItem value="Day">Day</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2.2} md={2.2} sx={{ marginTop: "-1.2%" }}>
                <TextField
                  label="Delivery Date"
                  name="date"
                  type="date"
                  size="small"
                  value={
                    action === "edit"
                      ? PoData?.delivery_date
                        ? new Date(Number(PoData?.delivery_date))
                            .toISOString()
                            .split("T")[0]
                        : dateFieldValue
                      : dateFieldValue // Set the initial value when action is "add"
                  }
                  onChange={handleDateChange}
                  fullWidth
                  required
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    style: { width: "100%" },
                    min: new Date().toISOString().split("T")[0], // sets min date to today's date

                    required: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <FaCalendarAlt />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={2.2} md={2.2} sx={{ marginTop: "-1.2%" }}>
                <TextField
                  label="External PO Number"
                  name="date"
                  size="small"
                  value={PoData?.ext_po_no}
                  onChange={(e) => handleChange("ext_po_no", e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    maxLength: 50,
                    minLength: 3,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <IoDocumentTextSharp />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1.5}
              sx={{ marginTop: "0%", marginLeft: "0.5%" }}
            >
              <Grid item xs={12} sm={2.2} md={2.2}>
                <TextField
                  label="Description"
                  name="Description"
                  type="Description"
                  size="small"
                  fullWidth
                  value={PoData?.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  // onChange={(e) => handleChange('description', e.target.value)}
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    maxLength: 50,
                    minLength: 3,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          {/* <DescriptionIcon /> */}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2.2} md={2.2}>
                <TextField
                  label="Reference Id"
                  name="Reference Id"
                  type="Description"
                  size="small"
                  value={PoData?.reference_id}
                  onChange={(e) => handleChange("reference_id", e.target.value)}
                  // value={newUserRoleState?.new_email}
                  fullWidth
                  // value={customerData?.description}
                  // onChange={(e) => handleChange('description', e.target.value)}
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    maxLength: 50,
                    minLength: 3,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          {/* <DescriptionIcon /> */}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1.1}
                md={1.1}
                sx={{ marginTop: "1.6%", marginLeft: "0.2%" }}
              >
                <Typography sx={{ fontSize: "15px", fontWeight: 100 }}>
                  Current Status{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.2} md={2.2} sx={{ marginTop: "1.2%" }}>
                <StatusTag status={currentStatus} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {poLoading ? (
            <>
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={poLoading} />
              </div>
            </>
          ) : (
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              height="58vh"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            >
              <Stack
                spacing={0}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ height: "8vh" }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  startIcon={
                    <IoMdAddCircleOutline
                      style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                    />
                  }
                  sx={{
                    backgroundColor: colors.green.DEFAULT,
                    color: colors.white.DEFAULT,
                  }}
                  onClick={handleSelectSKU}
                  title="Add SKU"
                  disabled={
                    !isAddSkuButtonEnabled ||
                    !warehouseId ||
                    !vendorId ||
                    !pendingFieldVisible
                  }
                >
                  <Typography variant="h7" color={colors.white.DEFAULT}>
                    Add SKU Here For PO Creation
                  </Typography>
                </Button>
              </Stack>
              <Grid container spacing={1} style={containerStyle}>
                {isLoading ? (
                  <div style={loaderContainerStyle}>
                    <ScaleLoader color="#495057" loading={isLoading} />
                  </div>
                ) : tempSkuData?.length === 0 ? (
                  <NullScreen
                    containerheight="48vh"
                    containerWidth="100%"
                    screenType="Info"
                    message="Add SKU Here For PO Creation"
                  />
                ) : (
                  <>
                    <div style={gridContainerStyle}>
                      <ThemeProvider theme={tableTheme}>
                        <MaterialReactTable
                          columns={columns}
                          data={currentData}
                          //enableRowSelection
                          getRowId={(row) =>
                            action === "edit" ? row?.sku_id : row?.sku_id
                          }
                          //give each row a more useful id
                          // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                          state={{ rowSelection }}
                          onSelectionModelChange={(selectedModel) => {
                            const newSelection = {};
                            selectedModel?.forEach((selectedRow) => {
                              newSelection[selectedRow] = true;
                            });
                            setRowSelection(newSelection);
                          }}
                          // editingMode="table"
                          //enableEditing
                          enableGrouping
                          enableStickyHeader
                          enableFullScreenToggle={false}
                          enableDensityToggle={false}
                          enableColumnActions={false}
                          enableColumnFilters={false}
                          enablePagination={false}
                          initialState={{
                            density: "compact",
                            pagination: { pageIndex: 0, pageSize: 30 },
                          }}
                          muiTableHeadCellProps={{
                            sx: {
                              fontWeight: "650",
                              color: "#34519fe6",
                              fontSize: "15px",
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr": {
                                backgroundColor: "#fff",
                              },
                            },
                          }}
                          muiTableContainerProps={{ sx: { maxHeight: "32vh" } }}
                          muiToolbarAlertBannerChipProps={{ color: "primary" }}
                        />
                      </ThemeProvider>
                      <Grid
                        container
                        justifyContent="flex-end"
                        sx={{ marginTop: "0.1%" }}
                      >
                        <CustomPagination
                          totalPages={totalPages}
                          currentPage={currentPage}
                          onPageChange={handleChangePage1}
                        />
                      </Grid>
                    </div>
                  </>
                )}
              </Grid>
            </Box>
          )}

          {dialogOpen && (
            <AddSKUTable
              handleChangePage={handleChangePage}
              handleAddClick={handleAddClick}
              selectedVendorId={selectedVendorId}
              warehouseId={warehouseId}
              vendorId={vendorId}
              handleCloseDialog={handleCloseDialog}
            />
          )}
        </Grid>
      </Grid>

      {openBillDialog && (
        <BillProgressPopUp
          setOpenDialog={setOpenBillDialog}
          po_id={poData?.po_id}
        />
      )}

      {openBillUploadDialog && (
        <BillUploadProgressPopUp
          // setOpenDialog={setOpenBillUploadDialog}
          setUploadConfirm={setUploadConfirm}
          po_id={receivedPoId}
        />
      )}

      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose1}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {statusOptions?.length > 0 && (
            <Typography
              variant="h5"
              style={{ marginLeft: "27%" }}
              color={colors.green.DEFAULT}
            >
              Select Status to Proceed
            </Typography>
          )}
          <IconButton
            aria-label="close"
            onClick={() => {
              handleDialogClose1();
            }}
            sx={{
              position: "absolute",
              right: 1.9,
              top: 1,
              color: "grey",
            }}
          >
            <CloseSharpIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          <Grid container spacing={1}>
            {statusOptions?.length > 0 ? (
              statusOptions?.map((option) => (
                <Grid
                  item
                  key={option.label}
                  xs={9}
                  sm={9}
                  md={9}
                  sx={{
                    padding: "3.2%",
                    marginLeft: "25%",
                    marginRight: "20%",
                  }}
                >
                  <Fab
                    variant="extended"
                    size="medium"
                    sx={{
                      backgroundColor:
                        senttoStatus === option.value
                          ? "#34519fe6"
                          : "#2949c73d",
                      "&:hover": {
                        backgroundColor: "#34519fe6",
                        color: "#fff",
                      },
                      padding: "3.2%",
                      width: "100%", // Adjust the width as needed
                      height: "100%",
                    }}
                    onClick={() => handleStatusClick(option?.value)}
                  >
                    <GrStatusGood
                      size={22}
                      style={{
                        marginRight: "8%",
                        color: "#000",
                      }}
                      sx={{
                        "&:hover": {
                          color: "#fff",
                        },
                      }}
                    />
                    <Typography
                      color={colors.black.DEFAULT}
                      fontWeightBold
                      fontWeight={"900"}
                      fontSize={"28px"}
                      sx={{
                        "&:hover": {
                          color: "#fff",
                        },
                      }}
                    >
                      {option?.label}
                    </Typography>
                  </Fab>
                </Grid>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "32%",
                }}
              >
                <Typography
                  color={colors.black.DEFAULT}
                  fontWeightBold
                  style={{ marginLeft: "-3%" }}
                  fontSize={"18px"}
                  sx={{
                    padding: "0.1%",

                    fontWeight: "bold",
                  }}
                >
                  No action needs to be performed !.
                </Typography>
                <IoMdSad
                  style={{
                    fontSize: "50px",
                    color: colors.black[200],
                    marginLeft: "25%",
                  }}
                />
              </div>
            )}
          </Grid>
          <div style={{ marginTop: "20px", textAlign: "right" }}>
            {statusOptions?.length > 0 && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#34519fe6",
                }}
                onClick={() => {
                  handleDialogClose();
                }}
                disabled={!senttoStatus}
                fullWidth
              >
                Save
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
      {errorPopupMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setErrorPopupMessage("")}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {errorPopupMessage}
          </Alert>
        </Snackbar>
      )}

      {isAttachmentDialog && (
        <Dialog open={true} onClose={handleAttachmentClose}>
          <DialogTitle sx={{ position: "sticky", height: "4%" }}>
            Upload Document
            <IconButton
              aria-label="close"
              onClick={handleAttachmentClose}
              sx={{
                position: "absolute",
                right: 1.9,
                top: 1,
                color: "grey",
              }}
            >
              <CloseSharpIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                style={{
                  maxWidth: "100%",
                  maxHeight: "300px",
                  marginBottom: "20px",
                }}
              />
            )}
            <input
              type="file"
              onChange={handleFileChange}
              accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
            />
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "32px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2949c73d", // Default background color
              }}
              onClick={handleSaveClick}
            >
              <Typography
                variant="h6"
                color={colors.white.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Save
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <InfoPopUp
        open={infoPopupOpen}
        onClose={() => setInfoPopupOpen(false)}
        message={infoMessage}
      />
      <AlertDialogBox
        open={errorPopupOpen1}
        okayText="Proceed"
        handleOkayClick={() => setErrorPopupOpen1(false)}
        onClose={() => setErrorPopupOpen1(false)}
        content={errorMessage1}
      />
      <AlertDialogBox
        open={errorPopupOpen2}
        okayText="Proceed"
        handleOkayClick={() => setErrorPopupOpen2(false)}
        onClose={() => setErrorPopupOpen2(false)}
        content={errorMessage2}
      />
      <AlertDialogBox
        open={errorPopupOpen3}
        okayText="Okay"
        handleOkayClick={() => setErrorPopupOpen3(false)}
        onClose={() => setErrorPopupOpen3(false)}
        content={errorMessage3}
      />
    </>
  );
};

export default PONewAddPage;
