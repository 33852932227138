import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Label } from "semantic-ui-react";
import ApiErrorAlert from "../../../ApiErrorAlert";
import SaveAlert from "../../../SaveAlert";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { BorderColor, ErrorOutlineOutlined } from "@mui/icons-material";
import moment from "moment";
import { Checkbox } from "@mui/material";
import Stack from "@mui/material/Stack";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { tokens } from "../../../theme";
import {
  Button,
  Drawer,
  Grid,
  List,
  ListItemIcon,
  ListItem,
  InputAdornment,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  InputLabel,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AWS from "aws-sdk";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "ap-south-1",
});

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const s3 = new AWS.S3();

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "#FDFDFD",
    borderRight: "1px solid #EAEAEA",
    width: "47%",
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function SideDrawer({ isOpen, onClose, details, task_id, fetchTaskData }) {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const handleClose = () => {
    onClose(task_id);
  };
  const currentDetail = details?.find(
    (item) => item?.mandatory_field.id === task_id
  );
  const selectedTask = currentDetail?.mandatory_field?.ledger_name;
  const [isArchive, setIsArchive] = useState(
    currentDetail?.mandatory_field?.status === "Archive"
  );
  const [status, setStatus] = useState(currentDetail?.mandatory_field?.status);
  // const [myLabel, setmyLabel] = useState(currentDetail?.label);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [fileName, setFileName] = useState(
    currentDetail?.optional_field?.proof_url
  );

  console.log("currentDetail------------------", currentDetail);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const updatedFormData = {
        ...formData,
        grades: JSON.stringify(grades),
      };
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_CROPMANAGEMENT_HARVEST,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        console.log("Handle submit clicked");
        setNewHarvestDialog(true);
      } else {
        console.log("Failed to create inventory harvesting record");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log(
        "An error occurred while creating the inventory harvesting record",
        error
      );
    }
  };

  const handleFormChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const MAX_PLANTS = currentDetail?.inv_data?.no_of_plant;

  const [formData, setFormData] = useState({
    farm_name: currentDetail?.inv_data?.farm_name,
    farm_zone: currentDetail?.inv_data?.zone_name,
    batch: currentDetail?.inv_data?.batch,
    no_of_plants: MAX_PLANTS,
    date_of_harvest: "",
    crop_type: currentDetail?.inv_data?.crop_type,
    exp_date_of_harvest: currentDetail?.inv_data?.exp_date_of_harvest,
    crop_name: currentDetail?.inv_data?.crop_name,
    crop_variety_name: currentDetail?.inv_data?.crop_variety_name,
    id: currentDetail?.inv_data?.id,
    old_no_of_plant: currentDetail?.inv_data?.no_of_plant,
    selected_file: "",
    harvest_grade: currentDetail?.inv_data?.harvest_grade,
    date_of_sow: currentDetail?.inv_data?.date_of_sow,
    farm_id: currentDetail?.inv_data?.farm_id_fk,
    crop_id: currentDetail?.inv_data?.crop_id_fk,
    crop_variety_id: currentDetail?.inv_data?.crop_variety_id_fk,
    farm_zone_id: currentDetail?.inv_data?.farm_zone_id_fk,
    action: "Harvesting",
    harvest_type: "Complete",
  });

  useEffect(() => {
    fetchTaskData();
    console.log("fileeeee", fileName);
  }, [fileName]);

  useEffect(() => {
    fetchGradesOptions();
  }, []);

  const handleDownloadClick = () => {
    const presignedUrl = s3.getSignedUrl("getObject", {
      Bucket: "fambo-assets",
      Key: `farmer/${fileName}`,
      Expires: 3600,
    });
    console.log("Presigned URL for viewing:", presignedUrl);
    window.open(presignedUrl, "_blank");
  };

  const getPriorityButtonColor = (priority) => {
    switch (priority) {
      case "To-Do":
        return "#EF5C48";
      case "In Progress":
        return "#579dff";
      case "Completed":
        return "#7EE2B8";
      case "Archive":
        return "#A45A52";
      default:
        return "";
    }
  };

  const extractTextFromHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const paragraphs = Array.from(doc.querySelectorAll("p"));
    const lists = Array.from(doc.querySelectorAll("ol"));
    const extractedText = [];

    const maxCount = Math.max(paragraphs.length, lists.length);

    for (let i = 0; i < maxCount; i++) {
      const heading = paragraphs[i]?.textContent || "";
      const points = lists[i]?.querySelectorAll("li");
      const pointsText = points
        ? Array.from(points).map((li) => li.textContent)
        : [];
      extractedText.push({ heading, points: pointsText });
    }

    return extractedText;
  };

  const extractedText = extractTextFromHTML(
    currentDetail?.optional_field?.instruction
  );

  console.log("INSTRUCTIONSSSSSSSSSSssssss", extractedText);

  const handleAttachmentClick = () => {
    setShowTextField(true);
  };

  const handleTaskDelete = () => {
    setShowConfirmation(true);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleConfirmationOK = async () => {
    const updatedFormData = {
      optional_fields: {
        ...currentDetail.optional_field,
      },
      mandatory_fields: {
        ...currentDetail.mandatory_field,
        status: "Archive",
      },
    };
    const endpoint = API_ENDPOINTS.PUT_COST_MANAGEMENT(
      currentDetail?.mandatory_field?.id
    );
    try {
      const response = await axiosInstance.put(`${endpoint}`, updatedFormData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        fetchTaskData();
        console.log("Initial state of showPopUp", showPopup);
        handleClose();
        setShowConfirmation(false);
        setShowPopup(true);
      } else {
        console.log("Error sending delete request");
        alert(
          "Sorry, an error occurred while deleting the task. Please try again later."
        );
      }
    } catch (error) {
      // setApiErrorOpen(true);
      console.log("Error", error);
      setShowConfirmation(false);
      handleClose();
    }
  };

  const [isAttachmentDialog, setIsAttachmentDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleAttachmentOpen = () => {
    setIsAttachmentDialog(true);
  };

  const handleAttachmentClose = () => {
    setIsAttachmentDialog(false);
    setSelectedFile(null);
  };

  // Using aws-sdk
  const handleSaveAttachmentClick = async () => {
    if (selectedFile) {
      const key = `${Date.now()}_${selectedFile.name}`;

      const params = {
        Bucket: "fambo-assets",
        Key: `farmer/${key}`,
        Body: selectedFile,
      };

      s3.upload(params, async (err, data) => {
        if (err) {
          console.log("Error uploading file:", err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          setFileName(key);
        }
      });
      const updatedFormData = {
        optional_fields: {
          ...currentDetail.optional_field,
          proof_url: key,
        },
        mandatory_fields: {
          ...currentDetail.mandatory_field,
        },
      };
      const endpoint = API_ENDPOINTS.PUT_COST_MANAGEMENT(
        currentDetail?.mandatory_field?.id
      );
      console.log("ENDPOINTTTTTTTTTT", endpoint);
      try {
        const response = await axiosInstance.put(
          `${endpoint}`,
          updatedFormData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 305) {
          console.log("Task edit successful");
        } else {
          console.log("No changes made");
        }
        console.log(response.data);
      } catch (error) {
        // setApiErrorOpen(true);
        console.log("Error", error);
      }
      console.log("EDIT DATAA", { updatedFormData });
    }
    handleAttachmentClose();
  };

  const moment = require("moment");
  const customStyle = {
    fontFamily: "Lexend Deca",
    width: "90%",
    fontSize: 10,
    color: "#FFFFFF",
    // backgroundColor: "#2c7B55",
    backgroundColor: getPriorityButtonColor(
      currentDetail?.mandatory_field?.status
    ),
  };

  const [gradesName, setGradesName] = useState([]);

  const fetchGradesOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_GRADESNAME,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.rows?.map((row) => row?.dropdown_value);
        setGradesName(options);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  console.log(
    "currentDetail?.mandatory_field?.tasks_included",
    currentDetail?.optional_field?.tasks_included
  );

  const [priceConfirmDialog, setPriceConfirmDialog] = useState(false);
  const [newHarvestDialog, setNewHarvestDialog] = useState(false);

  const handlePricePopupClose = () => {
    setPriceConfirmDialog(false);
    setOpenPriceDialog(false);
    handleClose();
    fetchTaskData();
  };
  const handleAddNewHarvest = () => {
    console.log("inside handleAddNewHarvest");
    setNewHarvestDialog(false);
    handleClose();
    fetchTaskData();
  };

  const EditPriceDialog = ({ open, onClose, grades }) => {
    const [newValues, setNewValues] = useState([]);
    const handleTaskEdit = async (totalSum) => {
      console.log("totalSum-----", totalSum);

      const updatedOptionalAmount =
        currentDetail?.optional_field?.amount + totalSum;
      const updatedMandatoryAmount =
        currentDetail?.mandatory_field?.amount + totalSum;
      const updatedFormData = {
        optional_fields: {
          ...currentDetail.optional_field,
          amount: updatedOptionalAmount,
        },
        mandatory_fields: {
          ...currentDetail.mandatory_field,
          status: "Completed",
          amount: updatedMandatoryAmount,
        },
      };
      const endpoint = API_ENDPOINTS.PUT_COST_MANAGEMENT(
        currentDetail?.mandatory_field?.id
      );

      try {
        const response = await axiosInstance.put(
          `${endpoint}`,
          updatedFormData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 305) {
          console.log("Task edit successful");
          setPriceConfirmDialog(true);
        } else {
          console.log("No changes made");
        }
        console.log(response.data);
      } catch (error) {
        console.log("Error", error);
      }
    };
    const handlePriceSubmit = async () => {
      try {
        const totalSum = newValues?.reduce((accumulator, currentValue) => {
          const sumOfGrades = currentValue?.grades?.reduce(
            (gradeAccumulator, grade) => gradeAccumulator + grade.price,
            0
          );
          return accumulator + sumOfGrades;
        }, 0);
        const updatedFormDataWithGradesStringified = newValues?.map((item) => {
          return {
            ...item,
            grades: JSON.stringify(item.grades),
          };
        });

        const response = await axiosInstance.post(
          API_ENDPOINTS.POST_CROPMANAGEMENT_EDIT_GRADES,
          updatedFormDataWithGradesStringified,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 305) {
          handleTaskEdit(totalSum);
        } else {
          console.log("Error in handlePriceSubmit");
        }
      } catch (error) {
        console.log("Error in handlePriceSubmit", error);
      }
    };
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const handleGradePriceChange = (harvestIndex, gradeIndex, newPrice) => {
      const price = parseInt(newPrice, 10);
      const updatedGrades = [...grades];
      updatedGrades[harvestIndex].grades[gradeIndex].price = price;
      setNewValues(updatedGrades);
    };
    // console.log("newValues-------", newValues);
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Prices</DialogTitle>
        <DialogContent>
          {grades?.map((harvest, index) => (
            <div key={index}>
              <List>
                <ListItem style={{ marginLeft: "-3%" }}>
                  <ListItemText
                    primary={`Date of Harvest: ${formatDate(
                      harvest?.date_of_harvest
                    )}`}
                  />
                </ListItem>
                {harvest?.grades?.map((grade, idx) => (
                  <div key={idx}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      display="flex"
                      key={index}
                      style={{ marginTop: "1%" }}
                    >
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Name"
                          fullWidth
                          variant="filled"
                          disabled
                          size="small"
                          margin="normal"
                          value={grade?.name}
                          placeholder="Name"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="No. of Plants"
                          fullWidth
                          variant="filled"
                          disabled
                          size="small"
                          margin="normal"
                          value={grade?.numberOfPlants}
                          placeholder="No. of Plants"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Weight(Kg)"
                          fullWidth
                          variant="filled"
                          disabled
                          size="small"
                          margin="normal"
                          value={grade?.weight}
                          placeholder="Weight(Kg)"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        style={{ marginTop: "-5%" }}
                      >
                        <TextField
                          label="Amount"
                          fullWidth
                          size="small"
                          variant="standard"
                          type="number"
                          margin="normal"
                          value={grade?.price}
                          onChange={(e) =>
                            handleGradePriceChange(index, idx, e.target.value)
                          }
                          placeholder="Amount"
                          inputProps={{
                            style: { width: "100%" },
                            min: 0,
                            max: 100000,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </List>
              <Divider
                sx={{
                  borderBottom: "2px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />
            </div>
          ))}
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-start"
          >
            <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
              <Stack
                spacing={1}
                direction="row"
                style={{
                  height: "32px",
                  marginRight: "0%",
                  marginLeft: "69%",
                }}
              >
                <Button
                  onClick={onClose}
                  variant="outlined"
                  style={{ float: "left", marginLeft: "15px" }}
                >
                  <Typography variant="h6" color={colors.green.DEFAULT}>
                    Cancel
                  </Typography>
                </Button>
                <Button
                  onClick={handlePriceSubmit}
                  variant="contained"
                  sx={{ backgroundColor: colors.green.DEFAULT }}
                  style={{ float: "left" }}
                >
                  <Typography variant="h6" color={colors.white.DEFAULT}>
                    Submit
                  </Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Dialog
            open={priceConfirmDialog}
            onClose={() => setPriceConfirmDialog(false)}
          >
            <DialogTitle style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ width: "60px", height: "60px", color: "#2C7B55" }}
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                color={colors.green.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Price Successfully Updated
              </Typography>
            </DialogContent>
            <Button onClick={handlePricePopupClose}>
              <Typography
                variant="h4"
                color={colors.green.DEFAULT}
                fontFamily="Lexend Deca"
              >
                OK
              </Typography>
            </Button>
          </Dialog>
        </DialogContent>
      </Dialog>
    );
  };

  const initialGrade = { name: "", numberOfPlants: 0, weight: 0, price: 0 };
  const [grades, setGrades] = useState([initialGrade]);

  const handleDeleteGrades = (index) => {
    const updatedGrades = [...grades];
    updatedGrades.splice(index, 1);
    setGrades(updatedGrades);
  };

  const addNewGrades = () => {
    setGrades([...grades, initialGrade]);
  };

  const handleGradeChange = (index, field, value) => {
    const updatedGrades = [...grades];
    updatedGrades[index][field] =
      field === "numberOfPlants" || field === "weight" || field === "price"
        ? parseInt(value, 10)
        : value;
    setGrades(updatedGrades);
  };

  const [openPriceDialog, setOpenPriceDialog] = useState(false);

  const handlePriceClick = () => {
    setOpenPriceDialog(true);
  };

  return (
    <>
      <StyledDrawer anchor="right" open={isOpen} onClose={handleClose}>
        {selectedTask === "Cleaning" && (
          <>
            <List>
              <ListItem
                style={{
                  backgroundColor: "#b5cbbf",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h5",

                    borderRadius: "8px",
                  }}
                  primary={
                    <Typography
                      variant="h2"
                      color={colors.white.DEFAULT}
                      fontWeight={typography.h1}
                    >
                      {currentDetail?.optional_field?.activity_name
                        ? currentDetail?.optional_field?.activity_name?.length >
                          15
                          ? `${currentDetail?.optional_field?.activity_name?.slice(
                              0,
                              15
                            )}...`
                          : currentDetail?.optional_field?.activity_name
                        : currentDetail?.mandatory_field?.ledger_name}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem style={{ marginBottom: "15px" }}>
                <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                  <FormControl>
                    <Label style={customStyle} tag>
                      {currentDetail?.mandatory_field?.status}
                    </Label>
                  </FormControl>
                </Stack>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 10%, #f5faf8 10%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.farm_name}
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.employee_name}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.amount}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Transaction Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.transaction_type}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  No. of Labour&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.labor}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.due_time).format(
                    "HH:mm"
                  )}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.end_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>

              {currentDetail?.optional_field?.start_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Start Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.start_location}
                  </Typography>
                </ListItem>
              )}
              {currentDetail?.optional_field?.end_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    End Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.end_location}
                  </Typography>
                </ListItem>
              )}

              <Divider
                sx={{
                  borderBottom: "1px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />

              {extractedText?.length > 0 ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Additional Instructions!
                    </Typography>
                  </ListItem>

                  <List>
                    {extractedText?.map((item, index) => (
                      <>
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: "18px" }}>
                            <RadioButtonUncheckedIcon
                              sx={{ fontSize: "small" }}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="h6"
                            color={colors.black.DEFAULT}
                            fontFamily="Lexend Deca"
                          >
                            {item.heading}
                          </Typography>
                        </ListItem>
                        <List
                          style={{ paddingLeft: "35px", marginTop: "-15px" }}
                        >
                          {item?.points?.map((point, pointIndex) => (
                            <ListItem key={pointIndex}>
                              {pointIndex + 1}.
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                                sx={{ marginLeft: "3px" }}
                              >
                                {point}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    ))}
                  </List>
                </>
              ) : null}

              {currentDetail?.optional_field?.proof ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Task Completed! Proof Attached.
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ height: "64px" }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={1}>
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                          sx={{
                            marginBottom: "6px",
                          }}
                        >
                          {currentDetail?.optional_field?.proof[0].description}
                        </Typography>
                      </Grid>
                      {!showTextField && (
                        <Grid item xs={6} sm={7}>
                          {userDetails?.task_b_addAttachment && (
                            <>
                              <div>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  {!isArchive ? (
                                    <>
                                      <Button
                                        onClick={handleAttachmentOpen}
                                        startIcon={<AttachmentIcon />}
                                        size="small"
                                        disabled={fileName}
                                        sx={{
                                          color: "#2C7B55",
                                          borderColor: "#2C7B55",
                                          marginRight: "5px",
                                        }}
                                        variant="outlined"
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Add
                                        </Typography>
                                      </Button>

                                      <Button
                                        variant="contained"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{
                                          backgroundColor: "#2C7B55", // Default background color
                                        }}
                                        size="small"
                                        disabled={!fileName}
                                        onClick={handleDownloadClick}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Download
                                        </Typography>
                                      </Button>
                                    </>
                                  ) : null}
                                </Stack>
                                <Dialog
                                  open={isAttachmentDialog}
                                  onClose={handleAttachmentClose}
                                >
                                  <DialogTitle
                                    sx={{ position: "sticky", height: "4%" }}
                                  >
                                    Upload Document
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleAttachmentClose}
                                      sx={{
                                        position: "absolute",
                                        right: 1.9,
                                        top: 1,
                                        color: "grey",
                                      }}
                                    >
                                      <CloseSharpIcon />
                                    </IconButton>
                                  </DialogTitle>
                                  <DialogContent>
                                    {selectedFile && (
                                      <img
                                        src={URL.createObjectURL(selectedFile)}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "300px",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                    />
                                  </DialogContent>

                                  <DialogActions
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "32px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#2C7B55", // Default background color
                                      }}
                                      onClick={handleSaveAttachmentClick}
                                    >
                                      <Typography
                                        variant="h6"
                                        color={colors.white.DEFAULT}
                                        fontFamily="Lexend Deca"
                                      >
                                        Save
                                      </Typography>
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </>
              ) : null}
            </List>

            <div style={{ marginTop: "auto" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    color: "#2C7B55",
                    borderColor: "#2C7B55",
                    marginRight: "5px",
                  }}
                  variant="outlined"
                >
                  <Typography
                    variant="h6"
                    color={colors.green.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Close
                  </Typography>
                </Button>
                {userDetails?.task_b_delete && (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<DeleteIcon />}
                      disabled={isArchive}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      onClick={handleTaskDelete}
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Archive
                      </Typography>
                    </Button>
                  </>
                )}

                <Dialog
                  open={showConfirmation}
                  onClose={handleConfirmationClose}
                >
                  <DialogTitle style={{ textAlign: "center" }}>
                    <ErrorOutlineOutlined
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#A45A52",
                      }}
                    />
                  </DialogTitle>
                  <DialogContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color={colors.red[100]}
                      fontWeight={typography.h1}
                      fontFamily="Lexend Deca"
                    >
                      Are you sure you want to Archive this task?
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ marginRight: "15px" }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleConfirmationClose}
                      sx={{
                        position: "absolute",
                        right: 1.9,
                        top: 1,

                        color: "grey",
                      }}
                    >
                      <CloseSharpIcon />
                    </IconButton>
                    <Button
                      onClick={handleConfirmationOK}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Ok
                      </Typography>
                    </Button>
                  </DialogActions>
                </Dialog>
                {showPopup && (
                  <div className="popup">
                    <div className="popup-content">
                      <p>Task Deleted!</p>
                      <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                  </div>
                )}
              </Stack>
            </div>
          </>
        )}
        {selectedTask === "Harvesting" && (
          <>
            <List>
              <ListItem
                style={{
                  backgroundColor: "#b5cbbf",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h5",

                    borderRadius: "8px",
                  }}
                  primary={
                    <Typography
                      variant="h2"
                      color={colors.white.DEFAULT}
                      fontWeight={typography.h1}
                    >
                      {currentDetail?.optional_field?.activity_name
                        ? currentDetail?.optional_field?.activity_name?.length >
                          15
                          ? `${currentDetail?.optional_field?.activity_name?.slice(
                              0,
                              15
                            )}...`
                          : currentDetail?.optional_field?.activity_name
                        : currentDetail?.mandatory_field?.ledger_name}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem style={{ marginBottom: "15px" }}>
                <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                  <FormControl>
                    <Label style={customStyle} tag>
                      {currentDetail?.mandatory_field?.status}
                    </Label>
                  </FormControl>
                </Stack>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 10%, #f5faf8 10%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.farm_name}
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.employee_name}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.amount}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Transaction Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.transaction_type}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  No. of Labour&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.labor}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.due_time).format(
                    "HH:mm"
                  )}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.end_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>

              {currentDetail?.optional_field?.start_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Start Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.start_location}
                  </Typography>
                </ListItem>
              )}
              {currentDetail?.optional_field?.end_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    End Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.end_location}
                  </Typography>
                </ListItem>
              )}
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 18%, #f5faf8 18%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Zone&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.optional_field?.zone}
                </Typography>
              </ListItem>

              <Divider
                sx={{
                  borderBottom: "2px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{marginLeft:"0.3%"}}
              >
                <Grid item xs={6} sm={6} md={6}>
                  <Typography
                    variant="h4"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                    gutterBottom
                  >
                    Add New Harvesting Inventory!
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2}></Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Button
                    variant="contained"
                    onClick={handlePriceClick}
                    sx={{ backgroundColor: colors.green.DEFAULT }}
                    disabled={currentDetail?.inv_data?.harvestdetails === null}
                  >
                    <Typography variant="h5" color={colors.white.DEFAULT}>
                      Update Old Harvest
                    </Typography>
                  </Button>
                </Grid>
              </Grid>

              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ marginTop: "2px" }}
                >
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="Farm Name"
                      name="farm_name"
                      value={formData?.farm_name}
                      readOnly
                      required
                      fullWidth
                      size="small"
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="Zone Name"
                      name="farm_zone"
                      value={formData?.farm_zone}
                      readOnly
                      required
                      fullWidth
                      size="small"
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="Batch Id"
                      name="batch"
                      value={formData?.batch}
                      readOnly
                      fullWidth
                      size="small"
                      required
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                        max: 256,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="No. of Plants"
                      name="no_of_plants"
                      type="number"
                      value={formData?.no_of_plants}
                      onChange={handleFormChange}
                      fullWidth
                      size="small"
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                        max: MAX_PLANTS,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="Weight(Kg) "
                      name="harvest_weight"
                      type="number"
                      value={formData?.harvest_weight}
                      onChange={handleFormChange}
                      fullWidth
                      size="small"
                      margin="normal"
                      inputProps={{
                        pattern: "^[0-9]+(\\.[0-9]{1,2})?$", // matches 1 or 2 decimal places
                        max: 9999,

                        style: { width: "100%" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="DOH"
                      name="date_of_harvest"
                      type="date"
                      size="small"
                      value={formData?.date_of_harvest}
                      onChange={handleFormChange}
                      fullWidth
                      margin="normal"
                      required
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        style: { width: "100%" },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="Start Location"
                      name="starting_location"
                      value={formData?.starting_location}
                      onChange={handleFormChange}
                      fullWidth
                      margin="normal"
                      size="small"
                      inputProps={{
                        style: { width: "100%" },
                        max: 256,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="End Location"
                      name="ending_location"
                      value={formData?.ending_location}
                      onChange={handleFormChange}
                      fullWidth
                      margin="normal"
                      size="small"
                      inputProps={{
                        style: { width: "100%" },
                        max: 256,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    <TextField
                      label="Note"
                      name="note"
                      value={formData?.note}
                      onChange={handleFormChange}
                      fullWidth
                      size="small"
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                        max: 256,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "1%" }}>
                    <div
                      style={{
                        border: "1px solid #ccc",
                        padding: "2px",
                        borderRadius: "6px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        color={colors.black[200]}
                        style={{
                          width: "100%",
                          marginTop: "1%",
                          marginLeft: "3%",
                        }}
                      >
                        Grades:
                      </Typography>
                      <table
                        style={{
                          width: "100%",
                          marginTop: "2%",
                          marginLeft: "3%",
                        }}
                      >
                        <tbody style={{ marginTop: "-7%" }}>
                          <tr style={{ marginTop: "-35%" }}>
                            {grades?.map((grade, index) => (
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                display="flex"
                                key={index}
                                style={{ marginTop: "1%" }}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  sm={3}
                                  md={3}
                                  style={{ marginTop: "-4%" }}
                                >
                                  <TextField
                                    select
                                    label="Name"
                                    fullWidth
                                    displayEmpty
                                    variant="standard"
                                    value={grade?.name}
                                    onChange={(e) =>
                                      handleGradeChange(
                                        index,
                                        "name",
                                        e.target.value
                                      )
                                    }
                                    SelectProps={{
                                      MenuProps: {
                                        PaperProps: {
                                          style: {
                                            maxHeight: "140px",
                                            overflowY: "auto",
                                          },
                                        },
                                      },
                                    }}
                                    inputProps={{
                                      style: { width: "100%" },
                                      "aria-label": "Select Grades",
                                    }}
                                    startAdornment={
                                      <InputAdornment position="start"></InputAdornment>
                                    }
                                  >
                                    {gradesName?.map((gradesOption, index) => (
                                      <MenuItem
                                        key={index}
                                        value={gradesOption}
                                      >
                                        {gradesOption}
                                      </MenuItem>
                                    ))}
                                    <MenuItem disabled value="">
                                      Select Grades
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sm={3}
                                  md={3}
                                  style={{ marginTop: "-5%" }}
                                >
                                  <TextField
                                    label="No. of Plants"
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    margin="normal"
                                    value={grade?.numberOfPlants}
                                    onChange={(e) =>
                                      handleGradeChange(
                                        index,
                                        "numberOfPlants",
                                        e.target.value
                                      )
                                    }
                                    placeholder="No. of Plants"
                                    inputProps={{
                                      style: { width: "100%" },
                                      min: 0,
                                      max: 100000,
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sm={3}
                                  md={3}
                                  style={{ marginTop: "-5%" }}
                                >
                                  <TextField
                                    label="Weight(Kg)"
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    margin="normal"
                                    value={grade?.weight}
                                    onChange={(e) =>
                                      handleGradeChange(
                                        index,
                                        "weight",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Weight(Kg)"
                                    inputProps={{
                                      style: { width: "100%" },
                                      min: 0,
                                      max: 100000,
                                    }}
                                  />
                                </Grid>
                                {/* <Grid
                                  item
                                  xs={6}
                                  sm={2.5}
                                  md={2.5}
                                  style={{ marginTop: "-5%" }}
                                >
                                  <TextField
                                    label="Amount"
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    margin="normal"
                                    value={grade.price}
                                    onChange={(e) =>
                                      handleGradeChange(
                                        index,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Amount"
                                    inputProps={{
                                      style: { width: "100%" },
                                      min: 0,
                                      max: 100000,
                                    }}
                                  />
                                </Grid> */}
                                <IconButton
                                  style={{ border: "none", marginTop: "12px" }}
                                  onClick={() => handleDeleteGrades(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            ))}
                          </tr>
                          <Divider
                            style={{
                              margin: "1px -1px 4px 7px",
                              width: "5%",
                              color: "colors.black[100]",
                            }}
                          />

                          <div
                            style={{
                              width: "100%",
                              marginTop: "1%",
                              marginLeft: "-1%",
                            }}
                          >
                            <Button
                              startIcon={
                                <AddCircleOutlineOutlinedIcon
                                  style={{
                                    color:
                                      grades?.length === gradesName?.length
                                        ? "grey"
                                        : colors.green.DEFAULT,
                                  }}
                                />
                              }
                              onClick={addNewGrades}
                              disabled={grades?.length === gradesName?.length}
                            >
                              <Typography
                                variant="h6"
                                color={
                                  grades?.length === gradesName?.length
                                    ? "grey"
                                    : colors.green.DEFAULT
                                }
                              >
                                Add more grades
                              </Typography>
                            </Button>
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  display="flex"
                  style={{ marginTop: "1%" }}
                >
                  <Grid item xs={9.5} sm={9.5} md={9.5}></Grid>
                  <Grid item xs={2.5} sm={2.5} md={2.5}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={MAX_PLANTS === 0}
                      sx={{ backgroundColor: colors.green.DEFAULT }}
                    >
                      <Typography
                        variant="h7"
                        disabled={MAX_PLANTS === 0}
                        color={colors.white.DEFAULT}
                      >
                        Add to Harvest
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>

              <EditPriceDialog
                open={openPriceDialog}
                onClose={() => setOpenPriceDialog(false)}
                grades={currentDetail?.inv_data?.harvestdetails}
              />
              <Divider
                sx={{
                  borderBottom: "2px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />

              {extractedText?.length > 0 ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Additional Instructions!
                    </Typography>
                  </ListItem>

                  <List>
                    {extractedText?.map((item, index) => (
                      <>
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: "18px" }}>
                            <RadioButtonUncheckedIcon
                              sx={{ fontSize: "small" }}
                            />
                          </ListItemIcon>
                          {item.heading && (
                            <Typography
                              variant="h5"
                              color={colors.black.DEFAULT}
                              fontFamily="Lexend Deca"
                            >
                              {item.heading}
                            </Typography>
                          )}
                        </ListItem>
                        <List
                          style={{ paddingLeft: "35px", marginTop: "-15px" }}
                        >
                          {item?.points?.map((point, pointIndex) => (
                            <ListItem key={pointIndex}>
                              {pointIndex + 1}.
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                                sx={{ marginLeft: "3px" }}
                              >
                                {point}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    ))}
                  </List>
                </>
              ) : null}

              {currentDetail?.optional_field?.proof ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Task Completed! Proof Attached.
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ height: "64px" }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={1}>
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                          sx={{
                            marginBottom: "6px",
                          }}
                        >
                          {currentDetail?.optional_field?.proof[0].description}
                        </Typography>
                      </Grid>
                      {!showTextField && (
                        <Grid item xs={6} sm={7}>
                          {userDetails?.task_b_addAttachment && (
                            <>
                              <div>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  {!isArchive ? (
                                    <>
                                      <Button
                                        onClick={handleAttachmentOpen}
                                        startIcon={<AttachmentIcon />}
                                        size="small"
                                        disabled={fileName}
                                        sx={{
                                          color: "#2C7B55",
                                          borderColor: "#2C7B55",
                                          marginRight: "5px",
                                        }}
                                        variant="outlined"
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Add
                                        </Typography>
                                      </Button>

                                      <Button
                                        variant="contained"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{
                                          backgroundColor: "#2C7B55", // Default background color
                                        }}
                                        size="small"
                                        disabled={!fileName}
                                        onClick={handleDownloadClick}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Download
                                        </Typography>
                                      </Button>
                                    </>
                                  ) : null}
                                </Stack>
                                <Dialog
                                  open={isAttachmentDialog}
                                  onClose={handleAttachmentClose}
                                >
                                  <DialogTitle
                                    sx={{ position: "sticky", height: "4%" }}
                                  >
                                    Upload Document
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleAttachmentClose}
                                      sx={{
                                        position: "absolute",
                                        right: 1.9,
                                        top: 1,
                                        color: "grey",
                                      }}
                                    >
                                      <CloseSharpIcon />
                                    </IconButton>
                                  </DialogTitle>
                                  <DialogContent>
                                    {selectedFile && (
                                      <img
                                        src={URL.createObjectURL(selectedFile)}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "300px",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                    />
                                  </DialogContent>

                                  <DialogActions
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "32px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#2C7B55", // Default background color
                                      }}
                                      onClick={handleSaveAttachmentClick}
                                    >
                                      <Typography
                                        variant="h6"
                                        color={colors.white.DEFAULT}
                                        fontFamily="Lexend Deca"
                                      >
                                        Save
                                      </Typography>
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </>
              ) : null}
            </List>
            <div style={{ marginTop: "auto" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    color: "#2C7B55",
                    borderColor: "#2C7B55",
                    marginRight: "5px",
                  }}
                  variant="outlined"
                >
                  <Typography
                    variant="h6"
                    color={colors.green.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Close
                  </Typography>
                </Button>
                {userDetails?.task_b_delete && (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<DeleteIcon />}
                      disabled={isArchive}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      onClick={handleTaskDelete}
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Archive
                      </Typography>
                    </Button>
                  </>
                )}

                <Dialog
                  open={showConfirmation}
                  onClose={handleConfirmationClose}
                >
                  <DialogTitle style={{ textAlign: "center" }}>
                    <ErrorOutlineOutlined
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#A45A52",
                      }}
                    />
                  </DialogTitle>
                  <DialogContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color={colors.red[100]}
                      fontWeight={typography.h1}
                      fontFamily="Lexend Deca"
                    >
                      Are you sure you want to Archive this task?
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ marginRight: "15px" }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleConfirmationClose}
                      sx={{
                        position: "absolute",
                        right: 1.9,
                        top: 1,

                        color: "grey",
                      }}
                    >
                      <CloseSharpIcon />
                    </IconButton>
                    <Button
                      onClick={handleConfirmationOK}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Ok
                      </Typography>
                    </Button>
                  </DialogActions>
                </Dialog>
                {showPopup && (
                  <div className="popup">
                    <div className="popup-content">
                      <p>Task Deleted!</p>
                      <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                  </div>
                )}
              </Stack>
            </div>
            <Dialog
              open={newHarvestDialog}
              onClose={() => setNewHarvestDialog(false)}
            >
              <DialogTitle style={{ textAlign: "center" }}>
                <CheckCircleOutlineIcon
                  style={{ width: "60px", height: "60px", color: "#2C7B55" }}
                />
              </DialogTitle>
              <DialogContent style={{ textAlign: "center" }}>
                <Typography
                  variant="h4"
                  color={colors.green.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  New Harvesting Added Successfully
                </Typography>
              </DialogContent>
              <Button onClick={handleAddNewHarvest}>
                <Typography
                  variant="h4"
                  color={colors.green.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  OK
                </Typography>
              </Button>
            </Dialog>
          </>
        )}
        {selectedTask === "Irrigation" && (
          <>
            <List>
              <ListItem
                style={{
                  backgroundColor: "#b5cbbf",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h5",

                    borderRadius: "8px",
                  }}
                  primary={
                    <Typography
                      variant="h2"
                      color={colors.white.DEFAULT}
                      fontWeight={typography.h1}
                    >
                      {currentDetail?.optional_field?.activity_name
                        ? currentDetail?.optional_field?.activity_name?.length >
                          15
                          ? `${currentDetail?.optional_field?.activity_name?.slice(
                              0,
                              15
                            )}...`
                          : currentDetail?.optional_field?.activity_name
                        : currentDetail?.mandatory_field?.ledger_name}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem style={{ marginBottom: "15px" }}>
                <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                  <FormControl>
                    <Label style={customStyle} tag>
                      {currentDetail?.mandatory_field?.status}
                    </Label>
                  </FormControl>
                </Stack>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 10%, #f5faf8 10%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.farm_name}
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.employee_name}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.amount}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Transaction Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.transaction_type}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  No. of Labour&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.labor}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(
                    currentDetail?.mandatory_field?.due_time,
                    "HH:mm:ss"
                  ).format("HH:mm")}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.end_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>

              {currentDetail?.optional_field?.volume && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Volume per unit plant (in
                    ml)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.volume}
                  </Typography>
                </ListItem>
              )}

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 32%, #f5faf8 32%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  pH of solution&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.optional_field?.ph}
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 63%, #f5faf8 63%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Temperature of solution (in
                  °C)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.optional_field?.temperature}
                </Typography>
              </ListItem>

              {currentDetail?.optional_field?.mS_cm ? (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 63%, #f5faf8 63%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    EC/TDS of solution&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.mS_cm}&nbsp;&nbsp;&nbsp;
                    {currentDetail?.optional_field?.tds}
                  </Typography>
                </ListItem>
              ) : null}

              {currentDetail?.optional_field?.ppm ? (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 58%, #f5faf8 58%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    EC/TDS of solution&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.ppm}&nbsp;&nbsp;&nbsp;
                    {currentDetail?.optional_field?.tds}
                  </Typography>
                </ListItem>
              ) : null}

              {currentDetail?.optional_field?.single_use_time ? (
                <ListItem style={{ marginBottom: "15px" }}>
                  <Typography
                    display="block"
                    variant="subtitle1"
                    component="div"
                    sx={{ fontWeight: "bold", color: "#4F4F4F" }}
                  >
                    Irrigation Interval (in
                    mins)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="body1" component="div">
                    {currentDetail?.optional_field?.single_use_time}
                  </Typography>
                </ListItem>
              ) : null}

              {currentDetail?.optional_field?.recirculating_on_time ? (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 61%, #f5faf8 61%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Irrigation Interval ON (in
                    mins)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.recirculating_on_time}
                  </Typography>
                </ListItem>
              ) : null}

              {currentDetail?.optional_field?.recirculating_off_time ? (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 62%, #f5faf8 62%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Irrigation Interval OFF (in
                    mins)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.recirculating_off_time}
                  </Typography>
                </ListItem>
              ) : null}

              <Divider
                sx={{
                  borderBottom: "2px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />

              {extractedText?.length > 0 ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Additional Instructions!
                    </Typography>
                  </ListItem>

                  <List>
                    {extractedText?.map((item, index) => (
                      <>
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: "18px" }}>
                            <RadioButtonUncheckedIcon
                              sx={{ fontSize: "small" }}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="h6"
                            color={colors.black.DEFAULT}
                            fontFamily="Lexend Deca"
                          >
                            {item.heading}
                          </Typography>
                        </ListItem>
                        <List
                          style={{ paddingLeft: "35px", marginTop: "-15px" }}
                        >
                          {item?.points?.map((point, pointIndex) => (
                            <ListItem key={pointIndex}>
                              {pointIndex + 1}.
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                                sx={{ marginLeft: "3px" }}
                              >
                                {point}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    ))}
                  </List>
                </>
              ) : null}

              {currentDetail?.optional_field?.proof ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Task Completed! Proof Attached.
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ height: "64px" }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={1}>
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                          sx={{
                            marginBottom: "6px",
                          }}
                        >
                          {currentDetail?.optional_field?.proof[0].description}
                        </Typography>
                      </Grid>
                      {!showTextField && (
                        <Grid item xs={6} sm={7}>
                          {userDetails?.task_b_addAttachment && (
                            <>
                              <div>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  {!isArchive ? (
                                    <>
                                      <Button
                                        onClick={handleAttachmentOpen}
                                        startIcon={<AttachmentIcon />}
                                        size="small"
                                        disabled={fileName}
                                        sx={{
                                          color: "#2C7B55",
                                          borderColor: "#2C7B55",
                                          marginRight: "5px",
                                        }}
                                        variant="outlined"
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Add
                                        </Typography>
                                      </Button>

                                      <Button
                                        variant="contained"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{
                                          backgroundColor: "#2C7B55", // Default background color
                                        }}
                                        size="small"
                                        disabled={!fileName}
                                        onClick={handleDownloadClick}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Download
                                        </Typography>
                                      </Button>
                                    </>
                                  ) : null}
                                </Stack>
                                <Dialog
                                  open={isAttachmentDialog}
                                  onClose={handleAttachmentClose}
                                >
                                  <DialogTitle
                                    sx={{ position: "sticky", height: "4%" }}
                                  >
                                    Upload Document
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleAttachmentClose}
                                      sx={{
                                        position: "absolute",
                                        right: 1.9,
                                        top: 1,
                                        color: "grey",
                                      }}
                                    >
                                      <CloseSharpIcon />
                                    </IconButton>
                                  </DialogTitle>
                                  <DialogContent>
                                    {selectedFile && (
                                      <img
                                        src={URL.createObjectURL(selectedFile)}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "300px",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                    />
                                  </DialogContent>

                                  <DialogActions
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "32px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#2C7B55", // Default background color
                                      }}
                                      onClick={handleSaveAttachmentClick}
                                    >
                                      <Typography
                                        variant="h6"
                                        color={colors.white.DEFAULT}
                                        fontFamily="Lexend Deca"
                                      >
                                        Save
                                      </Typography>
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </>
              ) : null}
            </List>
            <div style={{ marginTop: "auto" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    color: "#2C7B55",
                    borderColor: "#2C7B55",
                    marginRight: "5px",
                  }}
                  variant="outlined"
                >
                  <Typography
                    variant="h6"
                    color={colors.green.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Close
                  </Typography>
                </Button>
                {userDetails?.task_b_delete && (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<DeleteIcon />}
                      disabled={isArchive}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      onClick={handleTaskDelete}
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Archive
                      </Typography>
                    </Button>
                  </>
                )}

                <Dialog
                  open={showConfirmation}
                  onClose={handleConfirmationClose}
                >
                  <DialogTitle style={{ textAlign: "center" }}>
                    <ErrorOutlineOutlined
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#A45A52",
                      }}
                    />
                  </DialogTitle>
                  <DialogContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color={colors.red[100]}
                      fontWeight={typography.h1}
                      fontFamily="Lexend Deca"
                    >
                      Are you sure you want to Archive this task?
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ marginRight: "15px" }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleConfirmationClose}
                      sx={{
                        position: "absolute",
                        right: 1.9,
                        top: 1,

                        color: "grey",
                      }}
                    >
                      <CloseSharpIcon />
                    </IconButton>
                    <Button
                      onClick={handleConfirmationOK}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Ok
                      </Typography>
                    </Button>
                  </DialogActions>
                </Dialog>
                {showPopup && (
                  <div className="popup">
                    <div className="popup-content">
                      <p>Task Deleted!</p>
                      <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                  </div>
                )}
              </Stack>
            </div>
          </>
        )}
        {selectedTask === "Purchase" && (
          <>
            <List>
              <ListItem
                style={{
                  backgroundColor: "#b5cbbf",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h5",

                    borderRadius: "8px",
                  }}
                  primary={
                    <Typography
                      variant="h2"
                      color={colors.white.DEFAULT}
                      fontWeight={typography.h1}
                    >
                      {currentDetail?.optional_field?.activity_name
                        ? currentDetail?.optional_field?.activity_name?.length >
                          15
                          ? `${currentDetail?.optional_field?.activity_name?.slice(
                              0,
                              15
                            )}...`
                          : currentDetail?.optional_field?.activity_name
                        : currentDetail?.mandatory_field?.ledger_name}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem style={{ marginBottom: "15px" }}>
                <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                  <FormControl>
                    <Label style={customStyle} tag>
                      {currentDetail?.mandatory_field?.status}
                    </Label>
                  </FormControl>
                </Stack>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 10%, #f5faf8 10%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.farm_name}
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.employee_name}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.amount}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Transaction Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.transaction_type}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  No. of Labour&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.labor}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.due_time).format(
                    "HH:mm"
                  )}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.end_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>
              {currentDetail?.optional_field?.quantity && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Quantity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.quantity}
                  </Typography>
                </ListItem>
              )}
              {currentDetail?.optional_field?.vendor_name && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Vendor Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.vendor_name}
                  </Typography>
                </ListItem>
              )}
              {currentDetail?.optional_field?.date_of_purchase && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Date of purchase&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {moment(
                      currentDetail?.optional_field?.date_of_purchase
                    ).format("DD/MM/YY")}
                  </Typography>
                </ListItem>
              )}

              <Divider
                sx={{
                  borderBottom: "1px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />

              {extractedText?.length > 0 ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Additional Instructions!
                    </Typography>
                  </ListItem>

                  <List>
                    {extractedText?.map((item, index) => (
                      <>
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: "18px" }}>
                            <RadioButtonUncheckedIcon
                              sx={{ fontSize: "small" }}
                            />
                          </ListItemIcon>
                          {item.heading && (
                            <Typography
                              variant="h5"
                              color={colors.black.DEFAULT}
                              fontFamily="Lexend Deca"
                            >
                              {item.heading}
                            </Typography>
                          )}
                        </ListItem>
                        <List
                          style={{ paddingLeft: "35px", marginTop: "-15px" }}
                        >
                          {item?.points?.map((point, pointIndex) => (
                            <ListItem key={pointIndex}>
                              {pointIndex + 1}.
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                                sx={{ marginLeft: "3px" }}
                              >
                                {point}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    ))}
                  </List>
                </>
              ) : null}

              {currentDetail?.optional_field?.proof ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Task Completed! Proof Attached.
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ height: "64px" }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={1}>
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                          sx={{
                            marginBottom: "6px",
                          }}
                        >
                          {currentDetail?.optional_field?.proof[0].description}
                        </Typography>
                      </Grid>
                      {!showTextField && (
                        <Grid item xs={6} sm={7}>
                          {userDetails?.task_b_addAttachment && (
                            <>
                              <div>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  {!isArchive ? (
                                    <>
                                      <Button
                                        onClick={handleAttachmentOpen}
                                        startIcon={<AttachmentIcon />}
                                        size="small"
                                        disabled={fileName}
                                        sx={{
                                          color: "#2C7B55",
                                          borderColor: "#2C7B55",
                                          marginRight: "5px",
                                        }}
                                        variant="outlined"
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Add
                                        </Typography>
                                      </Button>

                                      <Button
                                        variant="contained"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{
                                          backgroundColor: "#2C7B55", // Default background color
                                        }}
                                        size="small"
                                        disabled={!fileName}
                                        onClick={handleDownloadClick}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Download
                                        </Typography>
                                      </Button>
                                    </>
                                  ) : null}
                                </Stack>
                                <Dialog
                                  open={isAttachmentDialog}
                                  onClose={handleAttachmentClose}
                                >
                                  <DialogTitle
                                    sx={{ position: "sticky", height: "4%" }}
                                  >
                                    Upload Document
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleAttachmentClose}
                                      sx={{
                                        position: "absolute",
                                        right: 1.9,
                                        top: 1,
                                        color: "grey",
                                      }}
                                    >
                                      <CloseSharpIcon />
                                    </IconButton>
                                  </DialogTitle>
                                  <DialogContent>
                                    {selectedFile && (
                                      <img
                                        src={URL.createObjectURL(selectedFile)}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "300px",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                    />
                                  </DialogContent>

                                  <DialogActions
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "32px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#2C7B55", // Default background color
                                      }}
                                      onClick={handleSaveAttachmentClick}
                                    >
                                      <Typography
                                        variant="h6"
                                        color={colors.white.DEFAULT}
                                        fontFamily="Lexend Deca"
                                      >
                                        Save
                                      </Typography>
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </>
              ) : null}
            </List>

            <div style={{ marginTop: "auto" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    color: "#2C7B55",
                    borderColor: "#2C7B55",
                    marginRight: "5px",
                  }}
                  variant="outlined"
                >
                  <Typography
                    variant="h6"
                    color={colors.green.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Close
                  </Typography>
                </Button>
                {userDetails?.task_b_delete && (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<DeleteIcon />}
                      disabled={isArchive}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      onClick={handleTaskDelete}
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Archive
                      </Typography>
                    </Button>
                  </>
                )}

                <Dialog
                  open={showConfirmation}
                  onClose={handleConfirmationClose}
                >
                  <DialogTitle style={{ textAlign: "center" }}>
                    <ErrorOutlineOutlined
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#A45A52",
                      }}
                    />
                  </DialogTitle>
                  <DialogContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color={colors.red[100]}
                      fontWeight={typography.h1}
                      fontFamily="Lexend Deca"
                    >
                      Are you sure you want to Archive this task?
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ marginRight: "15px" }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleConfirmationClose}
                      sx={{
                        position: "absolute",
                        right: 1.9,
                        top: 1,

                        color: "grey",
                      }}
                    >
                      <CloseSharpIcon />
                    </IconButton>
                    <Button
                      onClick={handleConfirmationOK}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Ok
                      </Typography>
                    </Button>
                  </DialogActions>
                </Dialog>
                {showPopup && (
                  <div className="popup">
                    <div className="popup-content">
                      <p>Task Deleted!</p>
                      <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                  </div>
                )}
              </Stack>
            </div>
          </>
        )}
        {selectedTask === "Transport" && (
          <>
            <List>
              <ListItem
                style={{
                  backgroundColor: "#b5cbbf",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h5",

                    borderRadius: "8px",
                  }}
                  primary={
                    <Typography
                      variant="h2"
                      color={colors.white.DEFAULT}
                      fontWeight={typography.h1}
                    >
                      {currentDetail?.optional_field?.activity_name
                        ? currentDetail?.optional_field?.activity_name?.length >
                          15
                          ? `${currentDetail?.optional_field?.activity_name?.slice(
                              0,
                              15
                            )}...`
                          : currentDetail?.optional_field?.activity_name
                        : currentDetail?.mandatory_field?.ledger_name}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem style={{ marginBottom: "15px" }}>
                <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                  <FormControl>
                    <Label style={customStyle} tag>
                      {currentDetail?.mandatory_field?.status}
                    </Label>
                  </FormControl>
                </Stack>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 10%, #f5faf8 10%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.farm_name}
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.employee_name}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.amount}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Transaction Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.transaction_type}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  No. of Labour&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.labor}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.due_time).format(
                    "HH:mm"
                  )}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.end_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>
              {currentDetail?.optional_field?.vehicle_number && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Vehicle Number&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.vehicle_number}
                  </Typography>
                </ListItem>
              )}
              {currentDetail?.optional_field?.date_of_travel && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Date of travel&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {moment(
                      currentDetail?.mandatory_field?.date_of_travel
                    ).format("DD/MM/YY")}
                  </Typography>
                </ListItem>
              )}
              {currentDetail?.optional_field?.start_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Start Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.start_location}
                  </Typography>
                </ListItem>
              )}
              {currentDetail?.optional_field?.end_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    End Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.end_location}
                  </Typography>
                </ListItem>
              )}

              <Divider
                sx={{
                  borderBottom: "1px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />

              {extractedText?.length > 0 ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Additional Instructions!
                    </Typography>
                  </ListItem>

                  <List>
                    {extractedText?.map((item, index) => (
                      <>
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: "18px" }}>
                            <RadioButtonUncheckedIcon
                              sx={{ fontSize: "small" }}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="h6"
                            color={colors.black.DEFAULT}
                            fontFamily="Lexend Deca"
                          >
                            {item.heading}
                          </Typography>
                        </ListItem>
                        <List
                          style={{ paddingLeft: "35px", marginTop: "-15px" }}
                        >
                          {item?.points?.map((point, pointIndex) => (
                            <ListItem key={pointIndex}>
                              {pointIndex + 1}.
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                                sx={{ marginLeft: "3px" }}
                              >
                                {point}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    ))}
                  </List>
                </>
              ) : null}

              {currentDetail?.optional_field?.proof ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Task Completed! Proof Attached.
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ height: "64px" }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={1}>
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                          sx={{
                            marginBottom: "6px",
                          }}
                        >
                          {currentDetail?.optional_field?.proof[0].description}
                        </Typography>
                      </Grid>
                      {!showTextField && (
                        <Grid item xs={6} sm={7}>
                          {userDetails?.task_b_addAttachment && (
                            <>
                              <div>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  {!isArchive ? (
                                    <>
                                      <Button
                                        onClick={handleAttachmentOpen}
                                        startIcon={<AttachmentIcon />}
                                        size="small"
                                        disabled={fileName}
                                        sx={{
                                          color: "#2C7B55",
                                          borderColor: "#2C7B55",
                                          marginRight: "5px",
                                        }}
                                        variant="outlined"
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Add
                                        </Typography>
                                      </Button>

                                      <Button
                                        variant="contained"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{
                                          backgroundColor: "#2C7B55", // Default background color
                                        }}
                                        size="small"
                                        disabled={!fileName}
                                        onClick={handleDownloadClick}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Download
                                        </Typography>
                                      </Button>
                                    </>
                                  ) : null}
                                </Stack>
                                <Dialog
                                  open={isAttachmentDialog}
                                  onClose={handleAttachmentClose}
                                >
                                  <DialogTitle
                                    sx={{ position: "sticky", height: "4%" }}
                                  >
                                    Upload Document
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleAttachmentClose}
                                      sx={{
                                        position: "absolute",
                                        right: 1.9,
                                        top: 1,
                                        color: "grey",
                                      }}
                                    >
                                      <CloseSharpIcon />
                                    </IconButton>
                                  </DialogTitle>
                                  <DialogContent>
                                    {selectedFile && (
                                      <img
                                        src={URL.createObjectURL(selectedFile)}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "300px",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                    />
                                  </DialogContent>

                                  <DialogActions
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "32px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#2C7B55", // Default background color
                                      }}
                                      onClick={handleSaveAttachmentClick}
                                    >
                                      <Typography
                                        variant="h6"
                                        color={colors.white.DEFAULT}
                                        fontFamily="Lexend Deca"
                                      >
                                        Save
                                      </Typography>
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </>
              ) : null}
            </List>

            <div style={{ marginTop: "auto" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    color: "#2C7B55",
                    borderColor: "#2C7B55",
                    marginRight: "5px",
                  }}
                  variant="outlined"
                >
                  <Typography
                    variant="h6"
                    color={colors.green.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Close
                  </Typography>
                </Button>
                {userDetails?.task_b_delete && (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<DeleteIcon />}
                      disabled={isArchive}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      onClick={handleTaskDelete}
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Archive
                      </Typography>
                    </Button>
                  </>
                )}

                <Dialog
                  open={showConfirmation}
                  onClose={handleConfirmationClose}
                >
                  <DialogTitle style={{ textAlign: "center" }}>
                    <ErrorOutlineOutlined
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#A45A52",
                      }}
                    />
                  </DialogTitle>
                  <DialogContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color={colors.red[100]}
                      fontWeight={typography.h1}
                      fontFamily="Lexend Deca"
                    >
                      Are you sure you want to Archive this task?
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ marginRight: "15px" }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleConfirmationClose}
                      sx={{
                        position: "absolute",
                        right: 1.9,
                        top: 1,

                        color: "grey",
                      }}
                    >
                      <CloseSharpIcon />
                    </IconButton>
                    <Button
                      onClick={handleConfirmationOK}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Ok
                      </Typography>
                    </Button>
                  </DialogActions>
                </Dialog>
                {showPopup && (
                  <div className="popup">
                    <div className="popup-content">
                      <p>Task Deleted!</p>
                      <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                  </div>
                )}
              </Stack>
            </div>
          </>
        )}
        {selectedTask === "Electricity" && (
          <>
            <List>
              <ListItem
                style={{
                  backgroundColor: "#b5cbbf",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h5",

                    borderRadius: "8px",
                  }}
                  primary={
                    <Typography
                      variant="h2"
                      color={colors.white.DEFAULT}
                      fontWeight={typography.h1}
                    >
                      {currentDetail?.optional_field?.activity_name
                        ? currentDetail?.optional_field?.activity_name?.length >
                          15
                          ? `${currentDetail?.optional_field?.activity_name?.slice(
                              0,
                              15
                            )}...`
                          : currentDetail?.optional_field?.activity_name
                        : currentDetail?.mandatory_field?.ledger_name}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem style={{ marginBottom: "15px" }}>
                <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                  <FormControl>
                    <Label style={customStyle} tag>
                      {currentDetail?.mandatory_field?.status}
                    </Label>
                  </FormControl>
                </Stack>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 10%, #f5faf8 10%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.farm_name}
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.employee_name}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Meter Number&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.optional_field?.meter_number}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Paid Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.optional_field?.paid_amount}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Due Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.optional_field?.due_amount}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Transaction Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.transaction_type}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Payment Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.optional_field?.payment_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Payment Method&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.optional_field?.payment_method}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Billing Period&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.optional_field?.start_date).format(
                    "DD/MM/YY"
                  )}
                  &nbsp;&nbsp; to &nbsp;&nbsp;{" "}
                  {moment(currentDetail?.optional_field?.end_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  No. of Labour&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.labor}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.due_time).format(
                    "HH:mm"
                  )}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.end_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>
              {currentDetail?.optional_field?.start_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Start Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.start_location}
                  </Typography>
                </ListItem>
              )}

              <Divider
                sx={{
                  borderBottom: "1px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />

              {extractedText?.length > 0 ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Additional Instructions!
                    </Typography>
                  </ListItem>

                  <List>
                    {extractedText?.map((item, index) => (
                      <>
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: "18px" }}>
                            <RadioButtonUncheckedIcon
                              sx={{ fontSize: "small" }}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="h6"
                            color={colors.black.DEFAULT}
                            fontFamily="Lexend Deca"
                          >
                            {item.heading}
                          </Typography>
                        </ListItem>
                        <List
                          style={{ paddingLeft: "35px", marginTop: "-15px" }}
                        >
                          {item?.points?.map((point, pointIndex) => (
                            <ListItem key={pointIndex}>
                              {pointIndex + 1}.
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                                sx={{ marginLeft: "3px" }}
                              >
                                {point}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    ))}
                  </List>
                </>
              ) : null}

              {currentDetail?.optional_field?.proof ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Task Completed! Proof Attached.
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ height: "64px" }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={1}>
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                          sx={{
                            marginBottom: "6px",
                          }}
                        >
                          {currentDetail?.optional_field?.proof[0].description}
                        </Typography>
                      </Grid>
                      {!showTextField && (
                        <Grid item xs={6} sm={7}>
                          {userDetails?.task_b_addAttachment && (
                            <>
                              <div>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  {!isArchive ? (
                                    <>
                                      <Button
                                        onClick={handleAttachmentOpen}
                                        startIcon={<AttachmentIcon />}
                                        size="small"
                                        disabled={fileName}
                                        sx={{
                                          color: "#2C7B55",
                                          borderColor: "#2C7B55",
                                          marginRight: "5px",
                                        }}
                                        variant="outlined"
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Add
                                        </Typography>
                                      </Button>

                                      <Button
                                        variant="contained"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{
                                          backgroundColor: "#2C7B55", // Default background color
                                        }}
                                        size="small"
                                        disabled={!fileName}
                                        onClick={handleDownloadClick}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Download
                                        </Typography>
                                      </Button>
                                    </>
                                  ) : null}
                                </Stack>
                                <Dialog
                                  open={isAttachmentDialog}
                                  onClose={handleAttachmentClose}
                                >
                                  <DialogTitle
                                    sx={{ position: "sticky", height: "4%" }}
                                  >
                                    Upload Document
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleAttachmentClose}
                                      sx={{
                                        position: "absolute",
                                        right: 1.9,
                                        top: 1,
                                        color: "grey",
                                      }}
                                    >
                                      <CloseSharpIcon />
                                    </IconButton>
                                  </DialogTitle>
                                  <DialogContent>
                                    {selectedFile && (
                                      <img
                                        src={URL.createObjectURL(selectedFile)}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "300px",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                    />
                                  </DialogContent>

                                  <DialogActions
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "32px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#2C7B55", // Default background color
                                      }}
                                      onClick={handleSaveAttachmentClick}
                                    >
                                      <Typography
                                        variant="h6"
                                        color={colors.white.DEFAULT}
                                        fontFamily="Lexend Deca"
                                      >
                                        Save
                                      </Typography>
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </>
              ) : null}
            </List>

            <div style={{ marginTop: "auto" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    color: "#2C7B55",
                    borderColor: "#2C7B55",
                    marginRight: "5px",
                  }}
                  variant="outlined"
                >
                  <Typography
                    variant="h6"
                    color={colors.green.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Close
                  </Typography>
                </Button>
                {userDetails?.task_b_delete && (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<DeleteIcon />}
                      disabled={isArchive}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      onClick={handleTaskDelete}
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Archive
                      </Typography>
                    </Button>
                  </>
                )}

                <Dialog
                  open={showConfirmation}
                  onClose={handleConfirmationClose}
                >
                  <DialogTitle style={{ textAlign: "center" }}>
                    <ErrorOutlineOutlined
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#A45A52",
                      }}
                    />
                  </DialogTitle>
                  <DialogContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color={colors.red[100]}
                      fontWeight={typography.h1}
                      fontFamily="Lexend Deca"
                    >
                      Are you sure you want to Archive this task?
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ marginRight: "15px" }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleConfirmationClose}
                      sx={{
                        position: "absolute",
                        right: 1.9,
                        top: 1,

                        color: "grey",
                      }}
                    >
                      <CloseSharpIcon />
                    </IconButton>
                    <Button
                      onClick={handleConfirmationOK}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Ok
                      </Typography>
                    </Button>
                  </DialogActions>
                </Dialog>
                {showPopup && (
                  <div className="popup">
                    <div className="popup-content">
                      <p>Task Deleted!</p>
                      <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                  </div>
                )}
              </Stack>
            </div>
          </>
        )}
        {selectedTask === "Miscellaneous" && (
          <>
            <List>
              <ListItem
                style={{
                  backgroundColor: "#b5cbbf",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h5",

                    borderRadius: "8px",
                  }}
                  primary={
                    <Typography
                      variant="h2"
                      color={colors.white.DEFAULT}
                      fontWeight={typography.h1}
                    >
                      {currentDetail?.optional_field?.activity_name
                        ? currentDetail?.optional_field?.activity_name?.length >
                          15
                          ? `${currentDetail?.optional_field?.activity_name?.slice(
                              0,
                              15
                            )}...`
                          : currentDetail?.optional_field?.activity_name
                        : currentDetail?.mandatory_field?.ledger_name}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem style={{ marginBottom: "15px" }}>
                <Stack direction="row" spacing={1} sx={{ marginLeft: "-15px" }}>
                  <FormControl>
                    <Label style={customStyle} tag>
                      {currentDetail?.mandatory_field?.status}
                    </Label>
                  </FormControl>
                </Stack>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 10%, #f5faf8 10%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Farm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.farm_name}
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 17%, #f5faf8 17%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Assigned To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.employee_name}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 13%, #f5faf8 13%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.amount}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 22%, #f5faf8 22%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Transaction Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.transaction_type}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  No. of Labour&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {currentDetail?.mandatory_field?.labor}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Due Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.due_time).format(
                    "HH:mm"
                  )}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "5px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 15%, #f5faf8 15%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {moment(currentDetail?.mandatory_field?.end_date).format(
                    "DD/MM/YY"
                  )}
                </Typography>
              </ListItem>

              {currentDetail?.optional_field?.start_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Start Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.start_location}
                  </Typography>
                </ListItem>
              )}
              {currentDetail?.optional_field?.end_location && (
                <ListItem
                  style={{
                    marginBottom: "5px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    End Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {currentDetail?.optional_field?.end_location}
                  </Typography>
                </ListItem>
              )}

              <Divider
                sx={{
                  borderBottom: "1px solid #000000", // Adjust the border color as needed
                  margin: "16px 0", // Adjust the margin as needed
                }}
                variant="middle"
              />

              {currentDetail?.optional_field?.tasks_included?.length > 0 ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Tasks Included!
                    </Typography>
                  </ListItem>

                  <List
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {currentDetail?.optional_field?.tasks_included?.map(
                      (item, index) => (
                        <>
                          <ListItem
                            style={{
                              marginBottom: "5px",
                              background: "#ecf4f0",
                              borderRadius: "8px",
                              width: "30%",
                              flex: "0 0 auto",
                            }}
                            key={index}
                          >
                            {/* <Checkbox
                              checked={item.task_status === 1}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                const updatedTasks = [
                                  ...currentDetail?.optional_field
                                    ?.tasks_included,
                                ];
                                updatedTasks[index].task_status = isChecked
                                  ? 1
                                  : 0;
                              }}
                            /> */}
                            <Typography
                              variant="h6"
                              color={colors.black.DEFAULT}
                              fontFamily="Lexend Deca"
                              style={{ textAlign: "center" }}
                            >
                              {item.task_name}
                            </Typography>
                          </ListItem>
                        </>
                      )
                    )}
                  </List>
                </>
              ) : null}
              {extractedText?.length > 0 ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Additional Instructions!
                    </Typography>
                  </ListItem>

                  <List>
                    {extractedText?.map((item, index) => (
                      <>
                        <ListItem key={index}>
                          <ListItemIcon sx={{ minWidth: "18px" }}>
                            <RadioButtonUncheckedIcon
                              sx={{ fontSize: "small" }}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="h6"
                            color={colors.black.DEFAULT}
                            fontFamily="Lexend Deca"
                          >
                            {item.heading}
                          </Typography>
                        </ListItem>
                        <List
                          style={{ paddingLeft: "35px", marginTop: "-15px" }}
                        >
                          {item?.points?.map((point, pointIndex) => (
                            <ListItem key={pointIndex}>
                              {pointIndex + 1}.
                              <Typography
                                variant="h6"
                                color={colors.black.DEFAULT}
                                fontFamily="Lexend Deca"
                                sx={{ marginLeft: "3px" }}
                              >
                                {point}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    ))}
                  </List>
                </>
              ) : null}

              {currentDetail?.optional_field?.proof ? (
                <>
                  <ListItem style={{ marginTop: "15px" }}>
                    <Typography
                      variant="h4"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      gutterBottom
                    >
                      Task Completed! Proof Attached.
                    </Typography>
                  </ListItem>

                  <ListItem sx={{ height: "64px" }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={1}>
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: "small" }}
                          />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography
                          variant="h6"
                          color={colors.black.DEFAULT}
                          fontFamily="Lexend Deca"
                          sx={{
                            marginBottom: "6px",
                          }}
                        >
                          {currentDetail?.optional_field?.proof[0].description}
                        </Typography>
                      </Grid>
                      {!showTextField && (
                        <Grid item xs={6} sm={7}>
                          {userDetails?.task_b_addAttachment && (
                            <>
                              <div>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                >
                                  {!isArchive ? (
                                    <>
                                      <Button
                                        onClick={handleAttachmentOpen}
                                        startIcon={<AttachmentIcon />}
                                        size="small"
                                        disabled={fileName}
                                        sx={{
                                          color: "#2C7B55",
                                          borderColor: "#2C7B55",
                                          marginRight: "5px",
                                        }}
                                        variant="outlined"
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Add
                                        </Typography>
                                      </Button>

                                      <Button
                                        variant="contained"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{
                                          backgroundColor: "#2C7B55", // Default background color
                                        }}
                                        size="small"
                                        disabled={!fileName}
                                        onClick={handleDownloadClick}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontFamily="Lexend Deca"
                                        >
                                          Download
                                        </Typography>
                                      </Button>
                                    </>
                                  ) : null}
                                </Stack>
                                <Dialog
                                  open={isAttachmentDialog}
                                  onClose={handleAttachmentClose}
                                >
                                  <DialogTitle
                                    sx={{ position: "sticky", height: "4%" }}
                                  >
                                    Upload Document
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleAttachmentClose}
                                      sx={{
                                        position: "absolute",
                                        right: 1.9,
                                        top: 1,
                                        color: "grey",
                                      }}
                                    >
                                      <CloseSharpIcon />
                                    </IconButton>
                                  </DialogTitle>
                                  <DialogContent>
                                    {selectedFile && (
                                      <img
                                        src={URL.createObjectURL(selectedFile)}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "300px",
                                          marginBottom: "20px",
                                        }}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept=".jpg, .jpeg, .png, .docx, .pdf" // Specify accepted file types here
                                    />
                                  </DialogContent>

                                  <DialogActions
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "32px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#2C7B55", // Default background color
                                      }}
                                      onClick={handleSaveAttachmentClick}
                                    >
                                      <Typography
                                        variant="h6"
                                        color={colors.white.DEFAULT}
                                        fontFamily="Lexend Deca"
                                      >
                                        Save
                                      </Typography>
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </>
              ) : null}
            </List>

            <div style={{ marginTop: "auto" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    color: "#2C7B55",
                    borderColor: "#2C7B55",
                    marginRight: "5px",
                  }}
                  variant="outlined"
                >
                  <Typography
                    variant="h6"
                    color={colors.green.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Close
                  </Typography>
                </Button>
                {userDetails?.task_b_delete && (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<DeleteIcon />}
                      disabled={isArchive}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      onClick={handleTaskDelete}
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Archive
                      </Typography>
                    </Button>
                  </>
                )}

                <Dialog
                  open={showConfirmation}
                  onClose={handleConfirmationClose}
                >
                  <DialogTitle style={{ textAlign: "center" }}>
                    <ErrorOutlineOutlined
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#A45A52",
                      }}
                    />
                  </DialogTitle>
                  <DialogContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color={colors.red[100]}
                      fontWeight={typography.h1}
                      fontFamily="Lexend Deca"
                    >
                      Are you sure you want to Archive this task?
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ marginRight: "15px" }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleConfirmationClose}
                      sx={{
                        position: "absolute",
                        right: 1.9,
                        top: 1,

                        color: "grey",
                      }}
                    >
                      <CloseSharpIcon />
                    </IconButton>
                    <Button
                      onClick={handleConfirmationOK}
                      sx={{
                        backgroundColor: "#2C7B55", // Default background color
                        "&:hover": {
                          backgroundColor: "#A45A52", // Background color on hover
                        },
                      }}
                      variant="contained"
                    >
                      <Typography
                        variant="h6"
                        color={colors.white.DEFAULT}
                        fontFamily="Lexend Deca"
                      >
                        Ok
                      </Typography>
                    </Button>
                  </DialogActions>
                </Dialog>
                {showPopup && (
                  <div className="popup">
                    <div className="popup-content">
                      <p>Task Deleted!</p>
                      <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                  </div>
                )}
              </Stack>
            </div>
          </>
        )}
      </StyledDrawer>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)}
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)}
      />
    </>
  );
}

export default SideDrawer;
