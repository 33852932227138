import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";

import SaveAlert from "../../../SaveAlert";
import ApiErrorAlert from "../../../ApiErrorAlert";
import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const CropStage = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [selectedFarm, setSelectedFarm] = useState("");
  const [selectedCrop, setSelectedCrop] = useState("");
  const [selectedTab, setSelectedTab] = useState("today");
  const [farmOptions, setFarmOptions] = useState([]);
  const [cropOptions, setCropOptions] = useState([]);
  //   const [cropData, setCropData] = useState({});
  const [farmData, setFarmData] = useState([]);
  const [cropData, setCropData] = useState([]);
  const [totalHarvestingCrops, setTotalHarvestingCrops] = useState(0);
  const [clientFarmDetails, setClientFarmDetails] = useState([]);
  const [cropsInHvtPhase, setCropsInHvtPhase] = useState();

  useEffect(() => {
    fetchFarmData();
  }, []);

  useEffect(() => {
    if (farmOptions?.length > 0) {
      setSelectedFarm(farmOptions[0]);
    }
    console.log(farmOptions);
    console.log(selectedFarm);
  }, [farmOptions]);

  useEffect(() => {
    console.log(cropOptions);
    if (cropOptions?.length > 0) {
      setSelectedCrop(cropOptions[0]);
    }
  }, [cropOptions]);

  const fetchFarmData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      setClientFarmDetails(data?.clientFarmInvStat);
      setFarmData(data?.farmdetail?.rows[0]?.farm_name);
      setFarmOptions(
        data?.farmdetail?.rows?.map((row) => row?.farm_name)?.flat()
      );
      setCropData(data?.phasedetail?.rows);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching farm data:", error);
    }
    console.log(farmData);
  };

  console.log("clientFarmDetails-------------", clientFarmDetails);

  const fetchCropData = async () => {
    try {
      const cropData1 = cropData?.find(
        (row) => row?.farm_name === selectedFarm
      );
      if (cropData1) {
        setCropOptions(cropData1?.farm_data?.map((crop) => crop?.crop_name));
      } else {
        setCropOptions([]);
        setSelectedCrop("");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching crop data:", error);
    }
  };

  useEffect(() => {
    if (selectedFarm) {
      fetchCropData();
      const selectedObject = clientFarmDetails?.find(
        (row) => row?.farm_name === selectedFarm
      );
      let count = selectedObject?.count_in_harvest_phase;
      if (count == null) {
        count = 0;
      }
      setCropsInHvtPhase(count);
    }
  }, [selectedFarm]);

  const handleFarmChange = (event) => {
    const selectedFarm = event.target.value;
    setSelectedFarm(selectedFarm);
    setSelectedCrop("");
    const totalHarvestingCrops = getTotalHarvestingCrops(selectedFarm);
    setTotalHarvestingCrops(totalHarvestingCrops);
  };

  const handleCropChange = (event) => {
    setSelectedCrop(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getStageData = (farm, crop) => {
    const farmCropData = cropData?.find((row) => row?.farm_name === farm);
    if (farmCropData) {
      const cropData = farmCropData?.farm_data?.find(
        (data) => data?.crop_name === crop
      );
      if (cropData) {
        return cropData?.stages || [];
      }
    }
    return;
  };

  const getTotalHarvestingCrops = (farm) => {
    const farmCropData = cropData?.find((row) => row?.farm_name === farm);
    let totalHarvestingCrops = 0;
    if (farmCropData) {
      farmCropData?.farm_data?.forEach((crop) => {
        crop?.stages?.forEach((stage) => {
          if (stage?.phase === "harvesting") {
            totalHarvestingCrops += stage.value;
          }
        });
      });
    }
    return totalHarvestingCrops;
  };

  // const colors = ['#b2cf89', '#95c268', '#83b455', '#6d9736', '#466a39', '#32523d', '#FF69B4'];
  const colorMap = {
    "Spawn Run": "#95c268",
    "Case Run": "#83b455",
    Germination: "#22c55e",
    Vegetative: "#16a34a",
    Flowering: "#15803d",
    Ruffling: "#6d9736",
    Spawning: "#b2cf89",
    Pinning: "#466a39",
    Harvesting: "#32523d",
    Nursery: "#065f46",
    Fruiting: "#10b981",
  };
  const selectedFarmData = getStageData(selectedFarm, selectedCrop) || [];
  const chartOptions = {
    xaxis: {
      categories: selectedFarmData?.map((data) => data?.phase || ""),

      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Plants",
        style: {
          fontSize: "16px",
          fontWeight: 400,
          color: "#333",
        },
      },
    },
    tooltip: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        distributed: true,
        barWidth: "20%",
        dataLabels: {
          position: "top",
        },
      },
      dataLabels: {
        position: "top",
        style: {
          fontSize: "12px",
          fontWeight: 200,
          colors: ["#333"],
        },
      },
    },
    // colors: colors.slice(0, getStageData(selectedFarm, selectedCrop).stages.length),
    stroke: {
      curve: "smooth",
      width: 0.5,
    },
    colors: getStageData(selectedFarm, selectedCrop || [])?.map(
      (data) => colorMap[data.phase] || ""
    ),
  };

  const chartSeries = [
    {
      name: `${selectedCrop} Stages`,
      data: selectedFarmData.map((data) => data?.value || 0),
    },
  ];

  console.log("FARMMM OPTIONSSSSSSSS Crop stagee", farmOptions);
  console.log("Chart seriessssss", chartSeries);
  console.log("Charttt optionsss", chartOptions);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",

          marginLeft: "40px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",

            marginLeft: "6px",
            marginTop: "-1%",
          }}
        >
          <div>
            <Typography
              variant="h1"
              color={colors.black.DEFAULT}
              fontFamily="Lexend Deca"
            >
              {/* {selectedTab === "today" ? totalHarvestingCrops : yearlyAverage} */}
              {cropsInHvtPhase}
            </Typography>

            <Typography
              variant="h6"
              color={colors.black[200]}
              fontFamily="Lexend Deca"
              sx={{ marginTop: "4px" }}
            >
              Total Crops in Harvesting Phase
            </Typography>

            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="#ffffff"
              textColor="#ffffff"
              sx={{ marginTop: "7px" }}
            >
              <Tab
                label="Today"
                value="today"
                style={{
                  backgroundColor:
                    selectedTab === "Today" ? "#2C7B55" : "#2C7B55",
                  color: selectedTab === "Today" ? "#ffffff" : "#ffffff",
                  // border: "1px solid #adadad",
                }}
              />
            </Tabs>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "35px",
              marginTop: "-17%",
            }}
          >
            <FormControl>
              <InputLabel>Select Farm</InputLabel>
              <Select
                id="Select Farm"
                label="Select Farm"
                labelId="Select Farm"
                name="Select Farm"
                size="small"
                value={selectedFarm}
                onChange={handleFarmChange}
                style={{ width: "120px" }}
                displayEmpty
                inputProps={{ "aria-label": "Select Farm" }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                {farmOptions &&
                  farmOptions?.map((farm) => (
                    <MenuItem key={farm} value={farm}>
                      {farm}
                    </MenuItem>
                  ))}
                <MenuItem disabled value="">
                  Select Farm
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl style={{ marginLeft: "7%" }}>
              <InputLabel id="SelectCrop" style={{ marginTop: "-1.7%" }}>
                Select Crop
              </InputLabel>
              <Select
                id="Select Crop"
                value={selectedCrop}
                label="Select Crop"
                labelId="Select Crop"
                size="small"
                name="Select Crop"
                displayEmpty
                inputProps={{ "aria-label": "Select Farm" }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
                onChange={handleCropChange}
                style={{ width: "120px" }}
              >
                {cropOptions &&
                  cropOptions?.map((crop) => (
                    <MenuItem key={crop} value={crop}>
                      {crop}
                    </MenuItem>
                  ))}
                <MenuItem disabled value="">
                  Select Crop
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div style={{ marginRight: "2%" }}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={200}
          // width={1150}
        />
      </div>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};

export default CropStage;
