import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BorderColor, ErrorOutlineOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import {
  MenuItem,
  Typography,
  Grid,
  Switch,
  TextField,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import { tokens } from "../../theme";

import axios from "axios";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";

import ApiErrorAlert from "../../ApiErrorAlert";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const UserRoleDataGrid = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);

  useEffect(() => {
    fetchAllUserData();
    fetchUserRoles();
  }, []);

  const [rows, setRows] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [currentRow, setCurrentRow] = useState([]);
  const [updatedValues, setUpdatedValues] = useState({});
  const [displaySwitchValues, setDisplaySwitchValues] = useState(currentRow);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isNewUserDialogOpen, setIsNewUserDialogOpen] = useState(false);
  const [cancelChanges, setCancelChanges] = useState(false);
  const [confirmNewUserAddDialog, setConfirmNewUserAddDialog] = useState(false);
  const [confirmSaveClick, setConfirmSaveClick] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  useEffect(() => {
    setDisplaySwitchValues(currentRow);
  }, [currentRow]);

  useEffect(() => {
    setDisplaySwitchValues(displaySwitchValues);
  }, [displaySwitchValues]);

  useEffect(() => {
    setUserRoleState({
      name: currentRow?.name,
      userName: currentRow?.username,
      mobile: currentRow?.mobile,
      role: currentRow?.role,
      email: currentRow?.email,
    });
  }, [currentRow]);

  // / Function to fetch all user roles
  const fetchUserRoles = async () => {
    console.log("inside fetchUserRoles function");
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_USER_ROLE_GETROLE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      setUserRole(data?.rows);
    } catch (error) {
      console.log("Error fetching user roles", error);
      setApiErrorOpen(true);
    }
  };

  console.log("setUserRole", setUserRole);

  // Function to fetch data of all users
  const fetchAllUserData = () => {
    axiosInstance
      .get(API_ENDPOINTS.GET_USER_ROLE_ALLUSERDATA)
      .then((response) => {
        console.log("responseeeeeeeeee", response);
        setRows(response?.data?.rows);
      })
      .catch((error) => {
        console.log("Error", error);
        setApiErrorOpen(true);
      });
  };

  // For opening the dialog box corresponding to the user details clicked
  const handleRowClick = (row) => {
    console.log("handleRowClick", row);
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    console.log("HITTTTTTTTTTT");
    setIsDialogOpen(false);
    setCancelChanges(true);
    setUpdatedValues({});
  };

  // Function to handle behaviour of switches
  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setUpdatedValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
    setDisplaySwitchValues((prevDisplayValues) => ({
      ...prevDisplayValues,
      [name]: checked,
    }));
  };

  // Function for Save button in User Details dialog
  const handleSaveButtonClick = async (e) => {
    console.log("Updated valueszzzzzzzzzzzzzzz", displaySwitchValues);
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);
    console.log("entered !");
    e.preventDefault();
    setIsDialogOpen(false);
    try {
      const response = await axiosInstance.put(
        API_ENDPOINTS.PUT_USER_ROLE_ACCESS,
        displaySwitchValues,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("responseeeeeeeeee", response);
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time
      if (response.status >= 200 && response.status < 305) {
        setConfirmSaveClick(true);
        fetchAllUserData();
      } else {
        console.log("Changes not saved due to some error");
        setApiErrorOpen(true);
      }
      console.log("Updated Data of user ", response.data);
    } catch (error) {
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      console.log(error);
      setApiErrorOpen(true);
    }
    e.target.reset();
  };

  // For opening Add New User dialog box
  const handleAddNewUser = () => {
    setIsNewUserDialogOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        size: 21,
        enableEditing: false,
      },
      {
        header: "User Name",
        accessorKey: "username",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Email",
        accessorKey: "email",
        size: 20,
        enableEditing: false,
      },
      {
        header: "Role",
        accessorKey: "role",
        size: 10,
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: userRole.map((role) => (
            <MenuItem key={role.role_id} value={role.role_id}>
              {role.role_name}
            </MenuItem>
          )),
        },
      },
      {
        header: "Mobile Number",

        accessorKey: "mobile",
        size: 15,
      },
    ],
    []
  );

  const [userRoleState, setUserRoleState] = useState({
    name: currentRow?.name,
    userName: currentRow?.username,
    mobile: currentRow?.mobile,
    role: currentRow?.role,
    email: currentRow?.email,
  });
  const { name, userName, mobile, role, email } = userRoleState;

  const [newUserRoleState, setNewUserRoleState] = useState({
    new_name: "",
    new_userName: "",
    new_mobile: "",
    new_role: "",
    new_email: "",
  });
  const { new_name, new_userName, new_mobile, new_role, new_email } =
    newUserRoleState;

  // Function to update new user add fields
  const updateNewUserState = (key, value) => {
    setNewUserRoleState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateState = (key, value) => {
    setUserRoleState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleNameChange = (event) => {
    updateState("name", event.target.value);
  };
  const handleUserNameChange = (event) => {
    updateState("userName", event.target.value);
  };
  const handleNumberChange = (event) => {
    updateState("mobile", event.target.value);
  };
  const handleEmailChange = (event) => {
    updateState("email", event.target.value);
  };

  // handleSubmit Function for adding new user
  const handleSubmit = async (e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);
    console.log("entered !");
    e.preventDefault();
    const formData = {
      name: new_name,
      username: new_userName,
      mobile: new_mobile,
      role: new_role,
      email: new_email,
    };
    setIsNewUserDialogOpen(false);
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_USER_ROLE_NEWUSER,
        formData,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

      if (response.status >= 200 && response.status < 305) {
        console.log("New User Added !");
        fetchAllUserData();
        setNewUserRoleState({
          new_name: "",
          new_userName: "",
          new_mobile: "",
          new_role: "",
          new_email: "",
        });
      } else {
        console.log("User not added");
        setApiErrorOpen(true);
      }
      // console.log("New user data", response.data);
      setConfirmNewUserAddDialog(true);
    } catch (error) {
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      console.log(error);
      setApiErrorOpen(true);
    }
    e.target.reset();
  };

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "#000000", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ecf4f0" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        button: {
          color: "#ffffff",

          backgroundColor: "#2c7B55",
        },
        typography: {
          button: {
            //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "success",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12, // S
          backgroundColor: "#2c7B55",
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
              root: {
                color: "#ffffff", // set the color of the text inside the button
                backgroundColor: "#2c7B55", // set the background color of the button
                "&:hover": {
                  backgroundColor: "#2c7B55", // set the background color when hovering over the button
                },
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#2c7B55", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  // Close button in Add New User dialog
  const handleCloseUserDialog = () => {
    setIsNewUserDialogOpen(false);
    setNewUserRoleState({
      new_name: "",
      new_userName: "",
      new_mobile: "",
      new_role: "",
      new_email: "",
    });
  };

  const handleConfirmNewUserAddDialog = () => {
    setConfirmNewUserAddDialog(false);
  };

  const handleConfirmSaveClick = () => {
    setConfirmSaveClick(false);
  };

  const handleCancelChangesClick = () => {
    setCancelChanges(false);
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  return (
    <div
      style={{
        marginTop: "35px",
        marginLeft: "15px",
        marginRight: "15px",
      }}
    >
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          columns={columns}
          data={rows}
          enableStickyHeader
          enableStickyFooter
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          // enablePagination={false}
          editingMode="modal"
          initialState={{
            density: "comfortable",
            pagination: { pageIndex: 0, pageSize: 5 },
          }}
          // muiTableContainerProps={{ sx: { marginBottom: "15px" } }}
          muiToolbarAlertBannerChipProps={{ color: "primary" }}
          enableRowActions
          muiTableHeadCellProps={{
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
              fontWeight: "650",
              color: "#2c7B55",
              fontSize: "15px",
            },
          }}
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#f8fafc",
              },
            },
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
              <IconButton
                // color="#D8D8D8"
                sx={{ color: "#D8D8D8" }}
                onClick={() => handleRowClick(row.original)}
              >
                <EditIcon />
              </IconButton>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Button
              onClick={() => setIsNewUserDialogOpen(true)}
              variant="contained"
              sx={{ backgroundColor: colors.green.DEFAULT }}
            >
              <Typography color={colors.white.DEFAULT}>Add New User</Typography>
            </Button>
          )}
        />
      </ThemeProvider>

      {/* To display error message */}
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />

      {/* Details of current user dialog */}
      <Dialog open={isDialogOpen} maxWidth="md" sx={{ borderRadius: "20px" }}>
        <DialogTitle id="form-dialog-title">
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item marginTop={"6px"}>
              <Typography
                variant="h2"
                color={colors.green.DEFAULT}
                fontWeight={typography.h1}
                fontFamily="Lexend Deca"
              >
                {currentRow.name} : {currentRow.role}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "-2%", backgroundColor: "red" }}>
              <IconButton
                aria-label="close"
                onClick={() => handleCloseDialog()}
                sx={{
                  position: "absolute",
                  right: 1.9,

                  top: 10,

                  color: "grey",
                }}
              >
                <CloseSharpIcon />
              </IconButton>
            </Grid>
            <Grid item marginTop={"4%"}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  variant="h3"
                  fontWeight={typography.h1}
                  fontFamily="Lexend Deca"
                  sx={{
                    color: (
                      updatedValues.is_active === undefined
                        ? currentRow.is_active
                        : updatedValues.is_active
                    )
                      ? "#D3D3D3"
                      : "#A45A52",

                    marginTop: "10%",
                  }}
                >
                  In-Active
                </Typography>
                <Android12Switch
                  name="is_active"
                  checked={
                    updatedValues.is_active === undefined
                      ? currentRow.is_active
                      : updatedValues.is_active
                  }
                  onChange={handleSwitchChange}
                />
                <Typography
                  variant="h3"
                  fontWeight={typography.h1}
                  fontFamily="Lexend Deca"
                  sx={{
                    color: (
                      updatedValues.is_active === undefined
                        ? currentRow.is_active
                        : updatedValues.is_active
                    )
                      ? "#2C7B55"
                      : "#D3D3D3",
                  }}
                >
                  Active
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </DialogTitle>
        <form onSubmit={handleSaveButtonClick}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                  fullWidth
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="User Name"
                  value={userName}
                  onChange={handleUserNameChange}
                  fullWidth
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="filled"
                />
              </Grid>

              {/* <Grid item xs={12} sm={4} marginTop={"6px"}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    label="Role"
                    value={role}
                    onChange={handleRoleChange}
                    fullWidth
                    required
                    displayEmpty
                    inputProps={{ "aria-label": "Role" }}
                  >
                    {userRole.map((role) => (
                      <MenuItem key={role.role_id} value={role.role_id}>
                        {role.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="Mobile Number"
                  value={mobile}
                  onChange={handleNumberChange}
                  fullWidth
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="filled"
                />
              </Grid>
            </Grid>
            {/* ACCESSIBILITY BUTTONS */}
            <div style={{ marginLeft: "5%" }}>
              <Grid
                container
                alignItems="center"
                spacing={4}
                sx={{
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12} sm={3}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        display="block"
                        gutterBottom
                        variant="h3"
                        color={colors.green.DEFAULT}
                        fontWeight={typography.h1}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        Reports
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="m_reports"
                        checked={
                          updatedValues.m_reports === undefined
                            ? currentRow.m_reports
                            : updatedValues.m_reports
                        }
                        onChange={handleSwitchChange}
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>

                  <Divider
                    // variant="middle"
                    sx={{
                      marginTop: "250px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      marginRight: "30px",
                    }}
                  />
                </Grid>
                <Divider
                  variant="middle"
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                />
                <Grid item xs={12} sm={4}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    // sx={{ marginBottom: "55px" }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        display="block"
                        gutterBottom
                        variant="h3"
                        color={colors.green.DEFAULT}
                        fontWeight={typography.h1}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        Home
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="m_home"
                        checked={
                          updatedValues.m_home === undefined
                            ? currentRow.m_home
                            : updatedValues.m_home
                        }
                        onChange={handleSwitchChange}
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Sensor Box
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="home_b_sensorBox"
                        checked={
                          updatedValues.home_b_sensorBox === undefined
                            ? currentRow.home_b_sensorBox
                            : updatedValues.home_b_sensorBox
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_home === undefined
                            ? currentRow.m_home
                            : updatedValues.m_home)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    // variant="middle"
                    sx={{
                      marginTop: "210px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      marginRight: "30px",
                    }}
                  />
                </Grid>

                <Divider
                  variant="middle"
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                />
                <Grid item xs={12} sm={4}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    // sx={{ marginBottom: "15px" }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        display="block"
                        gutterBottom
                        variant="h3"
                        color={colors.green.DEFAULT}
                        fontWeight={typography.h1}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        Configuration
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="m_configuration"
                        checked={
                          updatedValues.m_configuration === undefined
                            ? currentRow.m_configuration
                            : updatedValues.m_configuration
                        }
                        onChange={handleSwitchChange}
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Config Farm
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="config_b_configFarm"
                        checked={
                          updatedValues.config_b_configFarm === undefined
                            ? currentRow.config_b_configFarm
                            : updatedValues.config_b_configFarm
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_configuration === undefined
                            ? currentRow.m_configuration
                            : updatedValues.m_configuration)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Config Crop
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="config_b_configCrop"
                        checked={
                          updatedValues.config_b_configCrop === undefined
                            ? currentRow.config_b_configCrop
                            : updatedValues.config_b_configCrop
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_configuration === undefined
                            ? currentRow.m_configuration
                            : updatedValues.m_configuration)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Config Farmer
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="config_b_configFarmer"
                        checked={
                          updatedValues.config_b_configFarmer === undefined
                            ? currentRow.config_b_configFarmer
                            : updatedValues.config_b_configFarmer
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_configuration === undefined
                            ? currentRow.m_configuration
                            : updatedValues.m_configuration)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Edit Farm Details
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="config_b_editFarmDetails"
                        checked={
                          updatedValues.config_b_editFarmDetails === undefined
                            ? currentRow.config_b_editFarmDetails
                            : updatedValues.config_b_editFarmDetails
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_configuration === undefined
                            ? currentRow.m_configuration
                            : updatedValues.m_configuration)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Edit Farmer Details
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="config_b_editFarmerDetails"
                        checked={
                          updatedValues.config_b_editFarmerDetails === undefined
                            ? currentRow.config_b_editFarmerDetails
                            : updatedValues.config_b_editFarmerDetails
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_configuration === undefined
                            ? currentRow.m_configuration
                            : updatedValues.m_configuration)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Edit Crop Details
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="config_b_editCropDetails"
                        checked={
                          updatedValues.config_b_editCropDetails === undefined
                            ? currentRow.config_b_editCropDetails
                            : updatedValues.config_b_editCropDetails
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_configuration === undefined
                            ? currentRow.m_configuration
                            : updatedValues.m_configuration)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    // variant="middle"
                    sx={{
                      marginTop: "22px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      marginRight: "30px",
                    }}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={3} sx={{ marginBottom: "188px" }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        display="block"
                        gutterBottom
                        variant="h3"
                        color={colors.green.DEFAULT}
                        fontWeight={typography.h1}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        Recipe
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="m_recipe"
                        checked={
                          updatedValues.m_recipe === undefined
                            ? currentRow.m_recipe
                            : updatedValues.m_recipe
                        }
                        onChange={handleSwitchChange}
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Custom Recipe
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="recipe_b_addCustomRecipe"
                        checked={
                          updatedValues.recipe_b_addCustomRecipe === undefined
                            ? currentRow.recipe_b_addCustomRecipe
                            : updatedValues.recipe_b_addCustomRecipe
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_recipe === undefined
                            ? currentRow.m_recipe
                            : updatedValues.m_recipe)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}

                {/* <Divider
                  variant="middle"
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                /> */}

                <Grid item xs={12} sm={3} sx={{ marginBottom: "80px" }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    sx={{ marginBottom: "145px" }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        display="block"
                        gutterBottom
                        variant="h3"
                        color={colors.green.DEFAULT}
                        fontWeight={typography.h1}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        Farms
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="m_farm"
                        checked={
                          updatedValues.m_farm === undefined
                            ? currentRow.m_farm
                            : updatedValues.m_farm
                        }
                        onChange={handleSwitchChange}
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid container direction="row" alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Add Inventory</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Switch />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Inventory Actions</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Switch />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Harvesting Rollback</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Switch />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Damage Rollback</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Switch />
                  </Grid>
                </Grid> */}
                </Grid>
                <Divider
                  variant="middle"
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                />
                <Grid item xs={12} sm={4} sx={{ marginBottom: "150px" }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        display="block"
                        gutterBottom
                        variant="h3"
                        color={colors.green.DEFAULT}
                        fontWeight={typography.h1}
                        fontFamily="Lexend Deca"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        Finance
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="m_finance"
                        checked={
                          updatedValues.m_finance === undefined
                            ? currentRow.m_finance
                            : updatedValues.m_finance
                        }
                        onChange={handleSwitchChange}
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Management
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="f_finance_management"
                        checked={
                          updatedValues.f_finance_management === undefined
                            ? currentRow.f_finance_management
                            : updatedValues.f_finance_management
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_finance === undefined
                            ? currentRow.m_finance
                            : updatedValues.m_finance)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        color={colors.black[200]}
                        fontFamily="Lexend Deca"
                      >
                        Planning
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="f_finance_planning"
                        checked={
                          updatedValues.f_finance_planning === undefined
                            ? currentRow.f_finance_planning
                            : updatedValues.f_finance_planning
                        }
                        onChange={handleSwitchChange}
                        disabled={
                          !(updatedValues.m_finance === undefined
                            ? currentRow.m_finance
                            : updatedValues.m_finance)
                        }
                        style={{ color: "#2C7B55" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider
                  variant="middle"
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                />
              </Grid>
            </div>
          </DialogContent>
          <DialogActions sx={{ marginRight: "15px" }}>
            <Stack
              spacing={1}
              direction="row"
              style={{ height: "35px", marginTop: "2%", marginLeft: "84%" }}
            >
              <Button
                sx={{ backgroundColor: "#2C7B55" }}
                variant="contained"
                type="submit"
              >
                <Typography
                  variant="h6"
                  color={colors.white.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Save
                </Typography>
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={confirmSaveClick} onClose={handleConfirmSaveClick}>
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#2C7B55" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            color={colors.green.DEFAULT}
            fontWeight={typography.h1}
            fontFamily="Lexend Deca"
          >
            New Changes Saved Successfully.
          </Typography>
        </DialogContent>
        <Button onClick={handleConfirmSaveClick}>
          <Typography
            variant="h4"
            color={colors.green.DEFAULT}
            fontWeight={typography.h1}
            fontFamily="Lexend Deca"
          >
            OK
          </Typography>
        </Button>
      </Dialog>
      <Dialog open={cancelChanges} onClose={handleCancelChangesClick}>
        <DialogTitle style={{ textAlign: "center" }}>
          <ErrorOutlineOutlined
            style={{ width: "60px", height: "60px", color: "#A45A52" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            color={colors.red[100]}
            fontWeight={typography.h1}
            fontFamily="Lexend Deca"
          >
            No Changes Saved. Please save changes first!
          </Typography>
        </DialogContent>
        <Button onClick={handleCancelChangesClick}>
          <Typography
            variant="h4"
            color={colors.red[100]}
            fontWeight={typography.h1}
            fontFamily="Lexend Deca"
          >
            OK
          </Typography>
        </Button>
      </Dialog>

      {/* Add new user dialog */}
      <Dialog
        open={isNewUserDialogOpen}
        onClose={handleAddNewUser}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography
            variant="h3"
            color={colors.green.DEFAULT}
            fontWeight={typography.h1}
            fontFamily="Lexend Deca"
          >
            Add New User
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} marginTop={"6px"}>
                <TextField
                  label="Name"
                  value={new_name}
                  onChange={(e) =>
                    updateNewUserState("new_name", e.target.value)
                  }
                  size="small"
                  inputProps={{
                    maxLength: 20,
                    minLength: 5,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <PermIdentityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  autoFocus
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="User Name"
                  value={new_userName}
                  onChange={(e) =>
                    updateNewUserState("new_userName", e.target.value)
                  }
                  size="small"
                  inputProps={{
                    maxLength: 10,
                    minLength: 3,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <AccountCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  autoFocus
                  required
                  error={rows.some(
                    (user) => user.username === newUserRoleState?.new_userName
                  )}
                  helperText={
                    rows.some(
                      (user) => user.username === newUserRoleState?.new_userName
                    )
                      ? "User Name already exists !"
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    label="Role"
                    value={new_role}
                    onChange={(e) =>
                      updateNewUserState("new_role", e.target.value)
                    }
                    fullWidth
                    required
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Role" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <SupervisedUserCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Role
                    </MenuItem>
                    {userRole.map((role) => (
                      <MenuItem key={role.role_id} value={role.role_name}>
                        {role.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  size="small"
                  value={newUserRoleState?.new_email}
                  onChange={(event) => {
                    const { value } = event.target;
                    // Validate and update the new_email state
                    setNewUserRoleState((prevValues) => ({
                      ...prevValues,
                      new_email: value,
                    }));
                  }}
                  fullWidth
                  required
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    maxLength: 50,
                    minLength: 3,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <MailIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    (newUserRoleState?.new_email !== "" && // Check for empty string
                      !newUserRoleState?.new_email?.match(
                        // Validate the email with a less restrictive pattern
                        "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$"
                      )) ||
                    rows.some(
                      (user) => user.email === newUserRoleState?.new_email
                    ) // Check if the email is taken
                  }
                  helperText={
                    (newUserRoleState?.new_email !== "" && // Check for empty string
                    !newUserRoleState?.new_email?.match(
                      // Validate the email with a less restrictive pattern
                      "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$"
                    )
                      ? "Invalid email address"
                      : "") ||
                    (rows.some(
                      (user) => user.email === newUserRoleState?.new_email
                    ) // Check if the email is taken
                      ? "User with this email already exists !"
                      : "")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={"6px"}>
                <TextField
                  label="Mobile No."
                  value={newUserRoleState?.new_mobile}
                  size="small"
                  onChange={(event) => {
                    const { value } = event.target;
                    // Validate and update the new_mobile state only if it's a valid number
                    if (
                      value === "" ||
                      /^[0-9]*$/.test(value) ||
                      value?.match(/^[\+0-9]+$/)
                    ) {
                      setNewUserRoleState((prevValues) => ({
                        ...prevValues,
                        new_mobile: value,
                      }));
                    }
                  }}
                  fullWidth
                  type="tel"
                  required
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric", // Shows the numeric keyboard on mobile devices
                    pattern: "[0-9]*", // Only allows numeric input (additional validation)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <PhoneIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    newUserRoleState?.new_mobile?.length !== 10 &&
                    newUserRoleState?.new_mobile !== "" // Check for empty string
                  }
                  helperText={
                    newUserRoleState?.new_mobile?.length !== 10 &&
                    newUserRoleState?.new_mobile !== ""
                      ? "Invalid mobile number. It should be 10 digits."
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginRight: "15px" }}>
            <Stack
              spacing={1}
              direction="row"
              style={{ height: "35px", marginTop: "2%", marginLeft: "84%" }}
            >
              <Button
                onClick={handleCloseUserDialog}
                sx={{ color: "#2C7B55", borderColor: "#2C7B55" }}
                variant="outlined"
              >
                Close
              </Button>
              <Button
                type="submit"
                sx={{ backgroundColor: "#2C7B55" }}
                variant="contained"
              >
                <Typography
                  variant="h6"
                  color={colors.white.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Add
                </Typography>
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={confirmNewUserAddDialog}
        onClose={handleConfirmNewUserAddDialog}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#198754" }}
          />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p>New User Added Successfully.</p>
        </DialogContent>
        <Button
          onClick={handleConfirmNewUserAddDialog}
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "2%",
            color: "#198754",
          }}
        >
          OK
        </Button>
      </Dialog>
    </div>
  );
};

export default UserRoleDataGrid;
