import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Stack,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Box,
  Select,
  TextField,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
  Input,
  FormHelperText,
  MenuItem,
  Typography,
  Button,
  DialogTitle,
} from "@mui/material";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import RefreshIcon from "@mui/icons-material/Refresh";
import { MaterialReactTable } from "material-react-table";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { tokens } from "../WMStheme";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { FaSearch } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import { AlertDialog, AlertDialogBox } from "../PopUpMsg/AlertDialog";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

const AddSKUTable = ({
  handleChangePage,
  handleAddClick,

  warehouseId,
  vendorId,
  handleCloseDialog,
}) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const globalTheme = useTheme();
  const [skuMasterData, setSKUMasterData] = useState([]);
  //const [skuMasterData, setSKUMasterData] = useState(skuData1);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [skuConfirmDialog, setSkuConfirmDialog] = useState(false);
  const [rate, setRate] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorPopupMessage, setErrorPopupMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [errorPopupOpen1, setErrorPopupOpen1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [orderQty, setOrderQty] = useState(0);
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorPopupOpen3, setErrorPopupOpen3] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
  const [rateinputValues, setRateInputValues] = useState({});
  const [UpdatedRowPagination, setUpdatedRowPagination] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offsetofAddSkuTable, setOffsetofAddSkuTable] = useState(1);
  const [totalPagesofAddSkuTable, setTotalPageofAddSkuTable] = useState("");
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(1);
  const [valid, setValid] = useState(false);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "sku_name",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Code",
        accessorKey: "sku_code",
        size: 10,
        enableEditing: false,
      },
      {
        header: "UOM",
        accessorKey: "uom",
        size: 10,
        enableEditing: false,
      },
      // {
      //   header: "Lot No.",
      //   accessorKey: "lot_no",
      //   size: 10,
      //   enableEditing: false,
      // },
      // {
      //   header: "MRP",
      //   accessorKey: "mrp",
      //   size: 10,
      //   enableEditing: false,
      // },
      {
        header: "Last Purchase Price",
        accessorKey: "last_purchase_price",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Avg Purchase Price",
        accessorKey: "avg_puchase_price",
        size: 10,
        enableEditing: false,
      },

      {
        header: "PO Quantity",
        accessorKey: "order_qty",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const inputValue = inputValues[original.sku_id] || "";

          return (
            <TextField
              name={inputValue}
              value={inputValue}
              size="small"
              type="number"
              onChange={(e) => handleQuantityChange(e, original)}
              inputProps={{
                step: "any",
              }}
            />
          );
        },
      },
      {
        header: "Rate",
        accessorKey: "rate",
        size: 10,
        Cell: ({ row }) => {
          const { original } = row;
          const rateinputValue = rateinputValues[original?.sku_id] || "";

          return (
            <TextField
              name={rateinputValue}
              value={
                rateinputValue !== null && rateinputValue !== undefined
                  ? rateinputValue
                  : ""
              }
              size="small"
              type="number"
              onChange={(e) => handleRateQuantityChange(e, original)}
              inputProps={{
                step: "any",
              }}
            />
          );
        },
      },
    ],
    [skuMasterData, inputValues, rateinputValues]
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const handleQuantityChange = (e, original) => {
    let { value } = e.target;
    const uom = original?.uom;
    const restrictedUOM = ["Piece", "Pcs","pc"];
    const parts = value.toString().split(".");
    console.log("parts" , parts);
    if (value?.length <= 10) {
      if (restrictedUOM.includes(uom) && Number(value) % 1 !== 0) {
        value = Math.floor(Number(value));
        console.log("Rounded value:", value);
        setErrorMessage(`Quantity cannot be in decimal for SKU with UOM in Piece.       
                Click on Proceed to move ahead.`);
        setErrorPopupOpen(true);
      }
      else if (value < 0 ){
        console.log("order_qty", original?.order_qty);
        
        
        setErrorMessage3(
          "Order quantity should  be greater than 0."
        );
  
        setErrorPopupOpen3(true);
        value = 0;
      }
      else if (parts?.length >= 0 && parts[1]?.length > 2) { 
        // Convert to maximum 2 decimal places
        setErrorMessage3(
          "Please enter order quantity upto 2 decimal place only ."
        );
  
        setErrorPopupOpen3(true);
       
          value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
          console.log("value here after decimal round off " , value);
      
      } 
      // Limit the value to 20000
      else if (parseFloat(value) > 50000) {
          value = 50000;
          
      }
      const updatedSkuMasterData = skuMasterData.map((item) =>
        item.sku_id === original.sku_id
          ? { ...item, order_qty: parseFloat(value) }
          : item
      );
      setSKUMasterData(updatedSkuMasterData);

      // Update the input values state
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [original.sku_id]: parseFloat(value),
      }));

      // Update the selected rows with the new flag
      setRowSelection((prevRowSelection) => ({
        ...prevRowSelection,
        [original.sku_id]: true,
      }));

      // Add or update the row in the array
      const updatedRow = { ...original, order_qty: parseFloat(value) };
      const index = updatedRowsArray?.findIndex(
        (row) => row.sku_id === original.sku_id
      );

      if (index !== -1) {
        // If row exists in the array, update it
        const newArray = [...updatedRowsArray];
        newArray[index] = updatedRow;
        setUpdatedRowsArray(newArray);
      } else {
        // If row doesn't exist in the array, add it
        setUpdatedRowsArray((prevArray) => [...prevArray, updatedRow]);
      }

      // Console the updated array here
      console.log("Updated Rows Array:", updatedRowsArray);
    } 

  };
  const handleRateQuantityChange = (e, original) => {
    let { value } = e.target;
    const lastPurchasePrice = original.last_purchase_price || 0;
    const rateLimit = lastPurchasePrice + lastPurchasePrice * 0.1;
    const parts = value.toString().split(".");
    if (parseFloat(value) > rateLimit) {
      setErrorMessage(`Amount entered exceeded 10% of last purchase price.       
       An email alert will be automatically sent to the respective supervisor.       Click on Proceed to move ahead.`);
      setErrorPopupOpen(true);
    }
    else if (value < 0 ){
      console.log("value", value);
      
      
      setErrorMessage3(
        "Rate should  be greater than 0."
      );

      setErrorPopupOpen3(true);
      value = 0;
    }
     else if (parts?.length >= 0 && parts[1]?.length > 2) { 
      // Convert to maximum 2 decimal places
      setErrorMessage3(
        "Please enter Rate upto 2 decimal place only ."
      );

      setErrorPopupOpen3(true);
     
        value = parseFloat(parts[0] + "." + parts[1]?.slice(0, 2));
        console.log("value here after decimal round off " , value);
    
    } 
    // Limit the value to 200000
    else if (parseFloat(value) > 500000) {
        value = 500000;
        
    }
    const updatedSkuMasterData = skuMasterData?.map((item) =>
      item.sku_id === original.sku_id
        ? { ...item, rate: parseFloat(value) }
        : item
    );
    setSKUMasterData(updatedSkuMasterData);
    setRateInputValues((prevInputValues) => ({
      ...prevInputValues,
      [original.sku_id]: parseFloat(value),
    }));
    setRowSelection((prevRowSelection) => ({
      ...prevRowSelection,
      [original.sku_id]: true,
    }));
    const updatedRow = { ...original, rate: parseFloat(value) };
    const index = updatedRowsArray.findIndex(
      (row) => row.sku_id === original.sku_id
    );

    if (index !== -1) {
      const newArray = [...updatedRowsArray];
      newArray[index] = updatedRow;
      setUpdatedRowsArray(newArray);
    } else {
      setUpdatedRowsArray((prevArray) => [...prevArray, updatedRow]);
    }
  };

  useEffect(() => {
    // Update rate and orderQty when selected rows change
    const updatedSelectedRows = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    setSelectedRows(updatedSelectedRows);
  }, [rowSelection]);

  useEffect(() => {
    // Update rate and orderQty when selected rows change
    if (selectedRows.length > 0) {
      const selectedRow = selectedRows[0]; // Assuming only one row is selected
      setRate(selectedRow?.rate || 0);
      setOrderQty(selectedRow?.order_qty || 0);
    }
  }, [selectedRows]);
  const onAddClick = () => {
    // Use the updated values from rate and orderQty state
    const isValid = selectedRows.every((key) => {
      const orderQty = inputValues[key];
      const rate = rateinputValues[key];
      return orderQty && rate;
    });

    if (isValid) {
      // Use the updated values from rate and orderQty state
      const updatedRows = selectedRows.map((key) => ({
        ...skuMasterData.find((item) => item.sku_id === key),
        rate: skuMasterData.find((item) => item.sku_id === key)?.rate || 0,
        order_qty:
          skuMasterData.find((item) => item.sku_id === key)?.order_qty || 0,
      }));
      // Update rowSelection state with the newly added rows
      const updatedRowSelection = updatedRows?.reduce(
        (acc, row) => {
          acc[row.sku_id] = true;
          return acc;
        },
        { ...rowSelection }
      );

      setRowSelection(updatedRowSelection);
      setUpdatedRowPagination(updatedRows);
      console.log("row selection ", updatedRowSelection);
      handleAddClick(updatedRowsArray, rowSelection);
      console.log("updated row data ", updatedRows);
    } else {
      setValid(true);
      // Show an alert or handle the case where the data is not valid
      setErrorMessage1("Please fill quantity and rate for all selected rows");
      setErrorPopupOpen1(true);
    }
  };

  useEffect(() => {
    fetchSKUwrtVendor(1);
  }, []);

  const fetchSKUwrtVendor = async (page) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_PO_VENDOR_SKU_LIST,
        {
          params: {
            warehouse_id: warehouseId,
            vendor_id: vendorId,
            limit: 50,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = response?.data?.data;
        setSKUMasterData(data?.purchase_skus);
        setTotalPageofAddSkuTable(data?.total_pages);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchSearchData = async (page) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_PO_VENDOR_SKU_LIST,
        {
          params: {
            warehouse_id: warehouseId,
            vendor_id: vendorId,
            sku_search: search,
            limit: 50,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = response?.data?.data;
        setSKUMasterData(data?.purchase_skus);
        setTotalPageofAddSkuTable(data?.total_pages);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSearchClick = () => {
    setOffsetofAddSkuTable(1);
    fetchSearchData(1);
  };

  const onPageChange = (event, newPage) => {
    setOffsetofAddSkuTable(newPage);
    setSkuConfirmDialog(true);
  };

  const handleSkuConfirmDialogClose = () => {
    fetchSKUwrtVendor(offsetofAddSkuTable);
    setSkuConfirmDialog(false);
  };

  const handleSkuOkayClick = () => {
    onAddClick();
    fetchSKUwrtVendor(offsetofAddSkuTable);
  };

  const handleSkuDiscardClick = () => {
    handleSkuConfirmDialogClose();
  };

  const handleSearchDiscardClick = () => {
    fetchSearchData(offsetofAddSkuTable);
    setSkuConfirmDialog(false);
  };

  const handleClearClick = () => {
    setOffsetofAddSkuTable(1);
    setSearch("");
    fetchSKUwrtVendor(1);
    setCount((prevCount) => prevCount + 1);
  };

  return (
    <>
      <Dialog open={true} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Typography variant="h2" color={colors.green.DEFAULT}>
            Select SKU
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={skuMasterData}
                  key={count}
                  enableRowSelection
                  getRowId={(row) => row?.sku_id} //give each row a more useful id
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }}
                  editingMode="table"
                  enableEditing={false}
                  enableGrouping
                  enableStickyHeader
                  enablePagination={false}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  initialState={{
                    density: "compact",
                    pagination: { pageIndex: 0, pageSize: 10 },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      color: "#fff",
                      backgroundColor: "#34519fe6",
                    },
                  }}
                  muiToolbarAlertBannerChipProps={{ color: "primary" }}
                  muiTableBodyProps={{
                    sx: {
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#ffff",
                      },
                    },
                  }}
                  muiTableContainerProps={{ sx: { maxHeight: "43vh" } }}
                  enableGlobalFilter={false}
                  renderTopToolbarCustomActions={() => (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={3} md={3}>
                        <FormControl sx={{ width: "100%" }} variant="standard">
                          <InputLabel htmlFor="SKU Name">
                            Search by SKU Name
                          </InputLabel>
                          <Input
                            id="SKU Name"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="SKU Name"
                                  onClick={handleSearchClick}
                                  disabled={search.length < 3}
                                >
                                  {<FaSearch />}
                                </IconButton>
                                <IconButton
                                  aria-label="SKU Name"
                                  onClick={handleClearClick}
                                >
                                  {<CloseSharpIcon />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <FormHelperText id="component-helper-text">
                            At least 3 characters
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                />
              </ThemeProvider>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Pagination
                  count={totalPagesofAddSkuTable}
                  page={offsetofAddSkuTable}
                  size="small"
                  onChange={onPageChange}
                />
              </div>
            </Box>
          </Box>
        </DialogContent>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            //   marginRight: "1.0%",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-start"
          >
            <Grid item xs={12} sm={12}>
              <Stack
                spacing={1}
                direction="row"
                style={{
                  height: "32px",
                  marginRight: "0%",
                  marginLeft: "84%",
                }}
              >
                <Button
                  onClick={handleCloseDialog}
                  variant="outlined"
                  style={{ float: "left", marginLeft: "15px" }}
                >
                  <Typography variant="h6" color={colors.green.DEFAULT}>
                    Cancel
                  </Typography>
                </Button>
                <Button
                  onClick={onAddClick}
                  variant="contained"
                  sx={{ backgroundColor: colors.green.DEFAULT }}
                  style={{ float: "left" }}
                  // disabled ={currentStatus === 'received'}
                >
                  <Typography variant="h6" color={colors.white.DEFAULT}>
                    Add
                  </Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <AlertDialog
        open={skuConfirmDialog}
        onClose={handleSkuConfirmDialogClose}
        title="Add Current SKU?"
        content="Selected SKUs (if any) will be added"
        discardText="Discard"
        okayText="Add"
        handleDiscardClick={
          search.length > 3 ? handleSearchDiscardClick : handleSkuDiscardClick
        }
        handleOkayClick={handleSkuOkayClick}
      />
      {/* <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      /> */}
      <AlertDialogBox
        open={errorPopupOpen}
        okayText="Proceed"
        handleOkayClick={() => setErrorPopupOpen(false)}
        onClose={() => setErrorPopupOpen(false)}
        content={errorMessage}
      />
      <AlertDialogBox
        open={errorPopupOpen1}
        okayText="Okay"
        handleOkayClick={() => setErrorPopupOpen1(false)}
        onClose={() => setErrorPopupOpen1(false)}
        content={errorMessage1}
      />
      <AlertDialogBox
        open={errorPopupOpen3}
        okayText="Okay"
        handleOkayClick={() => setErrorPopupOpen3(false)}
        onClose={() => setErrorPopupOpen3(false)}
        content={errorMessage3}
      />
    </>
  );
};

export default AddSKUTable;
