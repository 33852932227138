import React, { useEffect, useState } from "react";
import "./Weather.css";
import { WiDaySunny, WiRain, WiCloud } from "react-icons/wi";
import { WiHumidity, WiStrongWind, WiBarometer } from "react-icons/wi";
import { Rings } from "react-loader-spinner";
import { tokens } from "../../../theme";
import { Typography, useTheme, Stack, Grid } from "@mui/material";
import ApiErrorAlert from "../../../ApiErrorAlert";
import SaveAlert from "../../../SaveAlert";
import {
  BASE_URL,
  API_ENDPOINTS,
} from "../../../../src/ApiEndpoint/ApiEndpoint";
import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const WeeklyWeather = () => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [location, setLocation] = useState("");
  const [currentTemperature, setCurrentTemperature] = useState("");
  const [currentIcon, setCurrentIcon] = useState("");
  const [currentHumidity, setCurrentHumidity] = useState("");
  const [currentPressure, setCurrentPressure] = useState("");
  const [currentSpeed, setCurrentSpeed] = useState("");
  const [weatherData, setWeatherData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    return () => clearTimeout(timeout);
  }, []);

  const fetchWeatherData = async (cityName) => {
    try {
      const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?q=${cityName}&appid=${apiKey}&units=metric`
      );
      const data = response?.data?.list?.slice(0, 7);
      setWeatherData(data);
      const { name } = response?.data?.city;
      const { temp } = response?.data?.list[0]?.main;
      const { humidity } = response?.data?.list[0]?.main;
      const { pressure } = response?.data?.list[0]?.main;
      const { speed } = response?.data?.list[0]?.wind;

      setLocation(name);
      setCurrentTemperature(temp);
      // setCurrentIcon(main);
      setCurrentHumidity(humidity);
      setCurrentPressure(pressure);
      setCurrentSpeed(speed);
      setIsLoading(false);
    } catch (error) {
      fetchWeatherData("Kanpur");
      setApiErrorOpen(true);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchFarmDetails = async () => {
      try {
        const response = await axiosInstance.get(
          API_ENDPOINTS.GET_STATIC_DASHBOARD_WEATHER,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response?.data?.rows;
        const cityName = data[0]?.farm_city;
        fetchWeatherData(cityName);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFarmDetails();
  }, []);

  const getWeatherIcon = (condition) => {
    switch (condition) {
      case "Sunny":
      case "Clear":
        return <WiDaySunny size={32} color="orange" />;
      case "Rain":
      case "light rain":
        return <WiRain size={32} color="blue" />;
      case "Clouds":
      case "scattered clouds":
      case "Windy":
      case "Thunderstorms":
        return <WiCloud size={32} color="gray" />;
      default:
        return null;
    }
  };

  const getNextSevenDays = () => {
    const days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
    const today = new Date().getDay();
    const nextSevenDays = [];
    for (let i = 0; i < 7; i++) {
      const nextDay = (today + i) % 7;
      nextSevenDays.push(days[nextDay]);
    }
    return nextSevenDays;
  };

  const nextSevenDays = getNextSevenDays();

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Rings
            height="80"
            width="80"
            color="#4fa94d"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      ) : (
        <div className="weekly-weather-container">
          <Grid container spacing={1} sx={{ marginLeft: "20px" }}>
            <Grid item xs={6} md={8}>
              <Typography
                variant="h1"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {location}
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <Typography
                variant="h2"
                color={colors.black[200]}
                fontFamily="Lexend Deca"
              >
                {currentTemperature}°C
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <div style={{ marginTop: "20px" }}>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <WiHumidity size={20} color="blue" />
                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      style={{ marginLeft: "2px", marginTop: "1px" }}
                    >
                      {currentHumidity}%
                    </Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <WiStrongWind size={20} color="grey" />

                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      style={{ marginLeft: "2px", marginTop: "1px" }}
                    >
                      {currentSpeed}m/s
                    </Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <WiBarometer size={20} color="green" />

                    <Typography
                      variant="h6"
                      color={colors.black.DEFAULT}
                      fontFamily="Lexend Deca"
                      style={{ marginLeft: "2px", marginTop: "1px" }}
                    >
                      {currentPressure}hpa
                    </Typography>
                  </div>
                </Stack>
              </div>
            </Grid>
          </Grid>

          {weatherData?.map((weather, index) => (
            <div className="weather-list" key={index}>
              <div className="weekly-weather-item">
                <div className="weather-day">
                  <Typography
                    variant="h5"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {nextSevenDays[index]}
                  </Typography>
                </div>

                <div className="weather-icon">
                  {getWeatherIcon(weather?.weather[0].main)}
                </div>
                <div className="weather-condition">
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {weather.weather[0].main}
                  </Typography>
                </div>
                <div className="weather-temperature">
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {weather.main.temp}°C
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </div>
  );
};

export default WeeklyWeather;
