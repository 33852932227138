import React, { useState } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";

import AnalyticsHome from "./AnalyticsHome";
import AnalyticsDataPreview from "./AnalyticsDataPreview";

const AnalyticsMainPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginTop: "-0.8%",
        flexItem: "space-between",
         backgroundColor: "##292B2A",
        overflow: "hidden",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="#292B2A"
        variant="fullWidth"
        indicatorColor="transparent"
      >
        <Tab
          label={
            <Typography
              variant="body1"
              style={{
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              Analytics
            </Typography>
          }
          sx={{
            "&.Mui-selected": { color: "#292B2A",backgroundColor: "#292B2A25" },
          }}
        />
        <Tab
          label={
            <Typography
              variant="body1"
              style={{
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              Orders Data Preview
            </Typography>
          }
          sx={{
            "&.Mui-selected": { color: "#292B2A",backgroundColor: "#292B2A25" },
          }}
        />

        
      </Tabs>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {value === 0 && (
          <div
            style={{ backgroundColor: "white", padding: "10px", flexGrow: 1 }}
          >
            <AnalyticsHome />
          </div>
        )}
        {value === 1 && (
          <div
            style={{ backgroundColor: "white", padding: "10px", flexGrow: 1 }}
          >
           
            <AnalyticsDataPreview />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default AnalyticsMainPage;
