import { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit"; // Import EditIcon
import Pagination from "@mui/material/Pagination";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import {
  Dialog,
  DialogContent,
  useTheme,
  IconButton,
  Typography,
  Divider,
  DialogTitle,
} from "@mui/material";
import { data1 } from "../../../../Modules/ExpenseTracker/Management/Data";
import { tokens } from "../../../../theme";
import ExpectedCard from "../ExpectedCard/ExpectedCard";
import ActualCard from "../ActualCard/ActualCard";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2c7B55",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ecf4f0",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const columns = [
  { id: "edit_ledger", label: "Edit" },
  { id: "farm_name", label: "Farm" },
  { id: "activity_name", label: "Name" },
  { id: "transaction_type", label: "Type" },
  { id: "total_labour", label: "Labour" },
  { id: "exp_amount", label: "Expected" },
  { id: "actual_amount", label: "Actual" },
  { id: "employee_name", label: "AssignTo" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
];
// Filter columns to only include the ones you want to display
const displayedColumns = columns.filter(
  (column) =>
    column.id === "edit_ledger" ||
    column.id === "farm_name" ||
    column.id === "activity_name" ||
    column.id === "transaction_type" ||
    column.id === "total_labour" ||
    column.id === "exp_amount" ||
    column.id === "actual_amount" ||
    column.id === "employee_name" ||
    column.id === "start_date" ||
    column.id === "end_date"
);
function TableHeadComponent() {
  console.log("ocliumns", columns);
  return (
    <TableHead>
      <TableRow>
        {displayedColumns.map((column) => (
          <StyledTableCell key={column.id} align="center">
            {column.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TableRowsComponent({ data1, datasending }) {
  console.log("row data aaaaaaaaaaaa", data1);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [datatocard, setdatatocard] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleRowClick = ({ row, datasending }) => {
    setSelectedRow(row);
    const selectedRowData = datasending?.actualledger?.rows?.find(
      (dataRow) =>
        dataRow?.expected?.mandatory_fields?.ledger_id === row?.ledger_id
    );

    setdatatocard(selectedRowData);
    setOpenDialog(true);
  };

  // const [isSaveClicked, setIsSaveClicked] = useState(false);

  // const handleSaveClicked = () => {
  //   setIsSaveClicked(true);
  // };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const handleSuccess = (newState) => {
    setApiSuccessOpen(newState);
  };

  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const handleFailure = (newState) => {
    setApiErrorOpen(newState);
  };

  const dialogRef = useRef(null);
  const handleBackdropClick = (event) => {
    // Check if the click event is on the dialog's backdrop and not on the dialog content
    if (dialogRef.current === event.target) {
      setOpenDialog(false);
    }
  };
  return (
    <>
      <TableBody>
        {data1?.map((row, index) => (
          <StyledTableRow key={index}>
            {displayedColumns.map((column) => (
              // <StyledTableCell
              //   key={column.id}
              //   onClick={() => handleRowClick({ row, datasending })}
              //   align="center"
              //   style={{ cursor: "pointer" }}
              // >
              //   {row[column.id]}
              // </StyledTableCell>

              <>
                {column.id === "edit_ledger" ? (
                  <StyledTableCell key={column.id} align="center">
                    <EditIcon
                      onClick={() => handleRowClick({ row, datasending })}
                      style={{
                        cursor: "pointer",
                        color: "#84899C",
                        transition: "color 0.3s", // Add a transition for smooth color change
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.color = "#2c7B55"; // Change color on hover
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.color = "#84899C"; // Restore initial color on mouse leave
                      }}
                    />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    key={column.id}
                    align="center"
                    style={{ cursor: "pointer" }}
                  >
                    {row[column.id]}
                  </StyledTableCell>
                )}
              </>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
      <Dialog
        open={openDialog}
        maxWidth="md"
        fullWidth
        onClose={handleCloseDialog}
        ref={dialogRef}
        // Attach a click event listener to the backdrop
        onClick={handleBackdropClick}
      >
        <DialogTitle sx={{ position: "sticky", height: "4%" }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 1.9,
              top: 1,

              color: "grey",
            }}
          >
            <CloseSharpIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            backgroundImage: "linear-gradient(white, #f2f2f2)",
          }}
        >
          <div
            style={{
              width: "50%",
              paddingRight: "10px",
              // boxShadow: "-5px 0px 10px rgba(0, 0, 0, 0.1)",
              // transformOrigin: 'right center',
              // transform: 'rotateY(10deg)',
              // borderTopRightRadius: '10px',
              // borderBottomRightRadius: '10px',

              // clipPath: "polygon(-22% -28px, 136% -60%, 189% 109%, -5% 113%)",
            }}
          >
            <Typography
              variant="h3"
              color={colors.green.DEFAULT}
              align="center"
            >
              Expected
            </Typography>
            <ExpectedCard selectedRow={selectedRow} filterdata={datatocard} />
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
          />
         
          <div
            style={{
              width: "50%",
              paddingLeft: "10px",
              // boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.1)',
              // transformOrigin: 'left center',
              // transform: 'rotateY(-10deg)',
              // borderTopLeftRadius: '10px',
              // borderBottomLeftRadius: '10px',
              //clipPath: "polygon(-14% -10px, 115% -10%, 105% 100%, -80% 160%)",
            }}
          >
            <Typography
              variant="h3"
              color={colors.green.DEFAULT}
              align="center"
            >
              Actual
            </Typography>
            <ActualCard
              onChange={handleCloseDialog}
              selectedRow={selectedRow}
              filterdata={datatocard}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
            />
          </div>
        </DialogContent>
      </Dialog>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
}
const moment = require("moment");

function DisplayTab({ onPaginationChange, datasend }) {
  console.log("DATA SENDDDDDDDDDDD", datasend?.actualledger);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (datasend?.actualledger?.rows) {
      // Filter the rows to include only the specified columns
      const filteredRows = datasend?.actualledger?.rows.map((row) => ({
        farm_name: row.expected.mandatory_fields.farm_name,
        activity_name: row.expected.mandatory_fields.ledger_name,
        transaction_type: row.transaction_type,
        total_labour: row.expected.mandatory_fields.total_labour,
        exp_amount: row.expected.mandatory_fields.exp_amount,
        actual_amount: row.actual.mandatory_fields.actual_amount,
        employee_name: row.expected.mandatory_fields.employee_name,
        ledger_id: row.expected.mandatory_fields.ledger_id,
        start_date: moment(row.expected.mandatory_fields.start_date).format(
          "DD/MM/YY"
        ),
        end_date: moment(row.expected.mandatory_fields.end_date).format(
          "DD/MM/YY"
        ),
      }));
      setRows(filteredRows);
      console.log("rowasdgyijs", rows);
    } else {
      setRows([]);
    }
  }, [datasend]);
  const [offset, setOffset] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState({
    offset: 1,
    limit: 10,
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const handleChangePage = (event) => {
  //   let newPage = page + 1;
  //   let testId = event.target.dataset.testid;
  //   console.log("testIddddddd", testId);
  //   if (testId === "KeyboardArrowRightIcon") {
  //     newPage = page + 1;
  //   } else {
  //     newPage = page - 1;
  //     if (newPage < 0) {
  //       newPage = 0;
  //     }
  //   }
  //   const newOffset = newPage + 1;
  //   setPage(newPage);
  //   setPaginationInfo({ ...paginationInfo, offset: newOffset }); // Update the paginationInfo state
  //   setOffset(newOffset);
  // };

  // const [page, setPage] = useState(1); // Set your initial page state

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginationInfo({ ...paginationInfo, offset: newPage });
    // You can perform any other actions related to page change here
  };

  // Define a callback function to send paginationInfo to the parent component

  const sendPaginationInfoToParent = () => {
    // Call the parent component's callback function and pass paginationInfo
    if (onPaginationChange) {
      onPaginationChange(paginationInfo);
    }
  };
  // Call sendPaginationInfoToParent whenever the pagination changes

  // ... your pagination controls ...
  useEffect(() => {
    sendPaginationInfoToParent();
  }, [paginationInfo]);

  // Pass paginationInfo to the parent component when needed

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 200 }} aria-label="customized table">
            <TableHeadComponent />

            {datasend !== null ? (
              <TableRowsComponent
                data1={rows}
                datasending={datasend}
                data={rows.slice(offset * rowsPerPage, offset * rowsPerPage)}
              />
            ) : (
              <TableCell
                colSpan={columns?.length}
                style={{
                  textAlign: "center",
                  height: "330px",
                }}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ margin: "16px" }}
                >
                  No records to display. Please select filters.
                </Typography>
              </TableCell>
            )}
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginTop: "5px",
          }}
        >
          {/* <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={10}
          count={rows?.length}
          limit={10}
          offset={page}
          rowsPerPage={rowsPerPage}
          sx={{
            borderBottom: "none",
            marginTop: "-1.0%", // Remove the light line below offset number
          }}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={() => `Page no ${page + 1}`}
        /> */}
          <Pagination count={10} onChange={handleChangePage} size="small" />
        </div>
      </div>
    </>
  );
}
export default DisplayTab;
