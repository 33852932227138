import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  Box,FormControlLabel,Switch,
  IconButton,
  Dialog,
  Input,
  FormHelperText,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { ScaleLoader } from "react-spinners";
import { TbFileCode } from "react-icons/tb";
import { FaSearch } from "react-icons/fa";
import { CiCalendarDate } from "react-icons/ci";
import { Label } from "semantic-ui-react";
import { useTheme } from "@mui/material";
import { tokens } from "../WMStheme";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Progress, Spin } from "antd";
import { IoIosPerson } from "react-icons/io";
import { HiOutlineIdentification } from "react-icons/hi2";
import NullScreen from "../PopUpMsg/NullScreen";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import RequestResponseDialog from "./RequestResponseDialog";
import SuccessPopup from "../PopUpMsg/SuccessPopUp";
import ErrorPopup from "../PopUpMsg/ErrorPopUp";
import { FaRegClock } from "react-icons/fa6";
import { RiMessage2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const containerStyle = {
  maxHeight: "65vh",
  height: "100%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  // backgroundColor: "blue",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "65vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "green",
};

function getStatusColor(logs_status) {
  switch (logs_status) {
    case "success":
      return "#257850";
    case "error":
      return "red";
    default:
      return "#7f1d1d";
  }
}

const customStyle = (logs_status) => {
  return {
    fontFamily: "Lexend Deca",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
  };
};

const formatDateIST = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} || ${hours}:${minutes}`;
};

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

function ZohoLogsMainPage({
  handleZohoLogsDialogClose,
  setIsZohoLogsDialogOpen,
  isZohoLogsDialogOpen,
}) {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [offset, setOffset] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [logsList, setLogsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [counter, setCounter] = useState(1);
  const [status, setStatus] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    request: null,
    response: null,
  });
  const [filterValues, setFilterValues] = useState({
    limit: 30,
    offset: offset,
    
  });

  useEffect(() => {
    fetchZohoLogsList(filterValues);
  }, []);

  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchZohoLogsList(updatedValues);
  };

  const handleRepushAPI = async (logs) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_REPUSH_ZOHO_API,
        logs,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status >= 200 && response?.status < 305) {
        setTimeout(() => {
          const updatedValues = {
            ...filterValues,
            offset: 1,
            code_search: null,
          };
          setFilterValues(updatedValues);
          fetchZohoLogsList(updatedValues);
          setCounter((prevCounter) => prevCounter + 1);
          setIsLoading(false);
        }, 3000);
        setSuccessMessage("Repush Successful !");
        setSuccessPopupOpen(true);
        //  setIsZohoLogsDialogOpen(false);
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      setErrorMessage("Repush Failed");
      setErrorPopupOpen(true);
    }
  };

  const handleViewClick = (log) => {
    console.log("log in handleViewClick--------", log);
    setDialogContent({ request: log.request, response: log.response });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const fetchZohoLogsList = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_ZOHO_LOGS,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response?.status >= 200 && response?.status < 305) {
        const data = await response?.data?.data;
        console.log("data-----------", data);
        setTotalPages(data?.total_pages);
        setLogsList(data?.list);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleStatusChange = (event) => {
    console.log("status before", status);
    setStatus(event.target.checked);
    const updatedValues = { ...filterValues, offset: 1,  status: status};
    setFilterValues(updatedValues);
    fetchZohoLogsList(updatedValues);
    setCounter((prevCounter) => prevCounter + 1);
    console.log("status after ", event.target.checked);
  };

  const handleSearchClick = () => {
    setOffset(1);
    const updatedValues = { ...filterValues, offset: 1, code_search: search  };
    setFilterValues(updatedValues);
    fetchZohoLogsList(updatedValues);
  };

  const handleClearClick = () => {
    setOffset(1);
    setSearch("");
    const updatedValues = { ...filterValues, offset: 1, code_search: null };
    setFilterValues(updatedValues);
    fetchZohoLogsList(updatedValues);
  };

  return (
    <>
      <Grid container spacing={1}>
        {isLoading ? (
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={isLoading} />
          </div>
        ) : logsList?.length === 0 ? (
          <>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={3} md={3}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <InputLabel htmlFor="Code">Search by Code</InputLabel>
                  <Input
                    id="Code"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Code"
                          onClick={handleSearchClick}
                          disabled={search.length < 3}
                        >
                          {<FaSearch />}
                        </IconButton>
                        <IconButton
                          aria-label="Code"
                          onClick={handleClearClick}
                        >
                          {<CloseSharpIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="component-helper-text">
                    At least 3 characters
                  </FormHelperText>
                </FormControl>
              </Grid>
            
            </Grid>
            <NullScreen containerheight="65vh" containerWidth="100%" />
          </>
        ) : (
          <>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={3} md={3}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <InputLabel htmlFor="Code">Search by Code</InputLabel>
                  <Input
                    id="Code"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Code"
                          onClick={handleSearchClick}
                          disabled={search.length < 3}
                        >
                          {<FaSearch />}
                        </IconButton>
                        <IconButton
                          aria-label="Code"
                          onClick={handleClearClick}
                        >
                          {<CloseSharpIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="component-helper-text">
                    At least 3 characters
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}></Grid>
                <Grid item xs={12} sm={2} md={2}>
                <FormControlLabel
  value="start"
  checked={status}
  onChange={handleStatusChange}
  control={<Switch color="primary" />}
  label="Apply Filter : Error"
  labelPlacement="start"
/>

              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={containerStyle}
              sx={{ marginTop: "5px" }}
            >
              {logsList?.map((logs, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  <Paper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "8px",
                      height: "50px",
                      transition:
                        "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
                      position: "relative", // Make sure the button is positioned relative to the Paper
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.01)";
                      e.currentTarget.style.backgroundColor = "#eeeeef";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.backgroundColor = "white";
                    }}
                    key={counter}
                  >
                    <Grid container direction="row">
                      <Grid
                        item
                        xs={1.5}
                        sm={1.5}
                        md={1.5}
                        container
                        direction="column"
                      >
                        <Grid item style={customStyle(logs?.status)}>
                          <Label
                            style={{
                              backgroundColor: getStatusColor(logs?.status),
                              color: "#ffffff",
                            }}
                            tag
                          >
                            {capitalizeFirstLetter(logs?.status)}
                          </Label>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={1.5}
                        sm={1.5}
                        md={1.5}
                        container
                        direction="column"
                      >
                        <Grid
                          item
                          style={{ color: "grey", textAlign: "center" }}
                        >
                          <TbFileCode fontSize="small" /> Code
                        </Grid>
                        <Grid
                          item
                          style={{ color: "black", textAlign: "center" }}
                        >
                          {logs?.document_code}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        container
                        direction="column"
                      >
                        <Grid
                          item
                          style={{ color: "grey", textAlign: "center" }}
                        >
                          <FaRegClock fontSize="small" /> Timestamp
                        </Grid>
                        <Grid
                          item
                          style={{ color: "black", textAlign: "center" }}
                        >
                          {formatDateIST(logs?.updated_at)}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        container
                        direction="column"
                      >
                        <Grid
                          item
                          style={{ color: "grey", textAlign: "center" }}
                        >
                          <RiMessage2Line fontSize="small" /> Message
                        </Grid>
                        <Grid
                          item
                          style={{ color: "black", textAlign: "center" }}
                        >
                          {logs?.message}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        container
                        direction="column"
                      >
                        <Grid
                          item
                          style={{ color: "grey", textAlign: "center" }}
                        >
                          <HiOutlineIdentification fontSize="small" /> Type
                        </Grid>
                        <Grid
                          item
                          style={{ color: "black", textAlign: "center" }}
                        >
                          {logs?.type}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        container
                        direction="column"
                      >
                        <Grid
                          item
                          style={{ color: "black", textAlign: "center" }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#34519fe6",
                              borderColor: "#34519fe6",
                            }}
                            onClick={() => handleViewClick(logs)}
                          >
                            <Typography
                              variant="h6"
                              fontFamily="Lexend Deca"
                              color="white"
                            >
                              View
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        container
                        direction="column"
                      >
                        <Grid
                          item
                          style={{ color: "black", textAlign: "center" }}
                        >
                          <Button
                            variant="contained"
                            disabled={logs?.status === "success"}
                            style={{
                              backgroundColor:
                                logs?.status === "error"
                                  ? "#b43652"
                                  : "#84899C",
                              borderColor: "#b43652",
                            }}
                            onClick={() => handleRepushAPI(logs)}
                          >
                            <Typography
                              variant="h6"
                              fontFamily="Lexend Deca"
                              color="white"
                            >
                              Re-Push
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <RequestResponseDialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              request={dialogContent.request}
              response={dialogContent.response}
            />
            <Grid container justifyContent="flex-end" sx={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={8.5} md={8.2}></Grid>
              <Grid item xs={12} sm={3.5} md={3.8}>
                {logsList?.length > 0 && (
                  <Pagination
                    count={totalPages}
                    page={offset}
                    onChange={handleChangePage}
                    style={{ color: "#5e89c7" }}
                  />
                )}
              </Grid>
            </Grid>
            <SuccessPopup
              open={successPopupOpen}
              onClose={() => setSuccessPopupOpen(false)}
              message={successMessage}
            />
            <ErrorPopup
              open={errorPopupOpen}
              onClose={() => setErrorPopupOpen(false)}
              message={errorMessage}
            />
          </>
        )}
      </Grid>
    </>
  );
}

export default ZohoLogsMainPage;
