import React from 'react';
import Pagination from '@mui/material/Pagination';
import { Table } from '@material-ui/core';

const CustomPagination = ({  totalPages, currentPage, onPageChange }) => {
//   const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleChangePage = (event, newPage) => {
    onPageChange(newPage);
  };

  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handleChangePage}
      size="small"
    //   rowsPerPage={5}
    />
  );
};

export default CustomPagination;
