import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LocalFloristOutlinedIcon from "@mui/icons-material/LocalFloristOutlined";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { tokens } from "../../theme";
import Collapse from "@mui/material/Collapse";
import Scheduler from "../../Components/TaskAllocationComponents/Scheduler";
import CropDataGrid from "./TaskList/CropDataGrid";
import SideDrawer from "./SideDrawer";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import moment from "moment";
import EventIcon from "@mui/icons-material/Event";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TimerIcon from "@mui/icons-material/Timer";
import { BASE_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import TableRowsIcon from "@mui/icons-material/TableRows";

import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const AllCards = ({ taskData, fetchTaskData }) => {
  console.log(
    "Filtered Task Data from AllCardsssssss function XXXXXXXXXXXXXXXXXX",
    taskData
  );

  const [isOpen, setIsOpen] = useState(Array(taskData.length).fill(false)); // Array to store open/close state of each card

  const handleOpen = (index) => {
    setIsOpen((prevState) => {
      console.log("Opening drawer:", index);
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleClose = (index) => {
    console.log("Closing drawer:", index);
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
    console.log("isOpen state:", isOpen);
  };

  const getPriorityButtonColor = (priority) => {
    switch (priority) {
      case "To-Do":
        return "#DB7093";
      case "In Progress":
        return "rgb(231 130 12)";
      case "Completed":
        return "#98FB98";
      default:
        return "";
    }
  };

  const getLabelButtonColor = (label) => {
    switch (label) {
      case "Urgent":
        return "#A45A52";
      case "High":
        return "rgb(12 164 231)";
      case "Low":
        return "rgb(231 130 12)";
      default:
        return "";
    }
  };

  const getPriorityButtonBorderColor = (priority) => {
    switch (priority) {
      case "To-Do":
        return "1px solid #DB7093";
      case "In Progress":
        return "1px solid rgb(231 130 12)";
      case "Completed":
        return "1px solid #98FB98";
      default:
        return "1px solid transparent";
    }
  };

  const getLabelButtonBorderColor = (label) => {
    switch (label) {
      case "Urgent":
        return "1px solid #A45A52";
      case "High":
        return "1px solid rgb(12 164 231)";
      case "Low":
        return "1px solid rgb(231 130 12)";
      default:
        return "1px solid transparent";
    }
  };

  return (
    <div>
      {taskData?.map(
        ({
          assign_to,
          due_time,
          label,
          priority,
          task_id,
          task,
          farm,
          end_date,
        }) => (
          <div
            style={{
              marginTop: "2%",
              marginLeft: "0%",
              marginBottom: "2%",
              marginRight: "1%",
              height: "170px",
              border: getLabelButtonBorderColor(label),
              borderRadius: "10px", // Adjust the value as per your preference
              overflow: "hidden", // Ensure the content stays within the curved border
            }}
            key={task_id}
          >
            <Card
              key={task_id}
              style={{ height: "202px" }}
              sx={{
                flex: "1",
                width: "100%",
                backgroundColor: "#ecf4f0",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "#f5faf8",
                },
              }}
            >
              <CardHeader
                avatar={
                  <div>
                    <Stack direction="row" spacing={0.7}>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={
                          <CheckCircleIcon style={{ fontSize: "12px" }} />
                        }
                        style={{
                          color: getPriorityButtonColor(priority),
                          border: getPriorityButtonBorderColor(priority),
                        }}
                      >
                        {priority.length > 5
                          ? `${priority.slice(0, 5)}...`
                          : priority}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={
                          <AccessTimeIcon style={{ fontSize: "12px" }} />
                        }
                        sx={{ color: "#4682B4" }}
                      >
                        {moment(due_time, "HH:mm:ss")?.format("HH:mm")}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          color: getLabelButtonColor(label),
                          border: getLabelButtonBorderColor(label),
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            marginRight: "4px",
                          }}
                        >
                          !
                        </span>
                        {label}
                      </Button>
                    </Stack>
                  </div>
                }
              />

              {isOpen[task_id] && (
                <SideDrawer
                  isOpen={isOpen[task_id]}
                  onClose={() => handleClose(task_id)}
                  details={taskData}
                  task_id={task_id}
                  fetchTaskData={fetchTaskData}
                />
              )}

              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography component="div" variant="h4">
                          {task}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Tooltip title="End By:" placement="top-end">
                          <Button
                            variant="outlined"
                            startIcon={<EventIcon />}
                            color="success"
                            size="small"
                          >
                            {moment(end_date)?.format("DD/MM/YY")}
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions disableSpacing>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    startIcon={<PersonIcon />}
                    size="small"
                    color="success"
                    style={{ pointerEvents: "none" }}
                  >
                    {assign_to && assign_to?.length > 4
                      ? `${assign_to?.slice(0, 4)}...`
                      : assign_to}
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<LocalFloristOutlinedIcon />}
                    size="small"
                    color="success"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",

                      maxWidth: "100%",
                      pointerEvents: "none",
                    }}
                  >
                    <Typography>
                      {farm.length > 4 ? `${farm.slice(0, 4)}...` : farm}
                    </Typography>
                  </Button>
                </Stack>
                <Tooltip title="View More" placement="top">
                  <Button
                    variant="text"
                    aria-label="show more"
                    endIcon={
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: 2 }} />
                    }
                    sx={{
                      fontSize: "8px",
                      marginLeft: "12px",
                    }}
                    onClick={() => handleOpen(task_id)}
                  ></Button>
                </Tooltip>
              </CardActions>
            </Card>
          </div>
        )
      )}
    </div>
  );
};

const TaskMainPage = () => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  // console.log("Detailssssssssssssssssssssssssssssssss---->>>>", userDetails);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCropTableDialogOpen, setIsCropTableDialogOpen] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const handleCropTableOpenDialog = () => {
    setIsCropTableDialogOpen(true);
  };

  const handleCropTableCloseDialog = () => {
    setIsCropTableDialogOpen(false);
    fetchTaskData();
  };

  useEffect(() => {
    fetchTaskData();
  }, [dataAvailable]);
  const fetchTaskData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_TASK_MANAGEMENT_TASKDATA,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("ALL CARDS GET API HIT");
      const data = await response?.data;
      const count = data?.rowCount;
      console.log("count here !!!", count);

      if (count === 0) {
        // Set your state here to true, for example:
        setDataAvailable(true);
      } else {
        setTaskData(data?.rows);
        setDataAvailable(false);
        // Set your state here to false, for example:
      }
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };
  console.log("data ", taskData);

  const [taskMainPageState, setTaskMainPageState] = useState({
    toDo: false,
    inProgress: false,
    urgent: false,
    high: false,
    low: false,
    ascending: false,
    descending: false,
  });
  const { toDo, inProgress, urgent, high, low, ascending, descending } =
    taskMainPageState;

  const [openCard, setOpenCard] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleToDoClick = () => {
    setTaskMainPageState((prevState) => ({
      ...prevState,
      toDo: !prevState.toDo,
      inProgress: false,
    }));
  };

  const handleInProgressClick = () => {
    setTaskMainPageState((prevState) => ({
      ...prevState,
      inProgress: !prevState.inProgress,
      toDo: false,
    }));
  };

  const handleAscendingClick = () => {
    setTaskMainPageState((prevState) => ({
      ...prevState,
      ascending: !prevState.ascending,
      descending: false,
    }));
  };

  const handleDescendingClick = () => {
    setTaskMainPageState((prevState) => ({
      ...prevState,
      descending: !prevState.descending,
      ascending: false,
    }));
  };

  const handleUrgentClick = () => {
    setTaskMainPageState((prevState) => ({
      ...prevState,
      urgent: !prevState.urgent,
      high: false,
      low: false,
    }));
  };

  const handleHighClick = () => {
    setTaskMainPageState((prevState) => ({
      ...prevState,
      high: !prevState.high,
      urgent: false,
      low: false,
    }));
  };

  const handleLowClick = () => {
    setTaskMainPageState((prevState) => ({
      ...prevState,
      low: !prevState.low,
      urgent: false,
      high: false,
    }));
  };

  const handleCardClick = () => {
    setOpenCard(!openCard);
    setExpanded(!expanded);
  };

  const compareTimes = (time1, time2) => {
    const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
    const [hours2, minutes2, seconds2] = time2.split(":").map(Number);

    if (hours1 !== hours2) {
      return hours1 - hours2;
    } else if (minutes1 !== minutes2) {
      return minutes1 - minutes2;
    } else {
      return seconds1 - seconds2;
    }
  };

  const filteredData = taskData
    ?.filter((task) => {
      // Check if no filters are selected
      if (!toDo && !inProgress && !urgent && !high && !low) {
        return true;
      }

      // Apply filtering conditions based on your criteria
      const priorityMatch =
        (!toDo || task.priority === "To-Do") &&
        (!inProgress || task.priority === "In Progress");

      const labelMatch =
        (!urgent || task.label === "Urgent") &&
        (!high || task.label === "High") &&
        (!low || task.label === "Low");

      return priorityMatch && labelMatch;
    })
    .sort((a, b) => {
      // Sort the filtered data based on time
      if (ascending) {
        return compareTimes(a.due_time, b.due_time);
      } else if (descending) {
        return compareTimes(b.due_time, a.due_time);
      } else {
        return 0;
      }
    });

  return (
    <Box m="1px" sx={{ height: "93vh" }}>
      <Typography
        variant="h1"
        color={colors.green.DEFAULT}
        style={{
          marginLeft: "1%",
          marginTop: "0.8%",
        }}
      >
        Task Management
      </Typography>

      <Box
        display="grid"
        gridTemplateColumns="11fr 4fr"
        height="88%"
        gap="12px"
        marginLeft="0%"
        marginTop="2.4%"
        gridAutoRows="100%"
        marginRight="-0.4%"
        marginBottom="4%"
      >
        <Box
          backgroundColor={colors.white[50]}
          display="flex"
          flexDirection="column"
          gridAutoRows="100%"
        >
          <Scheduler fetchTaskData={fetchTaskData} taskData={taskData} />
        </Box>
        <Box
          sx={{ overflowY: "scroll" }}
          backgroundColor={colors.white[50]}
          display="flex"
          flexDirection="column"
          height="100%"
          marginLeft="0px"
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            sx={{ width: "calc(100%)", padding: "14px", marginLeft: "0px" }}
          >
            <Grid item xs={12} sm={7}>
              {userDetails?.task_b_ViewCropData && (
                <Button
                  variant="outlined"
                  onClick={handleCropTableOpenDialog}
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontWeight: "normal",
                    marginLeft: "1px",
                    textTransform: "none",
                    color: "#2e7d32",
                    borderColor: "#2e7d32",
                  }}
                  startIcon={
                    <TableRowsIcon
                      sx={{ marginLeft: "-8px", marginRight: "-5px" }}
                    />
                  }
                >
                  View Crop Data
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={5} spacing={1}>
              {taskData?.length > 0 && (
                <Button
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "7px",
                    fontWeight: "normal",
                    textTransform: "none",
                    color: expanded ? "#2e7d32" : "#D8D8D8",
                    borderColor: expanded ? "#2e7d32" : "#D8D8D8",
                  }}
                  startIcon={
                    <FilterAltIcon
                      sx={{ marginLeft: "-8px", marginRight: "-8px" }}
                    />
                  }
                  onClick={handleCardClick}
                  variant="outlined"
                >
                  Add Filter
                </Button>
              )}
            </Grid>
            <Grid item>
              {openCard && (
                <Grid>
                  <Card
                    sx={{
                      width: "98%",
                      maxWidth: 345,
                      marginBottom: "10px",
                      fontWeight: "normal",
                      textTransform: "none",
                      border: "1px solid #3da58a",
                    }}
                  >
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                          sx={{ color: "#888888" }}
                        >
                          By Priority:
                        </Typography>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={12} sm={4} marginTop={"0px"}>
                            <Button
                              size="small"
                              sx={{
                                fontWeight: "normal",
                                textTransform: "none",
                                color: urgent ? "#A45A52" : "inherit",
                              }}
                              startIcon={<NotificationsActiveIcon />}
                              onClick={handleUrgentClick}
                            >
                              Urgent
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={4} marginTop={"0px"}>
                            <Button
                              size="small"
                              sx={{
                                fontWeight: "normal",
                                textTransform: "none",
                                color: high ? "rgb(12 164 231)" : "inherit",
                              }}
                              startIcon={<PriorityHighIcon />}
                              onClick={handleHighClick}
                            >
                              High
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={4} marginTop={"0px"}>
                            <Button
                              size="small"
                              sx={{
                                fontWeight: "normal",
                                textTransform: "none",
                                color: low ? "rgb(231 130 12)" : "inherit",
                              }}
                              startIcon={<LowPriorityIcon />}
                              onClick={handleLowClick}
                            >
                              Low
                            </Button>
                          </Grid>
                        </Grid>

                        <Divider
                          sx={{
                            borderBottom: "1px solid #000000", // Adjust the border color as needed
                            margin: "6px 0", // Adjust the margin as needed
                          }}
                        />

                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                          sx={{ color: "#888888" }}
                        >
                          By Label:
                        </Typography>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={6} marginTop={"0px"}>
                            <Button
                              size="small"
                              sx={{
                                fontWeight: "normal",
                                textTransform: "none",
                                color: toDo ? "#DB7093" : "inherit",
                              }}
                              startIcon={<CheckCircleOutlineIcon />}
                              onClick={handleToDoClick}
                            >
                              To-Do
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} marginTop={"0px"}>
                            <Button
                              size="small"
                              sx={{
                                fontWeight: "normal",
                                textTransform: "none",
                                color: inProgress
                                  ? "rgb(231 130 12)"
                                  : "inherit",
                              }}
                              startIcon={<TimerIcon />}
                              onClick={handleInProgressClick}
                            >
                              In Progress
                            </Button>
                          </Grid>
                        </Grid>

                        <Divider
                          sx={{
                            borderBottom: "1px solid #000000", // Adjust the border color as needed
                            margin: "6px 0", // Adjust the margin as needed
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                          sx={{ color: "#888888" }}
                        >
                          By Time:
                        </Typography>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={6} marginTop={"0px"}>
                            <Button
                              size="small"
                              sx={{
                                fontWeight: "normal",
                                textTransform: "none",
                                color: ascending ? "#2ECC71" : "inherit",
                              }}
                              startIcon={<ArrowUpwardIcon />}
                              onClick={handleAscendingClick}
                            >
                              Ascending
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} marginTop={"0px"}>
                            <Button
                              size="small"
                              sx={{
                                fontWeight: "normal",
                                textTransform: "none",
                                color: descending ? "#E74C3C" : "inherit",
                              }}
                              startIcon={<ArrowDownwardIcon />}
                              onClick={handleDescendingClick}
                            >
                              Descending
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              )}

              {dataAvailable ? (
                <div>
                  <Typography variant="h3" color={colors.black[100]}>
                    No task scheduled yet.
                  </Typography>
                </div>
              ) : (
                <AllCards
                  taskData={filteredData}
                  fetchTaskData={fetchTaskData}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box m="20px">
        <Dialog
          open={isCropTableDialogOpen}
          onClose={handleCropTableCloseDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            <Typography
              variant="h1"
              style={{ marginLeft: "0.1%" }}
              color={colors.green.DEFAULT}
            >
              Crop Data
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box m="0px 0 0 0" sx={{ marginBottom: "20px" }}>
              <Box>
                <CropDataGrid />
              </Box>
            </Box>
          </DialogContent>
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "1.0%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleCropTableCloseDialog}
            >
              Close
            </Button>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default TaskMainPage;
