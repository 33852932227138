import React from "react";
import { Typography, Box, Stack, useTheme, Grid } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Divider from "@mui/material/Divider";
import { tokens } from "../WMStheme";
const CardBox = ({ title, subtitle, title2 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  return (
    <Box mb="1px">
      <Typography
        variant="h4"
        color={colors.black.DEFAULT}
        fontWeight={typography.h1}
        fontFamily="Lexend Deca"
        sx={{ m: "0 0 0px 0" }}
        width="103%"
        textAlign="center"
        margin="-34px 30px 9px -5px"
      >
        {subtitle}
      </Typography>
      <Typography
        variant="h6"
        textAlign="center"
        marginTop="6px"
        color={colors.black[200]}
      >
        {title}
      </Typography>
      <Typography
        fontSize={"8px"}
        textAlign="center"
        marginTop="1px"
        color={colors.black[200]}
      >
        {title2}
      </Typography>
    </Box>
  );
};

const POStatContainer = ({ statData }) => {
  // console.log("statData in AnalyticsStatContainer", statData);
  return (
    <Stack direction="row" spacing={1}>
      <Box
        backgroundColor="#ffffff"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="63px"
        borderRadius="8px"
        width="100%"
      >
        <Grid container spacing={1}>
          <Grid item xs={1.5} sm={1.5} md={1.5}>
            <CardBox
              title="Pending State"
              subtitle={statData?.pending_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />
          <Grid item xs={1.5} sm={1.5} md={1.5}>
            <CardBox
              title="Release State"
              subtitle={statData?.release_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />
          <Grid item xs={2} sm={2} md={2}>
            <CardBox
              title="Pending For Receiving State"
              subtitle={statData?.pending_for_receiving_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />
          <Grid item xs={2} sm={2} md={2}>
            <CardBox
              title="Receiving Done State"
              subtitle={statData?.receiving_done_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />
          <Grid item xs={2} sm={2} md={2}>
            <CardBox
              title="GRN Confirmation Pending State"
              subtitle={statData?.grn_confirmation_pending_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />
          <Grid item xs={1.5} sm={1.5} md={1.5}>
            <CardBox
              title="Sent To Zoho State"
              subtitle={statData?.sent_to_zoho_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "rgba(0, 0, 0, 10)",
              marginTop: "15px",
              width: "1px",
            }}
          />
          <Grid item xs={1} sm={1} md={1}>
            <CardBox
              title="Cancel State"
              subtitle={statData?.cancel_count || "0"}
              title2="(per selected filter)"
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default POStatContainer;
