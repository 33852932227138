import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Stack,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Typography,
  FormHelperText,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BorderColor, ErrorOutlineOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ReactQuill from "react-quill";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";

import axios from "axios";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const ElectricityDialog = ({
  dialogOpen,
  setDialogOpen,
  mandatoryFields,
  fetchTaskData,
  listOfZones,
  closeMasterDialog,
}) => {
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const selectedPurchaseDate = new Date().toISOString().split("T")[0];
  const selectedStartDate = new Date().toISOString().split("T")[0];
  const selectedEndDate = new Date().toISOString().split("T")[0];
  const [electricityDialogState, setElectricityDialogState] = useState({
    meter_number: "",
    selectedColor: "#3174ad",
    selectedColorDescription: null,
    description: "",
    startLocation: "",
    endLocation: "",
    showPopup: false,
    open: false,
    savedText: "",
    showAttachment: false,
    showAddDescription: true,
    selectedProofs: [],
    selectedFields: [],
    descriptions: [],
    dataTypes: [],
    amount: 0,
    labor: 0,
    paid_amount: 0,
    due_amount: 0,
    payment_date: selectedPurchaseDate,
    payment_method: "",
    location: "",
    start_date: selectedStartDate,
    end_date: selectedEndDate,
    proof_url: "",
  });
  const {
    meter_number,
    selectedColor,
    selectedColorDescription,
    description,
    showPopup,
    selectedProofs,
    savedText,
    descriptions,
    showAttachment,
    showAddDescription,
    dataTypes,
    startLocation,
    endLocation,
    amount,
    labor,
    paid_amount,
    due_amount,
    payment_date,
    payment_method,
    location,
    start_date,
    end_date,
    proof_url,
  } = electricityDialogState;
  console.log("mandatoryFields", mandatoryFields);
  const hiddenToken = localStorage.getItem("token");
  const moment = require("moment");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const updateState = (key, value) => {
    setElectricityDialogState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [proofDetailsAdded, setProofDetailsAdded] = useState(false);
  const handleSubmit = async (e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 1000);
    console.log("entered !");
    e.preventDefault();
    const formData = {
      optional_fields: {
        activity_name: "",
        priority_color: selectedColor,
        priority_color_description: selectedColorDescription,
        start_location: startLocation,
        end_location: endLocation,
        proof: selectedProofs?.length > 0 ? selectedProofs : null, // Set value based on the selectedProofs array
        instruction: description,
        amount: amount,
        labor: labor,
        meter_number: meter_number,
        paid_amount: paid_amount,
        due_amount: due_amount,
        payment_date: moment(payment_date, "YYYY/MM/DD").valueOf(),
        payment_method: payment_method,
        location: location,
        start_date: moment(start_date, "YYYY/MM/DD").valueOf(),
        end_date: moment(end_date, "YYYY/MM/DD").valueOf(),
        proof_url: proof_url,
      },
      mandatory_fields: mandatoryFields,
    };
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_FINANCE_PLANNING,
        formData,
        {
          signal, // Pass the signal to the fetch request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      clearTimeout(timeoutId); // Clear the timeout if the response is received within the specified time

      if (response.status >= 200 && response.status < 305) {
        console.log("Purchase Task Allocated");
        updateState("showPopup", true);
      } else {
        setApiErrorOpen(true);
      }
    } catch (error) {
      setApiErrorOpen(true);
      clearTimeout(timeoutId); // Clear the timeout if an error occurs
      setApiErrorMessage(error.response.data.msg);
    }

    e.target.reset();
  };

  const handlePopupClose = () => {
    updateState("open", false);
    updateState("showPopup", false);
    closeMasterDialog();
    setDialogOpen(false);
    fetchTaskData();
  };

  const handleStartDateChange = async (event) => {
    updateState("start_date", event.target.value);
  };
  const handleEndDateChange = async (event) => {
    updateState("end_date", event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    updateState("payment_method", event.target.value);
  };

  const handleSelectedColorDescriptionChange = async (e) => {
    const selectedColorDescription = e.target.value;
    updateState("selectedColorDescription", selectedColorDescription);
  };

  const handleLocationChange = (event) => {
    updateState("location", event.target.value);
  };

  const handlePaymentDateChange = async (event) => {
    updateState("payment_date", event.target.value);
  };

  const handleMeterNumberChange = (event) => {
    updateState("meter_number", event.target.value);
  };

  const handleColorChange = (event) => {
    updateState("selectedColor", event.target.value);
  };

  const handleDescriptionChange = (e, index) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = e.target.value;
    updateState("descriptions", updatedDescriptions);
  };

  const handleDataTypeChange = (e) => {
    updateState("dataTypes", [e.target.value]);
  };

  const handleSave = () => {
    const proofs = descriptions.map((desc, index) => {
      const attachmentType = dataTypes[index] ? `${dataTypes[index]}` : "";
      setProofDetailsAdded(true);
      return { description: desc, attachmentType };
    });
    updateState("selectedProofs", proofs); // Update selectedProofs here
    updateState(
      "savedText",
      proofs
        .map((proof) => `${proof.description} | ${proof.attachmentType}`)
        .join("\n")
    );
    updateState("descriptions", []);
    updateState("dataTypes", []);
    updateState("showAddDescription", true);
    updateState("showAttachment", false);
  };

  const handleAddDescription = () => {
    updateState("showAddDescription", false);
    updateState("showAttachment", true);
    updateState("descriptions", [...descriptions, ""]);
  };

  const modules = {
    toolbar: {
      container: [[{ list: "ordered" }]],
    },
  };

  const formats = ["list"];

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { minWidth: "480px", minHeight: "480px" } }}
      >
        <DialogTitle>
          <Typography variant="h3" color={colors.green.DEFAULT}>
            Electricity
          </Typography>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} marginTop={"15px"}>
                <TextField
                  label="Meter Number"
                  value={meter_number}
                  onChange={handleMeterNumberChange}
                  fullWidth
                  size="small"
                  autoFocus
                  required
                  inputProps={{ maxLength: 10 }}
                  // style={{ marginBottom: "16px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
                <TextField
                  label="Location"
                  value={location}
                  onChange={handleLocationChange}
                  fullWidth
                  size="small"
                  autoFocus
                  required
                  inputProps={{ maxLength: 20 }}
                  // style={{ marginBottom: "16px" }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <TextField
                    label="Paid Amount"
                    name="Paid Amount"
                    value={paid_amount}
                    type="number"
                    size="small"
                    onChange={(event) => {
                      const value = parseInt(event.target.value);
                      if (!isNaN(value) && value > 100000) {
                        updateState("paid_amount", 100000);
                      } else {
                        updateState("paid_amount", value);
                      }
                    }}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 100000,
                      inputMode: "numeric",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <TextField
                    label="Due Amount"
                    name="Due Amount"
                    value={due_amount}
                    type="number"
                    size="small"
                    onChange={(event) => {
                      const value = parseInt(event.target.value);
                      if (!isNaN(value) && value > 100000) {
                        updateState("due_amount", 100000);
                      } else {
                        updateState("due_amount", value);
                      }
                    }}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      min: 0,
                      max: 100000,
                      inputMode: "numeric",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <TextField
                    label="Payment Date"
                    type="date"
                    size="small"
                    value={payment_date}
                    fullWidth
                    onChange={handlePaymentDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "5px" }}>
                <FormControl fullWidth>
                  <TextField
                    select
                    fullWidth
                    label="Select Payment Method"
                    value={payment_method}
                    onChange={handlePaymentMethodChange}
                    margin="normal"
                    size="small"
                    displayEmpty
                    inputProps={{ "arial-label": "Select Activity" }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: "100px", // Set the maximum height of the dropdown menu
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem key="Cash" value="Cash">
                      Cash
                    </MenuItem>
                    <MenuItem key="UPI" value="UPI">
                      UPI
                    </MenuItem>
                    <MenuItem key="Card" value="Card">
                      Card
                    </MenuItem>
                    <MenuItem disabled value="">
                      Select Payment Method
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ marginTop: "0px" }}
            >
              <Grid item xs={12} sm={2.5} style={{ marginTop: "10px" }}>
                <Typography
                  variant="h4"
                  color={colors.black[200]}
                  fontFamily="Lexend Deca"
                >
                  Billing Period:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4.75} style={{ marginTop: "10px" }}>
                <TextField
                  label="Start Date"
                  type="date"
                  size="small"
                  value={start_date}
                  fullWidth
                  onChange={handleStartDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: new Date().toISOString().split("T")[0],
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4.75} style={{ marginTop: "10px" }}>
                <TextField
                  label="End Date"
                  type="date"
                  size="small"
                  fullWidth
                  value={end_date}
                  onChange={handleEndDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: start_date,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                {descriptions?.map((description, index) => (
                  <TextField
                    key={index}
                    label="Proof title"
                    fullWidth
                    size="small"
                    margin="normal"
                    value={description}
                    inputProps={{ maxLength: 15 }}
                    onChange={(e) => handleDescriptionChange(e, index)}
                  />
                ))}
                {proofDetailsAdded ? (
                  <>
                    <Tooltip
                      title="Proof Type already added !"
                      placement="right"
                      disableHoverListener={!proofDetailsAdded}
                    >
                      <Button
                        variant="outlined"
                        endIcon={
                          <AddIcon style={{ color: colors.green.DEFAULT }} />
                        }
                        sx={{
                          borderColor: colors.green.DEFAULT,
                          color: colors.green.DEFAULT,
                        }}
                        onClick={handleAddDescription}
                        disabled
                      >
                        <Typography variant="h6" color={colors.green.DEFAULT}>
                          Proof List
                        </Typography>
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  showAddDescription && (
                    <Tooltip
                      title="Click to add the attachments that supervisor needs to send as a proof of completion of task !"
                      placement="right"
                    >
                      <Button
                        variant="outlined"
                        endIcon={
                          <AddIcon style={{ color: colors.green.DEFAULT }} />
                        }
                        sx={{
                          borderColor: colors.green.DEFAULT,
                          color: colors.green.DEFAULT,
                        }}
                        onClick={handleAddDescription}
                      >
                        <Typography variant="h6" color={colors.green.DEFAULT}>
                          Proof List
                        </Typography>
                      </Button>
                    </Tooltip>
                  )
                )}
              </Grid>

              {showAttachment && (
                <>
                  <Grid item xs={12} sm={4} style={{ marginTop: "10px" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Select Proof type</InputLabel>
                      <Select
                        value={dataTypes[0] || ""}
                        label="Select Proof type"
                        inputProps={{ "aria-label": "Select Proof Type" }}
                        displayEmpty
                        size="small"
                        onChange={handleDataTypeChange}
                        startAdornment={
                          <InputAdornment position="start"></InputAdornment>
                        }
                      >
                        <MenuItem value="PDF">PDF</MenuItem>
                        <MenuItem value="DOC">DOC</MenuItem>
                        <MenuItem value="Image">Image</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={1.2}>
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: colors.green.DEFAULT,
                        color: colors.green.DEFAULT,
                      }}
                      style={{
                        float: "left",
                        marginLeft: "16px",
                        marginTop: "19px",
                      }}
                      onClick={handleSave}
                    >
                      <Typography variant="h6" color={colors.green.DEFAULT}>
                        Save
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
                <ReactQuill
                  style={{
                    height: "100px",
                    width: "100%",
                  }}
                  placeholder="Additional instructions for this activity!"
                  modules={modules}
                  formats={formats}
                  value={description} // Add value prop
                  onChange={(value) => updateState("description", value)} // Update description state
                />
              </Grid>
              {savedText && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginTop: "-48%",
                    marginLeft: "52%",
                    backgroundColor: "#ecf4f0",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    # {savedText}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="flex-start"
            ></Grid>

            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={12} style={{ marginTop: "60px" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  style={{
                    height: "32px",
                    marginTop: "2%",
                    marginRight: "0%",
                    marginLeft: "69%",
                  }}
                >
                  <Button
                    type="submit" // Added type="submit" to trigger form submission
                    variant="contained"
                    sx={{ backgroundColor: colors.green.DEFAULT }}
                    style={{ float: "left" }}
                  >
                    <Typography variant="h6" color={colors.white.DEFAULT}>
                      Create
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => setDialogOpen(false)}
                    variant="outlined"
                    style={{ float: "left", marginLeft: "15px" }}
                  >
                    <Typography variant="h6" color={colors.green.DEFAULT}>
                      Cancel
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
          <Dialog open={apiErrorOpen} onClose={() => setApiErrorOpen(false)}>
            <DialogTitle style={{ textAlign: "center" }}>
              <ErrorOutlineOutlined
                style={{ width: "60px", height: "60px", color: "#A45A52" }}
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              {apiErrorMessage ? (
                <Typography
                  variant="h4"
                  color={colors.red[100]}
                  fontFamily="Lexend Deca"
                >
                  {apiErrorMessage}
                </Typography>
              ) : (
                <Typography
                  variant="h4"
                  color={colors.red[100]}
                  fontFamily="Lexend Deca"
                >
                  An error occurred, please try again
                </Typography>
              )}
            </DialogContent>
            <Button
              onClick={() => {
                setApiErrorOpen(false);
              }}
            >
              <Typography
                variant="h4"
                color={colors.red[100]}
                fontFamily="Lexend Deca"
              >
                OK
              </Typography>
            </Button>
          </Dialog>

          <Dialog open={showPopup} onClose={() => setDialogOpen(false)}>
            <DialogTitle style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ width: "60px", height: "60px", color: "#2C7B55" }}
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                color={colors.green.DEFAULT}
                // fontWeight={typography.h1}
                fontFamily="Lexend Deca"
              >
                New activity added!
              </Typography>
            </DialogContent>
            <Button onClick={handlePopupClose}>
              <Typography
                variant="h4"
                color={colors.green.DEFAULT}
                // fontWeight={typography.h1}
                fontFamily="Lexend Deca"
              >
                OK
              </Typography>
            </Button>
          </Dialog>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ElectricityDialog;
