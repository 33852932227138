import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Stack,
  FormControlLabel,
  Radio,
  useTheme,
  RadioGroup,
  FormControl,
  InputAdornment,
  IconButton,
  Alert,
  Slide,
} from "@mui/material";

import { tokens } from "./WMStheme";
import ErrorPopUp from "./PopUpMsg/ErrorPopUp";
import logoImage from "../Assets/Logo/NewLogo.png";
import aws from "../Assets/aws.png";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Popover from "@mui/material/Popover";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { AlertDialogBox } from "../../src/WMS/PopUpMsg/AlertDialog";
import { API_ENDPOINTS } from "../ApiEndpoint/ApiEndpoint";
import "./WMSLoginPage.css"; // Add your custom CSS for styling
import FamboLogo from "../Assets/LogoFambo.png";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const WMSLoginPage = ({loginComplete,setLoginComplete}) => {
  console.log("WMSLoginPage is in progress");
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [username, setusername] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [password, setPassword] = useState("");
  const [usernameError, setusernameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [usernameCheckError, setusernameCheckError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("customer");
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const clearUserRole = async () => {
    // Implement your logic to clear user role here
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const validateusername = (username) => {
    // Implement your username validation logic here
  };

  const showTooltip = (event) => {
    setAnchorEl(event.currentTarget);
    setTooltipVisible(true);
  };
  const handleLogin = async () => {
    const formData = {
      username,
      password,
    };

    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_WMS_LOGIN,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response?.data?.Data?.loggedIn) {
        const { user_id, token, name, warehouse ,exp ,user_access} = response?.data?.Data;
  
      // Decode the token to extract exp and iat
    
        localStorage.setItem("token", token);
        localStorage.setItem("name", name);
        localStorage.setItem("user-id", user_id);
        localStorage.setItem("exp", exp);
       

        // Check if the token is still valid
      const currentTime = Date.now();
      console.log("current time", currentTime);
     
      console.log("expiration time", exp);

       // Convert to seconds
       if (currentTime <= exp) {
      console.log("current time", currentTime);
      console.log("expiration time", exp);
        if (warehouse !== null && warehouse !== undefined) {
          
          localStorage.setItem("warehouse_data", JSON?.stringify(warehouse));
          // Convert the first element of the array to a string before storing in localStorage
localStorage.setItem("user_access", JSON.stringify(user_access[0]));
console.log("user_access in login ", user_access);

        } else {
          localStorage.setItem("warehouse_data", null);
        }

        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        setLoginComplete(true);
        navigate("/home");
  

      } 
      else {
        // Token has expired, redirect to login page
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("user-id");
        localStorage.removeItem("exp");
        localStorage.removeItem("warehouse_data");
        localStorage.removeItem("user_access");
        navigate("/");
        setErrorPopupOpen(true);
        setErrorMessage("Session expired. Please log in again.");
    
        // Optionally, you can redirect to the login page programmatically
        
      }
    }
      else if (response.status === 400) {
        console.log("Response for 400 status:", response);
        setErrorPopupOpen(true);
        setErrorMessage(response?.data?.msg || "Invalid credentials");
      } else {
        setErrorPopupOpen(true);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error in Login", error);

      setErrorPopupOpen(true);
      setErrorMessage("Invalid Credentials. Please try again.");
    }
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  const handleDialogClose = () => {
    setErrorPopupOpen(false);
  };
  const getButtonColor = () => {
    return selectedOption === "sales" ? "green" : "blue";
  };
  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "48vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };
  return (
    <>
      {/* <AlertDialogBox
        open={errorPopupOpen}
        okayText="Proceed"
        handleOkayClick={() => setErrorPopupOpen(false)}
        onClose={() => setErrorPopupOpen(false)}
        content={errorMessage}
      /> */}
      <div className="login-page3" style={{ backdropFilter: "blur(5px)" }}>
        {loading ? (
          <>
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={loading} />
            </div>
          </>
        ) : (
          <Grid
            container
            sx={{ backgroundColor: "#ffffffcf", width: "70%", height: "70%" }}
          >
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "-5%",
                  marginLeft: "-12%",
                }}
              >
                <img
                  src={logoImage}
                  alt="Logo"
                  style={{ maxWidth: "60%", maxHeight: "60%" }}
                  onError={(e) => {
                    console.log("prachi", e.target.src); // Display a placeholder image or a fallback
                    // Alternatively, you can handle the error in a custom way (e.g., show an error message)
                  }}
                />
                <Typography variant="h5" color={colors.green.DEFAULT}>
                  Cultivating Transformation
                </Typography>
                <Typography
                  variant="h5"
                  color={colors.green.DEFAULT}
                  sx={{ marginTop: "2%" }}
                >
                  powered by
                </Typography>
                <img
                  src={aws}
                  alt="Powered by AWS"
                  style={{
                    maxWidth: "30%",
                    maxHeight: "30%",
                    marginTop: "-10%",
                    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)",
                  }}
                  onError={(e) => {
                    console.log("prachi", e.target.src);
                  }}
                />
              </Stack>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  width: "88%",
                  height: "70%",
                  padding: "50px",
                  borderRadius: "0%",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                  marginRight: "22%",
                }}
              >
                <div className="overlay">
                  <Typography
                    variant="h2"
                    fontWeight="450"
                    color="#2c677b"
                    gutterBottom
                  >
                    Warehouse Management System
                  </Typography>
                  <Typography
                    variant="h2"
                    color="#2c677b"
                    gutterBottom
                    fontWeight="250"
                    sx={{ marginTop: "3%" }}
                  >
                    Login
                  </Typography>
                  <div style={{ marginTop: "9%" }}>
                    <TextField
                      label="UserName"
                      fullWidth
                      variant="outlined"
                      value={username}
                      onChange={(e) => setusername(e.target.value)}
                    />
                    <div style={{ margin: "8px 0" }}>
                      {/* Add space between username TextField and password TextField */}
                    </div>

                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Password"
                        type={isPasswordVisible ? "text" : "password"}
                        fullWidth
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                                edge="end"
                              >
                                {isPasswordVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <div style={{ margin: "8px 0" }}></div>
                    <div style={{ marginTop: "12%" }}>
                      <Button
                        className={`loginButton ${getButtonColor()}`}
                        fullWidth
                        variant="contained"
                        onClick={handleLogin}
                        sx={{ backgroundColor: "#2c677b" }}
                      >
                        Login
                      </Button>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "12%",
                          marginLeft: "25%",
                        }}
                      >
                        <div>
                          <Typography variant="h5" color="#6B6C6C" gutterBottom>
                            Version 1.0.48
                          </Typography>
                        </div>
                      </Grid>
                    </div>
                    {errorMessage && (
                      <Typography
                        color="error"
                        variant="subtitle1"
                        sx={{ marginTop: "1%" }}
                      >
                        {errorMessage}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default WMSLoginPage;
