import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import NullScreen from "../PopUpMsg/NullScreen";
import { ScaleLoader } from "react-spinners";
import { CiCalendarDate } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import Pagination from "@mui/material/Pagination";
import {
  Grid,
  Stack,
  Button,
  List,
  ListItem,
  ListItemText,
  Select,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  InputLabel,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { ulid } from "ulid";
import { TiTickOutline } from "react-icons/ti";
import { GiFruitBowl } from "react-icons/gi";
import Paper from "@mui/material/Paper";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import { tokens } from "../WMStheme";
import { GrChapterAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import SocketReportPopup from "../../Attendance/HomePage/SocketReportPopup";
import PerformCycleCount from "./PerformCycleCount";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const columns = [
  { id: "cyle_count_no", label: "Cycle Count No" },
  { id: "item_count", label: "Cycle Count(items)" },
  { id: "wh_name", label: "Warehouse" },
  { id: "created_at", label: "Created Date" },

  { id: "created_by_name", label: " Created By" },
];
const columns1 = [
  { id: "name", label: "Name" },
  { id: "code", label: "Code" },
  { id: "from_qty", label: "From Quantity" },
  { id: "to_qty", label: "To Quantity" },

  { id: "reason", label: "Reason" },
];

const gridContainerStyle = {
  // marginTop:"1%",
  display: "flex",
  height: "69vh",
  width: "100%",
  overflowY: "hidden",

  //  backgroundColor: "red",
};
const containerStyle8 = {
  maxHeight: "74vh",
  height: "80%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  //  backgroundColor: "pink",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};
const formatDateIST = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} || ${hours}:${minutes}`;
};
const formatDate = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "Invalid Timestamp";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
function TableHeadComponent() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((column) => (
          <StyledTableCell key={column?.id} align="center">
            {column?.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function TableHeadComponentDialog() {
  return (
    <TableHead>
      <TableRow>
        {columns1?.map((column) => (
          <StyledTableCell key={column?.id} align="center">
            {column?.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function TableRowsComponentDialog({ data1 }) {
  const navigate = useNavigate();

  const theme = useTheme();

  return (
    <>
      <TableBody>
        {/* replace data1 from dummyData  */}
        {data1?.map((row, index) => (
          <StyledTableRow key={index} style={{ cursor: "pointer" }}>
            {columns1?.map((column) => (
              <StyledTableCell key={column?.id} align="center">
                {["created_at", "updated_at"].includes(column.id)
                  ? formatDateIST(row[column.id])
                  : row[column.id]}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </>
  );
}
function TableRowsComponent({
  data1,
  datasending,
  HistoryDialog,
  setHistoryDialog,
  selectedRow,
  setCycleCountId,

  setSelectedRow,
}) {
  const navigate = useNavigate();

  const theme = useTheme();

  const handleRowClick = (row) => {
    // setSelectedRow(row?.details?.cycle_count_id);
    console.log("selected row data in tbalerow function", row?.cycle_count_id);
    // navigate("/addpo");
    setCycleCountId(row?.cycle_count_id);
    //fetchCycleCountHistoryData(row?.details?.cycle_count_id);

    // commented for now , wil update with dialog box
    setHistoryDialog(true);
  };

  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const handleSuccess = (newState) => {
    setApiSuccessOpen(newState);
  };

  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const handleFailure = (newState) => {
    setApiErrorOpen(newState);
  };

  const dialogRef = useRef(null);

  return (
    <>
      <TableBody>
        {/* replace data1 from dummyData  */}
        {data1?.map((row, index) => (
          <StyledTableRow
            key={index}
            onClick={() => handleRowClick(row)}
            style={{ cursor: "pointer" }}
          >
            {columns?.map((column) => (
              <StyledTableCell key={column?.id} align="center">
                {["created_at", "updated_at"].includes(column.id)
                  ? formatDateIST(row[column.id])
                  : row[column.id]}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </>
  );
}

const ListOfCycleCount = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isdailogLoading, setIsDialogLoading] = useState(false);
  const oneWeekAgo = new Date();
  const [skuNames, setSkuNames] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [HistoryDialog, setHistoryDialog] = useState(false);
  const [skuId, setSkuId] = useState(null);
  const [downloadActive, setDownloadActive] = useState(false);
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  //const [inventoryData, setInventoryData] = useState(dummyData);
  const [inventoryData, setInventoryData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [cycleCountId, setCycleCountId] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [warehouseId, setWarehouseData] = useState(null);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [generatedId, setGeneratedId] = useState(null);
  const [userId, setUserId] = useState("");
  const [openPerformCycleCount, setOpenPerformCycleCount] = useState(false);
  // const [selectedCycleData ,setSelectedCycleData] =useState([]);
  const [selectedCycleData, setSelectedCycleData] = useState([]);
  const [filterValues, setFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    limit: 16,
    offset: offset,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    limit: 16,
    offset: offset,
  });
  const [selectedRow, setSelectedRow] = useState(null);
 
  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,
      limit: 16,
      offset: offset,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, offset]);

  useEffect(() => {
    fetchCycleCountData(filterValues);
  }, [filterValues]);

  const handleApplyClick = () => {
    const startDateObj = new Date(temporaryFilterValues.start_date);
    const endDateObj = new Date(temporaryFilterValues.end_date);
    
    // Calculate the difference in days
    const differenceDays = Math.abs((endDateObj.getTime() - startDateObj.getTime()) / (1000 * 60 * 60 * 24));
    
    // Check if the difference is greater than a week
    const isDifferenceGreaterThanAWeek = differenceDays < 15;
    console.log("difference-----.",isDifferenceGreaterThanAWeek);
    // Set downloadActive based on the condition
    setDownloadActive(!isDifferenceGreaterThanAWeek);
    
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      limit: 16,
      offset: offset,
    };
    setStartDate(temporaryFilterValues.start_date);
    setEndDate(temporaryFilterValues.end_date);
    setFilterValues(newFilterValues);
    fetchCycleCountData(newFilterValues);
  };
  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchCycleCountData(updatedValues);
  };
  useEffect(() => {
    setUserId(localStorage.getItem("user-id"));
    console.log("user id in po page ", userId);
  }, [userId]);
  const handleGenerateCycleCount = async () => {
    setIsLoading(true);
    const requestId = ulid();
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_DOWNLOAD_CYCLE_COUNT,
        {
          params: {
            ...filterValues,
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };
  useEffect(() => {
    // Check if cycle_count_id is provided
    if (!cycleCountId) {
      console.error("cycle_count_id is required");
      setErrorMessage("Oops! Something went Wrong!");
      return;
    }

    // Execute the function when cycle_count_id changes
    fetchCycleCountHistoryData(cycleCountId);
  }, [cycleCountId]);
  const fetchCycleCountHistoryData = async (cycleCountId) => {
    setIsDialogLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_DETAIL_OF_CYCLE_COUNT(cycleCountId),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsDialogLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = response?.data?.data;
        console.log("data", data);
        setSelectedCycleData(data);
        // setTotalPages(data?.total_pages);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsDialogLoading(false);
      setErrorMessage("Please Try Again");
      setErrorPopupOpen(true);
    }
  };

  const HistoryDialogBox = ({
    selectedRow,
    historyDialog,
    isdailogLoading,
    setIsDialogLoading,
    setHistoryDialog,
    setSelectedCycleData,
    selectedCycleData,
  }) => {
    const handleCloseHistoryDialog = () => {
      setHistoryDialog(false);
    };
    console.log("sleected cycle data  in history ", selectedCycleData);
    return (
      <Dialog
        open={historyDialog}
        onClose={handleCloseHistoryDialog}
        maxWidth="lg"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          <Typography
            variant="h2"
            style={{ marginLeft: "0.1%" }}
            color="#0C0C0C"
          >
            View Cycle Count History
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseHistoryDialog}
            style={{ position: "absolute", right: "8px", top: "1px" }}
          >
            <IoMdClose style={{ fontSize: "32" }} />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ marginTop: "2%" }}>
          <Grid container spacing={1} style={containerStyle8}>
            {isdailogLoading ? (
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={isdailogLoading} />
              </div>
            ) : selectedCycleData[0]?.cycle_count_item?.length === 0 ? (
              <NullScreen containerheight="74vh" containerWidth="100%" />
            ) : (
              <>
                <div style={gridContainerStyle}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 900 }} aria-label="customized table">
                      <TableHeadComponentDialog />
                      <TableRowsComponentDialog
                        data1={selectedCycleData[0]?.cycle_count_item}
                      />
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const fetchCycleCountData = async (newParams) => {
    console.log("new params", newParams);
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_LIST_OF_PREVIOUS_CYCLE_COUNT,
        {
          params: newParams,

          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data;
        console.log("data", data);
        setTotalPages(data?.total_pages);
        setInventoryData(data?.list);

        setIsLoading(false);
      } else {
        setErrorMessage(response?.data?.msg);
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsLoading(false);
      setErrorMessage("Please Try Again");
      setErrorPopupOpen(true);
    }
  };
  const handleRefreshClick = () => {
    const initialValues = {
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
      limit: 16,
      offset: 1,
    };
    setOffSet(1);
    setSkuId(null);
    setSkuCode(null);
    setWarehouseData(null);
    setDownloadActive(true);
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    fetchCycleCountData(initialValues);
  };

  const handlePerformCycleCountClick = () => {
    navigate("/performcyclecount");
    // setOpenPerformCycleCount(true);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          width: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "10vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2.2} md={2.2}>
                <RangePicker
                  onChange={handleDateChange}
                  // size="large"
                  key={refreshCount}
                  format={dateFormat}
                  defaultValue={[
                    dayjs(formatDate(startDate), dateFormat),
                    dayjs(formatDate(endDate), dateFormat),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!startDate || !endDate}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={5.0} md={5.0}></Grid>
              <Grid item xs={12} sm={1.2} md={1.2}>
                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                disabled = {downloadActive}
                 title="Please select a 15 days interval for downloading."
                  onClick={handleGenerateCycleCount}
                 
                >
                  <Typography variant="h5" color={colors.white[100]}>
                    Download
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={
                    <GrChapterAdd
                      style={{ color: colors.white.DEFAULT, fontSize: "26px" }}
                    />
                  }
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                    color: "#ffff",
                  }}
                  onClick={handlePerformCycleCountClick}
                  disabled={!userDetail?.b_cycle_count_perform_cycle_count}
                >
                  <Typography variant="h5" color={colors.white[100]}>
                    Perform Cycle Count
                  </Typography>
                </Button>
              </Grid>
              {openDialog && (
                <SocketReportPopup
                  setOpenDialog={setOpenDialog}
                  requestId={generatedId}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={containerStyle8}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : inventoryData?.length === 0 ? (
            <NullScreen containerheight="74vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 900 }} aria-label="customized table">
                    <TableHeadComponent />
                    <TableRowsComponent
                      data1={inventoryData}
                      //data1={dummyData}
                      HistoryDialog={HistoryDialog}
                      setHistoryDialog={setHistoryDialog}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                      setCycleCountId={setCycleCountId}
                    />
                  </Table>
                </TableContainer>
              </div>

              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: "5px" }}
              >
                <Pagination
                  count={totalPages}
                  page={offset}
                  onChange={handleChangePage}
                  size="small"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
      {HistoryDialog && (
        <HistoryDialogBox
          selectedRow={selectedRow}
          isDialogLoading={isdailogLoading}
          setIsDialogLoading={setIsDialogLoading}
          selectedCycleData={selectedCycleData}
          setSelectedCycleData={setSelectedCycleData}
          historyDialog={HistoryDialog}
          setHistoryDialog={setHistoryDialog}
        />
      )}
    </>
  );
};

export default ListOfCycleCount;
