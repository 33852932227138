import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Stack,
  FormControlLabel,
  Radio,
  useTheme,
  RadioGroup,
  FormControl,
  InputAdornment,
  IconButton,
  Alert,
  Slide,
} from "@mui/material";
import { tokens } from "../theme";
import logoImage from "../Assets/Logo/NewLogo.png";
import aws from "../Assets/aws.png";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Popover from "@mui/material/Popover";
import axios from "axios";
import { BASE_OMS_URL, API_ENDPOINTS } from "../ApiEndpoint/ApiEndpoint";
import "./Login.css"; // Add your custom CSS for styling
import FamboLogo from "../Assets/LogoFambo.png";
const OMSLoginPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailCheckError, setEmailCheckError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("customer");
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const clearUserRole = async () => {
    // Implement your logic to clear user role here
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async () => {
    const formData = {
      email,
      password,
    };

    try {
      const response = await axios.post(
        `${BASE_OMS_URL}${API_ENDPOINTS.POST_OMS_LOGIN}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("data", formData);
      if (response.status >= 200 && response.status < 305) {
        if (response?.data?.loggedIn) {
          const userRole = response.data.role;
          const user_id = response.data.user_id;
          console.log("role", userRole);
          console.log("id", user_id);
          console.log("dataaaaaaaaaaaaaaaahereeeeeeee", response.data);
          // Assuming you receive a JWT token in the response
          const authToken = response?.data?.token;
          const name = response?.data?.name;

          // Store the token in local storage for future requests
          localStorage.setItem("token", authToken);
          localStorage.setItem("name", name);
          localStorage.setItem("user-id", response?.data?.user_id);
          console.log("selectedOption", selectedOption);
          console.log("user_id", response?.data?.user_id);
          console.log("userRole", userRole);
          // You can also store userRole and user_id in local storage if needed
          localStorage.setItem("userRole", userRole);

          console.log("localstorage", localStorage);
          // Set the Axios default headers for authentication
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${authToken}`;
          // Check if the selectedOption matches userRole before navigating
          if (selectedOption === userRole) {
            switch (userRole) {
              case "admin":
                navigate("/oms/approver");
                break;
              case "normal":
                navigate("/oms/sourcing");
                break;
              default:
                console.log("Unsupported role:", userRole);
                break;
            }
          } else {
            setShowAlert(true);
            // Handle the case where selectedOption does not match userRole
            console.log("Selected option does not match user role");
          }
        } else {
          setEmailError(true);
        }
      } else {
        console.log("Bad response");
      }
    } catch (error) {
      console.error("Error in Login", error);
      setEmailCheckError(true);
    }
  };
  const validateEmail = (email) => {
    // Implement your email validation logic here
  };

  const showTooltip = (event) => {
    setAnchorEl(event.currentTarget);
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  const getButtonColor = () => {
    return selectedOption === "sales" ? "green" : "blue";
  };

  return (
    <>
      <div className="login-page1" style={{ backdropFilter: "blur(5px)" }}>
        <Grid
          container
          sx={{ backgroundColor: "#ffffffcf", width: "70%", height: "90%" }}
        >
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-5%",
                marginLeft: "-12%",
              }}
            >
              <img
                src={logoImage}
                alt="Logo"
                style={{ maxWidth: "60%", maxHeight: "60%" }}
                onError={(e) => {
                  console.log("prachi", e.target.src); // Display a placeholder image or a fallback
                  // Alternatively, you can handle the error in a custom way (e.g., show an error message)
                }}
              />
              <Typography variant="h5" color={colors.green.DEFAULT}>
                Cultivating Transformation
              </Typography>
              <Typography
                variant="h5"
                color={colors.green.DEFAULT}
                sx={{ marginTop: "2%" }}
              >
                powered by
              </Typography>
              <img
                src={aws}
                alt="Powered by AWS"
                style={{
                  maxWidth: "30%",
                  maxHeight: "30%",
                  marginTop: "-10%",
                  boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)",
                }}
                onError={(e) => {
                  console.log("prachi", e.target.src);
                }}
              />
            </Stack>
          </Grid>
          {/* <Divider orientation="vertical" flexItem /> */}
          {/* <Divider variant="middle" /> */}
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "88%",
                height: "80%",
                padding: "50px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                marginRight: "22%",
              }}
            >
              <div className="overlay">
                <Typography variant="h4" color="green" gutterBottom>
                  Login to O.M.S
                </Typography>

                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="user-type"
                    name="user-type"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <FormControlLabel
                      value="normal"
                      control={<Radio color="success" />}
                      label="Executive"
                    />
                    <FormControlLabel
                      value="admin"
                      control={<Radio color="success" />}
                      label="Approver"
                    />
                  </RadioGroup>
                </FormControl>

                <TextField
                  label="Email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div style={{ margin: "8px 0" }}>
                  {/* Add space between email TextField and password TextField */}
                </div>

                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Password"
                    type={isPasswordVisible ? "text" : "password"}
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {isPasswordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <div style={{ margin: "8px 0" }}></div>

                <Button
                  className={`loginButton ${getButtonColor()}`}
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={handleLogin}
                >
                  Login
                </Button>

                {emailError && (
                  <Typography variant="body2" color="error" gutterBottom>
                    Wrong Credentials !!
                  </Typography>
                )}
                {emailCheckError && (
                  <Typography variant="body2" color="error" gutterBottom>
                    Please try again later !
                  </Typography>
                )}
                {showAlert && (
                  <Alert
                    severity="error"
                    onClose={() => setShowAlert(false)}
                    className="alert-slide-in"
                    style={{
                      position: "fixed",
                      top: "8px",

                      zIndex: 1000,
                      transition: "right 0.3s ease-in-out",
                    }}
                  >
                    Please login properly with respect to user role, or try
                    again later.
                  </Alert>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <Popover
        open={isTooltipVisible}
        anchorEl={anchorEl}
        onClose={hideTooltip}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className="tooltipText">
          {/* Tooltip content */}
          {emailError}
        </Typography>
      </Popover>
    </>
  );
};

export default OMSLoginPage;
