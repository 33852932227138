import React from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { TbMoodEmpty } from "react-icons/tb";
import { tokens } from "../WMStheme";
import { useTheme } from "@mui/material";
import { IoIosInformationCircleOutline } from "react-icons/io";

const NullScreen = ({
  containerheight,
  containerWidth,
  screenType,
  message,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const nullScreenContainerStyle = {
    top: 0,
    left: 0,
    display: "flex",
    overflowX: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: containerheight,
    width: containerWidth,
    
    // backgroundColor: "red",
  };

  if (screenType === "Info") {
    return (
      <Box style={nullScreenContainerStyle}>
        <IoIosInformationCircleOutline
          style={{ fontSize: "50px", color: colors.black[200] }}
        />
        <Typography variant="h2" color={colors.black[200]} mt={2}>
          {message}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box style={nullScreenContainerStyle}>
        <TbMoodEmpty
          style={{ fontSize: "50px", color: colors.black[200] }}
        />
        <Typography variant="h2" color={colors.black[200]} mt={2}>
          No Data Found
        </Typography>
      </Box>
    );
  }
};

export default NullScreen;
