import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MailIcon from "@mui/icons-material/Mail";
import SuccessPopUp from "../../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../../PopUpMsg/ErrorPopUp";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import { FaWarehouse } from "react-icons/fa6";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { useSelector, useDispatch } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import AWS from "aws-sdk";
import {
  Button,
  Chip,
  OutlinedInput,
  TextField,
  Stack,
  Checkbox,
  Autocomplete,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// import { useLocation } from '@reach/router';
import { HiCurrencyRupee } from "react-icons/hi2";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  MdModeEdit,
  MdPeopleAlt,
  MdLocationOn,
  MdDescription,
} from "react-icons/md";
import axios from "axios";

import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../WMStheme";
import AddIcon from "@mui/icons-material/Add";
const ITEM_HEIGHT = 48;
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "ap-south-1",
});
const s3 = new AWS.S3();
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};
const PersonalDetails = ({
  vendorDataSet,
  setVendorDataSet,
  setVendorTypeOptions,
  vendorTypeOptions,
  vendorNameError,
  setVendorNameError
}) => {
 
  useEffect(() => {
    fetchVendorTypeOptions();
  }, []);
  const fetchVendorTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_MODULE_VENDOR_VENDORTYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.Data?.map((row) => row?.dropdown_value);
      setVendorTypeOptions(options);
    } catch (error) {
      console.log("Error", error);
      // Handle error
    }
  };
  const handleChange = (field, value) => {
    const updatedValue = field === "active" ? value === "true" : value;

    setVendorDataSet((prevData) => ({
      ...prevData,
      [field]: updatedValue,
    }));
  };
  return (
    <>
      {/* <Typography variant="h6">Personal Details</Typography> */}

      <Grid container spacing={3.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Vendor Name"
            size="small"
            fullWidth
            required
            value={vendorDataSet?.vendor_name}
            onChange={(e) => handleChange("vendor_name", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 65, // Set maximum length to 60 characters
              },
            }}
           
            error={vendorNameError}
            helperText={
              vendorNameError
                ? "SKU Name is required"
                : vendorDataSet?.vendor_name.length > 60
                ? "Cannot enter more than 60 characters"
                : ""
            }
            
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <TextField
            label="Vendor Code"
            size="small"
            fullWidth
            required
            value={vendorDataSet?.vendor_code || ""}
            onChange={(e) => handleChange("vendor_code", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              fullWidth
              required
              size="small"
              value={vendorDataSet?.vendor_type}
              onChange={(e) => handleChange("vendor_type", e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <SupervisedUserCircleIcon />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Select Type
              </MenuItem>

              {vendorTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={6} sx={{ marginTop: "1.4%" }}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              fullWidth
              required
              size="small"
              displayEmpty
              value={vendorDataSet.active ? "true" : "false"}
              onChange={(e) => handleChange("active", e.target.value)}
              inputProps={{ "aria-label": "Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <ToggleOffIcon />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Select Status
              </MenuItem>
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">In-Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={3} sx={{ marginTop: "1.7%" }}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              fullWidth
              required
              size="small"
              value={vendorDataSet?.vendor_type}
              onChange={(e) => handleChange("vendor_type", e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Type" }}
              startAdornment={
                <InputAdornment position="start">
                  <SupervisedUserCircleIcon />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>
                Select Type
              </MenuItem>

              {vendorTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Description"
            name="Description"
            type="Description"
            size="small"
            // value={newUserRoleState?.new_email}
            fullWidth
           
            value={vendorDataSet?.description}
            onChange={(e) => handleChange("description", e.target.value)}
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <DescriptionIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {/* for creating spacing */}
      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const ShippingAddress = ({
  address,
  setAddress,
  vendorData,
  vendorDataSet,
  setVendorDataSet,
  vendorEmailError,
  vendorCountryError, setVendorCountryError,
  vendorCityError, setVendorCityError,
  vendorStateError, setVendorStateError,
vendorPincodeError, setVendorPincodeError,
  vendorMobileNumberError, setVendorMobileNumberError,

  
  setVendorEmailError,
  action,
}) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateiso, setStateIso] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  // useEffect (()=>{
  //   if ( action && vendorData) {

  //     const { country, state , city ,pincode , addressline1, addressline2} = vendorData;

  //   //   setAddress(

  //   //     country: country || 0,
  //   // state:  state || 0,
  //   // city:  city || 0,
  //   // pincode:  pincode || 0,
  //   // addressline1 : addressline1 || '',
  //   // addressline2:  addressline2 || '',
  //   //   );
  //   // }
  // },[action , vendorData , address]);
  useEffect(() => {
    fetchCountryOptions();
  }, []);
  const handleChange = (field, value) => {
    setVendorDataSet((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleChangeShippingAddress = (field, value) => {
    setAddress((prevData) => ({
      ...prevData,

      [field]: value,
    }));
  };

  console.log("address", address);
  const handlePincodeChange = (event) => {
    const pincode = event.target.value;

    handleChangeShippingAddress("pincode", pincode);
    console.log("pincode111111111111111", pincode);
  };

  const handleAddress1Change = (event) => {
    const address1 = event.target.value;
    handleChangeShippingAddress("addressline1", address1);
    console.log("ADDRESS LINE 1", address1);
  };

  const handleAddress2Change = (event) => {
    const address2 = event.target.value;
    handleChangeShippingAddress("addressline2", address2);
    console.log("ADDRESS LINE 2", address2);
  };

  const fetchCountryOptions = async () => {
    try {
      const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
      console.log("SEDRTFGYUHIJOKPL", apiKey);
      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", apiKey);
      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };
      const response = await fetch(
        "https://api.countrystatecity.in/v1/countries",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch countries");
      }

      const data = await response.json();

      setCountryOptions(data);
    } catch (error) {
      setApiErrorOpen(true);
      // Handle error
      console.log("Error fetching countries:", error);
    }
  };
  // const fetchStatesForCountry = async (countryCode) => {
  //   console.log("fetchStatesForCountry", countryCode);
  //   try {
  //     const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
  //     console.log("API KeyPOIUYTRSZXCFGBHJKML,:", apiKey);
  //     var headers = new Headers();
  //     headers.append("X-CSCAPI-KEY", apiKey);
  //     console.log("HEADERSSSSSSSSSSSSSS,:", headers);
  //     var requestOptions = {
  //       method: "GET",
  //       headers: headers,
  //       redirect: "follow",
  //     };
  //     const response = await fetch(
  //       `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
  //       requestOptions
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch states");
  //     }

  //     const data = await response.json(); // Await for the JSON parsing
  //     console.log("Statessssss", data);

  //     setStateOptions(data);
  //   } catch (error) {
  //     setApiErrorOpen(true);
  //     console.log("Error fetching states:", error);
  //   }
  // };

  // const fetchCitiesForCountryAndState = async (countryCode, stateIso) => {
  //   console.log("countryIso", countryCode);
  //   console.log("stateIso", stateIso);
  //   try {
  //     const apiKey = process.env.REACT_APP_COUNTRY_STATE_API_KEY;
  //     const url = `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateIso}/cities`;

  //     var headers = new Headers();
  //     headers.append("X-CSCAPI-KEY", apiKey);
  //     var requestOptions = {
  //       method: "GET",
  //       headers: headers,
  //       redirect: "follow",
  //     };

  //     const response = await fetch(url, requestOptions);

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch cities");
  //     }

  //     const data = await response.json();
  //     console.log("citiesss dataaaa", data);
  //     const cities = data?.map((city) => city?.name);

  //     setCityOptions(cities);
  //     console.log("setCityOptions", cities);
  //   } catch (error) {
  //     setApiErrorOpen(true);
  //     console.log("Error fetching cities:", error);
  //   }
  // };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    console.log("Selected Country:", selectedCountry);
    // Find the country object based on the selected name
    // const selectedCountry = countryOptions.find(country => country.name === selectedCountryName);
    handleChangeShippingAddress("country", selectedCountry);
    if (selectedCountry) {
      // setSelectedCountryCode(selectedCountry.iso2);
      // fetchStatesForCountry(selectedCountry);
    } else {
      console.error("Selected country or iso2 is undefined.");
    }
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    handleChangeShippingAddress("state", selectedState);
    if (selectedState) {
      // setStateIso(selectedState);
      // handleChangeShippingAddress("state", selectedState);
      // fetchCitiesForCountryAndState(address?.country, selectedState);
    } else {
      console.error("Selected state is undefined.");
    }
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;

    handleChangeShippingAddress("city", selectedCity);
  };
  // Fetch countries when the component mounts

  return (
    <>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="Country" style={{ textAlign: "center" }}>
              Select Country
            </InputLabel>
            <Select
              labelId="Select Country"
              id="Select Country"
              label="Select Country*"
              // value={vendorDataSet?.country}
              value={address?.country || ""}
              onChange={(e) => handleCountryChange(e)}
              // value={formData?.country}
              // onChange={handleCountryChange}
              fullWidth
              required
              size="small"
              margin="normal"
              name="Select Country"
              displayEmpty
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              inputProps={{
                style: { width: "100%" },
                "aria-label": "Select Country",
              }}
              error={vendorCountryError}
            helperText={
              vendorCountryError
                ? " Country is required "
                
                : ""
            }
            

            >
              {countryOptions?.length === 0 ? (
                <MenuItem disabled>No countries available</MenuItem>
              ) : (
                countryOptions?.map((country) => (
                  <MenuItem key={country.id} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginTop: "-1.6%" }}>
          <TextField
            fullWidth
            label="State"
            value={address?.state || ""}
            onChange={(e) => handleStateChange(e)}
            margin="normal"
            required
            name="State"
            size="small"
            InputLabelProps={{
              shrink: true,
              style: { textAlign: "center" },
            }}
            InputProps={{
              style: { width: "100%" },
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            error={vendorStateError}
            helperText={
              vendorStateError
                ? "State is required "
                
                : ""
            }
            
          />
        </Grid>

        <Grid item xs={12} sm={4} sx={{ marginTop: "-1.6%" }}>
          <TextField
            fullWidth
            label="City"
            value={address?.city || ""}
            onChange={(e) => handleCityChange(e)}
            margin="normal"
            name="City"
            required
            size="small"
            InputLabelProps={{
              shrink: true,
              style: { textAlign: "center" },
            }}
            InputProps={{
              style: { width: "100%" },
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            error={vendorCityError}
            helperText={
              vendorCityError
                ? "City is required "
                
                : ""
            }
          
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Pincode"
            name="pincode"
            // value={formData?.pincode}
            // readOnly
            value={address?.pincode}
            onChange={(e) => handlePincodeChange(e)}
            size="small"
            fullWidth
            required
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              maxLength: 6,
              pattern: "^[1-9]{6}$",
              title: "Pincode should be 6 digits long",
              inputMode: "numeric",
            }}
            error={vendorPincodeError}
            helperText={
              vendorPincodeError
                ? "Pincode is required & Pincode should be 6 digit long"
                
                : ""
            }
          
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Address 1"
            labelId="Address 1"
            name="addressline1"
            value={address?.addressline1 || ""}
            onChange={(e) => handleAddress1Change(e)}
            // value={street}
            required
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
           
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Address 2"
            labelId="Address 2"
            name="addressline2"
            value={address?.addressline2 || ""}
            onChange={(e) => handleAddress2Change(e)}
            // value={street}
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Mobile Number"
            name="phone"
            // value={formData?.pincode}
            // readOnly
            value={vendorDataSet?.phone}
            onChange={(event) => handleChange("phone", event.target.value)}
            size="small"
            fullWidth
            
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              maxLength: 10,
              pattern: "^[0-9]{10}$",
              title: "Mobile number should be 10 digits long",
              inputMode: "numeric",
            }}
            startAdornment={
              <InputAdornment position="start">
                <FaPhoneAlt />
              </InputAdornment>
            }
            helperText={
              !vendorDataSet?.phone.match(/^[0-9]{10}$/)
                ? "Please enter a valid 10-digit mobile number."
                : ""
            }
            error={!vendorDataSet?.phone.match(/^[0-9]{10}$/)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Email"
            labelId="Email"
            name="email"
            value={vendorDataSet?.email}
            onChange={(event) => handleChange("email", event.target.value)}
            // value={street}
            fullWidth
            required
            size="small"
            margin="normal"
            inputProps={{
              style: { width: "100%" },
              min: 0,
              max: 120,
            }}
            startAdornment={
              <InputAdornment position="start">
                <MdEmail />
              </InputAdornment>
            }
            error={vendorEmailError}
            helperText={
              vendorEmailError
                ? "Email is required"
                
                : ""
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

// Add more components for other sections as needed
const Payments = ({
  vendorDataSet,
  setVendorDataSet,
  bankDetails,
  setBankDetails,
  paymentModeTypeOptions,
  setPaymentModeTypeOptions,
  taxZoneTypeOptions,
  setTaxZoneTypeOptions,
}) => {
  useEffect(() => {
    fetchPaymentModeTypeOptions();
    fetchTaxZoneTypeOptions();
  }, []);
  const fetchPaymentModeTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_MODULE_VENDOR_PAYMENTMODETYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.Data?.map((row) => row?.dropdown_value);
      setPaymentModeTypeOptions(options);
    } catch (error) {
      console.log("Error", error);
      // Handle error
    }
  };
  const fetchTaxZoneTypeOptions = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_MODULE_VENDOR_TAXZONETYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.Data?.map((row) => row?.dropdown_value);
      setTaxZoneTypeOptions(options);
    } catch (error) {
      console.log("Error", error);
      // Handle error
    }
  };
  const handleChange = (field, value) => {
    setBankDetails((prevBankDetails) => ({
      ...prevBankDetails,
      [field]: value,
    }));

    // if (updateVendorDataSet) {
    //   setVendorDataSet((prevData) => ({
    //     ...prevData,
    //     bank_details: {
    //       ...prevData.bank_details,
    //       [field]: value,
    //     },
    //   }));
    // }
  };
  console.log("bank details ", bankDetails);
  const handleBankNameChange = (event) => {
    const bank_name = event.target.value;

    handleChange("bank_name", bank_name);
  };
  const handleAccountNameChange = (event) => {
    const account_no = event.target.value;

    handleChange("account_no", account_no);
  };
  const handleChangeCycle = (field, value) => {
    setVendorDataSet((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  return (
    <>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4} sx={{ marginTop: "-1.7%" }}>
          <TextField
            label="Credit Cycle"
            name="Credit Cycle"
            required
            value={vendorDataSet?.credit_cycle}
            onChange={(e) => handleChangeCycle("credit_cycle", e.target.value)}
            // value={formData?.pincode}
            // readOnly onChange={(event) => event.target.value}
            size="small"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="Select Mode" style={{ textAlign: "center" }}>
              Payment Mode
            </InputLabel>
            <Select
              labelId="Select Mode"
              id="Select Mode"
              label="Select Mode"
              value={vendorDataSet?.payment_terms}
              onChange={(e) => handleChange("payment_terms", e.target.value)}
              // onChange={handleCountryChange}
              fullWidth
              required
              size="small"
              margin="normal"
              name="Select Mode"
              displayEmpty
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              inputProps={{
                style: { width: "100%" },
                "aria-label": "Select Country",
              }}
            >
              <MenuItem value="">Payment Mode</MenuItem>

              {paymentModeTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="Tax Zone" style={{ textAlign: "center" }}>
              Tax Zone
            </InputLabel>
            <Select
              labelId="Tax Zone"
              id="Tax Zone"
              label="Tax Zone"
              required
              value={vendorDataSet?.tax_zone}
              onChange={(e) => handleChange("tax_zone", e.target.value)}
              // value={formData?.state}
              // onChange={handleStateChange}
              fullWidth
              margin="normal"
              name="Tax Zone"
              size="small"
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: "130px" }, // Set the max-height for the menu
                },
              }}
              displayEmpty
              inputProps={{
                style: { width: "100%" },
                "aria-label": "State",
              }}
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
            >
              <MenuItem value="">Tax Zone</MenuItem>

              {taxZoneTypeOptions?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="GST"
            name="GST"
            value={vendorDataSet?.gst}
            onChange={(e) => handleChangeCycle("gst", e.target.value)}
            // value={formData?.pincode}
            // readOnly onChange={(event) => event.target.value}
            size="small"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Aadhaar Number"
            name="Aadhaar Number"
            id="Aadhaar Number"
            required
            value={vendorDataSet?.aadhaar}
            // readOnly
            fullWidth
            margin="normal"
            onChange={(e) => handleChangeCycle("aadhaar", e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="PAN Number"
            name="PAN Number"
            value={vendorDataSet?.pan}
            // readOnly
            onChange={(e) => handleChangeCycle("pan", e.target.value)}
            size="small"
            required
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2.5} sx={{ marginTop: "1.6%" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Bank Name "
            name="Bank Name"
            value={bankDetails?.bank_name}
            onChange={(e) => handleBankNameChange(e)}
            size="small"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Account Number"
            name="Account Number"
            id="Account Number"
            value={bankDetails?.account_no}
            // readOnly
            fullWidth
            margin="normal"
            onChange={(e) => handleAccountNameChange(e)}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="IFSC Code"
            name="IFSC Code"
            value={bankDetails?.ifsc_code}
            // readOnly
            onChange={(e) => handleChange("ifsc_code", e.target.value)}
            size="small"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3.5} sx={{ marginTop: "2.9%" }}>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};

const handleDownloadClick = (fileName) => {
  const presignedUrl = s3.getSignedUrl("getObject", {
    Bucket: "fambo-assets",
    Key: `farmer/${fileName}`,
    Expires: 3600,
  });
  console.log("Presigned URL for viewing:", presignedUrl);
  window.open(presignedUrl, "_blank");
};

const Documents = ({
  images,
  handleCancelImage,
  vendorData,
  handleImageChange,
  setEditStatusActive,
  editStatusActive,
}) => (
  <>
    <Grid
      container
      spacing={3.5}
      style={{ marginTop: "0.6%", marginLeft: "0.5%" }}
    >
      {images?.map((imageType, index) => (
        <Grid key={index} item xs={12} sm={3.85}>
          <Typography
            variant="h2"
            color="textPrimary"
            fontWeight="400"
            gutterBottom
          >
            Upload&nbsp;
            {imageType.type.charAt(0).toUpperCase() + imageType.type.slice(1)}
            <>
              <Button
                variant="contained"
                startIcon={
                  <FileDownloadIcon
                    style={{ color: "#ffffff", fontSize: 26 }}
                  />
                }
                sx={{
                  backgroundColor: "#34519fe6",
                  marginLeft: "7%",
                }}
                size="small"
                disabled={
                  !editStatusActive || !vendorData[imageType.type + "_attach"]
                }
                onClick={() =>
                  handleDownloadClick(vendorData[imageType.type + "_attach"])
                }
              >
                <Typography variant="h7" fontFamily="Lexend Deca">
                  Download
                </Typography>
              </Button>
            </>
          </Typography>
          {imageType?.images.map((image, imgIndex) => (
            <Grid key={imgIndex} item xs={12} sm={5}>
              <Box
                border="1px dashed #000"
                borderRadius="10px"
                height="60px"
                width="60px"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                position="relative"
              >
                <IconButton
                  onClick={() => handleCancelImage(imageType.type, imgIndex)}
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    color: "red",
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <img
                  src={image.data}
                  alt={`${imageType.type} ${imgIndex + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ marginTop: "3%" }}>
            <Box
              border="1px dashed #000"
              borderRadius="10px"
              height="100px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <Typography
                variant="body1"
                color="textSecondary"
                marginBottom="8px"
              >
                Click or drag to add an image
              </Typography>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(imageType.type, event)}
                style={{ display: "none" }}
                id={`image-upload-${index}`}
              />
              <label htmlFor={`image-upload-${index}`}>
                <IconButton component="span">
                  <AddIcon style={{ color: "red" }} />
                  <Typography variant="button" color="red">
                    Select Image
                  </Typography>
                </IconButton>
              </label>
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
    <Grid
      container
      spacing={2.5}
      sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
    >
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
  </>
);

export const Mapping = ({
  warehouseData,
  vendorDataSet,
  setVendorDataSet,
  setFilteredWarehouse,
  filteredWarehouse,
}) => {
  const theme = useTheme();
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Ensure warehouseData is an array
    const warehouses = Array.isArray(warehouseData)
      ? warehouseData
      : [warehouseData];
    console.log("warehouses", warehouses);
    // Update vendorDataSet with selected warehouses
    const selectedWarehousesWithFlag = warehouses
      .filter((warehouse) => value.includes(warehouse?.wh_name))
      .map((selectedWarehouse) => ({
        id: selectedWarehouse?.id,
        warehouse_id: selectedWarehouse?.id,
        wh_name: selectedWarehouse?.wh_name,
        flag: true,
      }));

    // Set selectedWarehouses state
    setSelectedWarehouses(selectedWarehousesWithFlag);

    // Update the filteredWarehouses state (if needed)
    setFilteredWarehouse(selectedWarehousesWithFlag);
    // Create a copy of the original warehouses and update values
    const updatedWarehouses = warehouses?.map((warehouse) => {
      const selectedWarehouse = selectedWarehousesWithFlag?.find(
        (selectedWarehouse) => selectedWarehouse?.warehouse_id === warehouse.id
      );

      return selectedWarehouse
        ? {
            id: warehouse.id,
            warehouse_id: warehouse.id,
            wh_name: warehouse.wh_name,
            flag: selectedWarehouse.flag,
          }
        : {
            warehouse_id: warehouse.id,
            wh_name: warehouse.wh_name,
            flag: false,
          };
    });

    console.log("updatedWarehouses", updatedWarehouses);
    setSelectedWarehouses(updatedWarehouses);
    // Combine selectedWarehouses and other warehouses with flag: false
    const combinedWarehouses = [
      ...updatedWarehouses,
      ...warehouses
        .filter((warehouse) => !value.includes(warehouse?.wh_name))
        .map((warehouse) => ({
          id: warehouse.id,
          warehouse_id: warehouse.id,
          wh_name: warehouse.wh_name,
          flag: false,
        })),
    ];

    setVendorDataSet((prevData) => ({
      ...prevData,
      warehouse: combinedWarehouses || [],
    }));

    console.log("Selected Warehouses:", value);
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "0.6%", marginLeft: "2.1%", marginRight: "10%" }}
      >
        <Typography>Link to WareHouse</Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%", marginRight: "10%" }}
      >
        <FormControl sx={{ m: 1, width: "95%" }}>
          <InputLabel id="product-chip-label">Link to Warehouses</InputLabel>
          <Select
            labelId="product-chip-label"
            id="product-chip"
            multiple
            value={(filteredWarehouse || [])?.map((wh) => wh?.wh_name)}
            onChange={handleChange}
            input={
              <OutlinedInput
                id="select-product-chip"
                label="Select Warehouses"
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {Array.isArray(warehouseData) &&
              warehouseData?.map((warehouse) => (
                <MenuItem key={warehouse?.id} value={warehouse?.wh_name}>
                  {/* <Checkbox
                    checked={(vendorDataSet?.warehouse || [])
                      .map((wh) => wh.wh_name)
                      .includes(warehouse.wh_name)}
                  /> */}
                  {warehouse?.wh_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

const ProductMapping = ({
  productData,
  vendorId,
  setSelectedProduct,
  selectedProduct,
  setProductToSet,
  onsubmit,
  setOnSubmit,
  setProductData,
  fetchListOfAllSKU,
  action,
  setSelectedProductsWithFlag,
  selectedProductsWithFlag,
  onUpdateProductData, // Callback function from the parent
}) => {
  const theme = useTheme();

  console.log("productData-----------", productData);

  const updateSelectedProducts = (value, products) => {
    const updatedSelectedProducts =
      typeof value === "string" ? value.split(",") : value;

    // Update isSelected property
    const updatedProductData = products?.map((product) => ({
      ...product,
      isSelected: updatedSelectedProducts?.some(
        (selectedProduct) => selectedProduct?.sku_id === product?.sku_id
      ),
    }));
    console.log("updated product data ", updatedProductData);
    console.log("updated selected product data ", updatedSelectedProducts);
    setSelectedProduct(updatedSelectedProducts);

    const updatedSelectedProductsWithFlag = updatedProductData
      .map((selectedProduct) => {
        if (selectedProduct.isSelected) {
          // Check conditions for setting flag and adding to setProductToSet
          if (
            (selectedProduct.flag === false &&
              selectedProduct.isSelected === true) ||
            (selectedProduct.flag === true &&
              selectedProduct.isSelected === true)
          ) {
            setProductToSet((prevData) => ({
              ...prevData,
              product: [
                ...prevData.product,
                {
                  sku_id: selectedProduct?.sku_id,
                  sku_name: selectedProduct?.sku_name,
                  vendor_id_fk: vendorId,
                  sku_code: selectedProduct?.sku_code,
                  flag: true,
                },
              ],
            }));
          } else if (
            selectedProduct.flag === true &&
            selectedProduct.isSelected === false
          ) {
            // Handle the case when isSelected is false and flag is true
            setProductToSet((prevData) => ({
              ...prevData,
              product: prevData.product.map((p) =>
                p.sku_id === selectedProduct.sku_id ? { ...p, flag: false } : p
              ),
            }));
          }
        } else if (
          selectedProduct.flag === false &&
          selectedProduct.isSelected === false
        ) {
          // Exclude products where isSelected is false and both the current and previous flags are false
          return null;
        }
        return {
          sku_id: selectedProduct?.sku_id,
          sku_name: selectedProduct?.sku_name,
          vendor_id_fk: vendorId,
          sku_code: selectedProduct?.sku_code,
          flag: selectedProduct?.isSelected,
        };
      })
      .filter(Boolean);

    console.log(
      "updated selected product with flag data ",
      updatedSelectedProductsWithFlag
    );
    setProductToSet((prevData) => ({
      ...prevData,
      product: updatedSelectedProductsWithFlag,
    }));

    setSelectedProductsWithFlag(updatedSelectedProductsWithFlag);

    console.log("Selected Products:", updatedSelectedProductsWithFlag);
    console.log("Updated Product Data:", updatedProductData);
  };

  const handleProductChange = (event, newValue) => {
    const { value } = event.target;
    const products = Array.isArray(productData) ? productData : [productData];
    updateSelectedProducts(newValue, products);
  };
  useEffect(() => {
    // Fetch products for the customer when in 'edit' mode
    if (vendorId) {
      fetchListOfAllSKU(vendorId);
    }
  }, [vendorId]);

  useEffect(() => {
    if (productData) {
      const selectedProductsFromData = productData
        .filter((product) => product.flag === true)
        .map((selectedProduct) => selectedProduct);

      setSelectedProduct(selectedProductsFromData);
      setSelectedProductsWithFlag(productData);
      updateSelectedProducts(selectedProductsFromData, productData);
    }
  }, [action, productData]);
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "0.6%", marginLeft: "2.1%", marginRight: "10%" }}
      >
        <Typography>Link to SKU</Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ marginTop: "1.6%", marginLeft: "1.5%", marginRight: "10%" }}
      >
        <FormControl sx={{ m: 1, width: "95%" }}>
          <Autocomplete
            multiple
            id="Link to SKU"
            value={selectedProduct}
            onChange={handleProductChange}
            options={productData}
            getOptionLabel={(option) => option.sku_name}
            renderInput={(params) => (
              <TextField {...params} label="Select Product" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.sku_id}
                  label={option.sku_name}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </FormControl>
      </Grid>
      <Grid
        container
        spacing={2.5}
        sx={{ marginTop: "1.9%", marginLeft: "1.5%" }}
      >
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </>
  );
};
const VendorConfig = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  //console.log("Rowdata Here ", rowData);
  // const typography = tokens(theme.palette.mode.typography);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { action, vendorData } = useSelector((state) => state.vendor);
  console.log("Dataaaaaaaaaaaaaaaaaaaa", vendorData);
  console.log("action", action);
  const [editStatusActive, setEditStatusActive] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [selectedProductsWithFlag, setSelectedProductsWithFlag] = useState([]);
  const [productToSet, setProductToSet] = useState([]);
  const [vendorTypeOptions, setVendorTypeOptions] = useState([]);
  // const [selectedProductsOnSubmit, setSelectedProductsOnSubmit] = useState([]);
  const [paymentModeTypeOptions, setPaymentModeTypeOptions] = useState([]);
  const [taxZoneTypeOptions, setTaxZoneTypeOptions] = useState([]);
  // const [customerData23, setCustomerData23] = useState(rowData);
  const [vendorId, setVendorId] = useState(null);
  const [activeNextButton, setActiveNextButton] = useState(false);
  const [active2ndNextButton, setActive2ndNextButton] = useState(false);
  const [vendorNameError, setVendorNameError] = useState(false);
  const [vendorEmailError, setVendorEmailError] = useState(false);
  const [vendorCountryError, setVendorCountryError] = useState(false);
  const [vendorCityError, setVendorCityError] = useState(false);
  const [vendorStateError, setVendorStateError] = useState(false);
  const [vendorPincodeError, setVendorPincodeError] = useState(false);
  const [vendorMobileNumberError, setVendorMobileNumberError] = useState(false);
 
  const [isLoadingList, setIsLoadingList] = useState(false);
  const location = useLocation();
  const [message, setMessage] = useState("");
  const colors = tokens(theme.palette.mode);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [onsubmit, setOnSubmit] = useState(false);
  const [filteredWarehouse, setFilteredWarehouse] = useState([]);
  const [warehouseLinkingCompleted, setWarehouseLinkingCompleted] =
    useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState("Personal Details");
  const sections = [
    { name: "Personal Details", icon: <MdModeEdit /> },
    { name: "Address", icon: <MdLocationOn /> },
    { name: "Documents", icon: <MdDescription /> },
    { name: "Payments", icon: <HiCurrencyRupee /> },
    { name: "Connect Warehouse", icon: <FaWarehouse /> },
    { name: "SKU Linking", icon: <MdOutlineProductionQuantityLimits /> },
  ];
  const [address, setAddress] = useState({
    country: "",
    state: "",
    city: "",
    pincode: 123456,
    addressline1: "",
    addressline2: "",
  });
  const [bankDetails, setBankDetails] = useState({
    payment_terms: "",
    tax_zone: "",

    bank_name: "",
    account_no: "",
    ifsc_code: "",
  });

  useEffect(() => {
    switch (action) {
      case "edit":
        if (vendorData) {
          // If editing, prefill the form with the warehouseData
          console.log("customer data in edit form ", vendorData);
          console.log("edit action performed");
          setEditStatusActive(true);
          // Fetch states and cities based on the country and state in vendorData

          // Initialize WarehouseData with all fields from JSON structure
          setVendorId(vendorData?.id);
          console.log("customer id", vendorData?.id);
          // Filter warehouses with flag === true
          const filteredWarehouses = vendorData?.warehouse
            ? vendorData?.warehouse?.filter((wh) => wh.flag === true)
            : [];
          setFilteredWarehouse(filteredWarehouses);
          setVendorDataSet((prevData) => ({
            ...prevData,
            ...vendorData,

            warehouse: filteredWarehouses,
          }));
          setAddress({
            country: vendorData?.address?.country || "",
            state: vendorData?.address?.state || "",
            city: vendorData?.address?.city || "",
            pincode: vendorData?.address?.pincode || 123456,
            addressline1: vendorData?.address?.addressline1 || "",
            addressline2: vendorData?.address?.addressline2 || "",
          });
          setBankDetails({
            payment_terms: vendorData?.bank_details?.payment_terms || "",
            tax_zone: vendorData?.bank_details?.tax_zone || "",

            bank_name: vendorData?.bank_details?.bank_name || "",
            account_no: vendorData?.bank_details?.account_no || "",
            ifsc_code: vendorData?.bank_details?.ifsc_code || "",
          });
          // Update only the fields present in warehouseData
        }
        break;

      case "add":
        // If adding, clear the form data
        console.log("add action performed");
        console.log("Vendor Data Set:", vendorDataSet);
        console.log("Bank Details:", bankDetails);
        setVendorDataSet((prevData) => ({
          ...prevData,
          vendor_name: "",
          vendor_code: "",
          vendor_type: "",
          active: true,
          description: "",

          email: "",
          phone: "",
          pincode: 123456,

          address: address,
          gst_attach: "",
          pan_attach: "",
          aadhaar_attach: "",

          bank_details: bankDetails,
        }));
        break;

      // Add more cases if needed

      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, vendorData]);
  const [vendorDataSet, setVendorDataSet] = useState({
    vendor_name: "",
    vendor_code: "",
    active: true,
    description: "",
    vendor_type: "",
    email: "",
    phone: 9898989898,

    address: address,
    gst_attach: "",
    pan_attach: "",
    aadhaar_attach: "",
    gst: "",
    pan: "",
    payment_terms: "",
    bank_details: bankDetails,
    tax_zone: "",
  });
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the product data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []);
  useEffect(() => {
    fetchListOfAllSKU(vendorId);
  }, []);

  const [images, setImages] = useState([
    { type: "aadhaar", images: [], file: null },
    { type: "pan", images: [], file: null },
    { type: "gst", images: [], file: null },
  ]);

  const uploadDocuments = async () => {
    const uploadPromises = images?.map((file) => {
      if (file.file) {
        console.log("TYPE----------------------------------------", file.type);
        console.log("FILE----------------------------------------", file.file);
        const key = `${file.file.name}`;

        const params = {
          Bucket: "fambo-assets",
          Key: `farmer/${key}`,
          Body: file.file,
        };

        return new Promise((resolve, reject) => {
          s3.upload(params, (err, data) => {
            if (err) {
              console.log("Error uploading file:", err);
              reject(err);
            } else {
              console.log("File uploaded successfully:", data.Location);
              resolve(key);
            }
          });
        });
      }
      return Promise.resolve(null);
    });
    try {
      const uploadedKeys = await Promise.all(uploadPromises);
      console.log("All files uploaded:", uploadedKeys);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleImageChange = (imageType, event) => {
    const file = event.target.files[0];
    console.log("images------------", images);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const updatedImages = [...images];
        const imageTypeIndex = updatedImages.findIndex(
          (type) => type.type === imageType
        );

        if (imageTypeIndex !== -1) {
          updatedImages[imageTypeIndex].file = file;
          const fileName = `${file.name}`;
          updatedImages[imageTypeIndex].images.push({
            name: fileName,
            data: reader.result,
          });
          setImages(updatedImages);
          // Update corresponding state property
          setVendorDataSet((prevData) => ({
            ...prevData,
            [`${imageType.toLowerCase()}_attach`]: fileName,
          }));
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const handleSectionChange = async (sectionName) => {
    try {
      // Start loader when changing section
      setVendorLoading(true);

      // Your logic for fetching data based on the selected section
      // ...

      // Simulating an async operation
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Stop loader after fetching data or when changing to a non-SKU section
      setVendorLoading(false);

      // Set the current section
      setCurrentSection(sectionName);
    } catch (error) {
      console.error("Error handling section change:", error);
      // Handle errors if needed
    }
  };
  const handleCancelImage = (imageType, index) => {
    const updatedImages = [...images];
    const imageTypeIndex = updatedImages.findIndex(
      (type) => type.type === imageType
    );
    if (imageTypeIndex !== -1) {
      updatedImages[imageTypeIndex].images.splice(index, 1);
      setImages(updatedImages);
    }
  };
  useEffect(() => {
    // Update isValid based on the latest state
    const isVendorNameError = vendorDataSet?.vendor_name.trim() === "";
   const isVendorEmailError = vendorDataSet?.email.trim() === "";
   const isVendorCountryError = vendorDataSet?.address?.country.trim() === "";
   const isVendorCityError =vendorDataSet?.address?.city.trim() === "";
   const isVendorStateError = vendorDataSet?.address?.state.trim() === "";
   

    const isValid = !isVendorNameError ;
    const isValid1 =  !isVendorEmailError;
    setActiveNextButton(!isValid);
    setActive2ndNextButton(!isValid1);
  }, [vendorDataSet?.vendor_name , vendorDataSet?.email , vendorDataSet?.address?.country ,vendorDataSet?.address?.city ,vendorDataSet?.address?.state ]);
  const handleNext = async () => {
    let isValid= true;
    switch (currentSection) {
      case "Personal Details" : setVendorNameError(vendorDataSet?.vendor_name.trim() === "");
                                break;
      case "Address" :   setVendorEmailError(vendorDataSet?.email.trim() === "");
                           setVendorCountryError(vendorDataSet?.address?.country.trim() === "");
                             setVendorCityError(vendorDataSet?.address?.city.trim() === "");
                           setVendorStateError(vendorDataSet?.address?.state.trim() === "");
                           
                                break;

      default : break;


    }
    if(isValid) {
      const currentIndex = sections.findIndex(
        (section) => section.name === currentSection
      );
      if (currentIndex < sections.length - 1) {
        const nextSection = sections[currentIndex + 1].name;
        if (action === "edit" && nextSection === "SKU Linking") {
          await updateVendorAllData();
        } else if (action === "add" && nextSection === "SKU Linking") {
          await createProductData();
        }
        setCurrentSection(nextSection);
      }
    }
    else {
      // Display an error message or handle the validation error
      console.error("Fill required fields first");
      setMessage("Fill required fields first");
    }
   
  };
  // useEffect(() => {
  //   setVendorDataSet((prevData) => {
  //     let updatedData = {
  //       ...prevData,
  //       address: address,
  //       bank_details: bankDetails,
  //     };
  //     console.log("updatedData in this function ", updatedData);
  //     return updatedData;
  //   });
  //   console.log("Updated vendorDataSet 666666666:", vendorDataSet);
  // }, []);
  const updateVendorAllData = async () => {
    try {
      if (!vendorData?.id) {
        console.error("vendor id is missing in TransportData.");
        setErrorMessage("Please try Again");
        return;
      }

      console.log("vendor data ", vendorData);
      console.log("Creating vendorData:", vendorDataSet);

      const addressString = JSON.stringify(address);
      console.log("address string", addressString);
      // Update the address and bank_details directly

      // Update the address and bank_details with new objects
      // setVendorDataSet((prevData) => {
      //   let updatedData = {
      //     ...prevData,
      //     address: address,
      //     bank_details: bankDetails,
      //   };
      //   console.log("updatedData in this function ", updatedData);
      //   return updatedData;
      // });
      setVendorLoading(true);
      const updatedData = {
        ...vendorDataSet,
        address: address,
        bank_details: bankDetails,
      };
      console.log("updatedData in this function ", updatedData);

      console.log("after setting Creating vendorData:", vendorDataSet);

      console.log("address details in this function ", address);
      console.log("bank details in this function ", bankDetails);
      const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_VENDOR_DATA(
        vendorData?.id
      )}`;
      console.log("API Endpoint:", endpoint);
      setVendorId(vendorData?.id);
      console.log("after setting Creating vendorData:", vendorDataSet);
      // Log the TransportData before making the API call
      console.log("Updating vendorData before sending:", vendorDataSet);

      const response = await axiosInstance.put(endpoint, updatedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data updated Successfully!");
        // updateVendorData(vendorData?.id , selectedProductsWithFlag);
        setWarehouseLinkingCompleted(true);
        setVendorLoading(false);
        // navigate('/viewWarehouse');
      } else {
        console.error("Failed t;o update the  data");
        setErrorMessage("Failed to update the  data. Please try again.");
        setErrorPopupOpen(true);
        setVendorLoading(false);
      }
      console.log("Updated vendorData after sending:", updatedData);
    } catch (error) {
      setErrorPopupOpen(true);
      setVendorLoading(false);
      console.error("Error occurred while updating the data:", error);
    }
  };
  // useEffect(() => {
  //   if (vendorDataSet) {
  //     fetchData();
  //   }
  // }, [vendorDataSet]);

  useEffect(() => {
    // Effect to navigate after success message is set
    if (successPopupOpen) {
      const timer = setTimeout(() => {
        setSuccessPopupOpen(false);
        navigate("/viewVendor");
      }, 800);

      return () => clearTimeout(timer);
    }
  }, [successPopupOpen, navigate]);

  const createProductData = async () => {
    uploadDocuments();
    try {
      // Determine the endpoint for creating a new transport
      const endpoint = `${API_ENDPOINTS.POST_WMS_ADD_VENDOR_DATA}`;
      console.log("API Endpoint:", endpoint);
      setVendorLoading(true);
      // Log the TransportData before making the API call
      console.log("Creating vendorData:", vendorDataSet);
      console.log("before address details in this function ", address);
      console.log("Before bank details in this function ", bankDetails);
      const updatedData = {
        ...vendorDataSet,
        address: address,
        bank_details: bankDetails,
      };
      console.log("address details in this function ", address);
      console.log("bank details in this function ", bankDetails);
      console.log("after setting Creating vendorData:", vendorDataSet);
      // Send the POST request to create a new transport
      const response = await axiosInstance.post(endpoint, updatedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        console.log("response--------------", response);
        // Extract vendorId from the response
        const vendorId = response.data?.data?.vendor_id;
        setVendorId(response.data?.data?.vendor_id);
        // Call updateVendorData with vendorId and selectedProductsWithFlag
        // updateVendorData(vendorId, selectedProductsWithFlag);
        setWarehouseLinkingCompleted(true);
        setVendorLoading(false);
        // setSuccessPopupOpen(true);
        // setVendorId(Data?.customer_id);
        // console.log("customer id coming here ? ",Data?.customer_id);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the transport data");
        setErrorMessage(
          "Failed to create the transport data. Please try again."
        );
        setErrorPopupOpen(true);
        setVendorLoading(false);
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setVendorLoading(false);
      console.error("Error occurred while creating the transport data:", error);
    }
  };

  const updateVendorData = async (vendorId, selectedProductsWithFlag) => {
    console.log(
      "selectedproduct in updateproductdata function ",
      selectedProductsWithFlag
    );
    setVendorLoading(true);
    try {
      if (!vendorId) {
        console.error("vendor  id is missing in Data.");
        setErrorMessage("Please try Again");
        return;
      }

      const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_VENDOR_SKU_MAPPPED(
        vendorId
      )}`;
      console.log("API Endpoint:", endpoint);

      // Log the TransportData before making the API call
      console.log("Updating product data :", selectedProductsWithFlag);
      console.log("Request Body:", JSON.stringify(selectedProductsWithFlag));
      const response = await axiosInstance.put(
        endpoint,
        selectedProductsWithFlag,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        console.log("API Response:", response);
        setSuccessMessage("Data updated Successfully!");
        setSuccessPopupOpen(true);
        setVendorLoading(false);
        navigate("/viewVendor");
      } else {
        console.error("Failed to update the product data");
        setErrorMessage(
          "Failed to update the transport data. Please try again."
        );
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setVendorLoading(false);
      console.error("Error occurred while updating the transport data:", error);
    }
  };
  const handleSubmit = () => {
    uploadDocuments();
    try {
      if (action === "edit") {
        // If editing, send a PUT request

        updateVendorData(vendorId, selectedProductsWithFlag);
      } else {
        // If adding, send a POST request
        console.log("submit button clicked here , detach action", action);
        console.log(
          "selectedproduct with falg, detach action",
          selectedProductsWithFlag
        );
        console.log("vendor id , detach action", vendorId);
        updateVendorData(vendorId, selectedProductsWithFlag);
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error("Error submitting  Details:", error);
      setMessage("Error submitting  Details");
    }
  };
  const handlePrevious = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex > 0) {
      const previousSection = sections[currentIndex - 1].name;
      setCurrentSection(previousSection);
    }
  };
  function handleBack() {
    if (action === "add") {
      navigate("/configuration");
    } else {
      // Navigate to a different location based on your condition

      navigate("/viewVendor");
    }
  }
  const fetchListOfAllSKU = async (vendorId) => {
    setIsLoadingList(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_SKU_MAPPED_WITH_VENDOR}`,
        {
          params: {
            limit: 1000,
            offset: 1,
            vendor_id: vendorId,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data?.sku_list;
        console.log("product data", data);
        setProductData(data);

        console.log("product  Data", data);
      } else {
        console.log("Error fetching details");
        setErrorMessage("OOPS, Something went Wrong !!!");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoadingList(false);
      // Set loading to false after the request is completed
    }
  };

  useEffect(() => {
    if (vendorId && selectedProductsWithFlag?.length > 0) {
      updateVendorData(vendorId, selectedProductsWithFlag);
      // Reset the flag after calling updateVendorData
      setIsDataSubmitted(false);
    }
  }, [vendorId]);
  const isLastSection = currentSection === sections[sections.length - 1].name;
  //Personal Details States

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
          // backgroundColor:'pink'
          // backgroundColor: "rgb(44 123 85)",
        }}
      >
        <Typography
          variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%" }}
        >
          <MdPeopleAlt
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp; Vendor
        </Typography>
        {/* <InventoryRoundedIcon sx={{ fontSize: 32, marginLeft: "1px" }} />
          </Typography> */}

        <Stack spacing={2} direction="row">
          {/* <Button
            variant="contained"
            startIcon={
              <MdModeEdit
                style={{ color: colors.white.DEFAULT, fontSize: 15 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              backgroundColor: colors.green.DEFAULT,
            }}
            title="Edit"
          >
            <Typography variant="h7" color={colors.white.DEFAULT}>
              Edit
            </Typography>
          </Button> */}

          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>
      {vendorLoading ? (
        <>
          <div style={loaderContainerStyle}>
            <ClipLoader color="#495057" loading={vendorLoading} />
          </div>
        </>
      ) : (
        <Grid container spacing={3} sx={{ marginTop: "1.7%" }}>
          {/* Left Section (30%) */}
          <Grid item xs={12} sm={3}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            >
              {sections?.map((section) => (
                <Typography
                  key={section.name}
                  onClick={() => handleSectionChange(section.name)}
                  //onClick={() => setCurrentSection(section.name)}
                  style={{
                    cursor: "pointer",
                    padding: "8px",

                    background:
                      currentSection === section.name
                        ? "#34519F5D"
                        : "transparent",
                    color: currentSection === section.name ? "#000" : "inherit",
                    borderRadius:
                      currentSection === section.name ? "7px" : "inherit",
                    // padding:currentSection === section.name ? '5px' : 'inherit',
                  }}
                >
                  {section?.icon}
                  &nbsp;&nbsp;
                  {section?.name}
                </Typography>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={9} md={9}>
            <Box
              p={2}
              bgcolor="#ffff"
              borderRadius="10px"
              maxHeight="100%"
              height="100%"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
              position="relative"
            >
              {/* Dynamically render the current section */}
              {currentSection === "Personal Details" && (
                <PersonalDetails
                  vendorDataSet={vendorDataSet}
                  setVendorDataSet={setVendorDataSet}
                  vendorTypeOptions={vendorTypeOptions}
                  setVendorTypeOptions={setVendorTypeOptions}
                  vendorNameError={vendorNameError}
                  setVendorNameError = {setVendorNameError}
                />
              )}
              {currentSection === "Address" && (
                <ShippingAddress
                  vendorDataSet={vendorDataSet}
                  setVendorDataSet={setVendorDataSet}
                  address={address}
                  setAddress={setAddress}
                  vendorData={vendorData}
                  vendorEmailError ={vendorEmailError}
                  setVendorEmailError ={setVendorEmailError}
                  vendorCountryError={vendorCountryError}
                  setVendorCountryError ={setVendorCountryError}
                  vendorCityError={vendorCityError}
                  setVendorCityError={setVendorCityError}
                  vendorStateError={vendorStateError}
                  setVendorStateError={setVendorStateError}
                  vendorPincodeError={vendorPincodeError}
                  setVendorPincodeError={setVendorPincodeError}
                  vendorMobileNumberError={vendorMobileNumberError}
                  setVendorMobileNumberError={setVendorMobileNumberError}
                  action={action}
                />
              )}

              {currentSection === "Payments" && (
                <Payments
                  vendorDataSet={vendorDataSet}
                  setVendorDataSet={setVendorDataSet}
                  bankDetails={bankDetails}
                  setBankDetails={setBankDetails}
                  paymentModeTypeOptions={paymentModeTypeOptions}
                  setPaymentModeTypeOptions={setPaymentModeTypeOptions}
                  taxZoneTypeOptions={taxZoneTypeOptions}
                  setTaxZoneTypeOptions={setTaxZoneTypeOptions}
                />
              )}
              {currentSection === "Documents" && (
                <Documents
                  images={images}
                  handleCancelImage={handleCancelImage}
                  handleImageChange={handleImageChange}
                  vendorData={vendorData}
                  setEditStatusActive={setEditStatusActive}
                  editStatusActive={editStatusActive}
                />
              )}
              {currentSection === "Connect Warehouse" && (
                <Mapping
                  warehouseData={warehouseData}
                  vendorDataSet={vendorDataSet}
                  setVendorDataSet={setVendorDataSet}
                  setFilteredWarehouse={setFilteredWarehouse}
                  filteredWarehouse={filteredWarehouse}
                />
              )}
              {currentSection === "SKU Linking" && (
                <ProductMapping
                  productData={productData}
                  setProductData={setProductData}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  vendorDataSet={vendorDataSet}
                  setProductToSet={setProductToSet}
                  productToSet={productToSet}
                  vendorId={vendorId}
                  action={action}
                  setOnSubmit={setOnSubmit}
                  onsubmit={onsubmit}
                  fetchListOfAllSKU={fetchListOfAllSKU}
                  setVendorDataSet={setVendorDataSet}
                  updateVendorData={updateVendorData}
                  onUpdateProductData={updateVendorData} // Pass the callback function
                  selectedProductsWithFlag={selectedProductsWithFlag} // Pass the state as a prop
                  setSelectedProductsWithFlag={setSelectedProductsWithFlag}
                />
              )}
              <Box
                position="absolute"
                bottom="16px"
                right="20px"
                display="flex"
                gap={2}
              >
                {currentSection !== sections[0].name && (
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: colors.green.DEFAULT,
                    }}
                    onClick={handlePrevious}
                  >
                    <Typography variant="h7" color={colors.green.DEFAULT}>
                      Previous
                    </Typography>
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{
                    borderColor: colors.green.DEFAULT,
                    backgroundColor: colors.green.DEFAULT,
                  }}
                  onClick={isLastSection ? handleSubmit : handleNext}
                  disabled={
                    activeNextButton  || 
                    (currentSection === "Address" && active2ndNextButton)
                    
                  }
                >
                  <Typography variant="h7" color={colors.white.DEFAULT}>
                    {isLastSection ? "Submit" : "Next"}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default VendorConfig;
