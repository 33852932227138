
// reducers.js
const initialState = {
  action: '',
  soData: null,
  id: null, 
  
};
export const soReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SO_ACTION':
      console.log("Payload inside SoReducer:", action.payload);
      return {
        action: action.payload.action,
        soData: action.payload.soData,
        id: action.payload.id,
      };
    default:
      return state;
  }
};
