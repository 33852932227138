import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route, Outlet } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import WMSSideBar from "./WMS/WMSSideBar";
import StaticDashboard from "./Modules/StaticDashboard/index";
import { CssBaseline, ThemeProvider } from "@mui/material";
import ReportMainPage from "./Modules/Reports/MainPage/MainPage";
import { ColorModeContext, useMode } from "./theme";
import "./App.css";
import CDMainPage from "./ConfigDashboardWMSOMS/CDMainPage";
//import Dummy from "./Components/Dummy";
import { AuthProvider } from "./AuthContext";
import LoginPage from "./Modules/Authentication/LoginPage";
import ForgetPassword from "./Modules/Authentication/ForgetPassword";
import ResetPassword from "./Modules/Authentication/ResetPassword";
import UserRoleMainPage from "./Modules/UserRole/UserRoleMainPage";
import MainConfg from "./Modules/Configuration/MainPage";
import AllFarmView from "./Modules/Configuration/Farm/FarmView/FarmView";
import AllCropView from "./Modules/Configuration/Crop/CropView/CropView";
import AllFarmerView from "./Modules/Configuration/Farmer/FarmerView/FarmerView";
import InventoryConfig from "./Modules/CropManagement/Form/ConfigForm/Configuration";
import InventoryData from "./Modules/CropManagement/Page/DataDisplay/DataDisplay";
import InventoryHome from "./Modules/CropManagement/Page/MainPage/MainPage";
import TaskMainPage from "./Modules/TaskAllocation/TaskMainPage";
import ExpenseMainPage from "./Modules/ExpenseTracker/ExpenseMainPage/ExpenseMainPage";
import { PageNotFound, UnknownPage } from "./PageNotFound";
import UserNotFound from "./UserNotFound";
import HomePage from "./WMS/WMSHome/HomePage";
import "../src/WMS/WMS.css";
import InventoryMainPage from "./WMS/InventoryModule/InventoryMainPage";
import FooterWMS from "./scenes/global/FooterWMS";
// import ConfigDashboard from "./Modules/ConfigDashboard/LandingPage";
import OMSMainPage from "./OMS/OMSLoginPage";
import ProcurementMainPage from "./OMS/ProcurementMainPage";
import OMSLoginPage from "./OMS/OMSLoginPage";
import AnalyticsMainPage from "./OMS/AnalyticsMainPage";
import AdminMainPage from "./OMS/AdminMainPage";
import WMSLoginPage from "./WMS/WMSLoginPage";
import WMSConfigMainPage from "./WMS/WMSConfiguration/WMSConfigMainPage";
import GRNMainPage from "./WMS/GRNMainPage";
import VendorConfig from "./WMS/WMSConfiguration/Vendor/VendorConfig";
import VendorView from "./WMS/WMSConfiguration/Vendor/VendorView";
import SKUConfig from "./WMS/WMSConfiguration/SKU/SKUConfig";
import SKUView from "./WMS/WMSConfiguration/SKU/SKUView";
// import CustomerConfig from "./WMS/WMSConfiguration/Customer/CustomerConfig";
import CustomerView from "./WMS/WMSConfiguration/Customer/CustomerView";
import SalesOrderMainPage from "./WMS/SalesOrderMainPage";
import SidebarWMS from "./scenes/global/SidebarWMS";
import TopBarWMS from "./scenes/global/TopBarWMS";
import AttLoginPage from "./Attendance/AttLogin";
import SideBarAtt from "./Attendance/SideBarAtt";
import TopBarAtt from "./scenes/global/TopBarAtt";

import AttHomePage from "./Attendance/HomePage/AttHomePage";
import AttUserPage from "./Attendance/UserPage/AttUserPage";
import AttReportPage from "./Attendance/ReportPage/AttReportPage";
import WMSUserPage from "./ConfigDashboardWMSOMS/WMSConfig/WMSUserPage";
import OMSUserViewPage from "./ConfigDashboardWMSOMS/OMSConfig/OMSUserViewPage";
import CustomerMasterConfig from "./WMS/WMSConfiguration/Customer/CustomerMasterConfig";
// Import the Redux store and reducers if needed
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
// Replace with your actual reducer
import POMainPage from "./WMS/POModule/POMainPage";
import PONewAddPage from "./WMS/POModule/PONewAddPage";
import WarehouseView from "./WMS/WMSConfiguration/Warehouse/WarehouseView";
import WarehouseConfig from "./WMS/WMSConfiguration/Warehouse/WarehouseConfig";
// Create the Redux store for WMS
// Create the Redux store for WMS
import store from "../src/WMS/store/store";
import TransportView from "./WMS/WMSConfiguration/Transport/TransportView";
import TransportConfig from "./WMS/WMSConfiguration/Transport/TransportConfig";
import ReportsMainPage from "./WMS/Reports/ReportsMainPage";
import MainPage from "./WMS/CycleCountModule/MainPage";
import PerformCycleCount from "./WMS/CycleCountModule/PerformCycleCount";
import UserMainPage from "./WMS/UserManagement/UserMainPage";
import STOMainPage from "./WMS/STOModule/STOMainPage";
import STOAdd from "./WMS/STOModule/STOAdd";
import ProductView from "./WMS/WMSConfiguration/Product/ProductView";
import ProductConfig from "./WMS/WMSConfiguration/Product/ProductConfig";
import FamilyView from "./WMS/WMSConfiguration/Family/FamilyView";
import FamilyConfig from "./WMS/WMSConfiguration/Family/FamilyConfig";
import CustomerGroupConfig from "./WMS/WMSConfiguration/CustomerGroup/CustomerGroupConfig";
import CustomerGroupView from "./WMS/WMSConfiguration/CustomerGroup/CustomerGroupView";
import IntegrationMainPage from "./WMS/IntegrationModule/IntegrationMainPage";
import SOMainPage from "./WMS/SOModule/SOMainPage";
import SONewAddPage from "./WMS/SOModule/SONewAddPage";
import PNLReport from "./WMS/Reports/PNLReport";

function FMSLayout() {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;

  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const navigate = useNavigate();

  const handleLogin = () => {
    console.log("handleLogin called");
    setAuthenticated(true);
  };

  const handleLogoutConfirm1 = () => {
    setAuthenticated(false);
    navigate("/");
  };

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {!authenticated ? (
              <div className="login-container">
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <LoginPage
                        onLogin={handleLogin}
                        setAuthenticated={setAuthenticated}
                      />
                    }
                  />
                  <Route exact path="/not-found" element={<UserNotFound />} />
                  <Route
                    exact
                    path="/forget-password"
                    element={<ForgetPassword />}
                  />
                  <Route
                    exact
                    path="/reset-password"
                    element={<ResetPassword />}
                  />
                </Routes>
              </div>
            ) : (
              <>
                <div className="sidebar-container">
                  <Sidebar />
                </div>
                <div className="content-container">
                  <div className="topbar-container">
                    <Topbar
                      setIsSidebar={setIsSidebar}
                      setAuthenticated={setAuthenticated}
                    />
                  </div>
                  <div className="dashboard-container">
                    <div className="routes-wrapper">
                      <Routes>
                        {userDetails?.m_home && (
                          <Route
                            path="/"
                            element={<StaticDashboard />}
                            key="dashboard"
                          />
                        )}
                        {userDetails?.m_farm && (
                          <>
                            <Route
                              path="/inventory"
                              element={<InventoryHome />}
                              key="inventory"
                            />
                            <Route
                              exact
                              path="/inventory-data"
                              element={<InventoryData />}
                            />
                          </>
                        )}
                        {userDetails?.m_finance && (
                          <Route
                            exact
                            path="/expense"
                            element={<ExpenseMainPage />}
                          />
                        )}
                        {userDetails?.m_configuration && (
                          <>
                            <Route
                              exact
                              path="/mainpage"
                              element={<MainConfg />}
                            />
                            <Route
                              exact
                              path="/addon"
                              element={<MainConfg />}
                            />
                            <Route
                              exact
                              path="/fview"
                              element={<AllFarmView />}
                            />
                            <Route
                              exact
                              path="/cview"
                              element={<AllCropView />}
                            />
                            <Route
                              exact
                              path="/Farmerview"
                              element={<AllFarmerView />}
                            />
                          </>
                        )}
                        {userDetails?.m_reports && (
                          <Route
                            exact
                            path="/reports"
                            element={<ReportMainPage />}
                          />
                        )}

                        {userDetails?.m_user && (
                          <Route
                            exact
                            path="/user"
                            element={<UserRoleMainPage />}
                          />
                        )}

                        <Route path="*" element={<PageNotFound />} />
                        {!authenticated ? <Route path="/" replace /> : null}
                      </Routes>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}
function WMSLayout() {
  const userId = localStorage.getItem("user-id");
  const [loginComplete, setLoginComplete] = useState(false);
  // Retrieve the stored object from localStorage and parse
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const [theme, colorMode] = useMode();
  const currentUserId = localStorage.getItem("user-id");
  const allowedUserId = [
    "01HPGZX5JXS8FMV6ESAPPY9E6T",
    "01HMDZ7YB4TRC134JPTPBKH6D4",
  ];
  const [isAllowedUser, setIsAllowedUser] = useState(false);
  const [isSidebar, setIsSidebar] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(
    isExpanded ? "160px" : "50px"
  );
  const [contentWidth, setContentWidth] = useState(
    isExpanded ? "calc(100% - 170px)" : "calc(100% - 30px)"
  );

  useEffect(() => {
    if (allowedUserId.includes(currentUserId)) {
      setIsAllowedUser(true);
    }
  }, [currentUserId]);

  const handleSidebarToggle = () => {
    setIsExpanded(!isExpanded);
    // setSidebarWidth(isExpanded ? "80px" : "50px");
    // setContentWidth(isExpanded ? "calc(100% - 170px)" : "calc(100% - 30px)");
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="appwms">
          <>
            <div
              className={`sidebar-container ${isExpanded ? "expanded" : ""}`}
              style={{ width: sidebarWidth }}
            >
              <SidebarWMS
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                sidebarWidth={sidebarWidth}
                setSidebarWidth={setSidebarWidth}
                handleSidebarToggle={handleSidebarToggle}
                setLoginComplete={setLoginComplete}
              />
            </div>
            <div
              className="content-container"
              style={{
                marginLeft: isExpanded ? "8.3%" : "0.8%",
                paddingRight: isExpanded ? "8%" : "0%",
                width: contentWidth,
              }}
            >
              <div className="topbar-container">
                <TopBarWMS
                  setIsSidebar={setIsSidebar}
                  setAuthenticated={setAuthenticated}
                />
              </div>
              <div className="dashboard-container">
                <div className="routes-wrapper">
                  <Routes>
                    {!loginComplete ? (
                      <Route
                        path="/"
                        element={
                          <WMSLoginPage setLoginComplete={setLoginComplete} />
                        }
                      />
                    ) : (
                      <>
                        {userDetail?.m_home && (
                          <Route exact path="/home" element={<HomePage />} />
                        )}
                        {userDetail?.m_config && (
                          <Route
                            exact
                            path="/configuration"
                            element={<WMSConfigMainPage />}
                          />
                        )}
                        {userDetail?.b_config_add_vendor && (
                          <Route
                            exact
                            path="/addVendor"
                            element={<VendorConfig />}
                          />
                        )}
                        {userDetail?.b_config_view_vendor && (
                          <Route
                            exact
                            path="/viewVendor"
                            element={<VendorView />}
                          />
                        )}
                        {userDetail?.b_config_add_product && (
                          <Route
                            exact
                            path="/addProduct"
                            element={<ProductConfig />}
                          />
                        )}
                        {userDetail?.b_config_view_product && (
                          <Route
                            exact
                            path="/viewProduct"
                            element={<ProductView />}
                          />
                        )}
                        {userDetail?.b_config_add_sku && (
                          <Route exact path="/addSKU" element={<SKUConfig />} />
                        )}
                        {userDetail?.b_config_view_sku && (
                          <Route exact path="/viewSKU" element={<SKUView />} />
                        )}
                        {userDetail?.b_config_add_family && (
                          <Route
                            exact
                            path="/addFamily"
                            element={<FamilyConfig />}
                          />
                        )}
                        {userDetail?.b_config_view_family && (
                          <Route
                            exact
                            path="/viewFamily"
                            element={<FamilyView />}
                          />
                        )}
                        {userDetail?.b_config_add_customergroup && (
                          <Route
                            exact
                            path="/addCustomerGroup"
                            element={<CustomerGroupConfig />}
                          />
                        )}
                        {userDetail?.b_config_view_customergroup && (
                          <Route
                            exact
                            path="/viewCustomerGroup"
                            element={<CustomerGroupView />}
                          />
                        )}
                        {userDetail?.b_config_add_customer && (
                          <Route
                            exact
                            path="/addCustomer"
                            element={<CustomerMasterConfig />}
                          />
                        )}
                        {userDetail?.b_config_view_customer && (
                          <Route
                            exact
                            path="/viewCustomer"
                            element={<CustomerView />}
                          />
                        )}

                        {userDetail?.b_config_view_transport && (
                          <Route
                            exact
                            path="/viewTransport"
                            element={<TransportView />}
                          />
                        )}
                        {userDetail?.b_config_add_transport && (
                          <Route
                            exact
                            path="/addTransport"
                            element={<TransportConfig />}
                          />
                        )}
                        {userDetail?.m_cycle_count && (
                          <Route
                            exact
                            path="/cyclecount"
                            element={<MainPage />}
                          />
                        )}
                        {userDetail?.b_cycle_count_perform_cycle_count && (
                          <Route
                            exact
                            path="/performcyclecount"
                            element={<PerformCycleCount />}
                          />
                        )}

                        {userDetail?.m_inventory && (
                          <Route
                            exact
                            path="/inventory"
                            element={<InventoryMainPage />}
                          />
                        )}
                        {userDetail?.m_integration && (
                          <Route
                            exact
                            path="/integration"
                            element={<IntegrationMainPage />}
                          />
                        )}
                        
                        {userDetail?.m_profit_n_loss && (
                          <Route exact path="/pnl" element={<PNLReport />} />
                        )}
                        
                        {userDetail?.m_orders && (
                        <Route exact path="/so" element={<SOMainPage />} /> )}
                        {userDetail?.b_orders_create_order && (
                        <Route exact path="/addso" element={<SONewAddPage />} /> )}
                        
                        {userDetail?.m_purchase_order && (
                        <Route exact path="/po" element={<POMainPage />} /> )}
                        
                        
                        <Route exact path="/addpo" element={<PONewAddPage />} /> 
                        <Route exact path="/grn" element={<GRNMainPage />} />
                        {userDetail?.m_reports && (
                          <Route
                            exact
                            path="/reports"
                            element={<ReportsMainPage />}
                          />
                        )}
                        <Route exact path="/users" element={<UserMainPage />} />
                        {userDetail?.m_stock_transfer && (
                          <Route exact path="/STO" element={<STOMainPage />} />
                        )}
                        {userDetail?.b_stock_transfer_perform_stock_transfer && (
                          <Route exact path="/addsto" element={<STOAdd />} />
                        )}
                        
                            
                           
                        
                      </>
                    )}
                    <Route
                    exact
                    path="/userconfig"
                    element={<CDMainPage />}
                  /> 
                  <Route
                    exact
                    path="/wmsuser"
                    element={<WMSUserPage />}
                  /> 
                  <Route
                    exact
                    path="/omsuser"
                    element={<OMSUserViewPage />}
                  />

                    <Route path="/*" element={<UnknownPage />} />
                   
                  </Routes>
                </div>
              </div>
              <div className="footer-container">
                <FooterWMS />
              </div>
            </div>
          </>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function OMSLayout() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<OMSLoginPage />} />
          <Route path="/sourcing" element={<ProcurementMainPage />} />
          <Route path="/approver" element={<AdminMainPage />} />
          <Route path="/analytics" element={<AnalyticsMainPage />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function AttendanceLayout() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app3">
          <>
            <div className="sidebar-container">
              <SideBarAtt />
            </div>
            <div className="content-container">
              <div className="topbar-container">
                <TopBarAtt
                  setIsSidebar={setIsSidebar}
                  setAuthenticated={setAuthenticated}
                />
              </div>
              <div className="dashboard-container">
                <div className="routes-wrapper">
                  <Routes>
                    <Route path="/" element={<AttLoginPage />} />
                    <Route exact path="/home" element={<AttHomePage />} />
                    <Route exact path="/user" element={<AttUserPage />} />
                    <Route exact path="/report" element={<AttReportPage />} />
                  </Routes>
                </div>
              </div>
            </div>
          </>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function App() {
  return (
    <Routes>
      
      <Route path="/oms/*" element={<OMSLayout />} />
      <Route
        path="/*"
        element={
          <Provider store={store}>
            <AuthProvider>
              <WMSLayout />
            </AuthProvider>
          </Provider>
        }
      />
      <Route path="/attendance/*" element={<AttendanceLayout />} />
    </Routes>
  );
}

export default App;
