import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Typography, Grid, IconButton, useTheme, Alert } from "@mui/material";
import {
  Dialog,
  DialogActions,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import VegImg from "../../src/Assets/healthy-food.png";
import { tokens } from "../../src/theme";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import TopBarForOMS from "./TobBarForOMS";
import { BASE_OMS_URL, API_ENDPOINTS } from "../ApiEndpoint/ApiEndpoint";

let token = localStorage.getItem("token");
let userId = localStorage.getItem("user_id");
let userRole = localStorage.getItem("userRole");
const axiosInstance = axios.create({
  baseURL: BASE_OMS_URL,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const AdminMainPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [adminFlag, setAdminFlag] = useState(true);
  const [executiveFlag, setExecutiveFlag] = useState(true);
  const [misId, setMisId] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [offset, setOffset] = useState(1);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      const data = await fetchData(offset);
      if (data) {
        setTotalPages(data?.total_pages);
        setMisId(data?.mis_id);
        setAdminFlag(data?.a_flag);
        setExecutiveFlag(data?.e_flag);
        setData(data?.cropDetails);
      }
    };
    fetchInitialData();
  }, []);

  const handleOpenLogoutDialog = () => {
    setLogoutDialogOpen(true);
  };
  const handleCloseLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };

  const handleLogout = () => {
    // localStorage?.removeItem('token');
    localStorage?.clear();
    // console.log("token expire in OMS , ",localStorageAvailable());
    // console.log("token expire IN OMS , ",localStorage?.getItem('token'));
    navigate("/oms");
  };

  const fetchData = async (newPage) => {
    setIsLoading(true);
    const token = localStorage?.getItem("token");
    try {
      while (!token) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      const axiosInstance = axios.create({
        baseURL: BASE_OMS_URL,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const response = await axiosInstance.get(
        `${BASE_OMS_URL}${API_ENDPOINTS?.GET_OMS_PROCUREMENT(50, newPage)}`
      );
      setIsLoading(false);

      if (response.status >= 200 && response.status < 305) {
        const responseData = response?.data?.data;
        setTotalPages(responseData?.total_pages);
        setMisId(responseData?.mis_id);
        setAdminFlag(responseData?.a_flag);
        setExecutiveFlag(responseData?.e_flag);
        setData(responseData?.cropDetails);
        return responseData;
      } else {
        return null;
      }
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };

  const handleDataSave = async () => {
    setIsLoading(true);
    setAdminFlag(data[0]?.a_flag);
    const updatedDataToSend = data?.map((item, index) => ({
      ...item,
      e_flag: executiveFlag,
      a_flag: adminFlag,
      id: userId,
      role: userRole,
    }));

    try {
      const response = await axiosInstance.post(
        `${BASE_OMS_URL}${API_ENDPOINTS.POST_OMS_PROCUREMENT_UPDATE_DATA(
          misId,
          false,
          true
        )}`,
        updatedDataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    handleDataSave();
    setOffset(newPage);
    fetchData(newPage);
  };

  const handleSubmit = async () => {
    // console.log("handleSubmit clicked");
    setIsLoading(true);

    const updatedDataToSend = data?.map((item, index) => ({
      ...item,
      e_flag: false,
      a_flag: false,
      id: userId,
      role: userRole,
    }));

    const hasEditedData = updatedDataToSend?.some((item, index) => {
      return item?.someProperty !== data[index]?.someProperty;
    });

    const a_flag1 = hasEditedData ? true : false;
    updatedDataToSend?.forEach((item) => {
      item.a_flag = a_flag1;
    });

    const token = localStorage.getItem("token");
    try {
      while (!token) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      const axiosInstance = axios.create({
        baseURL: BASE_OMS_URL,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const response = await axiosInstance?.post(
        `${BASE_OMS_URL}${API_ENDPOINTS.POST_OMS_PROCUREMENT_UPDATE_DATA(
          misId,
          false,
          false
        )}`,
        updatedDataToSend
      );
      setIsLoading(false);
      if (response?.status >= 200 && response?.status < 305) {
        setAdminFlag(!adminFlag);
        setSuccessDialogOpen(true);
        setSuccessMessage("Great, you have approved MIS successfully.");
        fetchData(1);
        setIsLoading(true);

        // console.log("Data updated successfully");
      } else {
        // console.error("Error updating data:", response?.statusText);
      }
    } catch (error) {
      setIsLoading(false);
      // console.error("Error updating data:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Family",
        size: 2,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: "#f3f3f3",
            textAlign: "center",
          },
        },
        columns: [
          {
            header: "Family Items",
            accessorKey: "family",
            enableEditing: false,

            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            Cell: ({ cell }) => {
              const capitalizeFirstLetter = (string) => {
                return string?.charAt(0)?.toUpperCase() + string?.slice(1);
              };
              return (
                <div className="tooltip-cell">
                  <IconButton className="icon-wrapper">
                    <img
                      src={VegImg}
                      alt="Stages"
                      className="image"
                      style={{ height: "10px", width: "10px" }}
                    />
                  </IconButton>
                  <span className="farm-name">
                    {capitalizeFirstLetter(cell?.getValue())}
                  </span>
                </div>
              );
            },
          },
        ],
      },
      {
        header: "Fresh",
        size: 2,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: "#f3f3f3",
            textAlign: "center",
          },
        },
        columns: [
          {
            header: "Code",
            accessorKey: "code",
            size: 1,
            enableEditing: false,
            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            Cell: ({ row }) => {
              const crop = row?.original?.crops?.find(
                (c) => c?.type === "fresh"
              );
              return <span>{crop ? crop?.code : "-"}</span>;
            },
          },
          {
            header: "Rate",
            accessorKey: "fresh_mis",
            size: 1,
            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                backgroundColor: "#ffffff",
                textAlign: "center",
              },
            },
            Cell: ({ row }) => {
              const { original } = row;
              const { family, crops } = original;
              const crop = crops.find((c) => c.type === "fresh");

              const [inputValue, setInputValue] = useState(
                crop ? crop.fresh_mis : ""
              );

              useEffect(() => {
                setInputValue(crop ? crop.fresh_mis : "");
              }, [crop]);

              const handleMisChange = (e) => {
                const { value } = e.target;

                setData((prevData) =>
                  prevData.map((item) => {
                    if (item.family === family) {
                      const updatedCrops = item.crops.map((c) => {
                        if (c.type === "fresh" && c.id === crop.id) {
                          return { ...c, fresh_mis: value };
                        }
                        return c;
                      });
                      return { ...item, crops: updatedCrops };
                    }
                    return item;
                  })
                );
              };

              return (
                <TextField
                  type="number"
                  value={inputValue}
                  onChange={handleMisChange}
                  disabled={!executiveFlag}
                  style={{
                    border: "none",
                    outline: "none",
                    background: "transparent",
                  }}
                />
              );
            },
          },
        ],
      },
      {
        header: "MT",
        size: 2,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: "#f3f3f3",
            textAlign: "center",
          },
        },

        columns: [
          {
            header: "Code",
            accessorKey: "code",
            size: 1,
            enableEditing: false,
            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            Cell: ({ row }) => {
              const crop = row?.original?.crops?.find((c) => c?.type === "mt");
              return <span>{crop ? crop?.code : "-"}</span>;
            },
          },
          {
            header: "Rate",
            accessorKey: "mt_mis",
            size: 1,
            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                backgroundColor: "#ffffff",
                textAlign: "center",
              },
            },

            Cell: ({ row }) => {
              const { original } = row;
              const { family, crops } = original;
              const crop = crops.find((c) => c.type === "mt");

              const [inputValue, setInputValue] = useState(
                crop ? crop.mt_mis : ""
              );

              useEffect(() => {
                setInputValue(crop ? crop.mt_mis : "");
              }, [crop]);

              const handleMisChange = (e) => {
                const { value } = e.target;

                setData((prevData) =>
                  prevData.map((item) => {
                    if (item.family === family) {
                      const updatedCrops = item.crops.map((c) => {
                        if (c.type === "mt" && c.id === crop.id) {
                          return { ...c, mt_mis: value };
                        }
                        return c;
                      });
                      return { ...item, crops: updatedCrops };
                    }
                    return item;
                  })
                );
              };

              return (
                <TextField
                  type="number"
                  value={inputValue}
                  onChange={handleMisChange}
                  disabled={!executiveFlag}
                  style={{
                    border: "none",
                    outline: "none",
                    background: "transparent",
                  }}
                />
              );
            },
          },
        ],
      },
      {
        header: "Normal",
        size: 2,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: "#f3f3f3",
            textAlign: "center",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#ffffff",

            textAlign: "center",
          },
        },
        columns: [
          {
            header: "Code",
            accessorKey: "code",
            size: 1,
            enableEditing: false,
            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",

                textAlign: "center",
              },
            },
            Cell: ({ row }) => {
              const crop = row?.original?.crops?.find(
                (c) => c?.type === "normal"
              );
              return <span>{crop ? crop?.code : "-"}</span>;
            },
          },
          {
            header: "Rate",
            accessorKey: "normal_mis",
            size: 1,
            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "#f3f3f3",
                textAlign: "center",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                backgroundColor: "#ffffff",
                textAlign: "center",
              },
            },
            Cell: ({ row }) => {
              const { original } = row;
              const { family, crops } = original;
              const crop = crops.find((c) => c.type === "normal");

              const [inputValue, setInputValue] = useState(
                crop ? crop.normal_mis : ""
              );

              useEffect(() => {
                setInputValue(crop ? crop.normal_mis : "");
              }, [crop]);

              const handleMisChange = (e) => {
                const { value } = e.target;

                setData((prevData) =>
                  prevData.map((item) => {
                    if (item.family === family) {
                      const updatedCrops = item.crops.map((c) => {
                        if (c.type === "normal" && c.id === crop.id) {
                          return { ...c, normal_mis: value };
                        }
                        return c;
                      });
                      return { ...item, crops: updatedCrops };
                    }
                    return item;
                  })
                );
              };

              return (
                <TextField
                  type="number"
                  value={inputValue}
                  onChange={handleMisChange}
                  disabled={!executiveFlag}
                  style={{
                    border: "none",
                    outline: "none",
                    background: "transparent",
                  }}
                />
              );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <TopBarForOMS />
      {isLoading ? (
        <div
          className="loader-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "rgba(0, 128, 0, 0.1)",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <ScaleLoader
            color={colors.green.DEFAULT}
            loading={isLoading}
            size={150}
          />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%",
            height: "95%",
            // bgcolor: "green", // Set your desired background color
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "0.5%",
              paddingLeft: "10px",
            }}
          >
            <Typography variant="h6" color={colors.red[100]}>
              Approve MIS (Latest MIS can be approved only once)
            </Typography>
            <div style={{ paddingRight: "10px" }}>
              <Button
                variant="outlined"
                startIcon={<LogoutIcon style={{ color: colors.white[100] }} />}
                sx={{
                  backgroundColor: colors.red[100],
                  borderColor: colors.white.DEFAULT,
                  color: colors.white.DEFAULT,
                  height: "20px",
                  padding: "12px",
                  width: "200px",
                }}
                onClick={handleOpenLogoutDialog}
              >
                <Typography color={colors.white.DEFAULT}>Logout</Typography>
              </Button>
            </div>
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "100%",
              height: "100%",
              // bgcolor: "green", // Set your desired background color
            }}
          >
            <MaterialReactTable
              columns={columns}
              data={data}
              muiTableHeadCellProps={{
                sx: {
                  fontWeight: "650",
                  color: "#2c7B55",
                  fontSize: "10px",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  "& tr": {
                    height: "25px",
                  },
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f8fafc",
                  },
                },
              }}
              initialState={{
                density: "compact",
              }}
              muiTableBodyCellProps={{
                sx: {
                  flex: "0 0 auto",
                  padding: "0 0 0 0",
                  fontSize: "3px",
                  height: "1px",
                },
              }}
              muiTableContainerProps={{ sx: { maxHeight: "480px" } }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableStickyHeader
              enableSorting={false}
              enableTopToolbar={false}
              enablePagination={false}
              enableRowVirtualization
              enableBottomToolbar={false}
            />
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginTop: "-90%",
            }}
          >
            <Pagination
              count={totalPages}
              page={offset}
              onChange={handleChangePage}
              size="small"
            />
          </div>
          <Button
            variant="contained"
            style={{
              marginTop: "2px",
              height: "30px",
              backgroundColor:
                executiveFlag === adminFlag ? "#f3f3f3" : "#2c7B55",
            }}
            onClick={handleSubmit}
            disabled={!adminFlag || (executiveFlag && adminFlag)}
          >
            <Typography
              variant="h5"
              color={executiveFlag === adminFlag ? "grey" : "white"}
            >
              {adminFlag
                ? executiveFlag
                  ? "Not Yet Submitted"
                  : "Approve"
                : "Approved"}
            </Typography>
          </Button>
        </Box>
      )}
      <Dialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon
            style={{ width: "60px", height: "60px", color: "#198754" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {successMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSuccessDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={logoutDialogOpen}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {showAlert && (
        <Alert
          severity="error"
          onClose={() => setShowAlert(false)}
          className="alert-slide-in"
          style={{
            position: "fixed",
            top: "8px",
            zIndex: 1000,
            transition: "right 0.3s ease-in-out",
          }}
        >
          You cannot edit this , it has already been approved!
        </Alert>
      )}
    </>
  );
};

export default AdminMainPage;
