import React from "react";
import { tokens } from "../WMStheme";
import { useTheme, Grid, Typography } from "@mui/material";
import ListOfCycleCount from "./ListOfCycleCount";

const MainPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Cycle Count
        </Typography>
      </Grid>

      <Grid>
        <ListOfCycleCount />
      </Grid>
    </>
  );
};

export default MainPage;
