import React, { useState } from "react";
import "./ControlBox.css";
import { Button, Switch, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import Card from "@mui/material/Card";
import irrigationgif from "../../../Assets/irrigationgif.gif";
import { tokens } from "../../../theme";

import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import SaveAlert from "../../../SaveAlert";
import ApiErrorAlert from "../../../ApiErrorAlert";
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
function ControlBox(props) {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [isOn, setIsOn] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleButtonClick = () => {
    // fetch(
    //   `${BASE_URL}${API_ENDPOINTS.POST_STATIC_DASHBOARD_CONTROL(props.deviceName)}`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify({ is_on: !isOn }),
    //     headers: {
    //       "content-Type": "application/json",
    //       client_id: client_id,
    //     },
    //   }
    // )
    axiosInstance
      .post(`${API_ENDPOINTS.POST_STATIC_DASHBOARD_CONTROL(props.deviceName)}`)
      .then((response) => response.json())
      .then((data) => {
        // set the state of the on/off switch based on the response from the server
        setIsOn(data.is_on);
      })
      .catch((error) => {
        setApiErrorOpen(true);
        console.log("Error updating device status:", error);
      });
  };

  return (
    <div className="control-box">
      <div className="control-box-item">
        <div className="left">
          <Typography
            variant="h6"
            color={colors.black[200]}
            fontFamily="Lexend Deca"
          >
            {props.deviceName}
          </Typography>
        </div>
        <div className="right">
          {/* <Button
            variant={isOn ? "contained" : "outlined"}
            onClick={handleButtonClick}
          >
            {isOn ? "ON" : "OFF"}
          </Button> */}
          <Switch
            checked={isOn}
            onChange={() => setIsOn(!isOn)}
            disabled={isLoading}
            sx={{
              "& .MuiSwitch-track": {
                backgroundColor: isOn ? "green" : "gray",
              },
              "& .MuiSwitch-thumb": {
                backgroundColor: isOn ? "white" : "white",
              },
              fontWeight: "bold",
              fontSize: "16px",
            }}
          />
        </div>
      </div>
    </div>
  );
}

function Control() {
  const theme = useTheme();
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  return (
    <>
      <Card
        sx={{
          width: "80%",
          height: "96%",
          marginTop: "20px",
          backgroundColor: "#ecf4f0",
        }}
      >
        <div style={{ width: "100%", height: "100%", marginTop: "10px" }}>
          {/* <h5
          className="textstyle"
          style={{ fontWeight: "bold", fontSize: "16px" }}
        >
          Irrigation Control Unit
        </h5> */}
          <Typography
            style={{ marginLeft: "8px" }}
            variant="h4"
            color={colors.black.DEFAULT}
            fontFamily="Lexend Deca"
          >
            Irrigation
            <img
              src={irrigationgif}
              alt="GIF"
              style={{ marginLeft: "5px", width: "25px", height: "25px" }}
            />
          </Typography>

          <div className="deviceName">
            <ControlBox deviceName="Zone1" />
            <ControlBox deviceName="Zone2" />
            <ControlBox deviceName="Zone3" />
            <ControlBox deviceName="Zone4" />
          </div>
        </div>
      </Card>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
}

export default Control;
