import React, { useState, useEffect, useRef, useMemo } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { TbTransferIn } from "react-icons/tb";

import TableRow from "@mui/material/TableRow";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { MdModeEdit } from "react-icons/md";
import NullScreen from "../PopUpMsg/NullScreen";
import { ScaleLoader } from "react-spinners";
import { CiCalendarDate } from "react-icons/ci";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import Pagination from "@mui/material/Pagination";
import { MaterialReactTable } from "material-react-table";
import { IoSaveOutline } from "react-icons/io5";
import { AlertDialogBox } from "../PopUpMsg/AlertDialog";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { TableChart as TableChartIcon } from "@mui/icons-material"; // Importing an icon from @mui/icons-material

import {
  Grid,
  Button,
  Select,
  Box,
  Autocomplete,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  Checkbox,
  TextField,
  ThemeProvider,
  MenuItem,
  createTheme,
  IconButton,
  InputLabel,
  FormControl,
  Typography,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { IoIosPerson } from "react-icons/io";
import { TiTickOutline } from "react-icons/ti";
import { GiConsoleController, GiFruitBowl } from "react-icons/gi";
import Paper from "@mui/material/Paper";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import { tokens } from "../WMStheme";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const gridContainerStyle = {
  marginTop: "0.3%",
  //   display: "flex",
  height: "74%",
  width: "100%",
  overflowY: "hidden",
  //  backgroundColor: "red",
};
const containerStyle8 = {
  // maxHeight: "70vh",
  // height: "80%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  //  backgroundColor: "pink",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};

const FamilyTransfer = ({ setOpenFamilyTransfer, count , setCount }) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const oneWeekAgo = new Date();
  const [skuNames, setSkuNames] = useState([]);
  const [fromSKUUom, setFromSkUUom] = useState("");
  const [toSKUUom, setToSkUUom] = useState("");
  const [skuId, setSkuId] = useState(null);
  const [toskuId, setToSkuId] = useState(null);
  const [fromSKUID, setFromSKUID] = useState(null);
  const [toSKUID, setToSKUID] = useState(null);
  const [skuCode, setSkuCode] = useState("");
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  const [sTOData, setSTOData] = useState([]);
//   const [count, setCount] = useState("1");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorPopupOpen3, setErrorPopupOpen3] = useState(false);
  // const [sTOData, setSTOData] = useState(dummyData);
  const [errorMessage, setErrorMessage] = useState("");
  const [familyData, setFamilyData] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [fromwarehouseId, setfromWarehouseId] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [secondwarehouseData, setSecondWarehouseData] = useState([]);
  const [towarehouseId, setToWarehouseId] = useState(null);
  const [familyId, setFamilyId] = useState("");
  const [fromSKUAvailQty, setFromSKUAvailQty] = useState("");
  const [toSKUAvailQty, setToSKUAvailQty] = useState("");
  const [fromSKUTransferQty, setFromSKUTransferQty] = useState("");
  const [applyClick, setApplyClick] = useState(false);
  const [draftrowData, setDraftRowData] = useState([]);
  const [warehouseId, setWarehouseId] = useState("");
  const [selectedType, setSelectedType] = useState("sku");
  const [productCode, setProductCode] = useState("");
  const [productName, setProductName] = useState("");
  const [editedTransferQty, setEditedTransferQty] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const [uomFilteredData, setUomFilteredData] = useState([]);
  const [rows, setRows] = useState([]);
  const [updatedRowsArray, setUpdatedRowsArray] = useState([]);
  const [addedSKUMasterData, setAddedSKUMasterData] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // This state keeps track of the index of the row to be updated.
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
const [displayDraft , setDisplayDraft] =useState(false);
  // Function to handle changes in transfer_qty
  const [stoLoading, setStoLoading] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      if (Array.isArray(parsedWarehouseData)) {
        setWarehouseData(parsedWarehouseData);
        setSecondWarehouseData(parsedWarehouseData);
      }
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  const [filterValues, setFilterValues] = useState({
    warehouse_id: fromwarehouseId,
    family_id: familyId,
    limit: 1000,
    offset: 1,
    type: "sku",
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    warehouse_id: fromwarehouseId,
    family_id: familyId,
    limit: 1000,
    offset: 1,
    type: "sku",
  });
  useEffect(() => {
    // Check if both fromwarehouseId and towarehouseId are not null before calling fetchSTODatawrtWarehouse
    if (fromwarehouseId !== null && familyId !== null) {
      const updatedFilterValues = {
        warehouse_id: fromwarehouseId,
        family_id: familyId,
        limit: 1000,
        offset: 1,
        type: "sku",
      };
      setTemporaryFilterValues(updatedFilterValues);
      if (applyClick && fromwarehouseId && familyId) {
        fetchSKUForFamilyTransfer(updatedFilterValues);
      }
      // Call the function only if both IDs are not null
    }
  }, [fromwarehouseId, familyId, applyClick]);

  const handleApplyClick = () => {
    const newFilterValues = {
      warehouse_id: fromwarehouseId,
      family_id: familyId,
      limit: 1000,
      offset: 1,
      type: "sku",
    };
    setApplyClick(true);

    setWarehouseId(temporaryFilterValues?.warehouse_id);
    setFamilyId(temporaryFilterValues?.family_id);

    setFilterValues(newFilterValues);
    setDisplayDraft(true);
    if (applyClick && fromwarehouseId && familyId) {
      fetchSKUForFamilyTransfer(newFilterValues);
    }
  };
  useEffect(() => {
    fetchFamilyData();
    if (applyClick && fromwarehouseId && familyId) {
      fetchSKUForFamilyTransfer(filterValues);
    }
  }, []);
  const fetchFamilyData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_FAMILY_DATA}`,
        {
          params: {
            limit: 1000,
            offset: 1,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = response.data?.data?.family_list;
        setFamilyData(data);
        console.log("family Data, ", data); // Assuming the response is an array of family options
      } else {
        console.log("Error fetching family data");
      }
    } catch (error) {
      console.error("Error fetching family data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSKUForFamilyTransfer = async (newParams) => {
    console.log("warehouse id ", fromwarehouseId);
    console.log("family iD", familyId);
    console.log("applyClick", applyClick);
    if (!applyClick && !fromwarehouseId && !familyId) {
      return;
    }

    console.log("params in inventorydata function in sku filter", newParams);
    // Check if warehouseid is not null before making the API call
    if (newParams?.type === "sku") {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          API_ENDPOINTS.GET_WMS_INVENTORY_DATA,
          {
            params: newParams,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 305) {
          const data = await response?.data?.data;
          console.log("data", data);
          const skuNamesArray =
            data?.inv_list?.map((item) => item.sku_name) || [];
          console.log("sku names stored in an array ", skuNamesArray);
          setSkuNames(data?.inv_list); // Store the array of SKU names in skunames state

          setRows(data?.inv_list);

          setIsLoading(false);
        } else {
          setErrorMessage(response?.data?.msg);
          console.log("Error fetching details");
        }
      } catch (error) {
        console.log("Error fetching data:", error);
        setIsLoading(false);
        setErrorMessage("Please Try Again");
        setErrorPopupOpen(true);
      }
    } else {
      console.log("type is null, API call skipped");
    }
  };

  useEffect(() => {
    const updatedFilterValues = {
      // start_date: startDate,
      // end_date: endDate,
      limit: 15,
      offset: offset,
      warehouse_id: towarehouseId,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [offset, towarehouseId]);
  useEffect(() => {
    // Create a new object with the updated data
    const updatedRow = {
      from_sku: skuId,
      from_sku_uom: fromSKUUom,
      from_avl_qty: fromSKUAvailQty,
      transfer_qty: fromSKUTransferQty,
      to_sku: toskuId,
      to_sku_uom: toSKUUom,
      to_avl_qty: toSKUAvailQty,
    };

    // Update the existing row in the draftRowData array
    setDraftRowData((prevData) => {
      // If there's no currentRowIndex, this is the first update, so add the row to the array
      if (currentRowIndex === null) {
        setCurrentRowIndex(0);
        return [updatedRow];
      }

      // Create a copy of the existing array
      const newData = [...prevData];

      // Update the existing row at the specified index
      newData[currentRowIndex] = updatedRow;

      // Return the updated array
      return newData;
    });

    // Optionally, increment count if you want to re-render the component
    // setCount((prevCount) => prevCount + 1);
  }, [
    skuId,
    fromSKUUom,
    fromSKUAvailQty,
    fromSKUTransferQty,
    toskuId,
    toSKUUom,
    toSKUAvailQty,
  ]);

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleBackToSTOViewPage = () => {
    navigate("/STO");
  };
  const handleTransferQtyChange = (rowIndex, value) => {
    console.log(
      "Updating transfer_qty for rowIndex:",
      rowIndex,
      "with value:",
      value
    );
    setEditedTransferQty((prev) => ({ ...prev, [rowIndex]: value }));
  };

  const [selectedRowsState, setSelectedRowsState] = useState([]);

  const handleRefreshClick = () => {
    const initialValues = {
      limit: 1000,
      offset: 1,
      warehouse_id: fromwarehouseId,
      family_id: familyId,
    };

    setToWarehouseId(null);
    setfromWarehouseId(null);
    setFamilyId(null);
    setApplyClick(false);
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    setDisplayDraft(false);
  };
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#0A0A0A",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#040404", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const handleCloseFamilyTransferDialog = () => {
    setOpenFamilyTransfer(false);
  };
  const createFamilySTONew = async () => {
    console.log(" create sto function called ");
    try {
      // Check if there are any edited rows

      // Map selected rows to create inv_list
      const inv_list = {
        from_sku_id: fromSKUID,
        warehouse_id: fromwarehouseId,
        transfer_qty: fromSKUTransferQty,
        to_sku_id: toSKUID,
      };
      console.log("inv list outside  POST function : ", inv_list);

      console.log("to warehouse id ", towarehouseId);
      console.log("from warehouse id ", fromwarehouseId);
      setStoLoading(true);
      // Determine the endpoint for creating a new transport
      const endpoint = API_ENDPOINTS.POST_WMS_STO_ADD_NEW(
        fromwarehouseId,
        fromwarehouseId,
        "received",
        "family"
      );
      console.log("API Endpoint:", endpoint);
      console.log("Creating Count Data:", updatedRowsArray);
      console.log("inv list in POST function : ", inv_list);

      // Send the POST request to create a new transport
      const response = await axiosInstance.post(
        endpoint,
        {
          inv_list: inv_list,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Stock Transfer Order created successfully!");
        setSuccessPopupOpen(true);
        setOpenFamilyTransfer(false);
        setStoLoading(false);
         setCount((prevcount) => prevcount + 1);
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error("Failed to create the Stock Transfer Order ");
        setErrorMessage(
          "Failed to create the Stock Transfer Order. Please try again."
        );
      }
    } catch (error) {
      setErrorPopupOpen(true);
      setStoLoading(false);
      console.error(
        "Error occurred while creating the Stock Transfer Order ",
        error
      );
    }
  };
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const columns = useMemo(
    () => [
      {
        header: "From Sku",
        accessorKey: "from_sku",
        size: 10,
        enableEditing: false,
      },
      {
        header: "From SKU UOM",
        accessorKey: "from_sku_uom",
        size: 10,
        enableEditing: false,
      },

      {
        header: "Available Qty",
        accessorKey: "from_avl_qty",
        size: 10,
        enableEditing: false,
      },
      {
        header: "Transfer Qty",
        accessorKey: "transfer_qty",
        size: 10,
        enableEditing: false,
      },
      {
        header: "To Sku",
        accessorKey: "to_sku",
        size: 10,
        enableEditing: false,
      },
      {
        header: "To SKU UOM",
        accessorKey: "to_sku_uom",
        size: 10,
        enableEditing: false,
      },

      {
        header: "To Available Qty",
        accessorKey: "to_avl_qty",
        size: 10,
        enableEditing: false,
      },
    ],
    [
      inputValues,
      selectedRows,
      draftrowData,
      setAddedSKUMasterData,
      updatedRowsArray,
    ]
  );

  const handleAutocompleteTOSKUChange = (event, newValue) => {
    // If an SKU is selected
    console.log("newValue", newValue);

    if (newValue) {
      setToSkuId(newValue?.sku_name);
      setToSKUID(newValue?.id);
      setToSkUUom(newValue?.uom);
      setToSKUAvailQty(newValue?.total_avl_qty);
      setRefreshCount((prevCount) => prevCount + 1);
      console.log("newValue?.uom", newValue?.uom);
      // Update the UOM state based on the selected SKU
    } else {
      setToSkuId("");
      // setFromSkUUom(''); // Reset UOM state when no SKU is selected
    }
  };
  const handleAutocompleteChange = (event, newValue) => {
    // If an SKU is selected
    console.log("newValue", newValue);

    if (newValue) {
      setSkuId(newValue?.sku_name);
      setFromSKUID(newValue?.id);
      setFromSkUUom(newValue?.uom);
      // Filter rows based on selected row's UOM
      const selectedUOM = newValue?.uom;
      const selectedSkuId = newValue?.id;
      // Filter rows based on selected SKU ID and UOM
  const filteredData = skuNames?.filter(
    (dataRow) => dataRow?.id !== selectedSkuId && dataRow?.uom === selectedUOM
  );
      setUomFilteredData(filteredData);
      console.log("filteredDatafilteredData===>>>", filteredData);
      setFromSKUAvailQty(newValue?.total_avl_qty);
      setRefreshCount((prevCount) => prevCount + 1);
      console.log("newValue?.uom", newValue?.uom);
      console.log("newValue?.uom  fromSKUUom", fromSKUUom); // Update the UOM state based on the selected SKU
    } else {
      setSkuId("");
      setFromSkUUom(""); // Reset UOM state when no SKU is selected
    }
  };
  const handleFromSKUTransferQtyChange = (event) => {
    let inputValue = parseFloat(event.target.value);

    if (isNaN(inputValue)) {
      // If the input value is not a number, reset it to an empty string
      setFromSKUTransferQty("");
    } else {
      // If the input value exceeds the available quantity, set it to the available quantity
      if (inputValue > fromSKUAvailQty) {
        setFromSKUTransferQty(fromSKUAvailQty);
      } else {
        // Otherwise, set the value to the user's input
        setFromSKUTransferQty(inputValue);
      }
    }
  };

  const handleClose = () => {
    setOpenFamilyTransfer(false);
  };
  return (
    <>
      <Dialog
        maxWidth="lg"
        fullScreen
        open={true}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <DialogTitle sx={{ position: "sticky", height: "8vh" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1%",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row", // Stack items vertically
                alignItems: "center",
                marginTop: "1%",
              }}
            >
              <Typography variant="h2" color={colors.green.DEFAULT}>
                Inter Family Stock Transfer
              </Typography>
            </Grid>
          </Grid>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 1.9,
              top: 1,
              color: "grey",
            }}
          >
            <CloseSharpIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {stoLoading ? (
            <>
              {" "}
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={stoLoading} />
              </div>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "100%",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ height: "10vh" }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                      <FormControl fullWidth>
                        <InputLabel>Select Warehouse</InputLabel>
                        <Select
                          label="Select Warehouse"
                          value={fromwarehouseId}
                          onChange={(e) => {
                            setfromWarehouseId(e.target.value);
                          }}
                          size="small"
                          displayEmpty
                          MenuProps={{
                            PaperProps: {
                              style: { maxHeight: "150px" }, // Set the max-height for the menu
                            },
                          }}
                          inputProps={{ "aria-label": "Select Warehouse" }}
                          startAdornment={
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <CiCalendarDate />
                              </IconButton>
                            </InputAdornment>
                          }
                        >
                          {Array.isArray(warehouseData) &&
                            warehouseData.map((day) => (
                              <MenuItem key={day?.id} value={day?.id}>
                                {day?.wh_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="combo-box-demo"
                          options={familyData || []}
                          getOptionLabel={(option) =>
                            capitalizeFirstLetter(option?.family_name)
                          }
                          value={
                            familyData?.find(
                              (group) => group?.id === familyId
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            setFamilyId(newValue ? newValue?.id : null);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Family"
                              size="small"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      edge="start"
                                      size="small"
                                      disabled
                                    >
                                      <GiFruitBowl />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={0.8} md={0.8}>
                      <Button
                        variant="contained"
                        startIcon={
                          <TiTickOutline style={{ fontSize: "26px" }} />
                        }
                        sx={{
                          backgroundColor: "#34519fe6",
                          color: "#ffffff",
                        }}
                        size="small"
                        onClick={handleApplyClick}
                        disabled={!familyId}
                      >
                        Apply
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={0.8} md={0.8}>
                      <Button
                        variant="contained"
                        startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                        size="small"
                        sx={{
                          backgroundColor: "#34519fe6",
                          color: "#ffffff",
                        }}
                        onClick={handleRefreshClick}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}></Grid>
                    <Grid item xs={12} sm={2.4} md={2.4} textAlign="right">
                      <Button
                        variant="contained"
                        startIcon={
                          <IoSaveOutline
                            style={{
                              color: colors.white.DEFAULT,
                              fontSize: 26,
                            }}
                          />
                        }
                        sx={{
                          borderColor: colors.green.DEFAULT,
                          backgroundColor: colors.green.DEFAULT,
                          color: "#ffff",
                          marginRight: "16px",
                        }}
                        disabled={fromSKUTransferQty === ""}
                        onClick={() => {
                          console.log("Button clicked");
                          createFamilySTONew();
                        }}
                      >
                        <Typography variant="h5" color={colors.white[100]}>
                          Save
                        </Typography>
                      </Button>

                      <Button
                        variant="contained"
                        startIcon={
                          <IoArrowBackCircleOutline
                            style={{ fontSize: "26px" }}
                          />
                        }
                        size="small"
                        sx={{
                          backgroundColor: "#34519fe6",
                          color: "#ffffff",
                        }}
                        onClick={handleCloseFamilyTransferDialog}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {displayDraft && (
                <Divider
                  sx={{
                    color: "#34519fe6",
                    fontSize: "20px",
                    fontWeight: "600",
                    marginTop: "3%",
                  }}
                >
                  Conversion
                </Divider>
              )}
              <Grid container spacing={1} style={containerStyle8}>
                {isLoading ? (
                  <div style={loaderContainerStyle}>
                    <ScaleLoader color="#495057" loading={isLoading} />
                  </div>
                ) : (
                  <>
                    {fromwarehouseId === null || familyId === null ? (
                      <NullScreen
                        containerheight="70vh"
                        containerWidth="100%"
                        screenType="Info"
                        message="Select Warehouse & Family"
                      />
                    ) : (
                      <>
                        {rows?.length === 0 ? (
                          <NullScreen
                            containerheight="80vh"
                            containerWidth="100%"
                          />
                        ) : (
                          <>
                            <Grid
                              container
                              spacing={2}
                              style={gridContainerStyle}
                            >
                              <Grid item sm={12} xs={6} md={6}>
                                {/* From SKU Header with Icon */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "1%",
                                  }}
                                >
                                  {/* Icon */}
                                  <GiFruitBowl
                                    style={{ marginRight: "8px" }}
                                    size="24px"
                                  />
                                  {/* Bold, Centered Typography */}
                                  <Typography
                                    variant="h3"
                                    component="div"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Conversion From SKU
                                  </Typography>
                                </div>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  sx={{ marginTop: "4%" }}
                                >
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6.4} md={6.4}>
                                      <FormControl fullWidth>
                                        <Autocomplete
                                          options={skuNames || []}
                                          getOptionLabel={(option) =>
                                            `${option?.sku_name}`
                                          }
                                          value={
                                            skuNames?.find(
                                              (sku) => sku?.sku_name === skuId
                                            ) || null
                                          }
                                          //   onChange={(event, newValue) => {
                                          //     setSkuId(newValue?.sku_name || "");
                                          //   }}
                                          onChange={handleAutocompleteChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Select SKU"
                                              size="small"
                                              InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <IconButton
                                                      edge="start"
                                                      size="small"
                                                      disabled
                                                    >
                                                      <GiFruitBowl />
                                                    </IconButton>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={1.5} md={1.5}>
                                      <TextField
                                        label="Uom"
                                        name="Uom"
                                        size="small"
                                        value={fromSKUUom}
                                        fullWidth
                                        readOnly
                                        variant="outlined"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton
                                                edge="start"
                                                size="small"
                                                disabled
                                              >
                                                <GiFruitBowl />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          sx: {
                                            color: "black", // Set text color to red
                                            "& input": {
                                              color: "black", // Set the color of the input text
                                            },
                                            opacity: 1, // Ensure opacity is set to 1 for visibility
                                          },
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={2.0} md={2.0}>
                                      <TextField
                                        label="Avaialble Qty"
                                        name="Avaialble Qty"
                                        size="small"
                                        value={fromSKUAvailQty}
                                        fullWidth
                                        readOnly
                                        variant="outlined"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton
                                                edge="start"
                                                size="small"
                                                disabled
                                              >
                                                <TbTransferIn />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          sx: {
                                            color: "black", // Set text color to red
                                            "& input": {
                                              color: "black", // Set the color of the input text
                                            },
                                            opacity: 1, // Ensure opacity is set to 1 for visibility
                                          },
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={2.0} md={2.0}>
                                      <TextField
                                        label="Transfer Qty"
                                        name="Transfer Qty"
                                        size="small"
                                        value={fromSKUTransferQty}
                                        fullWidth
                                        type="number"
                                        onChange={
                                          handleFromSKUTransferQtyChange
                                        }
                                        variant="outlined"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton
                                                edge="start"
                                                size="small"
                                                disabled
                                              >
                                                <TbTransferIn />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          inputProps: {
                                            max: fromSKUAvailQty, // Use the max attribute to limit the value to available quantity
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {/* Vertical Divider */}
                              <Divider
                                orientation="vertical"
                                flexItem
                                style={{
                                  marginLeft: "2.4%",
                                  marginRight: "2%",
                                  backgroundColor: "#0C0C0C",
                                  width: "2px", // Adjust thickness as desired
                                }}
                              />
                              <Grid item sm={12} xs={5.0} md={5.0}>
                                {/* To SKU Header */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "1%",
                                  }}
                                >
                                  {/* Bold, Centered Typography */}
                                  <GiFruitBowl
                                    style={{ marginRight: "8px" }}
                                    size="24px"
                                  />
                                  <Typography
                                    variant="h3"
                                    component="div"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Converted To SKU
                                  </Typography>
                                </div>{" "}
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  sx={{ marginTop: "4%" }}
                                >
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4} md={4}>
                                      <FormControl fullWidth>
                                        <Autocomplete
                                          options={uomFilteredData || []}
                                          getOptionLabel={(option) =>
                                            option?.sku_name || ""
                                          }
                                          value={
                                            uomFilteredData?.find(
                                              (sku) => sku?.sku_name === toskuId
                                            ) || null
                                          }
                                          //   onChange={(event, newValue) => {
                                          //     setSkuId(newValue?.sku_name || "");
                                          //   }}
                                          onChange={
                                            handleAutocompleteTOSKUChange
                                          }
                                          key={refreshCount}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Select SKU"
                                              size="small"
                                              InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <IconButton
                                                      edge="start"
                                                      size="small"
                                                      disabled
                                                    >
                                                      <GiFruitBowl />
                                                    </IconButton>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                      <TextField
                                        label="Uom"
                                        name="Uom"
                                        size="small"
                                        value={toSKUUom}
                                        fullWidth
                                        readOnly
                                        variant="outlined"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton
                                                edge="start"
                                                size="small"
                                                disabled
                                              >
                                                <GiFruitBowl />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          sx: {
                                            color: "black", // Set text color to red
                                            "& input": {
                                              color: "black", // Set the color of the input text
                                            },
                                            opacity: 1, // Ensure opacity is set to 1 for visibility
                                          },
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                      <TextField
                                        label="Avaialble Qty"
                                        name="Avaialble Qty"
                                        size="small"
                                        value={toSKUAvailQty}
                                        fullWidth
                                        readOnly
                                        variant="outlined"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton
                                                edge="start"
                                                size="small"
                                                disabled
                                              >
                                                <TbTransferIn />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          sx: {
                                            color: "black", // Set text color to red
                                            "& input": {
                                              color: "black", // Set the color of the input text
                                            },
                                            opacity: 1, // Ensure opacity is set to 1 for visibility
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
              {displayDraft && (
                <Divider
                  sx={{
                    color: "#34519fe6",
                    fontSize: "20px",
                    fontWeight: "600",
                    marginTop: "3%",
                  }}
                >
                  Preview
                </Divider>
              )}

              <Grid container spacing={1} style={containerStyle8}>
                {isLoading ? (
                  <div style={loaderContainerStyle}>
                    <ScaleLoader color="#495057" loading={isLoading} />
                  </div>
                ) :  displayDraft  &&  (
                  <>
                    <div style={gridContainerStyle}>
                      <ThemeProvider theme={tableTheme}>
                        <MaterialReactTable
                          columns={columns}
                          data={draftrowData}
                          key={refreshCount}
                          enableGrouping
                          enableStickyHeader
                          enablePagination={false}
                          enableFullScreenToggle={false}
                          enableDensityToggle={false}
                          enableColumnActions={false}
                          enableColumnFilters={false}
                          initialState={{
                            density: "compact",
                            pagination: { pageIndex: 0, pageSize: 10 },
                          }}
                          muiTableHeadCellProps={{
                            sx: {
                              color: "#fff",
                              backgroundColor: "#34519fe6",
                            },
                          }}
                          muiToolbarAlertBannerChipProps={{ color: "primary" }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: "#ffff",
                              },
                            },
                          }}
                          muiTableContainerProps={{ sx: { maxHeight: "53vh" } }}
                          enableGlobalFilter={false}
                        />
                      </ThemeProvider>
                    </div>
                    <Grid
                      container
                      justifyContent="flex-end"
                      style={{ marginTop: "5px" }}
                    ></Grid>
                  </>
                )}
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
      <AlertDialogBox
        open={errorPopupOpen3}
        okayText="Okay"
        handleOkayClick={() => setErrorPopupOpen3(false)}
        onClose={() => setErrorPopupOpen3(false)}
        content={errorMessage3}
      />
    </>
  );
};

export default FamilyTransfer;
