import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Button,
  Grid,
  useTheme,
  Chip,
  Box,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import Divider from "@mui/material/Divider";
import { API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import { tokens } from "../../../../theme";
import AddIcon from "@mui/icons-material/Add";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const ExpectedCard = ({ selectedRow, filterdata }) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  function formatDate(epochTimestamp) {
    const date = new Date(epochTimestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Note: Month is 0-based, so we add 1
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const initialStartDateEpoch =
    filterdata?.expected?.mandatory_fields?.start_date;

  const formattedStartDate = formatDate(initialStartDateEpoch);
  console.log("formattedStartDate", formattedStartDate);
  const initialEndDateEpoch = filterdata?.expected?.mandatory_fields?.end_date;

  const formattedEndDate = formatDate(initialEndDateEpoch);
  const [formData, setFormData] = useState({
    // expected_cost: selectedRow.common.expected_cost,
    // Initialize other common fields here
    // Add fields specific to each activity type
  });
  console.log("data expected ::::::::", selectedRow);
  console.log("filtered dataaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", filterdata);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    fetchSubordinateData();
  }, []);

  const [subordinateData, setSubordinateData] = useState(null);
  const fetchSubordinateData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARM,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const subordinateData = data?.result?.rows;

      const subordinates = subordinateData?.farm_subordinate;
      setSubordinateData(subordinates);
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching subordinate data:", error);
    }
    console.log("Names of subordinate available", subordinateData);
  };

  const renderHarvestDetails = () => {
    const { inv_data } = filterdata?.expected || {};

    if (inv_data) {
      const detailsArray = [inv_data]; // Wrap the object into an array

      return detailsArray?.map((detail, index) => (
        <Stack direction="column" key={index}>
          <Typography
            variant="h4"
            color={colors.black.DEFAULT}
            fontFamily="Lexend Deca"
            style={{ marginTop: "4%" }}
          >
            Grades:
          </Typography>
          {detail?.harvestdetails &&
            detail?.harvestdetails?.map((harvest, idx) => (
              <div key={idx}>
                <ListItem style={{ marginLeft: "-4%" }}>
                  <ListItemText
                    primary={`Date of Harvest : ${formatDate(
                      harvest?.date_of_harvest
                    )}`}
                  />
                </ListItem>
                {harvest?.grades &&
                  harvest?.grades?.map((grade, gIndex) => (
                    <div key={gIndex}>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        display="flex"
                        key={index}
                        style={{ marginTop: "1%" }}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="Name"
                            fullWidth
                            variant="filled"
                            disabled
                            size="small"
                            margin="normal"
                            value={grade?.name}
                            placeholder="Name"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="No. of Plants"
                            fullWidth
                            variant="filled"
                            disabled
                            size="small"
                            margin="normal"
                            value={grade?.numberOfPlants}
                            placeholder="No. of Plants"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="Weight(Kg)"
                            fullWidth
                            variant="filled"
                            disabled
                            size="small"
                            margin="normal"
                            value={grade?.weight}
                            placeholder="Weight(Kg)"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={3}
                          style={{ marginTop: "-5%" }}
                        >
                          <TextField
                            label="Amount"
                            fullWidth
                            variant="filled"
                            disabled
                            size="small"
                            margin="normal"
                            value={grade?.price}
                            placeholder="Amount"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  ))}

                {idx !== detail?.harvestdetails.length - 1 && (
                  <Divider
                    sx={{
                      borderBottom: "2px solid #000000",
                      margin: "16px 0",
                    }}
                    variant="middle"
                  />
                )}
              </div>
            ))}
        </Stack>
      ));
    }
    return null;
  };

  return (
    <>
      <Grid sx={{ marginLeft: "2%" }}>
        <List>
          <Stack direction="row" spacing={2}>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 24%, #f5faf8 24%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Activity Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {filterdata?.expected?.mandatory_fields?.activity_name}
              </Typography>
            </ListItem>
          </Stack>
          <Stack direction="row" spacing={2}>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 42%, #f5faf8 42%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Farm Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {filterdata?.expected?.mandatory_fields?.farm_name}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 36%, #f5faf8 36%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Assign To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {filterdata?.expected?.mandatory_fields?.employee_name}
              </Typography>
            </ListItem>
          </Stack>
          <Stack direction="row" spacing={1}>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 27%, #f5faf8 27%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {filterdata?.expected?.mandatory_fields?.status}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 45%, #f5faf8 45%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Activity Type&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {filterdata?.expected?.mandatory_fields?.ledger_name}
              </Typography>
            </ListItem>
          </Stack>
          <Stack direction="row" spacing={2}>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 39%, #f5faf8 39%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Start Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {formattedStartDate}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 36%, #f5faf8 36%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                End Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {formattedEndDate}
              </Typography>
            </ListItem>
          </Stack>
          <Stack direction="row" spacing={2}>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 29%, #f5faf8 29%, #f5faf8 60%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Expected Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {filterdata?.expected?.mandatory_fields?.exp_amount}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                marginBottom: "15px",
                background:
                  "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 45%, #f5faf8 45%, #f5faf8 100%)",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                Total Labour&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography
                variant="h6"
                color={colors.black.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {filterdata?.expected?.mandatory_fields?.total_labour}
              </Typography>
            </ListItem>
          </Stack>
        </List>
        {filterdata?.expected?.mandatory_fields?.ledger_name ===
          "Harvesting" && (
          <List sx={{ marginTop: "-4%" }}>
            <Stack direction="row" spacing={2}>
              <ListItem
                style={{
                  marginBottom: "15px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 42%, #f5faf8 42%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Crop Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {filterdata?.expected?.optional_fields?.crop}
                </Typography>
              </ListItem>
              <ListItem
                style={{
                  marginBottom: "15px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 42%, #f5faf8 42%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Zone Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {filterdata?.expected?.optional_fields?.zone}
                </Typography>
              </ListItem>
            </Stack>
            <Stack direction="row" spacing={2}>
              <ListItem
                style={{
                  marginBottom: "15px",
                  background:
                    "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 20%, #f5faf8 20%, #f5faf8 100%)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  Quantity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.black.DEFAULT}
                  fontFamily="Lexend Deca"
                >
                  {
                    filterdata?.expected?.optional_fields?.weight_in_kg
                      ? `${filterdata.expected.optional_fields.weight_in_kg} (in kg)`
                      : filterdata?.expected?.optional_fields?.weight_in_plants
                      ? `${filterdata.expected.optional_fields.weight_in_plants} (in plants)`
                      : null // or an empty string if you don't want anything to display when neither field is present
                  }
                </Typography>
              </ListItem>
            </Stack>
            <Stack direction="row" spacing={2}>
              {renderHarvestDetails()}
            </Stack>
          </List>
        )}

        {filterdata?.expected?.mandatory_fields?.ledger_name ===
          "Irrigation" && (
          <>
            <List sx={{ marginTop: "-4%" }}>
              <Stack direction="row" spacing={2}>
                <ListItem
                  style={{
                    marginBottom: "15px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 49%, #f5faf8 49%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Zone Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {filterdata?.expected?.optional_fields?.zone}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    marginBottom: "15px",
                    background:
                      "linear-gradient(to right, #ecf4f0 0%, #ecf4f0 42%, #f5faf8 42%, #f5faf8 100%)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    Volume&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    fontFamily="Lexend Deca"
                  >
                    {filterdata?.expected?.optional_fields?.volume}
                  </Typography>
                </ListItem>
              </Stack>
              <Stack direction="row" spacing={2}></Stack>
            </List>
          </>
        )}
      </Grid>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};

export default ExpectedCard;
