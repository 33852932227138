import React, { useState ,useEffect} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
 import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from 'axios';
import SuccessPopUp from '../../PopUpMsg/SuccessPopUp';
import ErrorPopUp from '../../PopUpMsg/ErrorPopUp';
import { useSelector, useDispatch } from 'react-redux';
import {API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import {
  Button,
  TextField,
  Stack,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  MdModeEdit,
  MdPeopleAlt,
  
} from "react-icons/md";
import { useNavigate,useLocation  } from "react-router-dom";
import { tokens } from "../../WMStheme";
import { WarehouseOutlined } from "@mui/icons-material";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const PersonalDetails = ({ WarehouseData, setWarehouseData }) => {
    const handleChange = (field, value) => {
        // Use the appropriate nested structure based on your JSON
        if (field.includes('.')) {
          const [parentField, nestedField] = field.split('.');
          setWarehouseData((prevData) => ({
            ...prevData,
            [parentField]: {
              ...prevData[parentField],
              [nestedField]: value,
            },
          }));
        } else {
          setWarehouseData((prevData) => ({
            ...prevData,
            [field]: value,
          }));
        }
      };
  return (
  <>
    {/* <Typography variant="h6">Personal Details</Typography> */}

    <Grid
      container
      spacing={2.5}
      sx={{ marginTop: "1.6%", marginLeft: "2.9%" }}
    >
      <Grid item xs={12} sm={3.8}>
        <TextField
          label="Warehouse Name"
          size="small"
          fullWidth
          required
          value={WarehouseData?.wh_name}
            onChange={(e) => handleChange('wh_name', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon />
              </InputAdornment>
            ),
          
          inputProps: {
            maxLength: 65, // Set maximum length to 60 characters
          },
        }}
        helperText={
          WarehouseData?.wh_name && WarehouseData?.wh_name.length > 60
            ? "Cannot enter more than 65 characters"
            : ""
        }
        />
      </Grid>

      <Grid item xs={12} sm={3.8}>
        <TextField
          label="Warehouse Code"
          size="small"
          fullWidth
          required
          value={WarehouseData?.wh_code}
          onChange={(e) => handleChange('wh_code', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3.8} sx={{marginTop:"-1.6%"}}>
        <TextField
          label="POC"
          name="poc"
          
          size="small"
          value={WarehouseData?.wh_manager}
            onChange={(e) => handleChange('wh_manager', e.target.value)}
            fullWidth
    
          margin="normal"
          variant="outlined"
          inputProps={{
            maxLength: 50,
            minLength: 3,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="start" size="small" disabled>
                <PermIdentityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

    </Grid>
    <Grid
      container
      spacing={2.5}
      sx={{ marginTop: "1.0%", marginLeft: "2.9%" }}
    >
     
      
      <Grid item xs={12} sm={3.8} sx={{ marginTop: "1.7%" }}>
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            fullWidth
            required
            size="small"
            displayEmpty
            value={WarehouseData?.active ? 'true' : 'false'}
            onChange={(e) => handleChange('active', e.target.value === 'true')}
            inputProps={{ "aria-label": "Type" }}
            startAdornment={
              <InputAdornment position="start">
                <ToggleOffIcon />
              </InputAdornment>
            }
          >
            <MenuItem value="" disabled>
              Select Status
            </MenuItem>
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">In-Active</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3.8}>
        <TextField
          label="Address "
          labelId="Address 1"
          name="addressline1"
          value={WarehouseData?.wh_address?.address}
          onChange={(e) => handleChange('wh_address?.address', e.target.value)}
          fullWidth
          size="small"
          margin="normal"
          inputProps={{
            style: { width: "100%" },
            min: 0,
            max: 120,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3.8} sx={{ marginTop: "1.7%" }}>
        <FormControl fullWidth>
          <InputLabel>Location Type</InputLabel>
          <Select
            label="Location Type"
            fullWidth
            required
            size="small"
            displayEmpty
            value={WarehouseData?.locationType} // Update with the appropriate field name
      onChange={(e) => handleChange('locationType', e.target.value)}
            inputProps={{ "aria-label": "Type" }}
            startAdornment={
              <InputAdornment position="start">
                <ToggleOffIcon />
              </InputAdornment>
            }
          >
            <MenuItem value="" disabled>
              Select Location Type
            </MenuItem>
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">In-Active</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
   

    
    <Grid
      container
      spacing={2.5}
      sx={{ marginTop: "1.6%", marginLeft: "2.9%" }}
    >
      
      <Grid item xs={12} sm={3.8} sx={{ marginTop: "-1.7%" }}>
      <TextField
          label="Mobile No."
          
          size="small"
          
          fullWidth
          type="tel"
          required
          margin="normal"
          variant="outlined"
          value={WarehouseData?.manager_phone_number}
          onChange={(e) => handleChange('manager_phone_number', e.target.value)}
          inputProps={{
            maxLength: 10,
            inputMode: "numeric", // Shows the numeric keyboard on mobile devices
            pattern: "[0-9]*", // Only allows numeric input (additional validation)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="start" size="small" disabled>
                  <PhoneIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      
      
    </Grid>
   
    <Grid
      container
      spacing={3.5}
      sx={{ marginTop: "1.6%", marginLeft: "1.5%" }}
    >
      <Grid item xs={12} sm={12}></Grid>
    </Grid>
  </> );
};
const WarehouseConfig = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
 
  const navigate = useNavigate();
  const [WarehouseData, setWarehouseData] = useState();
  const dispatch = useDispatch();
  const[WarehouseId, setWarehouseId]=useState();
  const [message, setMessage] = useState('');
  const colors = tokens(theme.palette.mode);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState("Warehouse Details");
  const sections = [
    { name: "Warehouse Details", icon: <MdModeEdit /> },
    
    
  ];
  const { action, warehouseData } = useSelector((state) => state?.warehouse);
  console.log("warehouse data ", warehouseData);
  
  useEffect(() => {
 
    switch (action) {
      case 'edit':
        if (warehouseData) {
          // If editing, prefill the form with the warehouseData
          console.log("warehouse in edit form ", warehouseData);
          console.log("edit action performed");
          // Initialize WarehouseData with all fields from JSON structure
          setWarehouseId(warehouseData?.id);
          console.log("warehouse id", warehouseData?.id);
          setWarehouseData((prevData) => ({
            ...prevData,
            ...warehouseData,
          }));
          // Update only the fields present in warehouseData
        }
        break;
  
      case 'add':
        // If adding, clear the form data
        console.log("add action performed");
        setWarehouseData({
          wh_name: '',
          wh_address: {
            address: '',
          },
          wh_manager: '',
          manager_phone_number: '',
          active: true,
          wh_code: '',
        });
        break;
  
      // Add more cases if needed
  
      default:
        // Handle the default case if action is not recognized
        break;
    }
  }, [action, warehouseData]);
  
  
  
  
  
  const handleNext = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex < sections.length - 1) {
      const nextSection = sections[currentIndex + 1].name;
      setCurrentSection(nextSection);
    }
  };
  const handleSubmit = async () => {
    try {
      if (action === 'edit') {
        // If editing, send a PUT request
        await updateWarehouse();
      } else {
        // If adding, send a POST request
        await createWarehouse();
      }
      // You can add additional logic or navigation after successful submission
    } catch (error) {
      // Handle errors here
      console.error('Error submitting warehouse details:', error);
      setMessage('Error submitting warehouse details');
    }
  };
  const updateWarehouse = async () => {
    try {
      if (!WarehouseData?.id) {
        console.error('Warehouse id is missing in WarehouseData.');
        setErrorMessage("Please try Again");
        return;
      }
  
      const endpoint = `${API_ENDPOINTS.PUT_WMS_EDIT_WAREHOUSE_DATA(WarehouseData?.id)}`;
      console.log('API Endpoint:', endpoint);
  
    // Log the WarehouseData before making the API call
    console.log('Updating WarehouseData:', WarehouseData);

      const response = await axiosInstance.put(endpoint, WarehouseData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage("Data updated Successfully!");
        
        setSuccessPopupOpen(true);
        // navigate('/viewWarehouse');
      } else {
        console.error('Failed to update the warehouse data');
        setErrorMessage('Failed to update the warehouse data. Please try again.');
      }
    } catch (error) {
      setErrorPopupOpen(true);
      console.error('Error occurred while updating the warehouse data:', error);
    }
  };
  useEffect(() => {
    // Effect to navigate after success message is set
    if (successPopupOpen) {
      const timer = setTimeout(() => {
        setSuccessPopupOpen(false);
        navigate('/viewWarehouse');
      }, 3000);
  
      return () => clearTimeout(timer);
    }
  }, [successPopupOpen, navigate]);
  const createWarehouse = async () => {
    try {
      // Determine the endpoint for creating a new warehouse
      const endpoint = `${API_ENDPOINTS.POST_WMS_ADD_WAREHOUSE_DATA}`;
      console.log('API Endpoint:', endpoint);
  
      // Log the WarehouseData before making the API call
      console.log('Creating WarehouseData:', WarehouseData);
  
      // Send the POST request to create a new warehouse
      const response = await axiosInstance.post(endpoint, WarehouseData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage('Data created successfully!');
        setSuccessPopupOpen(true);
        // localStorage.setItem("warehouse_data",warehouse);
          
        // Optionally, you can perform additional actions after successful creation
      } else {
        console.error('Failed to create the warehouse data');
        setErrorMessage('Failed to create the warehouse data. Please try again.');
      }
    } catch (error) {
      setErrorPopupOpen(true);
      console.error('Error occurred while creating the warehouse data:', error);
    }
  };
  
  const handlePrevious = () => {
    const currentIndex = sections.findIndex(
      (section) => section.name === currentSection
    );
    if (currentIndex > 0) {
      const previousSection = sections[currentIndex - 1].name;
      setCurrentSection(previousSection);
    }
  };
  function handleBack() {
    if (action === "add") {
      navigate("/configuration");
    } else {
      // Navigate to a different location based on your condition
     
      navigate("/configuration");
    }
   
  }
  const isLastSection = currentSection === sections[sections.length - 1].name;
//Personal Details States

  return (
    <>
     
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
          // backgroundColor:'pink'
          // backgroundColor: "rgb(44 123 85)",
        }}
      >
        <Typography
          variant="h2"
          color={colors.green.DEFAULT}
          style={{ marginLeft: "0.6%" }}
        >
          <MdPeopleAlt
            color={colors.green.DEFAULT}
            fontSize={18}
            sx={{ marginRight: "6px" }}
          />
          &nbsp; Warehouse
        </Typography>
        {/* <InventoryRoundedIcon sx={{ fontSize: 32, marginLeft: "1px" }} />
          </Typography> */}

        <Stack spacing={2} direction="row">
          

          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
            }}
            onClick={handleBack}
          >
            <Typography variant="h7" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Stack>
      </Grid>

      <Grid container spacing={3} sx={{ marginTop: "1.7%" }}>
        {/* Left Section (30%) */}
        <Grid item xs={12} sm={3}>
          <Box
            p={2}
            bgcolor="#ffff"
            borderRadius="10px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
          >
            {sections?.map((section) => (
              <Typography
                key={section?.name}
                onClick={() => setCurrentSection(section.name)}
                style={{
                  cursor: "pointer",
                  padding: "8px",

                  background:
                    currentSection === section.name
                      ? "#34519F5D"
                      : "transparent",
                  color: currentSection === section.name ? "#000" : "inherit",
                  borderRadius:
                    currentSection === section.name ? "7px" : "inherit",
                  // padding:currentSection === section.name ? '5px' : 'inherit',
                }}
              >
                {section?.icon}
                &nbsp;&nbsp;
                {section?.name}
              </Typography>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Box
            p={2}
            bgcolor="#ffff"
            borderRadius="10px"
            maxHeight="100%"
            height="100%"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
            position="relative"
          >
            {/* Dynamically render the current section */}
            {currentSection === "Warehouse Details" && <PersonalDetails WarehouseData={WarehouseData}
          setWarehouseData={setWarehouseData} />}
           
            <Box
              position="absolute"
              bottom="16px"
              right="20px"
              display="flex"
              gap={2}
            >
              {currentSection !== sections[0].name && (
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: colors.green.DEFAULT,
                  }}
                  onClick={handlePrevious}
                >
                  <Typography variant="h7" color={colors.green.DEFAULT}>
                    Previous
                  </Typography>
                </Button>
              )}
              <Button
                variant="contained"
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                }}
                onClick={isLastSection ? handleSubmit : handleNext}
              >
                <Typography variant="h7" color={colors.white.DEFAULT}>
                  {isLastSection ? "Submit" : "Next"}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default WarehouseConfig;
