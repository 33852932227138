import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Divider,
  useTheme,
  Typography,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { tokens } from "../WMStheme";
import { CopyToClipboard } from "react-copy-to-clipboard";

const RequestResponseDialog = ({ open, onClose, request, response }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const containerStyle = {
    height: "50vh",
    // height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "#eeeeef",
    marginTop: "2px",
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography
          variant="h2"
          style={{ marginLeft: "0.1%" }}
          color={colors.green.DEFAULT}
        >
          Request & Response
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,
            color: "grey",
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          overflowY: "hidden",
        }}
      >
        <div style={{ flex: 1, paddingRight: "8px" }}>
          <Typography variant="h4" color={colors.black.DEFAULT}>
            Request:
            <CopyToClipboard text={JSON.stringify(request, null, 2)}>
              <IconButton>
                <FileCopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Typography>

          <Grid container spacing={1} style={containerStyle}>
            <Grid item xs={12} sm={12} md={12}>
              <pre>{JSON.stringify(request, null, 2)}</pre>
            </Grid>
          </Grid>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
        <div style={{ flex: 1, paddingLeft: "8px" }}>
          <Typography variant="h4" color={colors.black.DEFAULT}>
            Response:
            <CopyToClipboard text={JSON.stringify(response, null, 2)}>
              <IconButton>
                <FileCopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Typography>

          <Grid container spacing={1} style={containerStyle}>
            <Grid item xs={12} sm={12} md={12}>
              <pre>{JSON.stringify(response, null, 2)}</pre>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RequestResponseDialog;
