import React, { useState, useEffect, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import {
  Box,
  Button,
  Dialog,
  InputAdornment,
  DialogTitle,
  DialogContent,
  Grid,
  Stack,
  DialogActions,
  FormHelperText,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import HarvestingDialog from "./HarvestingDialog";
import IrrigationDialog from "./IrrigationDialog";
import CleaningDialog from "./CleaningDialog";
import PruningDialog from "./PruningDialog";
import PurchaseDialog from "./PurchaseDialog";
import SowingDialog from "./SowingDialog";
import TransportDialog from "./TransportDialog";
import ElectricityDialog from "./ElectricityDialog";
import MiscellaneousDialog from "./MiscellaneousDialog";
import axios from "axios";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const MasterDialog = ({ isOpen, onClose, fetchTaskData }) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const hiddenToken = localStorage.getItem("token");
  const [listOfZones, setListOfZones] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const selectedStartDate = new Date().toISOString().split("T")[0];
  const selectedEndDate = new Date().toISOString().split("T")[0];

  const [mandatoryFields, setMandatoryFields] = useState({
    status: "To-Do",
    label: "High",
    due_time: 0,
    assigned_to: "",
    farm: "",
    farm_id: 0,
    ledger_name: "Cleaning",
    start_date: selectedStartDate,
    end_date: selectedEndDate,
    transaction_type: "expense",
  });

  useEffect(() => {
    setMandatoryFields((prevState) => ({
      ...prevState,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
    }));
  }, [selectedStartDate, selectedEndDate]);

  const [harvestingDialogOpen, setHarvestingDialogOpen] = useState(false);
  const [irrigationDialogOpen, setIrrigationDialogOpen] = useState(false);
  const [cleaningDialogOpen, setCleaningDialogOpen] = useState(false);
  const [pruningDialogOpen, setPruningDialogOpen] = useState(false);
  const [purchaseDialogOpen, setPurchaseDialogOpen] = useState(false);
  const [transportDialogOpen, setTransportDialogOpen] = useState(false);
  const [sowingDialogOpen, setSowingDialogOpen] = useState(false);
  const [electricityDialogOpen, setElectricityDialogOpen] = useState(false);
  const [miscellaneousDialogOpen, setMiscellaneousDialogOpen] = useState(false);
  const [dateError, setDateError] = useState("");

  const moment = require("moment");

  const {
    status,
    transaction_type,
    due_time,
    assigned_to,
    farm,
    ledger_name,
    start_date,
    end_date,
    farm_id,
    employee_id,
  } = mandatoryFields;

  const epochStartDate = moment(start_date, "YYYY/MM/DD").valueOf();
  const epochEndDate = moment(end_date, "YYYY/MM/DD").valueOf();
  const currentDate = moment().format("YYYY-MM-DD");
  const dateTimeString = `${currentDate} ${due_time}`;
  const epochDueTime = moment(dateTimeString, "YYYY-MM-DD HH:mm").valueOf();

  const dataToSend = {
    status,
    transaction_type,
    assigned_to,
    farm,
    ledger_name,
    farm_id,
    employee_id,
    due_time: epochDueTime,
    start_date: epochStartDate,
    end_date: epochEndDate,
  };

  const updateState = (key, value) => {
    setMandatoryFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleOpen = (event) => {
    if (event.target === event.currentTarget) {
      console.log("handle open clicked !!!!!!!");
    }
  };

  const handleStatusChange = (event) => {
    updateState("status", event.target.value);
  };

  const handletaskChange = (event) => {
    updateState("ledger_name", event.target.value);
  };

  const handleTransactionTypeChange = (event) => {
    updateState("transaction_type", event.target.value);
  };

  const handleTimeChange = (event) => {
    console.log("timeEEEEEEEEEEEE", event.target.value);
    updateState("due_time", event.target.value);
  };

  const handleMyAsigneeChange = (event) => {
    const assignee = event.target.value;
    updateState("assigned_to", assignee);
    const selectedSubordinateObject = subordinateData?.find(
      (farmer) => farmer.employee_name === assignee
    );
    const selectedSubordinateId = selectedSubordinateObject.employee_id;
    updateState("employee_id", selectedSubordinateId);
  };

  // const handleMyFarmChange = (event) => {
  //   updateState("farm", event.target.value);
  // };

  const handleMyFarmChange = async (event) => {
    const selectedFarm = event.target.value;
    updateState("farm", selectedFarm);
    const selectedFarmObject = farmData?.find(
      (farm) => farm.farm_name === selectedFarm
    );
    console.log("selectedFarmObject", selectedFarmObject);
    const selectedFarmId = selectedFarmObject.farm_id_fk;
    updateState("farm_id", selectedFarmId);
    fetchSubordinateData(selectedFarmId);
  };
  const handleStartDateChange = async (event) => {
    updateState("start_date", event.target.value);
  };
  const handleEndDateChange = async (event) => {
    updateState("end_date", event.target.value);
  };

  const handleAddTaskClick = () => {
    switch (ledger_name) {
      case "Harvesting":
        setHarvestingDialogOpen(true);
        break;
      case "Irrigation":
        setIrrigationDialogOpen(true);
        break;
      case "Cleaning":
        setCleaningDialogOpen(true);
        break;
      case "Pruning":
        setPruningDialogOpen(true);
        break;
      case "Sowing":
        setSowingDialogOpen(true);
        break;
      case "Purchase":
        setPurchaseDialogOpen(true);
        break;
      case "Transport":
        setTransportDialogOpen(true);
        break;
      case "Electricity":
        setElectricityDialogOpen(true);
        break;
      case "Miscellaneous":
        setMiscellaneousDialogOpen(true);
        break;
    }
  };

  const tasks = [
    "Harvesting",
    "Cleaning",
    "Irrigation",
    "Purchase",
    "Transport",
    "Electricity",
    "Miscellaneous",
  ];

  const [farmData, setFarmData] = useState(null);
  const [selectedFarm, setSelectedFarm] = useState("");
  const [subordinateData, setSubordinateData] = useState(null);
  const [farmOptions, setFarmOptions] = useState([]);

  useEffect(() => {
    fetchFarmData();
    console.log("useEffect Hittttt");
  }, []);

  useEffect(() => {
    fetchData();
    fetchTaskLabel();
    fetchTaskStatus();
    console.log(" Zone Data Fetch SuccessfulL !");
  }, [farm]);

  const fetchFarmData = async () => {
    try {
      const response = await axiosInstance.get(
        // API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,
        API_ENDPOINTS.GET_ALL_FARM_NAMES,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      setFarmData(data?.data);
      setSelectedFarm(farmData[0]);
    } catch (error) {
      // setApiErrorOpen(true);
      console.log("Error fetching farm data:", error);
    }
    console.log("Names of Farm available", farmData);
  };

  // for fetching subordinates
  const fetchSubordinateData = async (farmId) => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARMER,
        {
          params: {
            farm_id: farmId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("responseeeeeeeee-------------", response);
      const data = await response?.data;
      const subordinateData = data?.rows;
      // console.log("SUBORDINATEEEEE DATAAA", subordinateData);

      setSubordinateData(subordinateData);
    } catch (error) {
      // setApiErrorOpen(true);
      console.log("Error fetching subordinate data:", error);
    }
  };

  console.log("Names of subordinate available", subordinateData);

  // for fetching zones corresponding to selected farm
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARM,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const zonesdata = response?.data?.result.rows;

      const filteredRows = zonesdata?.filter(
        (rows) => rows.farm_name === dataToSend.farm
      );

      const zones = filteredRows?.map((rows) =>
        rows.zones.map((zone) => ({
          zone_name: zone.zone_name,
          zone_id: zone.zone_id,
        }))
      );
      console.log(zones);

      const myArray = [];

      zones[0].forEach((item) => {
        const { zone_name, zone_id } = item;
        myArray.push({ zone_name, zone_id });
      });
      console.log(myArray);
      setListOfZones(myArray);
    } catch (error) {
      // setApiErrorOpen(true);
      console.log("Error fetching zone data:", error);
    }
  };

  console.log("listOfZonesssssssssssssss", listOfZones);

  const [taskLabel, setTaskLabel] = useState([]);

  const fetchTaskLabel = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_TASK_MANAGEMENT_TASKLABEL,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setTaskLabel(options);
    } catch (error) {
      // setApiErrorOpen(true);
      console.log("Error fetching Task Labels", error);
    }
  };

  const [taskStatus, setTaskStatus] = useState([]);

  const fetchTaskStatus = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_TASK_MANAGEMENT_TASKSTATUS,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setTaskStatus(options);
    } catch (error) {
      // setApiErrorOpen(true);
      console.log("Error fetching Task Status", error);
    }
  };

  const handleStartDateBlur = () => {
    if (end_date && new Date(start_date) >= new Date(end_date)) {
      setDateError("Start date should be less than end date");
    } else {
      setDateError("");
    }
  };
  const handleEndDateBlur = () => {
    if (start_date && new Date(start_date) >= new Date(end_date)) {
      setDateError("Start date should be less than end date");
    } else {
      setDateError("");
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
        PaperProps={{ style: { minWidth: "500px", minHeight: "350px" } }}
      >
        <DialogTitle variant="h4">Add new activity !</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    select
                    fullWidth
                    label="Select Activity"
                    value={ledger_name}
                    onChange={handletaskChange}
                    margin="normal"
                    size="small"
                    displayEmpty
                    inputProps={{ "arial-label": "Select Activity" }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: "100px", // Set the maximum height of the dropdown menu
                          },
                        },
                      },
                    }}
                  >
                    {tasks?.map((ledger_name) => (
                      <MenuItem key={ledger_name} value={ledger_name}>
                        {ledger_name}
                      </MenuItem>
                    ))}
                    <MenuItem disabled value="">
                      Select Activity
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <InputLabel>Select Transaction Type</InputLabel>
                  <Select
                    labelId="Select Transaction Type"
                    label="Select Transaction Type"
                    fullWidth
                    size="small"
                    value={transaction_type}
                    onChange={handleTransactionTypeChange}
                    required
                    displayEmpty
                    inputProps={{ "aria-label": "Select Transaction Type" }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "100px" }, // Set the max-height for the menu
                      },
                    }}
                  >
                    <MenuItem key="expense" value="expense">
                      Expense
                    </MenuItem>
                    <MenuItem key="income" value="income">
                      Income
                    </MenuItem>
                    <MenuItem disabled value="">
                      Select Transaction type
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ marginTop: "0px" }}
            >
              {/* <Grid item xs={12} sm={4} style={{ marginTop: "14px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Label</InputLabel>
                <Select
                  labelId="Select Label"
                  label="Select Label"
                  fullWidth
                  size="small"
                  value={label}
                  onChange={handlelabelChange}
                  required
                  displayEmpty
                  inputProps={{ "aria-label": "Select Label" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: "100px" }, // Set the max-height for the menu
                    },
                  }}
                >
                  {taskLabel?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem disabled value="">
                    Select Label
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
              <Grid item xs={12} sm={6} style={{ marginTop: "12px" }}>
                <FormControl fullWidth>
                  <InputLabel>Select Status</InputLabel>
                  <Select
                    labelId="Select Status"
                    label="Select Status"
                    value={status}
                    onChange={handleStatusChange}
                    required
                    fullWidth
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "100px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Status" }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  >
                    {taskStatus?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    <MenuItem disabled value="">
                      Select Status
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "14px" }}>
                <TextField
                  id="time"
                  label="Due time"
                  type="time"
                  value={due_time}
                  fullWidth
                  size="small"
                  onChange={handleTimeChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ marginTop: "0px" }}
            >
              <Grid item xs={12} sm={6} style={{ marginTop: "14px" }}>
                <FormControl fullWidth error={!farm}>
                  <InputLabel>Select Farm</InputLabel>
                  <Select
                    label="Select Farm"
                    labelId="Select Farm"
                    required
                    fullWidth
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "100px" }, // Set the max-height for the menu
                      },
                    }}
                    value={farm}
                    onChange={handleMyFarmChange}
                    inputProps={{ "aria-label": "Select Farm" }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  >
                    {farmData &&
                      farmData?.map((farm) => (
                        <MenuItem
                          key={farm.farm_name}
                          value={farm.farm_name}
                          disabled={ledger_name === "Harvesting" && farm.farm_name==="All Farms" }
                        >
                          {farm.farm_name}
                        </MenuItem>
                      ))}
                    <MenuItem disabled value="">
                      Select Farm
                    </MenuItem>
                  </Select>
                  {/* {!farm && (
                  <FormHelperText>This field is required</FormHelperText>
                )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "14px" }}>
                <FormControl fullWidth>
                  <InputLabel>Assign to</InputLabel>
                  <Select
                    value={assigned_to}
                    label="Assign to"
                    labelId="Assign to"
                    fullWidth
                    displayEmpty
                    required
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "100px" }, // Set the max-height for the menu
                      },
                    }}
                    onChange={handleMyAsigneeChange}
                    inputProps={{ "aria-label": "Select Subordinate" }}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                  >
                    {subordinateData &&
                      subordinateData?.map((farmer) => (
                        <MenuItem
                          key={farmer.employee_name}
                          value={farmer.employee_name}
                        >
                          {farmer.employee_name}
                        </MenuItem>
                      ))}
                    <MenuItem disabled value="">
                      Select Subordinate
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ marginTop: "0px" }}
            >
              <Grid item xs={12} sm={6} style={{ marginTop: "14px" }}>
                <TextField
                  label="Start Date"
                  type="date"
                  size="small"
                  value={start_date}
                  fullWidth
                  onChange={handleStartDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: new Date().toISOString().split("T")[0],
                    },
                  }}
                  onBlur={handleStartDateBlur}
                  // sx={{ width: 200, borderRadius: 30 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "14px" }}>
                <TextField
                  label="End Date"
                  type="date"
                  size="small"
                  fullWidth
                  value={end_date}
                  onChange={handleEndDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // sx={{ width: 200, borderRadius: 30 }}
                  onBlur={handleEndDateBlur}
                  InputProps={{
                    inputProps: {
                      min: start_date,
                    },
                  }}
                />
              </Grid>
            </Grid>
            {/* {dateError && <Typography color="error">{dateError}</Typography>} */}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Stack
            spacing={1}
            direction="row"
            style={{ height: "35px", marginTop: "-10%", marginRight: "3%" }}
          >
            <Button
              onClick={handleAddTaskClick}
              sx={{ marginRight: "2%", backgroundColor: colors.green.DEFAULT }}
              variant="contained"
              disabled={!farm}
            >
              <Typography variant="h6" color={colors.white.DEFAULT}>
                Add
              </Typography>
            </Button>
            <Button onClick={onClose} variant="outlined">
              <Typography variant="h6" color={colors.green.DEFAULT}>
                Close
              </Typography>
            </Button>
          </Stack>
        </DialogActions>

        {ledger_name === "Harvesting" && (
          <HarvestingDialog
            dialogOpen={harvestingDialogOpen}
            setDialogOpen={setHarvestingDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
        {ledger_name === "Irrigation" && (
          <IrrigationDialog
            dialogOpen={irrigationDialogOpen}
            setDialogOpen={setIrrigationDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
        {ledger_name === "Cleaning" && (
          <CleaningDialog
            dialogOpen={cleaningDialogOpen}
            setDialogOpen={setCleaningDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
        {ledger_name === "Pruning" && (
          <PruningDialog
            dialogOpen={pruningDialogOpen}
            setDialogOpen={setPruningDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
        {ledger_name === "Sowing" && (
          <SowingDialog
            dialogOpen={sowingDialogOpen}
            setDialogOpen={setSowingDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
        {ledger_name === "Purchase" && (
          <PurchaseDialog
            dialogOpen={purchaseDialogOpen}
            setDialogOpen={setPurchaseDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
        {ledger_name === "Transport" && (
          <TransportDialog
            dialogOpen={transportDialogOpen}
            setDialogOpen={setTransportDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
        {ledger_name === "Electricity" && (
          <ElectricityDialog
            dialogOpen={electricityDialogOpen}
            setDialogOpen={setElectricityDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
        {ledger_name === "Miscellaneous" && (
          <MiscellaneousDialog
            dialogOpen={miscellaneousDialogOpen}
            setDialogOpen={setMiscellaneousDialogOpen}
            mandatoryFields={dataToSend}
            listOfZones={listOfZones}
            fetchTaskData={fetchTaskData}
            closeMasterDialog={onClose}
          />
        )}
      </Dialog>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};

export default MasterDialog;
