import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,Autocomplete,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import NullScreen from "../../PopUpMsg/NullScreen";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { tokens } from "../../WMStheme";
import { GrChapterAdd } from "react-icons/gr";
import { useTheme } from "@mui/material";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../../PopUpMsg/SuccessPopUp";
import ErrorPopup from "../../PopUpMsg/ErrorPopUp";
import { LuWarehouse } from "react-icons/lu";
import { GiFruitBowl } from "react-icons/gi";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const columns = [
  { id: "sku_name", label: "SKU Name" },
  { id: "sku_code", label: "SKU Code" },
  { id: "qty", label: "Quantity" },
];

const AddProductInventory = ({ setOpenAddProductDialog }) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
const navigate = useNavigate();
  const theme = useTheme();
  const moment = require("moment");
  const colors = tokens(theme.palette.mode);
  const [repackData, setRepackData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [productNames, setProductNames] = useState([]);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [productId, setProductId] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseId, setWarehouseId] = useState("");
  const [productQuantity, setProductQuantity] = useState(null);

  
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  const fetchWarehouseNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_WAREHOUSE_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      //   console.log("data in fetchWarehouseNames", data?.data?.warehouse_list);
      setWarehouseNames(data?.data?.warehouse_list);
    } catch (error) {
      console.log("Error fetching WeekDays", error);
    }
  };
  const fetchProductMapped = async (warehouse_id) => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_REPACK_PRODUCT_LIST,
        {
          params: {
            limit: 1000,
            offset: 1,
            warehouse_id: warehouse_id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data?.Data;
      console.log("data in fetchProductMapped-------", data);
      setProductNames(data?.product_list);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
  };

  const fetchSKUMapped = async (product_id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_PRODUCT_SKU_ALL,
        {
          params: {
            limit: 10,
            offset: 1,
            product_id: product_id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      console.log("data in fetchSKUMapped-------", data);
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setRepackData(data?.items);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const handleSaveClick = async () => {
    setIsLoading(true);

    const paramValues = {
      product_id: productId,
      qty: productQuantity,
      warehouse_id: warehouseId,
    };
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.POST_REPACK_ADD_PRODUCT,
        paramValues,
        {
          params: paramValues,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setSuccessMessage(response?.data?.msg);
        setSuccessPopupOpen(true);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleWarehouseChange = (e) => {
    const warehouse_id = e.target.value;
    setProductId(null);
    setProductQuantity("");
    setRepackData([]);
    setProductNames([]);
    setWarehouseId(warehouse_id);
    fetchProductMapped(warehouse_id);
  };

  const handleProductChange = (e,newValue) => {
    const product_id = newValue?.id || '';
    setProductQuantity("");
    setRepackData([]);
    setProductId(product_id);
    fetchSKUMapped(product_id);
  };

  const handleRefreshClick = () => {
    setWarehouseId(null);
    setProductId(null);
    setProductQuantity("");
    setRepackData([]);
    setProductNames([]);
  };

  const containerStyle = {
    maxHeight: "53vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "53vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const gridContainerStyle = {
    display: "flex",
    height: "50vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "blue",
  };

  function TableHeadComponent() {
    return (
      <TableHead>
        <TableRow>
          {columns?.map((column) => (
            <StyledTableCell key={column?.id} align="center">
              {column?.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function TableRowsComponent() {
    return (
      <>
        <TableBody>
          {repackData
            ?.filter((row) => row.flag)
            ?.map((row, index) => (
              <StyledTableRow key={index}>
                {columns?.map((column) => (
                  <StyledTableCell key={column?.id} align="center">
                    {row[column.id]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
        </TableBody>
      </>
    );
  }

  const handleClose = () => {
    setOpenAddProductDialog(false);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={true}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <DialogTitle sx={{ position: "sticky", height: "8vh" }}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" color={colors.green.DEFAULT}>
            Repack
          </Typography>
        </Grid>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,
            color: "grey",
          }}
        >
          <CloseSharpIcon />
        </IconButton>
        <SuccessPopup
          open={successPopupOpen}
          onClose={() => setSuccessPopupOpen(false)}
          message={successMessage}
        />
        <ErrorPopup
          open={errorPopupOpen}
          onClose={() => setErrorPopupOpen(false)}
          message={errorMessage}
        />
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // paddingLeft: "10px",
            // paddingRight: "10px",
            width: "100%",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "25vh" ,marginTop:'1%'}}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2.5} md={2.5}>
                  <FormControl fullWidth>
                    <InputLabel>Select Warehouse</InputLabel>
                    <Select
                      label="Select Warehouse"
                      value={warehouseId}
                      onChange={handleWarehouseChange}
                      size="small"
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Warehouse" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <LuWarehouse />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Warehouse
                      </MenuItem>
                      {Array.isArray(warehouseData) &&
                      warehouseData?.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2.5} md={2.5}>
                  <FormControl fullWidth>
                   <Autocomplete
        options={productNames || []}
        getOptionLabel={(option) => capitalizeFirstLetter(option?.name)}
        value={productNames?.find((sku) => sku.name === productId) || null}
        onChange={handleProductChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Product"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <GiFruitBowl />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
                  
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <Button
                    variant="contained"
                    startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                    size="small"
                    sx={{
                      backgroundColor: colors.green.DEFAULT,
                      color: "#ffffff",
                    }}
                    onClick={handleRefreshClick}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} md={3}></Grid>
                <Grid item xs={12} sm={2} md={2} textAlign="right">
                  <Button
                    variant="contained"
                    startIcon={
                      <GrChapterAdd
                        style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                      />
                    }
                    sx={{
                      borderColor: colors.green.DEFAULT,
                      backgroundColor: colors.green.DEFAULT,
                    }}
                    onClick={handleSaveClick}
                    disabled={
                      productQuantity === "" || productQuantity === null
                    }
                    title="Save"
                  >
                    <Typography variant="h7" color={colors.white.DEFAULT}>
                      Save
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Enter Quantity"
                    value={productQuantity}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value) && value >= 0 && value <= 50000) {
                        setProductQuantity(value);
                      } else {
                        setProductQuantity("");
                      }
                    }}
                    variant="standard"
                    margin="normal"
                    inputProps={{
                      min: 0,
                      max: 50000,
                    }}
                    fullWidth
                    autoFocus
                    type="number"
                    required
                    helperText="Quantity should be less than 50000"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    startIcon={
                      <IoIosInformationCircleOutline
                        style={{ color: colors.black[200] }}
                      />
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: colors.black[200],
                        textAlign: "left",
                      }}
                    >
                      This is the total quantity of the product that will be
                      added in the Inventory.
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginTop: "8px" }}>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.black[200],
                      textAlign: "left",
                    }}
                  >
                    SKUs linked with above product:
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={containerStyle}>
            {isLoading ? (
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={isLoading} />
              </div>
            ) : (
              <>
                {warehouseId === null && productId === null ? (
                  <NullScreen
                    containerheight="53vh"
                    containerWidth="100%"
                    screenType="Info"
                    message="Select Warehouse and Product"
                  />
                ) : (
                  <>
                    {repackData?.length === 0 ? (
                      <NullScreen
                        containerheight="53vh"
                        containerWidth="100%"
                      />
                    ) : (
                      <>
                        <div style={gridContainerStyle}>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 200 }}
                              aria-label="customized table"
                            >
                              <TableHeadComponent />
                              <TableRowsComponent data1={repackData} />
                            </Table>
                          </TableContainer>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></DialogActions>
    </Dialog>
  );
};

export default AddProductInventory;
