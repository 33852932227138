import React, { useState, useEffect, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import {
  Box,
  Button,
  Dialog,
  InputAdornment,
  DialogTitle,
  DialogContent,
  Grid,
  Stack,
  DialogActions,
  FormHelperText,
} from "@mui/material";
import { BASE_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import HarvestingDialog from "./HarvestingDialog";
import IrrigationDialog from "./IrrigationDialog";
import CleaningDialog from "./CleaningDialog";
import PruningDialog from "./PruningDialog";
import LandDialog from "./LandDialog";
import SeedDialog from "./SeedDialog";
import PesticideDialog from "./PesticideDialog";
import axios from "axios";
import { SelectedDatesContext } from "../../../Components/TaskAllocationComponents/Scheduler"; // Update the path to the correct location of the Scheduler component

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const MasterDialog = ({ isOpen, onClose, fetchTaskData }) => {
  const [listOfZones, setListOfZones] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { selectedStartDate, selectedEndDate } =
    useContext(SelectedDatesContext);

  const [masterDialogState, setMasterDialogState] = useState({
    myStatus: "To-Do",
    myLabel: "High",
    myTime: "05:00",
    myAsignee: "",
    myFarm: "",
    myTask: "Cleaning",
    startDateString: selectedStartDate,
    endDateString: selectedEndDate,
  });

  useEffect(() => {
    setMasterDialogState((prevState) => ({
      ...prevState,
      startDateString: selectedStartDate,
      endDateString: selectedEndDate,
    }));
  }, [selectedStartDate, selectedEndDate]);

  const [harvestingDialogOpen, setHarvestingDialogOpen] = useState(false);
  const [irrigationDialogOpen, setIrrigationDialogOpen] = useState(false);
  const [cleaningDialogOpen, setCleaningDialogOpen] = useState(false);
  const [pruningDialogOpen, setPruningDialogOpen] = useState(false);
  const [landDialogOpen, setLandDialogOpen] = useState(false);
  const [seedDialogOpen, setSeedDialogOpen] = useState(false);
  const [pesticideDialogOpen, setPesticideDialogOpen] = useState(false);
  const [sowingDialogOpen, setSowingDialogOpen] = useState(false);
  const [transplantingDialogOpen, setTransplantingDialogOpen] = useState(false);
  const [customTaskDialogOpen, setCustomTaskDialogOpen] = useState(false);

  const { myStatus, myLabel, myTime, myAsignee, myFarm, myTask } =
    masterDialogState;

  const updateState = (key, value) => {
    setMasterDialogState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleOpen = (event) => {
    if (event.target === event.currentTarget) {
      console.log("handle open clicked !!!!!!!");
    }
  };

  const handleStatusChange = (event) => {
    updateState("myStatus", event.target.value);
  };

  const handleMyTaskChange = (event) => {
    updateState("myTask", event.target.value);
  };

  const handleMyLabelChange = (event) => {
    updateState("myLabel", event.target.value);
  };

  const handleTimeChange = (event) => {
    updateState("myTime", event.target.value);
  };

  const handleMyAsigneeChange = (event) => {
    updateState("myAsignee", event.target.value);
  };

  const handleMyFarmChange = (event) => {
    updateState("myFarm", event.target.value);
  };

  const handleAddTaskClick = () => {
    switch (myTask) {
      case "Harvesting":
        setHarvestingDialogOpen(true);
        break;
      case "Irrigation":
        setIrrigationDialogOpen(true);
        break;
      case "Cleaning":
        setCleaningDialogOpen(true);
        break;
      case "Pruning":
        setPruningDialogOpen(true);
        break;
      case "Land Acquisition":
        setLandDialogOpen(true);
        break;
      case "Seed Procurement":
        setSeedDialogOpen(true);
        break;
      case "Pesticide Acquisition":
        setPesticideDialogOpen(true);
        break;
    }
  };

  const tasks = [
    "Harvesting",
    "Cleaning",
    "Irrigation",
    "Pruning",
    "Land Acquisition",
    "Seed Procurement",
    "Pesticide Acquisition",
    // "Transplanting",
    // "Sowing",
    // "Custom Tasks",
  ];
  const [farmData, setFarmData] = useState(null);
  const [subordinateData, setSubordinateData] = useState(null);
  const [farmOptions, setFarmOptions] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState("");
  useEffect(() => {
    fetchFarmData();
  }, []);
  useEffect(() => {
    fetchData();
    fetchTaskLabel();
    fetchTaskStatus();
    fetchSubordinateData();
    console.log(" Zone Data Fetch SuccessfulL !");
  }, [myFarm]);
  const fetchFarmData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CROPMANAGEMENT_MAINPAGE,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response?.data;
      console.log("data in new API---------", data);
      setFarmData(data?.farmdetail?.rows);
      setFarmOptions(farmData);
      setSelectedFarm(farmData[0]);
    } catch (error) {
      console.log("Error fetching farm data:", error);
    }
    console.log("Names of Farm available", farmData);
  };
  console.log("Farm dataa", farmData);
  // for fetching subordinates corresponding to a farm name
  const fetchSubordinateData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARM,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const subordinateData = data?.result?.rows;
      const filteredRows = subordinateData?.filter(
        (rows) => rows?.farm_name === masterDialogState?.myFarm
      );
      const subordinates = filteredRows[0]?.farm_subordinate;
      setSubordinateData(subordinates);
    } catch (error) {
      console.log("Error fetching subordinate data:", error);
    }
    console.log("Names of subordinate available", subordinateData);
  };

  // for fetching zones corresponding to selected farm
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_CONFIGURATION_FARM,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const zonesdata = response?.data?.result.rows;

      const filteredRows = zonesdata?.filter(
        (rows) => rows.farm_name === masterDialogState.myFarm
      );
      const zones = filteredRows?.map((rows) =>
        rows.zones.map((zone) => ({
          zoneName: zone.zone_name,
        }))
      );
      console.log(zones);

      const myArray = [];

      zones[0].map((item) => {
        myArray.push(item.zoneName);
      });
      console.log(myArray);
      setListOfZones(myArray);
    } catch (error) {
      console.log("Error fetching zone data:", error);
    }
  };

  const [taskLabel, setTaskLabel] = useState([]);

  const fetchTaskLabel = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_TASK_MANAGEMENT_TASKLABEL,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setTaskLabel(options);
    } catch (error) {
      console.log("Error fetching Task Labels", error);
    }
  };

  const [taskStatus, setTaskStatus] = useState([]);

  const fetchTaskStatus = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_TASK_MANAGEMENT_TASKSTATUS,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      const options = data?.rows?.map((row) => row?.dropdown_value);
      setTaskStatus(options);
    } catch (error) {
      console.log("Error fetching Task Status", error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{ style: { minWidth: "500px", minHeight: "350px" } }}
    >
      <DialogTitle variant="h4">Create your Task Here !</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <FormControl fullWidth>
            <TextField
              select
              fullWidth
              label="Select Task"
              value={myTask}
              onChange={handleMyTaskChange}
              margin="normal"
              size="small"
              displayEmpty
              inputProps={{ "arial-label": "Select Task" }}
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: "100px", // Set the maximum height of the dropdown menu
                    },
                  },
                },
              }}
            >
              {tasks?.map((task) => (
                <MenuItem key={task} value={task}>
                  {task}
                </MenuItem>
              ))}
              <MenuItem disabled value="">
                Select Task
              </MenuItem>
            </TextField>
          </FormControl>
          <Grid
            container
            alignItems="center"
            spacing={1}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={12} sm={4} style={{ marginTop: "14px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Label</InputLabel>
                <Select
                  labelId="Select Label"
                  label="Select Label"
                  fullWidth
                  size="small"
                  value={myLabel}
                  onChange={handleMyLabelChange}
                  required
                  displayEmpty
                  inputProps={{ "aria-label": "Select Label" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: "100px" }, // Set the max-height for the menu
                    },
                  }}
                >
                  {taskLabel?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem disabled value="">
                    Select Label
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ marginTop: "14px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Status</InputLabel>
                <Select
                  labelId="Select Status"
                  label="Select Status"
                  value={myStatus}
                  onChange={handleStatusChange}
                  required
                  fullWidth
                  size="small"
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: "100px" }, // Set the max-height for the menu
                    },
                  }}
                  inputProps={{ "aria-label": "Select Status" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  {taskStatus?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem disabled value="">
                    Select Status
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ marginTop: "14px" }}>
              <TextField
                id="time"
                label="Due time"
                type="time"
                value={myTime}
                fullWidth
                size="small"
                onChange={handleTimeChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={1}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={12} sm={6} style={{ marginTop: "14px" }}>
              <FormControl fullWidth error={!myFarm}>
                <InputLabel>Select Farm</InputLabel>
                <Select
                  label="Select Farm"
                  labelId="Select Farm"
                  required
                  fullWidth
                  size="small"
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: "100px" }, // Set the max-height for the menu
                    },
                  }}
                  value={myFarm}
                  onChange={handleMyFarmChange}
                  inputProps={{ "aria-label": "Select Farm" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  {farmData &&
                    farmData?.map((farm) => (
                      <MenuItem key={farm.farm_name} value={farm.farm_name}>
                        {farm.farm_name}
                      </MenuItem>
                    ))}
                  <MenuItem disabled value="">
                    Select Farm
                  </MenuItem>
                </Select>
                {/* {!myFarm && (
                  <FormHelperText>This field is required</FormHelperText>
                )} */}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: "14px" }}>
              <FormControl fullWidth>
                <InputLabel>Assign to</InputLabel>
                <Select
                  value={myAsignee}
                  label="Assign to"
                  labelId="Assign to"
                  fullWidth
                  displayEmpty
                  required
                  size="small"
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: "100px" }, // Set the max-height for the menu
                    },
                  }}
                  onChange={handleMyAsigneeChange}
                  inputProps={{ "aria-label": "Select Subordinate" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  {subordinateData ? (
                    <MenuItem value={subordinateData}>
                      {subordinateData}
                    </MenuItem>
                  ) : (
                    <MenuItem disabled value="">
                      Select Subordinate
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Stack
          spacing={1}
          direction="row"
          style={{ height: "35px", marginTop: "-10%", marginRight: "3%" }}
        >
          <Button
            onClick={handleAddTaskClick}
            sx={{ marginRight: "2%", backgroundColor: colors.green.DEFAULT }}
            variant="contained"
            disabled={!myFarm}
          >
            <Typography variant="h6" color={colors.white.DEFAULT}>
              Add
            </Typography>
          </Button>
          <Button onClick={onClose} variant="outlined">
            <Typography variant="h6" color={colors.green.DEFAULT}>
              Close
            </Typography>
          </Button>
        </Stack>
      </DialogActions>

      {myTask === "Harvesting" && (
        <HarvestingDialog
          dialogOpen={harvestingDialogOpen}
          setDialogOpen={setHarvestingDialogOpen}
          masterDialogState={masterDialogState}
          listOfZones={listOfZones}
          fetchTaskData={fetchTaskData}
        />
      )}
      {myTask === "Irrigation" && (
        <IrrigationDialog
          dialogOpen={irrigationDialogOpen}
          setDialogOpen={setIrrigationDialogOpen}
          masterDialogState={masterDialogState}
          listOfZones={listOfZones}
          fetchTaskData={fetchTaskData}
        />
      )}
      {myTask === "Cleaning" && (
        <CleaningDialog
          dialogOpen={cleaningDialogOpen}
          setDialogOpen={setCleaningDialogOpen}
          masterDialogState={masterDialogState}
          listOfZones={listOfZones}
          fetchTaskData={fetchTaskData}
        />
      )}
      {myTask === "Pruning" && (
        <PruningDialog
          dialogOpen={pruningDialogOpen}
          setDialogOpen={setPruningDialogOpen}
          masterDialogState={masterDialogState}
          listOfZones={listOfZones}
          fetchTaskData={fetchTaskData}
        />
      )}
      {myTask === "Land Acquisition" && (
        <LandDialog
          dialogOpen={landDialogOpen}
          setDialogOpen={setLandDialogOpen}
          masterDialogState={masterDialogState}
          listOfZones={listOfZones}
          fetchTaskData={fetchTaskData}
        />
      )}
      {myTask === "Seed Procurement" && (
        <SeedDialog
          dialogOpen={seedDialogOpen}
          setDialogOpen={setSeedDialogOpen}
          masterDialogState={masterDialogState}
          listOfZones={listOfZones}
          fetchTaskData={fetchTaskData}
        />
      )}
      {myTask === "Pesticide Acquisition" && (
        <PesticideDialog
          dialogOpen={pesticideDialogOpen}
          setDialogOpen={setPesticideDialogOpen}
          masterDialogState={masterDialogState}
          listOfZones={listOfZones}
          fetchTaskData={fetchTaskData}
        />
      )}
    </Dialog>
  );
};

export default MasterDialog;
