
// reducers.js
const initialState = {
    action: '',
   skuData: null,
  };
  export const skuReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SKU_ACTION':
        return {
          action: action.payload.action,
          skuData: action.payload.skuData,
        };
      default:
        return state;
    }
  };