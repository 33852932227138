import React from 'react'
import Chart from "react-apexcharts";

const WMSForecastProductGraphComponent = ({ graphData }) => {
    const orderDate = graphData?.map((item) => item?.next_7_days_data) || [];

    const chartOptions = {
      colors: ["#34519F83"],
  
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          delay: 150,
          enabled: true,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 400,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 6,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        title: {
          text: "Date",
          offsetX: 1,
          style: {
            fontSize: "15px",
            fontWeight: 400,
            color: "#333",
          },
        },
        categories: orderDate,
        type: "datetime",
        labels: {
          datetimeUTC: true,
          style: {
            fontSize: "12px",
            fontWeight: 400,
            color: "#333",
          },
        },
      },
      yaxis: {
        title: {
          text: "Total Product Quantity",
          offsetX: 1,
          style: {
            fontSize: "15px",
            fontWeight: 400,
            color: "#333",
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 0.5,
        colors: ["#34519F83"],
      },
      tooltip: {
        enabled: true,
        position: "bottomRight",
        x: {
          format: "dd/MM/yyyy",
        },
        style: {
          fontSize: "9px",
          fontWeight: 400,
        },
      },
      fill: {
        opacity: 0.5,
      },
    };
  
    const chartSeries = graphData
      ? [
          {
            name: "Total Product Quantity",
            data: graphData?.map((item, index) => ({
              x: item?.order_date,
              y: item?.total_weight_kg,
              // goals: [
              //   {
              //     name: "Total Product Count",
              //     value: item?.distinct_Product_sold,
              //   },
              //   {
              //     name: "Total Customer Count",
              //     value: item?.distinct_customer_count,
              //   },
              //   {
              //     name: "Total Order Count",
              //     value: item?.order_count,
              //   },
              // ],
            })),
          },
        ]
      : [];
  
    return (
      <div style={{ overflowY: "hidden" }}>
        <div style={{ marginRight: "25px" }}>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={360}
          />
        </div>
      </div>
    );
  };

export default WMSForecastProductGraphComponent