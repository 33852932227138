import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Delete } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ulid } from "ulid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { Link } from "react-router-dom";
import SocketReportPopup from "../../../Attendance/HomePage/SocketReportPopup";

import NullScreen from "../../PopUpMsg/NullScreen";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { MdModeEdit } from "react-icons/md";
import { MenuItem, Grid } from "@mui/material";
import moment from "moment";
import { setProductAction } from "../../actions/productActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ScaleLoader } from "react-spinners";
import { TiTickOutline } from "react-icons/ti";
import { TbMoodEmpty } from "react-icons/tb";
import { IoIosPerson } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Box,
  InputLabel,
  createTheme,
  ThemeProvider,
  Select,
  Input,
  FormHelperText,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { Label } from "semantic-ui-react";

import { setCustomerAction } from "../../actions/customerActions";
import Pagination from "@mui/material/Pagination";

import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { tokens } from "../../WMStheme";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const gridContainerStyle = {
  height: "70vh",
  width: "100%",
};
const containerStyle8 = {
  maxHeight: "70vh",
  height: "100%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
};
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70vh",
  width: "100%",
  overflowY: "hidden",
};

const ProductView = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const [productId, setProductId] = useState(null);
  const [customerNames, setCustomerNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [rows, setRows] = useState([]);
  const [status, setStatus] = useState("");

  // Inside your component
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDropdown, setIsLoadingDropdown] = useState(false);
  const dispatch = useDispatch();
  const selectedProduct = useSelector((state) => state.product.selectedProduct);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [offset, setOffSet] = useState(1);
  const dateFormat = "DD/MM/YYYY";
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(1);
  const [generatedId, setGeneratedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [filterValues, setFilterValues] = useState({
    limit: 50,
    offset: offset,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    limit: 50,
    offset: offset,
    status: status,
    product_search: null,
  });

  useEffect(() => {
    const updatedFilterValues = {
      limit: 50,
      offset: offset,
      product_search: search,
      status: status,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [search, status]);

  const handleButtonClick = () => {
    navigate("/configuration");
  };
  useEffect(() => {
    fetchProductData(filterValues);
  }, []);

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const fetchProductData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_PRODUCT_MASTER_DATA}`,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.Data;
        console.log("data", data);
        setRows(data?.customer_list);
        setTotalPages(data?.total_pages);

        setIsLoading(false);
        console.log("customer  Data", data?.customer_list);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      // Set loading to false after the request is completed
    }
  };

  const fetchParticularProductDetails = async (productId) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_PRODUCT_DETAILS(productId)}`,
        {
          params: {
            limit: 50,
            offset: offset,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.Data;
        console.log("data", data);

        dispatch(setProductAction("edit", data));

        // setTotalPages(data?.total_pages);
        // setIsLoading(false);
        // console.log("customer  Data", data?.customer_list);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      // Set loading to false after the request is completed
    }
  };

  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchProductData(updatedValues);
  };
  const handleGenerateReportClick = async () => {
    setLoading(true);
    const requestId = ulid();
    console.log("FilterValues:", filterValues);

    // Clone the original filterValues object


// Clone the original filterValues object
const updatedFilterValues = { ...filterValues };

// Iterate over each key in the filterValues object
for (const key in updatedFilterValues) {
    // Check if the value corresponding to the key is a string and is an empty string
    if (typeof updatedFilterValues[key] === 'string' && updatedFilterValues[key].trim() === '') {
        // Set the value to null
        updatedFilterValues[key] = null;
    }
}

// Log the updated filterValues
console.log("updated filtervalues------->", updatedFilterValues);





    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_DOWNLOAD_MASTER,
        {
          params: {
            ...updatedFilterValues,
            type:'product',
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      }
    } catch (error) {
      setLoading(false);
      // setErrorPopupOpen(true);
    }
  };
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#34519fe6",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#34519fe6", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const StatusTag = ({ status }) => {
    let tagColor;
    let statusLabel;

    switch (status) {
      case true:
        tagColor = "#0B823A";
        statusLabel = "Active";
        break;
      case false:
        tagColor = "#DE3721";
        statusLabel = "In-Active";
        break;
      default:
        tagColor = "gray";
        statusLabel = "Unknown";
        break;
    }

    return (
      <Label
        style={{
          backgroundColor: tagColor,
          color: "white",
          fontWeight: typography.h1,
          fontFamily: "Lexend Deca",
          cursor: "pointer",
          width: "auto",
        }}
        tag
      >
        {statusLabel}
      </Label>
    );
  };
  const columns = useMemo(() => [
    {
      header: "Edit",
      size: 2,
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => ({
        onClick: () => {
          console.log("cell original", cell.row.original?.id);
          console.log(cell.getValue(), cell.id);
          const rowData = cell.row.original?.id;
          fetchParticularProductDetails(cell.row.original?.id);
          console.log("Dispatching action with rowData:", rowData);

          // Navigate to the "mainpage"
          navigate("/addProduct");
        },
        title: "Click to Edit ",
      }),
      Cell: ({ cell }) => {
        // const navigate = useNavigate();

        const handleClick = () => {
          console.log("clicked here or not ");
          //  console.log("Rowdata Here ", rowData);

          // Navigate to another page (replace '/destination' with your desired destination)

          // If you still want to render CustomerMasterConfig, you can do it conditionally
        };
        return (
          <div className="tooltip-cell" title="Click to Edit the Details ">
            <IconButton
              className="icon-wrapper"
              onMouseEnter={() => console.log("Hovering...")}
            >
              <MdModeEdit />
            </IconButton>

            <span className="farm-name">{cell.getValue()}</span>
          </div>
        );
      },
    },

    {
      header: "Name",
      accessorKey: "name",
      searchable: true,
      size: 100,
      Cell: ({ row }) => {
        const name = row?.original?.name;

        return <span>{name ? capitalizeFirstLetter(name) : "-"}</span>;
      },
    },
    {
      header: "Code",
      accessorKey: "code",
      size: 10,
    },
    {
      header: "Status",
      accessorKey: "status",
      size: 10,
      Cell: ({ row }) => <StatusTag status={row.original.status} />,
    },

    // {
    //   header: "Family",
    //   accessorKey: "family",
    //   size: 2,
    // },

    {
      header: "HSN Code",
      accessorKey: "hsn",
      size: 15,
    },
    {
      header: "Avg Sales Price",
      accessorKey: "avg_sale_price",
      size: 10,
    },
    {
      header: "Sales Price",
      accessorKey: "sale_price",
      size: 1,
    },
    {
      header: "UOM",
      accessorKey: "uom",
      size: 100,
    },
    {
      header: "Created At",
      accessor: "created_at",
      size: 100,
      Cell: ({ row }) => {
        const created_at = row?.original?.created_at;

        return <span>{created_at ? formatDate(created_at) : "-"}</span>;
      },
    },
  ]);

  const handleApplyClick = () => {
    const newFilterValues = {
      status: temporaryFilterValues.status,
      limit: 50,
      offset: 1,
    };
    setOffSet(1);
    setSearch("");
    setStatus(temporaryFilterValues.status);
    setFilterValues(newFilterValues);
    fetchProductData(newFilterValues);
  };

  const handleRefreshClick = () => {
    const initialValues = {
      limit: 50,
      offset: 1,
    };
    setOffSet(1);
    setStatus(null);
    setSearch("");
    setFilterValues(initialValues);
    fetchProductData(initialValues);
  };

  const handleSearchClick = () => {
    setOffSet(1);
    const updatedValues = {
      ...filterValues,
      offset: 1,
      product_search: search,
    };
    setFilterValues(updatedValues);
    fetchProductData(updatedValues);
  };

  const handleClearClick = () => {
    setOffSet(1);
    setSearch("");
    const updatedValues = { ...filterValues, offset: 1, product_search: null };
    setFilterValues(updatedValues);
    fetchProductData(updatedValues);
    setCount((prevCount) => prevCount + 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "100%",
      }}
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Product Details
        </Typography>
        <Button
          variant="outlined"
          startIcon={
            <ArrowBackIcon
              style={{ color: colors.green.DEFAULT, fontSize: 26 }}
            />
          }
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Back
          </Typography>
        </Button>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "10vh" }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2} md={2}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  fullWidth
                  required
                  size="small"
                  displayEmpty
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  inputProps={{ "aria-label": "Type" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <ToggleOffIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">In-Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={0.8} md={0.8}>
              <Button
                variant="contained"
                startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                sx={{
                  backgroundColor: "#34519fe6",
                  color: "#ffffff",
                }}
                size="small"
                onClick={handleApplyClick}
                disabled={!status}
              >
                Apply
              </Button>
            </Grid>
            <Grid item xs={12} sm={0.8} md={0.8}>
              <Button
                variant="contained"
                startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                size="small"
                sx={{
                  backgroundColor: "#34519fe6",
                  color: "#ffffff",
                }}
                onClick={handleRefreshClick}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12} sm={7.3} md={7.3}></Grid>
            <Grid item xs={12} sm={0.8} md={0.8}>
            <Button
              variant="contained"
              startIcon={
                <FileDownloadIcon
                  style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                />
              }
              sx={{
                borderColor: colors.green.DEFAULT,
                backgroundColor: colors.green.DEFAULT,
              }}
              onClick={handleGenerateReportClick}
              title="Download Product"
            >
              <Typography variant="h7" color={colors.white.DEFAULT}>
                Download
              </Typography>
            </Button>
          </Grid>
          </Grid>
        </Grid>
        {openDialog && (
          <SocketReportPopup
            setOpenDialog={setOpenDialog}
            requestId={generatedId}
          />
        )}
      </Grid>

      <Grid container spacing={1} style={containerStyle8}>
        {isLoading ? (
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={isLoading} />
          </div>
        ) : (
          <div style={gridContainerStyle}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={rows}
                key={count}
                enableGrouping
                enableStickyHeader
                enablePagination={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                getRowId={(row) => row.customerCode}
                initialState={{
                  density: "compact",
                  pagination: { pageIndex: 0, pageSize: 10 },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    color: "#fff",
                    backgroundColor: "#34519fe6",
                  },
                }}
                muiToolbarAlertBannerChipProps={{ color: "primary" }}
                muiTableBodyProps={{
                  sx: {
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#ffff",
                    },
                  },
                }}
                muiTableContainerProps={{ sx: { maxHeight: "53vh" } }}
                enableGlobalFilter={false}
                renderTopToolbarCustomActions={() => (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <InputLabel htmlFor="Product Name">
                          Search by Product Name
                        </InputLabel>
                        <Input
                          id="Product Name"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Product Name"
                                onClick={handleSearchClick}
                                disabled={search.length < 3}
                              >
                                {<FaSearch />}
                              </IconButton>
                              <IconButton
                                aria-label="Product Name"
                                onClick={handleClearClick}
                              >
                                {<CloseSharpIcon />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText id="component-helper-text">
                          At least 3 characters
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              />
            </ThemeProvider>
          </div>
        )}
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ marginTop: "0.1%" }}>
        {rows?.length > 0 && (
          <Pagination
            count={totalPages}
            page={offset}
            onChange={handleChangePage}
          />
        )}
      </Grid>
    </Box>
  );
};
export default ProductView;
