import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTheme } from "@mui/material";
import { tokens } from "../WMStheme";
import { Progress, Spin } from "antd";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import STOFile from "./STOFile";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

const STOProgressPopUp = ({ setOpenDialog, stoData }) => {
  console.log("stoData in STOProgressPopUp", stoData);
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState("In Progress");
  const [isLoading, setIsLoading] = useState(false);
  const [percent, setPercent] = useState(3);

  useEffect(() => {
    let currentPercent = 1;
    const interval = setInterval(() => {
      currentPercent += 2;
      if (currentPercent >= 100) {
        setPercent(100);
        clearInterval(interval);
      } else {
        setPercent(currentPercent);
      }
    }, 50);
    setTimeout(() => {
      clearInterval(interval);
    }, 5000);
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "30%",
          height: "35%",
        },
      }}
    >
      <DialogTitle sx={{ position: "sticky", height: "3%" }}>
        <Typography
          variant="h3"
          color={colors.green.DEFAULT}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {percent === 100 ? "Report Generated!" : responseMessage}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,
            color: "grey",
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              marginTop: "55px",
              //   height: "10vh",
              zIndex: "1",
            }}
          >
            <ScaleLoader color={colors.green.DEFAULT} loading={isLoading} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              //   alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Progress
              strokeColor={colors.green.DEFAULT}
              type="circle"
              percent={percent}
            />
          </div>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PDFDownloadLink
          document={<STOFile stoData={stoData} />}
          fileName={`${stoData?.sto_no}_sto.pdf`}
        >
          {({ loading }) => (
            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.green.DEFAULT,
              }}
              startIcon={
                <FileDownloadIcon
                  style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                />
              }
              disabled={percent !== 100}
            >
              <Typography
                variant="h6"
                color={colors.white.DEFAULT}
                fontFamily="Lexend Deca"
              >
                {loading ? "Loading" : "Download"}
              </Typography>
            </Button>
          )}
        </PDFDownloadLink>
      </DialogActions>
    </Dialog>
  );
};

export default STOProgressPopUp;
