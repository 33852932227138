import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  Box,
  Tab,
  TextField,
  Tabs,
  Autocomplete,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { BASE_OMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { FaHouseChimney } from "react-icons/fa6";
import NullScreen from "../PopUpMsg/NullScreen";
import { tokens } from "../WMStheme";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { VscGraph } from "react-icons/vsc";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import SuccessPopup from "../PopUpMsg/SuccessPopUp";
import ErrorPopup from "../PopUpMsg/ErrorPopUp";
import { TbMoodEmpty } from "react-icons/tb";
import { IoIosPerson } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { GiFruitBowl } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { Divider } from "antd";
import { MdCoPresent } from "react-icons/md";
import { HiOutlineIdentification } from "react-icons/hi2";
import { IoLocationOutline } from "react-icons/io5";
import { WiDayCloudy } from "react-icons/wi";
import { FaHourglassEnd } from "react-icons/fa6";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import WMSForecastProductGraphComponent from "./WMSForecastProductGraphComponent";
import WMSForecastOrderGraphComponent from "./WMSForecastOrderGraphComponent";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import WMSStatContainer from "./WMSStatContainer";
import WMSOrderGraphComponent from "./WMSOrderGraphComponent";
import WMSQuantityGraphComponent from "./WMSQuantityGraphComponent";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";
import RatePredictionGraph from "./RatePredictionGraph";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const axiosInstanceOMS = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_OMS_URL}`,
});

const axiosInstanceWMS = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstanceWMS.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
const HomePage = () => {
  axiosInstanceWMS.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const moment = require("moment");
  const [orderGraphData, setOrderGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [customerNames, setCustomerNames] = useState([]);
  const [customerGroupNames, setCustomerGroupNames] = useState([]);
  const [skuNames, setSkuNames] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [customerTypeOptions, setCustomerTypeOptions] = useState([]);
  const [value, setValue] = useState(0);
  const [forecastValue , setForecastValue] =useState(0);
  const [customerId, setCustomerId] = useState(null);
  const [skuId, setSkuId] = useState(null);
  const [customerGroupId, setCustomerGroupId] = useState(null);
  const [dayId, setDayId] = useState(null);
  const [customerType, setCustomerType] = useState(null);
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);

  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());

  const [refreshCount, setRefreshCount] = useState(1);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [filterValues, setFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
  });

  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    customer_id: null,
    customer_type: null,
    group_id: null,
    item_id: null,
    day: null,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleForecastChange = (event, newValue) => {
    setForecastValue(newValue);
  };
  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,
      customer_id: customerId,
      group_id: customerGroupId,
      customer_type: customerType,
      item_id: skuId,
      day: dayId,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [
    startDate,
    endDate,
    customerId,
    skuId,
    dayId,
    customerGroupId,
    customerType,
  ]);

  useEffect(() => {
    fetchCustomerNames(1);
    fetchCustomerTypeOptions();
    fetchSKUNames();
    fetchWeekDays();
    fetchCustomerGroupData();
    fetchOrderGraphData(filterValues);
  }, []);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const handleApplyClick = () => {
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      customer_id: temporaryFilterValues.customer_id,
      group_id: temporaryFilterValues.group_id,
      item_id: temporaryFilterValues.item_id,
      customer_type: temporaryFilterValues.customer_type,
      day: temporaryFilterValues.day,
    };
    setStartDate(temporaryFilterValues.start_date);
    setEndDate(temporaryFilterValues.end_date);
    setCustomerId(temporaryFilterValues.customer_id);
    setCustomerGroupId(temporaryFilterValues.group_id);
    setSkuId(temporaryFilterValues.item_id);
    setDayId(temporaryFilterValues.day);
    setCustomerType(temporaryFilterValues.customer_type);

    setFilterValues(newFilterValues);
    fetchOrderGraphData(newFilterValues);
  };

  const fetchCustomerTypeOptions = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceWMS.get(
        API_ENDPOINTS.GET_CONFIGURATION_MODULE_CUSTOMER_CUSTOMERTYPE,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data;
        const options = data?.Data?.map((row) => row?.dropdown_value);
        setCustomerTypeOptions(options);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  const fetchCustomerNames = async (currPage) => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceOMS.get(
        API_ENDPOINTS.GET_CUSTOMER_DATA,
        {
          params: {
            limit: 1000,
            offset: currPage,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const newData = response?.data?.data?.data || [];
        setCustomerNames((prevData) =>
          currPage === 1 ? newData : [...prevData, ...newData]
        );
        setHasMore(newData.length === 50);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };
  const fetchWeekDays = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceOMS.get(API_ENDPOINTS.GET_WEEK_DAYS, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      const data = await response?.data;
      if (response.status >= 200 && response.status < 305) {
        setWeekDays(data?.data);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };
  const fetchSKUNames = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceOMS.get(API_ENDPOINTS.GET_SKU_DATA, {
        params: {
          limit: 1000,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      const data = await response?.data;
      if (response.status >= 200 && response.status < 305) {
        setSkuNames(data);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  const fetchCustomerGroupData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceWMS.get(
        `${API_ENDPOINTS.GET_WMS_CUSTOMERGROUP_DATA}`,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      if (response.status >= 200 && response.status < 305) {
        setCustomerGroupNames(data?.group_list);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const fetchOrderGraphData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceOMS.get(
        API_ENDPOINTS.GET_ORDER_GRAPH_DATA,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      const data = await response?.data?.data;
      if (response?.data?.status >= 200 && response?.data?.status < 305) {
        setOrderGraphData(data);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  const handleRefreshClick = () => {
    const initialValues = {
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
    };
    setCustomerId(null);
    setCustomerGroupId(null);
    setSkuId(null);
    setDayId(null);
    setCustomerType(null);
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
    setPage(1);
    fetchCustomerNames(1);
    fetchOrderGraphData(initialValues);
  };

  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const containerStyle = {
    maxHeight: "63vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundColor: "green",
  };
  const statContainerStyle = {
    height: "15vh",
    // backgroundColor: "blue",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "63vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };

  const fetchPageData = (newPage) => {
    fetchCustomerNames(newPage);
    setPage(newPage);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid container alignItems="center" sx={{ height: "8vh" }}>
          <Typography
            variant="h1"
            fontWeight="400"
            fontFamily="Lexend Deca"
            color={colors.green.DEFAULT}
            style={{ marginLeft: "8px" }}
          >
            Home
          </Typography>
        </Grid>

        <div style={{ paddingRight: "10px" }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "6vh" }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2.2} md={2.2}>
                  <RangePicker
                    onChange={handleDateChange}
                    key={refreshCount}
                    format={dateFormat}
                    defaultValue={[
                      dayjs(formatDate(startDate), dateFormat),
                      dayjs(formatDate(endDate), dateFormat),
                    ]}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={1.6} md={1.6}>
                  <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                      label="Type"
                      fullWidth
                      required
                      size="small"
                      value={customerType}
                      onChange={(e) => {
                        setCustomerType(e.target.value);
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Type" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <SupervisedUserCircleIcon />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>

                      {customerTypeOptions?.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={1.6} md={1.6}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={customerGroupNames || []}
                    getOptionLabel={(option) =>
                      capitalizeFirstLetter(option?.group_name)
                    }
                    value={
                      customerGroupNames?.find(
                        (group) => group.id === customerGroupId
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setCustomerGroupId(newValue ? newValue.id : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Customer Group"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <GiFruitBowl />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={1.6} md={1.6}>
                  <Autocomplete
                    id="combo-box-customer"
                    options={customerNames || []}
                    getOptionLabel={(option) =>
                      capitalizeFirstLetter(option?.customer_name)
                    }
                    value={
                      customerNames?.find(
                        (customer) => customer?.customer_id === customerId
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setCustomerId(newValue ? newValue?.customer_id : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Customer"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <IoIosPerson />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={1.6} md={1.6}>
                  <Autocomplete
                    id="combo-box-product"
                    options={skuNames || []}
                    getOptionLabel={(option) =>
                      capitalizeFirstLetter(option?.item_name)
                    }
                    value={
                      skuNames?.find((sku) => sku?.item_id === skuId) || null
                    }
                    onChange={(event, newValue) => {
                      setSkuId(newValue ? newValue?.item_id : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Product"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start" size="small" disabled>
                                <GiFruitBowl />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={1.6} md={1.6}>
                  <FormControl fullWidth>
                    <InputLabel>Select Day</InputLabel>
                    <Select
                      label="Select Day"
                      value={dayId}
                      onChange={(e) => {
                        setDayId(e.target.value);
                      }}
                      size="small"
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: "150px" }, // Set the max-height for the menu
                        },
                      }}
                      inputProps={{ "aria-label": "Select Day" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <CiCalendarDate />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={null}>All</MenuItem>
                      {weekDays?.map((day) => (
                        <MenuItem key={day.value} value={day.value}>
                          {day.day}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={0.8} md={0.8}>
                  <Button
                    variant="contained"
                    startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                    sx={{
                      backgroundColor: "#34519fe6",
                      color: "#ffffff",
                    }}
                    size="small"
                    onClick={handleApplyClick}
                    disabled={!startDate || !endDate}
                  >
                    Apply
                  </Button>
                </Grid>
                <Grid item xs={12} sm={0.8} md={0.8}>
                  <Button
                    variant="contained"
                    startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                    size="small"
                    sx={{
                      backgroundColor: "#34519fe6",
                      color: "#ffffff",
                    }}
                    onClick={handleRefreshClick}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid style={statContainerStyle}>
            <WMSStatContainer statData={orderGraphData?.generic_stats} />
          </Grid>

          <Grid container spacing={1} style={containerStyle}>
            {isLoading ? (
              <div style={loaderContainerStyle}>
                <ScaleLoader color="#495057" loading={isLoading} />
              </div>
            ) : orderGraphData?.length === 0 ? (
              <NullScreen containerheight="62vh" containerWidth="100%" />
            ) : (
              <>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    flexGrow: 1,
                    // backgroundColor: "red",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="#292B2A"
                    variant="fullWidth"
                    indicatorColor="transparent"
                  >
                    <Tab
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            textDecoration: "none",
                            color: colors.black.DEFAULT,
                          }}
                        >
                          Order Details
                        </Typography>
                      }
                      sx={{
                        "&.Mui-selected": {
                          color: "#2949c73d",
                          backgroundColor: "#2949c73d",
                        },
                      }}
                    />
                    <Tab
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            textDecoration: "none",
                            color: colors.black.DEFAULT,
                          }}
                        >
                          Product Details
                        </Typography>
                      }
                      sx={{
                        "&.Mui-selected": {
                          color: "#2949c73d",
                          backgroundColor: "#2949c73d",
                        },
                      }}
                    />
                  </Tabs>

                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                    }}
                  >
                    {value === 0 && (
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <WMSOrderGraphComponent
                          graphData={orderGraphData?.order_stats}
                        />
                      </div>
                    )}
                    {value === 1 && (
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <WMSQuantityGraphComponent
                          graphData={orderGraphData?.order_stats}
                        />
                      </div>
                    )}
                  </Box>
                </div>
                <Divider
                style={{
                  background: "rgba(0, 0, 0, 0.2)",
                  height: "2px",
                  marginTop:"1%",
                }}
              >
                <Typography
                  variant="h3"
                  color={colors.white.DEFAULT}
                  style={{
                     backgroundColor: "#34519F83",
                    padding: "6px",
                    borderRadius: "6px",
                  }}
                >
                  Forecasting Order/ Product Details
                </Typography>
              </Divider>
              <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    flexGrow: 1,
                    marginTop:"1%",
                    // backgroundColor: "red",
                  }}
                >
                  <Tabs
                    value={forecastValue}
                    onChange={handleForecastChange}
                    textColor="#292B2A"
                    variant="fullWidth"
                    indicatorColor="transparent"
                  >
                    <Tab
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            textDecoration: "none",
                            color: colors.black.DEFAULT,
                          }}
                        >
                         Order Details
                        </Typography>
                      }
                      sx={{
                        "&.Mui-selected": {
                          color: "#2949c73d",
                          backgroundColor: "#2949c73d",
                        },
                      }}
                    />
                    <Tab
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            textDecoration: "none",
                            color: colors.black.DEFAULT,
                          }}
                        >
                         Product Details
                        </Typography>
                      }
                      sx={{
                        "&.Mui-selected": {
                          color: "#2949c73d",
                          backgroundColor: "#2949c73d",
                        },
                      }}
                    />
                  </Tabs>

                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                    }}
                  >
                    {forecastValue === 0 && (
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <WMSForecastOrderGraphComponent
                          graphData={orderGraphData?.next_7_days_data}
                        />
                      </div>
                    )}
                    {forecastValue === 1 && (
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <WMSForecastProductGraphComponent
                          graphData={orderGraphData?.next_7_days_data}
                        />
                      </div>
                    )}
                  </Box>
                </div>
                <Divider
                style={{
                  background: "rgba(0, 0, 0, 0.2)",
                  height: "2px",
                  marginTop:"2%",
                }}
              >
                <Typography
                  variant="h3"
                  color={colors.white.DEFAULT}
                  style={{
                     backgroundColor: "#34519F83",
                    padding: "6px",
                    borderRadius: "6px",
                  }}
                >
                  Rate Forecasting
                </Typography>
              </Divider>
              <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    flexGrow: 1,
                    marginTop:"1%",
                    // backgroundColor: "red",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="#292B2A"
                    variant="fullWidth"
                    indicatorColor="transparent"
                  >
                    <Tab
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            textDecoration: "none",
                            color: colors.black.DEFAULT,
                          }}
                        >
                         Rates Predictions
                        </Typography>
                      }
                      sx={{
                        "&.Mui-selected": {
                          color: "#2949c73d",
                          backgroundColor: "#2949c73d",
                        },
                      }}
                    />
                    
                  </Tabs>

                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                    }}
                  >
                    {value === 0 && (
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <RatePredictionGraph/>
                      </div>
                    )}
                    
                  </Box>
                </div>
              </>
              
            )}
          </Grid>
        </div>
      </Box>

      <SuccessPopup
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopup
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default HomePage;
