import React, { useState } from "react";
import Chart from "react-apexcharts";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCropAlt, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Grid,useTheme,Typography,
  Select,
  InputLabel,
  FormControl,MenuItem} from "@mui/material";
import {tokens} from '../../../../theme';
library.add(faCropAlt, faSeedling);

const HarvestingGraph = ({ harvestingdata }) => {
  const [selectedCrop, setSelectedCrop] = useState(harvestingdata[0].crop_name);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleCropChange = (event) => {
    setSelectedCrop(event.target.value);
  };

  const getCropData = (cropName) => {
    return harvestingdata?.find((crop) => crop?.crop_name === cropName);
  };

  const chartOptions = {
    chart: {
      id: "harvesting-chart",
      toolbar: {
        show: true,
        autoSelected: "zoom",
        tools: {
          zoom: true,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          delay: 150,
          enabled: true,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 400,
        },
      },
    },
    xaxis: {
      type: "datetime",
      categories: getCropData(selectedCrop)?.daily_data?.map((data) =>
        new Date(data.date)?.getTime()
      ),
      labels: {
        datetimeUTC: false,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#333",
        },
      },
    },
    yaxis: {
      title: {
        text: "Weight(in kg)",
        offsetX: 2,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#333",
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: "dd/MM/yyyy",
      },
      style: {
        fontSize: "12px",
        fontWeight: 400,
      },
    },
    colors: ["#0a6e21"],
    stroke: {
      curve: "smooth",
      width: 1,
      colors: ["#0a6e21"],
    },
    fill: {
      opacity: 0.5,
    },
  };

  const chartSeries = [
    {
      name: `${selectedCrop} Harvesting`,
      data: getCropData(selectedCrop)?.daily_data?.map((data) => ({
        x: new Date(data.date)?.getTime(),
        y: data?.total_weight,
      })),
    },
  ];

  return (
    <Grid sx={{marginRight:'-5%'}}>
      <Grid >
      <Grid
            item
            xs={12}
            sm={4}
            style={{ marginTop: "3%", marginLeft: "68%" }}
          >
            <FormControl fullWidth>
              <InputLabel id="Crop" style={{ textAlign: "center" }}>
                Crop
                <span style={{ marginRight: "30px" }}>
                  <FontAwesomeIcon icon={faSeedling} className="fal" />
                </span>
              </InputLabel>
              <Select
                labelId="Crop"
                id="Crop"
                label="Crop"
                name="Crop"
          
                margin="normal"
                value={selectedCrop}
                onChange={handleCropChange}
                size="small"
                placeholder="Select Crop"
                style={{ width: "80px",height:'20px' }}
              >
                 {harvestingdata?.map((crop) => (
                  <MenuItem key={crop?.crop_name} value={crop?.crop_name}>
                    <Typography variant="h6" fontFamily="Lexend Deca">
                      {crop?.crop_name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        
      </Grid>
       
      <Grid sx={{marginTop:'8%'}}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="area"
          height={200}
          width={280}
        />
     </Grid>
    </Grid>
  );
};

export default HarvestingGraph;
