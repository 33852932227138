import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import NullScreen from "../../PopUpMsg/NullScreen";
import { ScaleLoader } from "react-spinners";
import { CiCalendarDate } from "react-icons/ci";
import SuccessPopUp from "../../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../../PopUpMsg/ErrorPopUp";
import Pagination from "@mui/material/Pagination";
import {
  Grid,
  Button,
  Select,
  Box,
  TextField,
  MenuItem,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { TiTickOutline } from "react-icons/ti";
import { GiFruitBowl } from "react-icons/gi";
import Paper from "@mui/material/Paper";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IoIosPerson } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import { ulid } from "ulid";
import SocketReportPopup from "../../../Attendance/HomePage/SocketReportPopup";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const columns = [
  { id: "sku_name", label: "SKU Name" },
  { id: "sku_code", label: "SKU Code" },
  { id: "uom", label: "UOM" },
  { id: "open_qty", label: "Purchase Quantity" },

  // { id: "total_count", label: "Open Quantity" },
];

const gridContainerStyle = {
  // marginTop:"1%",
  display: "flex",
  height: "68vh",
  width: "100%",
  overflowY: "hidden",
  //  backgroundColor: "red",
};
const containerStyle8 = {
  maxHeight: "70vh",
  height: "100%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  //  backgroundColor: "pink",
};

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};

function TableHeadComponent() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((column) => (
          <StyledTableCell key={column?.id} align="center">
            {column?.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function TableRowsComponent({ data1, datasending }) {
  
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [datatocard, setdatatocard] = useState(null);

  const theme = useTheme();

  const handleRowClick = ({ row, datasending }) => {
    setSelectedRow(row);
    // navigate("/addpo");

    const selectedRowData = datasending?.actualledger?.rows?.find(
      (dataRow) =>
        dataRow?.expected?.mandatory_fields?.ledger_id === row?.ledger_id
    );

    setdatatocard(selectedRowData);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const handleSuccess = (newState) => {
    setApiSuccessOpen(newState);
  };

  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const handleFailure = (newState) => {
    setApiErrorOpen(newState);
  };

  const dialogRef = useRef(null);
  const handleBackdropClick = (event) => {
    // Check if the click event is on the dialog's backdrop and not on the dialog content
    if (dialogRef.current === event.target) {
      setOpenDialog(false);
    }
  };

  return (
    <>
      <TableBody>
        {data1?.map((row, index) => (
          <StyledTableRow
            key={index}
            onClick={() => handleRowClick({ row, datasending })}
            style={{ cursor: "default" }}
          >
            {columns?.map((column) => (
              <StyledTableCell key={column?.id} align="center">
                {row[column.id]}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </>
  );
}
const PurchaseSheet = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const navigate = useNavigate();
  const dateFormat = "DD/MM/YYYY";
  const currentDate = new Date();
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const oneWeekAgo = new Date();
  const [skuNames, setSkuNames] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [skuId, setSkuId] = useState(null);

  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [refreshCount, setRefreshCount] = useState(1);
  // const [purchaseSheetData, setPurchaseSheetData] = useState(dummyData);
  const [purchaseSheetData, setPurchaseSheetData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [warehouseId, setWarehouseId] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [generatedId, setGeneratedId] = useState(null);
  const [applyClick, setApplyClick] = useState(false);
  const [filterValues, setFilterValues] = useState({
    limit: 15,
    offset: offset,
    type: "open",
    warehouse_id: null,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    limit: 15,
    offset: offset,
    name: null,
    warehouse_id: null,
    type: "open",
  });
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, []); // The empty dependency array ensures that this effect runs only once on component mount

  useEffect(() => {
    const updatedFilterValues = {
      limit: 15,
      offset: offset,
      name: skuId,

      warehouse_id: warehouseId,

      type: "open",
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [skuId, warehouseId]);

  // useEffect(() => {
  //   fetchSKUNames();
  // }, []);

  // useEffect(() => {
  //   // Check if warehouseid is not null before calling fetchInventoryData
  //   if (warehouseId !== null) {
  //     fetchInventoryData(filterValues);
  //   }
  // }, [filterValues]); // Add filterValues as a dependency to re-run the effect when it changes

  const handleApplyClick = () => {
    const newFilterValues = {
      limit: 15,
      offset: 1,
      warehouse_id: temporaryFilterValues.warehouse_id,
      name: temporaryFilterValues.name,
      sku_code: temporaryFilterValues.sku_code,
      type: "open",
    };
    setApplyClick(true);
    setOffSet(1);
    setWarehouseId(temporaryFilterValues.warehouse_id);
    setSkuId(temporaryFilterValues.sku_name);
    setFilterValues(newFilterValues);
    fetchInventoryData(newFilterValues);
  };
  const handleGeneratePurchaseSheet = async () => {
    setIsLoading(true);
    const requestId = ulid();
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_DOWNLOAD_PURCHASE_SHEET,
        {
          params: {
            ...filterValues,
            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      } else {
        setErrorMessage(response?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.response?.data?.msg);
      setErrorPopupOpen(true);
    }
  };

  // const fetchSKUNames = async () => {
  //   try {
  //     const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_SKU_DATA, {
  //       params: {
  //         limit: 1000,
  //         offset: 1,
  //       },
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     const data = await response?.data?.Data?.sku_list;
  //     setSkuNames(data);
  //   } catch (error) {
  //     console.log("Error fetching farm data:", error);
  //   }
  // };

  const fetchInventoryData = async (newParams) => {
    console.log("params in inventorydata function in open filter", newParams);
    // Check if warehouseid is not null before making the API call
    if (newParams?.type === "open") {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          API_ENDPOINTS.GET_WMS_INVENTORY_DATA,
          {
            params: newParams,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 305) {
          const data = await response?.data?.data;
          console.log("data", data);
          setPurchaseSheetData(data?.inv_list);
          setTotalPages(data?.total_pages);
          setIsLoading(false);
        } else {
          setErrorMessage(response?.data?.msg);
          console.log("Error fetching details");
        }
      } catch (error) {
        console.log("Error fetching data:", error);
        setIsLoading(false);
        setErrorMessage("Please Try Again");
        setErrorPopupOpen(true);
      }
    } else {
      console.log("type is null, API call skipped");
    }
  };
  const onPageChange = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchInventoryData(updatedValues);
  };

  const handleRefreshClick = () => {
    // const initialValues = {
    //   limit: 15,
    //   offset: offset,
    // };
    setSkuId(null);
    setWarehouseId(null);
    setPurchaseSheetData([]);
    // setRefreshCount((prevVal) => prevVal + 1);
    // setFilterValues(initialValues);
    // fetchInventoryData(initialValues);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "10vh" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    label="Select Warehouse"
                    value={warehouseId}
                    onChange={(e) => {
                      setWarehouseId(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Warehouse" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <CiCalendarDate />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {Array.isArray(warehouseData) &&
                      warehouseData?.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!warehouseId}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.8} md={0.8}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={5.4} md={5.4}></Grid>
              <Grid item xs={12} sm={2} md={2} textAlign="right">
                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleGeneratePurchaseSheet}
                  disabled={!purchaseSheetData?.length}
                >
                  <Typography variant="h6" fontFamily="Lexend Deca">
                    Download Report
                  </Typography>
                </Button>
              </Grid>
              {openDialog && (
                <SocketReportPopup
                  setOpenDialog={setOpenDialog}
                  requestId={generatedId}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={containerStyle8}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : (
            <>
              {warehouseId === null ? (
                <NullScreen
                  containerheight="70vh"
                  containerWidth="100%"
                  screenType="Info"
                  message="Select Warehouse"
                />
              ) : (
                <>
                  {purchaseSheetData?.length === 0 || applyClick === false ? (
                    <NullScreen containerheight="70vh" containerWidth="100%" />
                  ) : (
                    <>
                      <div style={gridContainerStyle}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 900 }}
                            aria-label="customized table"
                          >
                            <TableHeadComponent />
                            <TableRowsComponent data1={purchaseSheetData} />
                          </Table>
                        </TableContainer>
                      </div>
                      <Grid
                        container
                        justifyContent="flex-end"
                        style={{ marginTop: "5px" }}
                      >
                        <Pagination
                          count={totalPages}
                          page={offset}
                          size="small"
                          onChange={onPageChange}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Box>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default PurchaseSheet;
