import React, { useState, useEffect } from "react";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import moment from "moment";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineCloudUpload } from "react-icons/ai";
import SaveAlert from "../../../../../SaveAlert";
import ApiErrorAlert from "../../../../../ApiErrorAlert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Stack,
  InputAdornment,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { Tooltip } from "@mui/material";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import {
  BASE_URL,
  API_ENDPOINTS,
} from "../../../../../ApiEndpoint/ApiEndpoint";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const ActionTransplant = ({
  farmName,
  noOfPlant,
  date_of_sow,
  next_phase,
  toZoneName,
  rows,
  handlePageRefresh
}) => {
  // console.log("CROP TYPEEEEEEEEE", rows?.original?.crop_type);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const [toZoneOptions, setToZoneOptions] = useState([]);
  const [formErrors, setFormErrors] = useState(false);

  const [data, setData] = useState({ farms: [] });
  const [phaseData, setPhaseData] = useState("");

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isPhaseCheckboxChecked, setIsPhaseCheckboxChecked] = useState(false);
  const [phaseList, setPhaseList] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  console.log("ROWSSSSSSSZZZZZZZZZZZZZZZZZZZ", rows?.original);

  const [formData, setFormData] = useState({
    farm_name: rows?.original?.farm_name,
    farm_zone: rows?.original?.zone_name,
    batch: rows?.original?.batch,
    crop_name: rows?.original?.crop_name,
    crop_variety_name: rows?.original?.crop_variety_name,
    crop_type: rows?.original?.crop_type,
    id: rows?.original?.id,
    parent_id: rows?.original?.parent_id,
    expected_doh: rows?.original?.expected_doh,
    starting_location: "",
    ending_location: "",
    // old_no_of_plant: noOfPlant,
    tofarmName: "",
    tozoneName: "",
    no_of_plants: rows?.original?.no_of_plant,
    date_of_sow: rows?.original?.date_of_sow,
    date_of_transplant: null,
    selected_file: null,
    current_phase: rows?.original?.current_phase,
    actual_phase: rows?.original?.actual_phase,
    next_phase: rows?.original?.next_phase,
    // next_phase:"NA",
    farm_id: rows?.original?.farm_id_fk,
    farm_zone_id: rows?.original?.farm_zone_id_fk,
    crop_id: rows?.original?.crop_id_fk,
    crop_variety_id: rows?.original?.crop_variety_id_fk,
    diff_days_phase: rows?.original?.diff_days_phase,
    date_of_phase: null,
    phase_change: rows?.original?.phase_change,
    phase_change_to: null,
  });

  useEffect(() => {
    setFormData({
      farm_name: rows?.original?.farm_name,
      farm_zone: rows?.original?.zone_name,
      batch: rows?.original?.batch,
      crop_name: rows?.original?.crop_name,
      crop_variety_name: rows?.original?.crop_variety_name,
      crop_type: rows?.original?.crop_type,
      id: rows?.original?.id,
      parent_id: rows?.original?.parent_id,
      expected_doh: rows?.original?.expected_doh,
      starting_location: "",
      ending_location: "",
      // old_no_of_plant: noOfPlant,
      tofarmName: "",
      tozoneName: "",
      no_of_plants: rows?.original?.no_of_plant,
      date_of_sow: rows?.original?.date_of_sow,
      date_of_transplant: null,
      selected_file: null,
      current_phase: rows?.original?.current_phase,
      actual_phase: rows?.original?.actual_phase,
      next_phase: rows?.original?.next_phase,
      // next_phase:"NA",
      farm_id: rows?.original?.farm_id_fk,
      farm_zone_id: rows?.original?.farm_zone_id_fk,
      crop_id: rows?.original?.crop_id_fk,
      crop_variety_id: rows?.original?.crop_variety_id_fk,
      diff_days_phase: rows?.original?.diff_days_phase,
      date_of_phase: null,
      phase_change: rows?.original?.phase_change,
      phase_change_to: null,
    });
  }, [rows]);

  console.log("FORMDATAAAAAAAAAAAA", formData);

  useEffect(() => {
    // Enable submit button only when at least one checkbox is checked
    setSubmitDisabled(!isCheckboxChecked && !isPhaseCheckboxChecked);
  }, [isPhaseCheckboxChecked, isCheckboxChecked]);

  // useEffect(() => {
  //   // Call the fetchPhase function here
  //   fetchPhase("crop_name", "crop_variety_name", "current_phase");
  // }, []);
  const date_of_sowing = moment(date_of_sow).format("YYYY-MM-DD");

  const MAX_PLANTS = noOfPlant;
  function validateNoOfPlants(value) {
    if (value > MAX_PLANTS) {
      return `Number of plants for transplant cannot exceed ${MAX_PLANTS}.`;
    }
    return null;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const hasErrors = () => {
    return Object.values(formErrors).some((error) => error !== null);
  };

  useEffect(() => {
    axiosInstance
      .get(API_ENDPOINTS.GET_CROPMANAGEMENT_CONFIG_FARMNAME)
      .then((response) => {
        const data = response?.data;
        setData({ farms: data?.rows });
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
    // fetchPhase();
  }, []);

  console.log("DATAAAAAAAAAAAAAA", data);

  const handleToFarmChange = (event) => {
    const selectedFarm = event.target.value;
    // console.log("selectedFarmmmmm", selectedFarm);
    const selectedFarmData = data?.farms?.find(
      (farm) => farm?.farm_name === selectedFarm
    );
    console.log("selectedFarmData", selectedFarmData);
    if (selectedFarmData) {
      const zones = selectedFarmData.zones.map((zone) => zone.zone_name);
      // const selectedZoneData = selectedFarmData?.zones?.find(
      //   (zone) => zone?.zone_name === selectedFarm
      // );
      // console.log("selectedZoneObjecttttttt", zones);
      setToZoneOptions(zones);
      setFormData((prevState) => ({
        ...prevState,
        tofarmName: selectedFarm,
        farm_id: selectedFarmData.farm_id_fk,
        tozoneName: "", // Clear the "To Zone" when "To Farm" changes
      }));
    }
  };

  const handleToZoneChange = (event) => {
    const selectedZone = event.target.value;
    const selectedFarm = formData?.tofarmName;
    console.log("selectedFarm", selectedFarm);
    const selectedFarmData = data?.farms?.find(
      (farm) => farm?.farm_name === selectedFarm
    );
    const zoneObject = selectedFarmData.zones.find(
      (zone) => zone?.zone_name === selectedZone
    );
    console.log("zoneObject", zoneObject);
    // const selectedZoneData = selectedFarmData?.zones?.find(
    //   (zone) => zone?.zone_name === selectedZone
    // );
    // console.log("selectedZoneObjecttttttt", selectedZoneData);
    setFormData((prevState) => ({
      ...prevState,
      tozoneName: selectedZone,
      farm_zone_id: zoneObject.zone_id,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const noOfPlantsError = validateNoOfPlants(formData?.no_of_plants);
    if (noOfPlantsError) {
      setFormErrors({ ...formErrors, no_of_plants: noOfPlantsError });
      return;
    }

    let dataToSubmit = {};

    if (isPhaseCheckboxChecked && isCheckboxChecked) {
      if (next_phase === "NA") {
        alert(
          "Sorry, you cannot add another phase to this crop as it has already reached its final stage of growth. The current phase of the crop is {'formData?.actual_phase'} and there are no more phases left to add. Please check your input and try again."
        );
        return;
      }
      dataToSubmit = formData;

      console.log("IN STEP 1", dataToSubmit);
    } else if (isCheckboxChecked) {
      dataToSubmit = {
        farm_name: formData?.farm_name,
        farm_zone: formData?.farm_zone,
        starting_location: formData?.starting_location,
        ending_location: formData?.ending_location,
        batch: formData?.batch,
        crop_name: formData?.crop_name,
        crop_variety_name: formData?.crop_variety_name,
        id: formData?.id,
        parent_id: formData?.parent_id,
        expected_doh: formData?.expected_doh,
        tofarmName: formData?.tofarmName,
        tozoneName: formData?.tozoneName,
        no_of_plants: formData?.no_of_plants,
        date_of_sow: formData?.date_of_sow,
        date_of_transplant: formData?.date_of_transplant,
        selected_file: formData?.selected_file,
        current_phase: formData?.current_phase,
        actual_phase: rows?.original?.actual_phase,
        next_phase: rows?.original?.next_phase,
        diff_days_phase: rows?.original?.diff_days_phase,
        date_of_phase: null,
        phase_change_to: null,
        crop_type: rows?.original?.crop_type,
        crop_id: rows?.original?.crop_id_fk,
        crop_variety_id: rows?.original?.crop_variety_id_fk,
        farm_id: formData?.farm_id,
        farm_zone_id: formData?.farm_zone_id,
      };
      console.log("IN STEP 2", dataToSubmit);
    } else if (isPhaseCheckboxChecked) {
      if (next_phase === "NA") {
        alert(
          "Sorry, you cannot add another phase to this crop as it has already reached its final stage of growth. The current phase of the crop is {'formData?.actual_phase'} and there are no more phases left to add. Please check your input and try again."
        );
        return;
      }
      console.log("IN STEP 3", dataToSubmit);
      dataToSubmit = {
        farm_name: formData?.farm_name,
        farm_zone: formData?.farm_zone,
        batch: formData?.batch,
        crop_name: formData?.crop_name,
        crop_variety_name: formData?.crop_variety_name,
        crop_type: rows?.original?.crop_type,
        id: formData?.id,
        expected_doh: formData?.expected_doh,
        tofarmName: formData?.tofarmName,
        tozoneName: formData?.tozoneName,
        no_of_plants: formData?.no_of_plants,
        date_of_sow: formData?.date_of_sow,
        selected_file: formData?.selected_file,
        current_phase: formData?.current_phase,
        actual_phase: formData?.actual_phase,
        next_phase: next_phase,
        starting_location: formData?.starting_location,
        ending_location: formData?.ending_location,
        // next_phase: "NA",
        crop_id: rows?.original?.crop_id_fk,
        crop_variety_id: rows?.original?.crop_variety_id_fk,
        farm_id: formData?.farm_id,
        farm_zone_id: formData?.farm_zone_id,
        diff_days_phase: rows?.original?.diff_days_phase,
        date_of_phase: formData?.date_of_phase,
        date_of_transplant: null,
        phase_change_to: next_phase,
      };
      console.log("IN STEP 4", dataToSubmit);
    }

    try {
      const response = await axiosInstance.put(
        API_ENDPOINTS.PUT_CROPMANAGEMENT_TRANSPLANT,
        dataToSubmit,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("responseeeeeeeeee", response);
      if (response.status >= 200 && response.status < 305) {
        console.log("Inventory transplant record created successfully");
        setOpen(false);
        if (typeof handlePageRefresh === "function") {
          handlePageRefresh();
        }
      } else {
        console.log("Failed to create inventory transplant record");
      }
    } catch (error) {
      console.log(
        "An error occurred while creating the inventory transplant record",
        error
      );
    }
    setOpen(false);
  };

  const handleFormChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  //   const handlePhaseChange = async(e)=>{
  //     const selectedPhase=e.target.value;
  //     setSelectedPhase(selectedPhase);
  // }
  return (
    <div>
      {/* <button onClick={handleClickOpen}>Transplant</button> */}
      <button
        onClick={() => {
          handleClickOpen();
          // fetchPhase();
        }}
        style={{
          borderRadius: "12px",
          backgroundColor: "white",
          border: "0.5px solid #7d7d7d",
          width: "80px",
          height: "21px",
          display: "flex",

          justifyContent: "center",
          alignItems: "center",
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <TransferWithinAStationIcon sx={{ mr: 0.5 }} />
        Activity
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="40%"
        maxHeight="100px"
      >
        {/* <DialogTitle>Transplant Inventory</DialogTitle> */}
        <div>
          <DialogTitle>
            <Typography variant="h5" color={colors.green.DEFAULT}>
              Transplant
            </Typography>
          </DialogTitle>

          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={9} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckboxChecked}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          setIsCheckboxChecked(isChecked);

                          if (!isChecked) {
                            // Reset the form data when checkbox is unchecked
                            setFormData({
                              ...formData,
                              tofarmName: farmName,
                              tozoneName: toZoneName,
                              no_of_plants: noOfPlant,
                              date_of_transplant: null,
                            });
                          }
                        }}
                        // style={{ marginLeft: "10px" }}
                      />
                    }
                    label="Transplant Inventory"
                  />
                </Grid>

                <Grid item xs={9} sm={2}>
                  <TextField
                    label="From Farm"
                    name="farm_name"
                    required
                    disabled={!isCheckboxChecked}
                    value={formData?.farm_name}
                    readOnly
                    size="small"
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                    }}
                  />
                </Grid>

                <Grid item xs={9} sm={2}>
                  <TextField
                    label="From Zone"
                    name="farm_zone"
                    required
                    size="small"
                    disabled={!isCheckboxChecked}
                    value={formData?.farm_zone}
                    readOnly
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                    }}
                  />
                </Grid>

                <Grid item xs={9} sm={2} style={{ marginTop: "9px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="To Farm">To Farm*</InputLabel>
                    <Select
                      labelId="To Farm"
                      id="To Farm"
                      value={formData?.tofarmName}
                      label="To Farm"
                      disabled={!isCheckboxChecked}
                      required
                      size="small"
                      onChange={handleToFarmChange}
                    >
                      {data?.farms.map((farm) => (
                        <MenuItem key={farm?.farm_name} value={farm?.farm_name}>
                          {farm?.farm_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* {formData?.tofarmName && ( */}
                <Grid item xs={9} sm={2} style={{ marginTop: "9px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="To Zone">To Zone*</InputLabel>
                    <Select
                      labelId="To Zone"
                      id="To Zone"
                      value={formData?.tozoneName || ""}
                      required
                      size="small"
                      disabled={!isCheckboxChecked}
                      label="To Zone"
                      onChange={handleToZoneChange}
                      // defaultValue={zoneName}
                    >
                      {toZoneOptions?.map((zone) => (
                        <MenuItem key={zone} value={zone}>
                          {zone}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={9} sm={2}>
                  <TextField
                    label="Batch Id"
                    name="batch"
                    value={formData?.batch}
                    required
                    size="small"
                    readOnly
                    disabled={!isCheckboxChecked}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      max: 256,
                    }}
                  />
                </Grid>
                <Grid item xs={9} sm={2}>
                  <TextField
                    label="No. of Plants"
                    name="no_of_plants"
                    type="number"
                    disabled={!isCheckboxChecked}
                    value={formData?.no_of_plants}
                    onChange={handleFormChange}
                    fullWidth
                    margin="normal"
                    required
                    size="small"
                    error={Boolean(formErrors.no_of_plants)}
                    helperText={formErrors.no_of_plants}
                    inputProps={{
                      style: { width: "120%" },
                      max: MAX_PLANTS,
                      min: 1,
                    }}
                  />
                </Grid>
                <Grid item xs={9} sm={2}>
                  <TextField
                    label="DOT"
                    name="date_of_transplant"
                    type="date"
                    disabled={!isCheckboxChecked}
                    value={formData?.date_of_transplant}
                    onChange={handleFormChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    required
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      style: { width: "100%" },
                    }}
                  />
                </Grid>
                <Grid item xs={9} sm={2}>
                  <TextField
                    label="Start Location"
                    name="starting_location"
                    disabled={!isCheckboxChecked}
                    size="small"
                    value={formData?.starting_location}
                    onChange={handleFormChange}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      maxLength: 25,
                    }}
                  />
                </Grid>
                <Grid item xs={9} sm={2}>
                  <TextField
                    label="End Location"
                    name="ending_location"
                    disabled={!isCheckboxChecked}
                    size="small"
                    value={formData?.ending_location}
                    onChange={handleFormChange}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      maxLength: 25,
                    }}
                  />
                </Grid>

                <Grid item xs={9} sm={4}>
                  <TextField
                    label="Note"
                    name="note"
                    size="small"
                    value={formData?.note}
                    disabled={!isCheckboxChecked}
                    onChange={handleFormChange}
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: { width: "100%" },
                      max: 256,
                    }}
                  />
                </Grid>
                {/* <DialogTitle>Stage</DialogTitle> */}

                <Grid item xs={9} sm={2}></Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPhaseCheckboxChecked}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        setIsPhaseCheckboxChecked(isChecked);
                        console.log("formData------------", formData);
                        if (!isChecked) {
                          // Reset the form data when checkbox is unchecked
                          setFormData({
                            ...formData,
                            date_of_phase: null,
                          });
                        }
                      }}
                      style={{ marginLeft: "10px" }}
                      disabled={formData?.next_phase === "NA"}
                    />
                  }
                  label="Stage"
                />

                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  marginLeft={"4px"}
                >
                  <Grid item xs={9} sm={2}>
                    <TextField
                      label="Actual Phase"
                      name="actual_phase"
                      required
                      size="small"
                      disabled={!isPhaseCheckboxChecked}
                      value={formData?.actual_phase || "NA"}
                      readOnly
                      fullWidth
                      margin="normal"
                      inputProps={{
                        style: { width: "100%" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={2}>
                    <TextField
                      label="Phase Change To"
                      name="phase_change_to"
                      // disabled={!isPhaseCheckboxChecked}
                      disabled={!isPhaseCheckboxChecked}
                      value={formData?.next_phase || "NA"}
                      readOnly
                      size="small"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{
                        style: { width: "100%" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={2}>
                    <TextField
                      label="DOP"
                      name="date_of_phase"
                      type="date"
                      disabled={!isPhaseCheckboxChecked}
                      value={formData?.date_of_phase}
                      onChange={handleFormChange}
                      fullWidth
                      size="small"
                      margin="normal"
                      required
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        // min: date_of_sowing,
                        // max: new Date().toISOString().split("T")[0],
                        style: { width: "100%" },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      style={{
                        height: "30px",
                        marginTop: "2%",
                        marginLeft: "86%",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ backgroundColor: colors.green.DEFAULT }}
                      >
                        <Typography variant="h7" color={colors.white.DEFAULT}>
                          Submit
                        </Typography>
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        style={{ marginLeft: "15px" }}
                      >
                        <Typography variant="h7" color={colors.green.DEFAULT}>
                          Cancel
                        </Typography>
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>

                {/* <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="flex-end"
                  style={{
                    height: "30px",
                    marginTop: "2%",
                    marginLeft: "86.5%",
                  }}
                >
                  <Button
                    type="submit"
                    sx={{
                      marginRight: "2%",
                      backgroundColor: colors.green.DEFAULT,
                    }}
                    variant="contained"
                    disabled={submitDisabled}
                  >
                    <Typography variant="h7" color={colors.white.DEFAULT}>
                      Submit
                    </Typography>
                  </Button>
                  <Button onClick={handleClose} variant="outlined">
                    <Typography variant="h7" color={colors.green.DEFAULT}>
                      Cancel
                    </Typography>
                  </Button>
                </Stack> */}
              </Grid>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};
export default ActionTransplant;
