import React, { useState } from "react";
import { tokens } from "../WMStheme";
import { useTheme, Grid, Typography, Button } from "@mui/material";
import STOList from "./STOList";
import { IoMdClose } from "react-icons/io";
const STOMainPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Transfer
        </Typography>
      </Grid>

      <Grid>
        <STOList />
      </Grid>
    </>
  );
};

export default STOMainPage;
