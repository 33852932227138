import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Delete } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "axios";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import dayjs from "dayjs";
import SocketReportPopup from "../../../Attendance/HomePage/SocketReportPopup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ulid } from "ulid";
import { GiFruitBowl } from "react-icons/gi";
import NullScreen from "../../PopUpMsg/NullScreen";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useDispatch } from "react-redux";
import { ScaleLoader } from "react-spinners";
import Pagination from "@mui/material/Pagination";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { Grid } from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import moment from "moment";
import { Label } from "semantic-ui-react";
import { FaSearch } from "react-icons/fa";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Box,
  Autocomplete,
  MenuItem,
  createTheme,
  ThemeProvider,
  Input,
  FormHelperText,
  useTheme,
  InputLabel,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { DatePicker, Space } from "antd";
import { IoIosPerson } from "react-icons/io";
import { TiTickOutline } from "react-icons/ti";
import { setSkuAction } from "../../actions/skuAction";
import { tokens } from "../../WMStheme";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

const gridContainerStyle = {
  height: "70vh",
  width: "100%",
};
const containerStyle8 = {
  maxHeight: "70vh",
  height: "100%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
};
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70vh",
  width: "100%",
  overflowY: "hidden",
};
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const SKUView = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [familyData, setFamilyData] = useState([]);
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const handleButtonClick = () => {
    navigate("/configuration");
  };
  const dateFormat = "DD/MM/YYYY";
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [skuCode, setSkuCode] = useState("");
  const [skuName, setSkuName] = useState("");
  const [status, setStatus] = useState("");
  const [skuId, setSKUId] = useState("");
  const [skuList, setSKUList] = useState([]);
  const [generatedId, setGeneratedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [familyId, setFamilyId] = useState("");
  const [filterValues, setFilterValues] = useState({
    limit: 50,
    offset: offset,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    sku_name: null,
    limit: 50,
    offset: offset,
    family_id: null,
    status: status,
  });
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(1);

  useEffect(() => {
    const updatedFilterValues = {
      sku_name: skuId,
      limit: 50,
      offset: offset,
      family_id: familyId,
      status: status,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [skuId, familyId, status, offset]);

  useEffect(() => {
    fetchFamilyData();
    fetchSKUDataForDropdown();
  }, []);
  const fetchFamilyData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_FAMILY_DATA}`,
        {
          params: {
            limit: 1000,
            offset: 1,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 305) {
        const data = response.data?.data?.family_list;
        setFamilyData(data);
        console.log("family Data, ", data); // Assuming the response is an array of family options
      } else {
        console.log("Error fetching family data");
      }
    } catch (error) {
      console.error("Error fetching family data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSKUData(filterValues);
  }, []);

  // Fetch sku data on component mount
  const fetchSKUData = async (newParams) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_SKU_DATA}`,
        {
          params: newParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.Data;
        setRows(data?.sku_list);
        setTotalPages(data?.total_pages);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error fetching data:", error);
    }
  };
  const handleApplyClick = () => {
    const newFilterValues = {
      sku_name: temporaryFilterValues?.sku_name,
      family_id: temporaryFilterValues?.family_id,
      status: temporaryFilterValues?.status,
      limit: 50,
      offset: 1,
    };

    setSKUId(temporaryFilterValues?.sku_name);
    setFamilyId(temporaryFilterValues?.family_id);

    setStatus(temporaryFilterValues?.status);
    setFilterValues(newFilterValues);
    fetchSKUData(newFilterValues);
  };
  const handleGenerateReportClick = async () => {
    setLoading(true);
    const requestId = ulid();
    console.log("FilterValues:", filterValues);

    // Clone the original filterValues object


// Clone the original filterValues object
const updatedFilterValues = { ...filterValues };

// Iterate over each key in the filterValues object
for (const key in updatedFilterValues) {
    // Check if the value corresponding to the key is a string and is an empty string
    if (typeof updatedFilterValues[key] === 'string' && updatedFilterValues[key].trim() === '') {
        // Set the value to null
        updatedFilterValues[key] = null;
    }
}

// Log the updated filterValues
console.log("updated filtervalues------->", updatedFilterValues);





    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_DOWNLOAD_MASTER,
        {
          params: {
            ...updatedFilterValues,
            type:'sku',

            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      }
    } catch (error) {
      setLoading(false);
      // setErrorPopupOpen(true);
    }
  };
  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };
  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleRefreshClick = () => {
    const initialValues = {
      limit: 50,
      offset: 1,
    };
    setOffSet(1);

    setSKUId(null);
    setFamilyId(null);

    setStatus(null);

    setFilterValues(initialValues);
    fetchSKUData(initialValues);
  };
  const fetchSKUDataForDropdown = async (newParams) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_SKU_DATA}`,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.Data;
        console.log("data", data);

        setSKUList(data?.sku_list);

        console.log("SKU Data", data?.sku_list);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      // Set loading to false after the request is completed
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchSKUData(updatedValues);
  };
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#fffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const StatusTag = ({ status }) => {
    const isActive = "true"; // Convert string to boolean

    let tagColor;
    let statusLabel;

    switch (status) {
      case true:
        tagColor = "#0B823A";
        statusLabel = "Active";
        break;
      case false:
        tagColor = "#DE3721";
        statusLabel = "In-Active";
        break;
      default:
        tagColor = "#DE3721";
        statusLabel = "In-Active";
        break;
    }

    return (
      <Label
        style={{
          backgroundColor: tagColor,
          color: "white",
          fontWeight: typography.h1,
          fontFamily: "Lexend Deca",
          cursor: "pointer",
          width: "auto",
        }}
        tag
      >
        {statusLabel}
      </Label>
    );
  };
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const columns = useMemo(() => [
    {
      header: "Edit",
      size: 2,
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => ({
        onClick: () => {
          console.log("cell original", cell?.row?.original);
          console.log(cell?.getValue(), cell?.id);
          const rowData = cell?.row?.original;
          console.log("Dispatching action with rowData:", rowData);
          // dispatch(setSelectedCustomer(rowData));
          dispatch(setSkuAction("edit", rowData));
          // Navigate to the "mainpage"
          navigate("/addSKU");
        },
        title: "Click to Edit ",
      }),
      Cell: ({ cell }) => {
        // const navigate = useNavigate();

        const handleClick = () => {
          console.log("clicked here or not ");
          //  console.log("Rowdata Here ", rowData);

          // Navigate to another page (replace '/destination' with your desired destination)

          // If you still want to render CustomerMasterConfig, you can do it conditionally
        };
        return (
          <div className="tooltip-cell" title="Click to Edit the Details ">
            <IconButton
              className="icon-wrapper"
              onMouseEnter={() => console.log("Hovering...")}
            >
              <MdModeEdit style={{ width: "10px", height: "10px" }} />
            </IconButton>

            <span className="farm-name">{cell?.getValue()}</span>
          </div>
        );
      },
    },

    {
      header: "Name",
      accessorKey: "sku_name",
      size: 10,
    },
    {
      header: "Code",
      accessorKey: "sku_code",
      size: 10,
    },
    {
      header: "UOM",
      accessorKey: "uom",
      size: 10,
    },

    {
      header: "MRP",
      accessorKey: "mrp",
      size: 10,
    },
    {
      header: "Family",
      accessorKey: "family_name",
      size: 10,
    },
    {
      header: "Status",
      accessorKey: "status",
      size: 10,
      Cell: ({ row }) => <StatusTag status={row?.original?.status} />,
    },

    {
      header: "Last Purchase Price",
      accessorKey: "last_purchase_price",
      size: 10,
    },
    {
      header: "Created At",
      accessor: "created_at",
      size: 100,
      Cell: ({ row }) => {
        const created_at = row?.original?.created_at;

        return <span>{created_at ? formatDate(created_at) : "-"}</span>;
      },
    },
  ]);

  const handleSearchClick = () => {
    setOffSet(1);
    const updatedValues = { ...filterValues, offset: 1, sku_search: search };
    setFilterValues(updatedValues);
    fetchSKUData(updatedValues);
  };

  const handleClearClick = () => {
    setOffSet(1);
    setSearch("");
    const updatedValues = { ...filterValues, offset: 1, sku_search: null };
    setFilterValues(updatedValues);
    fetchSKUData(updatedValues);
    setCount((prevCount) => prevCount + 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "100%",
      }}
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          SKU Details
        </Typography>
        <Button
          variant="outlined"
          startIcon={
            <ArrowBackIcon
              style={{ color: colors.green.DEFAULT, fontSize: 26 }}
            />
          }
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Back
          </Typography>
        </Button>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "10vh" }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2} md={2}>
              <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={familyData || []}
                getOptionLabel={(option) => capitalizeFirstLetter(option?.family_name)}
                value={familyData?.find((group) => group?.id === familyId) || null}
                onChange={(event, newValue) => {
                  setFamilyId(newValue ? newValue?.id : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Family"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start" size="small" disabled>
                            <GiFruitBowl />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
                
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  fullWidth
                  required
                  size="small"
                  displayEmpty
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  inputProps={{ "aria-label": "Type" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <ToggleOffIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">In-Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={0.8} md={0.8}>
              <Button
                variant="contained"
                startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                sx={{
                  backgroundColor: "#34519fe6",
                  color: "#ffffff",
                }}
                size="small"
                onClick={handleApplyClick}
                disabled={!startDate || !endDate}
              >
                Apply
              </Button>
            </Grid>

            <Grid item xs={12} sm={0.8} md={0.8}>
              <Button
                variant="contained"
                startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                size="small"
                sx={{
                  backgroundColor: "#34519fe6",
                  color: "#ffffff",
                }}
                onClick={handleRefreshClick}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12} sm={5.3} md={5.3}></Grid>
            <Grid item xs={12} sm={0.8} md={0.8}>
            <Button
              variant="contained"
              startIcon={
                <FileDownloadIcon
                  style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                />
              }
              sx={{
                borderColor: colors.green.DEFAULT,
                backgroundColor: colors.green.DEFAULT,
              }}
              onClick={handleGenerateReportClick}
              title="Download SKU"
            >
              <Typography variant="h7" color={colors.white.DEFAULT}>
                Download
              </Typography>
            </Button>
          </Grid>
          
          </Grid>
        
        </Grid>
        {openDialog && (
          <SocketReportPopup
            setOpenDialog={setOpenDialog}
            requestId={generatedId}
          />
        )}
      </Grid>

      <Grid container spacing={1} style={containerStyle8}>
        {loading ? (
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={loading} />
          </div>
        ) : (
         
          <div style={gridContainerStyle}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={rows}
                key={count}
                enableGrouping
                enableStickyHeader
                enablePagination={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                initialState={{
                  density: "compact",
                  pagination: { pageIndex: 0, pageSize: 10 },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    color: "#fff",
                    backgroundColor: "#34519fe6",
                  },
                }}
                muiToolbarAlertBannerChipProps={{ color: "primary" }}
                muiTableBodyProps={{
                  sx: {
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#ffff",
                    },
                  },
                }}
                muiTableContainerProps={{ sx: { maxHeight: "53vh" } }}
                enableGlobalFilter={false}
                renderTopToolbarCustomActions={() => (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <InputLabel htmlFor="SKU Name">
                          Search by SKU Name
                        </InputLabel>
                        <Input
                          id="SKU Name"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target?.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="SKU Name"
                                onClick={handleSearchClick}
                                disabled={search?.length < 3}
                              >
                                {<FaSearch />}
                              </IconButton>
                              <IconButton
                                aria-label="SKU Name"
                                onClick={handleClearClick}
                              >
                                {<CloseSharpIcon />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText id="component-helper-text">
                          At least 3 characters
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              />
            </ThemeProvider>
          </div> 
          
        )}
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ marginTop: "0.1%" }}>
        <Pagination
          count={totalPages}
          page={offset}
          onChange={handleChangePage}
          // style={{ color: "#5e89c7" }}
        />
      </Grid>
     
    </Box>
  );
};
export default SKUView;
