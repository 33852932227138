import axios from "axios";
import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { ScaleLoader } from "react-spinners";
import { Grid } from "@mui/material";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import SaveAlert from "../../../../SaveAlert";
import ApiErrorAlert from "../../../../ApiErrorAlert";
import Pagination from "@mui/material/Pagination";
import GradesImg from "../../../../Assets/ConfigurationImg/grades.png";
import Search from "../../../../Assets/ConfigurationImg/search.png";

import { BASE_URL, API_ENDPOINTS } from "../../../../ApiEndpoint/ApiEndpoint";
import {
  IconButton,
  Box,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Typography,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import "./Harvesting.css";
import { useState, useEffect } from "react";

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

function GridHarvesting({ farmname, farmId }) {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  console.log("farm_id in Harvesting page", farmId);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(1);
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    fetchData();
    const loaderTimer = setTimeout(() => {
      setIsLoading(false);
    }, 30000); // Display loader for 3 seconds

    fetchData();
    return () => clearTimeout(loaderTimer);
  }, [farmname, limit, offset]);

  const fetchData = async (newPage) => {
    console.log("newpage", newPage);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_CROPMANAGEMENT_HARVESTING(farmId, limit, offset)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        let data = await response?.data;
        const formattedData = data?.rows?.map((item) => {
          const formatteddos = moment(item?.date_of_sow).format("DD-MM-YYYY");
          let formatteddoh = moment(item?.date_of_harvest).format("DD-MM-YYYY");
          const formattededoh = moment(item?.exp_date_of_harvest).format(
            "DD-MM-YYYY"
          );
          if (formatteddoh === "Invalid date") {
            formatteddoh = "NT";
          }
          //console.log("date:",date_of_sow);
          return {
            ...item,
            formatteddos,
            formatteddoh,
            formattededoh,
          };
        });
        data = formattedData;
        console.log("data", data);
        setRows(data);
        setIsLoading(false);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      setApiErrorOpen(true);
      console.log("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
    fetchData();
  };

  const [selectedRowGrades, setSelectedRowGrades] = useState([]);
  const [isDialogOpenGrades, setIsDialogOpenGrades] = useState(false);

  const columns = useMemo(
    () => [
      {
        header: "Grades",
        size: 4,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: () => {
            console.log(cell.getValue(), cell.id);
          },
          title: "Click to view Grades",
        }),
        Cell: ({ cell }) => {
          const handleClickGrade = () => {
            // console.log("cell.row.original", cell.row.original);
            setSelectedRowGrades(cell?.row?.original?.grades);
            setIsDialogOpenGrades(true);
          };
          return (
            <div className="tooltip-cell" title="Click to view Grades">
              <IconButton className="icon-wrapper" onClick={handleClickGrade}>
                <img
                  src={GradesImg}
                  alt="Grades"
                  className="stages-image"
                  style={{ height: "30px", width: "30px" }}
                />
              </IconButton>
              <span className="farm-name">{cell.getValue()}</span>
            </div>
          );
        },
      },
      { header: "Crop Name", accessorKey: "crop_name", size: 10 },
      { header: "Variety", accessorKey: "crop_variety_name", size: 10 },
      { header: "Zone", accessorKey: "farm_zone", size: 10 },
      { header: "Batch", accessorKey: "batch", size: 10 },
      { header: "No. Of Plants", accessorKey: "no_of_plants", size: 10 },
      // { header: "Grades", accessorKey: "harvest_grade", size: 10 },
      { header: "DOS", accessorKey: "formatteddos", size: 10 },
      { header: "Exp DOH", accessorKey: "formattededoh", size: 10 },

      { header: "Actual DOH", accessorKey: "formatteddoh", size: 10 },
      { header: "Weight(Kg)", accessorKey: "harvest_weight", size: 10 },
    ],
    []
  );
  const handleOpen = (row) => {
    setOpen(true);
    setSelectedRow(row);
  };
  const handleRollBack = (row) => {
    handleOpen(row);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = async (rowData) => {
    setOpen(false);

    console.log("enter into rowwwwwwwwwwww", rowData);
    if (selectedRow) {
      try {
        const response = await axiosInstance.put(
          `${API_ENDPOINTS.PUT_CROPMANAGEMENT_HARVEST_ROLLBACK}`,
          rowData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status >= 200 && response.status < 305) {
          console.log("API call successful", response);
          // updateRows(rowData);
          fetchData();
        } else {
          console.log("API CALL failed");
        }
      } catch (error) {
        setApiErrorOpen(true);
        console.log("API call failed", error);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const GradesDialog = ({ isOpen, onClose, grades, name }) => {
    console.log("gradesss-----", grades);
    const renderGrades = () => {
      const displayedGrades = [];
      if (grades && Array.isArray(grades)) {
        grades?.forEach((grade, index) => {
          {
            displayedGrades?.push(
              <TableRow
                key={index}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "rgb(220 232 220)" : "rgb(239 244 239)",
                }}
              >
                <TableCell>{grade?.name || "N/A"}</TableCell>
                <TableCell>{grade?.weight}</TableCell>
                <TableCell>{grade?.numberOfPlants}</TableCell>
                {/* <TableCell>{grade?.price}</TableCell> */}
              </TableRow>
            );
          }
        });
      }
      return displayedGrades;
    };

    return (
      <Dialog
        open={isOpen}
        PaperProps={{
          style: {
            width: "500px",
            height: "450px",
          },
        }}
      >
        <DialogTitle>
          {grades && name && (
            <div style={{ width: "100%" }}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "800",
                  fontFamily: "Lexend Deca",
                  fontSize: "18px",
                }}
              >
                Grades for Variety {name}
              </Typography>
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          {grades ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "rgb(120 187 123)",
                      fontWeight: "bold",
                    }}
                  >
                    Grade Name
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "rgb(120 187 123)",
                      fontWeight: "bold",
                    }}
                  >
                    No of Plants
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "rgb(120 187 123)",
                      fontWeight: "bold",
                    }}
                  >
                    Weight(Kg)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderGrades()}</TableBody>
            </Table>
          ) : (
            <div
              style={{ width: "100%", marginTop: "25%", textAlign: "center" }}
            >
              <img
                src={Search}
                alt=""
                style={{ width: "10%", height: "10%" }}
              />
              <Typography
                variant="body1"
                style={{
                  fontFamily: "Lexend Deca",
                  fontSize: "16px",
                }}
              >
                No grades available for this variety.
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="success" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid sx={{ marginTop: "2%" }}>
        {isLoading ? ( // Check if data is still loading
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <ScaleLoader color="#2C7B55" />
          </div>
        ) : (
          // : rows?.length === 0 ? ( // Check if there are no records to display
          //   <h5>No records to display</h5>
          // )
          // <ThemeProvider theme={tableTheme}>
          <div>
            <MaterialReactTable
              columns={columns}
              data={rows}
              positionActionsColumn="last"
              enableRowActions
              enableGrouping
              enableStickyHeader
              enableRowVirtualization
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={false}
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 5 },
              }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: "650",
                  color: "#2c7B55",
                  fontSize: "15px",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f8fafc",
                  },
                },
              }}
              // muiTableContainerProps={{ sx: { marginBottom: "15px" ,} }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              renderRowActions={({ row }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Tooltip arrow placement="right" title="Rollback">
                    <IconButton onClick={() => handleRollBack(row.original)}>
                      <div style={{ rotate: "90deg" }}>
                        <UTurnLeftIcon />
                      </div>
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                //  marginTop: "-90%",
              }}
            >
              <Pagination
                count={100}
                onChange={handleChangePage}
                size="small"
              />
            </div>
          </div>
        )}

        <Dialog open={open} onClose={() => handleClose}>
          <DialogTitle>
            <h6>Revert Confirmation</h6>
          </DialogTitle>
          <DialogContent>
            <em>
              Reverting the harvested plants will bring them add back to live
              Inventory.!!!
            </em>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="success">
              Cancel
            </Button>
            <Button onClick={() => handleConfirm(selectedRow)} color="success">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      

      <GradesDialog
        isOpen={isDialogOpenGrades}
        onClose={() => setIsDialogOpenGrades(false)}
        grades={selectedRowGrades}
      />
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
}
export default GridHarvesting;
