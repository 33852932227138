import React, { useEffect, useState, useRef } from "react";
import "./ControlBox.css";
import { Button, Switch, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import Card from "@mui/material/Card";
import switchgif from "../../../Assets/switchgif.gif";
import { tokens } from "../../../theme";

function ControlBox({ deviceName, farmName }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  const [isOn, setIsOn] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const socketRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const socket = new WebSocket("ws://localhost:5050");
    socketRef.current = socket;

    socket.addEventListener("open", () => {
      setIsConnected(true);
      console.log("WebSocket connection established");
    });

    return () => {
      socket.close();
    };
  }, []);

  const sendOnOffData = (isOn) => {
    const data = {
      deviceName: deviceName,
      isOn: isOn,
      farmName: farmName,
    };

    if (isConnected && socketRef.current) {
      socketRef.current.send(JSON.stringify(data));
    }
  };

  const handleButtonClick = () => {
    const newIsOn = !isOn;
    setIsLoading(true);
    setError(false);
    setIsOn(newIsOn);

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsLoading(false);
      setError(true);
      setIsOn(!newIsOn);
    }, 5000);

    sendOnOffData(newIsOn);

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
      setError(false);
    }, 5000);
    clearTimeout(timeoutId);
  };

  useEffect(() => {
    const handleReceivedData = (event) => {
      const response = JSON.parse(event.data);

      clearTimeout(timeoutRef.current);
      setIsLoading(false);

      if (response === true) {
        setIsOn(true);
      } else if (response === false) {
        setIsOn(false);
      }
    };

    if (isConnected && socketRef.current) {
      socketRef.current.addEventListener("message", handleReceivedData);
    }

    return () => {
      if (isConnected && socketRef.current) {
        socketRef.current.removeEventListener("message", handleReceivedData);
      }
    };
  }, [isConnected]);

  return (
    <div className="control-box">
      <div className="control-box-item">
        <div className="left">
          <Typography
            variant="h6"
            color={colors.black[200]}
            fontFamily="Lexend Deca"
          >
            {deviceName}
          </Typography>
        </div>
        <div className="right">
          {/* <Button
            variant={isOn ? 'contained' : 'outlined'}
            onClick={handleButtonClick}
            disabled={isLoading}
            sx={{ backgroundColor: isLoading ? 'yellow' : undefined }}
          >
            {isLoading ? 'Loading...' : isOn ? 'ON' : 'OFF'}
          </Button> */}

          <Switch
            checked={isOn}
            onChange={() => setIsOn(!isOn)}
            disabled={isLoading}
            sx={{
              "& .MuiSwitch-track": {
                backgroundColor: isOn ? "green" : "gray",
              },
              "& .MuiSwitch-thumb": {
                backgroundColor: isOn ? "white" : "white",
              },
              fontWeight: "bold",
              fontSize: "16px",
            }}
          />
        </div>
      </div>
      {error && (
        <div className="error-popup">
          <span className="error-message">
            Failed to turn on/off the device. Please try again.
          </span>
          {/* <p className="error-message">Failed to turn on/off the device. Please try again.</p> */}
        </div>
      )}
    </div>
  );
}
function Control({ farmName }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const typography = tokens(theme.palette.mode.typography);
  return (
    <Card sx={{ width: "80%", height: "96%", marginTop: "20px",backgroundColor:"#ecf4f0"  }}>
      <div style={{ width: "100%", height: "100%", marginTop: "10px" }}>
        {/* <h3 className="textstyle" style={{ fontWeight: '400', fontSize: '16px' }}>Control Panel</h3> */}
        <Typography
          style={{ marginLeft: "8px" }}
          variant="h4"
          color={colors.black.DEFAULT}
          fontFamily="Lexend Deca"
          // backgroundColor="#ecf4f0"
        >
          Control Panel
          <img
            src={switchgif}
            alt="GIF"
            style={{ marginLeft: "5px", width: "25px", height: "25px" }}
          />
        </Typography>

        <div className="deviceName">
          <ControlBox deviceName="Fanpad" farmName={farmName} />
          <ControlBox deviceName="ACF" farmName={farmName} />
          <ControlBox deviceName="Fogger" farmName={farmName} />
          <ControlBox deviceName="Sprinklers" farmName={farmName} />
        </div>
      </div>
    </Card>
  );
}
export default Control;
