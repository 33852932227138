import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(() => {
    // Retrieve the username from local storage, or an empty string if not available
    return localStorage.getItem("username") || "";
  });
  
// Initialize with an empty string
  //  console.log("Uewbuerbuwbubwbw", username);
  return (
    <AuthContext.Provider value={{ username, setUsername }}>
      {children}
    </AuthContext.Provider>
  );
};
